import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { InfosDiv } from './styles'

const Infositens: React.FC = () => {


  return (
    <InfosDiv>
		<div className="infos">
			<div className="infos-item">
				<h1>Missão</h1>
				<p>
					Exercer, fundamentada nos princípios e
					valores institucionais, a livre defesa
					dos interesses dos associados e da
					sociedade e prover soluções geradoras de
					valor que contribuam para o seu
					desenvolvimento
				</p>
			</div>
				<div className="infos-item">
					<h1>Visão</h1>
					<p>
						Ser reconhecida por sua forte liderança
						e influência institucional, alicerçada
						por uma atuação independente e
						representativa
					</p>
								</div>
								<div className="infos-item">
									<h1>Valores</h1>
									<p>
										Ser reconhecida por sua forte liderança
										e influência institucional, alicerçada
										por uma atuação independente e
										representativa
									</p>
								</div>
</div>


    </InfosDiv>
  );

};

export default Infositens;
