import styled from 'styled-components';

export const Container = styled.div`
	min-height: 100vh;
	background: #f5f5f5;
	padding-top: 86px;
	.row-title {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 0px 6%;
		h1 {
			margin-bottom: 54px;
			color: #353a40;
			font-size: 26px;
			font-family: 'Roboto';
		}
		.input-row {
			position: relative;
			svg {
				z-index: 99999999;
				margin-top: 6px;
				margin-left: 35%;
				width: 18px;
				position: absolute;
				left: 5px;
				color: #2a3199;
			}
		}
		input {
			margin-bottom: 54px;
			margin-left: 35%;
			width: 648px;
			height: 32px;
			background: #f5f5f5;
			border: 1px solid #c5ced7;
			border-radius: 8px;
			padding-left: 25px;
			position: relative;
			svg {
				margin-top: 6px;
				margin-left: 35%;
				width: 18px;
				position: absolute;
				left: 5px;
				color: #2a3199;
			}
		}
	}
	.col-grid {
		display: grid;
		grid-template-columns: 1fr 3fr;
	}
	.categorys {
		margin-left: 24%;
		font-family: 'Roboto';
		/* background: blue; */
		ul {
			font-size: 16px;
			color: #5a646e;
			list-style: none;
			li {
				padding-bottom: 25px;
				margin-left: 25px;
				font-weight: lighter;
			}
		}
		h6 {
			padding-bottom: 25px;
			font-weight: 500;
			font-size: 18px;
			color: #353a40;
		}
	}
	.cards {
		/* background: red; */
	}
`;
export const ProductContainer = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: 20px;
	row-gap: 19px;
	width: 100%;

	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;

	height: 90%;
	padding-bottom: 50px;

	@media (max-width: 768px) {
		display: flex;
		width: 105%;
		flex-wrap: nowrap;
		/* background: purple; */
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		padding-bottom: 38px;
	}
`;
