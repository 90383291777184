import React from 'react';
import Header from '../../components/Default/header';
import Login from '../../components/Login/Login';
import LoginHeader from '../../components/Login/LoginHeader/LoginHeader';
import LoginAdmin from '../../components/LoginAdmin/LoginAdmin';

import { Container } from './styles';

const LoginAdminPage: React.FC = () => {
	return (
		<Container>
			<LoginHeader />
			<LoginAdmin />
		</Container>
	);
};

export default LoginAdminPage;
