import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';

import Modal from 'react-modal';
import config from '../../../../config';
import { ProductPhotos } from '../../../../interfaces/Product';
import { BackButton, CloseButton, FowardButton } from './styles';
import { ReactComponent as Arrow } from '../../../../assets/icons/arrowBack.svg';
import { IoMdClose } from 'react-icons/io';
import { VariationPhotos } from '../../../../interfaces/ProductVariations';

export interface PhotosModalHandles {
	handleOpenModal: () => void;
}

interface ProductImage {
	id: string;
	name: string;
}

type Props = {
	images: ProductImage[];
	selectedImage: ProductImage;
};

const PhotosModal: React.ForwardRefRenderFunction<PhotosModalHandles, Props> = (
	{ ...props },
	ref
) => {
	const [modalIsOpen, setIsOpen] = useState(false);
	const [images, setImages] = useState<ProductImage[]>([]);
	const [indexImageSelected, setIndexImageSelected] = useState(0);

	useEffect(() => {
		setImages(props.images);
	}, [props.images]);

	useEffect(() => {
		if (props.selectedImage) {
			findSelectedImage();
		}
	}, [props.selectedImage]);

	const findSelectedImage = () => {
		const result = images.indexOf(props.selectedImage);
		if (result != -1) {
			setIndexImageSelected(result);
		}
	};

	const changeSelectedImage = (action: string) => {
		if (action === 'back' && indexImageSelected !== 0) {
			setIndexImageSelected(indexImageSelected - 1);
		} else if (
			action === 'foward' &&
			indexImageSelected !== images.length - 1
		) {
			setIndexImageSelected(indexImageSelected + 1);
		}
	};

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			//marginRight: '-50%',
			width: 'max-content',
			height: 'max-content',
			minWidth: '500px',
			maxWidth: '900px',
			minHeight: '500px',
			maxHeight: '600px',
			transform: 'translate(-50%, -50%)',
			padding: '0',
			display: 'flex',
			//justifyContent: 'space-between',
			alignItems: 'center',
			backgroundColor: 'transparent',
			border: 'none',
			overflow: 'visible',
			zIndex: 3,
		},
		overlay: {
			backgroundColor: 'rgba(0,0,0, 0.8)',
			zIndex: 3,
		},

		h2: {
			color: '#3B3D4A',
		},
	};
	function closeModal() {
		setIsOpen(false);
	}
	function handleOpenModal() {
		setIsOpen(true);
	}

	useImperativeHandle(ref, () => {
		return {
			handleOpenModal,
		};
	});

	return (
		<Modal
			isOpen={modalIsOpen}
			onRequestClose={closeModal}
			style={customStyles}
		>
			<CloseButton onClick={closeModal}>
				<IoMdClose fill='#aaa' size={30} />
			</CloseButton>
			<BackButton
				className={0 === indexImageSelected ? 'active' : ''}
				onClick={() => changeSelectedImage('back')}
			>
				<Arrow className="button-icon" fill=" #004d93" />
			</BackButton>
			<img
				src={`${config.host}/static/products/${images[indexImageSelected]?.name}`}
				alt=""
				style={{
					minWidth: '500px',
					maxWidth: '900px',
					minHeight: '600px',
					maxHeight: '500px',
					objectFit: 'cover',
				}}
			/>
			<FowardButton
				className={
					images.length - 1 === indexImageSelected ? 'active' : ''
				}
				onClick={() => changeSelectedImage('foward')}
			>
				<Arrow className="button-icon" fill=" #004d93" />
			</FowardButton>
		</Modal>
	);
};

export default forwardRef(PhotosModal);
