import styled from 'styled-components';

export const Container = styled.div`
	font-family: var(--Roboto-font-family);
	.title {
		margin-bottom: 10px;
		h6 {
			font-size: 16px;
			color: #2b63fc;
		}
	}
	form {
		border-top: solid 1px #e1e1e1;
		.first-row {
			display: flex;
			margin: 30px 0;

			.input-and-error {
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				.field-error,
				.field-validation-error {
					font-size: 11px;
					margin-top: 4px;
					color: red;
				}
			}
		}
		.second-row {
			.input-and-error {
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				.field-error,
				.field-validation-error {
					font-size: 11px;
					margin-top: 4px;
					color: red;
				}
			}
		}
		input {
			border: 1px solid #8a99a8;
			border-radius: 8px;
			font-style: italic;
			height: 40px;
		}
	}
	.button {
		display: flex;
		justify-content: center;
	}
	button {
		background: #2b63fc;
		border-radius: 8px;
		width: 326px;
		height: 40px;
		color: #fff;
		border: none;
		font-size: 16px;
		font-weight: normal;
	}
`;
export const CardAndFormContainer = styled.div`
	display: flex;
	align-items: center;
`;
