import React, { useEffect, useRef, useState } from 'react';

import { FaUserCircle } from 'react-icons/fa';
import { AiFillDollarCircle } from 'react-icons/ai';

import UserAccount, { UserTypeEnum } from '../../../interfaces/UserAccount';
import { AppState } from '../../../store';

import {
	Container,
	GeneralInfosContainer,
	AddressContainer,
	PaymentInfosContainer,
	Informations,
	GoToEbanxButton,
} from './styles';
import NewAddressModal, {
	NewAddressModalHandles,
} from '../AddressArea/NewAddressModal/NewAddressModal';
import AddressItem from '../AddressArea/AddressItem/AddressItem';
import PaymentCard from './PaymentCard/PaymentCard';
import AddPaymentModaL, {
	AddPaymentModalHandle,
} from './AddPaymentModal/AddPaymentModal';
import UpdateUserInfosModal, {
	UpdateUserInfosModalHandle,
} from './UpdateUserInfosModal/UpdateUserInfosModal';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../api';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { GetSignUpLinkResponse } from '../../../interfaces/ApiResponses/SignUpLink';
import { HiLocationMarker } from 'react-icons/hi';
import { useForm } from 'react-hook-form';
import { insertUser } from '../../../actions/UserActions';

const UserInformation: React.FC = () => {
	const history = useHistory();
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const handleWindowSizeChange = () => {
		setIsMobile(window.innerWidth <= 768);
	};
	const [accountStatus, setAccountStatus] = useState<
		'AWAITING_DOCUMENTS' | 'VERIFIED' | 'BLOCKED' | 'INACTIVE' | ''
	>('');

	useEffect(() => {
		setIsMobile(window.innerWidth <= 768);
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	const { handleSubmit, register, errors, setValue } = useForm<{
		bank_code: number;
        branch_number: number;
        branch_check_digit: number;
        account_number: number;
        account_check_digit: number;
	}>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		shouldFocusError: true,
	});
	const [recipientSubmited, setRecipientSubmited] = useState<boolean>(false);
	const dispatch = useDispatch();
	const submit = async (info: {
		bank_code: number;
        branch_number: number;
        branch_check_digit: number;
        account_number: number;
        account_check_digit: number;
	}) => {
		setIsSavingBank(true);
		setRecipientSubmited(false);
		setSaveBankError(undefined);

		setTimeout(() => {
			api.post('/pagarme-recipient', info).then((response) => {
				setRecipientSubmited(true);
				setIsSavingBank(false);
				setSaveBankError(undefined);
				dispatch(insertUser({...user, pagarme_recipient_id: 'yes'}));
			} ).catch((error) => {
				setSaveBankError(error.response.data.error);
				setIsSavingBank(false);
			})
		}, 500)
	}
	const [saveBankError, setSaveBankError] = useState<string | undefined>(undefined);
	useEffect(() => {
		const fetch = async () => {
			const { data } = await api.get('/pagarme-recipient');

			const { default_bank_account: bank } = data;

			if(bank !== {}) {
				setValue('bank_code', bank?.bank || '');
				setValue('branch_number', bank?.branch_number || '');
				setValue('branch_check_digit', bank?.branch_check_digit || '');
				setValue('account_number', bank?.account_number || '');
				setValue('account_check_digit', bank?.account_check_digit || '');
			}
		}

		fetch();
	}, [setValue])

	const modalRef = useRef<NewAddressModalHandles>(null);
	const modalRef1 = useRef<AddPaymentModalHandle>(null);
	const modalRef2 = useRef<UpdateUserInfosModalHandle>(null);

	const openModal = () => {
		modalRef.current?.handleOpenModal();
	};
	const openModal1 = () => {
		modalRef1.current?.handleOpenModal();
	};
	const openModal2 = () => {
		modalRef2.current?.handleOpenModal();
	};

	useEffect(() => {
		api.get('/get-account-infos')
			.then((res: AxiosResponse<any>) => {
				setAccountStatus(res.data.account.status);
			})
			.catch((err) => {});
	}, []);

	function getSignLink() {
		api.get<any, AxiosResponse<GetSignUpLinkResponse>>('/get-signup-link')
			.then((res) => {
				window.open(res.data.data.link);
			})
			.catch((err) => {
				console.error(err);
			});
	}
	function verifyAccountStatus(
		status: 'AWAITING_DOCUMENTS' | 'VERIFIED' | 'BLOCKED' | 'INACTIVE' | ''
	): string {
		switch (status) {
			case 'AWAITING_DOCUMENTS':
				return 'Aguardando documentos';
			case 'VERIFIED':
				return 'Verificada';
			case 'BLOCKED':
				return 'Bloqueada';
			case 'INACTIVE':
				return 'Inativa';

			default:
				return '';
		}
	}

	const isSeller = user.user_types && user.user_types.name === UserTypeEnum.seller;

	function RenderButton() {
		if (
			!user.ebanx_resource_token &&
			user.user_types &&
			user.user_types.name === UserTypeEnum.seller
		) {
			return (
				<GoToEbanxButton onClick={() => getSignLink()}>
					Comece a vender
				</GoToEbanxButton>
			);
		} else if (user.ebanx_resource_token) {
			return (
				<>
					<GoToEbanxButton
						onClick={() => getSignLink()}
						disabled={true}
						style={{ opacity: '0.6' }}
					>
						Já registrado
					</GoToEbanxButton>
					<span className="account-status">
						Status da conta: {verifyAccountStatus(accountStatus)}{' '}
					</span>
				</>
			);
		}
	}

	const [isSavingBank, setIsSavingBank] = useState<boolean>(false);

	return (
		<Container>
			<GeneralInfosContainer>
				<UpdateUserInfosModal ref={modalRef2} />
				<div className="top-side">
					<h4>
						<FaUserCircle className="react-icons" />
						Dados Gerais
					</h4>
					{isMobile ? (
						<h4
							onClick={() => {
								history.push(`/change-information-mobile`);
							}}
							style={{ cursor: 'pointer' }}
						>
							Alterar informações
						</h4>
					) : (
						<h4
							onClick={() => openModal2()}
							style={{ cursor: 'pointer' }}
						>
							Alterar informações
						</h4>
					)}
				</div>
				<div className="infosUser">
					<div className="InfosRow">
						<label>Nome:</label>
						<span>{user.name}</span>
					</div>
					<div className="infosRow">
						<label>E-mail:</label>
						<span>{user.email}</span>
					</div>
					<div className="infosRow">
						<label>Telefone:</label>
						<span style={{ marginRight: '0' }}>
							{user?.Contacts![0]!.phone}
						</span>
					</div>
				</div>
			</GeneralInfosContainer>

			<AddressContainer>
				<Informations>
					<h4>
						<HiLocationMarker className="react-icons" />
						Endereços cadastrados
					</h4>
					<div className="container">
						{user.Address?.map((address) => (
							<AddressItem address={address} />
						))}
						<NewAddressModal ref={modalRef} />

						<div className="third-row">
							{isMobile ? (
								<div
									className="add-address"
									onClick={() => {
										history.push(`/user-address-mobile`);
									}}
								>
									<div className="plus-icon">+</div>
									<span>Adicionar novo endereço</span>
								</div>
							) : (
								<div
									className="add-address"
									onClick={() => openModal()}
								>
									<div className="plus-icon">+</div>
									<span>Adicionar novo endereço</span>
								</div>
							)}
						</div>
					</div>
				</Informations>
			</AddressContainer>
			{ isSeller && <PaymentInfosContainer>
				<h4>
					<AiFillDollarCircle className="react-icons" />
					Informações de pagamento
				</h4>
				<form onSubmit={handleSubmit(submit)}>
					<label>Banco</label>
					<input type='number' 
						name='bank_code'
						{...register('bank_code')}
						ref={register({ required: true })}
						disabled={isSavingBank}
						required
						min={0}
					/>
					<label>Agência</label>
					<input type='number'
						name='branch_number'
						{...register('branch_number')}
						ref={register({ required: true })}
						disabled={isSavingBank}
						required
						min={0}
					/>
					<label>Digito Validador da Agência</label>
					<input type='number' 
						name='branch_check_digit'
						{...register('branch_check_digit')}
						ref={register({ required: true })}
						disabled={isSavingBank}
						required
						min={0}
					/>
					<label>Conta</label>
					<input type='number'
						name='account_number'
						{...register('account_number')}
						ref={register({ required: true })}
						disabled={isSavingBank}
						required
						min={0}
					/>
					<label>Digito Validador da Conta</label>
					<input type='number' 
						name='account_check_digit'
						{...register('account_check_digit')}
						ref={register({ required: true })}
						disabled={isSavingBank}
						required
						min={0}
					/>
					<button type='submit'
						disabled={isSavingBank}
					>Salvar</button>
					{recipientSubmited && 
						<p>Alterações salvas</p>
					}
					{saveBankError && <p style={{ color: 'red' }}>{saveBankError}</p>}
				</form>
			</PaymentInfosContainer>}
			{/* {RenderButton()} */}
		</Container>
	);
};

export default UserInformation;
