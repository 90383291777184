import React from 'react';
import { Container, PageContainer, AlignmentContainer } from './styles';
import deliveryTruck from '../../../../assets/icons/deliveryTruck.svg';
import { useHistory } from 'react-router-dom';
import ShippingMethodItemCart from '../ShippingMethodItemCart/ShippingMethodItemCart';
import ShippingAddressCart from '../ShippingAddressCart/ShippingAddressCart';
import PaymentForm from '../../../BuyProcess/Review/PaymentForm/PaymentForm';
import PaymentPlan from '../../../BuyProcess/Review/PaymentPlan/PaymentPlan';
import { useSelector } from 'react-redux';
import ProductsCart from '../../../../interfaces/ProductsCart';
import { AppState } from '../../../../store';
import { calculateProductCartValue } from '../../../../utils/CalculateProductCartValue';
import CartReviewCard from '../CartReviewCard/CartReviewCard';

const PaymentCartMethod: React.FC = () => {
	const history = useHistory();

	const productsCart: ProductsCart[] = useSelector(
		(state: AppState) => state.productsCart.currentCart
	);
	return (
		<PageContainer>
			<AlignmentContainer>
				<Container>
					<h1>Finalização de compra</h1>

					<div className="informations-container">
						<div className="main-card">
							<PaymentForm />
							<PaymentPlan />
						</div>
							<CartReviewCard 
								backButtonPath={`/cart/shipping-method/${'payment'}`}
								nextButtonPath={`/cart/order-confirmation/${'payment'}`}/>
					</div>
				</Container>
			</AlignmentContainer>
		</PageContainer>
	);
};

export default PaymentCartMethod;
