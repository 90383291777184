import styled from 'styled-components';

export const Container = styled.form`
	height: max-content;
	background: #f5f5f5;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;

	.background-overlay {
		position: fixed;
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.5);
		z-index: 1;
	}

	@media (max-width: 768px) {
		/* height: 100%; */
	}
	input {
		height: 40px !important;
		color: #898989 !important;
	}

	.no-token {
		padding-top: 150px;
		padding-bottom: 300px;
		font-family: var(--Roboto-font-family);
		h1 {
			text-align: center;
			font-weight: 400;
			color: #3b3d4a;
			font-size: 30px;
			margin-bottom: 28px;
		}
	}

	header {
		padding-top: 61px;
		display: flex;
		width: 100%;

		flex-direction: column;
		align-items: center;
		margin-bottom: 35px;
		font-family: var(--Roboto-font-family);
		background: #ffffff;
		@media (max-width: 768px) {
			background: #f5f5f5;
			padding-top: 20px;
		}
		h2 {
			font-weight: 400;
			color: #3b3d4a;
			font-size: 24px;
			margin-bottom: 28px;
		}
		h1 {
			color: #2b63fc;
			font-size: 26px;
		}
		.categories-card-container {
			margin-top: 25px;
			border-radius: 8px;
			padding: 12px 20px;
			@media (max-width: 760px) {
				flex-wrap: wrap;
			}
			h2 {
				color: #2b63fc;
				margin-top: 15px;
				font-family: var(--Roboto-font-family);
				font-size: 0.6rem;
				margin-bottom: 20px;
			}
			.categories-card {
				display: grid;
				grid-template-columns: 152px 152px 152px 152px;
				column-gap: 19px;
				row-gap: 16px;
				z-index: 1;
				@media (max-width: 768px) {
					grid-template-columns: 40% 40%;
					column-gap: 34px;
				}
				@media (max-width: 310px) {
					display: flex;
					flex-direction: column;
				}

				img {
					width: 88px;
					height: 88px;
					object-fit: contain;
				}
			}
		}
	}
`;

export const CardContainer = styled.div`
	margin-top: calc(70px + 50px);
	height: 100%;
	width: var(--Default-page-alignment-vale);
	@media (max-width: 768px) {
		width: 100%;
		margin-top: 438px;
	}

	@media (max-width: 310px) {
		margin-top: 1208px;
	}
	@media (max-width: 900px) {
		width: 100%;
	}

	.order-mobile {
		display: flex;
	}
	.price-card {
		order: 1;
	}

	.stock-container {
		font-family: var(--Roboto-font-family) !important;
		margin-top: 15px;

		h2 {
			color: #2b63fc;
			font-size: 0.72rem;
		}

		button {
			background: #2b63fc;
			border: none;
			border-radius: 8px;
			width: 227px;
			height: 43px;
			font-family: var(--Roboto-font-family);
			font-size: 14px;
			color: #ffffff;
		}

		.buttons-container {
			display: flex;
			justify-content: space-between;
			flex-direction: row-reverse;
			margin-top: 24px;

			span {
				font-family: var(--Roboto-font-family);
				font-size: 16px;
				font-weight: 500;
				color: #c9182a;
				text-align: center;
			}
		}

		.inputs-container {
			.inputs {
				border-bottom: 1px solid #c5ced7;
				padding-bottom: 38px;
				margin-bottom: 31px;
				.row-inputs-delete {
					display: flex;
					flex-direction: row;
				}
				button {
					border: 1px solid #2b63fc;
					border-radius: 8px;
					color: #2b63fc;
					background: #ffffff;
				}
				.delete-button {
					margin-top: 35px;
					display: flex;
					align-items: center;
					cursor: pointer;
					svg {
						width: 25px;
						height: 25px;
						margin-top: -75px;
					}
					span {
						text-decoration: underline;
						color: #c91729;
						font-size: 14px;
						margin-left: 5px;
						margin-top: -75px;
					}
				}
				.add-images-card {
					height: max-content;
					/* padding: 12px 20px; */
					display: flex;
					flex-direction: row;
					margin: 30px;
					align-items: flex-start;
					column-gap: 15px;
					@media (max-width: 768px) {
						flex-wrap: wrap;
					}
					figure {
						width: 152px;
						height: 105px;
						display: flex;
						flex-direction: row;
						label {
							margin-right: 15px;
							width: 137px;
							height: 137px;
							background: #f6f6f6;
							font-size: 20px;
							color: #8a99a8;
							cursor: pointer;
							text-align: center;
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 8px;
							@media (max-width: 768px) {
								margin-bottom: 60px;
							}
						}
						input {
							border: none !important;
							display: none;
						}
						svg {
							.a {
								fill: #a7a7a7;
							}
						}
					}
					.preview {
						display: flex;
						flex-direction: row;
						overflow-x: auto;

						.img-preview {
							height: 120px;
							width: 120px;
						}

						.close-icon-container {
							position: relative;
							.close-icon {
								position: relative;
								left: 80%;
								bottom: 70%;
								background: white;
								height: 20px;
								width: 20px;
								border-radius: 50%;
								border: 1px solid #c5ced7;

								&:hover {
									cursor: pointer;
								}
							}
						}
					}
				}
				.size-container {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					row-gap: 10px;
					@media (max-width: 768px) {
						width: 232px;
					}
					.size-inputs-container {
						display: flex;
						flex-direction: row;
					}
				}
			}
		}
		#color {
			border: none !important;
			border-bottom: solid 1px #8998a7 !important;
		}
		#stock {
			border: 1px solid #8a99a8 !important;
			border-radius: 8px;
			width: 210px;
			margin-right: 20px;
			padding-left: 15px;
			::placeholder {
				font-style: italic;
			}
		}
		#variation-stock {
			border: none !important;
			border-bottom: solid 1px #8a99a8 !important;
			width: 110px;
			text-align: center;
			margin-right: 20px;
		}
		#photo {
			width: 137px;
			height: 137px;
			background: #f6f6f6;
			font-size: 20px;
			color: #8a99a8;
			cursor: pointer;
		}
		#size {
			width: 44px;
			height: 43px;
			border: 1px solid #8a99a8 !important;
			border-radius: 8px;
			font-size: 13px;
			color: #8a99a8;
			text-align: center;
			margin-right: 10px;
		}
	}

	header {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 35px;
		font-family: var(--Roboto-font-family);
		h2 {
			font-weight: 400;
			color: #3b3d4a;
			font-size: 24px;
			margin-bottom: 28px;
		}
		h1 {
			color: #2b63fc;
			font-size: 26px;
		}
		.categories-card-container {
			margin-top: 25px;
			border-radius: 8px;
			padding: 12px 20px;

			@media (max-width: 760px) {
				flex-wrap: wrap;
			}
			h2 {
				color: #2b63fc;
				margin-top: 15px;
				font-family: var(--Roboto-font-family);
				font-size: 0.6rem;
				margin-bottom: 20px;
			}
			.categories-card {
				border-radius: 8px;
				display: grid;
				grid-template-columns: 152px 152px 152px 152px;
				column-gap: 19px;
				row-gap: 16px;
				@media (max-width: 768px) {
					grid-template-columns: 150px 150px;
				}

				img {
					width: 88px;
					height: 88px;
					object-fit: contain;
				}

				.category {
					width: 152px;
					height: 152px;
					box-shadow: 0px 3px 6px #00000029;
					border-radius: 4px;
					border: solid 1px #c9c9c9;
					/* width: 117px;
         	 		height: 30px; */
					background: #f5f5f5;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					border-radius: 8px;
					transition: all 300ms;
					span {
						color: #000;
						font-size: 12px;
						font-family: var(--Roboto-font-family);
						font-weight: 400;
						margin-top: 5px;
					}

					&:hover {
						cursor: pointer;
						border: solid 1px #2b63fc;
					}
				}
				.active {
					font-size: 0.5rem;
					font-family: var(--Roboto-font-family);
					font-weight: 400;
					/* background-color: #20256c; */
					border: solid 1px #2b63fc;

					span {
						color: #000;
					}
				}
			}
		}
	}
	main {
		width: 100%;
		padding-bottom: 20px;
		margin-bottom: 50px;
		label {
			color: #898989;
			font-family: var(--Roboto-font-family);
			font-size: 10px;
			font-weight: bold;
		}

		.finish-button-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			column-gap: 18px;
			@media (max-width: 768px) {
				flex-direction: column-reverse;
			}
			.negative-button {
				width: 157px;
				height: 40px;
				border: 1px solid #c9c9c9 !important;
				border-radius: 8px;
				background: transparent;
				color: #898989;
				font-family: var(--Roboto-font-family);
				font-weight: bold;
				font-size: min(13px, 1vw);
				@media (max-width: 768px) {
					width: 92%;
					margin-bottom: 15px;
					font-size: 12px;
				}
			}

			.positive-button {
				width: 157px;
				height: 40px;
				background: #02c384 0% 0% no-repeat padding-box;
				color: #ffffff;
				font-family: var(--Roboto-font-family);
				font-weight: bold;
				font-size: min(13px, 1vw);
				border-radius: 8px;
				border: none;
				@media (max-width: 768px) {
					width: 92%;
					margin-bottom: 15px;
					font-size: 12px;
				}
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
`;
export const CardTitle = styled.div`
	/* border: solid 1px #cbcbcb; */
	border-radius: 8px !important;

	padding: 0px 10px 12px 10px;
	h2 {
		color: #2b63fc;
		font-size: 0.72rem;
		font-family: var(--Roboto-font-family);
	}
`;

export const CardInputs = styled.div`
	padding: 0px 10px 12px 10px;
	width: 100%;

	@media (max-width: 768px) {
		padding: 0 5%;
		width: 90%;
	}
	h3 {
		color: #898989;
		margin-top: 15px;
		font-family: var(--Roboto-font-family);
		font-size: 0.6rem;
	}
	.title-and-user-state {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		margin-top: 23px;
		@media (max-width: 768px) {
			display: flex;
			flex-direction: column;
		}

		.use-state {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-flex-direction: row;
			-ms-flex-direction: row;
			flex-direction: row;
			-webkit-flex: 1;
			-ms-flex: 1;
			flex: 1;
			height: 38px;
			border-radius: 8px;
			-webkit-align-self: flex-end;
			-ms-flex-item-align: end;
			/* align-self: flex-end; */
			justify-content: space-around;
			/* padding: 0px; */
			border: solid 1px #c9c9c9;
			@media (max-width: 768px) {
				width: 100%;
			}

			.state-label {
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				font-family: var(--Roboto-font-family);
				font-weight: bold;
				font-size: 0.6rem;
				color: #a8a8a8;
				@media (max-width: 768px) {
					height: 40px;
				}
				#new,
				#used {
					display: none;
				}
			}
			.active-1 {
				background: #114ae9;
				color: white;

				border-top-left-radius: 8px;
				border-bottom-left-radius: 8px;
			}
			.active-2 {
				background: #114ae9;
				color: white;

				border-top-right-radius: 8px;
				border-bottom-right-radius: 8px;
			}
		}
	}
	.checkbox {
		margin-top: 5px;
		display: flex;
		flex-direction: row;
		@media (max-width: 768px) {
			/* width: 116%; */
		}
		input:checked {
			@media (max-width: 768px) {
				/* color: #20256C; */
				background-color: yellow;
			}
		}
		.ui {
			width: 100px;
			label {
				margin-top: 10px;
				margin-left: 10px;
				color: #5a646e;
				font-weight: 500;
				font-size: 12px;
			}
		}
	}
	.description {
		width: 99.8%;
		border: solid 1px #8a99a8;
		margin-right: 0px;
		/* padding-top: 10px; */
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		height: 89px;
		border-radius: 4px;
		margin-top: 15px;
		border-radius: 8px;
	}
	textarea {
		width: 95%;
		border: none;
		height: 69px;
		resize: none;
		color: #898989;
		font-family: var(--Roboto-font-family);
		font-size: 13px;
		margin-left: 17px;
		margin-top: 10px;
	}
	.brand-model-and-material {
		margin-top: 25px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: wrap;
		display: flex;
		column-gap: 15px;
	}
	.ButtonWithdraw {
		display: none;
		margin-top: 25px;
		@media (max-width: 768px) {
			display: flex;
		}

		button {
			border: 1px solid #707070;
			border-radius: 8px;
			height: 40px;
			width: 50%;
			background-color: white;
			color: #6f6f6f;
		}
		.ButtonYes {
			background-color: #707070;
			color: white;
			/* border-radius: 8px 0 8px 0; */
		}
		.ButtonNo {
			padding: 8px 0px 8px;
			margin-left: 13px;
		}
		.ButtonYes:active {
			background-color: #707070;
			color: white;
		}
		.ButtonNo:active {
			background-color: #707070;
			color: white;
		}
	}

	.sending-informations {
		margin-top: 15px;
		h3 {
			font-size: 0.72rem;
			color: #2b63fc;
		}
		.sending-informations-input {
			display: flex;
			flex-direction: row;
			margin-top: 25px;
			width: 40%;
			@media (max-width: 768px) {
				/* display: none; */
				width: 100%;
				flex-direction: column;
				row-gap: 23px;
			}
			.select-container {
				display: flex;
				flex-direction: column;
				@media (max-width: 768px) {
					display: none;
				}

				label {
					font-family: 'Open sans';
					font-weight: bold;
					font-size: min(12px, 1.5vw);
					color: #898989;
					margin-bottom: 4px;
					@media (max-width: 768px) {
						display: none;
					}
				}
				select {
					height: 42px;
					border: 1px solid #8a99a8;
					border-radius: 8px;
					padding: 3px;
					color: #898989;
					font-family: var(--Roboto-font-family);
					font-weight: 400;
					@media (max-width: 768px) {
						width: 100%;
						height: 43px;
						margin-top: 20px;
					}
				}
			}
			.select-container + div {
				margin-left: 30px;
				@media (max-width: 768px) {
					margin-left: 0;
				}
			}
		}
	}

	.sending-informations-buttom {
		@media (min-width: 768px) {
			display: none;
		}
		margin: 20px 0;
		width: 100%;
		height: 42px;
		/* background: blue; */
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		border: 1px solid #707070;
		border-radius: 8px;
		font-size: 13px;
		/* color: #a8a8a8; */
		font-family: var(--Roboto-font-family);
		font-weight: 400;
		/* margin-top: 25px;; */
		label {
			position: relative;
			cursor: pointer;
			font-size: 12px;
			display: inline-block;
			text-transform: uppercase;
			letter-spacing: 5px;
			width: 200px;
			padding: 13px 0;
			transition: all 0.3s ease;
			z-index: 1;
			text-align: center;
			/* color: white; */
		}

		#gray:checked + label {
			color: white;
			z-index: 99;
		}
		#white + label:before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: -100%;
			background-color: #6f6f6f;
			border-radius: 8px;
			transition: all 0.3s ease;
			box-sizing: border-box;
		}
		#white:checked + label:before {
			left: 0%;
			z-index: -1;
		}
		#white:checked + label,
		#gray:checked + label {
			color: white;
		}
	}
	.packaging-format-mobile {
		display: none;
		@media (max-width: 768px) {
			display: block;
			margin-bottom: -25px;
		}
	}
	.product-sending-informations {
		display: flex;
		flex-direction: row;
		margin-top: 25px;
		flex-wrap: wrap;
		display: flex;
		column-gap: 15px;

		.input-div {
			font: status-bar;
			@media (max-width: 768px) {
				width: 100%;
			}
		}
	}
	.additional-informations {
		color: #a8a8a8;
		font-size: 13px;
		font-family: var(--Roboto-font-family);
		font-weight: 400;
		margin-top: 25px;
	}
	.image-card {
		h2 {
			color: #2b63fc;
			margin-top: 15px;
			font-family: var(--Roboto-font-family);
			font-size: 0.72rem;
			margin-bottom: 20px;
		}
		.add-images-card {
			height: max-content;
			padding: 12px 0px;
			display: flex;
			margin-top: 20px;
			flex-direction: row;
			align-items: flex-start;
			column-gap: 15px;
			flex-wrap: wrap;
			@media (max-width: 768px) {
				flex-wrap: wrap;
			}
			label {
				margin-right: 15px;
				width: 137px;
				height: 137px;
				background: #f6f6f6;
				font-size: 20px;
				color: #8a99a8;
				cursor: pointer;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 8px;
			}
			figure {
				/* width: 157px; */
				height: 105px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				input {
					display: none;
				}
				svg {
					&.a {
						fill: #a7a7a7;
					}
				}
			}
			.preview {
				display: flex;
				flex-direction: row;

				overflow-x: auto;
				.img-preview {
					height: 120px;
					width: 120px;
				}

				.close-icon-container {
					position: relative;
					.close-icon {
						position: relative;
						left: 80%;
						bottom: 70%;
						height: 20px;
						background: white;
						width: 20px;
						border-radius: 50%;

						&:hover {
							cursor: pointer;
						}
					}
				}
			}
		}
	}
	.price-card-mobile {
		/* display: none; */
		margin-top: 25px;
		@media (max-width: 768px) {
			height: 253px;
		}
		@media (max-width: 768px) {
		}
		h2 {
			color: #2b63fc;
			margin-top: 15px;
			font-family: var(--Roboto-font-family);
			font-size: 0.72rem;
			margin-bottom: 20px;
		}
		.price-inputs-card {
			@media (max-width: 768px) {
				height: 115px;
			}
			display: flex;
			flex-direction: row;
			align-items: flex-start;

			.limit-container {
				width: 38%;
				display: flex;
				flex-direction: row;
				display: flex;
				column-gap: 15px;
				@media (max-width: 768px) {
					flex-direction: column;
					width: 100%;
				}

				#priceInput {
					width: calc(206px - 17px);
					text-align: center;
					height: 70px !important;
					font-size: 22px;
					color: #20256c;
					@media (max-width: 768px) {
						padding: 0;
						width: 100%;
						color: #20256c;
					}
				}
				.checkbox {
					margin-top: 5px;
					display: flex;
					flex-direction: row;
					@media (max-width: 768px) {
						/* width: 116%; */
					}
					input:checked {
						@media (max-width: 768px) {
							/* color: #20256C; */
							background-color: yellow;
						}
					}
					.ui {
						width: 100px;
						label {
							margin-top: 10px;
							margin-left: 10px;
							color: #5a646e;
							font-weight: 500;
							font-size: 12px;
						}
					}
				}
			}
		}
	}

	.price-card {
		margin-top: 25px;
		@media (max-width: 768px) {
			height: 253px;
		}
		h2 {
			color: #2b63fc;
			margin-top: 15px;
			font-family: var(--Roboto-font-family);
			font-size: 0.72rem;
			margin-bottom: 20px;
		}
		.price-inputs-card {
			@media (max-width: 768px) {
				height: 115px;
			}
			display: flex;
			flex-direction: row;
			align-items: flex-start;

			.limit-container {
				width: 38%;
				display: flex;
				flex-direction: row;
				display: flex;
				column-gap: 15px;
				@media (max-width: 768px) {
					flex-direction: column;
					width: 100%;
				}

				#priceInput {
					width: calc(206px - 17px);
					text-align: center;
					height: 70px !important;
					font-size: 22px;
					color: #20256c;
					@media (max-width: 768px) {
						padding: 0;
						width: 100%;
						color: #20256c;
					}
				}
				.checkbox {
					margin-top: 5px;
					display: flex;
					flex-direction: row;
					@media (max-width: 768px) {
						/* width: 116%; */
					}
					input:checked {
						@media (max-width: 768px) {
							/* color: #20256C; */
							background-color: yellow;
						}
					}
					.ui {
						width: 100px;
						label {
							margin-top: 10px;
							margin-left: 10px;
							color: #5a646e;
							font-weight: 500;
							font-size: 12px;
						}
					}
				}
			}
		}
	}

	.shipping-options-container {
		margin-top: 25px;
		h2 {
			color: #2b63fc;
			font-family: var(--Roboto-font-family);
			font-size: 0.72rem;
			margin-bottom: 20px;
		}
		.shipping-options-card {
			height: max-content;
			display: flex;
			flex-direction: column;

			align-items: flex-start;
			.container {
				display: flex;
				flex-direction: column;
				border-radius: 8px;

				width: max-content;
				/* padding: 20px 5px; */
				row-gap: 20px;
				@media (max-width: 768px) {
					display: flex;
					width: 100%;
				}
				.shipping-option {
					height: max-content;
					display: flex;
					flex-direction: row;
					align-items: center !important;

					width: 100%;

					align-items: flex-start;
					padding: 0px 18px;

					input {
						margin-right: 10px;
						height: 40px !important;
					}

					span {
						color: #000;
						font-family: var(--Roboto-font-family);
						font-size: 0.6rem;
						font-weight: 400;
					}
				}
			}

			.add-new-address {
				height: 40px;
				background: #f2f2f2 0% 0% no-repeat padding-box;
				width: 100%;
				padding: 0px 18px;
				border: none;
				color: #ababab;
				font-family: var(--Roboto-font-family);
				font-size: 0.6rem;
				font-weight: bold;
				margin-top: 25px;
			}
		}
	}
`;
export const SectionContainerMobile = styled.div`
	background: #ffffff;
	border-radius: 0px 8px 8px 8px;
	padding: 24px 34px 48px 36px;
	margin-bottom: 22px;
	display: none;
	@media (max-width: 768px) {
		display: block;
	}
`;

export const SectionContainer = styled.div`
	background: #ffffff;
	border-radius: 0px 8px 8px 8px;
	padding: 24px 34px 48px 36px;
	margin-bottom: 22px;
`;

export const HintContainer = styled.div`
	position: relative;
	.hint-text {
		z-index: 2;
		background-color: #333;
		border-radius: 8px;
		width: max-content;
		position: absolute;
		top: 40px;
		left: 0;
		padding: 4px 15px;
		color: #fff;
		font-size: 13px !important;
		font-family: var(--Roboto-font-family);
		font-weight: normal;
		user-select: none;

		.hint-button {
			border: 1px solid #111 !important;
			background-color: #888 !important;
			border-radius: 8px !important;
			color: #fff !important;
			width: 40px !important;
			height: 33px !important;
			margin-left: 10px !important;
		}
	}
`;

export const ProductType = styled.div`
	margin-top: 30px;
	margin-bottom: 25px;
	button {
		border: none !important;
		border-radius: 8px !important;
		width: 227px !important;
		height: 43px !important;
		font-family: var(--Roboto-font-family) !important;
		font-size: 14px !important;
		background: #ffffff !important;
		border: 1px solid #2b63fc !important;
		color: #2b63fc !important;
		@media (max-width: 768px) {
			padding: 0 10px;
			width: 50% !important;
			font-size: 12px;
		}
	}

	.frist {
		border-radius: 8px 0 0 8px !important;
	}
	.second {
		border-radius: 0 8px 8px 0 !important;
	}
	.active {
		background: #2b63fc !important;
		color: #ffffff !important;
	}
`;
