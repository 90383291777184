import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import { Container} from './styles';

const RegisterSellerModal: React.ForwardRefRenderFunction<{handleOpenModal: () => void}> = (
	{ ...props },
	ref
) => {
	const [modalIsOpen, setIsOpen] = useState(false);
	const history = useHistory();

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			transform: 'translate(-50%, -50%)',
			padding: '0',
			display: 'flex',
			backgroundColor: 'transparent',
			overflow: 'visible',
			zIndex: 3,
      border: 'none',
		},
		overlay: {
			backgroundColor: 'rgba(0,0,0, 0.8)',
			zIndex: 3,
		},

		h2: {
			color: '#3B3D4A',
		},
	};
	function closeModal() {
		setIsOpen(false);
	}
	function handleOpenModal() {
		setIsOpen(true);
	}

	useImperativeHandle(ref, () => {
		return {
			handleOpenModal,
		};
	});

  const buttonClick = () => {
    closeModal();
    history.push('/seller');
  };

	return (
		<Modal
			isOpen={modalIsOpen}
			onRequestClose={closeModal}
			style={customStyles}
		>
      <Container>
        <h1>Solicitação enviada</h1>
        <span>Nossa equipe avaliará sua solicitação<br /> e em breve entrará em contato</span>
        <button onClick={buttonClick}>Voltar para Home</button>
      </Container>
		</Modal>
	);
};

export default forwardRef(RegisterSellerModal);
