import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { insertProductQuestion } from '../../../../actions/ProductActions';
import api from '../../../../api';
import UserAccount from '../../../../interfaces/UserAccount';
import { AppState } from '../../../../store';
import { IProductQuestions } from '../ProductAreas';
import QuestionItem from './QuestionItem/QuestionItem';

import {
	Container,
	MakeQuestionCard,
	QuestionsContainer,
	DefaultColumn,
	DefaultRow,
	DefualtRowMobile,
	DefaultRowSubimit,
	ShowMore,
} from './styles';

type Props = {
	questions?: IProductQuestions[];
	product_id: string;
};

interface FormInputs {
	description: string;
}
const QuestionsCard: React.FC<Props> = ({ questions, product_id }) => {
	const { register, handleSubmit, errors } = useForm<FormInputs>();
	const [descriptionContent, setDescriptionContent] = useState<string>('');
	const dispatch = useDispatch();
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [questionNumber, SetQuestionNumber] = useState<number>(3);

	const onSubmit = async (data: any) => {
		api.post('/create-question', {
			question_description: data.description,
			product_id: product_id,
		}).then((response: any) => {
			dispatch(insertProductQuestion(response.data.response));
			setDescriptionContent('');
		});
	};
	return (
		<>
			{user && (
				<Container>
					<MakeQuestionCard onSubmit={handleSubmit(onSubmit)}>
						<h3>
							{questions!.length > 0
								? 'Ainda tem dúvidas?'
								: 'Tem dúvidas?'}{' '}
							Faça uma pergunta ao vendedor
						</h3>
						<DefaultRow>
							<textarea
								name="description"
								id="descrip"
								className="decription-textarea"
								ref={register({ required: true })}
								placeholder="Digite sua pergunta"
								onChange={(e) =>
									setDescriptionContent(e.target.value)
								}
								value={descriptionContent}
							></textarea>
						</DefaultRow>
						<DefualtRowMobile>
							<button type="submit">Enviar Pergunta</button>
							<p>
								Após enviada, a sua pergunta ficara visível a
								todos que visualizarem o produto
							</p>
						</DefualtRowMobile>
						<DefaultRowSubimit>
							<button type="submit">Enviar Pergunta</button>
							<p>
								Após enviada, a sua pergunta ficara visível a
								todos que visualizarem o produto
							</p>
						</DefaultRowSubimit>
					</MakeQuestionCard>
					{questions!.length > 0 && (
						<>
							<h2>Perguntas</h2>
							<QuestionsContainer>
								{questions?.slice(0,questionNumber).sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()).map(
									(question: IProductQuestions) => (
										<>
											<QuestionItem
												question={question}
											>
												{question.question_description}
											</QuestionItem>
										</>
									)
								)}
								{questions && 
									questions!.length > questionNumber && (
									<ShowMore onClick={() => SetQuestionNumber(questionNumber + 3)}>
										Ver mais perguntas
									</ShowMore>
								)}
							</QuestionsContainer>
						</>
					)}
				</Container>
			)}
		</>
	);
};

export default QuestionsCard;
