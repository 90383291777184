import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Modal from 'react-modal';
import { CardAndFormContainer, Container } from './styles';
import { useForm } from 'react-hook-form';
import InputWithMask from '../../../Default/InputWithMask/InputWithMask';
import config from '../../../../config';
import api from '../../../../api';
import { useDispatch } from 'react-redux';
import { insertUserCard } from '../../../../actions/UserActions';

import Cards, { Focused } from 'react-credit-cards';
import { useEffect } from 'react';
import Spinner from '../../../Loader/Spinner';
import { cpf } from 'cpf-cnpj-validator';
export interface AddPaymentModalHandle {
	handleOpenModal: () => void;
}
export interface FormInputs {
	card_number: string;
	expire_date: string;
	verification_code: string;
	card_holder_name: string;
	card_holder_document: string;
}

const AddPaymentModal: React.ForwardRefRenderFunction<AddPaymentModalHandle> = (
	{},
	ref
) => {
	const [modalIsOpen, setIsOpen] = useState(false);
	const { register, handleSubmit, errors } = useForm<FormInputs>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		shouldFocusError: true,
	});
	const dispatch = useDispatch();
	const [cardCvc, setCardCvc] = useState<string>('');
	const [cardExpiry, setCardExpiry] = useState<string>('');
	const [cardNumber, setCardNumber] = useState<string>('');
	const [cardFocus, setCardFocus] = useState<Focused>();
	const [cardName, setCardName] = useState<string>('');
	const [cardFieldErrors, setCardFieldErrors] = useState({
		card_number_is_valid: true,
		expire_date_is_valid: true,
		verification_code_is_valid: true,
		card_holder_name_is_valid: true,
		card_holder_document_is_valid: true,
	});
	const [loading, setLoading] = useState(false);
	const [ebanxToken, setEbanxToken] = useState<any>();
	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			width: '70%',
			maxWidth: '1200px',
			transform: 'translate(-50%, -50%)',
			padding: '32px',
		},
		overlay: {
			backgroundColor: 'rgba(0,0,0, 0.8)',
		},

		h2: {
			color: '#3B3D4A',
		},
	};

	function closeModal() {
		setIsOpen(false);
	}
	function handleOpenModal() {
		setIsOpen(true);
	}
	function getEbanxToken() {
		if (ebanxToken) {
			return ebanxToken;
		} else {
			let checkout = new DirectCheckout(config.cardPublicToken, false);
			setEbanxToken(checkout);
			return checkout;
		}
	}
	function onSubmit(data: FormInputs) {
		if (
			cardFieldErrors.card_number_is_valid &&
			cardFieldErrors.expire_date_is_valid &&
			cardFieldErrors.verification_code_is_valid
		) {
			setLoading(true);
			var cardData = {
				cardNumber: data.card_number.replaceAll(' ', ''),
				holderName: data.card_holder_name,
				securityCode: data.verification_code,
				expirationMonth: data.expire_date.substring(0, 2),
				expirationYear: data.expire_date.substring(
					3,
					data.expire_date.length
				),
			};
			let token = getEbanxToken();

			token.getCardHash(
				cardData,
				function (cardHash: any) {
					api.post('/create-credit-card', {
						card_hash: cardHash,
					})
						.then((res) => {
							dispatch(insertUserCard(res.data.createdCard));
							setIsOpen(false);
							setCardCvc('');
							setCardName('');
							setCardExpiry('');
							setCardNumber('');
							setLoading(false);
						})
						.catch((err) => {});
				},
				function (error: any) {
					console.error(error);
				}
			);
		}
	}

	function showFieldError(is_valid: boolean, is_valid_at_form: any) {
		if (!is_valid || is_valid_at_form) {
			return 'solid 1px red';
		} else {
			return 'solid 1px #8a99a8';
		}
	}
	function verifyFields(
		fieldType:
			| 'cardNumber'
			| 'expiryDate'
			| 'verificationCode'
			| 'cardHolderName'
			| 'cardHolderDocument',
		value: string
	) {
		switch (fieldType) {
			case 'cardNumber':
				setCardNumber(value);

				if (value.length === 19) {
					let token = getEbanxToken();

					setCardFieldErrors({
						...cardFieldErrors,
						card_number_is_valid: token.isValidCardNumber(
							value.replaceAll(' ', '')
						),
					});
				}
				break;

			case 'expiryDate':
				setCardExpiry(value);

				if (value.length === 7) {
					let token = getEbanxToken();

					setCardFieldErrors({
						...cardFieldErrors,
						expire_date_is_valid: token.isValidExpireDate(
							value.substring(0, 2),
							value.substring(3, value.length)
						),
					});
				}
				break;
			case 'verificationCode':
				setCardCvc(value);

				if (value.length === 3) {
					let token = getEbanxToken();

					setCardFieldErrors({
						...cardFieldErrors,
						verification_code_is_valid: token.isValidSecurityCode(
							cardNumber,
							value
						),
					});
				}
				break;
			case 'cardHolderName':
				setCardName(value);
				break;
			case 'cardHolderDocument':
				if (value.length === 14) {
					setCardFieldErrors({
						...cardFieldErrors,
						card_holder_document_is_valid: cpf.isValid(
							value.replaceAll('.', '').replace('-', '')
						),
					});
				}
				break;

			default:
				break;
		}
	}

	useImperativeHandle(ref, () => {
		return {
			handleOpenModal,
		};
	});

	return (
		<Modal
			isOpen={modalIsOpen}
			onRequestClose={closeModal}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<Container>
				<div className="title">
					<h6>Adicionar nova forma de pagamento</h6>
				</div>
				<CardAndFormContainer>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div
							className="first-row"
							style={{ display: 'flex', marginBottom: '30px' }}
						>
							<div className="input-and-error">
								<InputWithMask
									type="text"
									name="card_number"
									ref={register({
										required: true,
										minLength: {
											value: 16,
											message: 'Cartão inválido',
										},
									})}
									onChange={(e: any) =>
										verifyFields(
											'cardNumber',
											e.target.value
										)
									}
									maxLength={19}
									mask="card_number"
									onFocus={() => setCardFocus('number')}
									style={{
										width: '311px',
										marginRight: '15px',
										padding: '0 10px 0 10px',
										border: `${showFieldError(
											cardFieldErrors.card_number_is_valid,
											errors.card_number
										)}`,
									}}
									placeholder="XXXX XXXX XXXX XXXX"
								/>
								{errors.card_number && (
									<span className="field-error">
										{errors.card_number?.message}
									</span>
								)}
								{!cardFieldErrors.card_number_is_valid &&
									!errors.card_number && (
										<span className="field-validation-error">
											{'Cartão inválido'}
										</span>
									)}
							</div>

							{/* <img
								src={cardBrand}
								style={{ marginRight: '20px' }}
								alt=""
							/> */}
							<div className="input-and-error">
								<InputWithMask
									type="text"
									name="expire_date"
									mask="card_expiring_date"
									onChange={(e: any) =>
										verifyFields(
											'expiryDate',
											e.target.value
										)
									}
									onFocus={() => setCardFocus('expiry')}
									ref={register({
										required: true,
										maxLength: 7,
										minLength: {
											value: 7,
											message:
												'Formato inválido ex: 12/2022',
										},
									})}
									style={{
										width: '87px',
										padding: '0 10px 0 10px',
										marginRight: '19px',
										border: `${showFieldError(
											cardFieldErrors.expire_date_is_valid,
											errors.expire_date
										)}`,
									}}
									maxLength={7}
									placeholder="MM/AAAA"
								/>
								{errors.expire_date && (
									<span className="field-error">
										{errors.expire_date.message}
									</span>
								)}
								{!cardFieldErrors.expire_date_is_valid &&
									!errors.card_number && (
										<span className="field-error">
											{'Data inválida'}
										</span>
									)}
							</div>

							<div className="input-and-error">
								<InputWithMask
									type="text"
									name="verification_code"
									onChange={(e: any) =>
										verifyFields(
											'verificationCode',
											e.target.value
										)
									}
									onFocus={() => setCardFocus('cvc')}
									maxLength={3}
									ref={register({
										required: true,
										minLength: {
											value: 3,
											message: 'Formato inválido',
										},
									})}
									style={{
										width: '87px',
										padding: '0 10px 0 10px',
										border: `${showFieldError(
											cardFieldErrors.verification_code_is_valid,
											errors.verification_code
										)}`,
									}}
									placeholder="CVV"
								/>
								{errors.verification_code && (
									<span className="field-error">
										{errors.verification_code.message}
									</span>
								)}
								{!cardFieldErrors.verification_code_is_valid &&
									!errors.verification_code && (
										<span className="field-error">
											{'Valor inválido'}
										</span>
									)}
							</div>
						</div>
						<div
							className="second-row"
							style={{ display: 'flex', marginBottom: '30px' }}
						>
							<InputWithMask
								type="text"
								name="card_holder_name"
								onChange={(e: any) =>
									verifyFields(
										'cardHolderName',
										e.target.value
									)
								}
								onFocus={() => setCardFocus('name')}
								style={{
									width: '363px',
									marginRight: '27px',
									padding: '0 10px 0 10px',
								}}
								placeholder="Nome do Titular"
								ref={register({
									required: true,
								})}
								maxLength={100}
							/>
							<div className="input-and-error">
								<InputWithMask
									type="text"
									name="card_holder_document"
									mask="document_cpf"
									maxLength={14}
									onChange={(e: any) =>
										verifyFields(
											'cardHolderDocument',
											e.target.value
										)
									}
									ref={register({
										required: true,
										maxLength: 14,
										minLength: {
											value: 14,
											message: 'CPF inválido',
										},
									})}
									style={{
										width: '213px',
										padding: '0 10px 0 10px',
										border: `${showFieldError(
											cardFieldErrors.card_holder_document_is_valid,
											errors.card_holder_document
										)}`,
									}}
									placeholder="CPF do titular"
								/>
								{errors.card_holder_document && (
									<span className="field-error">
										{errors.card_holder_document.message}
									</span>
								)}
								{!cardFieldErrors.card_holder_document_is_valid &&
									!errors.card_holder_document && (
										<span className="field-error">
											{'CPF inválido'}
										</span>
									)}
							</div>
						</div>
						<div className="button">
							<button
								type="submit"
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									opacity: `${loading ? '0.6' : '1'}`,
								}}
								disabled={loading}
							>
								{!loading ? (
									'Adicionar nova forma de pagamento'
								) : (
									<>
										<Spinner />
									</>
								)}
							</button>
						</div>
					</form>
					<Cards
						cvc={cardCvc}
						expiry={cardExpiry}
						focused={cardFocus}
						name={cardName}
						number={cardNumber}
					/>
				</CardAndFormContainer>
			</Container>
		</Modal>
	);
};

export default forwardRef(AddPaymentModal);
