import React, { useEffect, useState } from 'react';
import { Container, DailyPromotion } from './styles';
import { insertProducts } from '../../../actions/ProductsList';
import { AreaTypes, ProductsList } from '../../../interfaces/ProductsList';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store';
import ProductCard from '../../Default/ProductCard/productCard';
import { productExibitionRules } from '../../../utils/productsExibitionRules';
import api from '../../../api';
import { Product } from '../../../interfaces/Product';
import { useHistory } from 'react-router-dom';

type ProductsSponsored = {
	id: string;
	product_id: string;
	products: Product;
};

const PatronizeProduct: React.FC = () => {
	const history = useHistory();

	const [productsSponsored, setProductsSponsored] = useState<
		ProductsSponsored[]
	>([]);

	const [allProducts, setAllProducts] = useState<Product[]>([]);

	useEffect(() => {
		api.post('/list-products').then((response) => {
			setAllProducts(response.data.data.response);
		});
	}, []);

	useEffect(() => {
		api.get('/all-product-sponsored').then((response) => {
			setProductsSponsored(response.data.productsSponsored);
		});
	}, []);

	return (
		<>
			<Container>
				<div className="text-button">
					<p>Produtos patrocinados em página de produto</p>
					<span onClick={() => history.push('/seller')}>Ver no site</span>
				</div>
				<DailyPromotion>
					<div className="daily-promotions-container">
						{allProducts.map((product) => (
							<>
								{productsSponsored.find(
									(productS) =>
										productS.product_id === product.id
								) && (
									<ProductCard
										product={product}
										isReplace={true}
									/>
								)}
							</>
						))}
					</div>
				</DailyPromotion>
			</Container>
		</>
	);
};
export default PatronizeProduct;
