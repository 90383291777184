import styled from 'styled-components';

export const Container = styled.div`
    width: 61%;
    max-width: 26%;
	.buttonMobile {
		@media (max-width: 768px) {
			display: none;
		}
	}
	.buttonAndShippingMobile {
		@media (min-width: 768px) {
			display: none;
		}
		position: fixed;
		bottom: 0;
		width: 100%;
		.price {
			display: flex;
			align-items: end;
		}
		.total-value {
			display: flex;
			flex-direction: row;
			align-items: flex-end;
			flex: 1;
			width: 100%;
			margin-bottom: 15px;
			margin-top: auto;
			color: #8f8f8f;
			font-weight: normal;
			font-size: 16px;
			font-family: var(--Roboto-font-family);

			h3 {
				white-space: pre;
				display: block;
				color: #002793;
				font-size: 24px;
				font-weight: normal;
				font-family: var(--Roboto-font-family);
				margin-left: 14px;
				&.small-text {
					margin-left: 0;
					font-size: 14px;
					margin-top: 5px;
				}
			}
		}
		.containerShippingButton {
			background: white;
			margin-left: -5%;
			padding: 20px;
			border-top: 1px solid #e8e8e8;

			@media (min-width: 768px) {
				display: none;
			}
		}
		.shipment-value {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			flex: 1;
			width: 100%;

			.infos {
				display: flex;
				flex-direction: row;
				flex: 1;
				width: 100%;
				justify-content: space-between;
				padding-right: 5%;
				/* border-bottom: 1px solid #c5ced7; */
				.shipping-adrress {
					width: 70%;
					padding-bottom: 14px;
					h4 {
						font-size: 14px;
						font-family: var(--Roboto-font-family);
						color: #5a646e;
						font-weight: normal;
						margin-top: 6px;

						&.mid-text {
							color: #8a99a8;
						}
						&.bottom-text {
							margin-top: 6px;
							font-size: 16px;
						}

						span {
							font-size: 20px;
							color: #00b06a;
							font-weight: normal;
						}
					}
				}
			}
		}
	}
	.container {
		flex: 1;
		background-color: #ffffff;
		border-radius: 12px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 16px;
		padding: 24px;
		@media (max-width: 768px) {
			display: none;
		}
		.shipment-value {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			flex: 1;
			width: 100%;

			.infos {
				display: flex;
				flex-direction: column;
				flex: 1;
				padding-bottom: 16px;
				margin-bottom: 24px;
				width: 100%;
				border-bottom: 1px solid #c5ced7;
				h4 {
					font-size: 14px;
					font-family: var(--Roboto-font-family);
					color: #5a646e;
					font-weight: normal;
					margin-bottom: 8px;
					&.mid-text {
						color: #8a99a8;
						margin-bottom: 16px;
					}
					&.bottom-text {
						font-size: 16px;
					}

					span {
						font-size: 20px;
						color: #00b06a;
						font-weight: normal;
					}
				}
			}
		}

		.total-value {
			display: flex;
			flex-direction: row;
			align-items: flex-end;
			flex: 1;
			width: 100%;

			color: #8f8f8f;
			font-weight: normal;
			font-size: 16px;
			font-family: var(--Roboto-font-family);

			h3 {
				color: #002793;
				font-size: 24px;
				font-weight: normal;
				font-family: var(--Roboto-font-family);
				margin-left: 14px;
				&.small-text {
					margin-left: 0;
					font-size: 14px;
					margin-top: 5px;
				}
			}
		}
	}
	button {
		width: 100%;
		height: 40px;

		font-size: min(16px, 1.3vw);
		font-family: var(--Roboto-font-family);
		font-weight: medium;
		color: white;
		border: none;
		background: #02c384;
		border-radius: 8px;
		@media (max-width: 768px) {
			font-size: 16px;
			width: 80%;
			height: 48px;
		}

		&:hover {
			cursor: pointer;
		}
	}
`;

export const ButtonMobile = styled.button`
	font-family: var(--Roboto-font-family);
	font-weight: medium;
	color: white;
	border: none;
	background: #02c384;
	border-radius: 8px;
	font-size: 16px;
	height: 48px;
	width: 95% !important;

	&:hover {
		cursor: pointer;
	}
`;
