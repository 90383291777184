import React from 'react';
import HeaderAdmin from '../../components/HeaderAdmin/HeaderAdmin';
import RegisteredProduct from '../../components/RegisteredProduct/RegisteredProduct';
import { Container } from './styles';

const RegisteredProductPage: React.FC = () => {
	return (
		<Container>
			<HeaderAdmin />
			<RegisteredProduct />
		</Container>
	);
};
export default RegisteredProductPage;
