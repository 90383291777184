import React from 'react';
import ShippingMethodCart from '../../../components/Cart/ShippingMethodCart/ShippingMethodCart';

import Header from '../../../components/Default/header';

import { Container } from './styles';

const ShippingMethodPage: React.FC = () => {
	return (
		<Container>
			<Header />
			<ShippingMethodCart />
		</Container>
	);
};

export default ShippingMethodPage;
