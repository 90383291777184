import styled from 'styled-components';

export const Container = styled.div`
	/* width: 100%; */
	/* display: flex;
	flex: 1;
	max-width: 245px;

	height: 575px; */ /* 
	grid-template-columns: 1.5fr 1fr;
	grid-template-rows: 0.5fr 4fr 1fr; */
	/* column-gap: 45px;
	row-gap: 8px;

	@media (max-width: 1000px) {
		flex-direction: column;
	} */
`;

export const InfosContainer = styled.div`
	/* 
	grid-row-start: 2;
	grid-row-end: 2;
	grid-column-start: 2;
	grid-column-end: 2; */
	padding-top: calc(40px - 10px);

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	@media (min-width: 768px) {
		display: none;
	}

	.action-buttons {
		display: flex;
		flex-direction: column;
		row-gap: 10px;

		button {
			height: 45px;
			width: 100%;
			border: none;
			border-radius: 8px;
			background: #0038d2;
			color: white;
			font-family: var(--Roboto-font-family);
			font-weight: bold;
			font-size: 16px;
			margin-bottom: 18px;
			&:hover {
				cursor: pointer;
			}
		}
		.cart-button {
			margin: 16px 0 29px 0;
			width: 100%;
			height: 40px;
			background: #0038d2;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			flex: 1;
			border: 1px solid #0038d2;
			border-radius: 8px;
			cursor: pointer;

			&.not-vailable {
				border: 1px solid #707070;
				background: #707070;
				cursor: default;
			}
			.cart-icon {
				width: 27px;
				height: 40px;
				margin-right: 20px;
				fill: #fff;
				.a {
					fill: #fff;
				}
			}
			span {
				font-family: var(--Roboto-font-family);
				font-weight: medium;
				font-size: 16px;
				color: #fff;
			}
		}
		.buyer {
			background: transparent;
			border: 1px solid #004d93;

			span {
				color: #004d93;
			}
			.cart-icon {
				.a {
					fill: #004d93;
					opacity: 1;
				}
			}
		}
		.link {
			align-self: flex-end;

			justify-content: flex-end;
			width: 100%;
			display: flex;

			color: #6699cc;
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			font-size: min(14px, 1.6vw);
		}

		.delivery-informations-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			flex: 1;
			height: 64px;

			.delivery-icon {
				.a {
					fill: #2b63fc;
				}
			}

			.delivery-informations-card {
				display: flex;
				flex-direction: column;
				margin-left: 16px;

				h4 {
					color: #2b63fc;
					font-family: var(--Roboto-font-family);
					font-size: 14px;
					font-weight: normal;

					&.innactive {
						color: #353a40;
					}
				}
			}
		}

		.seller-informations-container {
			display: flex;
			flex-direction: column;
			flex: 1;

			border: 1px solid #8a99a8;
			border-radius: 8px;
			padding: 16px;
			margin-bottom: 24px;

			h4 {
				color: #002793;
				font-family: var(--Roboto-font-family);
				font-size: 18px;
				font-weight: bold;
				margin-bottom: 8px;
			}

			.seller-avaliation {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				height: 20px;
				margin: 10px 0 18px 0;

				svg {
					font-size: 42px;
					color: #f49d37;
				}
			}

			span {
				font-family: var(--Roboto-font-family);
				font-size: 14px;
				font-weight: normal;
				color: #8a99a8;
				margin-bottom: 4px;
			}

			}

			span {
				font-family: var(--Roboto-font-family);
				font-size: 14px;
				font-weight: normal;
				color: #8a99a8;
				margin-bottom: 4px;
			}
		}

		.cart-button-container {
			display: flex;
			flex-direction: column;

			.delvirey-span {
				font-family: var(--Roboto-font-family);
				font-size: 14px;
				font-weight: normal;
				display: block;
				color: #5a646e;
				margin-bottom: 16px;
			}

			.stock-container {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				flex: 1;
				@media (max-width: 768px) {
					font-weight: 600;
				}
				h3 {
					font-family: var(--Roboto-font-family);
					font-size: 16px;
					font-weight: bold;
					color: #353a40;
					margin-bottom: 16px;
					&.not-vailable {
						color: #c9182a !important;
					}
				}
				span {
					font-family: var(--Roboto-font-family);
					font-size: 16px;
					font-weight: 300;
					color: #5a646e;
					margin-bottom: 4px;
					select {
						font-family: var(--Roboto-font-family);
						font-size: 16px;
						font-weight: normal;
						color: #353a40;
						margin-left: 4px;
						margin-bottom: 4px;
						border: 0;
						outline: none;
					}
				}
				.colors-div {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					.color-option {
						width: 40px;
						height: 40px;
						padding: 2px;
						border-radius: 8px;
						border: 1px solid #8a99a8;
						margin-right: 13px;
						margin-bottom: 5px;
						margin-top: 7px;
						transition: all 300ms;
						cursor: pointer;

						img {
							width: 40px;
							height: 40px;
							object-fit: cover;
							border-radius: 8px;
						}
					}
					.color-option:hover {
						border: 1px solid #0038d2;
					}
					.active-color {
						border: 1px solid #0038d2;
						background-color: #f7fbff;
					}
				}
			}

			input {
				border: 1px solid #8a99a8;
				border-radius: 4px;
				margin-bottom: 24px;
				padding: 11px 20px;
				font-family: var(--Roboto-font-family);
				font-size: 14px;
				font-weight: normal;
				color: #8a99a8;
				::placeholder {
					font-style: italic;
					color: #8a99a8;
				}
			}
			@keyframes seta{
				from{
					right: 3%;
				}
				to{
					right: 6%;
				}
			}
			.size-div {
				display: flex;
				flex-direction: row;
				position: relative;
				height: 70px;
				width: 90%;
				overflow-x: auto;
				align-items: center;
				margin-bottom: 20px;
				svg {
					cursor: pointer;
				}
				.size-option {
					width: 43px!important;
					min-width: 43px;
					height: 40px;
					border: 1px solid #8a99a8;
					border-radius: 8px;
					color: #8a99a8;
					font-size: 16px;
					font-family: var(--Roboto-font-family);
					display: flex;
					justify-content: center;
					align-items: center;
					margin-right: 6px;
					transition: all 300ms;
					cursor: pointer;
				}
				.size-option:hover {
					border: 1px solid #0038d2;
				}
				.active-size {
					border: 1px solid #0038d2;
					background-color: #f7fbff;
				}
			}
		}

		.innactive-info-container {
			font-size: 16px;
			font-weight: bold;
			font-family: var(--Roboto-font-family);
			color: #707070;
			button {
				margin-top: 14px;
				flex: 1;
				border-radius: 8px;
				color: #ffffff;
				background: #707070;
			}
		}
	}
`;
