import { Button } from './../../Default/AddButton/styles';
import styled from 'styled-components';

export const Container = styled.div`
	min-height: 100vh;
	width: 100%;
	margin-top: 51px;
	text-align: center;
	font-family: 'Roboto';
	background: #f5f5f5;
	.category-text {
		color: #353a40;
		font-size: 26px;
		margin-top: 40px;
		margin-bottom: 31px;
	}
	.container-cards {
		display: flex;
		flex-direction: column;
		background: white;
		height: 480px;
		width: 85%;
		margin: auto;
		text-align: start;
		padding-left: 37px;
		padding-top: 40px;
		p {
			font-size: 16px;
			margin-bottom: 41px;
		}
		.categories-card {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
			grid-row-gap: 16px;
			margin-left: 53px;
		}
		.category {
			height: 152px;
			width: 152px;
			box-shadow: 0px 3px 6px #00000029;
			border-radius: 4px;
			background: #f5f5f5;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}
		.more-category {
			height: 152px;
			width: 152px;
			border: 2px solid #2b63fc;
			border-radius: 4px;
			background: white;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			/* margin: 0px 50px; */
			cursor: pointer;
			span {
				color: #2b63fc;
				font-weight: 500;
			}
		}
		img {
			width: 88px;
			height: 88px;
		}
		span {
			font-size: 12px;
			font-family: 'Roboto';
			color: #353a40;
		}
		/* .category {
			display: flex;
			justify-content: center;
			flex-direction: column;
			box-sizing: border-box;
		} */
	}
`;

export const ModalContainer = styled.div`
	width: 440px;
	height: 340px;
	padding: 26px;
	font-family: 'Roboto';
	font-size: 19px;
	.cards {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		h1 {
			font-size: 19px;
			color: #2b63fc;
			margin-bottom: 44px;
		}
		p {
			font-weight: 400;
			color: #5a646e;
			margin-bottom: 32px;
		}
		strong {
			color: #5a646e;
			font-weight: 500;
		}
	}
`;
