import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 314px;

	font-family: var(--Roboto-font-family);
	@media (max-width: 768px) {
		background: white;
		padding: 30px;
	}

	h4 {
		display: flex;
		align-items: center;
		color: #2579fc;
		font-weight: 500;
		font-family: var(--Roboto-font-family);
	}
	.react-icons {
		vertical-align: middle;
		height: 20px;
		width: 20px;
		margin-right: 20px;
	}

	.account-status {
		font-size: min(18px, 3vw);
		color: #5a646e;

		font-weight: 500;
		font-family: var(--Roboto-font-family);
		margin-top: 10px;
	}
`;

export const GeneralInfosContainer = styled.div`
	padding: 24px;
	border: 1px solid #c5ced7;
	border-radius: 8px;
	font-size: 16px;

	@media (min-width: 768px) {
		background: white;
		border: none;
	}
	.infosUser {
		@media (min-width: 768px) {
			display: flex;
			column-gap: 60px;
		}
	}
	.infosRow {
		display: flex;
		/* flex-direction: column; */
		/* max-width: 300px; */
	}
	.top-side {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
	}
	h4 {
		display: flex;
		align-items: center;
		color: #2579fc;
		font-weight: 500;
		font-family: var(--Roboto-font-family);
	}
	label {
		color: #8a99a8;
		font-weight: 600;
	}
	span {
		color: #5a646e;
		margin-left: 15px;
		margin-right: 20px;
	}
`;

export const AddressContainer = styled.div`
	padding: 24px 24px 0 24px;
	border: 1px solid #c5ced7;
	border-radius: 8px;
	font-size: 16px;
	margin-top: 32px;

	@media (min-width: 768px) {
		background: white;
		border: none;
	}
`;
export const Informations = styled.div`
	width: 100%;
	height: max-content;

	display: flex;
	flex-direction: column;
	.Overlay {
		background-color: black;
	}

	.container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		padding-top: 35px;

		@media (min-width: 768px) {
			display: grid;
			grid-template-columns: 48% 48%;
			column-gap: 19px;
			row-gap: 16px;
		}

		.Overlay {
			background-color: black;
		}

		.address-card {
			display: flex;
			flex-direction: column;
			border: solid 1px #e1e1e1;
			border-radius: 8px;
			margin-bottom: 20px;
			width: 450px;
			padding: 16px;
			@media (min-width: 768px) {
				width: calc(100% - 32px);
			}
			:hover {
				border: 1px solid #2b63fc;
			}

			.first-row {
				display: flex;
				margin-bottom: 10px;
				justify-content: space-between;

				.infos-container {
					display: flex;
					flex-direction: column;
					label {
						font-family: var(--Roboto-font-family);
						font-weight: 700;
						font-size: 16px;
						color: #8a99a8;
					}
					span {
						font-family: var(--Roboto-font-family);
						font-weight: 400;
						font-size: min(12px, 0.7vw);

						color: #898989;
					}
				}
				.img-container {
					display: flex;
					justify-content: center;
					align-items: center;

					.options-menu {
						position: absolute;
						width: 0px;
						.options {
							position: relative;
							position: relative;
							top: 28px;
							right: 45px;
							span {
								background: #ffffff 0% 0% no-repeat padding-box;
								box-shadow: 0px 3px 6px #00000029;
								padding: 8px 15px;
								border-radius: 4px;

								color: #888888;
								font-family: var(--Poppins-font-family);
								padding: 5px;
								font-weight: 400;
								font-size: min(18px, 2vw);
							}
						}
					}
				}
			}

			.second-row {
				display: flex;
				flex-direction: column;
				.first-line {
					display: flex;
					flex-direction: column;
					span {
						color: #5a646e;
						margin-bottom: 5px;
					}
				}
				.second-line {
					display: flex;
					column-gap: 25px;
					margin-top: 10px;
					span {
						color: #2b63fc;
						cursor: pointer;
						@media (min-width: 786px) {
							text-decoration: underline;
						}
					}
				}
				.infos-container {
					display: flex;
					flex-direction: column;
					margin-right: 15px;

					label {
						font-family: var(--Roboto-font-family);
						font-weight: bold;
						font-size: min(9px, 0.6vw);

						color: #898989;
					}

					span {
						font-family: var(--Roboto-font-family);
						font-weight: 400;
						font-size: min(12px, 0.7vw);

						color: #898989;
					}
				}
			}
		}

		.third-row {
			display: flex;
			flex-direction: row;

			margin-bottom: 25px;
			justify-content: flex-start;
			align-self: flex-start;
			@media (max-width: 768px) {
				width: 100%;
			}

			.add-address {
				display: flex;
				flex-direction: column;
				justify-content: center;
				row-gap: 13px;
				padding: 3px;
				width: 484px;
				height: 164px;
				color: #8a99a8;
				border: 2px dashed #eaeef3;
				background: #fff;
				border-radius: 12px;
				font-family: var(--Roboto-font-family);
				font-weight: bold;
				font-size: 16px;
				margin-right: 0;
				text-align: center;
				@media (min-width: 768px) {
					width: 100%;
				}

				&:hover {
					cursor: pointer;
				}

				.plus-icon {
					font-size: 52px;
					line-height: 30px;
				}
			}
		}
	}
`;

export const PaymentInfosContainer = styled.div`
	margin-top: 32px;
	padding: 24px;
	border: 1px solid #c5ced7;
	border-radius: 8px;
	font-size: 16px;

	/* display: flex;
	flex-direction: column; */

	@media (min-width: 768px) {
		background: white;
		border: none;
	}

	> form {
		display: flex;
		flex-direction: column;

		> label {
			margin: 0;
		}

		> input {
			padding: 4px 12px;
			border: 1px solid #999;
			border-radius: 12px;
		}

		> button[type="submit"] {
			border: 0;
			border-radius: 12px;
			width: max-content;
			padding: 4px 24px;
			margin: 12px auto;
		}

		> p {
			text-align: center;
		}
	}

	.cards-container {
		@media (min-width: 768px) {
			display: grid;
			grid-template-columns: 48% 48%;
			column-gap: 19px;
			row-gap: 16px;
		}

		.add-card {
			display: flex;
			flex-direction: column;
			justify-content: center;
			row-gap: 13px;
			margin-top: 32px;
			font-family: var(--Roboto-font-family);
			padding: 3px;
			width: 100%;
			height: 104px;
			color: #8a99a8;
			border: 2px dashed #eaeef3;
			background: #fff;
			border-radius: 12px;
			font-weight: bold;
			font-size: 16px;
			margin-right: 25px;
			text-align: center;
			@media (max-width: 768px) {
				margin-right: 0;
			}
			.plus-icon {
				font-size: 52px;
				line-height: 30px;
			}
		}
	}
	h4 {
		display: flex;
		align-items: center;
		color: #2579fc;
		font-weight: 500;
		font-family: var(--Roboto-font-family);
	}
	.react-icons {
		vertical-align: middle;
		height: 22px;
		width: 22px;
		margin-right: 20px;
	}
`;
export const GoToEbanxButton = styled.button`
	padding: 20px;
	border-radius: 8px;
	font-size: 16px;
	margin-top: 32px;
	border: none;
	color: white;
	width: 250px;
	font-family: var(--Roboto-font-family);
	background: #2b63fc;
`;
