import React from 'react'
import { RiStarFill, RiStarLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { IProduct } from '../../../../Pages/ProductPage/ProductPage';

import { Container } from './styles'

interface IReview {
    review: {
		id: string;
		rating: number;
		review: string;
		user_accounts: {
			name: string;
			social_reason: string;
		}
        product: IProduct;
    }
}

const Review: React.FC<IReview> = ({
    review
}) => {
    return (
        <Container>
            <div className='starsRow'>
                {[...Array(5)].map((star, index) => (
                    index + 1 <= review.rating ?
                        <RiStarFill key={index} /> :
                        <RiStarLine key={index} />
                ))}
            </div>
            <Link
                to={`/product/${review.product.id}`}
            >
                {review.product.title}
            </Link>
            <p>{review.review}</p>
        </Container>
    )
}

export default Review;