import styled from 'styled-components';
import { Checkbox } from 'semantic-ui-react';

export const Container = styled.form`
	padding: 25px 16px 0 16px;
	font-family: var(--Roboto-font-family);
	width: var(--Default-page-alignment-vale);
	background: #f5f5f5;
	/* overflow: hidden; */
	textarea {
		resize: none;
	}
	h4 {
		font-size: 24px;
		font-weight: 300;
		margin-bottom: 25px;
		margin-left: 25px;
	}
	.color-menu {
		display: flex;
		flex-direction: row;
		color: #8a99a8;
		font-size: 16px;
		font-style: italic;
	}
	.color-option {
		margin-right: 10px;
		padding: 15px;
		cursor: pointer;
	}
	.active-variation {
		background: #ffffff 0% 0% no-repeat padding-box;
		border-radius: 8px 8px 0 0;
	}
	.new-variation {
		color: #00b06a;
	}
`;
export const ContainerForm = styled.div`
	//border: 1px solid #c5ced7;
	border-radius: 8px;
	padding: 30px 25px 25px 25px;
	background: #fff;
`;
export const InputsRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	
`;
export const RowItem = styled.div`
	display: flex;
	flex-direction: column;
	label {
		font-size: 16px;
		color: #2d327c;
		font-weight: 500;
		margin-bottom: 14px;
	}
	input {
		border: 1px solid #8a99a8;
		border-radius: 8px;
		height: 46px;
		padding-left: 15px;
		color: #5a646e;
		font: 400 16px Arial; //same font and weight of textarea.description

	}
	select {
		border: 1px solid #8a99a8;
		border-radius: 8px;
		height: 46px;
		padding-left: 15px;
		color: #5a646e;
		width: calc(215px);
		outline: none;
	}
	textarea {
		font-family: var(--Roboto-font-family);
		border: 1px solid #8a99a8;
		border-radius: 8px;

		&.description {
			color: #5a646e;
			font-size: 16px;
			padding: 21px 14px 18px 22px;
			margin-right: 19px;
			width: calc(551px - 36px);
			height: calc(164px - 39px);
		}
		&.title {
			padding: 12px 19px;
			width: calc(551px - 36px);
			height: calc(90px - 24px);
			font-size: 20px;
			color: #000;
		}
	}
	.price {
		font-size: 26px;
		text-align: center;
		padding-left: 45px;
		color: #2d327c;
		height: 90px;
		width: calc(311px - 45px);
		margin-right: 19px;
	}
	.promotion {
		font-size: 26px;
		text-align: center;
		padding: 0 30px 0 0;
		color: #2d327c;
		height: 90px;
		width: calc(215px - 30px);
	}
	#tec-description {
		margin-bottom: 11px;
	}
	.variation-name-container {
		display: flex;
		flex-direction: row;
		width: 700px;
		margin-bottom: 30px;
		column-gap: 20px;
		row-gap: 20px;
		.variation-input-container {
			display: flex;
			flex-direction: column;
		}
		.delete-button {
			margin-top: 35px;
			display: flex;
			align-items: center;
			cursor: pointer;
			svg {
				width: 25px;
				height: 25px;
			}
			span {
				text-decoration: underline;
				color: #c91729;
				font-size: 14px;
				margin-left: 5px;
			}
		}
	}
	.photo-container {
		display: flex;
		flex: row;
		column-gap: 20px;
		row-gap: 20px;
		flex-wrap: wrap;
		margin-bottom:30px;

		.photo {
			width: 137px;
			height: 137px;
		}
		.preview {
			display: flex;
			flex-direction: row;
			width: 147px;
			height: 137px;
			/* overflow-x: auto; */

			.img-preview {
				/* border: 1px solid #8a99a8;
				padding: 5px; */
				border-radius: 8px;
				height: 127px;
				width: 127px;
			}

			.close-icon-container {
				position: relative;
				.close-icon {
					position: absolute;
					left: 80%;
					bottom: 80%;
					height: 20px;
					background: white;
					width: 20px;
					border: 1px solid #444;
					border-radius: 50%;

					&:hover {
						cursor: pointer;
					}
				}
			}
		}
	}
	figure {
		width: 152px;
		height: 105px;
		display: flex;
		flex-direction: row;
		label {
			margin-right: 15px;
			width: 137px;
			height: 137px;
			background: #f6f6f6;
			font-size: 20px;
			color: #8a99a8;
			cursor: pointer;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 8px;
		}
		input {
			border: none !important;
			display: none;
		}
	}
	.input-size-div {
		margin-right: 35px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		column-gap: 10px;
		row-gap: 10px;
		div {
			display: flex;
			align-items: center;
		}
		svg {
			cursor: pointer;
			:hover {
				fill: #c9182a;
			}
		}
		button {
			width: 227px;
			height: 43px;
			border-radius: 8px;
			border: none;
			color: #2b63fc;
			background: #fff;
			border: 1px solid #2b63fc;
			font-style: italic;
			font-size: 14;
		}
	}
	.danger {
		border-color: red !important;
	}
	.size {
		border: 1px solid #8a99a8;
		border-radius: 8px;
		width: 29px;
		height: 43px;
		padding: 0 5px;
		text-align: center;
	}
	.size-stock {
		width: 104px;
		height: 43px;
		border: none;
		border-radius: 0;
		border-bottom: 1px solid #8a99a8;
		text-align: center;
		margin-left: 10px;
	}
	#stock {
		width: 104px;
		height: 43px;
		border: none;
		border-radius: 0;
		border: 1px solid #8a99a8;
		border-radius: 8px;
		text-align: center;
	}
`;
export const SideImgContainer = styled.div`
	border-radius: 8px;
	padding: 2px;

	img {
		width: 66px;
		height: 69px;
		object-fit: cover;
		border-radius: 2px;
		margin-right: 20px;
	}
`;

export const ButtonsContainer = styled.div`
	background: #f5f5f5;
	display: flex;
	place-content: flex-end;
	margin: 30px 0;
	span {
		font-family: var(--Roboto-font-family);
		font-size: 16px;
		font-weight: 500;
		color: #c9182a;
		text-align: center;
	}
	button {
		width: 236px;
		height: 34px;
		border-radius: 8px;
		border: none;
	}
	#save-changes {
		color: #fff;
		background: #00b06a;
	}
	#cancel-changes {
		color: #707070;
		margin-right: 12px;
		border: 1px solid #707070;
		margin-left: 40px;
	}
	#delete-product {
		color: #ffffff;
		background: #c9182a;
		margin-left: 20px;
		margin-right: auto;
	}
`;

export const StyledCheckBox = styled(Checkbox)`
	display: flex;
	align-items: center;
	height: 35px;

	label {
		color: #5a646e;
		margin-left: 20px;
		font-size: 14px;
		margin-bottom: 0;
	}
`;
export const FloatingTextContainer = styled.div`
	position: relative;
`;

interface FloatingProps {
	top: string;
	left: string;
}
export const FloatingText = styled.span<FloatingProps>`
	position: absolute;
	font-size: 26px;
	text-align: center;
	color: #2d327c;
	height: 90px;
	top: ${(Prop) => Prop.top};
	left: ${(Prop) => Prop.left};
`;
