import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { insertProductQuantity } from '../../../../actions/ProductsCartActions';
import ProductsCart from '../../../../interfaces/ProductsCart';
import { AppState } from '../../../../store';

// import { Container } from './styles';

const Quantity: React.FC = () => {
	const [quantity, setQuantity] = useState(0);
	const productsCart: ProductsCart[] = useSelector(
		(state: AppState) => state.productsCart.currentCart
	);
	const dispatch = useDispatch();

	useEffect(() => {
		setQuantity(productsCart[0].parameters.quantity);
	}, [productsCart]);

	function changeProductQuantity(moreOrLess: 'more' | 'less') {
		switch (moreOrLess) {
			//TODO- get in real time from database
			/* case 'more':
				dispatch(
					insertProductQuantity(
						productsCart[0].parameters.product.id,
						quantity + 1
					)
				);

				break;
			case 'less':
				dispatch(
					insertProductQuantity(
						productsCart[0].parameters.product.id,
						quantity - 1
					)
				);
				break;
 */
			default:
				break;
		}
	}

	return (
		<div className="product-quantity-container">
			<h2>Quantidade</h2>
			<div className="quantity">
				<span
					className="less-selector"
					onClick={() => changeProductQuantity('less')}
					style={{ cursor: 'pointer' }}
				>
					{' '}
					-{' '}
				</span>
				<span className="quantity-number">
					{' '}
					{productsCart[0].parameters.quantity}{' '}
				</span>
				<span
					className="more-selector"
					onClick={() => changeProductQuantity('more')}
					style={{ cursor: 'pointer' }}
				>
					{' '}
					+{' '}
				</span>

				<span className="product-name">
					{productsCart[0].parameters.product.title.substring(0, 20)}
				</span>
			</div>
		</div>
	);
};

export default Quantity;
