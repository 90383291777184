import styled from 'styled-components';

export const Container = styled.form`
	display: flex;
	flex-direction: column;
	padding: 40px 35px 30px 35px;

	align-items: center;
	h6 {
		color: #2b63fc;
		font-family: var(--Roboto-font-family);
		margin-bottom: 30px;
	}

	p {
		font-family: var(--Roboto-font-family);
		font-size: 12px;
		color: #5a646e;
		margin-bottom: 40px;
	}
	label {
		font-family: 'Open sans', sans-serif;
		color: #969696;
		font-size: 12px;
		margin-bottom: 5px;
		font-weight: bold;
	}
	div {
		display: grid;

		.error-text {
			width: 100%;
			text-align: center;
			margin-bottom: 5px;
			font-family: var(--Roboto-font-family);
			font-size: 15px;
		}
	}
	input {
		border: 1px solid 8a99a8;
		border-radius: 8px;
		color: #8a99a8;
		font-size: 14px;
		height: 40px;
		width: 266px;
		margin-bottom: 30px;
		padding-left: 10px;
		width: 318px;
		height: 40px;
	}
	button {
		text-align: -webkit-center;
		background: #2b63fc 0% 0% no-repeat padding-box;
		border-radius: 8px;
		color: #fff;
		font-family: var(--Poppins-font-family);
		font-size: 14px;
		width: 328px;
		height: 40px;
		font-weight: bold;
		border: none;
	}
`;
