import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 12px;
`

export const StarsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

interface IStar {
    canEdit: boolean;
}
export const Star = styled.div<IStar>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    flex: 0 0 auto;

    cursor: ${props => props.canEdit ? 'pointer' : 'default'};

    > svg, p {
        color: #20256c;
    }

    > p {
        font-size: 12px;
        font-family: var(--Roboto-font-family);
    }
`

export const ReviewTextArea = styled.textarea`
    flex: 1 1 auto;
    height: 100%;
    width: 100%;
    border: solid 1px #c5ced7;
    border-radius: 8px;
    resize: none;
    padding: 8px;
    font-family: var(--Roboto-font-family);
    font-size: 16px;
`

export const SendReview = styled.button`
    flex: 0 0 auto;
    border: 0;
    border-radius: 8px;
    background: #20256c;
    color: white;
    font-family: var(--Roboto-font-family);
    width: max-content;
    padding: 8px 23px;
    font-size: 16px;

    &:disabled {
        cursor: default;
    }
`