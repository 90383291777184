import React, { useEffect, useState } from 'react';
import SellerStoreHeader from './SellerStoreHeader/SellerStoreHeader';
import IconSearch from '../../assets/icons/searchIcon.svg';
import { Link, useHistory, useParams } from 'react-router-dom';

import {
	Container,
	PageContainer,
	FiltersRow,
	Select,
	CardContainer,
	ProductContainer,
	SearchBox,
	SerchRow,
	SearchIcon,
	Search,
} from './styles';
import api from '../../api';
import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { insertCustomizations } from '../../actions/ShopCustomizationsActions';
import { ShopCustomizations } from '../../interfaces/ShopCustomizations';
import { ProductsList, AreaTypes } from '../../interfaces/ProductsList';
import { AppState } from '../../store';
import ProductCard from '../Default/ProductCard/productCard';
import { clearList, insertProducts } from '../../actions/ProductsList';
import { productExibitionRules } from '../../utils/productsExibitionRules';
import { ProductCategories } from '../ProductRegister/ProductRegister';
import { IProduct } from '../../Pages/ProductPage/ProductPage';
import ProductCardShop from '../Default/ProductCardShop/ProductCardShop';
import UserAccount from '../../interfaces/UserAccount';

interface PageParams {
	userId: string;
	category: string;
	state: string;
	min_price: string;
	max_price: string;
}
export interface ShopQueryReturn {
	status: string;
	shop: ShopCustomizations[];
}

const SellerStore: React.FC = () => {
	const history = useHistory();
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [initailPath, setInitailPath] = useState('/seller-area');
	const [searchQuery, setSearchQuery] = useState('');
	const [
		productCategories,
		setProductCategories,
	] = useState<ProductCategories>();
	const [minValue, setMinValue] = useState<string>('0');
	const [maxValue, setMaxValue] = useState<string>('100000000');

	const [messageIsVisible, setVisibleMessage] = useState<boolean>(true);
	const pageParams = useParams<PageParams>();
	const dispacth = useDispatch();
	const productsList: ProductsList[] = useSelector(
		(state: AppState) => state.productsList?.currentProductsList
	);

	useEffect(() => {
		if (user && user.id === pageParams.userId) {
			setInitailPath('/my-store');
		} else {
			setInitailPath('/seller-area');
		}
	}, [user]);

	useEffect(() => {
		api.get('/get-product-categories')
			.then((response: AxiosResponse<ProductCategories>) => {
				setProductCategories(response.data);
			})
			.catch((err) => {});
	}, []);

	useEffect(() => {
		api.get('/get-shop-customizations', {
			params: {
				user_account_id: pageParams.userId,
			},
		}).then((response: AxiosResponse<ShopQueryReturn>) => {
			dispacth(insertCustomizations(response.data.shop[0]));
		});
	}, [pageParams]);

	useEffect(() => {
		api.get('/get-all-user-products', {
			params: {
				user_account_id: pageParams.userId,
				state: pageParams.state,
				min_price: pageParams.min_price,
				max_price: pageParams.max_price,
				category_id: pageParams.category,
			},
		}).then((response: AxiosResponse<any>) => {
			console.log({response})
			dispacth(
				insertProducts(
					productExibitionRules(
						response.data.products,
						AreaTypes.MYPRODUCTSPAGE
					),
					AreaTypes.MYPRODUCTSPAGE
				)
			);
		});
	}, [pageParams]);

	useEffect(() => {
		if (
			productsList.find(
				(item) => item?.areaTitle === AreaTypes.MYPRODUCTSPAGE
			)?.products
		) {
			if (
				productsList.find(
					(item) => item?.areaTitle === AreaTypes.MYPRODUCTSPAGE
				)!.products!.length > 0
			) {
				setVisibleMessage(false);
			} else {
				setVisibleMessage(true);
			}
		}
	}, [productsList]);

	return (
		<PageContainer>
			<Container>
				<SellerStoreHeader />
				<FiltersRow>
					{/* <SearchBox style={{ width: '540px' }}> */}
					<SearchBox>
						<SerchRow>
							<SearchIcon>
								<img
									alt="lupa pesquisar"
									src={IconSearch}
								/>
							</SearchIcon>
							<Search
								style={{ background: '#f5f5f5' }}
								type="text"
								onChange={(e) => setSearchQuery(e.target.value)}
								placeholder="quero comprar..."
							></Search>
						</SerchRow>
					</SearchBox>
				</FiltersRow>

				<CardContainer>
					<div className="filters">
						<h6>Categoria</h6>
						<ul className="categories-list">
							{productCategories?.categories.map((category) => (
								<li
									id={
										pageParams.category == category.id
											? 'activated'
											: ''
									}
									onClick={() =>
										history.push(
											`${initailPath}/${pageParams.userId}/${pageParams.state}/${pageParams.min_price}/${pageParams.max_price}/${category.id}`
										)
									}
								>
									{category.name}
								</li>
							))}
						</ul>
						<h6 style={{ marginTop: '25px' }}>Condição</h6>
						<ul>
							<li
								id={
									pageParams.state === 'new'
										? 'activated'
										: ''
								}
								onClick={() =>
									history.push(
										`${initailPath}/${pageParams.userId}/new/${pageParams.min_price}/${pageParams.max_price}/${pageParams.category}`
									)
								}
							>
								Novo
							</li>
							<li
								id={
									pageParams.state === 'used'
										? 'activated'
										: ''
								}
								onClick={() =>
									history.push(
										`${initailPath}/${pageParams.userId}/used/${pageParams.min_price}/${pageParams.max_price}/${pageParams.category}`
									)
								}
							>
								Usado
							</li>
						</ul>
						<h6 style={{ marginTop: '25px' }}>Preço</h6>
						<ul>
							<li
								id={
									pageParams.min_price === '0' &&
									pageParams.max_price === '70'
										? 'activated'
										: ''
								}
								onClick={() =>
									history.push(
										`${initailPath}/${pageParams.userId}/${pageParams.state}/0/70/${pageParams.category}`
									)
								}
							>
								até R$ 70
							</li>
							<li
								id={
									pageParams.min_price === '70' &&
									pageParams.max_price === '200'
										? 'activated'
										: ''
								}
								onClick={() =>
									history.push(
										`${initailPath}/${pageParams.userId}/${pageParams.state}/70/200/${pageParams.category}`
									)
								}
							>
								R$ 70 a R$ 200
							</li>
							<li
								id={
									pageParams.min_price === '200' &&
									pageParams.max_price === '100000000'
										? 'activated'
										: ''
								}
								onClick={() =>
									history.push(
										`${initailPath}/${pageParams.userId}/${pageParams.state}/200/100000000/${pageParams.category}`
									)
								}
							>
								mais de R$ 200
							</li>
						</ul>
						<div className="filters-input">
							<input
								type="text"
								placeholder="Minimo"
								style={{ marginRight: '20px' }}
								onChange={(e) => setMinValue(e.target.value)}
								onKeyPress={(e) => {
									if (
										e.nativeEvent.key === 'Enter' &&
										minValue.length > 0
									) {
										history.push(
											`${initailPath}/${pageParams.userId}/${pageParams.state}/${minValue}/${maxValue}/${pageParams.category}`
										);
									}
								}}
							/>
							<input
								type="text"
								placeholder="Máximo"
								onChange={(e) => setMaxValue(e.target.value)}
								onKeyPress={(e) => {
									if (
										e.nativeEvent.key === 'Enter' &&
										minValue.length > 0
									) {
										history.push(
											`${initailPath}/${pageParams.userId}/${pageParams.state}/${minValue}/${maxValue}/${pageParams.category}`
										);
									}
								}}
							/>
						</div>
						<span
							onClick={() =>
								history.push(
									`${initailPath}/${pageParams.userId}/all/0/100000000/undefined`
								)
							}
						>
							Limpar Filtros
						</span>
					</div>
					<ProductContainer>
						{productsList
							.find(
								(item) =>
									item?.areaTitle === AreaTypes.MYPRODUCTSPAGE
							)
							?.products?.filter((product) => product.title.toLowerCase().includes(searchQuery)).map((product, index: number) => (
								<>
									{index < 20 && (
										<ProductCard product={product} />
									)}
								</>
							))}
						{messageIsVisible && <h3>Produtos não encontrados</h3>}
					</ProductContainer>
				</CardContainer>
			</Container>
		</PageContainer>
	);
};

export default SellerStore;
