import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import api from '../../../api';
import BlockedSeller from '../../../interfaces/BlockSeller';
import UserAccount from '../../../interfaces/UserAccount';

import { ModalContainer } from './styles';

type Props = {
	userId: string;
	open: boolean;
	setOpen: (open: boolean) => void;
};

const BlockSellerModal: React.FC<Props> = ({ userId, open, setOpen }) => {
	const onSubmit = (data: BlockedSeller) => {
		try {
			api.post('/block-seller', {
				...data,
				user_accounts_id: userId,
			}).then((response) => {
				alert('Vendedor bloqueado!');
				setOpen(false);
			});
		} catch (error) {
			alert('Erro ao bloquear vendedor!');
		}
	};

	const { register, handleSubmit, errors } = useForm<BlockedSeller>();

	return (
		<Modal
			isOpen={open}
			onRequestClose={() => setOpen(false)}
			style={{
				overlay: {
					position: 'fixed',
					top: 0,
					zIndex: 1001,
					left: 0,
					right: 0,
					bottom: 0,
					background: '#0007',
				},
				content: {
					top: '50%',
					left: '50%',
					right: 'auto',
					padding: '0 auto',
					bottom: 'auto',
					marginRight: '-50%',
					maxHeight: '100%',
					border: 'none',
					borderRadius: '11px',
					transform: 'translate(-50%, -50%)',
				},
			}}
		>
			<ModalContainer>
				<div className="title">
					<h1>Bloquear vendedor</h1>
				</div>
				<form className="cards" onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="motivation">
							<span>Motivo</span>
							<input
								name="reason"
								ref={register({ required: false })}
							/>
						</div>
						<div className="duration">
							<span>Duração do bloqueio</span>
							<input
								name="blocking_time"
								ref={register({ required: false })}
							/>
						</div>
					</div>
					<div className="motive">
						<span>Especifique o motivo</span>
						<textarea
							name="specify_reason"
							ref={register({ required: false })}
						/>
					</div>
					<div className="button-text">
						<p>O vendedor será notificado</p>
						<button type="submit">Confirmar bloqueio</button>
					</div>
				</form>
			</ModalContainer>
		</Modal>
	);
};

export default BlockSellerModal;
