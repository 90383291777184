import styled from 'styled-components';

export const Tracking = styled.ul`
    >li {
        margin: 10px 0;

        display: flex;
        flex-wrap: wrap;

        align-items: center;
        justify-content: space-between;

        >h1 {
            font-size: 16px;
        }

        >p {
            font-size: 12px;
        }
    }

`

export const Container = styled.div`
    width:1186px;
    padding:40px 19px 40px 19px;
    font-family: var(--Roboto-font-family);
    h4{
        height: 100%;
        font-size:26px;
        font-weight: 300;
        margin-bottom:35px;
        display: flex;
        align-items:center;
    }
    .infos{
        display:flex;
    }
    .buttons{
        display:flex;
        float: right;
        margin:37px 0;
        button{
            width: 236px;
            height: 34px;
            border-radius: 8px;
            border:none;
            font-size: 16px;
        }
    }

    #invoice{
        color:#20256C;
        border: 1px solid #2D327C;
        background:#fff;
        font-weight:500;
    }
    #printer{
        color:#fff;
        background:#00B06A;
        font-weight:500;
        margin-left:16px;
    }

    .tracking-bar{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .complete{
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background: #00B06A;
        }
        .grey-line{
            border: 1px solid #898989;
            width: 160px;
            height: 0;

        }
        .income-circle{
            width: 14px;
            height: 14px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #898989;
            border-radius: 50%;
        }
    }
`;

export const InfoContainer = styled.div`
    border: 1px solid #C5CED7;
    border-radius: 8px;
    padding: 0px 18px 0px 18px;
    width: 397px;
    margin-right:19px;
    min-height: 252px;

    label{
        font-size:16px;
        color:#2D327C;
        font-weight: 500; 
        font-family: var(--Roboto-font-family);
    }
`;
export const InputsRow = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    padding-bottom:15px;
`;
export const RowItem = styled.div`
    display: flex;
    flex-direction: column;
    img{
        width: 50px;
        height: 53px;
    }
    .product-title{
        color: #353A40;
        font-size:20px;
    
    font-weight:400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    }
    
    span{
        color:#20256C;
        font-size:17px;
        font-weight:bold;
    }
    .user-photo{
        width: 28px;
        height: 28px;
        border-radius:50%;
        background:#5A646E;
        margin-right:6px;
    }

    p{
        color:#5A646E;
        font-size:16px;
    }

    .size{
        width: 77px;
        height: 32px;
        background: #F6F6F6 0% 0% no-repeat padding-box;
        border-radius: 8px;
        font-size:16px;        
        display: flex;
        p{
            color:#999999;
            margin: auto;
            font-weight:bold;
        }
    }
`;

export const CorreiosLabel = styled.div`
    display: none;

    @media print {
        display: block;
    }

    margin: 1cm;

    height: max-content;
    width: 10cm;

    outline: 2px solid black;

    * {
        margin: 0;
    }

    h1 {
        font-size: 18px;
    }

    h1, p {
        font-size: 16px;
        color: black;
    }

    > section {
        outline: 2px solid black;

        > h1 {
            width: max-content;

            background-color: black;
            color: white;
            font-weight: bold;
        }
    }
`