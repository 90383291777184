import styled from 'styled-components';

export const CategoryContainer = styled.div`
	position: relative;
	.category {
		width: 152px;
		height: 152px;
		box-shadow: 0px 3px 6px #00000029;
		border-radius: 4px;
		border: solid 1px #c9c9c9;
		background: #f5f5f5;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 8px;
		transition: all 300ms;
		span {
			color: #000;
			font-size: 12px;
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			margin-top: 5px;
		}

		&:hover {
			cursor: pointer;
			border: solid 1px #2b63fc;
		}
	}
	.active {
		font-size: 0.5rem;
		font-family: var(--Roboto-font-family);
		font-weight: 400;
		z-index: 1;
		position: relative;
		border: solid 1px #2b63fc;
		border-radius: 8px;
		span {
			color: #000;
		}
	}
	.sub-category-container {
		width: 20rem;
		background: #f5f5f5;
		box-shadow: 0px 3px 6px #00000029;
		border-top: none;
		border-radius: 8px;
		position: absolute;
		top: 162px;
		z-index: 1;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;

		@media (max-width: 1360px) {
			width: 100%;
		}

		.sub-category {
			display: flex;
			flex-direction: column;
			row-gap: 10px;
			width: 100%;
			padding: 10px 15px;

			.input-container {
				width: 100%;
				display: flex;
				align-items: center;
				border: 1px solid #c5ced7;
				border-radius: 8px;
				padding: 0px 5px;
				box-sizing: border-box;

				.icon {
					stroke: rgb(45, 50, 124);
					width: 25px;
					height: 25px;
				}

				input {
					width: 100%;
					height: 100%;
					border: none;
					border-radius: 8px;
					margin-left: 5px;
				}
			}
			.items {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				flex-wrap: wrap;
				gap: 10px;

				.subcategory {
					background: #d1d0d0;
					color: black;
					border: none;
					border-radius: 8px;
					font-size: 12px;
					font-family: var(--Roboto-font-family);
					font-weight: 400;
					font-style: italic;
					padding: 8px 15px;
				}
				.active {
					background: rgb(45, 50, 124);
					color: white;
				}
			}
		}
	}
`;
