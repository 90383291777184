import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { insertProducts } from '../../../../actions/ProductsList';
import api from '../../../../api';
import { Product } from '../../../../interfaces/Product';
import { AreaTypes, ProductsList } from '../../../../interfaces/ProductsList';
import { AppState } from '../../../../store';
import { productExibitionRules } from '../../../../utils/productsExibitionRules';
import MyProductsItem from './MyProductsItem/MyProductsItem';
import { CgSmileSad } from 'react-icons/cg';

import { Container, NoProducts } from './styles';

type Props = {
	listFilter: string;
	searchQuery: string;
};

const MyProductsCard: React.FC<Props> = ({ listFilter, searchQuery }) => {
	const productsList: ProductsList[] = useSelector(
		(state: AppState) => state.productsList?.currentProductsList
	);
	const dispatch = useDispatch();

	useEffect(() => {
		if (listFilter === 'all') {
			api.get('/get-user-products').then((response) => {
				if (response?.data?.products?.length > 0) {
					dispatch(
						insertProducts(
							productExibitionRules(
								response.data.products,
								AreaTypes.MYPRODUCTSPAGE
							),
							AreaTypes.MYPRODUCTSPAGE
						)
					);
				} else {
					dispatch(insertProducts([], AreaTypes.MYPRODUCTSPAGE));
				}
			});
		} else if (listFilter === 'sale') {
			api.get('/get-user-active-products').then((response) => {
				if (response?.data?.products?.length > 0) {
					dispatch(
						insertProducts(
							productExibitionRules(
								response.data.products,
								AreaTypes.MYPRODUCTSPAGE
							),
							AreaTypes.MYPRODUCTSPAGE
						)
					);
				} else {
					dispatch(insertProducts([], AreaTypes.MYPRODUCTSPAGE));
				}
			});
		} else if (listFilter === 'deactivated') {
			api.get('/get-user-inactive-products').then((response) => {
				if (response?.data?.products?.length > 0) {
					dispatch(
						insertProducts(
							productExibitionRules(
								response.data.products,
								AreaTypes.MYPRODUCTSPAGE
							),
							AreaTypes.MYPRODUCTSPAGE
						)
					);
				} else {
					dispatch(insertProducts([], AreaTypes.MYPRODUCTSPAGE));
				}
			});
		}
	}, [listFilter]);

	const [filteredProducts, setFilteredProducts] = useState<Product[]>([])

	useEffect(() => {
		setFilteredProducts(
			productsList.find((item) => item.areaTitle === AreaTypes.MYPRODUCTSPAGE)?.products?.filter((product) => product.title.toLowerCase().includes(searchQuery.toLowerCase())) || []
		)
	}, [productsList, searchQuery]);

	return (
		<Container>
			{
				filteredProducts.map((product) => (
					<>
						<MyProductsItem product={product}></MyProductsItem>
					</>
				))}
			{filteredProducts.length == 0 && (
				<NoProducts>
					<div className="emoji-and-text">
						<CgSmileSad color="#2B63FC" className="emoji" />
						<h2>
							Você não possui nenhum produto
							{listFilter === 'all'
								? ' cadastrado'
								: listFilter === 'sale'
								? ' à venda'
								: ' desativado'}
						</h2>
					</div>
				</NoProducts>
			)}
		</Container>
	);
};

export default MyProductsCard;
