import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { insertMobileHeader } from '../../actions/MobileHeaderActions';
import AboutUs from '../../components/AboutUs/AboutUs';
import Header from '../../components/Default/header';
import SellerArea from '../../components/SellerArea/SellerArea';
import { BackPreset } from '../../interfaces/MobileHeader';

import American from '../../assets/American.svg';
import Aura from '../../assets/Aura.svg';
import Diners from '../../assets/Diners.svg';
import Discover from '../../assets/Discover.svg';
import Elo from '../../assets/Elo.svg';
import Hipercard from '../../assets/Hipercard.svg';
import JCB from '../../assets/JCB.svg';
import Mastercard from '../../assets/Mastercard.svg';
import Visa from '../../assets/Visa.svg';

import Boleto from '../../assets/Boleto.jpg';

import { Container, Section } from './styles';

const PaymentMethodsPage: React.FC = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(insertMobileHeader(BackPreset))
	});
	return (
		<Container>
			<Header />
			<Section>
				<h1>Formas de pagamento</h1>
				<h2>Cartões de crédito/débito</h2>
				<div className='list-types'>
					<img src={American} alt="American Express"/>
					<img src={Aura} alt="Aura"/>
					<img src={Diners} alt="Diners"/>
					<img src={Discover} alt="Discover"/>
					<img src={Elo} alt="Elo"/>
					<img src={Hipercard} alt="Hipercard"/>
					<img src={JCB} alt="JCB"/>
					<img src={Mastercard} alt="Mastercard"/>
					<img src={Visa} alt="Visa"/>
				</div>
				<h2>Outras formas de pagamento</h2>
				<div className='list-types'>
					<img src={Boleto} alt="Boleto"/>
				</div>
			</Section>
		</Container>
	);
};

export default PaymentMethodsPage;