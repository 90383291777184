import { Product } from '../interfaces/Product';
import {
	AreaTypes,
	InsertProducts,
	RemoveSpecificProduct,
	ClearList,
} from '../interfaces/ProductsList';

export const insertProducts = (
	products: Product[],
	targetArea: AreaTypes
): InsertProducts => ({
	type: 'INSERT_PRODUCTS',
	products,
	targetArea,
});

export const removeSpecificProduct = (
	product_id: string,
	target_area: string
): RemoveSpecificProduct => ({
	type: 'REMOVE_SPECIFIC_PRODUCT',
	product_id,
	target_area,
});

export const clearList = (): ClearList => ({
	type: 'CLEAN_PRODUCTS_LIST',
});
