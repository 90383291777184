import React from 'react';
import Review from '../../../components/BuyProcess/Review/Review';
import Header from '../../../components/Default/header';

import { Container } from './styles';

const ReviewPage: React.FC = () => {
	return (
		<Container>
			<Header />
			<Review />
		</Container>
	);
};

export default ReviewPage;
