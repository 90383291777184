import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import {
	Container,
	ImageAndInfosContainer,
	PriceContainer,
	PromotionLabel,
} from './styles';
import imageTeste from '../../../../../assets/imagem.jpg';
import moreIcon from '../../../../../assets/icons/moreIcon.svg';
import { Product } from '../../../../../interfaces/Product';
import api from '../../../../../api';
import { useDispatch } from 'react-redux';
import { removeSpecificProduct } from '../../../../../actions/ProductsList';
import { AreaTypes } from '../../../../../interfaces/ProductsList';
import { format } from 'date-fns';
import config from '../../../../../config';
import ChangeProductsInfosModal from './ChangeProductsInfosModal/ChangeProductsInfosModal';
import { getFristImageSource } from '../../../../../utils/GetProductImage';
import useCheckMobileScreen from '../../../../../utils/useCheckMobileScreen';
import { useHistory } from 'react-router-dom';

type Props = {
	product: Product;
};

const MyProductsItem: React.FC<Props> = ({ product }) => {
	const history = useHistory();
	const [menuIsVisible, setMenuVisible] = useState(false);
	const dispatch = useDispatch();
	const [arrayLenght, setArrayLenght] = useState<number>(0);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const closeMenu = (areaClicked: string) => {
		if (areaClicked === 'outside' && menuIsVisible) {
			setMenuVisible(false);
		} else {
			return;
		}
	};

	const inactiveProduct = () => {
		api.post('/inactive-product', {
			product_id: product.id,
		})
			.then((res) => {
				dispatch(
					removeSpecificProduct(product.id, AreaTypes.MYPRODUCTSPAGE)
				);
				alert('Produto inativado com sucesso');
				window.location.reload(false);
			})
			.catch((err) => {
				alert('Falha ao inativar o produto');
			});
	};
	const activeProduct = () => {
		api.delete('/active-product', {
			params: { inactive_product_id: product.inactive_products!.id },
		})
			.then((response) => {
				alert('Operação realizada com sucesso');
				dispatch(
					removeSpecificProduct(
						product.inactive_products!.id,
						AreaTypes.INACTIVEPRODUCTSPAGE
					)
				);
				window.location.reload(false);
			})
			.catch((err) => alert('Falha ao realizar operação'));
	};

	function closeModal() {
		document.body.style.overflow = 'initial';
		setModalIsOpen(false);
	}
	function openModal() {
		document.body.style.overflow = 'hidden';
		setModalIsOpen(true);
	}

	const handleActiveInactive = () => {
		!product.inactive_products ? inactiveProduct() : activeProduct();
	};

	return (
		<Container>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => {
					closeModal();
				}}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						zIndex: 1001,
						left: 0,
						right: 0,
						bottom: 0,
						background: '#0007',
						overflow: 'hidden',
					},
					content: {
						top: '54%',
						left: '50%',
						right: 'auto',
						padding: '0 auto 0 auto',
						bottom: 'auto',
						marginRight: '-50%',
						maxHeight: '85%',
						border: 'none',
						borderRadius: '11px',
						transform: 'translate(-50%, -50%)',
						background: '#f5f5f5',
					},
				}}
			>
				<ChangeProductsInfosModal
					product={product}
					handleCloseModal={closeModal}
				/>
			</Modal>

			<ImageAndInfosContainer>
				<div className="card" onClick={() => {
					history.push(`/product/${product.id}`);
				}}>
					<img
						src={getFristImageSource(product)}
						alt="imagem do produto"
					/>
					<div className="infos-container">
						{/* <h3>{product?.user_accounts?.name}</h3> */}
						<h2> {product?.title} </h2>
						{!product.showcase && (
							<div className="bottom-infos">
								<PriceContainer>
									<h5>
										{Number(product.price).toLocaleString('pt-BR', {minimumFractionDigits: 2, style: 'currency', currency:'BRL'}).replace('.','')}
									</h5>
								</PriceContainer>
								<div className="rowMobile">
									<span>
										<strong>{product.stock}</strong>à venda
									</span>
									<span>
										<strong>{product.charge_products?.length || 0}</strong>vendidos
									</span>
								</div>
							</div>
						)}
						
					</div>
				</div>
				<div className="buttons">
					{useCheckMobileScreen() ? (
						<button
							id="update-infos"
							onClick={() => {
								history.push(
									`/update-product-mobile/${product.id}`
								);
							}}
						>
							Alterar informações
						</button>
					) : (
						<button
							id="update-infos"
							onClick={() => {
								openModal();
							}}
						>
							Alterar informações
						</button>
					)}
					<button
						id={product.inactive_products ? 'enable' : 'disable'}
						onClick={() => handleActiveInactive()}
					>
						{product.inactive_products
							? 'Reativar anúncio'
							: 'Inativar anúncio'}
					</button>
				</div>
			</ImageAndInfosContainer>
		</Container>
	);
};

export default MyProductsItem;
