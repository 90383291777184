import React from 'react';
import { ReactComponent as Logo } from '../../../assets/icons/acpLogo.svg';
import { Link, useHistory } from 'react-router-dom';
import { Container, DivLogo } from './styles';

const LoginHeader: React.FC = () => {
	const history = useHistory();
	return (
		<Container>
			<DivLogo>
				<Logo style={{ cursor: 'pointer' }} onClick={() => history.push('/seller')} />
			</DivLogo>
		</Container>
	);
};

export default LoginHeader;
