import styled from 'styled-components';

export const Container = styled.div`
	font-family: var(--Roboto-font-family);
	height: 100vh;
	width: 90%;
	padding: 0 5%;
	background-color: #f5f5f5;

	@media (max-width: 370px) {
		.rccs {
			width: 200px;
		}
		.rccs__card {
			width: 200px;
		}
	}

	.title {
		margin-bottom: 10px;
		h6 {
			font-size: 16px;
			color: #2b63fc;
		}
	}
	.button {
		display: flex;
		justify-content: center;
	}
	button {
		background: #2b63fc;
		border-radius: 8px;
		width: 100%;
		min-height: 30px;
		color: #fff;
		border: none;
		font-size: 16px;
		font-weight: 400;
		padding: 10px 10px;
	}
`;
export const CardAndInputContainer = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	border-radius: 12px;
	background-color: #fff;
	padding: 33px 16px 31px 18px;
	width: calc(100% - 34px);
	margin-bottom: 20px;
`;

export const FormContainer = styled.form`
	margin-top: 17px;
	width: 100%;

	.row {
		display: flex;
		flex-direction: column;
		row-gap: 24px;
		width: 100%;

		.input-and-error {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.field-error,
			.field-validation-error {
				font-size: 11px;
				margin-top: 4px;
				color: red;
			}
		}
	}
	input {
		border: 1px solid #8a99a8;
		border-radius: 8px;
		font-style: italic;
		height: 40px;
	}
`;
