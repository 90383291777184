import styled from 'styled-components';

export const Container = styled.div`
	background: #f5f5f5;
	/* margin: 0px 10%; */
	min-height: 100vh;
	font-family: 'Roboto';
	.cards-flex {
		display: flex;
		flex-wrap: wrap;
		column-gap: 15px;
		row-gap: 15px;
		padding-bottom: 100px;
		justify-content: center;
	}
	.buttons-search {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	.text-buttons {
		margin-top: 80px;

		h1 {
			text-align: center;
			margin-bottom: 25px;
			font-size: 26px;
			color: #5a646e;
		}
		input {
			/* width: 100%; */
			background: #f5f5f5;
			border: none;
			border-bottom: 1px solid #5a646e;
			padding-bottom: 3px;
		}
		.search {
			margin-left: 30%;
			svg {
				margin-left: -23px;
				width: 20px;
				height: 19px;
				color: #5a646e;
			}
		}
	}
	.row-buttons-text {
		display: flex;
		flex-direction: row;
	}
	.buttons {
		margin-left: 17%;
		display: flex;
		flex-direction: row;
		font-size: 12px;
		width: 96%;
		margin-bottom: 54px;
	}
	.button-active {
		border: 1px solid #5a646e;
		border-radius: 4px 0px 0px 4px;
		background: #5a646e;
		color: white;
		width: 174px;
		height: 30px;
		justify-content: center;
		text-align: center;
	}
	.button-no-active {
		justify-content: center;
		border-radius: 0px 4px 4px 0px;
		text-align: center;
		width: 174px;
		height: 30px;
		border: 1px solid #5a646e;
		p {
			color: #5a646e;
		}
	}
`;
