import React from 'react';
import OrderConfirmation from '../../../components/Cart/OrderConfirmation/OrderConfirmation';
import Header from '../../../components/Default/header';

import { Container } from './styles';

const OrderConfirmationPage: React.FC = () => {
	return (
		<Container>
			<Header />
			<OrderConfirmation />
		</Container>
	);
};

export default OrderConfirmationPage;
