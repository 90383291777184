import { BsWhatsapp } from 'react-icons/bs';
import { ContactForm, ContactFormInput, ContactFormLabel, ContactFormSection, ContactFormSubmit, FormSentText, WhatsappButton } from "./styles";
import { useForm } from "react-hook-form";
import api from "../../api";
import { useState } from "react";
import React from 'react'

interface PreRegisterFormData {
    name: string;
    enterprise: string;
    phone: string;
    email: string;
}

const LandingPage: React.FC = () => {

    // ! FIXME: That's 100% illegal
    if (window?.location.pathname === '/')
    {
        require("./styles.css")
        require("./bootstrap.min.css")
    }

    const { register, handleSubmit, errors, reset } = useForm<PreRegisterFormData>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        shouldFocusError: true,
    });
    
    const [formSent, setFormSent] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const onSubmit = async (data: PreRegisterFormData) => {
        setIsSubmitting(true);
        await api.post('/contact-form', data);

        reset();
        setFormSent(true);
        setIsSubmitting(false);
    }

    return (
        <body className="body-wrapper">
        <nav className="navbar main-nav navbar-expand-lg">
    <div className="container">
        <a className="navbar-brand" href="./"><img src="images/logo-bom-place-transparente.png?v=1.05" alt="Logo Bom Place"/></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="tf-ion-android-menu"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <a className="nav-link scrollTo" href="#parceiros">PARCEIROS</a>
                </li>
            </ul>
        </div>
    </div>
</nav>

<section className="banner bg-1" id="home" style={{backgroundImage: "url(images/banner-bomplace-saia-na-frente-v2.jpg)"}}>
    <div className="container">

    </div>
</section>

<section className="section" id="conhecimentos">
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="section-title">
                    <h2 className="text-white">ESTRUTURA COMPLETA PARA SUAS VENDAS ONLINE!</h2>
                    <p className="text-white">O <strong>BomPlace</strong> é o Marketplace para você, empresário do Paraná, apresentar e vender seus produtos com o máximo de visibilidade, tecnologia e segurança.</p>
                </div>
                <hr className="hr-bottom"/>
            </div>
        </div>
    </div>
</section>

<section className="section" id="plataforma">
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="section-title">
                    <h2 className="text-white">UMA PLATAFORMA EXTRAORDINÁRIA FEITA PARA SUA EMPRESA!</h2>
                    <p className="text-white">Mais do que um e-commerce. Mais do que uma vitrine digital. O <strong>BomPlace</strong> é a plataforma de conexão entre vendedores e compradores do Estado do Paraná. Traga sua empresa e seja parte do marketplace que veio para mudar o jeito de vender online.</p>
                </div>
                <hr className="hr-bottom"/>
            </div>
        </div>
    </div>
</section>

<section className="section" id="experiencia">
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div id="lista-experiencias">
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <div className="row align-items-center">
                                <div className="col-lg-1 col-2">
                                    <i className="tf-refresh"></i>
                                </div>
                                <div className="col-lg-10 col-10 offset-lg-1">
                                    <p>PERSONALIZE A SUA LOJA COM LOGO, DESCRIÇÃO E MEIOS DE CONTATO.</p>
                                </div>
                            </div>
                        </li>
                        <li className="list-group-item">
                            <div className="row align-items-center">
                                <div className="col-lg-1 col-2">
                                    <i className="tf-ion-network"></i>
                                </div>
                                <div className="col-lg-10 col-10 offset-lg-1">
                                    <p>CONECTE-SE E VENDA PARA COMPRADORES DE TODO O ESTADO.</p>
                                </div>
                            </div>
                        </li>
                        <li className="list-group-item">
                            <div className="row align-items-center">
                                <div className="col-lg-1 col-2">
                                    <i className="tf-puzzle"></i>
                                </div>
                                <div className="col-lg-10 col-10 offset-lg-1">
                                    <p>CONTE COM UMA VITRINE PARA VENDA ONLINE DE TODOS OS SEUS PRODUTOS, COM OS MELHORES MEIOS DE PAGAMENTO, LOGÍSTICA E TAXAS IMBATÍVEIS.</p>
                                </div>
                            </div>
                        </li>
                        <li className="list-group-item">
                            <div className="row align-items-center">
                                <div className="col-lg-1 col-2">
                                    <i className="tf-ion-android-bulb"></i>
                                </div>
                                <div className="col-lg-10 col-10 offset-lg-1">
                                    <p>UMA PLATAFORMA COMPLETA, COM POSSIBILIDADE DE PERSONALIZAÇÕES POR SEGMENTO.</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <hr className="hr-bottom"/>
            </div>
        </div>
    </div>
</section>

<section className="section" id="parceiros">

    <div className="container">

        <div className="row">
            <div className="col-12">
                <div className="section-title">
                    <h2 className="text-white">REDE DE PARCEIROS</h2>
                    <p className="text-white">Um grupo mais que especial para alavancar as suas vendas com conforto e segurança.</p>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-12 m-auto">
                <div className="parceiros-slider">

                    <div className="parceiros-item">
                        <div className="image">
                            <img className="img-fluid" src="images/acp_72_dpi_300x300.jpg?v=1.05" alt="Tradição e Capilaridade – ACP"/>
                        </div>

                        <div className="content">
                            <div className="name">
                                <h5>Tradição ACP</h5>
                            </div>
                            <div className="speech">
                                <p>A tradição e a força de 130 anos de história em prol do comércio e comunidade paranaense, agora com foco na multiplicação de negócios no meio digital.</p>
                            </div>
                        </div>
                    </div>

                    <div className="parceiros-item">
                        <div className="image">
                            <img className="img-fluid" src="images/pipehub_72_dpi_300x300.png?v=1.05" alt="Pagamento – PipeHub"/>
                        </div>

                        <div className="content">
                            <div className="name">
                                <h5>Integração via PipeHub</h5>
                            </div>
                            <div className="speech">
                                <p>O PipeHub é uma central de integração para o lojista vender em outros marketplaces, via WhatsApp e em sintonia com seus sistemas atuais (ERP, estoque e e-commerce próprio). Controle e distribuição online em um ambiente único.</p>
                            </div>
                        </div>
                    </div>

                    <div className="parceiros-item">
                        <div className="image">
                            <img className="img-fluid" src="images/sequoia_72_dpi_300x300.jpg?v=1.05" alt="Logística – Sequoia"/>
                        </div>

                        <div className="content">
                            <div className="name">
                                <h5>Logística via Sequoia</h5>
                            </div>
                            <div className="speech">
                                <p>Entregas e coletas em velocidade e segurança imbatíveis, com a qualidade que só uma empresa entre as top 3 no segmento logístico brasileiro pode oferecer. Tudo isso com taxas reduzidas para as lojas filiadas ao BomPlace.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <hr className="hr-bottom"/>
    </div>
</section>

<section className="section" id="form">
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="section-title">
                    <h2 className="text-white">ENTRE EM CONTATO</h2>
                    <p className="text-white">Entre em contato com a gente e tire suas dúvidas sobre o bomplace.</p>
                </div>
            </div>
        </div>
        <div className="row" style={{ alignItems: 'center', justifyContent: 'center' }}>
            <a href="https://api.whatsapp.com/send?phone=554187049679" style={{ height: 'max-content', margin: '30px 0'}}>
                <WhatsappButton>
                    <BsWhatsapp/> Entre em contato via WhatsApp!
                </WhatsappButton>
            </a>
        </div>
        <div className="row">
            <ContactForm onSubmit={handleSubmit(onSubmit)}>
                <h6>Ou envie um e-mail para gente</h6>
                <ContactFormSection>
                    <ContactFormLabel>Nome</ContactFormLabel>
                    <ContactFormInput 
                        type="text" 
                        name="name"
                        hasErrors={!!errors.name} 
                        {...register('name')}
                        ref={register({ required: true })}
                    />
                </ContactFormSection>
                <ContactFormSection>
                    <ContactFormLabel>Empresa</ContactFormLabel>
                    <ContactFormInput 
                        type="text" 
                        name="enterprise"
                        hasErrors={!!errors.enterprise} 
                        {...register('enterprise')}
                        ref={register({ required: true })}
                    />
                </ContactFormSection>
                <ContactFormSection>
                    <ContactFormLabel>Telefone</ContactFormLabel>
                    <ContactFormInput 
                        type="text" 
                        name="phone"
                        hasErrors={!!errors.phone} 
                        {...register('phone')}
                        ref={register({ required: true })}
                    />
                </ContactFormSection>
                <ContactFormSection>
                    <ContactFormLabel>Email</ContactFormLabel>
                    <ContactFormInput 
                        type="email" 
                        name="email"
                        hasErrors={!!errors.email} 
                        {...register('email')}
                        ref={register({ required: true })}
                    />
                </ContactFormSection>
                <ContactFormSubmit 
                    type="submit"
                    disabled={isSubmitting}
                >
                    Enviar
                </ContactFormSubmit>
                {formSent && 
                    <FormSentText>
                        Formulário enviado com sucesso!
                    </FormSentText>
                }
            </ContactForm>
        </div>
    </div>
</section>

<footer className="footer-main">
    <div className="container">
        <div className="row">
            <div className="col-md-6">
                <div className="copyright">
                    <p>
                        <img src="https://www.explay.com.br/wp-content/themes/explay_new/images/img-footer-brand.png"/>
                        Realização: Explay <a href="tel:4130494858" style={{
                            display: "block",
                            marginTop: "-12px",
                            marginLeft: "48px",
                            color: "white"
                        }}>(41) 3049-4858</a>
                    </p>
                </div>
            </div>
            <div className="col-md-6">
                <div className="copyright text-right">
                    <p>Uma iniciativa da <img src="./images/logo-acp2020white.png?v=1.05" alt="Associação Comercial do Paraná"/></p>
                </div>
            </div>
        </div>
    </div>
</footer>

        </body>
    )
};

export default LandingPage;