import styled from 'styled-components';

export const PageContainer = styled.main`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	align-items: center;
	text-align: left;
	font-family: var(--Roboto-font-family);
	/* width: var(--Default-page-alignment-vale); */

	/* @media (max-width: 768px) {
		width: 100%;
	} */

	h2 {
		color: black;
		font-size: 24px;
		font-family: var(--Roboto-font-family);
		width: var(--Default-page-alignment-vale);
		font-weight: normal;
		font-size: 26px;
		margin-top: 47px;
		margin-bottom: 34px;

		@media (max-width: 768px) {
			width: 90%;
			margin-left: 5%;
		}
	}
`;

export const Container = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 8px;
	/* padding: 31px 50px 50px 21px; */
	width: var(--Default-page-alignment-vale);
	height: 100%;
	margin-bottom: 60px;
	@media (max-width: 768px) {
		width: 100%;
	}
    .questions {
        display: flex;
        flex-direction: column;

		margin: 22px;

		.question{
			display: flex;
			flex-direction:column;

			h2 {
				color: #004d93;
				font-family: var(--Roboto-font-family);
				font-weight: 500;
				margin:18px 18px 10px 18px;
				font-size: min(20px, 1.5vw);
				@media (max-width: 768px) {
					font-size: 18px;
				}
			}
	
			p {
				color: #959595;
				font-family: var(--Roboto-font-family);
				font-weight: 400;
				font-size: min(16px, 1.4vw);
				margin-left: 25px;
				margin-bottom: 20px;
				line-height: 20px;
				@media (max-width: 768px) {
					font-size: 14px;
					max-width: 100%;
				}
			}
		}

    }
`;

