import { AxiosResponse } from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { insertMobileHeader } from '../../actions/MobileHeaderActions';
import { insertCustomizations } from '../../actions/ShopCustomizationsActions';
import api from '../../api';
import Header from '../../components/Default/header';
import SellerArea from '../../components/SellerArea/SellerArea';
import SellerStore, {
	ShopQueryReturn,
} from '../../components/SellerStore/SellerStore';
import { SearchPreset } from '../../interfaces/MobileHeader';
import UserAccount from '../../interfaces/UserAccount';
import { AppState } from '../../store';

import { Container } from './styles';

interface PageParams {
	userId: string;
	category: string;
	state: string;
	min_price: string;
	max_price: string;
}

const SelerStorePage: React.FC = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(insertMobileHeader(SearchPreset));
	});
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const pageParams = useParams<PageParams>();

	useEffect(() => {
		api.get('/get-shop-customizations', {
			params: {
				user_account_id: pageParams.userId,
			},
		}).then((response: AxiosResponse<ShopQueryReturn>) => {
			dispatch(insertCustomizations(response.data.shop[0]));
		});
	}, []);

	return (
		<Container>
			<Header />
			{/* not sure whats going on here */}
			{user?.id === pageParams.userId && !pageParams.min_price ? (
				<SellerStore />
			) : (
				<SellerStore />
			)}
		</Container>
	);
};

export default SelerStorePage;
