import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, PageContainer, AlignmentContainer } from './styles';
import OrderItem from './OrderItem/OrderItem';
import { useDispatch, useSelector } from 'react-redux';
import ProductsCart from '../../../interfaces/ProductsCart';
import { AppState } from '../../../store';
import CartReviewCard from '../ShippingMethodCart/CartReviewCard/CartReviewCard';
import UserAccount from '../../../interfaces/UserAccount';
import { currency, currencyDefault } from '../../../utils/mask';
import { calculateProductCartValue } from '../../../utils/CalculateProductCartValue';
import CartReviewCardMobile from '../ShippingMethodCart/CartReviewCard/CartReviewMobile/ReviewContainerMobile';

const OrderConfirmation: React.FC = () => {
	const history = useHistory();

	const [addressId, setAddressId] = useState<string>('');
	const [isSelected, setIsSelected] = useState<[boolean]>([false]);

	const productsCart: ProductsCart[] = useSelector(
		(state: AppState) => state.productsCart.currentCart
	);
	const [products, setProducts] = useState<string[]>([]);
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	useEffect(() => {
		if (user.Address) {
			user.Address.map(() => {
				var temp = isSelected;
				temp.push(false);
				setIsSelected(temp);
			});
		}
	}, [user]);
	const dispatch = useDispatch();
	useEffect(() => {
		setProducts(
			productsCart.map((item) => {
				return `${item.parameters.product.title} ${variationsLabel(
					item
				)}`;
			})
		);
	}, [productsCart]);

	function variationsLabel(item: ProductsCart) {
		return `${
			item.parameters.product.product_variations![0]
				? `Cor: ${
						item.parameters.product.product_variations![0].color
				  } ${
						item.parameters.product.product_variations![0]!
							.variation_sizes[0]
							? `Tamanho: ${
									item.parameters.product
										.product_variations![0]!
										.variation_sizes[0].size
							  }`
							: `Tamanho: U`
				  }`
				: ``
		}`;
	}

	function shippingMethodLabel() {
		return productsCart.map((item) => {
			if (item.parameters.shippingMethod === 'withdraw') {
				return `${item.parameters.product.title} ${variationsLabel(
					item
				)}, Retirada com o vendedor `;
			} else {
				return `${item.parameters.product.title} ${variationsLabel(
					item
				)}, Frete Correios, ${
					item.parameters.product.address?.street
				} - ${item.parameters.product.address?.zip_code} `;
			}
		});
	}

	return (
		<PageContainer>
			<AlignmentContainer>
				<Container>
					<h1>Finalização de compra</h1>
					<div className="informations-container">
						<div className="cardMobile">
							<OrderItem
								areaTitle="Produto:"
								itemList={[...products]}
								hasBorder={false}
								editPath={`/cart/${'confirmation'}`}
							/>
							<OrderItem
								areaTitle="Enviar para:"
								hasBorder={true}
								itemList={shippingMethodLabel()}
								editPath={`/cart/shipping-method/${'confirmation'}`}
							/>
							<p>
								Frete: <h5>Retirar com o vendedor</h5>
							</p>
							<div className="total-value">
								Total:{' '}
								<h3>
									{'R$ ' +
										String(
											currencyDefault(
												Number(
													calculateProductCartValue(
														productsCart,
														user
													)
												)
											)
										)
											.replace('.', ',')
											.substring(
												0,
												currencyDefault(
													Number(
														calculateProductCartValue(
															productsCart,
															user
														)
													)
												).toString().length - 2
											)}{' '}
								</h3>
								<h3 className="small-text">
									{String(
										currencyDefault(
											Number(
												calculateProductCartValue(
													productsCart,
													user
												)
											)
										)
									)
										.replace('.', ',')
										.substring(
											currencyDefault(
												Number(
													calculateProductCartValue(
														productsCart,
														user
													)
												)
											).toString().length - 2,
											currencyDefault(
												Number(
													calculateProductCartValue(
														productsCart,
														user
													)
												)
											).toString().length
										)}
								</h3>
							</div>
							{/* <OrderItem
									areaTitle="Endereço de envio"
									itemList={[
										`Travessa ney Azevedo 177, Novo Mundo, Curitiba, Paraná - 81050220 `,
									]}
									hasBorder={false}
									editPath=""
								/> */}
							{/* <OrderItem
									areaTitle="Forma de pagamento"
									hasBorder={true}
									itemList={[
										`${user.payment_method?.portuguese_name} ${user.payment_method?.card_id}`,
									]}
									editPath={`/cart/PaymentCartMethod/${'confirmation'}`}
								/>
								<OrderItem
									areaTitle="Condições de pagamento"
									hasBorder={false}
									itemList={[
										`${
											productsCart[0].installments === 1
												? `Pagamento à vista`
												: `Parcelado em:  ${productsCart[0].installments}`
										}`,
									]}
									editPath=""
								/> */}
						</div>
						<div className="main-card">
							<OrderItem
								areaTitle="Produto:"
								itemList={[...products]}
								hasBorder={false}
								editPath={`/cart/${'confirmation'}`}
							/>
							<OrderItem
								areaTitle="Forma de envio"
								hasBorder={true}
								itemList={productsCart.map((item) => {
									if (
										item.parameters.shippingMethod ===
										'delivery'
									) {
										return `${item.parameters.address?.street} ${item.parameters.address?.number}, ${item.parameters.address?.city}, ${item.parameters.address?.city}, ${item.parameters.address?.state} - ${item.parameters.address?.zip_code}`;
									}
									return 'Retirada no local';
								})}
								editPath={`/cart/shipping-method/${'confirmation'}`}
							/>
							{/* <OrderItem
									areaTitle="Endereço de envio"
									itemList={[
										`Travessa ney Azevedo 177, Novo Mundo, Curitiba, Paraná - 81050220 `,
									]}
									hasBorder={false}
									editPath=""
								/> */}
							{/* <OrderItem
								areaTitle="Forma de pagamento"
								hasBorder={true}
								itemList={[
									`${user.payment_method?.portuguese_name} ${user.payment_method?.card_id}`,
								]}
								editPath={`/cart/PaymentCartMethod/${'confirmation'}`}
							/>
							<OrderItem
								areaTitle="Condições de pagamento"
								hasBorder={false}
								itemList={[
									`${
										productsCart[0].installments === 1
											? `Pagamento à vista`
											: `Parcelado em:  ${productsCart[0].installments}`
									}`,
								]}
								editPath=""
							/> */}
						</div>
						<div className="ButtonConfirm">
							<button>Continuar</button>
						</div>
						<div className="cardMobile">
							<OrderItem
								areaTitle="Endereço de envio"
								itemList={[`Frete Correios - R$50,00 `]}
								hasBorder={false}
								editPath=""
							/>
							<OrderItem
								areaTitle="Endereço de envio:"
								hasBorder={true}
								itemList={shippingMethodLabel()}
								editPath={`/cart/shipping-method/${'confirmation'}`}
							/>
							{/* <OrderItem
								areaTitle="Forma de pagamento"
								hasBorder={true}
								itemList={[
									`${user.payment_method?.portuguese_name} ${user.payment_method?.card_id}`,
								]}
								editPath={`/cart/PaymentCartMethod/${'confirmation'}`}
							/> */}
							{/* <OrderItem
								areaTitle="Condições de pagamento"
								hasBorder={false}
								itemList={[
									`${
										productsCart[0].installments === 1
											? `Pagamento à vista`
											: `Parcelado em:  ${productsCart[0].installments}`
									}`,
								]}
								editPath=""
							/> */}
						</div>
						<CartReviewCard
							backButtonPath=""
							nextButtonPath={`/cart/order-confirmation/${'confirmation'}`}
							finalPage={true}
						/>
					</div>
				</Container>
			</AlignmentContainer>
		</PageContainer>
	);
};

export default OrderConfirmation;
