import React, { useEffect, useState } from 'react';
import { ReviewContainer } from './styles';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UserAccount from '../../../../interfaces/UserAccount';
import ProductsCart from '../../../../interfaces/ProductsCart';
import Spinner from '../../../Loader/Spinner';
import { AppState } from '../../../../store';
import {
	calculateProductCartValue,
	calculateShippingCartValue,
} from '../../../../utils/CalculateProductCartValue';
import api from '../../../../api';
import {
	CheckoutRequest,
	CheckoutResponse,
} from '../../../../interfaces/ApiResponses/Checkout';
import { AxiosResponse } from 'axios';
import { Base64 } from 'js-base64';
import ReviewContainerMobile from './CartReviewMobile/ReviewContainerMobile';
import useCheckMobileScreen from '../../../../utils/useCheckMobileScreen';
type Props = {
	backButtonPath: string;
	nextButtonPath: string;
	finalPage?: boolean;
};
interface PageParams {
	from_page: string;
}

const CartReviewCard: React.FC<Props> = ({
	backButtonPath,
	nextButtonPath,
	finalPage = false,
}) => {
	const history = useHistory();
	const pageParams = useParams<PageParams>();

	const { url } = useRouteMatch();

	const [productsValue, setProductsValue] = useState<string>('');

	const productsCart: ProductsCart[] = useSelector(
		(state: AppState) => state.productsCart.currentCart
	);
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);

	useEffect(() => {
		setProductsValue(
			(
				calculateProductCartValue(productsCart, user) +
				calculateShippingCartValue(productsCart)
			).toLocaleString('pt-br', {
				style: 'currency',
				currency: 'BRL',
			})
		);
	}, [productsCart]);

	const [loading, setLoading] = useState(false);

	const purchase = () => {
		setLoading(true);
		const data: CheckoutRequest = {
			products: productsCart.map((item) => {
				return {
					product_id: item.parameters.product.id,
					variation_id: item.parameters.product
						?.product_variations![0]?.id,
					variation_size_id: item.parameters.product
						.product_variations![0]?.variation_sizes[0]?.id,
					quantity: item.parameters.quantity,
					delivery_address_id: item.parameters.address?.id,
					shippingMethod: item.parameters.shippingMethod!,
				};
			}),
			installments: productsCart[0].installments!,
			payment_method: user.payment_method?.english_name!,
			credit_card_id: user.payment_method?.card_id,
		};
		api.post<CheckoutRequest, AxiosResponse<CheckoutResponse>>(
			'/checkout',
			data
		)
			.then((res) => {
				window.open((res.data as any).checkout_url, "_blank")
				history.push('/my-purchases')
			})
			.catch((err) => {
				setLoading(false);
				alert('Falha ao efetuar transação');
			});
	};

	/* ALSO USED IN src\components\BuyProcess\Review\Review.tsx */
	return (
		<>
			{useCheckMobileScreen() ? (
				<ReviewContainerMobile
					backButtonPath={backButtonPath}
					nextButtonPath={nextButtonPath}
					finalPage={finalPage}
				/>
			) : (
					<ReviewContainer>
						{/* {image && <img src={image} alt="" />} */}
						<div className="review">
							<div className="products">
								<span className="static-text">Produtos:</span>
								{productsCart.map((item) => (
									<span className="products-name">
										{`${item.parameters.quantity}x ${item.parameters.product.title}`}
									</span>
								))}
							</div>
							<div className="shipping-cost-container">
								<span className="title">Enviar para:</span>
								<div className="shipping-cost-and-time">
									{/* <img src={deliveryTruck} alt="" /> */}
									<div className="cost">
										{user.Address && user.Address?.length > 0 && (
											<>
												<span className="mid-text">
													{user.Address[0].street +
														', ' +
														user.Address[0].city +
														', ' +
														user.Address[0].state +
														'.'}
												</span>
												{productsCart.map((item) => (
													<h4
														className="title"
														style={{ marginTop: '8px' }}
													>
														Frete{' '}
														{`${item.parameters.quantity}x ${item.parameters.product.title}:`}
														<span className="green-span">
															{item.parameters
																.shippingMethod ===
																'delivery'
																? `${item.parameters.shippingValue}`
																: 'Retirar com o vendedor'}
														</span>
													</h4>
												))}
											</>
										)}
									</div>
								</div>
							</div>
							<div className="total-container">
								<span className="total-value-title">Total:</span>
								<div className="value">
									<h2>
										{' '}
										{String(productsValue).substring(
											0,
											productsValue.toString().length - 2
										)}{' '}
									</h2>
									<h2 className="small-text">
										{String(productsValue).substring(
											productsValue.toString().length - 2,
											productsValue.toString().length
										)}
									</h2>
								</div>
							</div>
						</div>
						<div className="review-actions">
							{!finalPage && (
								<>
									<button
										className="back-button"
										onClick={() => history.push(backButtonPath)}
									>
										Voltar
								</button>

									<button
										className="next-button"
										disabled={
											url.includes('PaymentCartMethod') &&
											!user.payment_method
										}
										onClick={() => {
											if (
												pageParams.from_page ===
												'confirmation'
											) {
												history.push(
													`/cart/order-confirmation/${'card'}`
												);
											} else {
												history.push(nextButtonPath);
											}
										}}
									>
										{finalPage
											? 'Finalizar compra'
											: 'Continuar'}
									</button>
								</>
							)}

							{finalPage && (
								<>
									<button
										className="next-button"
										onClick={() => purchase()}
										disabled={loading}
										style={{ opacity: loading ? '0.5' : '1' }}
									>
										{!loading ? (
											finalPage ? (
												'Finalizar compra'
											) : (
													'Continuar'
												)
										) : (
												<span
													style={{
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}
												>
													<Spinner />
												</span>
											)}
									</button>
								</>
							)}
						</div>
					</ReviewContainer>
				)}
		</>
	);
};

export default CartReviewCard;
