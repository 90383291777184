import UserAccount, {
	Address,
	InsertAddress,
	InsertUser,
	InsertUserCard,
	InsertUserFavorite,
	PaymentMethod,
	RemoveAddress,
	RemoveUser,
	RemoveUserCard,
	RemoveUserFavorite,
	SetPaymentMethod,
	UpdateAddress,
	UpdatePhotoName,
	UserCards,
	Favorites,
} from '../interfaces/UserAccount';

export const insertUser = (user: UserAccount): InsertUser => ({
	type: 'INSERT_USER',
	user,
});
export const removeUser = (): RemoveUser => ({
	type: 'REMOVE_USER',
});
export const updatePhotoName = (photoName: string): UpdatePhotoName => ({
	type: 'UPDATE_PHOTO_NAME',
	photoName,
});
export const insertAddress = (address: Address): InsertAddress => ({
	type: 'INSERT_ADDRESS',
	address,
});
export const updateAddress = (address: Address): UpdateAddress => ({
	type: 'UPDATE_ADDRESS',
	address,
});
export const removeAddress = (address_id: string): RemoveAddress => ({
	type: 'REMOVE_ADDRESS',
	address_id,
});

export const setPaymentMethod = (
	paymentMethod: PaymentMethod
): SetPaymentMethod => ({
	type: 'SET_PAYMENT_METHOD',
	paymentMethod,
});

export const insertUserCard = (card: UserCards): InsertUserCard => ({
	type: 'INSERT_USER_CARD',
	card,
});
export const removeUserCard = (card_id: string): RemoveUserCard => ({
	type: 'REMOVE_USER_CARD',
	card_id,
});

export const insertUserFavorite = (favorite: Favorites): InsertUserFavorite => ({
	type: 'INSERT_USER_FAVORITE',
	favorite,
});

export const removeUserFavorite = (favorite_id: string): RemoveUserFavorite => ({
	type: 'REMOVE_USER_FAVORITE',
	favorite_id,
});
