import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { insertProductShippingWay } from '../../../../actions/ProductsCartActions';
import { insertUser } from '../../../../actions/UserActions';
import { useShippingValue } from '../../../../hooks/useShippingValue';
import ProductsCart from '../../../../interfaces/ProductsCart';
import UserAccount, { Address } from '../../../../interfaces/UserAccount';
import { AppState } from '../../../../store';
import Spinner from '../../../Loader/Spinner';
import { ItemContainer, NewAddressButton } from '../styles';

// import { Container } from './styles';

const ShippingMethodItemCart: React.FC = () => {
	const productsCart: ProductsCart[] = useSelector(
		(state: AppState) => state.productsCart.currentCart
	);
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [isDelivery, setIsdelivery] = useState<boolean>(true);
	const { getValues, isLoading } = useShippingValue({
		onComplete: (result) => {},
		onError: (err) => {},
	});

	const dispatch = useDispatch();

	const calculateMultipleShippingValue = async (productsCart: ProductsCart[], address: Address) => {
		for (const product of productsCart) {
			await calculateShippingValue(product, address);
		}
	}

	const insertMultipleProductShippingWay = async (productsCart: ProductsCart[]) => {
		for (const product of productsCart) {
			dispatch(
				insertProductShippingWay(
					product.parameters.product.id,
					'withdraw',
					product.cart_product_id
				)
			)
		}
	}

	const checkMultipleShippingValue = (productsCart: ProductsCart[], address?: Address) =>
		address ?
			productsCart.find(
				product => product.parameters.address?.id === address.id && product.parameters.shippingMethod === 'delivery'
			) !== undefined
			:
			productsCart.find(
				product => product.parameters.shippingMethod === 'delivery'
			)

	const calculateShippingValue = async (
		productCart: ProductsCart,
		address: Address
	) => {
		const result = await getValues({
			productId: productCart.parameters.product.id,
			destinationZipCode: address.zip_code,
		});
		const value =
			result && result[0].value.length > 0
				? result[0].value
				: 'Não foi encontrado precificação, entre em contato com o vendedor. Certifique-se se o seu endereço está correto.';
		dispatch(
			insertProductShippingWay(
				productCart.parameters.product.id,
				'delivery',
				productCart.cart_product_id,
				value,
				address
			)
		);
	};

	const itemName = (item: ProductsCart) =>
		`${item.parameters.product.title} ${item.parameters.product
			.product_variations![0]
			? `Cor: ${item.parameters.product
				.product_variations![0]
				.color
			} ${item.parameters.product
				.product_variations![0]!
				.variation_sizes[0]
				? `Tamanho: ${item.parameters
					.product
					.product_variations![0]!
					.variation_sizes[0]
					?.size
				}`
				: `Tamanho: U`
			}`
			: ``
		}`

	// Get all the products by shop
	const productsByShop: { [key: string]: ProductsCart[] } = {};

	for (let index = 0; index < productsCart.length; index++) {
		const product = productsCart[index];

		// Check if exists
		if (!productsByShop[product.parameters.product.user_account_id]) {
			productsByShop[product.parameters.product.user_account_id] = [];
		}

		productsByShop[product.parameters.product.user_account_id].push(product);
	}

	const history = useHistory();

	console.log({ productsByShop })

	return (
		<ItemContainer style={{ borderTop: 'none' }}>
			<h2>Selecione a forma de envio</h2>
			<div className="options-container">
				{Object.entries(productsByShop).map(([_, items]) => (
					<>
						<h2 className='product-title'>
							{items.map((item) => itemName(item)).join(', ')}
						</h2>
						{user.Address!.map((address) => (
							<>
								{!isLoading ? (
									<div className="item">
										<div
											className="selector"
											onClick={() =>
												calculateMultipleShippingValue(
													items,
													address
												)
											}
										>
											{checkMultipleShippingValue(items, address) && (
												<div className="active-selector" />
											)}
										</div>
										<div className="option-textMobile">
											<span
												className={
													isDelivery
														? 'span-blue'
														: ''
												}
											>
												Frete Correios - R$17,90
											</span>
										</div>
										<div className="option-text">
											<span
												className={
													checkMultipleShippingValue(items, address)
														? 'span-blue'
														: ''
												}
											>
												Endereço{' '}
												{`${address.street} - ${address.zip_code}`}{' '}
												{/* - R$17,90 */}
											</span>
										</div>
									</div>
								) : (
										<Spinner />
									)}
							</>
						))}{' '}
						{!items.some((item) => !item.parameters.product.allow_withdraw) &&
							<div className="item">
								<div
									className="selector"
									onClick={() => insertMultipleProductShippingWay(items)}
								>
									{
										!checkMultipleShippingValue(items)
										&& (
											<div className="active-selector" />
										)}
								</div>
								<div className="option-text">
									{' '}
									<span
										className={
											!checkMultipleShippingValue(items)
												? 'span-blue'
												: ''
										}
									>
										Retirar no estabelecimento
								</span>
									<p>
										Disponível no endereço{' '}
										{items.map(
											(item) => `${item.parameters.product?.address?.street} - ${item.parameters.product?.address?.zip_code}`
										).join(', ')}
									</p>
								</div>
							</div>}
					</>
				))
				}
			</div>
			<NewAddressButton onClick={() => {
				history.push('/profile/user-information')
			}}>Adicionar novo endereço</NewAddressButton>
		</ItemContainer>
	);
};

export default ShippingMethodItemCart;
