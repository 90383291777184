import styled from 'styled-components';
export const Container = styled.div`
	/* width: 890px; */
	height: 203px;
	font-family: var(--Roboto-font-family);
	background: #ffffff 0% 0% no-repeat padding-box;
	margin-bottom: 16px;
	border-radius: 8px;
	align-items: center;
	display: flex;
	flex-direction: row;
	position: relative;
	cursor: pointer;
	@media (max-width: 768px) {
		min-height: 180px;
		margin-bottom: 8px;
	}
	@media (min-width: 768px) {
		padding-left: 40px;
	}
	.more-colorsMobile {
		z-index: 0;
		bottom: 25px;
		display: block;
		opacity: 1;
		width: 48px;
		font-size: 13px;
		font-weight: 900;
		@media (min-width: 768px) {
			display: none;
		}
	}

	.more-colors {
		display: none;
		@media (min-width: 768px) {
			/* bottom: 25px; */
			z-index: 0;
			opacity: 1;
			display: block;
		}
	}

	figure {
		display: flex;
		/* flex-direction: row; */
		align-items: center;
		justify-content: center;
		img {
			width: 140px;
			height: 149px;
			object-fit: cover;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			@media (max-width: 768px) {
				width: 99px;
				height: 96px;
				padding-left: 10px;
				margin-top: -50px;
			}
		}
	}
	.product-infos {
		font-size: 10px;
		width: 100%;
		margin-left: 50px;
		/* overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap; */
		@media (max-width: 768px) {
			/* display: grid; */
			margin-left: 0px;
			/* grid-template-rows: 0.4fr 1fr 1fr; */
			align-items: baseline;
			/* background: red; */
			padding: 17px 15px;
			width: 150%;
		}

		span {
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			color: #888888;
			padding: 8px;
		}
		h3 {
			font-size: 14px;
			width: 150px;
			word-break: break-word;
			color: #353a40;
			font-family: var(--Roboto-font-family);
			font-weight: 300;
			height: 31px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		h4 {
			font-size: 20px;
			line-height: 20px;
			font-weight: 400;
			word-break: break-word;
			color: #353a40;
			font-family: var(--Roboto-font-family);
			height: 38px;
			overflow: hidden;
			width: 70%;
			margin-bottom: 10px;
			@media (max-width: 768px) {
				height: 38px;
				width: 90%;
				overflow: hidden;
			}
		}
		h5 {
			font-size: 40px;
			margin-bottom: 8px;
			color: #002793;
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			@media (max-width: 768px) {
				margin-top: -10px;
			}
		}
		.buy-button {
			display: flex;
			justify-content: center;
			padding: 8px;
			padding-bottom: 8px;
			position: absolute;
			button {
				background: #d29800;
				width: 154px;
				border: none;
				height: 28px;
				border-radius: 12px;
				color: white;
				font-family: var(--Roboto-font-family);
				font-weight: bold;
				font-size: min(13px, 2.3vw);
				position: relative;
			}
		}

		.small-text {
			font-size: 18px;
		}

		.active {
			display: flex;
			position: absolute;
		}
	}
`;
export const InfosContainer = styled.div`
	display: flex;
	margin-right: 40px;
	width: 100%;
	height: 149px;
	@media (max-width: 768px) {
		flex-direction: row;
		height: 182px;
		margin-right: 0px;
	}
	.mobile {
		@media (max-width: 768px) {
			display: none;
		}
	}
	.row-stars-mobile {
		display: none;
		@media (max-width: 768px) {
			display: flex;
			width: 100%;
			padding-top: 5px;
			justify-content: flex-start;
		}
		/* order:-1; */
	}
	.stars-mobile {
		font-family: var(--Roboto-font-family);
		font-size: 10px;
		display: flex;
		align-items: center;
		@media (max-width: 768px) {
		}

		svg {
			width: 17px;
			height: 16px;
			fill: #f49d37;
		}
	}
`;

export const DefaultRow = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	align-items: baseline;
	@media (max-width: 768px) {
	}
	.row-prices {
		display: flex;
		flex-direction: column;
	}
	.col-price-stars {
		margin-top: 26px;
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: flex-end;
	}
	.rowPrice {
		display: flex;
		align-items: baseline;
		h5 {
			font-size: 30px;
			@media (max-width: 768px) {
				font-size: 26px;
			}
		}
		.small-text {
			font-size: 18px;
			@media (max-width: 768px) {
				font-size: 15px;
			}
		}
	}
	.row-stars {
		display: flex;
		width: 100%;
		justify-content: flex-end;
		@media (max-width: 768px) {
			display: none;
		}
	}
	.stars {
		font-family: var(--Roboto-font-family);
		font-size: 10px;
		display: flex;
		align-items: center;
		@media (max-width: 768px) {
		}

		svg {
			width: 17px;
			height: 16px;
			fill: #f49d37;
		}
	}
`;

export const DefaultColumn = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const PromotionLabel = styled.label`
	position: absolute;
	left: -6px;
	top: 10px;
	background-color: #0fd594;
	width: 60px;
	font-size: 15px;
	font-weight: medium;
	color: #ffffff;
	padding: 6px 0;
	border-radius: 4px;
	text-align: center;
	max-height: 20px;
`;

export const OriginalPrice = styled.span`
	font-size: 12px;
	width: 150px;
	text-decoration: line-through;
	word-break: break-word;
	color: #353a40;
	font-family: var(--Roboto-font-family);
	font-weight: 300;
	height: 25px;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const MoreColors = styled.label`
	font-family: var(--Roboto-font-family);
	/* position: absolute; */
	left: 19px;
	bottom: 80px;
	transition: all 0.5s;
	padding: 6px 10px;
	width: 86px;
	background-color: #707070;
	border-radius: 6px;
	font-size: 10px;
	font-weight: medium;
	letter-spacing: 0px;
	text-align: center;
	color: #ffffff;
	opacity: 0;
	cursor: pointer;
`;

export const MoreColorsMobile = styled.label`
	font-family: var(--Roboto-font-family);
	/* position: absolute; */
	left: 19px;
	bottom: 80px;
	transition: all 0.5s;
	padding: 6px 10px;
	width: 86px;
	background-color: #707070;
	border-radius: 6px;
	font-size: 10px;
	font-weight: medium;
	letter-spacing: 0px;
	text-align: center;
	color: #ffffff;
	opacity: 0;
	cursor: pointer;
`;
