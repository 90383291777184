import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Input } from 'semantic-ui-react';
import { insertUser } from '../../actions/UserActions';
import api from '../../api';
import UserAccount from '../../interfaces/UserAccount';
import { AppState } from '../../store';
import { Container, InputsContainer } from './styles';

interface UpdateUserFormInputs {
	name: string;
	phone: string;
	email: string;
}

const ChangeUserInformation: React.FC = () => {
	const history = useHistory();
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const { register, handleSubmit, errors } = useForm<UpdateUserFormInputs>();
	const dispacth = useDispatch();
	const onSubmit = (data: any) => {
		try {
			api.put('/update-user', data).then((response) => {
				dispacth(insertUser(response.data.user));
				history.push('/profile/user-information');
				alert('Dados enviados com sucesso');
			});
		} catch (error) {
			alert('Falha ao alterar os dados');
		}
	};

	return (
		<Container>
			<form onSubmit={handleSubmit(onSubmit)}>
				<InputsContainer>
					<h2 className="modal-title">Alterar Dados Cadastrados</h2>
					<div className="first-line">
						<input
							placeholder="Nome"
							name="name"
							defaultValue={user.name}
							ref={register({ required: true })}
						/>
						<input
							placeholder="E-mail"
							name="email"
							type="email"
							ref={register({ required: true })}
							defaultValue={user.email}
						/>
						<input
							placeholder="Telefone"
							name="phone"
							type="text"
							ref={register({ required: true })}
							defaultValue={user.Contacts![0]!.phone}
						/>
					</div>
				</InputsContainer>
				<button type="submit">Alterar dados</button>
			</form>
		</Container>
	);
};

export default ChangeUserInformation;
