import React from 'react';
import Header from '../../components/Default/header';
import Login from '../../components/Login/Login';
import LoginHeader from '../../components/Login/LoginHeader/LoginHeader';

import { Container } from './styles';

const LoginPage: React.FC = () => {
	return (
		<Container>
			<LoginHeader />
			<Login />
		</Container>
	);
};

export default LoginPage;
