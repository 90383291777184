import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	background: white;
	display: flex;
	flex-direction: column;
	border-radius: 12px;
	height: max-content;
	@media (max-width: 768px) {
		background: #f5f5f5;
	}

	.mobileContainer {
		display: none;
		@media (max-width: 768px) {
			display: flex;
			flex: 2;
			flex-direction: column;
		}
	}
	h3 {
		display: flex;
	}
	.rowPrice {
		@media (max-width: 768px) {
			display: flex;
			align-items: baseline;
		}

		h2 {
			color: #20256c;
			font-family: var(--Roboto-font-family);
			font-size: 26px;
			font-weight: normal;
			margin-top: 16px;
			@media (max-width: 768px) {
				font-size: 25px;
			}
		}
		span {
			text-decoration: underline;
			font-size: 14px;
			font-family: var(--Roboto-font-family);
			color: #8a99a8;
			cursor: pointer;
			display: flex;
			flex-direction: row;
			align-items: center;
			text-align: center;
			@media (max-width: 768px) {
				font-size: 1;
			}
		}
	}
	.rowCounterAndColor {
		@media (max-width: 768px) {
			display: flex;
			flex-direction: row-reverse;
		}
	}
	.rowProduc-Infos {
		display: flex;
		flex-direction: column;

		p {
			color: #353a40;
			font-family: var(--Roboto-font-family);
			max-width: 210px;
			font-size: 20px;
			@media (max-width: 768px) {
				max-width: 100%;
				overflow-y: hidden;
			}
		}

		h3 {
			color: #353a40;
			font-family: var(--Roboto-font-family);
			font-size: 20px;
			max-width: 400px;
			font-weight: normal;
		}
		h4 {
			color: #353a40;
			font-family: var(--Roboto-font-family);
			font-size: 14px;
			font-weight: normal;
			margin-top: 16px;
		}
		h2 {
			color: #20256c;
			font-family: var(--Roboto-font-family);
			font-size: 26px;
			font-weight: normal;
			margin-top: 16px;
			@media (max-width: 768px) {
				font-size: 25px;
			}
		}

		.counter {
			display: flex;
			flex-direction: row;
			color: #5a5a5a;
			font-family: var(--Roboto-font-family);
			font-size: 18px;
			@media (max-width: 768px) {
				align-items: center;
				height: 21px;
				padding-top: 13px;
			}

			.span-div {
				user-select: none;
				text-decoration: none;
				width: 21px;
				height: 21px;
				background: #eaeef3;
				color: #8a99a8;
				font-size: 20px;
				font-weight: bold;
				text-align: center;
				cursor: pointer;
				@media (max-width: 768px) {
					width: 21px;
					height: 21px;
					font-size: 15px;
				}
			}
			h4 {
				font-size: 18px;
				margin-left: 15px;
				margin-right: 15px;
				color: #2b63fc;
				font-size: 20px;
				font-weight: bold;
				@media (max-width: 768px) {
					margin-top: 0px;
				}
			}
		}
	}
	.my-product {
		padding: 16px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		@media (max-width: 768px) {
			padding: 0;
		}
		.small-text {
			@media (max-width: 768px) {
				font-size: 20px;
			}
		}

		h1 {
			/* @media (max-width: 768px){
				font-size: 320px;
			} */
		}
		h2 {
			font-size: min(20px, 1.8vw);
			font-family: var(--Roboto-font-family);
			font-weight: 500;
			color: #353a40;
			@media (max-width: 768px) {
				/* /* font-size: 12px; */
				font-size: 26px;
				color: #20256c;
			}

			h2 {
				@media (max-width: 768px) {
				}
				font-size: 20px;
			}
		}

		.infos-container {
			display: flex;
			//flex: 1;
			flex-direction: row;
			border-bottom: 1px solid #c5ced7;
			/* border-radius: 4px; */
			justify-content: space-between;
			padding: 16px;
			@media (max-width: 768px) {
				border: none;
				background: white;
				flex-direction: row;
				border-radius: 12px;
				display: flex;
				margin-bottom: 6px;
				height: max-content;
				padding: 16px 16px 24px;
				justify-content: flex-start;
				column-gap: 20px;
			}

			.foto {
				width: 102px;
				height: 107px;
				background: #eeeeee;
				margin-right: 25px;
				@media (max-width: 768px) {
					margin-right: 0;
					width: 86px;
					height: 91px;
					flex: 1;
				}
			}

			.product-info {
				display: flex;
				flex-direction: column;
				max-width: 600px;
				@media (max-width: 768px) {
					display: none;
				}

				.is-variation h3 {
					overflow-y: hidden;
					height: 25px;
				}

				h3 {
					color: #353a40;
					font-family: var(--Roboto-font-family);
					font-size: 20px;
					max-width: 400px;
					font-weight: normal;
				}
				h4 {
					color: #707070;
					font-family: var(--Roboto-font-family);
					font-size: 12px;
					font-weight: medium;
					margin-top: 6px;
					padding: 6px 14px;
					border-radius: 8px;
					background-color: #f2f2f2;
					min-height: 14px;
					max-height: 26px;
				}
				h2 {
					color: #20256c;
					font-family: var(--Roboto-font-family);
					font-size: 26px;
					font-weight: normal;
					margin-top: 16px;
					@media (max-width: 768px) {
						font-size: 25px;
					}
				}

				.small-text {
					font-size: 20px;
				}
			}

			.right-side-container {
				@media (max-width: 768px) {
					display: none;
				}
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				flex: 1;
				justify-content: space-between;
				span {
					text-decoration: underline;
					font-size: 14px;
					font-family: var(--Roboto-font-family);
					color: #8a99a8;
					cursor: pointer;
					display: flex;
					flex-direction: row;
					align-items: center;
					text-align: center;
				}

				.counter {
					display: flex;
					flex-direction: row;
					color: #5a5a5a;
					font-family: var(--Roboto-font-family);
					font-size: 18px;
					/* 
					margin-top: 28px; */

					.span-div {
						user-select: none;
						text-decoration: none;
						width: 21px;
						height: 21px;
						background: #eaeef3;
						color: #8a99a8;
						font-size: 20px;
						font-weight: bold;
						text-align: center;
						cursor: pointer;
					}
					h4 {
						font-size: 18px;
						margin-left: 15px;
						margin-right: 15px;
						color: #2b63fc;
						font-size: 20px;
						font-weight: bold;
					}
				}
			}
		}
	}
`;

export const DefaultRow = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	align-items: flex-end;

	@media (max-width: 768px) {
		align-items: baseline;
		height: 42px;
		margin-left: 0;
	}
`;
export const DefaultColumn = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const NoProducts = styled.div`
	height: 187px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	background: #fff;
	@media (max-width: 768px) {
		border-radius: 8px;
		width: 100%;
	}

	.emoji-and-text {
		margin-left: 50px;
		display: flex;
		flex-direction: row;
		align-items: center;
		@media (max-width: 768px) {
			width: 100%;
			margin-left: 0;
			flex-direction: column;
		}

		.emoji {
			width: 60px;
			height: 60px;
		}

		h2 {
			color: #353a40;
			font-size: 20px;
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			margin-left: 22px;
			text-align: center;
			@media (max-width: 468px) {
				max-width: 221px;
				text-align: center;
			}
		}
	}
`;
