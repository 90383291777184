import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { insertMobileHeader } from '../../actions/MobileHeaderActions';
import Header from '../../components/Default/header';
import ProductRegister from '../../components/ProductRegister/ProductRegister';
import { BackPreset } from '../../interfaces/MobileHeader';
import UserAccount from '../../interfaces/UserAccount';
import { AppState } from '../../store';
import { Container } from './styles';

const ProductRegisterPage: React.FC = () => {
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(insertMobileHeader({
			...BackPreset,
			leftSideComp: {
				leftSide: 1,
				backArrowPath: `/seller-area/${user.id}/all/0/100000000/undefined/products`
			}
		}))
	});
	return (
		<Container>
			<Header></Header>
			<ProductRegister />
		</Container>
	);
};

export default ProductRegisterPage;
