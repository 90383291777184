import React from 'react';
import CardSallers from './CardSallers/CardSallers';
import { Container } from './styles';
import { BsSearch } from 'react-icons/bs';
import HeaderAdmin from '../HeaderAdmin/HeaderAdmin';
import { useState } from 'react';
import { useEffect } from 'react';
import api from '../../api';
import UserAccount from '../../interfaces/UserAccount';

const Saller: React.FC = () => {
	const [sellers, setSellers] = useState<UserAccount[]>([]);
	const [searchStores, setSearchStores] = useState<UserAccount[]>([]);

	useEffect(() => {
		api.get('/get-all-shops')
			.then((response: any) => {
				setSellers(response.data.shops);
				setSearchStores(response.data.shops);
			})
			.catch((err) => {});
	}, []);

	const handleSearchSeller = (storeSearch: string) => {
		setSearchStores(
			sellers!.filter((store) => {
				return store.shop_customizations?.shop_name
					.toLocaleLowerCase()
					.includes(storeSearch.toLowerCase());
			})
		);
	};

	return (
		<>
			<HeaderAdmin />
			<Container>
				<div className="sallers-stors">
					<p>Vendedores e lojas cadastrados</p>
					<div className="icon-input">
						<BsSearch className="icon" />
						<input
							placeholder="Buscar"
							type="text"
							onChange={(e: any) =>
								handleSearchSeller(e.target.value)
							}
						></input>
					</div>
				</div>
				{
					<div className="cards-sallers">
						{searchStores.map((seller) => (
							<CardSallers userAccount={seller} />
						))}
					</div>
				}
			</Container>
		</>
	);
};
export default Saller;
