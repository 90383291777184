import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ProductAreas from './ProductAreas/ProductAreas';
import ProductDetails from './ProductDetails/ProductDetails';
import ProductInfos from './ProductInfos/ProductInfos';

import {
	CardsContainer,
	Container,
	DailyPromotion,
	DescriptionAndReputation,
	ProductInfosCard,
	AlignmentContainer,
	DefaultRow,
	SideInfoContainer,
	ProductsSeller,
	ProductNotFound,
	ModalContainer,
} from './styles';
import { IProduct } from '../../Pages/ProductPage/ProductPage';
import SellerReputationCard from './ProductAreas/SellerReputationCard/SellerReputationCard';
import { useSelector, useDispatch } from 'react-redux';
import { insertProducts } from '../../actions/ProductsList';
import api from '../../api';
import { ProductsList, AreaTypes } from '../../interfaces/ProductsList';
import { AppState } from '../../store';
import { productExibitionRules } from '../../utils/productsExibitionRules';
import ProductCard from '../Default/ProductCard/productCard';
import QuestionsCard from './ProductAreas/QuestionsCard/QuestionsCard';
import ProductReviews from './ProductAreas/ProductReviews/ProductReviews';
import ProductSideInfos from './ProdutctSideInfos/ProductSideInfos';
import ProductCardSmall from './ProductCardSmall/ProductCardSmall';
import { IoMdCloseCircle } from 'react-icons/io';
import { ProductVariations } from '../../interfaces/ProductVariations';
import { reOrderProductVariations } from '../../utils/reOrderProduct';
import ProductSideInfosMobile from './ProductSideInfosMobile/ProductSideInfosMobile';
import UserAccount from '../../interfaces/UserAccount';
import Modal from 'react-modal';
import BlockProductModal from './BlockProductModal/BlockProductModal';
import BlockSellerModal from './BlockSellerModal/BlockSellerModal';

type Props = {
	product: IProduct;
};
const Product: React.FC<Props> = ({ product }) => {
	const productsList: ProductsList[] = useSelector(
		(state: AppState) => state.productsList?.currentProductsList
	);
	const dispatch = useDispatch();
	const [open, setIsOpen] = useState(false);
	const [openAds, setIsOpenAds] = useState(false);

	const history = useHistory();

	const [
		selectedVariation,
		setSelectedVariation,
	] = useState<ProductVariations>();
	const [orderedProduct, setOrderedProduct] = useState<IProduct>();
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);

	useEffect(() => {
		if (product) {
			api.post(`/list-store-products`, {
				params: { user_account_id: product.user_account_id },
			}).then((response: any) => {
				dispatch(
					insertProducts(
						productExibitionRules(
							response.data.data.response,
							AreaTypes.HOMEPAGE
						),
						AreaTypes.HOMEPAGE
					)
				);
			});

			if (
				product.product_variations &&
				product.product_variations.length > 0
			) {
				setSelectedVariation(product.product_variations[0]);
				setOrderedProduct({
					...product,
					product_variations: reOrderProductVariations(
						product.product_variations
					),
				});
			}
		}
	}, [product]);

	const moreThanOneProduct = () => {
		try {
			if (
				productsList.find(
					(item) => item!.areaTitle === AreaTypes.HOMEPAGE
				)!.products!.length > 1
			) {
				return true;
			} else {
				return false;
			}
		} catch (err) {
			return false;
		}
	};

	return (
		<>
			<BlockProductModal
				product={product}
				setOpen={setIsOpenAds}
				open={openAds}
			/>
			<BlockSellerModal
				userId={product.user_account_id}
				setOpen={setIsOpen}
				open={open}
			/>

			<Container>
				<AlignmentContainer>
					{product && (
						<>
							{true /* {user && user.user_types.name === 'Administrator' && ( */ && (
								<div className="row-buttons">
									<p style={{cursor: 'pointer', marginLeft: '12px'}} onClick={() => {
										history.goBack();
									}}>Voltar</p>
									<div className="buttons-block">
										{!product.user_accounts
											.blocked_seller && user?.user_types?.name === 'Administrator' && (
											<div
												className="buttons-sallers"
												onClick={() => {
													setIsOpen(true);
												}}
											>
												Bloquear vendedor
											</div>
										)}

										{!product.blocked_announcement && user?.user_types?.name === 'Administrator' && (
											<div
												className="buttons-ads"
												onClick={(e) => {
													setIsOpenAds(true);
												}}
											>
												Bloquear anúncios
											</div>
										)}
									</div>
								</div>
							)}
							<ProductInfosCard>
								<CardsContainer>
									<ProductInfos
										product={product}
										selectedVariation={selectedVariation}
									/>
									<ProductSideInfosMobile
										product={
											orderedProduct
												? orderedProduct
												: product
										}
										setSelectedVariation={
											setSelectedVariation
										}
									/>
									{moreThanOneProduct() && (
										<ProductsSeller>
											<h2>
												Confira outros produtos do mesmo
												vendedor
											</h2>
											<div className="daily-promotions-container">
												{productsList
													.find(
														(item) =>
															item?.areaTitle ===
															AreaTypes.HOMEPAGE
													)
													?.products?.filter((p) => p.id !== product.id).map(
														(product, index) => (
															<>
																{index < 4 && (
																	<ProductCardSmall
																		product={
																			product
																		}
																	/>
																)}
															</>
														)
													)}
											</div>
										</ProductsSeller>
									)}
									<DescriptionAndReputation>
										<div className="description">
											<h2>Descrição do produto</h2>
											<p>{product.description}</p>
										</div>
										{/* 
								<div className="reputation">
								<SellerReputationCard />
							</div> */}
									</DescriptionAndReputation>

									<QuestionsCard
										questions={product.product_questions}
										product_id={product.id}
									/>

									<ProductReviews product={product} />
									{/* 
							<ProductDetails product={product} /> */}
								</CardsContainer>
								<SideInfoContainer>
									<ProductSideInfos
										product={
											orderedProduct
												? orderedProduct
												: product
										}
										setSelectedVariation={
											setSelectedVariation
										}
									/>
								</SideInfoContainer>
							</ProductInfosCard>
						</>
					)}
					{!product && (
						<ProductNotFound>
							<DefaultRow>
								<IoMdCloseCircle size={57} fill={'#C9182A'} />
								{
									{
										/* <h1>Anúncio removido pelo vendedor</h1> */
									}
								}
								<h1>Anúncio não encontrado</h1>
							</DefaultRow>
						</ProductNotFound>
					)}
					<DailyPromotion>
						<h2>Produtos Patrocinados</h2>
						<div className="daily-promotions-container">
							{productsList
								.find(
									(item) =>
										item?.areaTitle === AreaTypes.HOMEPAGE
								)
								?.products?.map((product, index) => (
									<>
										{index < 5 && (
											<ProductCard product={product} />
										)}
									</>
								))}
						</div>
					</DailyPromotion>
				</AlignmentContainer>
			</Container>
		</>
	);
};

export default Product;
