import styled from 'styled-components';

export const Container = styled.div`
	height: 100%;

	display: flex;
	flex-direction: column;
	padding: 0px 20px;
`;

export const NoProducts = styled.div`
	height: 43vh;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	background: #fff;

	@media (max-width: 768px) {
		border-radius: 8px;
		width: 100%;
	}

	.emoji-and-text {
		display: flex;
		flex-direction: column;
		align-items: center;

		.emoji {
			width: 96px;
			height: 96px;
		}

		h2 {
			color: #353a40;
			font-size: 20px;
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			margin-top: 22px;
			text-align: center;

			@media (max-width: 468px) {
				max-width: 221px;
				text-align: center;
			}
		}
	}
`;
