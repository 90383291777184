import styled from 'styled-components';

export const Container = styled.div`
	@media (min-width: 768px) {
		display: none;
	}
	display: flex;
	flex-direction: column;
	width: 90%;
	margin-top: 30px;
	padding: 0 5%;
	
	

	@media (max-width: 505px) {
		overflow-x: scroll;
	}

	.item {
		color: #707070;
		font-size: 16px;
		font-family: var(--Roboto-font-family);
		/* font-weight: 600; */
		text-decoration: none;
		box-shadow: 0px 3px 6px #00000029;
		background: #ffffff;
		height: 52px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-bottom: 10px;
		border-radius: 8px;
		justify-content: space-between;
		padding: 0px 17px;
	}

	.item-active {
		background: white;
		color: #2d327c;

		font-size: min(12px, 0.8vw);
		font-family: var(--Roboto-font-family);
		font-weight: 600;
		text-decoration: none;
		width: 206px;
		height: 57px;

		font-size: 16px;
		max-width: 220px;
		min-width: 79px;

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.button-container {
		/* width: 297px; */
		display: flex;
		flex-direction: column;
		/* justify-content: flex-end; */
		/* align-items: flex-end; */

		button {
			/* height: 90%;
			width: 30%; */
			/* max-width: 278px; */
			width: 100%;
			height: 57px;
			margin-bottom: 31px;
			font-size: 16px;
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			color: white;
			border: none;
			background: #2b63fc;
			border-radius: 8px;
		}
	}
`;
