import React, { useEffect, useState } from 'react';
import { Container, InputBanner, IntermediaryTitle } from './styles';
import config from '../../../config';
import api from '../../../api';
import { useHistory } from 'react-router-dom';

type Banner = {
	id: string;
	path_image: string;
	order: number;
};

export interface FormInputs {
	id: string;
}

const EditHome: React.FC = () => {
	const [active, SetActive] = useState<number>(0);
	const [activeMiddle, SetActiveMiddle] = useState<number>(-1);
	const [activeButton, setactiveButton] = useState<number>(-1);
	const [banners, setBanners] = useState<Banner[]>([]);
	const [middleBanners, setMiddleBanners] = useState<Banner[]>([]);

	useEffect(() => {
		api.get('/all-main-banner')
			.then((response: any) => {
				setBanners(response.data.mainBanner);
			})
			.catch((err) => {});
	}, []);

	useEffect(() => {
		api.get('/all-middle-banner')
			.then((response: any) => {
				setMiddleBanners(response.data.middleBanner);
			})
			.catch((err) => {});
	}, []);

	const saveNewBanner = async (data: any) => {
		const newData = new FormData();
		newData.append('newBanner', data[0]);

		api.post('/create-main-banner', newData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then(() => window.location.reload());
	};
	const saveNewMiddleBanner = async (id: string, file: any) => {
		const newData = new FormData();
		newData.append('updateMiddleBanner', file);
		newData.append('id', id);

		api.put('/update-middle-banner', newData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then(() => window.location.reload());
	};

	const changeMainBanner = async (id: string, file: any) => {
		const newData = new FormData();
		newData.append('updateMainBanner', file);
		newData.append('id', id);

		api.put('/update-main-banner', newData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then(() => window.location.reload());
	};

	const deleteMainBanner = async (id: string) => {
		api.delete('/delete-main-banner', {
			params: { main_banner_id: id },
		}).then(() => window.location.reload());
	};

	const history = useHistory();

	return (
		<Container>
			<div className="banners-main">
				<div className="text-button">
					<p>Banner em carrosel</p>
					<span onClick={() => history.push('/seller')}>Ver no site</span>
				</div>
				<div className="img">
					{banners
						.sort((a, b) => a.order - b.order)
						.map((banner, index) => (
							<div
								className={
									active === index
										? 'banner-img active'
										: 'banner-img'
								}
								onMouseOver={() => {
									SetActive(index);
								}}
								onMouseLeave={() => {
									SetActive(-1);
								}}
							>
								<img
									src={`${config.host}/static/banners-home-page/${banner.path_image}`}
									alt=""
								/>
								<div
									className={
										activeButton === 0
											? 'buttons active'
											: 'buttons'
									}
								>
									<InputBanner
										type="file"
										id="change-main-banner"
										name="changeMainBanner"
										onChange={(e: any) => {
											changeMainBanner(
												banner.id,
												e.target.files[0]
											);
										}}
									></InputBanner>
									{/* <div className="test"> */}
									<label
										htmlFor="change-main-banner"
										onClick={(e) => {
											setactiveButton(-1);
											// deleteMainBanner(banner.id);
										}}
									>
										substituir
									</label>
									<span
										onClick={(e) => {
											setactiveButton(-1);
											deleteMainBanner(banner.id);
										}}
									>
										excluir
									</span>
									{/* </div> */}
								</div>
							</div>
						))}
					<div className="img-add">
						<label htmlFor="new-banner">+ Novo banner</label>
						<input
							type="file"
							id="new-banner"
							name="newBanner"
							onChange={(e: any) => {
								saveNewBanner(e.target.files);
							}}
						></input>
					</div>
				</div>
			</div>
			<IntermediaryTitle>Banners intermediario</IntermediaryTitle>
			<div className="banners-intermediary">
				{middleBanners
					.sort((a, b) => a.order - b.order)
					.map((banner, i) => (
						<div className="banners-int">
							<div
								className={
									activeMiddle === i
										? 'img-int active '
										: 'img-int'
								}
								onMouseOver={() => {
									SetActiveMiddle(i);
								}}
								onMouseLeave={(e) => {
									SetActiveMiddle(-1);
											}}
							>
								{' '}
								<img
									src={`${config.host}/static/middle-banners/${banner.path_image}`}
									alt=""
								/>
								<label
									htmlFor={'new-middle-banner' + banner.id}
								>
									substituir
								</label>
								<InputBanner
									type="file"
									id={'new-middle-banner' + banner.id}
									name="newMiddleBanner"
									onChange={(e: any) => {
										saveNewMiddleBanner(
											banner.id,
											e.target.files[0]
										);
									}}
								></InputBanner>
							</div>
						</div>
					))}
			</div>
		</Container>
	);
};

export default EditHome;
