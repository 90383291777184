import styled from 'styled-components';

export const Container = styled.div`
	height: max-content;
	min-height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	background: #ffffff;
`;

export const AlignmentContainer = styled.div`
	width: var(--Default-page-alignment-vale);
	height: max-content;
	margin: auto;
`;

export const SuccessContainer = styled.div`
	width: 100%;
	height: 233px;
	background: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;

	.success-container {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;

		svg {
			margin-right: 18px;
		}
		.informations-and-buttons-container {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;

			h2 {
				color: #02c384;
				font-size: min(20px, 1.2vw);
				font-family: var(--Roboto-font-family);
				font-weight: medium;
				line-height: 38px;

				margin-bottom: 12px;
			}
			p {
				color: #888888;
				font-size: min(13px, 1vw);
				font-family: var(--Roboto-font-family);
				font-weight: normal;
				width: 440px;
				margin-bottom: 21px;
				line-height: 20px;
			}
			.buttons {
				display: flex;
				width: 100%;
				justify-content: flex-start;
				column-gap: 10px;

				.redirect-button {
					background: #114ae9;
					border-radius: 8px;
					width: 249px;
					height: 40px;
					border: none;

					color: white;
					font-size: min(13px, 1vw);
					font-family: var(--Roboto-font-family);
					font-weight: bold;
				}
				.print-button {
					border: solid 1px #114ae9;
					background: transparent;
					color: #114ae9;
					border-radius: 8px;

					width: 121px;
					height: 40px;

					font-size: min(13px, 1vw);
					font-family: var(--Roboto-font-family);
					font-weight: bold;
				}
			}
		}
	}
`;
export const LastViewsContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-start;
	align-items: flex-start;
	background: #f5f5f5;

	padding-top: 50px;
	margin-bottom: 45px;

	.text-and-cards-container {
		display: flex;
		flex-direction: column;

		h2 {
			color: #000000;
			font-size: min(24px, 2vw);
			font-family: var(--Roboto-font-family);
			font-weight: 300;

			margin-bottom: 35px;
		}

		.products-card {
			display: flex;

			column-gap: 25px;
		}
	}
`;
