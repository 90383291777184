import styled from 'styled-components';

export const PageContainer = styled.main`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	
	@media (max-width: 768px) {
		width: 90%;
		padding: 0 5%;
		justify-content: baseline;
		align-items: baseline;
		height: 100vh;
	}
	h1 {
		color: black;
		width: var(--Default-page-alignment-vale);
		font-family: var(--Roboto-font-family);
		font-weight: 300;
		font-size: 32px;
		margin-top: 47px;
		margin-bottom: 34px;
		@media (max-width: 4000px) {
			display: flex;
			justify-content: flex-start;
			
		}
	}
	p {
		@media (min-width: 768px) {
			display: none;
		}
		font-family: var(--Roboto-font-family);
		font-size: 26px;
		margin-top: 41px;
		font-weight: normal;
		margin-bottom: 34px;
		/* margin-left: 5%; */
		align-items: none;
		justify-content: none;
	}
	.favorite-mobile {
		display: flex;
	}
	.favorite-mobile p {
		/* margin-left: -96%;	 */
		width: 100%;
	}
`;

export const Container = styled.div`
	border-radius: 8px;
	width: var(--Default-page-alignment-vale);
	height: 100%;
	/* margin-bottom: 100px; */
	display: flex;
	row-gap: 12px;
	flex-direction: column;
	@media (max-width: 768px) {
		width: 100%;
	}
	h2 {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.2rem;
		font-family: var(--Roboto-font-family);
		font-weight: 400;
		padding: 30px 0px 35px;
		letter-spacing: var(--unnamed-character-spacing-0);
		color: var(---353a40);
		font: normal normal normal 20px/36px Roboto;
		letter-spacing: 0px;
		color: #353A40;
		opacity: 1;
		top: 286px;
		left: 658px;
	}
	.favorite-container{
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		background: #fff;
		border-radius: 8px;
		width: 1186px;
		height: 425px;
		@media (max-width: 800px){
			display: flex;
			align-items: center;
			justify-content: center;
			width:100%;	
			margin-left: 0.7em;
		}
		@media (max-width: 600px){
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 0.5em;
			
		}
	}

  .facet_sidebar
   {
    display: none;
   }


  .facet_sidebar
   {
    display: none;
   }
	
			
	}
	.sad-emoji{
		display: flex;
		flex-direction: column;
		align-items: center;
		
		
		.emoji {
			width: 96px;
			height: 96px;
		}
	}
	.no-fav-button{
				text-align: center;
				width: 379px;
				height: 40px;
				background: #2B63FC 0% 0% no-repeat padding-box;
				border-radius: 8px;
				opacity: 1;
				border:none;
				color: #fff;
				font-size: 16px;
				font-weight: 500;
				font-family: var(--Roboto-font-family); 
				margin-bottom: 40px;
			}
`;

export const NoFavorites = styled.div`
	height: 67vh;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #fff;

	@media (max-width: 768px) {
		border-radius: 8px;
		width: 100%;
	}

	.emoji-and-text {
		display: flex;
		flex-direction: column;
		align-items: center;

		.emoji {
			width: 96px;
			height: 96px;
		}

		h2 {
			color: #353a40;
			font-size: 20px;
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			margin-top: 22px;
			text-align: center;
			@media (max-width: 468px) {
				max-width: 221px;
				text-align: center;
			}
		}
	}
	.start-to-buy-button {
		width: 321px;
		height: 40px;
		background: #2b63fc;
		border: none;
		border-radius: 8px;

		@media (max-width: 468px) {
			width: 221px;
		}
		color: white;
		font-family: var(--Roboto-font-family);
		font-weight: 500;
		font-size: 16px;
		margin-top: 26px;
	}
`;
