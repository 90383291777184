import styled from 'styled-components';

export const PageContainer = styled.main`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	@media (max-width: 768px) {
		/* min-height: 700px; */
		width: 100%;
		height: 100vh;
		background: #f5f5f5;
		justify-content: flex-start;
	}
`;

export const AlignmentContainer = styled.div`
	width: var(--Default-page-alignment-vale);
	height: max-content;
	margin: auto;

	@media (max-width: 768px) {
		width: 90%;
		margin: 0px;
	}
`;

export const DefaultRow = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	gap: 25px;
	@media (max-width: 768px) {
		flex-direction: column;
		display: block;
	}
`;

export const Container = styled.div`
	width: 100%;
	margin-bottom: 70px;
	display: flex;
	flex-direction: column;
	background: #f5f5f5;

	height: 100%;
	@media (max-width: 768px) {
		margin-bottom: 200px;
		height: 100%;
	}
	h1 {
		margin-top: 45px;
		font-family: var(--Roboto-font-family);
		font-size: 26px;
		font-weight: normal;
		margin-bottom: 24px;
		color: #353a40;
	}
	h3 {
		color: #353a40;
		font-size: 20px;
		font-family: var(--Roboto-font-family);
		font-weight: normal;

		&.same-category {
			margin-bottom: 40px;
		}
		@media (max-width: 768px) {
			display: none;
		}
	}
`;

export const ProductsSeller = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 15px;
	padding-top: 60px;

	@media (max-width: 768px) {
		display: none;
	}
	.products {
		display: flex;
		justify-content: flex-start;
		column-gap: 20px;
	}
`;
