import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import ProductRegisterPage from '../Pages/ProductRegister/ProductRegisterPage';
import UserProfilePage from '../Pages/UserProfilePage/UserProfilePage';
import Footer from '../components/Footer/Footer';
import SellerStorePage from '../Pages/SellerStorePage/SellerStorePage';
import SellerNotificationPage from '../Pages/SellerNotificationPage/SellerNotificationPage';
import UpdateProductMobilePage from '../Pages/MobilePages/UpdateProductMobilePage/UpdateProductMobilePage';
import UserAddressMobilePage from '../Pages/MobilePages/UserAddressMobilePage/UserAddressMobilePage';
import AddPaymentMobilePage from '../Pages/AddPaymentMobile/AddPaymentMobile';
import ChangeUserInformationPage from '../Pages/MobilePages/ChangeUserInformationPage/ChangeUserInformationPage';
import SellerAreaPage from '../Pages/SellerAreaPage/SellerAreaPage';

const SellerRoutes: React.FC = () => {
	return (
		<>
			<Route path="/notification" component={SellerNotificationPage} />
			<Route
				exact
				path="/product-register"
				component={ProductRegisterPage}
			/>
			{/* <Route path="/profile" component={UserProfilePage} /> */}
			<Route exact path="/my-store/:userId" component={SellerStorePage} />
			<Route
				exact
				path="/my-store/:userId/:state/:min_price/:max_price/:category"
				component={SellerStorePage}
			/>
			<Route
				path="/update-product-mobile/:product_id"
				component={UpdateProductMobilePage}
			/>
			<Route
				exact
				path="/user-address-mobile"
				component={UserAddressMobilePage}
			/>
			<Route
				exact
				path="/user-address-mobile/:address_id"
				component={UserAddressMobilePage}
			/>
			<Route
				path="/add-payment-mobile/:from_page"
				component={AddPaymentMobilePage}
			/>
			<Route
				path="/change-information-mobile/"
				component={ChangeUserInformationPage}
			/>
		</>
	);
};
export default SellerRoutes;
