import { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import api from '../../../../../api';

import { Container } from './styles';

interface Props {
  closeModal(): void;
  email?: string;
}

const EmailSent: React.FC<Props> = ({ closeModal, email = ''}) => {

  const [reSent , setReSent] = useState<boolean>(false);

  const reSend = () =>{
		try {
			api.post('/forgot-password', {
				email: email,
			}).then((res: AxiosResponse<any>) => {
				if (res.status === 200) {
					setReSent(true);
				} else {
					window.alert('falha ao enviar email, verifique seu email');
				}
			});
		} catch (error) {
			//setDisabled(true);
		}
  };

  return (
    <Container>
      <RiCheckboxCircleFill fill="#02C384" size={69}/>
      <p>E-mail enviado</p>
      <button onClick={reSend}>Reenviar e-mail</button>
      <button className="back" onClick={closeModal}>Voltar para Login</button>
      <p className="observation">
        {reSent? "Reenviado!" : "Se este email existe na base de dados ele foi enviado!"}
      </p>
    </Container>
  );
};

export default EmailSent;
