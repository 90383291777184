import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	/* justify-content:space-between; */

	@media (max-width: 760px) {
		flex-direction: column;
	}

	margin-bottom: 15px;
	border-radius: 8px;
	border: solid 1px #e1e1e1;
	padding: 15px 15px 0 15px;
	margin: 20px 15px 20px 15px;
`;
export const ImageAndInfosContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
	width: 100%;
	height: 100%;

	@media (max-width: 768px){
		flex-direction: column;
	}
	.card {
		cursor: pointer;
		display: flex;
		flex-direction: row;
		border-right: solid 1px #e1e1e1;
		flex: 1 0 auto;
		@media (max-width: 768px){
			width: 90%;
			border: none;
		}
		img {
			max-width: 194px;
			max-height: 117px;
			object-fit: cover;
			@media (max-width: 768px){
				width: 60px;
				height: 60px;
			}
		}
		/* justify-content:space-between; */
		.infos-container {
			width: inherit;
			@media (max-width: 768px){
				width: 100%;
				
			}
			.statusShipping{
				display: none;
				@media (max-width: 768px){
					display: flex;
				}
			}
					p{
						color: #5A646E;
						font-size: 12px;
					}
			span{
				@media (max-width: 768px){
					font-size: 20px;
					margin: 0;
				}
			}
			h3 {
				color: #b1b1b1;
				font-size: 13px;
				font-family: var(--Roboto-font-family);
				margin-left: 15px;
				@media (max-width: 768px){
					font-size: 20px;

				}
			}
			h2 {
				font-size: 20px;
				font-family: var(--Roboto-font-family);
				font-weight: 400;
				color: #5a5a5a;
				margin-left: 15px;
				margin-top: 10px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 410px;
				@media (max-width: 768px){
					width: 80%;
				}
			}
			h5 {
				font-family: var(--Roboto-font-family);
				font-weight: 300;
				color: #20256c;
				font-size: min(23px, 2vw);
				margin-left: 15px;
				@media (max-width: 768px){
					font-size: 29px;
					max-width: 179px;
					font-weight: 400;
				}
			}
			.bottom-infos {
				font-family: var(--Roboto-font-family);
				display: flex;
				flex-direction: row;
				margin-top: 40px;
				align-items: center;
				@media (max-width: 768px){
					display:flex;
					margin: 0;
					flex-direction: column;
					align-items: flex-start;
				}
				h5 {
					span{
						@media (max-width: 768px){

					font-size: 14px;
					font-family: var(--Roboto-font-family);
					margin-left: 23px;
					align-items: center;
					@media (max-width: 768px){
						font-size: 20px;
						margin: 0;
					}
						}
				}
				}
				span {
					font-size: 14px;
					font-family: var(--Roboto-font-family);
					margin-left: 23px;
					align-items: center;
					@media (max-width: 768px){
						font-size: 12px;
						font-weight: 600;
						margin-left: 15px;
					}
				}
				strong {
					font-weight: 550;
					font-size: 18px;
					margin-right: 5px;
					align-items: center;
				}
			}
		}
	}
	.buttons {
		display: flex;
		flex-direction: column;
		flex: 0 1 auto;
		justify-content: center;
		overflow: hidden;
		padding: 0 15px;
		row-gap: 7px;
		@media (max-width: 768px){
			padding-top:10px ;
			width: 64%;
			display: flex;
    		padding-left:56PX;
		}

		span {
			font-family: var(--Roboto-font-family);
			color: #5a646e;
			font-size: 14px;
			overflow: hidden;
			text-overflow: ellipsis;
			@media (max-width: 768px){
				color:#CBCBCB;
				display: none;
			}
			@media (max-width: 768px){
				padding-left: 15px;
			}
		}

		button {
			height: 34px;
			font-family: var(--Roboto-font-family);
			background: #ffffff 0% 0% no-repeat padding-box;
			border-radius: 8px;
			font-size: 16px;
		}
		#more-infos {
			color: #2b63fc;
			border: 1px solid #2b63fc;
			transition: 0.3s;
		}

		#more-infos:hover {
			color: #fff;
			background: #2b63fc;
		}
		#cancel-order {
			color: #c9182a;
			border: 1px solid #c9182a;
			transition: 0.3s;
		}

		#cancel-order:hover {
			color: #fff;
			background: #c9182a;
		}
	}
`;
