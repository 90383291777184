import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { insertMobileHeader } from '../../actions/MobileHeaderActions';
import Header from '../../components/Default/header';
import SearchCard from '../../components/SearchCard/SearchCard';
import { SearchPreset } from '../../interfaces/MobileHeader';

import { Container } from './styles';

const SearchPage: React.FC = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(insertMobileHeader(SearchPreset))
	});
	return (
		<Container>
			<Header />
			<SearchCard />
		</Container>
	);
};

export default SearchPage;
