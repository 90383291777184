import styled from 'styled-components';
interface InputSize {
	width: string;
	border: string;
}
export const InputContainer = styled.div<InputSize>`
	flex: ${(Prop) => Prop.width};
	display: flex;
	flex-direction: column;
	@media (max-width: 768px) {
		width: 100%;
	}

	label {
		font-family: 'Open sans';
		font-weight: bold;
		font-size: min(12px, 1.5vw);
		color: #898989;
		margin-bottom: 4px;
	}

	input {
		height: 30px;
		border: ${(Prop) => Prop.border};

		border-radius: 8px;
		padding: 0px 0px 0px 17px;
		color: #a8a8a8;
		font-family: var(--Roboto-font-family);
		background: white;
		@media (max-width: 768px) {
			margin-bottom: 23px;
		}
	}
`;
