import styled from 'styled-components';

export const PageContainer = styled.div`

	//Reputação
	width: 75%;
	/* height: 500px; */
	border-radius: 12px;
	background: white;
	margin-top: 10px;


	@media (max-width: 768px) {
		border-radius: 0;
		width: 100%;
	}
`;
export const Container = styled.div`
	width: 93%;
	padding: 0 35px 35px 35px;
	display: flex;
	flex-direction: column;
	min-height: 326px;
	row-gap: 12px;

	@media (max-width: 768px) {
		width: 90%;
		padding: 20px 5% 35px 5%;
	}
	h2 {
		margin-top: 30px;
		color: #353a40;
		font-size: 18px;
		font-weight: 300;
		font-family: var(--Roboto-font-family);
		@media (max-width: 768px) {
			margin-top: 0;
		}
	}
	h3 {
		margin-top: 30px;
		color: #353a40;
		font-size: 22px;
		font-weight: 400;
		font-family: var(--Roboto-font-family);
	}
`;

export const NoAvaliations = styled.div`
	height: 55vh;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #fff;

	@media (max-width: 768px) {
		border-radius: 8px;
		width: 100%;
	}

	.emoji-and-text {
		display: flex;
		flex-direction: column;
		align-items: center;

		.emoji {
			width: 96px;
			height: 96px;
		}

		h2 {
			color: #353a40;
			font-size: 20px;
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			margin-top: 22px;
			text-align: center;
			@media (max-width: 468px) {
				max-width: 221px;
				text-align: center;
			}
		}
	}
	.start-to-buy-button {
		width: 321px;
		height: 40px;
		background: #2b63fc;
		border: none;
		border-radius: 8px;

		@media (max-width: 468px) {
			width: 221px;
		}
		color: white;
		font-family: var(--Roboto-font-family);
		font-weight: 500;
		font-size: 16px;
		margin-top: 26px;
	}
`;
