import styled from 'styled-components';

export const Drop = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 10px;
	margin-right: 5px;
	column-gap: 20px;

	svg {
		width: 26px;
		height: 26px;
	}
	.box {
		width: 18px;
		height: 16px;
		position: relative;
		/* margin: 50px auto; */
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: 0.5s ease-in-out;
		-moz-transition: 0.5s ease-in-out;
		-o-transition: 0.5s ease-in-out;
		transition: 0.5s ease-in-out;
		top: 0;
		transition: top 0.2s;
		cursor: pointer;
		/* margin-top: -12px; */
		margin-bottom: 4px;
	}
	.box span {
		width: 20px;
		height: 4px;
		background: #2d327c;
		border-radius: 9px;
		display: block;
		position: absolute;
		opacity: 1;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: 0.25s ease-in-out;
		-moz-transition: 0.25s ease-in-out;
		-o-transition: 0.25s ease-in-out;
		transition: 0.25s ease-in-out;
	}
	.box span:nth-child(1) {
		top: 0px;
	}
	.box span:nth-child(2) {
		top: 7px;
	}
	.box span:nth-child(3) {
		top: 14px;
	}
	.box.on {
		top: 5px;
	}
	.box.on span:nth-child(1) {
		top: 3px;
		transform: rotate(45deg);
		transition: 0.2s ease-in-out;
	}
	.box.on span:nth-child(2) {
		opacity: 0;
		left: -15px;
		/* transform: rotate(-450deg); */
		transition: 0.9s ease-out forwards;
		transform-origin: 1px;
	}
	.box.on span:nth-child(3) {
		/* opacity: 0; */
		/* left: -60px;   */
		top: 3px;
		transform: rotate(-45deg);
		transition: 0.2s ease-in-out;
	}
	@media (min-width: 768px) {
		display: none;
	}
`;

export const MobileMenuContainer = styled.div`
	position: absolute;
	top: 0;
	background-color: #fff;
	width: 70vw;
	height: 100vh;
	z-index: -1;
	transition: all 0.3s;
	display: none;
	border-left: 1px solid #eee;

	@keyframes showMenu {
		0% {
			right: -500px;
		}
		100% {
			right: -50px;
		}
	}

	&.active {
		animation: showMenu 0.4s ease-out forwards;
		display: block;
	}
	&.sub-active {
		border-left: 1px solid #353a40;
		background-color: #353a40;
	}

	.menu {
		position: relative;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		row-gap: 36px;
		padding-left: 14px;
		padding-top: 104px;
		width: 75%;
		animation: ascendItems 0.5s ease-out forwards;

		@keyframes ascendItems {
			0% {
				left: 200px;
				top: 300px;
			}
			100% {
				left: 0;
				top: 0;
			}
		}

		.arrow-menu-item {
			display: flex;
			margin-bottom: -20px;
			justify-content: space-between;
			cursor: pointer;
			transition: all 0.3s;

			&.active-item {
				span {
					color: #fff;
				}
				svg {
					fill: #fff;
				}
			}

			img {
				width: 18px;
				height: 18px;
			}
			svg {
				width: 20px;
				height: 20px;
				transition: all 0.3s;
			}

			span {
				margin-top: 2px;
				font-family: var(--Roboto-font-family);
				font-weight: 400;
				font-size: 16px;
				color: #2d327c;
				margin-left: 10px;
				transition: all 0.3s;
			}
		}

		.menu-item {
			display: flex;
			align-items: center;

			img {
				width: 18px;
				height: 18px;
			}
			svg {
				width: 20px;
				height: 20px;
				cursor: pointer;
			}

			span {
				font-family: var(--Roboto-font-family);
				font-weight: 400;
				font-size: 16px;
				color: #0b43dc;
				margin-left: 10px;
				cursor: pointer;
			}
		}
		.border-top {
			border-top: 1px solid #c5ced7;
			padding-top: 25px;
		}

		.logout-button {
			display: flex;
			align-items: center;
			flex: 1;
			justify-content: flex-start;
			svg {
				width: 20px;
				height: 20px;
			}
			a {
				margin-left: 10px;
				font-family: var(--Roboto-font-family);
				font-weight: 400;
				font-size: 16px;
				color: #0b43dc;
				text-decoration: none;
			}
		}
	}
`;

export const Overlay = styled.div`
	display: none;
	position: fixed;
	top: 71px;
	left: 0;
	background-color: #000;
	opacity: 0.3;
	width: 100vw;
	height: 100vh;
	z-index: -2;

	&.active {
		display: block;
	}
`;

export const SubContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transition: all 0.3s;
	padding-left: 20px;
	max-height: 70vh;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	span {
		color: #fff;
		font-family: var(--Roboto-font-family);
		font-weight: 400;
		font-size: 16px;
		margin-left: 10px;
		cursor: pointer;
	}
`;

export const SearchBoxStore = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 8px;

	width: 130px; /* 
min-width: 406px;
max-width: 155px; */
	width: 100%;
	height: 32px;
`;
export const SerchRowStore = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: 6px 8px 8px 7px;
`;

export const SearchStore = styled.input`
	display: flex;
	flex-direction: column;
	flex: 1;
	background: none;
	padding-left: 10px;
	width: 120px;
	border: none;
	font-style: italic;
	font-family: var(--Roboto-font-family);
	font-size: 14px;
	color: #fff;

	::placeholder {
		color: #c5ced7;
	}

	&:focus {
		outline: none;
	}
`;

export const SearchIcon = styled.div`
	display: flex;
	flex-direction: column;
	height: 20px;
	width: 20px;
	svg {
		fill: #7f7f7f;
		cursor: pointer;
	}
`;
