import styled from 'styled-components';

export const SearchBoxStore = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 8px;

	width: 130px; /* 
min-width: 406px;
max-width: 155px; */
	width: 100%;
	height: 32px;
`;
export const SerchRowStore = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: 6px 8px 8px 7px;
`;

export const SearchStore = styled.input`
	display: flex;
	flex-direction: column;
	flex: 1;
	background: none;
	padding-left: 10px;
	width: 120px;
	border: none;
	font-family: var(--Roboto-font-family);
	font-size: 14px;
	color: #fff;

	::placeholder {
		color: #c5ced7;
		font-size: 10px;
		font-style: italic;
	}

	&:focus {
		outline: none;
	}
`;

export const DropDownItem = styled.div`
	font-family: var(--Roboto-font-family);
	font-weight: normal;
	font-size: max(13px, 0.7vw);
	color: #ffffff;
	&:hover {
		cursor: pointer;
	}
`;

export const SearchIcon = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 13px;
	min-width: 13px;
	svg {
		fill: #7f7f7f;
		cursor: pointer;
	}
`;

export const StoreContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 20px;
	margin-top: 16px;
	max-height: 250px;
	overflow: auto;
`;
