import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	flex: 1;
	background: #f5f5f5;
	margin-bottom: 40px;
`;

export const DefaultRow = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
`;

export const DefaultColumn = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const SideInfoContainer = styled(DefaultColumn)`
	max-width: 245px;
	@media (max-width: 768px) {
		display: none;
	}
`;

export const CardsContainer = styled.div`
	flex-direction: column;
	flex: 1;
	padding-right: 35px;
	width: 75%;

	@media (max-width: 768px) {
		width: 90%;
		padding-right: 0;
	}
	.back-button {
		width: 71px;
		height: 27px;
		background: #3b3d4a;
		color: white;
		font-family: var(--Roboto-font-family);
		font-weight: bold;
		font-size: 0.5rem;
		border: none;
		&:hover {
			cursor: pointer;
		}
	}
`;
export const ProductInfosCard = styled.div`
	display: flex;
	flex-direction: row;

	background: white;

	margin-top: 60px;
	padding: 10px 32px;
	border-radius: 12px;
	/* height: 680px; */
	@media (max-width: 768px) {
		width: calc(100% - 48px);
		padding: 10px 24px;
		margin-top: 23px;
	}
`;
export const DescriptionAndReputation = styled.div`
	padding-top: 30px;
	width: 100%;
	column-gap: 45px;
	border-bottom: solid 1px #e1e1e1;
	padding-bottom: 32px;
	@media (max-width: 768px) {
		padding-top: 25px;
	}
	.description {
		h2 {
			color: #353a40;
			font-family: var(--Roboto-font-family);
			font-size: 20px;
			font-weight: 300;
			margin-bottom: 10px;
			@media (max-width: 768px) {
				font-size: 20px;
				font-weight: normal;
			}
		}
		p {
			color: #353a40;
			font-family: var(--Roboto-font-family);
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			word-break: break-word;
			@media (max-width: 768px) {
				font-size: 16px;
				max-width: 329px;
				max-height: 431px;
			}
		}
	}
`;
export const DailyPromotion = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 15px;
	padding-top: 40px;

	@media (max-width: 768px) {
		width: calc(100% - 24px);
		padding-left: 5%;
		height: 400px;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	h2 {
		color: #353a40;
		font-size: 20px;
		font-weight: normal;
		font-family: var(--Roboto-font-family);
		margin-bottom: 20px;
	}

	.daily-promotions-container {
		display: flex;
		justify-content: flex-start;
		column-gap: 20px;
	}
`;

export const AlignmentContainer = styled.div`
	width: var(--Default-page-alignment-vale);
	height: max-content;
	margin: auto;
	font-size: 16px;
	font-family: 'Roboto';
	@media (max-width: 768px) {
		width: 100%;
	}
	.row-buttons {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 50px;
		P {
			position: absolute;
			margin-top: 70px;
		}
	}
	.buttons-block {
		display: flex;
		flex-direction: row;
		margin-left: auto;
		column-gap: 20px;
		font-weight: 500;
	}
	.buttons-sallers {
		width: 223px;
		height: 31px;
		border: 1px solid #000;
		border-radius: 8px;
		text-align: center;
		padding-top: 10px;
	}
	.buttons-ads {
		width: 223px;
		height: 31px;
		border: 1px solid #000;
		border-radius: 8px;
		text-align: center;
		padding-top: 10px;
		background: black;
		color: white;
	}
`;

export const ProductsSeller = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 15px;
	padding-top: 30px;
	@media (max-width: 768px) {
		border-top: solid 1px #e1e1e1;
	}

	h2 {
		color: #353a40;
		font-size: 20px;
		font-family: var(--Roboto-font-family);
		font-weight: normal;
		margin-bottom: 40px;
		font-weight: 300;
	}

	.daily-promotions-container {
		display: flex;
		justify-content: flex-start;
		column-gap: 19px;
		@media (max-width: 768px) {
			width: 100%;
			overflow-x: auto;
			-webkit-overflow-scrolling: touch;
		}
	}
`;

export const ProductNotFound = styled.div`
	display: flex;
	flex: 1;
	height: 154px;
	background-color: #ffffff;
	border-radius: 12px;
	margin-top: 67px;

	svg {
		margin-top: 43px;
		margin-left: 42px;
	}
	h1 {
		color: #353a40;
		font-size: 24px;
		font-weight: normal;
		font-family: var(--Roboto-font-family);

		margin-left: 24px;
		margin-top: 59px;
	}
`;

export const ModalContainer = styled.div`
	width: 796px;
	height: 408px;
	padding: 26px;
	font-family: 'Roboto';
	.cards {
		font-size: 16px;
		.button-text {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-top: 40px;
			span {
				width: 196px;
				height: 30px;
				border-radius: 8px;
				background: #2b63fc;
				color: white;
				font-size: 13px;
				text-align: center;
				padding-top: 15px;
			}
			p {
				font-size: 14px;
				color: #8a99a8;
				font-style: italic;
			}
		}
		span {
			text-align: start;
			margin-bottom: 16px;
		}
		.row {
			display: grid;
			column-gap: 20px;
			grid-template-columns: 1fr 1fr;
			input {
				height: 38px;
				border-radius: 8px;
				border: 1px solid #8a99a8;
				margin-bottom: 22px;
			}
			.motivation {
				display: flex;
				flex-direction: column;
			}
			.duration {
				display: flex;
				flex-direction: column;
			}
		}
		.motive {
			display: flex;
			flex-direction: column;
			input {
				height: 123px;
				border-radius: 8px;
				border: 1px solid #8a99a8;
			}
		}
	}
	.title {
		margin-bottom: 31px;
		h1 {
			font-size: 19px;
			font-weight: 500;
			color: #2a3199;
		}
	}
`;
