import React from 'react';
import { useForm } from 'react-hook-form';

import { ModalContainer } from './styles';
import Modal from 'react-modal';
import { IProduct } from '../../../Pages/ProductPage/ProductPage';
import BlockedAnnouncement from '../../../interfaces/BlockAnnouncement';
import api from '../../../api';
import { useDispatch } from 'react-redux';
import { insertProduct } from '../../../actions/ProductActions';

type Props = {
	product: IProduct;
	open: boolean;
	setOpen: (open: boolean) => void;
};

const BlockProductModal: React.FC<Props> = ({ product, open, setOpen }) => {
	const dispatch = useDispatch();
	const onSubmit = (data: BlockedAnnouncement) => {
		try {
			api.post('/block-announcement', {
				...data,
				product_id: product.id,
			}).then((response) => {
				alert('Produto bloqueado!');
				setOpen(false);
				dispatch(
					insertProduct({
						...product,
						blocked_announcement: response.data.blockAnnouncement,
					})
				);
			});
		} catch (error) {
			alert('Erro ao bloquear produto!');
		}
	};

	const { register, handleSubmit, errors } = useForm<BlockedAnnouncement>();

	return (
		<>
			<Modal
				isOpen={open}
				onRequestClose={() => setOpen(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						zIndex: 1001,
						left: 0,
						right: 0,
						bottom: 0,
						background: '#0007',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						padding: '0 auto',
						bottom: 'auto',
						marginRight: '-50%',
						maxHeight: '100%',
						border: 'none',
						borderRadius: '11px',
						transform: 'translate(-50%, -50%)',
					},
				}}
			>
				<ModalContainer>
					<div className="title">
						<h1>Bloquear anúncio</h1>
					</div>
					<form className="cards" onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="motivation">
								<span>Motivo</span>
								<input
									name="reason"
									ref={register({ required: false })}
								/>
							</div>
							<div className="duration">
								<span>Duração do bloqueio</span>
								<input
									name="blocking_time"
									ref={register({ required: false })}
								/>
							</div>
						</div>
						<div className="motive">
							<span>Especifique o motivo</span>
							<input
								name="specify_reason"
								ref={register({ required: false })}
							/>
						</div>
						<div className="button-text">
							<p>O vendedor será notificado</p>
							<button type="submit">Confirmar bloqueio</button>
						</div>
					</form>
				</ModalContainer>
			</Modal>
		</>
	);
};

export default BlockProductModal;
