import React from 'react';

import { Container, CardHeader, CardMid, CardBottom } from './styles';

const AdvicesCard: React.FC = () => {
	return (
		<Container>
			<CardHeader>
				<h4>Corredor Cultural</h4>
				Coordenação: Fabíola Bach
			</CardHeader>
			<CardMid>
				<p>
					O Conselho Barão do Serro Azul foi criado pela Presidência
					para agregar personalidades da sociedade que, por suas
					atividades, possam contribuir para a valorização da ACP como
					instituição de referência empresarial do Estado e no Brasil
				</p>
			</CardMid>
			<CardBottom>
				<span>Membros do Conselho</span>
				<ul>
					<li>Ana Amélia Filizolla</li>
					<li>Sebastião Motta</li>
					<li>Francisco Simeão Rodrigues Neto</li>
					<li>Antenor Demeterco Júnior</li>
					<li>Domingos Tarço Murta Ramalho </li>
					<li>Leonardo Petrelli</li>
				</ul>
			</CardBottom>
		</Container>
	);
};

export default AdvicesCard;
