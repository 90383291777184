import styled from 'styled-components';

export const Container = styled.div`
	width: 218px;
	flex: 1 0 auto;
	border-radius: 4px;
	padding-left: 0px;
	padding-right: 0px;
	margin-bottom: 25px;
	height: max-content;
	
	position: relative;
	z-index: 1;
	cursor: pointer;

	.replace{
		/* padding-top:20px; */
		font-weight: 500;
		text-decoration: underline;
		animation: replace 0.5s ease-out forwards ;
		z-index: 99999999;
	}
	.select-product{
		width:100%;
		height:37px;
		background:#2B63FC;
		color:white;
		border-radius:8px;
		margin-top: 80px;
		font-size:13px;
		display:flex;
		justify-content:center;
		align-items:center;
	}

	@keyframes replace {
		0% {
			padding-top:20px;
			color: white;
		}
		60%{

			color:white;
		}
		100%{
			color: #5A646E;
			display:flex;
			padding-top:70px;
			animation-play-state: paused;
		}
		/* from {
			padding-top:20px;
			color: white;
		}
		to{
			color: #5A646E;
			display:flex;
			padding-top:70px;
			animation-play-state: paused;
		} */
	}
	@media (max-width: 768px){
		box-shadow: 0px 1px 6px #00000038;
	}
	&.is-mobile{
		height: 312px !important;
	}
	li {
		list-style: none;
	}

	@keyframes fadeInFromNone {
		/* https://stackoverflow.com/questions/8449933/animation-css3-display-opacity */
		0% {
			display: none;
			opacity: 0;
			@media (max-width: 768px){
				display: none;
			}
		}

		60% {
			display: block;
			opacity: 0;
			@media (max-width: 768px){
				display: none;
			}
		}

		80% {
			display: block;
			opacity: 0.3;
			@media (max-width: 768px){
				display: none;
			}
		}

		100% {
			display: block;
			opacity: 1;
			@media (max-width: 768px){
				display: none;
			}
		}
	}
	@keyframes reduceMargin {
		0% {
			margin-top: 13px;
			@media (max-width: 768px){
				display: none;
			}
		}
		100% {
			margin-top: 13px;
			@media (max-width: 768px){
				display: none;
			}
		}
	}
	@keyframes increaseMargin {
		0% {
			margin-top: 13px;
			@media (max-width: 768px){
				display: none;
			}
		}
		100% {
			margin-top: 13px;
			@media (max-width: 768px){
				display: none;
			}
		}
	}

	::before {
		/* https://justmarkup.com/articles/2015-11-27-scale-an-element-without-changing-the-size-of-its-child-elements/ */
		z-index: -1;
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: max-content;
		min-height: 249px;
		background: #ffffff 0% 0% no-repeat padding-box;
		border-radius: 4px;
		box-shadow: 0px 3px 6px #0000001c;
		transform-origin: 0 0;
		transition: all 0.3s;
		@media (max-width: 768px){
				display: none;
			}
	}
	&:not(.is-mobile) {
		&:hover:before {
			transform: scaleY(1.25);
		}
		&:hover .hover-info {
			animation: fadeInFromNone 0.3s ease-out;
		}
		&:hover .product-infos {
			animation: reduceMargin 0.3s ease-out forwards;
		}
		&:not(:hover) .product-infos {
			animation: increaseMargin 0.3s ease-out forwards;
		}
	}

	.hover-info {
		position: absolute;
		transform-origin: 0 0;
		transition: all 0.3s;
		margin-right: 2px;

		h4 {
			font-weight: normal;
		}
	}

	figure {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		z-index:0;
		img {
			width: 100%;
			height: 161px;
			object-fit: cover;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}
	}
	.product-infos {
		font-size: 10px;
		margin: 13px 24px 13px 24px;
		z-index:4;

		/* span {
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			color: #888888;
			padding: 8px;
		} */
		h3 {
			font-size: 12px;
			width: 150px;
			word-break: break-word;
			color: #353a40;
			font-family: var(--Roboto-font-family);
			font-weight: 300;
			height: 20px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		h4 {
			font-size: 14px;
			line-height: 20px;
			width: 95%;
			word-break: break-word;
			color: #353a40;
			font-family: var(--Roboto-font-family);
			height: max-content;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-bottom: 8px;
		}
		h5 {
			font-size: 24px;
			margin-top: 8px;
			color: #002793;
			font-family: var(--Roboto-font-family);
			font-weight: bold;

			&.showcase {
				font-size: 16px;
			}
		}
		.buy-button {
			display: flex;
			justify-content: center;
			padding: 8px;
			padding-bottom: 8px;
			position: absolute;
			button {
				background: #d29800;
				width: 154px;
				border: none;
				height: 28px;
				border-radius: 12px;
				color: white;
				font-family: var(--Roboto-font-family);
				font-weight: bold;
				font-size: min(13px, 2.3vw);
				position: relative;
			}
		}

		.small-text {
			font-size: 18px;
		}

		.active {
			display: flex;
			position: absolute;
		}
	}
	}
	
	.more-colorsMobile { 
		z-index: 0;
		bottom: 100px;
		display: block;
		opacity: 1;
		width: 48px;
		font-size: 13px; 
		font-weight: 900;
		@media (max-width: 768px) {
			bottom: 160px;
		}
		@media (min-width: 768px) {
			display: none;
		}
	}
	:hover {
		.more-colors {
			display: none;
			@media (min-width: 768px){
				bottom: 100px;
				z-index: 0;
				opacity: 1;
				display: block;
			}		
		}
`;

export const DefaultRow = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	align-items: center;
`;

export const DefaultColumn = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const PromotionLabel = styled.label`
	font-family: var(--Roboto-font-family);
	position: absolute;
	left: 6px;
	top: 6px;
	background-color: #0fd594;
	width: 46px;
	font-size: 12px;
	font-weight: medium;
	color: #ffffff;
	padding: 4px 0;
	border-radius: 4px;
	text-align: center;
	max-height: 16px;
`;

export const MoreColors = styled.label`
	font-family: var(--Roboto-font-family);
	position: absolute;
	left: 19px;
	bottom: 80px;
	transition: all 0.5s;
	padding: 6px 10px;
	width: 86px;
	background-color: #707070;
	border-radius: 6px;
	font-size: 10px;
	font-weight: medium;
	letter-spacing: 0px;
	text-align: center;
	color: #ffffff;
	opacity: 0;
	cursor: pointer;
	z-index: 1;
	overflow: hidden;
`;

export const MoreColorsMobile = styled.label`
	font-family: var(--Roboto-font-family);
	position: absolute;
	left: 19px;
	bottom: 80px;
	transition: all 0.5s;
	padding: 6px 10px;
	width: 86px;
	background-color: #707070;
	border-radius: 6px;
	font-size: 10px;
	font-weight: medium;
	letter-spacing: 0px;
	text-align: center;
	color: #ffffff;
	opacity: 0;
	cursor: pointer;
`;

export const OriginalPrice = styled.span`
	display: flex;
	align-items: center;
	font-size: 12px;
	width: 150px;
	text-decoration: line-through;
	word-break: break-word;
	color: #353a40;
	font-family: var(--Roboto-font-family);
	font-weight: 300;
	height: 20px;
	padding-top: 4px;
	margin-bottom: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
`;
