import React from 'react'
import { FeatureStores } from '../Edit/Featured/Associated/Associated'
import { AssociatedCard } from './styles';

import placeholder_store_background from '../../assets/placeHolders/placeholder_store_background.png';
import placeholder_store_profile from '../../assets/placeHolders/placeholder_store_profile.png';

import config from '../../config';
import { useHistory } from 'react-router-dom';
import { AppState } from '../../store';
import UserAccount from '../../interfaces/UserAccount';
import { useSelector } from 'react-redux';
import { getFristImageSource } from '../../utils/GetProductImage';
import { Product } from '../../interfaces/Product';

interface IFeaturedStoreCard {
    store: FeatureStores
}

export const FeaturedStoreCard: React.FC<IFeaturedStoreCard> = ({ store }) => {
    const history = useHistory();

	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);

    if(!store.product || store.product.length <= 0) return (<></>)

    return (
                            <AssociatedCard
                                        onClick={(e) => {
                                            if((e.target as any).className === 'product_image') return;

                                            if (
                                                user?.id !==
                                                store.user_accounts_id
                                            ) {
                                                history.push(
                                                    `/seller-area/${store.user_accounts_id}/all/0/100000000/undefined`
                                                );
                                                window.scrollTo(0, 0);
                                            } else {
                                                history.push(
                                                    `/seller-area/${store.user_accounts_id}/all/0/100000000/undefined/products`
                                                );
                                                window.scrollTo(0, 0);
                                            }
                                        }}
                            >
                                <div className="associated-img-container">
                                    <div className="image-cover">
                                    <img
                                        className="associated-img-cover"
                                        src={
                                            store
                                                .shop_customizations
                                                ?.shop_cover_photo
                                                ? `${config.host}/static/shopImages/${store.shop_customizations?.shop_cover_photo}`
                                                : placeholder_store_background
                                        }
                                        alt="Imagem da categoria"
                                    />
                                    </div>
                                    <div className='img-profile'>
                                    <img
                                        className="associated-img-profile"
                                        src={
                                            store
                                                .shop_customizations
                                                ?.shop_profile_photo
                                                ? `${config.host}/static/shopImages/${store.shop_customizations?.shop_profile_photo}`
                                                : placeholder_store_profile
                                        }
                                        alt="Imagem da categoria"
                                    />
                                    </div>
                                </div>
                                <div className="associated-name-products-container">
                                    <h3>
                                        {store.shop_customizations
                                            .shop_name
                                            ? store
                                                    .shop_customizations
                                                    .shop_name.length > 40
                                                ? store.shop_customizations.shop_name.substring(
                                                        0,
                                                        40
                                                    ) + '...'
                                                : store
                                                        .shop_customizations
                                                        .shop_name
                                            : 'Sem nome'}
                                    </h3>
                                        <div className="associated-products">
                                    {store.product.map((p: any) => (
                                                <img    
                                                    className='product_image'
                                                    onClick={() => {
                                                        history.push(`/product/${p.id}/questions`);
                                                    }}
                                                    src={
                                                        store.product ?
                                                        getFristImageSource(
                                                            p
                                                        ) : ''
                                                    }
                                                    alt="Imagem do produto"
                                                />
                                    ))}
											</div>
                                    <h4
                                    >
                                        ver loja
                                    </h4>
                                </div>
                            </AssociatedCard>
    )
}