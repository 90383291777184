import Header from "../../components/Default/header"
import { Container } from "./styles"
import Faq from '../../components/Faq/Faq';

const FaqPage: React.FC = () => {
    return(
        <Container>
            <Header />
            <Faq />
        </Container>
    )
}

export default FaqPage;