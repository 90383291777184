import styled from 'styled-components';

import imagem3 from '../../assets/correta (1) (1).png';

export const PageContainer = styled.main`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	align-items: center;
	text-align: left;
	font-family: var(--Roboto-font-family);
	/* width: var(--Default-page-alignment-vale); */

	/* @media (max-width: 768px) {
		width: 100%;
	} */

	h2 {
		color: black;
		font-size: 24px;
		font-family: var(--Roboto-font-family);
		width: var(--Default-page-alignment-vale);
		font-weight: normal;
		font-size: 26px;
		margin-top: 47px;
		margin-bottom: 34px;

		@media (max-width: 768px) {
			width: 90%;
			margin-left: 5%;
		}
	}
`;

export const AboutUsCard = styled.div`
	display: flex;
	column-gap: 35px;
	border-bottom: solid 1px #e1e1e1;
	padding-bottom: 40px;
	margin-bottom: 60px;
	@media (max-width: 768px) {
		padding-bottom: 30px;
    	margin-bottom: 0px;
		flex-direction: column;
	}
	h2 {
		color: #858585;
		font-family: var(--Roboto-font-family);
		font-weight: 500;
		font-size: min(20px, 1.8vw);
		margin-bottom: 25px;
		
	}
	.foto-and-infos {
		display: flex;
		flex-direction: column;
		@media (max-width: 768px) {
			width: 94%;
		}

		.photo {
			margin-top: 31px;
			margin-left: 21px;
			img {
				width: 571px;
				height: 288px;
				border-radius: 12px;
				object-fit: cover;
				@media (max-width: 768px) {
					width: 100%;
					height: 177px;
				}
			}
		}

		.infos {
			display: flex;
			flex-direction: column;
			border: 1px solid #c5ced7;
			border-radius: 8px;
			margin-top: 45px;
			padding: 1rem;
			margin-left: 21px;
		
			@media (max-width: 768px) {
				display: none;
			}
			.infos-item {
				margin-bottom: 25px;
				width: 85%;
				h1 {
					color: #004d93;
					font-family: var(--Roboto-font-family);
					font-weight: 500;
					font-size: min(20px, 1.8vw);
					width: 90%;
					margin-bottom: 15px;
					@media (max-width: 768px) {
						font-size: 20px;
					}
				}

				p {
					margin-left: 15px;
					padding-left: 15px;

					color: #959595;
					font-family: var(--Roboto-font-family);
					font-weight: 400;
					font-size: min(13px, 1.4vw);

					border-left: solid 1px #e1e1e1;
					/* height: 90px; */
					display: flex;
					align-items: center;
					@media (max-width: 768px) {
						font-size: 13px;
						width: 200px;
					}
				}
			}
		}
	}

	.about-association {
		margin-top: 25px;
		margin-right: 50px;
		@media (max-width: 768px) {
			margin-top: 31px;
			margin-left: 5%;
			margin-right: 5%;
		}

		h3 {
			color: #004d93;
			font-family: var(--Roboto-font-family);
			font-weight: 500;
			font-size: min(18px, 1.6vw);
			margin-bottom: 20px;
			@media (max-width: 768px) {
				font-size: 18px;
			}
		}
		p {
			color: #959595;
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			font-size: min(14px, 1.4vw);
			margin-bottom: 55px;
			line-height: 30px;
			@media (max-width: 768px) {
				font-size: 14px;
				max-width: 100%;
			}
		}
		.button-container {
			display: flex;
			justify-content: flex-start;
			button {
				width: 240px;
				height: 34px;
				border-radius: 8px;
				

				background: #2a31b9;
				color: white;
				border: none;

				

				font-family: var(--Roboto-font-family);
				font-size: min(12px, 1.4vw);
				@media (max-width: 768px) {
					width: 100%;
					font-size: 12px;
				}
			}
		}
	}
`;

export const AboutDirectionCard = styled.div`
	/* margin-bottom: 60px; */
	/* margin-left: 21px; */
	/* margin-right: 50px; */
	justify-content: center;
	display: flex;
	flex-direction: column;
	border-bottom: solid 1px #e1e1e1;
	padding-bottom: 40px;
	margin-bottom: 60px;
	padding: 27px;
	@media (max-width: 768px) {
		padding-bottom: 0px;
		margin-bottom: 0px;
		margin-left: 21px;
		padding: 22.4px;
	}

	h2 {
		font-family: var(--Roboto-font-family);
		font-weight: 500;
		font-size: min(20px, 1.8vw);
		color: #004d93;

		margin-bottom: 35px;
		@media (max-width: 768px) {
			font-size: 18px;
			margin-top: 0px;
			margin-left: 0px;
		}
	}
	.pictures-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		column-gap: 20px;
		row-gap: 20px;
		justify-content: center;

		.images {
			width: 250px;
			height: 300px;
			@media (max-width: 1366px) {
				width: 267px;
				height: 310px;
			}
			display: flex;
			flex-direction: row;
			align-items: center;
			position: relative;
			justify-content: center;
			img {
				width: 100%;
				height: 100%;
				border-radius: 12px;
				object-fit: cover;
				/* -webkit-filter: sepia(10%) brightness(85%) contrast(100%); */
			}

			&:hover {
				.text {
					opacity: 1;
				}
			}

			.text {
				position: absolute;
				bottom: 10px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				background-color: rgba(0,0,0, 0.8);

				padding: 8px;
				margin: 0 12px;
				border-radius: 8px;

				opacity: 0;
				transition: opacity 0.15s ease-in-out;

				@media (max-width: 768px) {
					opacity: 1 !important;
				}

				span {
					text-align: center;
					color: white;
					font-family: var(--Roboto-font-family);
					font-weight: 500;
					font-size: 14px;
				}
				p {
					color: #d59600;
					font-family: var(--Roboto-font-family);
					font-weight: 500;
					font-size: min(12px);
				}
			}
		}
	}

	.informations {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 0px 60px;
		margin-top: 20px;
		@media (max-width: 768px){
			display: flex;
			flex-direction: column;
    		padding: 0px;
		}
		.item {
			display: flex;
			align-items: center;
			@media (max-width: 768px){
				margin-bottom: 15px;
			}

			.contact-icon {
				margin-right: 10px;
				fill: #cecece;

				.a {
					fill: #cecece;
				}
			}

			span {
				color: #858585;
				font-family: var(--Roboto-font-family);
				font-weight: 500;
				font-size: min(16px, 1.8vw);
				@media (max-width: 768px){
					font-size: 12px;
					align-items: center;
				}
			}
		}
	}
`;
export const Advices = styled.div`
	margin-bottom: 60px;
	margin-left: 21px;
	margin-right: 21px;
	display: flex;
	flex-direction: column;
	border-bottom: solid 1px #e1e1e1;
	padding-bottom: 40px;
	margin-bottom: 60px;
	@media (max-width: 768px){
		margin: 0px 5%;
	}

	h2 {
		color: #004d93;
		font-family: var(--Roboto-font-family);
		font-weight: 500;
		font-size: min(20px, 1.5vw);
		margin-bottom: 20px;
		@media (max-width: 768px) {
			font-size: 18px;
		}
	}
	section {
		display: flex;
		flex-direction: row;
		@media (max-width: 768px) {
			flex-direction: column;
		}
	}

	.area-container {
		display: flex;
		align-items: flex-start;
		column-gap: 15px;
		.back-arrow-container {
			background: #ececec;

			width: 58px;
			height: 43px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			border-radius: 30px;

			margin-top: 70px;
		}
		.column-container {
			width: 88%;
		}
		.items-container {
			display: flex;

			align-items: center;

			column-gap: 65px;
			justify-content: center;

			.item {
				background: white;

				width: 445px;

				height: 160px;

				display: flex;
				column-gap: 15px;
				padding: 6px 12px 12px 18px;
				border-radius: 15px;
				align-items: center;

				.image {
					width: 146px;
					height: 142px;
					background: #e4e4e4;
					border-radius: 15px;
				}

				.infos {
					display: flex;
					flex-direction: column;
					justify-content: center;
					background: purple;
					height: 50000px;
					

					h4 {
						color: #004d93;
						font-family: var(--Roboto-font-family);
						font-weight: 500;
						font-size: min(18px, 1.3vw);

						margin-bottom: 10px;
					}

					.title {
						color: #d59600;

						font-family: var(--Roboto-font-family);
						font-weight: 500;
						font-size: min(14px, 1vw);
					}
					.description {
						color: #89898b;

						font-family: var(--Roboto-font-family);
						font-weight: 500;
						font-size: min(14px, 1vw);
					}
				}
			}
		}
		.board-members-container {
			display: flex;
			flex-direction: column;
			background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
			background: #ffffff 0% 0% no-repeat padding-box;
			border-radius: 15px;

			margin-top: 45px;
			padding: 50px 100px;

			p {
				color: #959595;
				font-family: var(--Roboto-font-family);
				font-weight: 400;
				font-size: min(13px, 1vw);

				margin-bottom: 30px;
			}
			span {
				color: #858585;
				font-family: var(--Roboto-font-family);
				font-weight: 500;
				font-size: min(14px, 1.1vw);
				margin-bottom: 20px;
			}

			.list {
				list-style-type: none;

				li {
					color: #959595;
					font-family: var(--Roboto-font-family);
					font-weight: 400;
					font-size: min(14px, 1.1vw);
					margin-bottom: 10px;
				}
			}
		}
		.foward-arrow-container {
			background: #ececec;

			width: 58px;
			height: 43px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			border-radius: 30px;
			margin-top: 70px;

			img {
				transform: rotate(180deg);
			}
		}
	}
`;

export const AddressDiv = styled.div`
	flex: 1;

	padding: 0 24px;

	display: flex;
	flex-direction: column;
	row-gap: 24px;

	svg {
		color: #0075bb;
		font-size: 32px;
	}

	> p {
		font-size: 22px;
		display: flex;
		column-gap: 6px;
		align-items: center;
		justify-content: left;
	}
`

export const Images = styled.div`
	display: flex;

	/* margin-bottom: 60px; */
	width: 100%;
	opacity: 0.7;
	justify-content: center;
	@media (max-width: 768px) {
		flex-direction: column;
		box-sizing: border-box;
	}
	.imgHover {
		height: 344px;
		overflow: hidden;
		padding: 0 16px;
		position: relative;
	}
	img {
		filter: brightness(35%);
		flex: 1;
		object-fit: cover;
		height: 380px;
		@media (max-width: 768px) {
			width: 100%;
		}
	}
	.img-1-container {
		position: relative;
		height: 540px;
		width: 540px;
		background-image: ${() => `url('${imagem3}')`};
		background-position: bottom;

		>img {
			vertical-align: bottom;
			width: 540px;
		}

		overflow: hidden;
		@media (max-width: 768px) {
			padding: 16px;
		}
	}
	.infos-container {
		display: flex;
		position: absolute;
		top: 65px;
		left: 94px;
		@media (max-width: 768px) {
			flex-direction: column;
			/* margin: 81px 100px; */
			top: 19%;
			left: 36%;
		}
		

		button {
			background: #004d93;
			color: white;
			width: 180px;
			height: 43px;
			border-radius: 30px;
			border: none;
			margin-right: 40px;
			@media (max-width: 768px) {
				margin-bottom: 20px;
				width: 240px;
			}
			@media (max-width: 362px){
				width: 140px;
			}
		}
		.address-list {
			display: flex;
			flex-direction: column;
			row-gap: 10px;
			width: 200px;
			@media (max-width: 768px) {
				row-gap: 30px;
			}
			@media (max-width: 362px){
				width: 140px;
			}
			span {
				color: white;
				font-family: var(--Roboto-font-family);
				font-weight: 400;
				font-size: min(14px, 1.1vw);
				@media (max-width: 768px) {
					font-size: 14px;
				}
			}
		}

		.img1-text {
			/* position: absolute; */
			top: 0px;
			flex: '1';
			/* width: 100%; */
			@media (max-width: 768px) {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				margin: 88px 0 0;
				flex-direction: column;
			}

			.img-1-container {
				filter: brightness(35%);
				flex: 1;
				object-fit: cover;
				height: 380px;
				@media (max-width: 768px) {
					width: 100%;
				}
			}

			.img2-text {
				position: absolute;
				display: flex;
				@media (max-width: 768px) {
				}
			}
		}
	}
`;

interface ImageProps {
	url: string;
}

export const Image1 = styled.div<ImageProps>`
	width: 100%;
	height: 200px;
	display: flex;
	background-image: url(${(Prop) => Prop.url});
	background-repeat: no-repeat;
	background-size: cover;
`;

export const Image2 = styled.div<ImageProps>`
	width: 100%;
	display: flex;
	height: 200px;
	background-image: url(${(Prop) => Prop.url});
	background-repeat: no-repeat;
	background-size: cover;
`;

export const ReceiveNewsContainer = styled.div`
	width: 60%;
	max-width: 1079px;
	height: 218px;
	border-radius: 15px;
	margin-bottom: 60px;
	padding: 25px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	h2 {
		color: #004d93;
		font-family: var(--Roboto-font-family);
		font-weight: 500;
		font-size: min(20px, 1.6vw);
		text-align: center;
		margin-bottom: 15px;
	}
	span {
		color: #89898b;
		font-family: var(--Roboto-font-family);
		font-weight: 500;
		font-size: min(16px, 1.3vw);
		margin-bottom: 35px;
	}

	.email-input-container {
		width: 100%;
		display: flex;
		justify-content: center;
		column-gap: 25px;

		input {
			width: 50%;
			min-width: 629px;
			height: 43px;
			border-radius: 30px;
			background: rgb(237, 237, 237);
			border: none;

			padding: 0px 15px;
		}
		button {
			width: 10%;
			min-width: 127px;
			background: #d59600;
			border-radius: 30px;
			border: none;
			color: white;

			font-family: var(--Roboto-font-family);
			font-weight: bold;
			font-size: min(14px, 1.1vw);
		}
	}
`;

export const Container = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 8px;
	/* padding: 31px 50px 50px 21px; */
	width: var(--Default-page-alignment-vale);
	height: 100%;
	margin-bottom: 60px;
	@media (max-width: 768px) {
		width: 100%;
	}
`;

export const BottomContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 50px;
	.download-manual {
		width: 30%;
		margin-left: 50px;
		font-family: var(--Roboto-font-family);
		ul {
			list-style: none;
			color: #959595;
			font-size: min(13px, 1.3vw);
			font-weight: 700;
		}
		p {
			margin-top: 20px;
			color: #959595;
			font-size: min(14px, 1.3vw);
		}

		button {
			border: 1px solid #2a3199;
			border-radius: 8px;
			color: #2a3199;
			font-weight: 700;
			background: #fff;
			padding: 14px 87px;
			font-family: var(--Roboto-font-family);
			font-size: min(14px, 1.3vw);
			margin-top: 70%;
		}
	}
	.img-phone {
		margin-left: 40px;
	}
	.logo {
		margin: auto;
	}
`;
