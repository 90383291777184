import styled from 'styled-components';

export const Container = styled.div`
	min-height: 100vh;
	background: #f5f5f5;
	padding-top: 80px;
	.sallers-stors {
		text-align: center;
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding-top: 80px;
		p {
			font-family: 'Roboto';
			font-size: 26px;
			color: #353a40;
			margin-bottom: 23px;
		}
		svg {
			width: 18px;
			height: 18px;
			color: #2a3199;
		}
	}
	.cards-sallers {
		width: 100%;
		height: 100%;
		justify-content: center;
		display: flex;
		flex-wrap: wrap;
		column-gap: 16px;
		row-gap: 40px;
	}
	input {
		width: 648px;
		height: 40px;
		background: #f5f5f5;
		border: 1px solid #c5ced7;
		border-radius: 8px;
		padding-left: 35px;
		position: relative;
		margin-left: 11px;
		margin-top: 3px;
		margin-bottom: 48px;
	}

	.icon-input {
		position: relative;
		width: min-content;
		align-self: center;
		.icon {
			position: absolute;
			z-index: 99;
			top: 15px;
			left: 20px;
			/* left: 5px; */
			/* margin-left: 40px;
		margin-top: 3px; */
		}
	}
`;
