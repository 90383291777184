import React, { useEffect, useState } from 'react';
import NotificationItem from './NotificationItem/NotificationItem';

import { Container, NotificationsContainer, CardContainer, SeeMoreButton, NoFavorites } from './styles';
import imagem from '../../../../assets/imagem.jpg';
import { useHistory } from 'react-router-dom';
import api from '../../../../api';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store';
import { CgSmileSad } from 'react-icons/cg';

type Props = {
	onMouseLeave: any;
};
const NotificationsCard: React.FC<Props> = ({ onMouseLeave }) => {
	const history = useHistory();
	
	const user = useSelector((state: AppState) => state.user.currentUser)
	const [notifications, setNotifications] = useState<any[]>([]);

	useEffect(() => {
		const fetch = async () => {
			const response = await api.get('/notifications', {
				params: {
					user_id: user.id,
				}
			});

			setNotifications(response.data.data.notifications);
		}

		fetch();
	}, [])

	return (
		<CardContainer>
			<Container onMouseLeave={onMouseLeave}>
				{
					notifications.length > 0 ? (
						<>
						<NotificationsContainer>
							{notifications.map((notification: any) => (
								<NotificationItem
									key={notification.id}
									notification={notification}
								/>
							))}
						</NotificationsContainer>
						<SeeMoreButton>
							<button onClick={() => history.push("/notification")}>
								+Ver tudo
							</button>
						</SeeMoreButton>
						</>
					) : (
						<NoFavorites>
							<div className="emoji-and-text">
								<CgSmileSad color="#2B63FC" className="emoji" />
								<h2>Você ainda não possui nenhuma notificação</h2>
							</div>
						</NoFavorites>
					)
				}
			</Container>
		</CardContainer>
	);
};

export default NotificationsCard;
