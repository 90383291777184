import styled from 'styled-components';

export const Container = styled.div`
	.back {
		display: flex;
		margin-bottom: 10px;
		margin-right: 5px;
	}
	@media (min-width: 768px) {
		display: none;
	}
`;
