import React from 'react';
import Notification from '../../components/Notification/Notification';
const NotificationPage: React.FC = () => {
	return (
		<>
			<Notification />
		</>
	);
};
export default NotificationPage;
