import React, { forwardRef, InputHTMLAttributes } from 'react';
import { InputContainer } from './styles';

// import { Container } from './styles';
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	label: string;
	flex: string;
}

const FormInput: React.RefForwardingComponent<HTMLInputElement, InputProps> = (
	{ label, flex, ...rest },
	ref
) => {
	return (
		<InputContainer width={flex}>
			{/* <label> {label} </label> */}
			<input ref={ref} {...rest} placeholder={label}/>
		</InputContainer>
	);
};

export default forwardRef(FormInput);
