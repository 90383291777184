import React from 'react';
import PaymentSuccess from '../../../components/BuyProcess/PaymentSuccess/PaymentSuccess';
import Header from '../../../components/Default/header';

import { Container } from './styles';

const PaymentSuccessPage: React.FC = () => {
	return (
		<Container>
			<Header />
			<PaymentSuccess />
		</Container>
	);
};

export default PaymentSuccessPage;
