import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import api from '../../api';
import { ReactComponent as IconSearch } from '../../assets/icons/searchIcon.svg';
import UserAccount from '../../interfaces/UserAccount';
import { AppState } from '../../store';

import AuthHeader from './AuthHeader/AuthHeader';
import PublicHeader from './PublicHeader/PublicHeader';

const Header: React.FC = () => {
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);

	if (user) {
		return <AuthHeader />;
	} else {
		return <PublicHeader />;
	}
};

export default Header;
