import React, { useEffect, useState } from 'react';
import Header from '../../../components/Default/header';
import { Container } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { insertMobileHeader } from '../../../actions/MobileHeaderActions';
import { BackPreset } from '../../../interfaces/MobileHeader';
import ChangeUserInformation from '../../../components/ChangeUserInformation/ChangeUserInformation';

const ChangeUserInformationPage: React.FC = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(insertMobileHeader({
			...BackPreset,
			leftSideComp: {
				leftSide: 1,
				backArrowPath: '/profile/user-information'
			}
		}))
	});

    return (
		<Container>
			<Header />
			<ChangeUserInformation  />
		</Container>
	);
};

export default ChangeUserInformationPage;
