import styled from 'styled-components';

export const Container = styled.div`
	width: 80%;
	margin-left: 10%;
	min-height: 100vh;
	padding: 40px;
	background: white;
	font-size: 16px;
	font-family: 'Roboto';
	.text-button {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		color: #353a40;
		p {
			padding-bottom: 40px;
		}
		span {
			width: 132px;
			height: 30px;
			background: #00b06a;
			padding-top: 10px;
			color: white;
		}
	}
`;
export const DailyPromotion = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 15px;
	padding-top: 40px;

	@media (max-width: 768px) {
		width: calc(100% - 24px);
		padding-left: 5%;
		height: 400px;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	h2 {
		color: #353a40;
		font-size: 20px;
		font-weight: normal;
		font-family: var(--Roboto-font-family);
		margin-bottom: 20px;
	}

	.daily-promotions-container {
		display: flex;
		justify-content: flex-start;
		column-gap: 20px;
	}
`;
