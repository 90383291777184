import { useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { ProductCategory } from '../../../interfaces/ProductCategory';
import { CategoryContainer, SearchIcon } from './styles';
import { RiSearch2Line } from 'react-icons/ri';

interface OtherProductCategoriesProps {
    selectedCategory: string;
    categories: ProductCategory[];
    setSelectedCategory(categroyId: string): void;
    showCategoryModal: boolean,
    setShowCategoryModal(): void;
}

const OtherProductCategories: React.FC<OtherProductCategoriesProps> = ({
    selectedCategory,
    categories,
    setSelectedCategory,
    showCategoryModal,
    setShowCategoryModal,
}) => {
    const [searchCategories, setSearchCategories] = useState<ProductCategory[]>([]);

    const handleSearchCategory = async (search: string) => {
		setSearchCategories(
            categories!.filter((category) => {
                return category.name.toLocaleLowerCase()
                .includes(search.toLocaleLowerCase());
            })
        );
	};
    
    return (
		<CategoryContainer>
			<button
				className={`others-card ${
					showCategoryModal  ? 'active' : ''
				}`}
				type="button"
				onClick={() => {
					setShowCategoryModal();
                    setSearchCategories(categories)
				}}
			>
                <SearchIcon>
                    <RiSearch2Line></RiSearch2Line>
                </SearchIcon>
				<span> Outras </span>
			</button>

            {showCategoryModal && (
                <div className="other-category-container">
                    <div className="other-category">
                        <div className="input-container">
                            <FiSearch className="icon" />
                            <input
                                type="text"
                                placeholder="Pesquisar categorias..."
                                onChange={(e) =>
                                    handleSearchCategory(e.target.value)
                                }
                            />
                        </div>
                        <div className="items">
                            {searchCategories.map((category) => (
                                <button
                                    type="button"
                                    className={`category ${selectedCategory === category.id ? 'active' : ''}`}
                                    onClick={() => {
                                        setSelectedCategory(category.id);
                                    }}
                                >
                                    {category.name}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            )}
		</CategoryContainer>
	); 
}

export default OtherProductCategories;