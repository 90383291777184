import styled from 'styled-components';

export const Container = styled.div`
	margin: 20px;
	h2 {
		color: #2b63fc;
		font-size: 10px;
		font-family: var(--Roboto-font-family);
		font-weight: 400;
		font-size: min(22px, 1.5vw);
		border-bottom: solid 1px #e1e1e1;
		padding-bottom: 18px;
	}

	button {
		background: #2b63fc;
		border: none;
		color: white;
		border-radius: 8px;
		width: calc(100%);
		margin: 40px 0 15px 0;
		height: 34px;

		align-self: center;

		font-family: var(--Roboto-font-family);
		font-weight: 400;
		font-size: 16px;
		transition: all 0.5s;

		&:hover {
			background: #fff;
			color: #2b63fc;
			border: solid 1px #2b63fc;
		}
	}
`;
export const InputsContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	padding: 15px 15px;
	background: white;
	box-shadow: 0 3px 6px #00000029;
	border-radius: 12px;
	.modal-title {
		font-size: 16px;
		padding-bottom: 18px;
		margin-bottom: 20px;
		padding: 17px;
		font-weight: 600;
		display: flex;
		align-items: center;

		svg {
			width: 21px;
			height: 21px;
			margin-right: 16px;
		}
	}

	.first-line {
		display: flex;
		column-gap: 15px;
		flex-direction: row;
		@media (max-width: 768px) {
			flex-direction: column;
		}
	}

	.second-line {
		display: flex;
		display: flex;
		column-gap: 15px;
		@media (max-width: 768px) {
			flex-direction: column;
		}
	}
	.address-number {
		display: flex;
		flex-direction: row;
	}
	.number {
		width: 32%;
		margin-left: 10%;
	}
	.rowCity {
		display: flex;
		flex-direction: row;
		width: 100%;
	}
	.city {
		/* width: 0%; */
	}
	.estado {
		width: 80%;
		margin-left: 13%;
	}
`;

export const FormContainer = styled.form``;
