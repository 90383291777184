import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Footer from './components/Footer/Footer';
import config from './config';
import { AppProvider } from './Provider';

import Routes from './routes';
import store, { persistor } from './store';

import { GlobalStyles } from './styles/globalStyles';

function App() {
	/* useEffect(() => {
		fetch(`${config.host}/refresh_token`, {
			method: 'POST',
			credentials: 'include',
			headers: { authorization: localStorage.getItem('authorization')! },
		}).then(async (result) => {
			const { accessToken } = await result.json();

			localStorage.setItem('authorization', accessToken);
		});
	}, []); */
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<AppProvider>
					<GlobalStyles />
					<Routes />
				</AppProvider>
			</PersistGate>
		</Provider>
	);
}
export default App;
