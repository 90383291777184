import styled from 'styled-components';

export const Container = styled.div`
	max-width: 190px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 4px;
	padding-left: 0px;
	padding-right: 0px;
	margin-bottom: 25px;
	height: max-content;
	min-height: 228px;
	box-shadow: 0px 3px 6px #0000001c;
	/* transform-origin: 0 0;
	transition: 100ms; */
	position: relative;
	z-index: 1;
	cursor: pointer;
	transition: box-shadow 0.5s;

	:hover {
		box-shadow: 1px 5px 6px #00000040;
		.cart-hover {
			right: 10px;
			top: 10px;
			opacity: 1;
		}
	}

	.hover-info {
		position: absolute;
		transform-origin: 0 0;
		transition: all 0.3s;

		h4 {
			font-weight: normal;
		}
	}

	figure {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		img {
			width: 100%;
			height: 161px;
			object-fit: cover;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}
	}
	.product-infos {
		font-size: 10px;
		margin: 26px 24px 0px 24px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		/* span {
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			color: #888888;
			padding: 8px;
		} */
		h3 {
			font-size: 12px;
			width: 150px;
			word-break: break-word;
			color: #5a646e;
			font-family: var(--Roboto-font-family);
			font-weight: 300;
			height: 25px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		h4 {
			font-weight: normal;
			font-size: 14px;
			line-height: 20px;
			width: 95%;
			word-break: break-word;
			color: #353a40;
			font-family: var(--Roboto-font-family);
			height: max-content;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-bottom: 8px;
		}
		h5 {
			font-size: 24px;
			margin-bottom: 8px;
			color: #002793;
			font-family: var(--Roboto-font-family);
			font-weight: bold;
		}
		.buy-button {
			display: flex;
			justify-content: center;
			padding: 8px;
			padding-bottom: 8px;
			position: absolute;
			button {
				background: #d29800;
				width: 154px;
				border: none;
				height: 28px;
				border-radius: 12px;
				color: white;
				font-family: var(--Roboto-font-family);
				font-weight: bold;
				font-size: min(13px, 2.3vw);
				position: relative;
			}
		}

		.small-text {
			font-size: 18px;
		}

		.active {
			display: flex;
			position: absolute;
		}
	}
`;

export const DefaultRow = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	align-items: center;
`;

export const DefaultColumn = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const CartHover = styled.div`
	position: absolute;
	width: 42px;
	height: 42px;
	border-radius: 50%;
	background-color: #002793;
	transition: opacity 0.5s;
	transition: box-shadow 0.5s;
	transition: top 0.5s;
	right: 10px;
	top: 10px;
	opacity: 0;
	box-shadow: 0px 0px 6px #00000010;

	@media (max-width: 768px) {
		right: 10px;
		top: 10px;
		opacity: 1;
	}

	:hover {
		box-shadow: 0px 2px 6px #00000040;
	}
	svg {
		margin: 9px;
	}
`;
