import { ShopCustomizations } from './ShopCustomizations';
import { Product } from './Product';

export default interface UserAccount {
	id: string;
	name: string;
	CPF: string;
	social_reason?: string;
	date_birth: string;
	email: string;
	password: string;
	user_type_id: string;
	user_types: UserTypes;
	Address?: Address[];
	Contacts?: Contacts[];
	Favorites?: Favorites[];
	shop_customizations?: ShopCustomizations;
	payment_method?: PaymentMethod;
	user_cards: UserCards[];
	ebanx_resource_token: string;
	ebanx_account_id: string;
	pagarme_recipient_id: string;
	user_photo?: {
		id: string;
		name: string;
	};
	CNPJ?: string;
	createdAt?: string;
}

export enum UserTypeEnum {
	customer = 'Customer',
	seller = 'Seller',
	administrator = 'Administrator',
}
export enum PaymentMethodEnglishEnum {
	CREDIT_CARD = 'CREDIT_CARD',
	BOLETO = 'BOLETO',
	PIX = 'BOLETO_PIX',
}
export enum PaymentMethodPortugueseEnum {
	CREDIT_CARD = 'Cartão de crédito',
	BOLETO = 'Boleto bancário',
	PIX = 'Pix',
}
export interface UserCards {
	id: string;
	card_id: string;
	last4CardNumber: string;
	expirationMonth: string;
	expirationYear: string;
	user_account_id: string;
}
export interface PaymentMethod {
	english_name: 'CREDIT_CARD' | 'BOLETO' | 'BOLETO_PIX';
	portuguese_name: 'Cartão de crédito' | 'Boleto bancário' | 'Pix';
	card_id?: string;
}
export interface UserTypes {
	id: string;
	name: 'Customer' | 'Seller' | 'Administrator';
}
export interface UserShopProducts {
	//user_account: UserAccount;
	user_account_id: string;
	user_account_name: string;
	shop_customizations: ShopCustomizations;
	user_products: Product[];
}

export interface Address {
	id: string;
	zip_code: string;
	street: string;
	state: string;
	city: string;
	number: string;
	complement?: string;
	user_account_id: string;
}
interface Contacts {
	id: string;
	contact_name?: string;
	phone: string;
	user_account_id: string;
}
export interface Favorites {
	id: string;
	product_id: string;
	user_account_id: string;
}
export interface UserPurchases {
	productId: number;
	purchase: boolean;
}

export interface UserState {
	currentUser?: UserAccount;
}

export const INSERT_USER = 'INSERT_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const UPDATE_PHOTO_NAME = 'UPDATE_PHOTO_NAME';
export const INSERT_ADDRESS = 'INSERT_ADDRESS';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const REMOVE_ADDRESS = 'REMOVE_ADDRESS';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const INSERT_USER_CARD = 'INSERT_USER_CARD';
export const REMOVE_USER_CARD = 'REMOVE_USER_CARD';
export const INSERT_USER_FAVORITE = 'INSERT_USER_FAVORITE';
export const REMOVE_USER_FAVORITE = 'REMOVE_USER_FAVORITE';

export interface InsertUser {
	type: typeof INSERT_USER;
	user: UserAccount;
}
export interface InsertUserCard {
	type: typeof INSERT_USER_CARD;
	card: UserCards;
}
export interface RemoveUserCard {
	type: typeof REMOVE_USER_CARD;
	card_id: string;
}
export interface SetPaymentMethod {
	type: typeof SET_PAYMENT_METHOD;
	paymentMethod: PaymentMethod;
}
export interface UpdatePhotoName {
	type: typeof UPDATE_PHOTO_NAME;
	photoName: string;
}
export interface RemoveUser {
	type: typeof REMOVE_USER;
}
export interface InsertAddress {
	type: typeof INSERT_ADDRESS;
	address: Address;
}
export interface UpdateAddress {
	type: typeof UPDATE_ADDRESS;
	address: Address;
}
export interface RemoveAddress {
	type: typeof REMOVE_ADDRESS;
	address_id: string;
}
export interface InsertUserFavorite {
	type: typeof INSERT_USER_FAVORITE;
	favorite: Favorites;
}
export interface RemoveUserFavorite {
	type: typeof REMOVE_USER_FAVORITE;
	favorite_id: string;
}

export type UserAction =
	| InsertUser
	| RemoveUser
	| UpdatePhotoName
	| InsertAddress
	| UpdateAddress
	| RemoveAddress
	| SetPaymentMethod
	| InsertUserCard
	| RemoveUserCard
	| InsertUserFavorite
	| RemoveUserFavorite;
