import styled from 'styled-components';

export const Container = styled.div`
	height: max-content;
	min-height: 100vh;
	background: #f5f5f5;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (max-width: 768px){
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
	}
`;
