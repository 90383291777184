import styled from 'styled-components';

export const Container = styled.div`
    padding: 26px 40px 34px 40px;
    font-family: var(--Roboto-font-family);
    min-width:730px;
    display:flex;
    flex-direction:column;
        h4{
        font-size:22px;
        font-weight:300;
        }

        textarea{
            border: 1px solid #8A99A8;
            font-family: var(--Roboto-font-family);
            color:#8A99A8;
            font-size: 15px;
            border-radius: 8px;
            min-height: 141px;
            padding: 12px 0 0 16px;
            margin-bottom:36px;
        }
        button{
            background: #2B63FC 0% 0% no-repeat padding-box;
            border-radius: 8px;
            color:#fff;
            font-weight:400;
            border:none;
            width: 326px;
            height: 40px;
            margin:auto;
            
        }

    .input-div{
        display: flex;
        margin: 40px 0;

        label{
            color:#5A646E;
            font-size:14px;
            margin-right:40px;
        }

        input[type=radio] {
            border: 1px solid #2B63FC;
            padding: 0.5em 0.5em;
            -webkit-appearance: none;
            margin-right:20px;
            border-radius: 3px;
            background-color: #2B63FC;
            
            
        }
        input[type=radio]:checked  {
            
            background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 20px;
        }       
}
`;
