import styled from 'styled-components';

export const Container = styled.main`
	margin: 36px 0px;
	width: var(--Default-page-alignment-vale);
	@media (max-width: 768px) {
		width: 90%;
	}

	height: max-content;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
`;
export const PageTitle = styled.h1`
	color: #353a40;
	font-family: var(--Roboto-font-family);
	font-weight: normal;
	font-size: 26px;
	margin-bottom: 23px;

	@media (max-width: 768px) {
		width: 90%;
	}
	width: 100%;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
`;

export const PurchasesCard = styled.div`
	width: 100%;
	background: #fff;
	border-radius: 8px;
	padding: 24px;
	height: max-content;
	min-height: 500px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	row-gap: 24px;

	@media (max-width: 768px) {
		background: #f5f5f5;
		padding: 0;
		row-gap: 16px;
	}
`;

export const NoPurchase = styled.div`
	height: 67vh;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #fff;

	@media (max-width: 768px) {
		border-radius: 8px;
		width: 100%;
	}

	.emoji-and-text {
		display: flex;
		flex-direction: column;
		align-items: center;

		.emoji {
			width: 96px;
			height: 96px;
		}

		h2 {
			color: #353a40;
			font-size: 20px;
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			margin-top: 22px;
			text-align: center;
			@media (max-width: 468px) {
				max-width: 221px;
				text-align: center;
			}
		}
	}
	.start-to-buy-button {
		width: 321px;
		height: 40px;
		background: #2b63fc;
		border: none;
		border-radius: 8px;

		@media (max-width: 468px) {
			width: 221px;
		}
		color: white;
		font-family: var(--Roboto-font-family);
		font-weight: 500;
		font-size: 16px;
		margin-top: 26px;
	}
`;
