import React, { forwardRef, InputHTMLAttributes } from 'react';
import FormInput from '../../UserRegister.tsx/FormInput/FormInput';
import { InputContainer } from './styles';

// import { Container } from './styles';
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	label: string;
	flex: string;
	margin?: string;
	border?: boolean;
	error?: boolean;
	borderColor?: string;
}

const Input: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
	{ label, margin, flex, border, error, borderColor, ...rest },
	ref
) => {
	return (
		<InputContainer
			width={flex}
			border={!border ? 'solid 1px #e1e1e1' : 'none'}
		>
			<label> {label} </label>
			<input
				ref={ref}
				{...rest}
				style={{
					border: `solid 1px ${error ? 'red' : borderColor? borderColor : '#e1e1e1'}`,
				}}
			/>
		</InputContainer>
	);
};

export default forwardRef(Input);
