import React from 'react';
import { Container } from './styles';
import { GrNotification } from 'react-icons/gr';
import { AiOutlineNotification } from 'react-icons/ai';
import { ImDisplay } from 'react-icons/im';
import { useHistory } from 'react-router-dom';
import { IconType } from 'react-icons/lib';
import { HtmlIframeProps } from 'semantic-ui-react/dist/commonjs/generic';
interface Props {
	icon: any;
	textMain: string;
	textSub: string;
	link: string;
}

const Cards: React.FC<Props> = ({
	icon = '',
	textMain = '',
	textSub = '',
	link = '',
}) => {
	const history = useHistory();

	return (
		<Container
			onClick={(e) => {
				history.push(link);
			}}
		>
			<div className="col-icon">{icon}</div>
			<div className="col-infos">
				<h3>{textMain}</h3>
				<h4>{textSub}</h4>
			</div>
		</Container>
	);
};

export default Cards;
