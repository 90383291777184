import React, { forwardRef, useImperativeHandle, useState } from 'react';

import Modal from 'react-modal';
import { ChargeProducts, Purchase } from '../../interfaces/ApiResponses/UserPurchases';
import {
	Container,
	ContainerTitle,
	ProductsAndAvaliationContainer,
	TrackingAndButtonsContainer,
} from './styles';
import image from '../../assets/imagem.jpg';
import { RiSearch2Line } from 'react-icons/ri';
import config from '../../config';
import { AvaliationContainer } from './AvaliationContainer';
import { Product } from '../../interfaces/Product';
import { Link } from 'react-router-dom';
type Props = {
	purchase: Purchase;
};
export interface MorePurchaseInformationsModalHandles {
	handleOpenModal: () => void;
}
const PurchaseDetails: React.ForwardRefRenderFunction<
	MorePurchaseInformationsModalHandles,
	Props
> = ({ purchase }, ref) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState<ChargeProducts | undefined>(undefined);
	const customStyles = {
		content: {
			top: '55%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			width: '80%',
			height: '80%',
			transform: 'translate(-50%, -50%)',
			padding: '40px',
		},
		overlay: {
			backgroundColor: 'rgba(0,0,0, 0.8)',
		},

		h2: {
			color: '#3B3D4A',
		},
	};
	function closeModal() {
		setIsOpen(false);
		setSelectedProduct(undefined);
	}
	function handleOpenModal() {
		setIsOpen(true);
	}
	useImperativeHandle(ref, () => {
		return {
			handleOpenModal,
		};
	});
	function returnPaymentMethod() {
		if (purchase.payment_method === 'BOLETO') {
			return {
				buttonText: 'Reimprimir boleto',
				link: purchase.link,
				isDisabled: false,
			};
		} else if (purchase.payment_method === 'BOLETO_PIX') {
			return {
				buttonText: 'Pix QR CODE',
				link: `data:image/jpeg;base64,${purchase.pix_imageInBase64}`,
				isDisabled: false,
			};
		} else {
			return {
				buttonText: 'Pago com cartão de crédito',
				link: ``,
				isDisabled: true,
			};
		}
	}
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={closeModal}
			style={customStyles}
			shouldCloseOnEsc={true}
			shouldCloseOnOverlayClick={true}
			contentLabel="Mais informações"
		>
			<Container>
				<ContainerTitle>
					<h2>Pedido {purchase.ebanx_charge_id}</h2>
				</ContainerTitle>
				<TrackingAndButtonsContainer>
					<div className="products-info">
						{selectedProduct ? (
							<>
								<h1>Detalhes do produto {selectedProduct?.products.title}</h1>
								<p>Vendedor: <strong>{selectedProduct.products.user_accounts.name}</strong></p>
								<p>Email para contato: <strong>{selectedProduct.products.user_accounts.email}</strong></p>
							</>
						) : (<h1>Selecione um produto para ver informações</h1>)}
					</div>

					<div className="buttons-container">
						{purchase.payment_method !== 'BOLETO_PIX' && (
							<button
								className="button-default gray"
								onClick={() =>
									window.open(purchase.link)
								}
							>
								Abrir checkout
							</button>
						)}
						<img src={returnPaymentMethod().link} alt="" />
						{/* <button className="button-default transparent">
							Comprar novamente
						</button> */}
						{
							purchase.charge_receipt && purchase.charge_receipt.length > 0 &&
							<a
								href={`${config.host}/static/receipts/${purchase.charge_receipt[0].name}`}
								style={{
									width: '100%',
								}}
							>
								<button className="button-default transparent">
									Visualizar Nota Fiscal
								</button>
							</a>
						}
						{/* <button className="button-default transparent-red">
							Cancelar compra
						</button>
						<button className="button-default red">
							Solicitar devolução
						</button> */}

						<span className="seller-report-link"></span>
					</div>
				</TrackingAndButtonsContainer>
				<ProductsAndAvaliationContainer>
					<div className="product-container">
						<div className="title">
							<h3>Produtos nessa compra</h3>
						</div>
						{purchase.charge_products.map((item) => (
							<div 
								className="item"
								onClick={() => {
									setSelectedProduct(item)
								}}
								key={item.id}
							>
								<div className="photo-and-title">
									<img
										src={`${config.host}/static/products/${item.products.product_photos[0]?.name}`}
										className="product-image"
										alt=""
									/>
									<h2 className="product-title">
										{`${item.products.title.substring(
											0,
											20
										)} ${
											item.product_variations?.color
												? `- ${item.product_variations?.color}`
												: ''
										}`}
									</h2>
								</div>
								<div className="size-and-price">
									<span className="size">
										Tam:{' '}
										{item.product_variations
											? item.variation_sizes?.size
											: 'U'}
									</span>
									<span className="price">
										{purchase.amount.toLocaleString(
											'pt-br',
											{
												style: 'currency',
												currency: 'BRL',
											}
										)}
									</span>
								</div>
							</div>
						))}
					</div>
					<div className="avaliation-container">
						{ !selectedProduct && 
						<>
							<div className="title">
								<h3>
									Selecione um produto para avaliar
								</h3>
							</div>
						</>}
						{
							selectedProduct && !selectedProduct.canceled &&
							<>
								<div className="title">
									<h3>
										{
											`Avaliação do produto: ${selectedProduct.products.title}`
										}
									</h3>
								</div>
								<AvaliationContainer
									productId={selectedProduct.product_id}
								/>
							</>
						}
						{
							selectedProduct && selectedProduct.canceled && 
							<>
								<div className="title">
									<h3>
										Produto cancelado
									</h3>
								</div>
								<div className='cancel'>
									<p>Motivo: {selectedProduct.cancel_reason}</p>
									{selectedProduct.canceled_at && 
									<p>
										Cancelado em: {new Date(selectedProduct.canceled_at).toLocaleDateString('pt-br')}
									</p>}
								</div>
							</>
						}
					</div>
				</ProductsAndAvaliationContainer>
			</Container>
		</Modal>
	);
};

export default forwardRef(PurchaseDetails);
