import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: max-content;
	width: 100%;

	padding-top: 0px;
	color: #353a40;
	font-family: var(--Roboto-font-family);
	font-weight: normal;
	font-size: 16px;
	word-break: break-word;
	overflow-y: auto;

	.row {
		display: flex;
		flex-direction: row;
	}
	.delete {
		width: 125px;
		height: 30px;
		background: black;
		border-radius: 8px;
		color: white;
		text-align: center;
		padding-top: 10px;
		cursor: pointer;
	}
	.question-container {
		width: 90%;
		background-color: #f8f8f8;
		border-radius: 17px;
		padding: 16px 27px;
		margin-bottom: 14px;
	}
`;
export const QuestionActions = styled.form`
	margin-bottom: 31px;
	width: 100%;
	font-family: var(--Roboto-font-family);
	.text-container {
		width: 100%;
		margin-top: 8px;
		textarea {
			width: calc(65% - 34px);
			height: 100px;
			border: solid 1px #b1b1b1;
			resize: none;
			border-radius: 8px;

			padding: 10px 17px;
			font-weight: 400;
			font-size: 14;
			color: #8a99a8;
		}
		.text-containter-actions {
			display: flex;
			justify-content: flex-end;
			column-gap: 13px;
			width: 65%;

			.negative-button {
				width: 110px;
				height: 33px;
				background: white;
				border: solid 1px #8a99a8;
				border-radius: 8px;

				color: #8a99a8;
				font-weight: 500;
				font-size: 12px;
			}
			.positive-button {
				width: 110px;
				height: 33px;
				background: #02c384;
				border: none;
				border-radius: 8px;
				color: white;
			}
		}
	}
`;

export const AnswersContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 10px;
	margin-bottom: 31px;
	margin-left: 36px;
	span {
		/* color: #8a99a8; */
		font-family: var(--Roboto-font-family);
		font-weight: normal;
		font-size: 16px;
		word-break: break-word;
		overflow-y: auto;
	}
`;

export const ShowMore = styled.span`
	color: #2b63fc;
	cursor: pointer;
`;

export const AnswerDescription = styled.span`
	color: #8a99a8;
`;

export const ReplyButton = styled.button`
	margin-right: auto;
	font-family: var(--Roboto-font-family);
	width: 118px;
	height: 40px;
	font-size: 16px;
	border: 1px solid #2b63fc;
	border-radius: 8px;
	background: #2b63fc;
	margin-bottom: 31px;

	color: #fff;
	font-weight: normal;

	&:hover {
		background: #2b63fc;
		color: #ffffff;
	}
`;
