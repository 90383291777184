import React from 'react';
import Categorys from '../../components/Categorys/Categorys';
import { Container } from './styles';

const CategorysPage: React.FC = () => {
	return (
		<>
			<Container>
				<Categorys />
			</Container>
		</>
	);
};

export default CategorysPage;
