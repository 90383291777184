import { FormEvent, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import api from '../../../api';
import { ProductCategories } from '../Categorys';
import { ModalContainer } from '../CreateCategoryModal/styles';

interface IEditCategoryModalProps {
    openModal: React.MutableRefObject<(category: ProductCategories) => void>;
}

const EditCategoryModal: React.FC<IEditCategoryModalProps> = ({
    openModal
}: IEditCategoryModalProps) => {

    const [isOpen, setIsOpen] = useState(false);
    const [category, setCategory] = useState<ProductCategories>();
    const [name, setName] = useState('');

    const onSubmitSave = async (e: FormEvent) => {
        e.preventDefault();

        if(!category) { setIsOpen(false); return; }

        const response = await api.post('/update-category', {
            id: category?.id,
            name,
        });

        if(response.status === 200)
            // reload window
            window.location.reload();
    }

    const onSubmitDelete = async () => {
        if(!category) { setIsOpen(false); return; }

        const response = await api.post('/delete-category', {
            id: category?.id,
        });

        if(response.status === 200)
            // reload window
            window.location.reload();
    }

    openModal.current = (category: ProductCategories) => {
        setIsOpen(true);
        setCategory(category);
    }

    useEffect(() => {
        setName(category?.name || '');
    }, [category])

    return <Modal 
            isOpen={isOpen}
			onRequestClose={() => setIsOpen(false)}
            style={{
				overlay: {
					position: 'fixed',
					top: 0,
					zIndex: 1001,
					left: 0,
					right: 0,
					bottom: 0,
					background: '#0007',
				},
				content: {
					top: '50%',
					left: '50%',
					right: 'auto',
					padding: '0 auto',
					bottom: 'auto',
					marginRight: '-50%',
					maxHeight: '100%',
					border: 'none',
					borderRadius: '11px',
					transform: 'translate(-50%, -50%)',
				},
    }}><ModalContainer>
        <form
            onSubmit={onSubmitSave}
        >
            <div className='title-block'>
                <h1>Editar categoria</h1>
            </div>
            <div className='cards'>
                <div className='row'>
                    <div className='name'>
                        <span>Nome</span>
                        <input type='text' 
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        />
                    </div>
                </div>
                <div className='button-text'>
                    <button className='button-text' type='submit'>Salvar</button>
                    <button className='button-text' type='button' style={{
                        backgroundColor: '#F44336',
                    }}
                        onClick={() => onSubmitDelete()}
                    >Excluir</button>
                </div>
            </div>
        </form>
    </ModalContainer></Modal>
};

export default EditCategoryModal;