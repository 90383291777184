import { AxiosResponse } from 'axios';
import { spawn } from 'child_process';
import { add } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import api from '../../../../../api';
import Spinner from '../../../../Loader/Spinner';

import { Container } from './styles';

type Props = {
	step(): void;
};
interface PageInputs {
	email: string;
}

const ForgotPassword: React.FC<Props> = ({ step }) => {
	const { register, handleSubmit, errors } = useForm<PageInputs>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	});
	const [isDisabled, setDisabled] = useState(false);

	const onSubmit = async (data: PageInputs) => {
		setDisabled(true);

		try {
			api.post('/forgot-password', {
				email: data.email,
			}).then((res: AxiosResponse<any>) => {
				if (res.status === 200) {
					step();
				} else {
					window.alert('falha ao enviar email, verifique seu email');
				}
			});
		} catch (error) {
			setDisabled(true);
		}
	};

	return (
		<Container onSubmit={handleSubmit(onSubmit)} id="teste">
			<h6>Recuperar senha</h6>
			<p>
				Insira seu e-mail e serão enviadas as instruções para efetuar a
				recuperação de senha
			</p>
			<div>
				<input
					type="text"
					name="email"
					placeholder="E-mail"
					style={{
						border: `${
							!errors.email
								? 'solid 1px #c9c9c9'
								: 'solid 1px red'
						}`,
					}}
					ref={register({
						required: true,
						pattern: {
							value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
							message: 'Insira um email válido',
						},
					})}
				/>
				{errors.email && (
					<span className="error-text">{errors.email.message}</span>
				)}
				<button type="submit" id="submit-button">
					{' '}
					{isDisabled ? <Spinner /> : 'Enviar'}
				</button>
			</div>
		</Container>
	);
};

export default ForgotPassword;
