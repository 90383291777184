import { useState } from 'react';
import { AxiosResponse } from 'axios';
import { formatDistance, add } from 'date-fns';
import brazil from 'date-fns/locale/pt-BR';
import api from '../api';

export interface ShippingValues {
	Codigo: string;
	Valor: string;
	PrazoEntrega: string;
	ValorSemAdicionais: string;
	ValorMaoPropria: string;
	ValorAvisoRecebimento: string;
	ValorValorDeclarado: string;
	EntregaDomiciliar: string;
	EntregaSabado: string;
	Erro: string;
}
export interface FormattedData {
	service: string;
	value: string;
	deadline: string;
	message: string | null;
}
interface useShippingValueProps {
	onError(err: unknown): void;
	onComplete(result: FormattedData[]): void;
}

type GetValuesParams = {
	productId: string;
	destinationZipCode: string;
};

interface useShippingValueReturn {
	getValues(params: GetValuesParams): Promise<FormattedData[] | undefined>;
	isLoading: boolean;
	hasError: boolean;
}

export const useShippingValue = ({
	onComplete,
	onError,
}: useShippingValueProps): useShippingValueReturn => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [hasError, setHasError] = useState<boolean>(false);

	const mapResponse = (shippings: ShippingValues[]) => {
		const availableServices: { [key: string]: string } = {
			'04014': 'SEDEX',
			'04510': 'PAC',
		};
		const formatedShippings = shippings.map((actualShipping) => {
			if (actualShipping.Erro !== '0') {
				return {
					message:
						'Verifique com o vendedor informações sobre a entrega',
					service: availableServices[actualShipping.Codigo],
					value: '',
					deadline: '',
				};
			}
			return {
				service: availableServices[actualShipping.Codigo],
				value: actualShipping.Valor,
				deadline: formatDistance(
					new Date(),
					add(new Date(), {
						days: Number(actualShipping.PrazoEntrega),
					}),
					{
						locale: brazil,
					}
				),
				message: null,
			};
		});

		return formatedShippings;
	};
	const getValues = async (
		params: GetValuesParams
	): Promise<FormattedData[] | undefined> => {
		setIsLoading(true);
		try {
			const result = await api.get<
				unknown,
				AxiosResponse<{ status: string; data: ShippingValues[] }>
			>(
				`/get-shipping-value?product_id=${params.productId}&destination_zip_code=${params.destinationZipCode}`,
				{ timeout: 15000 }
			);
			setIsLoading(false);
			const formattedData = mapResponse(result.data.data);
			onComplete(formattedData);
			return formattedData;
		} catch (error) {
			setHasError(true);
			setIsLoading(false);

			onError(error);
		}
	};

	return {
		getValues,
		isLoading,
		hasError,
	};
};
