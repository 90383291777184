import styled from 'styled-components';

export const Container = styled.div`
	h2 {
		color: #2B63FC;
		font-size: 10px;

		font-family: var(--Roboto-font-family);
		font-weight: 400;
		font-size: min(22px, 1.5vw);

		border-bottom: solid 1px #e1e1e1;
		padding-bottom: 18px;
	}
`;
export const InputsContainer = styled.form`
	display: flex;
	flex-direction: column;

	margin-top: 20px;
	padding: 15px 50px;

	.first-line {
		display: flex;
		column-gap: 15px;
		flex-direction: row;
	}

	.second-line {
		display: flex;
		margin-top: 30px;
		display: flex;
		column-gap: 15px;
	}

	button {
		background: #2B63FC;
		border: none;
		color: white;
		border-radius: 8px;

		width: 236px;
		height: 34px;

		margin-top: 40px;
		align-self: center;

		font-family: var(--Roboto-font-family);
		font-weight: 400;
		font-size: min(12px, 1vw);
		transition: all .5s;

		&:hover {
			background: #fff;
			color:#2B63FC;
			border: solid 1px #2B63FC;
		}
	}
`;
