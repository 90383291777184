import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { insertInstallments } from '../../../../actions/ProductsCartActions';
import ProductsCart from '../../../../interfaces/ProductsCart';
import UserAccount, {
	PaymentMethodEnglishEnum,
} from '../../../../interfaces/UserAccount';
import { AppState } from '../../../../store';
import { ItemContainer } from '../styles';

const PaymentPlan: React.FC = () => {
	const [isInstallment, setIsInstallment] = useState<boolean>(false);
	const productsCart: ProductsCart[] = useSelector(
		(state: AppState) => state.productsCart.currentCart
	);
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const dispatch = useDispatch();
	const installments = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

	function renderInstallments(maxInstallments: number) {
		let installments: number[] = [];
		for (let index = 2; index <= maxInstallments; index++) {
			installments.push(index);
		}
		return installments;
	}
	return (
		<ItemContainer>
			<h2>Plano de pagamento</h2>
			<div className="options-container">
				<div className="item">
					<div
						className="selector"
						onClick={() =>
							dispatch(
								insertInstallments(
									1,
									productsCart[0].parameters.product.id
								)
							)
						}
					>
						{productsCart[0].installments === 1 && (
							<div className="active-selector" />
						)}
					</div>
					<div className="option-text">
						<span className={productsCart[0].installments === 1? "span-blue" : ""}>
							À vista
						</span>
					</div>
				</div>
				<div className="item">
					<div
						className="selector"
						onClick={() => {
							if (
								user.payment_method?.english_name ===
								PaymentMethodEnglishEnum.CREDIT_CARD
							) {
								dispatch(
									insertInstallments(
										2,
										productsCart[0].parameters.product.id
									)
								);
							}
						}}
					>
						{productsCart[0].installments! > 1 &&
							user.payment_method?.english_name ===
								PaymentMethodEnglishEnum.CREDIT_CARD && (
								<div className="active-selector" />
							)}
					</div>
					<div
						className="option-text"
						style={{
							opacity: `${
								user.payment_method?.english_name !==
								PaymentMethodEnglishEnum.CREDIT_CARD
									? '0.5'
									: '1'
							}`,
						}}
					>
						{' '}
						<span 
							className={(productsCart[0].installments! > 1 &&
								user.payment_method?.english_name ===
									PaymentMethodEnglishEnum.CREDIT_CARD)? "span-blue" : ""}
						>
							Parcelado em:{' '}
							<select
								onChange={(e) =>
									dispatch(
										insertInstallments(
											parseInt(e.target.value),
											productsCart[0].parameters.product
												.id
										)
									)
								}
							>
								{installments.map((item) => (
									<option value={item}>{item}</option>
								))}
							</select>{' '}
						</span>
					</div>
				</div>
			</div>
		</ItemContainer>
	);
};

export default PaymentPlan;
