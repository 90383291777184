import { useRef } from 'react';

type UseDebounceReturn = (param: string) => void;

export const useDebounce = (
	debounceFunction: (param: string) => void,
	delay: number
): UseDebounceReturn => {
	const timeoutRef = useRef<number | undefined>();
	const debounce = (param: string) => {
		window.clearTimeout(timeoutRef.current);
		timeoutRef.current = window.setTimeout(() => {
			debounceFunction(param);
		}, delay);
	};

	return debounce;
};
