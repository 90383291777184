import React, { useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import api from '../../api';
import HeaderAdmin from '../HeaderAdmin/HeaderAdmin';
import LoginHeader from '../Login/LoginHeader/LoginHeader';
import CardsOccurrences from './CardsOccurrences/CardsOccurrences';
import { Container } from './styles';

interface IReportNotification {
	id: string;
	solved: boolean;

	related_product_id: string;
	created_at: string;
}

const Notification: React.FC = () => {
	const [notification, setNotifications] = useState<IReportNotification[]>([]);
	const [showUnsolved, setShowUnsolved] = useState<boolean>(true);

	const history = useHistory();

	const fetchNotification = async () => {
		const response = await api.get('/report-notifications')

		setNotifications(response.data);
	}

	useEffect(() => {
		fetchNotification();
	}, [])

	const handleSolve = async (notification_id: string) => {
		await api.post('/report-solved', { notification_id });

		await fetchNotification();
	}

	const goToProduct = async (product_id: string) => {
		history.push('/product/' + product_id);
	}

	return (
		<>
			{/* <LoginHeader /> */}
			<HeaderAdmin />
			<Container>
				<div className="buttons-search">
					<div className="text-buttons">
						<h1>Ocorrências</h1>
						<div className="row-buttons-text">
							<div className="buttons">
								<div className={showUnsolved ? "button-active" : "button-no-active"} onClick={() => setShowUnsolved(true)}>
									<p>Denúncias pendentes</p>
								</div>
								<div className={!showUnsolved ? "button-active" : "button-no-active"} onClick={() => setShowUnsolved(false)}>
									<p>Denúncias resolvidas</p>
								</div>
							</div>
							{/* <div className="search">
								<input placeholder="xxxxxx"></input>
								<AiOutlineSearch className="search-icon" />
							</div> */}
						</div>
					</div>
				</div>
				<div className="cards-flex">
					{notification.filter(notification => notification.solved == !showUnsolved).map((notification) => <CardsOccurrences 
						name="Report de produto" 
						ids={notification.id}
						description="Esse produto foi denunciado por um usuário"
						data={notification.created_at}
						onClickSolve={() => handleSolve(notification.id)}
						onClickGo={() => goToProduct(notification.related_product_id)}
						solved={notification.solved}
					/>)}
				</div>
			</Container>
		</>
	);
};
export default Notification;
