import styled from 'styled-components';

export const PageContainer = styled.main`
	display: flex;
	flex-direction: row;
	justify-content: center;
	height: 100%;
`;

// export const Container = styled.div`
// 	display: flex;
// 	/* height: 90vh;
// 	grid-template-rows: 1fr 1fr 8fr; */
// 	/* grid-template-columns: 90px 250px 750px 90px; */
// 	width: var(--Default-page-alignment-vale);
// 	background: #f5f5f5;
// 	/* row-gap: 5px; */
// 	height: 100%;
// 	margin-bottom: 50px;
// 	flex-direction: row;
// 	justify-content: center;
// 	padding-top: 35px;

export const Container = styled.div`
	width: var(--Default-page-alignment-vale);
	height: 100%;
	margin-bottom: 50px;
	@media (max-width: 768px) {
		width: 90%;
	}
	.filterRow{
		row-gap: 25px;
		display: flex;
		flex-direction: column;
		@media (max-width: 768px){
			flex-direction: row;
			justify-content: space-between;
			width: 100%;
			/* display: flex; */

		}
	}
	
	header {
		display: flex;
		flex: column;
		margin-top: 40px;
		margin-bottom: 25px;
		@media (max-width: 768px) {
			margin-top: 20px;
		}
		h2 {
			color: #3b3d4a;
			font-size: 32px;
			font-family: var(--Roboto-font-family);
			font-weight: bold;
		}
		h4 {
			color: #3b3d4a;
			font-size: 0.8rem;
			font-family: var(--Roboto-font-family);
			font-weight: bold;
			text-decoration: underline;
		}
	}

	.bottom-block {
		background: #f5f5f5;
		padding: 20px;
		padding-left: 0;
		display: grid;
		grid-template-columns: 25% 75%;
		column-gap: 22px;
		row-gap: 10px;
		padding-top: 5px;
		@media (max-width: 768px) {
			display: flex;
			flex-direction: column;
			padding-right: 0px;
		}
		.filters {
			row-gap:25px;
			font-family: var(--Roboto-font-family);
			@media (max-width: 768px) {
				align-items: baseline;
				/* padding: 20px; */
				padding: 20px 0px 10px 20px;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;
			}
			.more {
				color: #1C27CC;
				font-size: 12px;
				text-decoration: underline;
				font-weight: normal;
			}
			ul {
				list-style: none;
				color: #5a646e;
				font-size: 16px;
				font-weight: 300;
			}
			li {
				margin-bottom: 8px;
				transition: all 0.3s;
				cursor: pointer;
			}
			.store-list {
				@media (min-width: 768px) {
					max-height: 145px;
					overflow: auto;
					margin-right: 70px;
				}
			}
			ul {
				list-style: none;
				color: #5a646e;
				font-size: 16px;
				font-weight: 300;
			}
			li {
				margin-bottom: 8px;
				transition: all 0.3s;
				cursor: pointer;
			}
			#activated {
				font-weight: bold;
			}
			li:hover {
				color: #5a646e;
				text-decoration: underline;
				font-weight: bold;
			}
			span:hover {
				font-weight: bold;
				text-decoration: underline;
			}
			h6 {
				font-size: 18px;
				color: #353a40;
				margin-bottom: 16px;
			}
			span {
				color: #8a99a8;
				font-size: 14px;
				cursor: pointer;
				transition: all 300ms;
			}
			.filters-input {
				input {
					width: 73px;
					height: 40px;
					background: #f5f5f5 0% 0% no-repeat padding-box;
					border: 1px solid #8a99a8;
					border-radius: 4px;
					text-align: center;
					&::placeholder {
						font-size: 12px;
					}
				}
			}
		}
	}
`;
export const Select = styled.select`
	width: 150px;
	height: 30px;
	border: none;
	border-radius: 6px;
	padding: 5px;

	color: #888888;
	font-family: var(--Roboto-font-family);
	font-weight: 400;

	&:focus {
		outline: none;
	}
`;
