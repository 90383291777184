import React, { useEffect, useState } from 'react';
import ProductsCart from '../../interfaces/ProductsCart';
import { AppState } from '../../store';
import MyCart from './MyCart/MyCart';
import ShippingContainer from './ShippingContainer/ShippingContainer';
import { IProduct } from '../../Pages/ProductPage/ProductPage';
import { useSelector, useDispatch } from 'react-redux';
import { insertProducts } from '../../actions/ProductsList';
import api from '../../api';
import { ProductsList, AreaTypes } from '../../interfaces/ProductsList';
import { productExibitionRules } from '../../utils/productsExibitionRules';
// import ProductCard from '../Default/ProductCard/productCard';

import {
	Container,
	PageContainer,
	AlignmentContainer,
	DefaultRow,
	ProductsSeller,
} from './styles';
import ProductCardSmall from '../Product/ProductCardSmall/ProductCardSmall';

const Cart: React.FC = () => {
	const productsCart: ProductsCart[] = useSelector(
		(state: AppState) => state.productsCart.currentCart
	);

	const productsList: ProductsList[] = useSelector(
		(state: AppState) => state.productsList?.currentProductsList
	);
	const dispatch = useDispatch();

	useEffect(() => {
		api.post('/list-products').then((response: any) => {
			dispatch(
				insertProducts(
					productExibitionRules(
						response.data.data.response,
						AreaTypes.HOMEPAGE
					),
					AreaTypes.HOMEPAGE
				)
			);
		});
	}, []);

	useEffect(() => {
		if (productsCart.length > 0) {
			api.get(`/get-products-cart-by-category`, {
				params: {
					category_id: productsCart[0].parameters.product.category_id,
				},
			}).then((response: any) => {
				dispatch(
					insertProducts(
						productExibitionRules(
							response.data.data.response,
							AreaTypes.HOMEPAGE
						),
						AreaTypes.HOMEPAGE
					)
				);
			});
		}
	}, []);

	return (
		<PageContainer>
			<AlignmentContainer>
				<Container>
					<h1>Meu Carrinho</h1>
					<DefaultRow>
						<MyCart />
						<ShippingContainer />
					</DefaultRow>
					<ProductsSeller>
						<h3 className="same-category">
							Confira outros produtos!
						</h3>
						<div className="products">
							{productsList
								.find(
									(item) =>
										item?.areaTitle === AreaTypes.HOMEPAGE
								)
								?.products?.sort(() =>
									Math.random() > 0.5 ? 1 : -1
								)
								.map((product, index) => (
									<>
										{index < 4 && (
											<ProductCardSmall
												product={product}
												maxWidth={'202px'}
											/>
										)}
									</>
								))}
						</div>
					</ProductsSeller>
				</Container>
			</AlignmentContainer>
		</PageContainer>
	);
};

export default Cart;
