	import React, { useEffect, useState } from 'react';
import api from '../../api';

import ProductCard from '../Default/ProductCard/productCard';

import {
	AdvertisingCard,
	AdvertisingContainer,
	CardContainer,
	ProductContainer,
	ImageContainer,
	ImageCard,
	CategoryContainer,
	CategoryCard,
	CategoryRowContainer,
	AssociatedContainer,
	AssociatedRowContainer,
	ModalCookies,
} from './styles';
import { AreaTypes, ProductsList } from '../../interfaces/ProductsList';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import { insertProducts } from '../../actions/ProductsList';
import { productExibitionRules } from '../../utils/productsExibitionRules';
import { useHistory, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import CategoryEletrodomestico from '../../assets/homePageImages/categoryEletrodomestico.png';
import CategoryModaMasculina from '../../assets/homePageImages/categoryModaMasculina.png';
import CategoryMoveis from '../../assets/homePageImages/categoryMoveis.png';
import CategorySofasAlmofadas from '../../assets/homePageImages/categorySofasAlmofadas.png';
import CategoryFerramentas from '../../assets/homePageImages/categoryFerramentas.png';
import UserAccount, { UserShopProducts } from '../../interfaces/UserAccount';
import config from '../../config';
import { getFristImageSource } from '../../utils/GetProductImage';
import Carousel from './Carousel/Carousel';
import placeholder_store_background from '../../assets/placeHolders/placeholder_store_background.png';
import placeholder_store_profile from '../../assets/placeHolders/placeholder_store_profile.png';
import { FeatureStores } from '../Edit/Featured/Associated/Associated';
import { FeaturedStoreCard } from '../FeaturedStoreCard';
import { useCookies } from 'react-cookie';

interface PageParams {
	userId: string;
}

type Banners = {
	id: string;
	path_image: string;
	order: number;
};

type FeaturedStores = {
	id: string;
	user_accounts_id: string;
};

const Home: React.FC = () => {
	const productsList: ProductsList[] = useSelector(
		(state: AppState) => state.productsList?.currentProductsList
	);
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const dispatch = useDispatch();
	const history = useHistory();
	const pageParams = useParams<PageParams>();
	const BreakPoints = [{ width: 1, itemsToShow: 1 }];
	const [usersShopsProducts, setUsersShopsProducts] = useState<
		UserShopProducts[]
	>([]);
	const [orderMiddleBanners, setOrderMiddleBanners] = useState<Banners[]>([]);

	useEffect(() => {
		api.post('/list-products').then((response: any) => {
			dispatch(
				insertProducts(
					productExibitionRules(
						response.data.data.response,
						AreaTypes.HOMEPAGE
					),
					AreaTypes.HOMEPAGE
				)
			);
		});
	}, []);

	const [featuredStores, setFeaturedStores] = useState<FeatureStores[]>([]);
	useEffect(() => {
		api.get('/all-featured-store').then((response) => {
			setFeaturedStores(response.data.featuredStore);
		});
	}, []);
	useEffect(() => {
		api.get('/get-all-users-shops-products').then((response: any) => {
			let tempUsersShopsProducts = response.data.usersShopProducts;
			setUsersShopsProducts([...tempUsersShopsProducts]);
		});
	}, []);

	useEffect(() => {
		api.get('/all-middle-banner')
			.then((response: any) => {
				setOrderMiddleBanners(response.data.middleBanner);
			})
			.catch((err) => {});
	}, []);

	const [cookies, setCookies] = useCookies(['ok_cookies']);

	useEffect(() => {});

	return (
		<>
			<ModalCookies display={cookies.ok_cookies ? 'none' : 'block'}>
				<h1>Cookies</h1>
				<h2>
					Este site utiliza cookies para te proporcionar uma melhor
					experiência. Ao continuar navegando, você aceita nossa{' '}
					<u>Política de privacidade.</u>
				</h2>
				<button onClick={() => setCookies('ok_cookies', true)}>OK</button>
			</ModalCookies>
			<Carousel />
			<CardContainer>
				<h2>Produtos mais pesquisados essa semana</h2>
				<ProductContainer>
					{productsList
						.find((item) => item?.areaTitle === AreaTypes.HOMEPAGE)
						?.products?.map((product, index) => (
							<>
								{index < 10 && (
									<ProductCard product={product} />
								)}
							</>
						))}
				</ProductContainer>
			</CardContainer>

			<ImageContainer>
				{orderMiddleBanners.map((banner) => (
					<>
						{banner.order === 1 && (
							<ImageCard
								className="ImageCard1"
								src={`${config.host}/static/middle-banners/${banner.path_image}`}
							/>
						)}
						{banner.order === 2 && (
							<ImageCard
								src={`${config.host}/static/middle-banners/${banner.path_image}`}
							/>
						)}
					</>
				))}
			</ImageContainer>

			<AdvertisingContainer>
				{orderMiddleBanners.map((banner) => (
					<>
						{banner.order === 3 && (
							<AdvertisingCard
								src={`${config.host}/static/middle-banners/${banner.path_image}`}
							/>
						)}
						{banner.order === 4 && (
							<AdvertisingCard
								src={`${config.host}/static/middle-banners/${banner.path_image}`}
							/>
						)}
					</>
				))}
			</AdvertisingContainer>

			<AssociatedContainer>
				<h2>Confira as lojas associadas</h2>
				<AssociatedRowContainer>
					{featuredStores.map((store) => (
						<FeaturedStoreCard store={store} />
					))}
				</AssociatedRowContainer>
			</AssociatedContainer>
		</>
	);
};

export default Home;
