import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    padding: 12px;

	font-family: var(--Roboto-font-family);

    > .starsRow {
        > svg {
            color: #F49D37;
        }
    }

    > a {
        color: #2D327C;
        text-decoration: underline;
        font-size: 16pt;
    }

    > p {
        margin-top: 14px;
        color: #5A646E;
        font-size: 16pt;
    }
`