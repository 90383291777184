import React from 'react';
import imagem from '../../../assets/imagem.jpg'
import { RiStarFill, RiStarHalfFill, RiStarLine } from 'react-icons/ri';
import { 
  Container,
  ItemsRow,
  DefaultRow,
  InfoSection,
  UserSection,
  ButtonSection,
  CircleSection,
  StarsDiv
} from './styles';
import { IProduct } from '../../../Pages/ProductPage/ProductPage';
import config from '../../../config';
import { Link, NavLink } from 'react-router-dom';
import api from '../../../api';
import { getFristImageSource } from '../../../utils/GetProductImage';

type Props = {
  notification: any,
};

const NotificationCard: React.FC<Props> = ({ notification }) => {
  
  return (
    <Container>
      <InfoSection>
        <h1>
          {notification.title}
          {
            notification.related_product_reviews &&
            <StarsDiv>
                {[...Array(5)].map((star, index) => (
                  index + 1 <= notification.related_product_reviews.rating ?
                    <RiStarFill key={index} /> :
                    <RiStarLine key={index} />
                ))}
            </StarsDiv>
          }
        </h1>
        <h2>
          {
            notification.related_product?.title || 
            notification.related_product_reviews?.product.title
          }
        </h2>
        <p>
          {notification.message}
        </p>
      </InfoSection>
      <ButtonSection>
        <Link
          to={`/product/${notification.related_product?.id || notification.related_product_reviews?.product.id}`}
          style={{
            width: '100%',
          }}
        >
          <button className="action-button">
            Visualizar
          </button>
        </Link>
      </ButtonSection>
    </Container>
  );
}

export default NotificationCard;