import styled from 'styled-components';

export const ModalContainer = styled.div`
	width: 796px;
	height: 408px;
	padding: 26px;
	font-family: 'Roboto';
	.search {
		display: flex;
		flex-direction: row;
		position: relative;

		svg {
			padding-right: 5px;
			width: 18px;
			position: absolute;
			left: 5px;
			color: #2a3199;
		}
	}
	.title {
		margin-bottom: 31px;
		h1 {
			font-size: 19px;
			font-weight: 500;
			color: #2a3199;
		}
	}
	input {
		background: white;
		border-radius: 8px;
		border: 1px solid #c5ced7;
		width: 95%;
		height: 32px;
		padding-left: 30px;
	}
	ul {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		gap: 24px;
		padding: 12px 0px;
		width: 100%;

		border-bottom: 1px solid rgba(0,0,0,0.1);

		> li {
			flex: 1 1 auto;
			text-overflow: ellipsis;
			overflow: hidden;
			font-size: 16px;
		}

		> button {
			background-color: #2a3199;
			color: white;
			border-radius: 8px;
			border: 1px solid #2a3199;
			padding: 8px;

			transition: all 0.2s;

			&:hover {
				background-color: white;
				color: #2a3199;
			}
		}

	}
`;

export const ProductContainer = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: 20px;
	row-gap: 19px;
	width: 100%;

	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;

	height: 90%;
	padding-bottom: 50px;

	@media (max-width: 768px) {
		display: flex;
		width: 105%;
		flex-wrap: nowrap;
		/* background: purple; */
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		padding-bottom: 38px;
	}
`;
export const DailyPromotion = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 15px;
	padding-top: 40px;

	@media (max-width: 768px) {
		width: calc(100% - 24px);
		padding-left: 5%;
		height: 400px;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	h2 {
		color: #353a40;
		font-size: 20px;
		font-weight: normal;
		font-family: var(--Roboto-font-family);
		margin-bottom: 20px;
	}

	.daily-promotions-container {
		display: flex;
		justify-content: flex-start;
		column-gap: 20px;
	}
`;
