import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useRouteMatch } from 'react-router-dom';
import { insertMobileHeader } from '../../actions/MobileHeaderActions';
import { BackPreset } from '../../interfaces/MobileHeader';
import UserAccount from '../../interfaces/UserAccount';
import { AppState } from '../../store';
import useCheckMobileScreen from '../../utils/useCheckMobileScreen';
import Reviews from './Reviews';

import MyProducts from './MyProducts/MyProducts';
import Sales from './Sales/Sales';
import SellerAreaMenu from './SellerAreaMenu/SellerAreaMenu';
import SellerAreaMenuMobile from './SellerAreaMenu/SellerAreaMenuMobile/SellerAreaMenuMobile';
import SellerReputation from './SellerReputation/SellerReputation';
import SellerReputationInfosCard from './SellerReputation/SellerReputationInfosCard/SellerReputationInfosCard';

import { Container, PageContainer } from './styles';

type Props = {
	userId: string;
};

const SellerArea: React.FC<Props> = ({ ...props }) => {
	const { path, url } = useRouteMatch();
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(
			insertMobileHeader({
				...BackPreset,
				leftSideComp: {
					leftSide: 1,
					backArrowPath: `/seller-area/${user.id}/all/0/100000000/undefined`
				},
			})
		);
	});

	const getPageTitle = () => {
		const page = window.location.href;
		if (page.includes('/products')) {
			return 'Produtos cadastrados';
		} else if (page.includes('/sales')) {
			return 'Minhas vendas';
		} else if (page.includes('/reputation')) {
			return 'Reputação';
		} else if (page.includes('/income')) {
			return 'Rendimentos';
		} else {
			return 'Área do vendedor';
		}
	};

	return (
		<PageContainer>
			<Container>
				<h1>
					{useCheckMobileScreen()
						? getPageTitle()
						: 'Área do vendedor'}
				</h1>
				<SellerAreaMenu />
				{!window.location.href.includes('/products') &&
					!window.location.href.includes('/sales') &&
					!window.location.href.includes('/reputation') &&
					!window.location.href.includes('/income') && (
						<SellerAreaMenuMobile />
					)}
				<Route path={`${url}/products`} component={MyProducts} />
				<Route path={`${url}/sales`} component={Sales} />
				<Route path={`${url}/reputation`} component={Reviews} />
				<Route path={`${url}/income`} component={SellerReputation} />
			</Container>
		</PageContainer>
	);
};

export default SellerArea;
