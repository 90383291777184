import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;

	padding-top: 30px;
	padding-bottom: 25px;
	border-bottom: solid 1px #e1e1e1;
	min-height: 470px;
	/* 
	grid-template-columns: 1.5fr 1fr;
	grid-template-rows: 0.5fr 4fr 1fr; */
	column-gap: 45px;
	row-gap: 8px;

	@media (max-width: 768px) {
		flex-direction: column;
		padding-top: 0;
	}

	.category {
		/* 
		grid-row-start: 1;
		grid-row-end: 1;
		grid-column-start: 1;
		grid-column-end: 1; */
		color: #888888;
		font-family: var(--Roboto-font-family);
		font-weight: 400;
		font-size: 0.5rem;
		display: flex;
		align-items: flex-end;
	}
	#image {
		/* 
		grid-row-start: 2;
		grid-row-end: 2;
		grid-column-start: 1;
		grid-column-end: 1; */
		object-fit: cover;
		max-width: 400px;
		min-width: 360px;
		height: 390px;
		cursor: pointer;
	}
	.bottom-images {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		margin-top: 10px;
		gap: 10px;

		@media (max-width: 768px) {
			flex-direction: row;
		}

		img {
			width: 40px;
			height: 40px;
			object-fit: cover;
			border-radius: 8px;
		}
	}
`;

export const PhotosContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	width: 50%; /* 
	grid-row-start: 3;
	grid-row-end: 3;
	grid-column-start: 1;
	grid-column-end: 1; */

	@media (max-width: 1000px) {
		width: 90%;
	}

	figure {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.main-image {
			width: 100%;
			object-fit: cover;
			height: 301px;
			@media (max-width: 580px) {
				height: 230px;
			}
		}
	}
`;

export const InfosContainer = styled.div`
	/* 
	grid-row-start: 2;
	grid-row-end: 2;
	grid-column-start: 2;
	grid-column-end: 2; */

	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-start;
	width: 100%;
	.starsRow {
		align-items: center;
		display: flex;
	}
	.rowReview p {
		font-size: 12px;
		color: #8a99a8;
	}
	@media (max-width: 1000px) {
		width: 90%;
	}
	.priceRow {
		display: flex;
	}

	header {
		svg {
			width: 23px;
			height: 22px;
			fill: #f49d37;
			margin-right: 6px;
			@media (max-width: 768px) {
				width: 16px;
				height: 16px;
			}
		}
	}
	p {
		font-family: var(--Roboto-font-family);
		font-weight: 400;
		color: #888888;
		font-size: 0.6rem;
		/* width: 480px; */
		line-height: 20px;
	}
	span {
		color: #002793;
		font-size: 14px; /* 
		font-size: 0.5rem; */
		font-family: var(--Roboto-font-family);
		font-style: normal;
		margin-bottom: 16px;
	}
	.price {
		display: flex;
		align-items: baseline;
		flex-direction: row;
	}
	.price-container {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		margin: 10px;
		margin-top: 27px;
		margin-left: 0px;
		white-space: nowrap;

		span {
			color: #002793;
			font-size: 14px;
			font-family: var(--Roboto-font-family);
			font-style: normal;
			margin-bottom: 10px;
		}
		p {
			font-family: var(--Roboto-font-family);
			font-weight: normal;
			height: 30px;
			color: #002793;
			font-size: 40px; /* 
			font-size: 1.2rem; */
			@media (max-width: 768px) {
				width: 190px;
			}
		}
		h3 {
			color: #002793;
			font-family: var(--Roboto-font-family);
			font-weight: normal;
			font-size: 40px;
		}
		.small-text {
			font-size: 24px;
		}
	}
	.details-row {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		gap: 16px;
		@media (max-width: 768px) {
			display: none;
		}
		.details-column {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin-top: 16px;
			.details-placeholder {
				color: #5a646e;
				font-family: var(--Roboto-font-family);
				font-weight: 300;
				font-size: 16px;
			}
			.details-info {
				color: #353a40;
				font-family: var(--Roboto-font-family);
				font-weight: normal;
				font-size: 16px;
			}
		}
	}
`;

export const SideImgContainer = styled.div`
	border: 1px solid #2b63fc;
	border-radius: 8px;
	padding: 2px;
	width: 40px;
	height: 40px;
	cursor: pointer;
`;

export const TitleContainer = styled.div`
	display: flex;

	h1 {
		flex: 1;
		color: #353a40;
		font-family: var(--Roboto-font-family);
		font-weight: normal;
		font-size: min(24px, 2.1vw);
		line-height: 36px;
		/* width: 376px; */
		@media (max-width: 768px) {
			font-size: 24px;
			color: #353a40;
			max-width: 100%;
		}
	}
`;
