import React, { useState } from 'react';
import api from '../../../../../api';

import { Container } from './styles';

interface ICancelOrderModal {
  charge_id: string;
}

const CancelOrderModal: React.FC<ICancelOrderModal> = ({ charge_id }: ICancelOrderModal) => {

  const [cancel_reason, setCancelReason] = useState<string>('');

  const cancel = async () => {
    const result = await api.post('/cancel-charge', {
      charge_id,
      cancel_reason,
    });

    if(result.data) {
      window.location.reload();
    }
  }

  return (
    <Container>
      <h4>Cancelar Venda</h4>
      <textarea value={cancel_reason} onChange={(e) => setCancelReason(e.target.value)} placeholder="Descreva o motivo do cancelamento"></textarea>
      <button onClick={() => cancel()}>Concluir cancelamento</button>
    </Container>
  );
}

export default CancelOrderModal;