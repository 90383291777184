import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import WelcomePage from '../Pages/WelcomePage/WelcomePage';
import NotificationPage from '../Pages/NotificationPage/NotificationPage';
import CategorysPage from '../Pages/CategorysPage/CategorysPage';
import SallersPage from '../Pages/SallersPage/SallersPage';
import EditPage from '../Pages/EditPage/EditPage';
import RegisteredProductPage from '../Pages/RegisteredProductPage/RegisteredProductPage';
import AdministratorPage from '../Pages/AdministratorPage/AdministratorPage';
import SearchPage from '../Pages/SearchPage/SearchPage';

const AdministratorRoutes: React.FC = () => {
	return (
		<>
			<Route path="/admin/welcome" component={WelcomePage} />
			<Route path="/admin/sellers" component={SallersPage} />
			<Route path="/admin/edit" component={EditPage} />
			<Route path="/admin/notification" component={NotificationPage} />
			<Route path="/admin/categorys" component={CategorysPage} />
			<Route
				path="/admin/newadministrator"
				component={AdministratorPage}
			/>
			<Route
				path="/admin/search/:search_query/:search_type/:state/:min_price/:max_price"
				component={SearchPage}
			/>
			<Route
				path="/admin/registeredproduct/:search_query/:search_type/:state/:min_price/:max_price"
				component={RegisteredProductPage}
			/>
			<Route
				path="/registeredproduct/:search_query/:search_type/:state/:min_price/:max_price"
				component={RegisteredProductPage}
			/>
			<Route path="/categorys" component={CategorysPage} />
		</>
	);
};
export default AdministratorRoutes;
