import styled from 'styled-components';
interface Cookies {
	display: string;
}
export const ModalCookies = styled.div<Cookies>`
	position: fixed;
	width: 300px;
	height: 150px;
	background-color: #007aff;
	bottom: 0;
	right: 0;
	border-top-left-radius: 10px;
	padding: 60px;
	display:  ${(Prop) => Prop.display};
	z-index:999;
	h1 {
		font-size: 20px;
		color: white;
	}
	h2 {
		font-size: 14px;
		color: white;
		margin-top: 20px;
	}
	button {
		padding: 20px 25px;
		background-color: white;
		border-radius: 4px;
		margin-top: 20px;		
		font-size: 14px;
		font-weight:600;
		border: none;
		color:#2b63fc;
	}
	
	@media (max-width: 768px) {
	width: 80vw;
	height: 22vh;
	padding: 10vw;
	border-top-left-radius: 0px;
	h1, h2{
		text-align: center;
	}
`;
export const CarouselDiv = styled.div`
	width: 100%;
	height: 100%;
	max-width: 1350px;

	img {
		image-rendering: -webkit-optimize-contrast;
	}
	.carousel {
		img {
			height: 100%;
			width: 1182px;
		}
		button {
			margin: 0 5px;
			background: #f5f5f5;
			box-shadow: none;
		}
		button:hover {
			background: #f5f5f5;
			box-shadow: none;
		}
		button:focus {
			background: #f5f5f5;
			box-shadow: none;
			color: #333;
		}
		.rec-arrow {
			border-radius: 0;
		}
		.rec-arrow:disabled {
			visibility: hidden;
		}
		.rec-arrow:hover {
			border-radius: 10%;
			color: #3483fa;
		}
		.rec-pagination {
			margin-top: -40px;
			z-index: 1;
			.rec-dot_active {
				background: #004d93;
				box-shadow: none;
			}
			.rec-dot:hover {
				box-shadow: 0 0 1px 3px #004d93;
			}
		}
	}
`;

export const DefaultRow = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
`;

export const DefaultColumn = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const Container = styled.img`
	object-fit: cover;
	width: 100%;
	height: 360px;
`;

export const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	margin-top: 75px;
	width: var(--Default-page-alignment-vale);
	height: 100%;
	/* margin-bottom: 75px; */
	@media (max-width: 768px) {
		margin-top: 30px;
		width: 90%;
	}

	h2 {
		color: #000000;
		font-family: var(--Roboto-font-family);
		font-weight: 300;
		line-height: 36px;
		font-size: min(24px, 1.8vw);
		margin-bottom: 24px;
		@media (max-width: 768px) {
			font-size: 20px;
			color: #000000;
			max-width: 250px;
		}
	}
`;

export const ProductContainer = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: 20px;
	row-gap: 55px;
	width: 100%;

	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;

	height: 90%;
	padding-bottom: 50px;

	@media (max-width: 768px) {
		display: flex;
		width: 105%;
		flex-wrap: nowrap;
		/* background: purple; */
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		padding-bottom: 38px;
	}
`;

export const AdvertisingContainer = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: 25px;
	width: var(--Default-page-alignment-vale);
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: nowrap;
	height: 100%;
	margin-bottom: 75px;
	@media (max-width: 768px) {
		flex-direction: column;
		row-gap: 10px;
		margin-bottom: 37px;
		width: 90%;
		div {
			height: 200px;
			width: 100%;
		}
	}
`;
interface CardMobile {
	photo: string;
	// imageWidth: string;
}
export const CardMobile = styled.div<AdvertisingPhoto>`
	width: 100%;
	height: 261px;
	/* background: white;; */
	background-image: ${(Prop) => Prop.photo};
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
	display: none;
	@media (max-width: 768px) {
		display: block;
		width: 100%;
	}
`;

interface AdvertisingPhoto {
	photo: string;
	imageWidth: string;
}
export const AdvertisingCard = styled.img`
	width: 50%;
	height: 100%;
	background-color: white;
	background-size: cover;
	background-position: center;
	@media (max-width: 768px) {
		width: 100%
	}
`;

export const ImageContainer = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: 25px;
	width: var(--Default-page-alignment-vale);

	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: nowrap;

	height: 100%;
	margin-bottom: 75px;
	@media (max-width: 768px) {
		margin-bottom: 50px;
		flex-direction: column;
		row-gap: 10px;
		width: 90%;
		overflow: hidden;
		padding-bottom: 38px;
		margin-bottom: 0px;
		div {
			height: 182px;
			width: 100%;
		}
	}
`;
interface RegularPhotoMobile {
	photos: string;
	// imageWidths: string;
}
export const ImageCardMobile = styled.div<RegularPhotoMobile>`
	width: 400px;
	min-height: 150px;
	background: white;
	background-image: ${(Prop) => Prop.photos};
	background-size: cover;
	background-position: center;
	margin-bottom: 10px;
	@media (max-width: 768px) {
		width: 100%;
	}
`;
interface RegularPhoto {
	photoUrl: string;
	imageWidth: string;
}
export const ImageCard = styled.img`
	width: 50%;
	height: 100%;
	background: white;
	background-size: cover;
	background-position: center;
	@media (max-width: 768px) {
		width: 100%
	}
`;

export const CategoryContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	width: var(--Default-page-alignment-vale);
	height: 100%;
	margin-bottom: 75px;
	@media (max-width: 768px) {
		margin-bottom: 37px;
		width: 90%;
	}
	h2 {
		color: #000000;
		font-family: var(--Roboto-font-family);
		font-weight: 300;
		line-height: 36px;
		font-size: min(24px, 1.8vw);
		margin-bottom: 24px;
		@media (max-width: 768px) {
			color: #000000;
			font-size: 20px;
			max-width: 263px;
			line-height: 24px;
		}
	}
`;

export const CategoryRowContainer = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: 24px;
	height: 100%;
	width: 100%;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: nowrap;
	padding-bottom: 10px;
	@media (max-width: 768px) {
		padding-bottom: 0;
		display: grid;
		grid-template-columns: repeat(auto-fill, 172px);
		column-gap: 0px;
	}
`;

export const CategoryCard = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	width: calc(218px - 20px);
	height: calc(218px - 20px);
	//margin-right: 24px;
	padding: 31px 39px;
	border: 1px solid #5a646e;
	border-radius: 4px;
	@media (max-width: 768px) {
		flex: 1;
		border: 0.5px solid #5a646e;
		/* padding: 15px 23px; */
		height: 171px;
		width: 171px;
		margin-bottom: 10px;
		padding: 0;
		display: flex;
		justify-content: space-around;
		img {
			width: 126px;
			height: 126px;
		}
	}

	h3 {
		color: #000000;
		/* white-space: nowrap; */
		text-align: center;
		font-family: var(--Roboto-font-family);
		font-weight: 300;
		line-height: 36px;
		font-size: min(24px, 1.8vw);
		@media (max-width: 768px) {
			font-size: 14px;
			max-width: 110px;
			line-height: 19px;
		}
	}
	img {
		transition: all 0.3s;
		width: 132px;
		height: 132px;
		@media (max-width: 768px) {
			width: 126px;
			height: 126px;
		}
	}

	&:hover {
		border: 1px solid #2b63fc;
		img {
			transform: scale(1.1);
		}
		h3 {
			color: #2b63fc;
		}
	}
`;

export const AssociatedContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;

	width: var(--Default-page-alignment-vale);
	height: 100%;
	margin-bottom: 75px;
	@media (max-width: 768px) {
		width: 90%;
	}
	h2 {
		color: #000000;
		font-family: var(--Roboto-font-family);
		font-weight: 300;
		line-height: 36px;
		font-size: min(24px, 1.8vw);
		margin-bottom: 24px;
		@media (max-width: 768px) {
			font-size: 20px;
		}
	}
`;

export const AssociatedRowContainer = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: 24px;
	height: 100%;
	width: 100%;

	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: nowrap;

	padding-bottom: 50px;

	@media (max-width: 768px) {
		flex-wrap: nowrap;
		row-gap: 23px;
		justify-content: space-between;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
`;

export const AssociatedCard = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	background: #ffffff;
	width: 278px;
	height: 392px;
	//margin-right: 24px;
	cursor: pointer;
	border-radius: 4px;
	box-shadow: 0px 3px 16px #0000001c;
	transition: box-shadow 0.3s;
	@media (max-width: 768px) {
		min-width: 277px;
	}
	&:hover {
		box-shadow: 0px 6px 26px #0000003c;
		.associated-name-products-container {
			h4 {
				color: #2b63fc;
			}
		}
	}
	.associated-img-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		position: relative;
		.image-cover {
			width: 100%;
			height: 110px;
			overflow: hidden;
		}
		.associated-img-cover {
			display: flex;
			flex: 1;
			width: 100%;
			min-height: 110px;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			object-fit: cover;
		}
		.associated-img-profile {
			display: flex;
			flex: 1;
			position: absolute;
			width: 100px;
			height: 100px;
			top: 50px;
			border-radius: 10px;
			background: #f5f5f5;
		}
	}

	.associated-name-products-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex: 1;
		margin-top: 55px;
		gap: 24px;

		h3 {
			color: #353a40;
			/* white-space: nowrap; */
			text-align: center;
			font-family: var(--Roboto-font-family);
			font-weight: medium;
			line-height: 36px;
			padding: 0 15px;
			font-size: 20px;
		}

		h4 {
			color: #8a99a8;
			/* white-space: nowrap; */
			text-align: center;
			font-family: var(--Roboto-font-family);
			font-weight: bold;
			line-height: 28px;
			font-size: 18px;
		}

		.associated-products {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			padding-right: 18px;
			padding-left: 18px;
			gap: 8px;

			img {
				width: 75px;
				height: 75px;
				border-radius: 4px;
			}
		}
	}
`;
