import styled from 'styled-components';

export const CardContainer = styled.div`
	position: absolute;
	z-index: 1;
	top: 26px;
	right: -100px;
	overflow: hidden;
`;

export const NoFavorites = styled.div`
	height: 67vh;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #fff;

	@media (max-width: 768px) {
		border-radius: 8px;
		width: 100%;
	}

	.emoji-and-text {
		display: flex;
		flex-direction: column;
		align-items: center;

		.emoji {
			width: 96px;
			height: 96px;
		}

		h2 {
			color: #353a40;
			font-size: 20px;
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			margin-top: 22px;
			text-align: center;
			@media (max-width: 468px) {
				max-width: 221px;
				text-align: center;
			}
		}
	}
	.start-to-buy-button {
		width: 321px;
		height: 40px;
		background: #2b63fc;
		border: none;
		border-radius: 8px;

		@media (max-width: 468px) {
			width: 221px;
		}
		color: white;
		font-family: var(--Roboto-font-family);
		font-weight: 500;
		font-size: 16px;
		margin-top: 26px;
	}
`;

export const Container = styled.div`
	width: 342px;
	height: 380px;
	background: white;
	box-shadow: 0px 2px 6px #00000033;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
`;
export const SeeMoreButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: flex-end;
	height: 100%;
	padding-bottom: 15px;
	button {
		height: 40px;
		color: #2b63fc;
		font-family: var(--Roboto-font-family);
		font-size: min(13px, 1.1vw);
		font-weight: 600;
		width: 70%;
		text-align: center;
		border: none;
		border-radius: 8px;
		background-color: #f2f2f2;
	}
`;

export const NotificationsContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 300px;
	overflow: auto;
`;
