import styled from 'styled-components';

export const Container = styled.div`
	padding: 15px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 15px 20px 15px 20px;
	border: 1px solid #e6e6e6;
	cursor: pointer;
	:hover {
		background: #eaeef3;
	}

	> p {
		color: #959595;
		font-family: var(--Roboto-font-family);
		font-size: 14px;
		font-weight: bold;
		display: inline;
	}
`;

export const StarsContainer = styled.div`
	> svg {
		fill: #f49d37;
	}
`

export const Header = styled.div`

	width: 100%;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	> div.titles {
		display: flex;
		flex-direction: column;

		h1 {
			color: #5a646e;
			font-family: var(--Roboto-font-family);
			font-size: 16px;
			font-weight: bold;
			display: inline;
		}

		h2 {
			color: #5a646e;
			font-family: var(--Roboto-font-family);
			font-size: 14px;
			font-weight: bold;
			display: inline;
		}
	}

	> ${StarsContainer} {

	}

`
