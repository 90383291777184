import styled from 'styled-components';

export const CardContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	z-index: 1;
	min-height: 700px;
`;

export const AlignmentContainer = styled.div`
	width: var(--Default-page-alignment-vale);
	height: max-content;
	margin: 30px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@media (max-width: 768px) {
		width: 90%;
	}

	.page-form {
		@media (max-width: 768px) {
			width: 100%;
		}
	}
`;

export const CardInputsContainer = styled.div`
	width: 100%;
	width: 900px;
	@media (max-width: 768px) {
		width: auto;
		width: 100%;
	}
`;
export const CardInputsContainerTitle = styled.div`
	border-bottom: solid 1px #e1e1e1;
	padding-bottom: 9px;
	padding: 14px;
	margin-bottom: 30px;

	h2 {
		color: #3b3d4a;
		font-size: 16px;
		/* font-size: 1rem; */
		font-family: 'Roboto';
	}
`;
export const GroupContainer = styled.div`
	display: flex;
	flex-direction: row;
	color: #8a99a8 !important;

	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.first-line-inputs-container {
		display: flex;
		flex-direction: row;
		padding: 20px;
		padding-bottom: 5px;
	}
	.second-line-inputs-container {
		display: flex;
		flex-direction: row;
		margin-top: 8px;
		padding: 20px;
		padding-bottom: 5px;
	}
	/* 
	.final-input-group {
		max-width: 505px;
		padding: 20px;
		padding-bottom: 5px;
	} */
`;

export const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: 15px;
	margin-bottom: 60px;

	.negative-button {
		width: 144px;
		height: 34px;
		background: #707070 0% 0% no-repeat padding-box;
		color: white;
		border: none;
		margin-right: 10px;
		font-family: 'Roboto';
		font-weight: bold;
	}
	.positive-button {
		width: 218px;
		height: 40px;
		background: #1c27cc 0% 0% no-repeat padding-box;
		opacity: 1;
		color: #ffffff;
		border: none;
		font-family: 'Roboto';
		font-size: 16px;
		font-weight: bold;
		border-radius: 8px;
	}
`;

export const GroupColumn = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 10px;

	@media (max-width: 768px) {
		max-width: 100%;
	}

	&.has-margin {
		margin-right: 36px;
		@media (max-width: 768px) {
			margin-right: 0;
		}
	}
`;

export const GroupRow = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
`;
export const MaskInput = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	.form-input {
		height: calc(40px - 11px - 11px);
		border: 1px solid #8a99a8;
		border-radius: 8px;
		padding: 11px 5px 11px 10px;
		background: none;
		margin-bottom: 24px;

		font-family: 'Roboto';
		font-weight: normal;
		font-size: 14px;
		color: #3b3d4a;

		::placeholder {
			font-style: italic;
			color: #8a99a8;
		}
	}
`;
export const InputAndErrorMessage = styled.div`
	margin-top: -38px;
	.field-validation-error {
		font-family: var(--Roboto-font-family);
		font-size: 11px;
		margin-top: 4px;
		color: red;
	}
`;

export const SelectionContainer = styled.div`
	background-color: #fff;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	row-gap: 36px;
	width: 100%;
	padding-top: 116px;
	padding-bottom: 27px;
	/* 	
	position: absolute;
	top: 0;
	left: 0; */
`;

export const DefaultRow = styled.div`
	display: flex;
	flex-direction: row;
`;

export const UserTypeRow = styled(DefaultRow)`
	column-gap: 12px;

	@media (max-width: 768px) {
		row-gap: 12px;
		flex-direction: column;
	}
`;

export const SelectType = styled(DefaultRow)`
	font-family: var(--Roboto-font-family);
	font-size: 16px;
	font-weight: medium;
	letter-spacing: 0px;
	color: #1c27cc;
	border: 1px solid #1c27cc;
	border-radius: 8px;
	padding: 13px 0;
	width: 211px;
	max-width: 211px;
	cursor: pointer;
	justify-content: center;
	transition: all 0.3s;

	svg {
		width: 21px;
		height: 21px;
		margin-right: 9px;
	}

	&.innactive {
		border: 1px solid #f2f2f2;
		background-color: #f2f2f2;
		color: #898989;

		svg {
			fill: #898989;
		}

		:hover {
			color: #1c27cc;
			svg {
				fill: #1c27cc;
			}
		}
	}
`;

export const GroupTitle = styled.h2`
	font-family: var(--Roboto-font-family);
	font-weight: medium;
	font-size: 16px;
	color: #333333;
	margin-bottom: 16px;
`;
