import styled from 'styled-components';

export const Container = styled.div`
	height: max-content;
	min-height: 100vh;
	background: #f5f5f5;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
