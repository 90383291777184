import React, { useEffect, useState } from 'react';
import { ReviewContainer } from './styles';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UserAccount from '../../../../../interfaces/UserAccount';
import ProductsCart from '../../../../../interfaces/ProductsCart';
import Spinner from '../../../../Loader/Spinner';
import { AppState } from '../../../../../store';
import {
	calculateProductCartValue,
	calculateShippingCartValue,
} from '../../../../../utils/CalculateProductCartValue';
import api from '../../../../../api';
import {
	CheckoutRequest,
	CheckoutResponse,
} from '../../../../../interfaces/ApiResponses/Checkout';
import { AxiosResponse } from 'axios';
import { Base64 } from 'js-base64';

type Props = {
	backButtonPath: string;
	nextButtonPath: string;
	finalPage?: boolean;
};
interface PageParams {
	from_page: string;
}

const CartReviewCardMobile: React.FC<Props> = ({
	backButtonPath,
	nextButtonPath,
	finalPage = false,
}) => {
	const history = useHistory();
	const pageParams = useParams<PageParams>();

	const [productsValue, setProductsValue] = useState<string>('');

	const productsCart: ProductsCart[] = useSelector(
		(state: AppState) => state.productsCart.currentCart
	);
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);

	const [width, setWidth] = useState<number>(window.innerWidth);
	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	let isMobile: boolean = width <= 768;

	useEffect(() => {
		setProductsValue(
			(
				calculateProductCartValue(productsCart, user) +
				calculateShippingCartValue(productsCart)
			).toLocaleString('pt-br', {
				style: 'currency',
				currency: 'BRL',
			})
		);
	}, [productsCart]);

	const [loading, setLoading] = useState(false);

	const purchase = () => {
		setLoading(true);
		const data: CheckoutRequest = {
			products: productsCart.map((item) => {
				return {
					product_id: item.parameters.product.id,
					variation_id: item.parameters.product
						?.product_variations![0]?.id,
					variation_size_id: item.parameters.product
						.product_variations![0]?.variation_sizes[0]?.id,
					quantity: item.parameters.quantity,
					delivery_address_id: item.parameters.address?.id,
					shippingMethod: item.parameters.shippingMethod!,
				};
			}),
			installments: productsCart[0].installments!,
			payment_method: user.payment_method?.english_name!,
			credit_card_id: user.payment_method?.card_id,
		};
		api.post<CheckoutRequest, AxiosResponse<CheckoutResponse>>(
			'/checkout',
			data
		)
			.then((res) => {
				if (user.payment_method?.english_name === 'BOLETO') {
					history.push(
						`/payment-success/${Base64.encode(
							res.data.data.charge.link!
						)}/boleto`
					);
				} else if (user.payment_method?.english_name === 'BOLETO_PIX') {
					history.push(
						`/payment-success/${Base64.encode(
							`data:image/jpeg;base64,${res.data.data.charge.pix.imageInBase64}`
						)}/boleto_pix`
					);
				} else {
					history.push(
						`/payment-success/credit_card/${productsCart[0].parameters.product.id}`
					);
				}
			})
			.catch((err) => {
				setLoading(false);
				alert('Falha ao efetuar transação');
			});
	};

	/* ALSO USED IN src\components\BuyProcess\Review\Review.tsx */
	return (
		<>
			<ReviewContainer>
				{/* {image && <img src={image} alt="" />} */}
				<div className="review">
					<div className="products">
						<span className="static-text">Produtos:</span>
						{productsCart.map((item) => (
							<span className="products-name">
								{`${item.parameters.quantity}x ${item.parameters.product.title}`}
							</span>
						))}
					</div>
					<div className="shipping-cost-container">
						<div className="shipping-cost-and-time">
							{/* <img src={deliveryTruck} alt="" /> */}
							<div className="cost">
								{user.Address && user.Address?.length > 0 && (
									<>
										{productsCart.map((item) => (
											<h4
												className="title"
												style={{ marginTop: '8px' }}
											>
												Frete{' '}
												{`${item.parameters.quantity}x ${item.parameters.product.title}: `}
												<span className="green-span">
													<span className="green-span">
														{item.parameters
															.shippingMethod ===
															'delivery'
															? `${item.parameters.shippingValue}`
															: 'Retirar com o vendedor'}
													</span>
												</span>
												<br />
												{item.parameters.shippingMethod === 'withdraw' && <span>Retirar com o vendedor</span>}
												{item.parameters.address &&
													<span>
														Enviar para: {item.parameters.address.street}, {item.parameters.address.number} {item.parameters.address.city} - {item.parameters.address.state}
													</span>
												}
											</h4>
										))}
									</>
								)}
							</div>
						</div>
					</div>
					<div className="total-container">
						<span className="total-value-title">Total:</span>
						<div className="value">
							<h2>
								{' '}
								{String(productsValue).substring(
									0,
									productsValue.toString().length - 2
								)}{' '}
							</h2>
							<h2 className="small-text">
								{String(productsValue).substring(
									productsValue.toString().length - 2,
									productsValue.toString().length
								)}
							</h2>
						</div>
					</div>
				</div>
				<div className="review-actions">
					{!finalPage && (
						<>
							<button
								className="back-button"
								onClick={() => history.push(backButtonPath)}
							>
								Voltar
							</button>
							<button
								className="next-button"
								onClick={() => {
									if (
										pageParams.from_page === 'confirmation'
									) {
										history.push(
											`/cart/order-confirmation/${'card'}`
										);
									} else {
										history.push(nextButtonPath);
									}
								}}
							>
								Continuar
							</button>
						</>
					)}

					{finalPage && (
						<>
							<button
								className="next-button"
								onClick={() => purchase()}
								disabled={loading}
								style={{ opacity: loading ? '0.5' : '1' }}
							>
								{!loading ? (
									'Continuar'
								) : (
										<span
											style={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<Spinner />
										</span>
									)}
							</button>
						</>
					)}
				</div>
			</ReviewContainer>
		</>
	);
};

export default CartReviewCardMobile;
