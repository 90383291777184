import styled from 'styled-components';

export const Container = styled.div`
	background: white;
	padding: 30px 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	overflow-y: auto;

	@media (max-width: 760px) {
		grid-row-start: 2;
		grid-column-end: 3;
		grid-column-start: 1;
	}

	h2 {
		align-self: start;
		font-family: var(--Roboto-font-family);
		/* margin-bottom: 13px; */
		color: #353a40;
		font-weight: normal;
		font-size: 20px;
		font-weight: 300;
	}
`;

export const MakeReviewsCard = styled.form`
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	p {
		font-size: 14px;
	}
	textarea {
		flex: 3;
		margin-right: 15px;
		height: 70px;
		resize: none;
		border: 1px solid #d5d5d5;
		padding: 10px;
	}
	button {
		flex: 1;
		max-width: 115px;
		height: 40px;

		background: var(--Default-green-color);
		border: none;
		color: white;

		font-family: var(--Roboto-font-family);
		font-weight: 400;
		font-size: 0.7rem;

		&:hover {
			cursor: pointer;
		}
	}
`;

export const ReviewsContainer = styled.div`
	display: flex;

	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
	/* margin-left:100px; */
	/* padding-right:30px; */
	@media (max-width: 768px) {
		margin-top: 0px;
	}

	>h1 {
		margin: 8px 0;
		font-size: 16px;
		color: #353a40;
		font-weight: 400;
		font-style: italic;
	}

	p {
		font-family: var(--Roboto-font-family);
		font-size: min(14px, 1.5vw);
		font-weight: normal;
		margin-top: 38px;
		color: #2b63fc;
		width: max-content;
		cursor: pointer;
		@media (max-width: 768px) {
			font-size: 14px;
		}
	}

	p {
		@media (max-width: 768px) {
			margin-top: 0px;
		}
	}

	.review-card {
		display: flex;
		flex-direction: row;
		img {
			width: 52px;
			height: 52px;
			border-radius: 50%;
			margin-right: 10px;
		}

		.review-info {
			border-radius: 8px;
			.rowReviewText p {
				@media (max-width: 768px) {
					font-size: 14px;
					margin-bottom: 60px;
				}
			}
			.review-header {
				display: flex;
				align-items: center;
				.delete {
					margin-left: 58%;
					width: 125px;
					height: 30px;
					background: black;
					border-radius: 8px;
					color: white;
					text-align: center;
					padding-top: 10px;
					cursor: pointer;
				}
				p {
					font-family: var(--Roboto-font-family);
					font-size: 16px;
					font-weight: normal;
					color: #353a40;
					margin-bottom: 10px;
					margin-top: 15px;
					margin-right: 5px;
					width: max-content;
					cursor: default;
				}

				.star {
					width: 22px;
					height: 22px;
					.a {
						fill: none;
					}
					.b {
						fill: #f49d37;
					}
				}
			}
			p {
				font-family: var(--Roboto-font-family);
				font-size: min(16px, 1.6vw);
				color: #353a40;
				font-weight: normal;
				line-height: 24px;
				margin-top: 8px;
				margin-bottom: 10px;
				margin-right: 0;
				text-align: justify;
				width: 100%;
				cursor: default;
			}
		}
	}
	.see-more-button {
		width: 67%;
		height: 38px;
		background: #f4f4f4;
		border-radius: 8px;
		border: none;
		margin-top: 25px;

		color: #6699cc;
		font-family: var(--Roboto-font-family);
		font-size: min(16px, 1.5vw);
		font-weight: 500;
	}
`;
