import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';

type Props = {
	itemList: string[];
	areaTitle: string;
	hasBorder: boolean;
	editPath: string;
};

const OrderItem: React.FC<Props> = ({ ...props }) => {
	const history = useHistory();
	return (
		<Container
			style={{
				borderTop: `${!props.hasBorder ? 'none' : 'solid 1px #cecece'}`,
				paddingTop: `${!props.hasBorder ? '0' : '30px'}`,
			}}
		>
			<div className="title-container">
				<h2>{props.areaTitle}</h2>
				{props.editPath != "" && (
					<span 
						className="edit-button"
						onClick={() => history.push(props.editPath)}
						>
						Editar
					</span>
				)}
			</div>
			<div className="items-container">
				<div className="items">
					{props.itemList.map((item: string) => (
						<>
							<span>
								{item}
							</span>
							<br/>
						</>
					))}
				</div>
			</div>
		</Container>
	);
};

export default OrderItem;
