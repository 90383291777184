import UserAccount from "../interfaces/UserAccount";

/**
 * Gets the correct product price depending on the informed user
 * @param user The user
 * @param product The product to get the price from
 * @returns The correct price as number
 */
export const getProductPrice = (
    product: any & { 
        price: number, 
        collaborator_price: number 
    }, 
    user?: any & {
        user_types: {
            name: string
        }
    }
): number => user && user.user_types?.name === "Seller" ?
            (product.collaborator_price || product.price) : product.price