import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	/* justify-content:space-between; */

	@media (max-width: 760px) {
		flex-direction: column;
	}

	margin-bottom: 15px;
	border-radius: 8px;
	border: solid 1px #e1e1e1;
	padding: 30px 18px 30px 24px;
`;

export const ImageAndInfosContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	@media (max-width: 768px) {
		flex-direction: column;
	}

	.card {
		cursor: pointer;
		display: flex;
		flex-direction: row;
		border-right: solid 1px #e1e1e1;
		padding-right: 30px;
		@media (max-width: 768px) {
			border-right: none;
		}
		img {
			width: 90px;
			height: 90px;
			object-fit: cover;
			@media (max-width: 768px) {
				width: 75px;
				height: 64px;
			}
		}
		/* justify-content:space-between; */
		.infos-container {
			width: inherit;
			@media (max-width: 768px) {
				width: 90%;
			}
			h3 {
				color: #b1b1b1;
				font-size: 13px;
				font-family: var(--Roboto-font-family);
				margin-left: 15px;
				@media (max-width: 768px) {
					font-size: 26px;
				}
			}
			h2 {
				font-size: 20px;
				font-family: var(--Roboto-font-family);
				font-weight: 400;
				color: #353a40;
				margin-left: 15px;
				margin-top: 3px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 391px;
				@media (max-width: 768px) {
					width: auto;
				}
			}
			.bottom-infos {
				font-family: var(--Roboto-font-family);
				display: flex;
				flex-direction: row;
				margin-top: 30px;
				align-items: center;
				justify-content: space-between;
				@media (max-width: 768px) {
					flex-direction: column;
					margin: 0px;
					align-items: baseline;
				}
				.rowMobile {
					flex-direction: row;
					@media (max-width: 768px) {
						flex-direction: column;
					}
				}
				.rowMobile span {
					@media (max-width: 768px) {
						font-size: 12px;
					}
				}

				.rowMobile span strong {
					@media (max-width: 768px) {
						font-size: 15px;
					}
				}
				span {
					font-size: 16px;
					font-family: var(--Roboto-font-family);
					margin-top: 3px;
					margin-left: 23px;
					align-items: center;
					color: #353a40;
					@media (max-width: 768px) {
						margin-left: 13px;
					}
				}
				strong {
					font-weight: 500;
					font-size: 16px;
					margin-right: 5px;
					align-items: center;
				}
			}
		}
	}
	.buttons {
		display: flex;
		flex-direction: column;
		justify-content: center;
		@media (max-width: 768px) {
			margin-top: 10px;
		}

		button {
			margin-left: 15px;
			width: 260px;
			height: 34px;
			font-family: var(--Roboto-font-family);
			background: #ffffff 0% 0% no-repeat padding-box;
			border-radius: 8px;
			@media (max-width: 768px) {
				margin-left: calc(68px + 4%);
				width: auto;
			}
		}
		#update-infos {
			color: #2b63fc;
			border: 1px solid #2b63fc;
			transition: 0.3s;
		}

		#update-infos:hover {
			color: #fff;
			background: #2b63fc;
		}
		#disable {
			margin-top: 10px;
			color: #8a99a8;
			border: 1px solid #8a99a8;
			transition: 0.3s;
		}

		#disable:hover {
			color: #fff;
			background: #8a99a8;
		}

		#enable {
			margin-top: 10px;
			color: #00b06a;
			border: 1px solid #00b06a;
			transition: 0.3s;
		}

		#enable:hover {
			color: #fff;
			background: #00b06a;
		}
	}
`;

export const PriceContainer = styled.div`
	display: flex;
	align-items: center;
	margin-right: 30px;

	h5 {
		color: #20256c;
		font-family: var(--Roboto-font-family);
		font-size: 26px !important;
		font-weight: normal !important;
		margin-left: 15px;
	}

	.small-text {
		font-size: 20px !important;
		margin-left: 0;
	}
`;

export const PromotionLabel = styled.label`
	font-family: var(--Roboto-font-family);
	position: absolute;
	left: -6px;
	top: 15px;
	background-color: #0fd594;
	width: 60px;
	font-size: 15px;
	font-weight: medium;
	color: #ffffff;
	padding: 6px 0;
	border-radius: 4px;
	text-align: center;
	max-height: 20px;
`;
