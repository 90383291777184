import styled from 'styled-components';

export const PageContainer = styled.main`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	h1 {
		color: #353a40;
		width: var(--Default-page-alignment-vale);
		font-family: var(--Roboto-font-family);
		font-weight: normal;
		font-size: 26px;
		margin-top: 47px;
		margin-bottom: 34px;
	}
`;

export const NoFavorites = styled.div`
	height: 67vh;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #fff;

	@media (max-width: 768px) {
		border-radius: 8px;
		width: 100%;
	}

	.emoji-and-text {
		display: flex;
		flex-direction: column;
		align-items: center;

		.emoji {
			width: 96px;
			height: 96px;
		}

		h2 {
			color: #353a40;
			font-size: 20px;
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			margin-top: 22px;
			text-align: center;
			@media (max-width: 468px) {
				max-width: 221px;
				text-align: center;
			}
		}
	}
	.start-to-buy-button {
		width: 321px;
		height: 40px;
		background: #2b63fc;
		border: none;
		border-radius: 8px;

		@media (max-width: 468px) {
			width: 221px;
		}
		color: white;
		font-family: var(--Roboto-font-family);
		font-weight: 500;
		font-size: 16px;
		margin-top: 26px;
	}
`;

export const Container = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 8px;
	width: var(--Default-page-alignment-vale);
	height: 100%;
	margin-bottom: 100px;
	padding-bottom: 40px;

	h2 {
		color: #3b3d4a;
		font-size: 1.2rem;
		font-family: var(--Roboto-font-family);
		font-weight: 400;
		padding: 10px;
	}
`;
