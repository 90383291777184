import styled from 'styled-components';

export const Container = styled.div`
	max-width: 1184px;
	max-height: 245px;
	padding: 19px 32px;
	font-family: var(--Roboto-font-family);

	display: flex;
	flex-direction: row;

	border-bottom: 1px solid #c5ced7;
`;
export const ItemsRow = styled.div`
	display: flex;
	flex-direction: row;
	padding-bottom: 33px;
	border-bottom: 1px solid #c5ced7;
`;

export const StarsDiv = styled.div`
	> svg {
		fill: #ffc107;
	}
`

export const DefaultRow = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	align-items: center;

	> p {
		height: 100%;
	}
`;

export const CircleSection = styled.div`
	margin-right: 48px;
	padding-top: 8px;

	.circle {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: #2b63fc;
	}
`;

export const InfoSection = styled.div`
	display: flex;
	flex-direction: column;
	flex: 0.8;

	> h1, h2, p {
		width: max-content;
	}

	> h1 {
		text-align: left;
		font-size: 24px !important;
		margin: 0 0 8px 0;
		font-weight: normal;
		color: #353a40;
		padding: 0;

		display: flex;
		flex-direction: row;
		align-items: center;
	}
	> h2 {
		text-align: left;
		font-size: 20px !important;
		font-weight: normal;
		color: #353a40;
		padding: 0;
	}
	> p {
		text-align: left;
		font-size: 12px;
		font-weight: normal;
		color: #5a646e;
		line-height: 18px;
	}
`;

export const UserSection = styled.div`
	display: flex;
	flex-direction: column;
	flex: 0.2;
	row-gap: 3px;

	border-left: 1px solid #c5ced7;
	border-right: 1px solid #c5ced7;
	padding: 31px;

	h3 {
		text-align: left;
		font-size: 16px;
		font-weight: medium;
		color: #2d327c;
	}
	span {
		text-align: left;
		font-size: 16px;
		font-weight: 300;
		letter-spacing: 0px;
		color: #5a646e;
	}
`;

export const ButtonSection = styled.div`
	display: flex;
	flex-direction: column;
	flex: 0.2;
	row-gap: 14px;
	padding: 14px 0 12px 16px;

	button {
		height: 34px;
		border-radius: 8px;

		text-align: center;
		font: 13px;
		font-weight: medium;
	}
	.action-button {
		background: #2b63fc;
		border: 1px solid #2b63fc;
		color: #ffffff;
		width: 100%;
	}
	.read-button {
		background: #ffffff;
		border: 1px solid #999999;
		color: #707070;
	}
`;
