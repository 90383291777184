import React, { useEffect, useState } from 'react';
import { CartHover, Container, DefaultRow } from './styles';
import imagem from '../../../assets/imagem.jpg';
import config from '../../../config';
import { useHistory } from 'react-router-dom';
import { IProduct } from '../../../Pages/ProductPage/ProductPage';
import { getFristImageSource } from '../../../utils/GetProductImage';
import { RiShoppingCart2Line } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import {
	addSingleProductCartAction,
	addProductCartAction,
} from '../../../actions/ProductsCartActions';
import UserAccount, { UserTypeEnum } from '../../../interfaces/UserAccount';
import { AppState } from '../../../store';
import { searchInCart } from '../../../utils/searchInCart';
import ProductsCart from '../../../interfaces/ProductsCart';
import { CgWindows } from 'react-icons/cg';
import { getProductPrice } from '../../../utils/GetProductPrice';
// import { Container } from './styles';
type Props = {
	product: IProduct;
	maxWidth?: string;
};
const ProductCardSmall: React.FC<Props> = ({ product, maxWidth = '190px' }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [goingToCart, setGoingToCart] = useState<boolean>(false);

	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const productsCart: ProductsCart[] = useSelector(
		(state: AppState) => state.productsCart.currentCart
	);

	const handleAddToCart = async () => {
		setGoingToCart(true);
		if (
			parseInt(product.stock) > 0 &&
			(!user || (user && user.id !== product.user_account_id))
		) {
			let cartProduct = product;

			if (cartProduct.product_variations!.length > 0) {
				//send product with only the
				//selected color and size
				let colorSize = cartProduct.product_variations![0];
				if (
					cartProduct.product_variations![0].variation_sizes.length >
					1
				) {
					colorSize.variation_sizes = [
						cartProduct.product_variations![0].variation_sizes[0],
					];
				} else {
					colorSize.variation_sizes = [];
				}
				cartProduct.product_variations = [colorSize];
			}
			const card_product_id = await searchInCart(
				productsCart,
				product.id
			);

			if (user) {
				dispatch(
					addSingleProductCartAction({
						parameters: {
							product: cartProduct,
							quantity: 1,
							shippingMethod: 'withdraw',
							address: user.Address![0]!,
						},
						cart_product_id: card_product_id + 1,
						formOfPayment: 'credit_card',
						installments: 1,
					})
				);
				/* history.push('/cart'); */
			} else if (!user) {
				dispatch(
					addProductCartAction({
						parameters: {
							product: cartProduct,
							quantity: 1,
							shippingMethod: 'withdraw',
							/* address: user.Address![0]!, */
						},
						cart_product_id: card_product_id + 1,
						formOfPayment: 'credit_card',
						installments: 1,
					})
				);
			}
			window.scroll({
				behavior: 'smooth',
				left: 0,
				top: 0,
			});
		}
	};

	return (
		<Container
			style={{ maxWidth: maxWidth }}
			onClick={() => {
				if (!goingToCart) {
					history.push(`/product/${product.id}/questions`);
					window.scroll(0, 0);
				} else {
					setGoingToCart(false);
				}
			}}
		>
			{user && user.user_types.name === UserTypeEnum.customer && !product.showcase && (
				<CartHover
					className="cart-hover"
					onClickCapture={() => handleAddToCart()}
				>
					<RiShoppingCart2Line size={25} fill="#fff" />
				</CartHover>
			)}
			<figure>
				<img
					src={getFristImageSource(product)}
					alt="imagem do produto"
				/>
			</figure>
			<div className="product-infos">
				<DefaultRow>
					{product.showcase ? (
						<h5>
							{product.title.substring(0, 41) +
								`${product.title.length > 41 ? '...' : ''}`}{' '}
						</h5>
					) : (
							<>
								<h5>
									{' '}
									{'R$ ' +
										String(getProductPrice(product, user))
											.replace('.', ',')
											.substring(
												0,
												product.price.toString().length - 2
											)}{' '}
								</h5>
								<h5 className="small-text">
									{String(product.price)
										.replace('.', ',')
										.substring(
											product.price.toString().length - 2,
											product.price.toString().length
										)}
								</h5>
							</>
						)}

				</DefaultRow>
				{parseInt(product.max_installments) > 0 && !product.showcase && (
					<h3 style={{ marginTop: '0' }}>
						{' '}
						{product.max_installments +
							'x R$ ' +
							Math.round(
								product.price / parseInt(product.max_installments)
							)
								.toFixed(2)
								.replace('.', ',') +
							' sem juros'}{' '}
					</h3>
				)}
				{product.showcase ? (
					<h4>
						{product.description.substring(0, 41) +
							`${product.description.length > 41 ? '...' : ''}`}{' '}
					</h4>
				) : (
						<h4>
							{product.title.substring(0, 41) +
								`${product.title.length > 41 ? '...' : ''}`}{' '}
						</h4>
					)}
				<h3>
					{product.user_accounts.social_reason?.substring(0, 30) +
						`${product.title.length > 30 ? '...' : ''}`}{' '}
				</h3>
				{/* {<div
					className={`buy-button ${isVisible ? 'active' : ''}`}
				>
					<button>Comprar</button>
				</div>} */}
			</div>
		</Container>
	);
};

export default ProductCardSmall;
