import styled from 'styled-components';

export const PageContainer = styled.div`
	width: 100%;
	height: max-content;
	min-height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	background: #f5f5f5;
`;

export const Container = styled.div`
	width: var(--Default-page-alignment-vale);
	height: 100%;
	margin-bottom: 84px;
	@media (max-width: 768px) {
		margin-bottom: 0;
		width: 100%;
	}

	header {
		margin-top: 40px;
		margin-bottom: 40px;
		h1 {
			font-family: var(--Roboto-font-family);
			font-weight: normal;
			font-size: 26px;

			color: #3b3d4a;
			@media (max-width: 768px) {
				margin-left: 15px;
			}
		}
	}
`;
