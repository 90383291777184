import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { insertMobileHeader } from '../../actions/MobileHeaderActions';
import Header from '../../components/Default/header';
import Favorite from '../../components/Favorite/Favorite';
import { SearchPreset } from '../../interfaces/MobileHeader';
import { Container } from './styles';

const FavoriteItemsPage: React.FC = () => {
	const dispatch = useDispatch();
	useEffect(() => {
					dispatch(insertMobileHeader(SearchPreset))
	});
	return (
		<Container>
			<Header />
			<Favorite />
		</Container>
	);;
}

export default FavoriteItemsPage;
