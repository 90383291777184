import React, { useEffect, useState } from 'react';
import PaymentForm from './PaymentForm/PaymentForm';
import PaymentPlan from './PaymentPlan/PaymentPlan';
import Quantity from './Quantity/Quantity';
import ShippingAddress from './ShippingAddress/ShippingAddress';
import ShippingMethod from './ShippingMethod/ShippingMethod';
import deliveryTruck from '../../../assets/icons/deliveryTruck.svg';
import { useHistory } from 'react-router-dom';
import Spinner from '../../Loader/Spinner';
import { useSelector } from 'react-redux';
import ProductsCart from '../../../interfaces/ProductsCart';
import { AppState } from '../../../store';
import CartReviewCard from '../../Cart/ShippingMethodCart/CartReviewCard/CartReviewCard';
import OrderItem from '../../Cart/OrderConfirmation/OrderItem/OrderItem';
import { Container, PageContainer, AlignmentContainer } from './styles';
import UserAccount from '../../../interfaces/UserAccount';

const Review: React.FC = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);

	const productsCart: ProductsCart[] = useSelector(
		(state: AppState) => state.productsCart.currentCart
	);
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [products, setProducts] = useState<string[]>([]);

	useEffect(() => {
		setProducts(
			productsCart.map((item) => {
				return `${item.parameters.product.title} ${variationsLabel(
					item
				)}`;
			})
		);
	}, [productsCart]);

	function variationsLabel(item: ProductsCart) {
		return `${
			item.parameters.product.product_variations![0]
				? `Cor: ${
						item.parameters.product.product_variations![0].color
				  } ${
						item.parameters.product.product_variations![0]!
							.variation_sizes
							? `Tamanho: ${
									item.parameters.product
										.product_variations![0]!
										.variation_sizes[0].size
							  }`
							: `Tamanho: U`
				  }`
				: ``
		}`;
	}

	function shippingMethodLabel() {
		return productsCart.map((item) => {
			if (item.parameters.shippingMethod === 'withdraw') {
				return `${item.parameters.product.title} ${variationsLabel(
					item
				)} Retirada com o vendedor `;
			} else {
				return `${item.parameters.product.title} ${variationsLabel(
					item
				)} Frete Correios, ${
					item.parameters.product.address.street
				} - ${item.parameters.product.address.zip_code} `;
			}
		});
	}

	const purchase = () => {
		setLoading(true);
		setTimeout(() => history.push('/payment-success'), 3000);
	};
	return (
		<PageContainer>
			<AlignmentContainer>
				<Container>
					<h1>Finalização de compra</h1>

					<div className="informations-container">
						<div className="main-card">
							<OrderItem
								areaTitle="Produtos"
								itemList={[...products]}
								hasBorder={false}
								editPath="/cart"
							/>
							<OrderItem
								areaTitle="Forma de envio"
								hasBorder={true}
								itemList={shippingMethodLabel()}
								editPath="/cart/shipping-method"
							/>
							{/* <OrderItem
								areaTitle="Endereço de envio"
								itemList={[
									`Travessa ney Azevedo 177, Novo Mundo, Curitiba, Paraná - 81050220 `,
								]}
								hasBorder={false}
								editPath=""
							/> */}
						{/* <OrderItem
								areaTitle="Condições de pagamento"
								hasBorder={false}
								itemList={productsCart.map((item) => {
									if (item.installments === 1) {
										return `${item.parameters.product.title}: Pagamento à vista`;
									} else {
										return `${item.parameters.product.title}: Parcelado em ${item.installments}`;
									}
								})}
								editPath=""
							/> */}
							<Quantity />
							{/* <ShippingMethod />
							<ShippingAddress /> */}
							{/* <PaymentForm /> */}
							{/* 	<PaymentPlan /> */}
						</div>
						{/* 
						<div className="review-container">
							<div className="review">
								<div className="products">
									<span className="static-text">
										Produtos dessa compra
									</span>
									{productsCart.map((item) => (
										<span className="products-name">
											{`${item.parameters.quantity}x ${item.parameters.product.title}`}
										</span>
									))}
								</div>
								<div className="shipping-cost-container">
									<span className="title">Custo de envio</span>
									<div className="shipping-cost-and-time">
										<img src={deliveryTruck} alt="" />
										<div className="cost">
											<h3>Frete R$17,90</h3>
											<span>Chegada dia 22/10/2020</span>
										</div>
									</div>
								</div>
								<div className="total-container">
									<span className="total-value-title">
										Total a pagar
									</span>
									<div className="value">
										<h2>R$ 500,00</h2>
										<span>em 5x sem juros</span>
									</div>
								</div>
							</div>
							<div className="review-actions">
								<button
									className="back-button"
									onClick={() => history.push('/seller')}
								>
									Voltar
								</button>
								<button
									className="next-button"
									onClick={() => purchase()}
								>
									{!loading ? (
										'Finalizar compra'
									) : (
										<span
											style={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<Spinner />
										</span>
									)}
								</button>
							</div>
						</div> */}
						<CartReviewCard 
								backButtonPath="" 
								nextButtonPath={`/cart/order-confirmation/${'review'}`}
								finalPage={true}/>
					</div>
				</Container>
			</AlignmentContainer>
		</PageContainer>
	);
};

export default Review;
