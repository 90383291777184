import styled from 'styled-components';

export const PageContainer = styled.main`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	h1 {
		color: black;
		font-size: 24px;
		width: var(--Default-page-alignment-vale);
		font-family: var(--Roboto-font-family);
		font-weight: normal;
		font-size: 26px;
		margin-top: 47px;
		margin-bottom: 34px;
		@media (max-width: 768px) {
			width: 80%;
		}
	}
`;

export const Container = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 8px;
	width: var(--Default-page-alignment-vale);
	height: 100%;

	@media (max-width: 768px) {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	h1 {
		margin-top: 50px;
		font-size: min(20px, 1.8vw);
		font-family: var(--Roboto-font-family);
		font-weight: 400;
		margin-bottom: 25px;
		color: #858585;
	}
	h2 {
		margin: 35px 0 40px 50px;
		font-size: min(20px, 1.8vw);
		font-family: var(--Roboto-font-family);
		font-weight: 400;
		margin-bottom: 40px;
		color: #2d327c;
		@media (max-width: 768px) {
			font-size: 16px;
		}
	}

	.benefits {
		display: flex;
		flex-direction: column;
		row-gap: 20px;
		padding: 6px 0px 6px 22px;
		margin-left: 90px;
		@media (max-width: 768px) {
			margin: 0px 5%;
			padding: 0px;
			align-items: center;
		}

		.first-line,
		.second-line {
			display: flex;
			column-gap: 12px;
			@media (max-width: 768px) {
				display: flex;
				flex-direction: column;
				width: 100%;
			}
		}
	}
`;
export const BenefitsInBuying = styled.div`
	margin-top: 70px;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	@media (max-width: 768px){
		margin-top: 0px;
	}

	.main-title {
		margin-bottom: 45px;

		font-size: min(20px, 1.8vw);
		font-family: var(--Roboto-font-family);
		font-weight: 500;
		color: #2d327c;
		margin-left: 50px;
		@media (max-width: 768px) {
			font-size: 16px;
			max-width: 232px;
		}
	}
	.images-and-text {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		.images-container {
			display: flex;
			padding: 6px 0px 6px 22px;
			column-gap: 25px;
			@media (max-width: 768px) {
				display: flex;
				padding: 0px;
				flex-direction: column;
				width: 100%;
			}

			.image {
					position: relative;
				img {
					width: 310px;
					height: 213px;
					object-fit: cover;
					@media (max-width: 768px) {
						position: relative;
						object-fit: contain;
						height: 100%;
						width: 100%;
					}
				}

				.textImage {
					position: absolute;
    				bottom: 123px;
    				font-size: min(16px,1.5vw);
    				font-family: var(--Roboto-font-family);
    				font-weight: bold;
    				color: white;
    				width: 100%;
    				display: flex;
    				-webkit-flex-direction: row;
    				-ms-flex-direction: row;
    				flex-direction: row;
    				-webkit-box-pack: center;
    				-webkit-justify-content: center;
    				-ms-flex-pack: center;
    				justify-content: center;
					/* position: relative; */
					width: 100%;
					@media (max-width: 768px) {
						bottom: 0px;
						width: 60%;
						margin-left: -9px;
						position: absolute;
						left: 17%;
						top: 35%;
						text-align: center;
						font-size: 18px;
					}

					span {
						position: absolute;
						bottom: 123px;
						font-size: min(16px, 1.5vw);
						font-family: var(--Roboto-font-family);
						font-weight: bold;
						color: white;
						width: 100%;
						display: flex;
						flex-direction: row;
						justify-content: center;
						@media (max-width: 768px) {
							/* font-size: 18px; */

						}
					}
				}
			}
		}
		h2 {
			font-size: min(20px, 1.8vw);
			font-family: var(--Roboto-font-family);
			font-weight: 500;
			color: #004d93;
			margin-bottom: 80px;
			@media (max-width: 768px) {
				font-size: 16px;
				margin:  35px 80px;
			}
		}
	}
`;
export const AreaFooter = styled.div`
	width: 100%;
	/* height: 307px; */
	/* background: #e8e8e8; */

	display: none;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	button {
		width: 304px;
		height: 43px;
		background: #004d93;
		color: white;
		font-size: min(14px, 1.4vw);
		font-family: var(--Roboto-font-family);
		font-weight: bold;
		border: none;
		border-radius: 30px;
		@media (max-width: 768px) {
			font-size: 14px;
		}
	}
`;
export const FooterOptions = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (max-width: 768px) {
		padding: 49px;
	}
	button {
		background: #2a3199 0% 0% no-repeat padding-box;
		border-radius: 8px;
	}

	.icon-and-text {
		display: flex;
		align-items: center;
		margin-bottom: 14px;

		img {
			margin-right: 20px;
		}

		.text {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			h3 {
				color: #353a40;
				font-size: min(20px, 1.8vw);
				font-family: var(--Roboto-font-family);
				font-weight: 300;
				@media (max-width: 768px) {
					font-size: 20px;
				}
			}
			span {
				color: #353a40;
				font-size: min(13px, 1.4vw);
				font-family: var(--Roboto-font-family);
				font-weight: 400;
				@media (max-width: 768px) {
					font-size: 13px;
					font-size: 23px;
					position: absolute;
					left: 17%;
					top: 29%
				}
			}
		}
	}
`;
