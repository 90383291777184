import React, { useState } from 'react';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import UserAccount from '../../../interfaces/UserAccount';
import { cnpjMask } from '../../../utils/mask';
import BlockSellerModal from '../../Product/BlockSellerModal/BlockSellerModal';
import { Container, ModalContainer } from './styles';

type Props = {
	userAccount: UserAccount;
};

const CardSallers: React.FC<Props> = ({ userAccount }) => {
	const [open, setIsOpen] = useState(false);
	const [openBlock, setopenBlock] = useState(false);
	const history = useHistory();
	// function closeModal() {
	// 	setIsOpen(false);
	// }

	// function handleOpenModal() {
	// 	setIsOpen(true);
	// }
	return (
		<>
			<Modal
				isOpen={openBlock}
				onRequestClose={() => setopenBlock(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						zIndex: 1001,
						left: 0,
						right: 0,
						bottom: 0,
						background: '#0007',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						padding: '0 auto',
						bottom: 'auto',
						marginRight: '-50%',
						maxHeight: '100%',
						border: 'none',
						borderRadius: '11px',
						transform: 'translate(-50%, -50%)',
					},
				}}
			>
				{/* <ModalContainer>
					<div className="title-block">
						<h1>Bloquear vendedor</h1>
					</div>
					<div className="cards">
						<div className="row">
							<div className="motivation">
								<span>Motivo</span>
								<input />
							</div>
							<div className="duration">
								<span>Duração do bloqueio</span>
								<input />
							</div>
						</div>
						<div className="motive">
							<span>Especifique o motivo</span>
							<input />
						</div>
						<div className="button-text">
							<p>O vendedor sera notificado</p>
							<span>Confirmar bloqueio</span>
						</div>
					</div>
				</ModalContainer> */}
				<BlockSellerModal
					userId={userAccount.id}
					setOpen={setopenBlock}
					open={openBlock}
				></BlockSellerModal>
			</Modal>
			<Modal
				isOpen={open}
				onRequestClose={() => setIsOpen(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						zIndex: 1001,
						left: 0,
						right: 0,
						bottom: 0,
						background: '#0007',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						padding: '0 auto',
						bottom: 'auto',
						marginRight: '-50%',
						maxHeight: '100%',
						border: 'none',
						borderRadius: '11px',
						transform: 'translate(-50%, -50%)',
					},
				}}
			>
				<ModalContainer>
					<div className="title">
						<h1>{userAccount.name}</h1>
						<p>Associado desde: {userAccount.createdAt}</p>
					</div>
					<div className="collumns">
						<div className="cnpj-modal">
							<h2 className="cnpj-txt">CNPJ</h2>
							<p>{cnpjMask(userAccount!.CNPJ!)}</p>
							<h2>RAZÃO SOCIAL</h2>
							<p>{userAccount.name}</p>
							<h2>Código associado</h2>
							<p>{userAccount.id} </p>
						</div>
						<div className="statistics">
							<div className="product-registre">
								<span>22</span>
								<p>Produtos</p>
								<p>cadastrados</p>
							</div>
							<div className="sallers">
								<span>000</span>
								<p>Vendas</p>
							</div>
							<div className="cancel">
								<span>000</span>
								<p>cancelamentos</p>
							</div>
							<div className="reports">
								<span>000</span>
								<p>Reports</p>
							</div>
							<div className="devolution">
								<span>000</span>
								<p>Devoluções</p>
							</div>
							<div className="cancel-client">
								<div className="row-text">
									<span>000</span>
								</div>
								<p>Cancelados</p>
								<p>pelo cliente</p>
							</div>
							<div className="reputation">
								<div className="row-text">
									<h1>Avaliação</h1>
									<div className="bar-green"></div>
									<p>reputação</p>
								</div>
							</div>
						</div>
					</div>
					<div className="buttons-modal">
						<div
							className="going"
							onClick={() =>
								history.push(
									`/seller-area/${userAccount.id}/all/0/100000000/undefined`
								)
							}
						>
							Ir para loja
						</div>
						{/* <div
							className="block"
							onClick={() => {
								setopenBlock(true);
								setIsOpen(false);
							}}
						>
							Bloquear vendedor
						</div> */}
						<div
							className="block"
							onClick={() => {
								setopenBlock(true);
								setIsOpen(false);
							}}
						>
							Bloquear vendedor
						</div>
					</div>
				</ModalContainer>
			</Modal>
			<Container>
				<div className="name-id">
					<p>
						{userAccount.name.length > 20
							? userAccount.name.substring(0, 20) + '...'
							: userAccount.name}
					</p>
				</div>
				<div className="cnpj">
					<p>CNPJ</p>
					<span>{cnpjMask(userAccount!.CNPJ!)}</span>
					<div className="id-row">
						<p>Id:</p>
					</div>
					<span>{userAccount.id}</span>
				</div>
				<div className="buttons">
					<div className="saller-btn" onClick={() => setIsOpen(true)}>
						Ver detalhes do vendedor
					</div>
					<div
						className="going-btn"
						onClick={() =>
							history.push(
								`/seller-area/${userAccount.id}/all/0/100000000/undefined`
							)
						}
					>
						<BsBoxArrowUpRight />
						ir para loja
					</div>
				</div>
			</Container>
		</>
	);
};
export default CardSallers;
