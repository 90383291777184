import { ProductActions, ProductState } from '../interfaces/Product';

const initialState: ProductState = {
	currentProduct: undefined,
};

export default function ProductReducer(
	state = initialState,
	action: ProductActions
) {
	switch (action.type) {
		case 'INSERT_PRODUCT':
			return { ...state, currentProduct: action.product };

		case 'INSERT_PRODUCT_QUESTION':
			return {
				...state,
				currentProduct: {
					...state.currentProduct,
					product_questions: [
						...state?.currentProduct!.product_questions!,
						{
							...action.question,
							answers_product_questions: []
						},
					],
				},
			};
		case 'INSERT_PRODUCT_ANSWER':
			return {
				...state,
				currentProduct: {
					...state.currentProduct,
					product_questions: [
						...state?.currentProduct!.product_questions!.map((question) =>{
							if(question.id === action.question.id){
								return {
									...question,
									answers_product_questions: [
									 ...question.answers_product_questions!,
									 action.answer,
									]
								}
							} else {
								return {...question};
							}
						}),
					],
				},
			};
		default:
			return state;
	}
}
