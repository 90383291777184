import React, { useEffect } from 'react';
import { Container, MakeReviewsCard, ReviewsContainer } from './styles';
import user from '../../../../assets/defaultImg.svg';
import { ReactComponent as Star } from '../../../../assets/icons/star.svg';
import UserAccount from '../../../../interfaces/UserAccount';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../store';
import { IProduct } from '../../../../Pages/ProductPage/ProductPage';
import { RiStarFill, RiStarLine } from 'react-icons/ri';

type Props = {
	product?: IProduct;
};

const ProductReviews: React.FC<Props> = ({ product }) => {

	return (
		<Container>
			<h2>Opiniões sobre {product ? product.title : 'o produto'}</h2>
			<ReviewsContainer>
				{
					(!product?.product_reviews || (product?.product_reviews as any[]).length === 0) && <h1>Nenhuma opinião publicada ainda.</h1>
				}
				{
					product?.product_reviews?.map((review) => (
						<div className="review-card">
							<div className="review-info">
								<div className="review-header">
									<p id="title">{review.user_accounts.name}</p>{' '}
									{[...Array(5)].map((star, index) => (
										index + 1 <= review.rating ?
											<RiStarFill key={index} /> :
											<RiStarLine key={index} />
									))}
								</div>
								<div className="rowReviewText">
									<p>
										{review.review}
									</p>
								</div>
							</div>
						</div>
					))
				}
			</ReviewsContainer>
		</Container>
	);
};

export default ProductReviews;
