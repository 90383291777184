import { Product } from '../interfaces/Product';
import { ProductVariations } from '../interfaces/ProductVariations';

export const reOrderProductVariations = (
	variations: ProductVariations[],
	reNumber: boolean = true
) => {
	let orderedVariations = variations;

	orderedVariations = orderedVariations.sort(
		(a, b) => a.variation_order - b.variation_order
	);
	if (reNumber) {
		let reNumberingVariations = orderedVariations;
		orderedVariations.map((variation, index) => {
			reNumberingVariations[index].variation_order = index + 1;
		});
		orderedVariations = reNumberingVariations;
	}

	orderedVariations.map((variation, index) => {
		orderedVariations[
			index
		].variation_sizes = variation.variation_sizes.sort(
			(a, b) => a.size_order - b.size_order
		);

		if (reNumber) {
			let reNumberSizes = orderedVariations[index].variation_sizes;
			orderedVariations[index].variation_sizes.map((size, index) => {
				reNumberSizes[index].size_order = index + 1;
			});
			orderedVariations[index].variation_sizes = reNumberSizes;
		}

		orderedVariations[
			index
		].variation_photos = variation.variation_photos.sort(
			(a, b) => a.photo_order - b.photo_order
		);

		if (reNumber) {
			let reNumberPhotos = orderedVariations[index].variation_photos;
			orderedVariations[index].variation_photos.map((photo, index) => {
				reNumberPhotos[index].photo_order = index + 1;
			});
			orderedVariations[index].variation_photos = reNumberPhotos;
		}
	});

	return orderedVariations;
};
