import React from 'react';
import Header from '../../components/Default/header';
import PurchaseDetails from '../../components/PurchaseDetails/PurchaseDetails';
import { Container } from './styles';

// import { Container } from './styles';

const PurchaseDetailsPage: React.FC = () => {
	return (
		<Container>
			<Header />
			{/* <PurchaseDetails /> */}
		</Container>
	);
};

export default PurchaseDetailsPage;
