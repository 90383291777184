import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 40px 130px 30px 130px;
	row-gap: 11px;
	align-items: center;
	max-width: 398px;
	max-height: 380px;

	p {
		font-family: var(--Roboto-font-family);
		font-family: 26px;
		color: #02c384;
		margin-bottom: 15px;
		font-size: 20px;
		font-weight: medium;
		max-width: 300px;
		text-align: center;

		&.observation {
			color: #8a99a8;
			margin-bottom: 15px;
			margin-top: 30px;
			font-size: 14px;
		}
	}
	a {
		color: #4c6fb5;
		font-family: var(--Poppins-font-family);
		font-size: 14px;
		margin-bottom: 30px;
	}
	button {
		background: #2b63fc;
		border-radius: 8px;
		color: #fff;
		font-family: var(--Poppins-font-family);
		font-size: 16px;
		width: 290px;
		height: 40px;
		font-weight: medium;
		border: none;

		&.back {
			background: #fff;
			border: 1px solid #2b63fc;
			color: #2b63fc;
		}
	}
`;
