import React, { ChangeEvent, useEffect, useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { GrFormClose } from 'react-icons/gr';
import config from '../../config';
import { IProduct } from '../../Pages/ProductPage/ProductPage';
import { currency } from '../../utils/mask';
import closeIcon from '../../assets/icons/closeIcon.svg';
import {
	Container,
	ContainerForm,
	InputsColumn,
	RowItem,
	PageTitle,
	ButtonsContainer,
	StyledCheckBox,
	DefaultRow,
	DeleteProduct,
} from './styles';
import {
	ProductVariations,
	VariationPhotos,
	VariationSizes,
} from '../../interfaces/ProductVariations';
import { useForm } from 'react-hook-form';
import api from '../../api';
import UserAccount from '../../interfaces/UserAccount';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { ProductCategories } from '../ProductRegister/ProductRegister';
import { useHistory } from 'react-router-dom';

type Props = {
	product: IProduct;
};

export interface ProductUpdate {
	id: string;
	title: string;
	stock: string;
	max_installments: number;
	image: any;
	width: string;
	//use_state: string;
	//format: number;
	//height: string;
	//thickness: string;
	//weight: string;
	model: string;
	material: string;
	description: string;
}
export interface UpdateProductPhotos {
	id?: string;
	name?: string;
	product_id?: string;
	created_at?: Date;
	updated_at?: Date;
	photo?: File | undefined;
}

const UpdateProductMobile: React.FC<Props> = ({ product }) => {
	const history = useHistory();
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [editProduct, setEditProduct] = useState<IProduct>();
	const [hasVariation, setHasVariation] = useState<boolean>(false);
	const [productPhotos, setProductPhotos] = useState<any>([]);
	const { register, handleSubmit, errors } = useForm<ProductUpdate>();

	const [validVariations, setValidVariations] = useState<boolean>(false);
	const [
		selectedVariaiton,
		setSelectedVariaiton,
	] = useState<ProductVariations>();
	const [
		selectedVariationIndex,
		setSelectedVariationIndex,
	] = useState<number>(0);
	//editPhotos, control state to store every current image and every new one
	//is not supposed to be render anywhere
	const [editPhotos, setEditPhotos] = useState<UpdateProductPhotos[]>([]);
	const [
		productCategories,
		setProductCategories,
	] = useState<ProductCategories>();

	useEffect(() => {
		api.get('/get-product-categories')
			.then((response: any) => {
				setProductCategories(response.data);
			})
			.catch((err) => {});
	}, []);

	useEffect(() => {
		setEditProduct(product);
		if (
			product.product_variations &&
			product.product_variations.length > 0
		) {
			setSelectedVariaiton(product.product_variations[0]);
			setHasVariation(true);
		} else {
			setHasVariation(false);
			setEditPhotos(product.product_photos! as UpdateProductPhotos[]);
		}
	}, [product]);

	useEffect(() => {
		let tempEditProduct = editProduct!;
		if (editProduct && editProduct.product_variations) {
			const index = tempEditProduct.product_variations!.findIndex(
				(variation) => variation.id === selectedVariaiton?.id
			);

			tempEditProduct!.product_variations![index] = selectedVariaiton!;

			setSelectedVariationIndex(index);
			setEditProduct({ ...tempEditProduct });
		}
	}, [selectedVariaiton]);

	const handleVariationChange = (variation: ProductVariations) => {
		setSelectedVariaiton(variation);
	};

	const handleAddVariation = () => {
		let tempEditVariations = editProduct!;
		tempEditVariations.product_variations!.push({
			id: Math.random().toString(36).substring(7), //temp id
			product_id: '',
			color: 'cor',
			hex: '',
			variation_order: tempEditVariations.product_variations!.length + 1,
			created_at: '',
			updated_at: '',
			variation_photos: [],
			variation_sizes: [],
		});
		setEditProduct({ ...tempEditVariations });
	};

	const handleAddImage = (e: ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) {
			return;
		}
		const selectedImages = Array.from(e.target.files);

		if (hasVariation) {
			let tempEditPhotos = editPhotos;
			for (const file of selectedImages) {
				tempEditPhotos.push({
					id: '',
					photo: file,
					name: file.name,
					product_id: '',
				} as UpdateProductPhotos);
			}

			let tempSelectedVariaiton = selectedVariaiton;
			let tempEditProduct = editProduct;
			tempEditProduct!.product_variations!.map((pVariation, index) => {
				if (selectedVariaiton!.id === pVariation.id) {
					for (const file of selectedImages) {
						/* 
            tempSelectedVariaiton!.variation_photos.push({
              id:"",
              name:"",
              created_at:"",
              updated_at:"",
              photo: file,
            }); */
						tempEditProduct!.product_variations![
							index
						].variation_photos.push({
							id: '',
							name: '',
							photo_order:
								tempEditProduct!.product_variations![index]
									.variation_photos.length + 1,
							created_at: '',
							updated_at: '',
							photo: file,
						});
					}
				}
			});

			setSelectedVariaiton({ ...tempSelectedVariaiton! });
			setEditProduct({ ...tempEditProduct! });
			setEditPhotos([...tempEditPhotos]);
		} else {
			setProductPhotos(productPhotos.concat(selectedImages));
		}
	};

	const handleRemoveNewPhoto = (photoName: string) => {
		setProductPhotos(
			productPhotos.filter((imgItem: File) => imgItem.name !== photoName)
		);

		let tempEditPhotos = editPhotos;
		tempEditPhotos! = tempEditPhotos!.filter((photo) => {
			return photo.photo?.name !== photoName;
		});
		setEditPhotos([...tempEditPhotos]);
	};

	const handleRemoveCurrentPhoto = (id: string) => {
		let tempEditProduct = editProduct;
		tempEditProduct!.product_photos! = tempEditProduct!.product_photos!.filter(
			(photo) => {
				return photo.id !== id;
			}
		);
		setEditProduct({ ...tempEditProduct! });

		let tempEditPhotos = editPhotos;
		tempEditPhotos! = tempEditPhotos!.filter((photo) => {
			return photo.id !== id;
		});
		setEditPhotos([...tempEditPhotos]);
	};

	const handleRemoveVariationPhoto = (photo: VariationPhotos) => {
		let tempSelectedVariaiton = selectedVariaiton;
		tempSelectedVariaiton!.variation_photos! = tempSelectedVariaiton!.variation_photos.filter(
			(vPhoto) => {
				return vPhoto !== photo;
			}
		);

		let tempEditProduct = editProduct;
		tempEditProduct!.product_variations!.map((variations, index) => {
			if (variations.id === selectedVariaiton!.id) {
				tempEditProduct!.product_variations![
					index
				] = tempSelectedVariaiton!;
			}
		});

		setEditProduct({ ...tempEditProduct! });
		setSelectedVariaiton({ ...tempSelectedVariaiton! });
	};

	const handleRemoveVariation = () => {
		let tempEditProduct = editProduct;

		tempEditProduct!.product_variations = tempEditProduct!.product_variations!.filter(
			(variation) => {
				return variation.id !== selectedVariaiton!.id;
			}
		);
		setEditProduct({ ...tempEditProduct! });
		//setEditVariations(editVariations.filter((variation) => variation !== selectedVariaiton));
		setSelectedVariaiton(
			editProduct?.product_variations![0] === selectedVariaiton
				? editProduct?.product_variations![1]
				: editProduct?.product_variations![0]
		);
	};

	const handleRemoveSize = (size: string) => {
		let tempSelectedVariaiton = selectedVariaiton;
		tempSelectedVariaiton!.variation_sizes! = tempSelectedVariaiton!.variation_sizes!.filter(
			(vSize) => {
				return vSize.id !== size;
			}
		);

		let tempEditProduct = editProduct;
		tempEditProduct!.product_variations!.map((variations, index) => {
			if (variations.id === selectedVariaiton!.id) {
				tempEditProduct!.product_variations![
					index
				] = tempSelectedVariaiton!;
			}
		});

		setEditProduct({ ...tempEditProduct! });
		setSelectedVariaiton({ ...tempSelectedVariaiton! });
	};

	const handleAddSize = () => {
		let tempSelectedVariaiton = selectedVariaiton;
		tempSelectedVariaiton!.variation_sizes!.push({
			id: Math.random().toString(36).substring(6), //temp id
			size: '',
			stock: 1,
			size_order: tempSelectedVariaiton!.variation_sizes!.length + 1,
			created_at: '',
			updated_at: '',
		});

		setSelectedVariaiton({ ...tempSelectedVariaiton! });
	};

	const handleSizeChange = (size: VariationSizes, sizeName: string) => {
		let tempSelectedVariaiton = selectedVariaiton;
		const index = tempSelectedVariaiton!.variation_sizes!.findIndex(
			(vSize) => size.id === vSize.id
		);
		tempSelectedVariaiton!.variation_sizes![
			index
		].size = sizeName.toUpperCase();

		setSelectedVariaiton({ ...tempSelectedVariaiton! });
	};

	const handleSizeStockChange = (size: VariationSizes, stock: number) => {
		let tempSelectedVariaiton = selectedVariaiton;
		const index = tempSelectedVariaiton!.variation_sizes!.findIndex(
			(vSize) => size.id === vSize.id
		);
		tempSelectedVariaiton!.variation_sizes![index].stock = stock;

		setSelectedVariaiton({ ...tempSelectedVariaiton! });
	};

	const handleDeleteProduct = () => {
		api.delete('/delete-product', {
			params: { product_id: product.id },
		})
			.then((response) => {
				alert('Operação realizada com sucesso');
				window.location.reload(false);
			})
			.catch((err) => alert('Falha ao realizar operação'));
	};

	const validateVariations = () => {
		let valid = true;
		if (hasVariation && editProduct) {
			for (let i = 0; i < editProduct.product_variations!.length; i++) {
				if (editProduct.product_variations![i].color.trim() === '') {
					valid = false;
					break;
				} else {
					for (
						let j = 0;
						j <
						editProduct.product_variations![i].variation_sizes
							.length;
						j++
					) {
						if (
							editProduct.product_variations![i].variation_sizes[
								j
							].size.trim() === ''
						) {
							valid = false;
							break;
						}
					}
				}
			}
		}
		setValidVariations(!valid);
		return valid;
	};

	const onSubmit = async (data: any) => {
		if (validateVariations()) {
			const newData = new FormData();

			newData.append('id', editProduct!.id);
			newData.append('title', data.title);
			newData.append('max_installments', editProduct!.max_installments);
			//Dont have a field, data is no beign changed
			newData.append('height', product.height.toString());
			newData.append('width', product.width.toString());
			newData.append('use_state', product.use_state);
			newData.append('category_id', editProduct!.category_id.toString());
			newData.append('thickness', product.thickness.toString());
			newData.append('format', product.format.toString());
			newData.append('weight', product.weight.toString());
			//To here
			newData.append('brand', data.brand);
			newData.append('model', data.model);
			newData.append('material', data.material);
			newData.append('description', data.description);
			newData.append('user_account_id', user.id);
			newData.append(
				'price',
				data.price.replace('.', '').replace(',', '.')
			);

			if (data.promotion) {
				newData.append('promotion', data.promotion);
				newData.append(
					'original_price',
					data.price.replace('.', '').replace(',', '.')
				);
			}

			if (hasVariation) {
				newData.append(
					'variations',
					JSON.stringify(editProduct!.product_variations)
				);

				let totalStock: number = 0;
				editProduct!.product_variations!.map((variation) => {
					variation.variation_photos.map((photo) => {
						newData.append('image', photo.photo as any);
					});

					variation.variation_sizes.map((size) => {
						totalStock += size.stock;
					});
				});

				newData.append('stock', totalStock.toString());
			} else {
				newData.append('photos', JSON.stringify(editPhotos));
				productPhotos.forEach((image: any) => {
					newData.append('image', image);
				});

				newData.append('stock', data.stock);
			}

			api.put('/update-product', newData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
				.then((response) => {
					alert('Operação realizada com sucesso');
					history.push(`/seller-area/${user.id}/all/0/100000000/undefined/products`);
				})
				.catch((err) => alert('Falha ao realizar operação'));
		}
	};

	const getPriceDefaultValue = () => {
		if (editProduct!.promotion && editProduct!.promotion > 0) {
			return editProduct!.original_price!.toString().replace('.', ',');
		} else {
			return editProduct!.price.toString().replace('.', ',');
		}
	};

	return (
		<>
			<PageTitle>Editar detalhes do anúncio</PageTitle>
			<Container
				onSubmit={handleSubmit(onSubmit)}
				encType="multipart/form-data"
			>
				{editProduct && (
					<>
						<ContainerForm>
							<InputsColumn>
								<RowItem>
									<label>Titulo do anúncio</label>
									<textarea
										name="title"
										className="title"
										ref={register({ required: false })}
										defaultValue={editProduct.title}
									/>
								</RowItem>
								<DefaultRow>
									<RowItem style={{ flex: '3' }}>
										<label>Valor do anúncio</label>
										<input
											name="price"
											type="text"
											ref={register({ required: false })}
											onKeyUp={currency}
											defaultValue={getPriceDefaultValue()}
											className="price"
										/>
									</RowItem>
									<RowItem style={{ flex: '2' }}>
										<label>Aplicar promoção</label>
										<input
											name="promotion"
											type="text"
											ref={register({ required: false })}
											className="promotion"
											defaultValue={editProduct.promotion}
										/>
									</RowItem>
								</DefaultRow>
								<RowItem>
									<label>Descrição do anúncio</label>
									<textarea
										name="description"
										ref={register({ required: false })}
										className="description"
										defaultValue={editProduct.description}
									></textarea>
								</RowItem>
								{/* <RowItem style={{ marginLeft: '15px' }}>
									<label>Número de parcelas</label>
									<input
										name="max_installments"
										type="text"
										ref={register({ required: false })}
										defaultValue={editProduct.max_installments}
										style={{ width: '206px' }}
									/>
									<StyledCheckBox
										checked={false} label="Frete grátis" />
									<StyledCheckBox
										checked={false} label="Parcelamento sem juros" />
								</RowItem> */}
								<RowItem>
									<label>Descrição técnica</label>
									<input
										name="brand"
										type="text"
										ref={register({ required: false })}
										defaultValue={editProduct.brand}
										style={{
											marginBottom: '11px',
										}}
										placeholder="Marca"
									/>
									<input
										name="model"
										type="text"
										ref={register({ required: false })}
										defaultValue={editProduct.model}
										style={{
											marginBottom: '11px',
										}}
										placeholder="Modelo"
									/>
									<input
										name="material"
										type="text"
										ref={register({ required: false })}
										defaultValue={editProduct.material}
										style={{
											marginBottom: '11px',
										}}
										placeholder="Material"
									/>
								</RowItem>
								<RowItem>
									<label>Categoria</label>
									<select
										value={editProduct.category_id}
										onChange={(e: any) => {
											setEditProduct({
												...editProduct,
												category_id: e.target.value,
											});
										}}
									>
										{productCategories &&
											productCategories.categories.map(
												(category) => (
													<option
														id={category.id}
														value={category.id}
													>
														{category.name}
													</option>
												)
											)}
									</select>
								</RowItem>
							</InputsColumn>
						</ContainerForm>
						<h4>Controle de estoque</h4>
						{hasVariation && (
							<div className="color-menu">
								{editProduct.product_variations!.map(
									(variation, index) => (
										<div
											id={'variation' + index}
											className={
												selectedVariaiton!.id ===
												variation.id
													? 'color-option active-variation'
													: 'color-option'
											}
											onClick={() =>
												handleVariationChange(variation)
											}
										>
											{variation.color}
										</div>
									)
								)}
								<div
									id={'variation-new'}
									className={'color-option new-variation'}
									onClick={() => handleAddVariation()}
								>
									Nova!
								</div>
							</div>
						)}
						<ContainerForm
							style={{
								border: 'none',
								borderRadius: '0 8px 8px 8px',
							}}
						>
							{selectedVariaiton && (
								<RowItem style={{ flex: '1' }}>
									<div
										className="variation-name-container"
										style={{ flex: '1' }}
									>
										<div
											className="variation-input-container"
											style={{ flex: '1' }}
										>
											<label>Variação</label>
											<input
												onChange={(e) =>
													setSelectedVariaiton({
														...selectedVariaiton!,
														color: e.target.value,
													})
												}
												value={selectedVariaiton!.color}
											/>
										</div>
										<button
											className="delete-button"
											type="button"
											onClick={() =>
												handleRemoveVariation()
											}
										>
											Excluir variação
										</button>
									</div>
								</RowItem>
							)}
							<RowItem>
								<label>Fotos do anúncio</label>
								<div className="photo-container">
									<figure>
										<label htmlFor="add-image">+</label>
										<input
											type="file"
											name="image"
											id="add-image"
											onChange={(e: any) => {
												handleAddImage(e);
											}}
											accept="image/*"
											multiple
											maxLength={4}
											style={{ display: 'none' }}
										/>
									</figure>
									{hasVariation &&
										selectedVariaiton &&
										selectedVariaiton.variation_photos!.map(
											(photo, index) => (
												<div className="photo">
													<div className="preview">
														<div className="close-icon-container">
															<img
																src={closeIcon}
																alt="imagem do produto"
																className="close-icon"
																onClick={() =>
																	handleRemoveVariationPhoto(
																		photo
																	)
																}
															/>
															<img
																style={{
																	objectFit:
																		'cover',
																}}
																src={
																	photo.name ===
																	''
																		? URL.createObjectURL(
																				photo.photo
																		  )
																		: `${config.host}/static/products/${photo.name}`
																}
																alt="imagem do produto"
																className="img-preview"
															/>
														</div>
													</div>
												</div>
											)
										)}
									{!hasVariation &&
										editProduct.product_photos!.map(
											(photo, index) => {
												if (photo.name)
													return (
														<div className="photo">
															<div className="preview">
																<div className="close-icon-container">
																	<img
																		src={
																			closeIcon
																		}
																		alt="imagem do produto"
																		className="close-icon"
																		onClick={() =>
																			handleRemoveCurrentPhoto(
																				photo.id
																			)
																		}
																	/>
																	<img
																		style={{
																			objectFit:
																				'cover',
																		}}
																		src={`${config.host}/static/products/${photo.name}`}
																		alt="imagem do produto"
																		className="img-preview"
																	/>
																</div>
															</div>
														</div>
													);
											}
										)}
									{productPhotos.length > 0 &&
										productPhotos.map(
											(img: File, index: any) => {
												if (img.name)
													return (
														<div className="preview">
															<div className="close-icon-container">
																<img
																	src={
																		closeIcon
																	}
																	alt="imagem do produto"
																	className="close-icon"
																	onClick={() =>
																		handleRemoveNewPhoto(
																			img.name
																		)
																	}
																/>
																<img
																	style={{
																		objectFit:
																			'cover',
																	}}
																	src={URL.createObjectURL(
																		img
																	)}
																	alt="imagem do produto"
																	className="img-preview"
																/>
															</div>
														</div>
													);
											}
										)}
								</div>
							</RowItem>
							{hasVariation && selectedVariaiton && editProduct && (
								<RowItem style={{ marginTop: '60px' }}>
									<label>Estoque dos tamanhos</label>
									<div className="input-size-div">
										{editProduct!.product_variations![
											selectedVariationIndex
										].variation_sizes.map((size, index) => (
											<div>
												<input
													className="size"
													onChange={(e) =>
														handleSizeChange(
															size,
															e.target.value
														)
													}
													defaultValue={size.size}
													value={
														editProduct!
															.product_variations![
															selectedVariationIndex
														].variation_sizes[index]
															.size
													}
													type="text"
												/>
												<input
													className="size-stock"
													onChange={(e) =>
														handleSizeStockChange(
															size,
															parseInt(
																e.target.value
															)
														)
													}
													defaultValue={size.stock}
													value={
														editProduct!
															.product_variations![
															selectedVariationIndex
														].variation_sizes[index]
															.stock
													}
													type="number"
												/>
												<GrFormClose
													onClick={(e) =>
														handleRemoveSize(
															size.id
														)
													}
													fill={'#8A99A8'}
												/>
											</div>
										))}
										<button
											onClick={(e) => {
												e.preventDefault();
												handleAddSize();
											}}
										>
											+ Adicionar tamanho
										</button>
									</div>
								</RowItem>
							)}
							{!hasVariation && (
								<RowItem style={{ marginTop: '60px' }}>
									<label>Estoque total</label>
									<div className="input-size-div single">
										<input
											id="stock"
											name="stock"
											ref={register({ required: false })}
											type="number"
											defaultValue={editProduct.stock}
										/>
									</div>
								</RowItem>
							)}
						</ContainerForm>
						<ButtonsContainer>
							<span>
								{validVariations && (
									<span>
										Todas as cores e tamanhos
										<br />
										devem estar preenchidos.
									</span>
								)}
							</span>
							<button
								id="cancel-changes"
								type="button"
								onClick={() =>
									history.push(
										`/seller-area/${user.id}/all/0/100000000/undefined/`
									)
								}
							>
								Cancelar Alterações
							</button>
							<button id="save-changes" type="submit">
								Salvar Alterações
							</button>
						</ButtonsContainer>
					</>
				)}
			</Container>
			<DeleteProduct>
				<button
					id="delete-product"
					type="button"
					onClick={() => handleDeleteProduct()}
				>
					Excluir Anúncio
				</button>
			</DeleteProduct>
		</>
	);
};

export default UpdateProductMobile;
