import React from 'react';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { Container } from './styles';

interface Props {
	name?: string;
	ids?: string;
	description?: string;
	data?: string;
	solved?: boolean;
	onClickGo?: () => any;
	onClickSolve?: () => any;
}

const CardsOccurrences: React.FC<Props> = ({
	name = '',
	ids = '',
	description = '',
	data = '',
	solved = false,
	onClickGo = () => {},
	onClickSolve = () => {},
}) => {
	return (
		<Container>
			<div className="type-id">
				<h1>{name}</h1>
				<p>{ids}</p>
			</div>
			<div className="description">
				<h2>Descrição</h2>
				<p>{description}</p>
			</div>
			<div className="data">
				<h2>Data da denúncia</h2>
				<p>{data}</p>
			</div>
			<div className="buttons-cards">
				{!solved && <div className="button-check" onClick={onClickSolve}>Marcar como resolvida</div>}
				<div className="button-going" onClick={onClickGo}>
					<BsBoxArrowUpRight />
					<p>Ir para anúncio</p>
				</div>
			</div>
		</Container>
	);
};

export default CardsOccurrences;
