import styled from 'styled-components';

export const AssociatedCard = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	background: #ffffff;
	width: 278px;
	height: 392px;
	//margin-right: 24px;
	cursor: pointer;
	border-radius: 4px;
	box-shadow: 0px 3px 16px #0000001c;
	transition: box-shadow 0.3s;
	@media (max-width: 768px) {
		min-width: 277px;
	}
	&:hover {
		box-shadow: 0px 6px 26px #0000003c;
		.associated-name-products-container {
			h4 {
				color: #2b63fc;
			}
		}
	}
	.associated-img-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		position: relative;
		.image-cover{
			width: 100%;
			height: 110px;
			overflow:hidden;
		}
		.associated-img-cover {
			display: flex;
			flex: 1;
			width: 100%;
			min-height: 110px;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			object-fit: cover;
		}
		div.img-profile {
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 1;
			position: absolute;
			height: 100px;
			width: 100px;
			top: 50px;
			border-radius: 10px;
			background: #f5f5f5;
			overflow: hidden;
			> img {
				height: 100%;
			}
		}
	}

	.associated-name-products-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex: 1;
		margin-top: 55px;
		gap: 24px;

		h3 {
			color: #353a40;
			/* white-space: nowrap; */
			text-align: center;
			font-family: var(--Roboto-font-family);
			font-weight: medium;
			line-height: 36px;
			padding: 0 15px;
			font-size: 20px;
		}

		h4 {
			color: #8a99a8;
			/* white-space: nowrap; */
			text-align: center;
			font-family: var(--Roboto-font-family);
			font-weight: bold;
			line-height: 28px;
			font-size: 18px;
		}

		.associated-products {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			padding-right: 18px;
			padding-left: 18px;
			gap: 8px;

			img {
				width: 75px;
				height: 75px;
				border-radius: 4px;
			}
		}
	}
`;