import React, { useEffect, useRef, useState } from 'react';
import { Container, InfosContainer, ReportButton } from './styles';

import { RiShoppingCart2Line, RiErrorWarningLine, RiStarFill, RiStarLine } from 'react-icons/ri';
import { RiTruckLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';

import { IProduct } from '../../../Pages/ProductPage/ProductPage';
import { useDispatch, useSelector } from 'react-redux';
import {
	addProductCartAction,
	addSingleProductCartAction,
	insertProductQuantity,
} from '../../../actions/ProductsCartActions';
import UserAccount, { UserTypeEnum } from '../../../interfaces/UserAccount';
import { AppState } from '../../../store';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import {
	ProductVariations,
	VariationSizes,
} from '../../../interfaces/ProductVariations';
import { getFristVariationImageSource } from '../../../utils/GetProductImage';
import ProductsCart, {
	ProductParameters,
} from '../../../interfaces/ProductsCart';
import { searchInCart } from '../../../utils/searchInCart';
import FavoriteIcon from '../../Default/FavoriteIcon/FavoriteIcon';
import api from '../../../api';
import { AxiosResponse } from 'axios';
import { ShopQueryReturn } from '../../SellerStore/SellerStore';
import { ShopCustomizations } from '../../../interfaces/ShopCustomizations';
import {
	FormattedData,
	useShippingValue,
} from '../../../hooks/useShippingValue';
import Spinner from '../../Loader/Spinner';
import { cepDefault } from '../../../utils/mask';

type Props = {
	product: IProduct;
	setSelectedVariation: React.Dispatch<
		React.SetStateAction<ProductVariations | undefined>
	>;
};

const ProductSideInfos: React.FC<Props> = ({
	product,
	setSelectedVariation,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [shippingValues, setShippingValues] = useState<FormattedData[]>([]);
	const [zipCode, setZipCode] = useState<string>('');
	const { getValues, isLoading, hasError } = useShippingValue({
		onComplete: (result) => {
			setShippingValues(result);
		},
		onError: () => {
			console.log('error');
		},
	});
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [info, setInfo] = useState<any | undefined>(undefined);

	const [isInnactive, setIsInnactive] = useState<boolean>(false);
	const [isStockAvailable, setIsStockAvailable] = useState<boolean>(true);
	const [productQuantity, setProductQuantity] = useState<number>(1);
	const [selectedColor, setSelectedColor] = useState<ProductVariations>();
	const [selectecSize, setSelectecdSize] = useState<VariationSizes>();
	const [sizeDivider, setSizeDivider] = useState<{
		start: number;
		end: number;
	}>({ start: 0, end: 4 });
	const [shopCustomization, setShopCustomization] = useState<
		ShopCustomizations | undefined
	>(undefined);
	const productsCart: ProductsCart[] = useSelector(
		(state: AppState) => state.productsCart.currentCart
	);

	const handleChangeZipCode = async (zipCode: string) => {
		setZipCode(cepDefault(zipCode));
		if (zipCode.length > 8) {
			await getValues({
				productId: product.id,
				destinationZipCode: zipCode.replace('-', ''),
			});
		}
	};

	const handleSizeDivider = (foward: boolean) => {
		if (foward && selectedColor!.variation_sizes.length > sizeDivider.end) {
			setSizeDivider({
				start: sizeDivider.start + 1,
				end: sizeDivider.end + 1,
			});
		} else if (!foward && 0 < sizeDivider.start) {
			setSizeDivider({
				start: sizeDivider.start - 1,
				end: sizeDivider.end - 1,
			});
		}
	};
	const handleCorlorChange = (variation: ProductVariations) => {
		setSelectedVariation(variation);
		setSelectecdSize(variation.variation_sizes[0]);
		setSelectedColor(variation);
		setSizeDivider({ start: 0, end: 4 });
	};

	const handleReport = async () => {
		api.post('/report-product', { product_id: product.id }).then(() => {
			alert("Report enviado com sucesso!");
		});
	}

	const handleAddToCart = async () => {
		if (
			isStockAvailable &&
			(!user || (user && user.id !== product.user_account_id))
		) {
			let cartProduct = product;

			if (selectedColor && selectecSize) {
				//send product with only the
				//selected color and size

				let colorSize = selectedColor;
				if (selectedColor.variation_sizes.length > 1) {
					colorSize.variation_sizes = [selectecSize];
				} else {
					colorSize.variation_sizes = [];
				}
				cartProduct.product_variations = [colorSize];
			}
			const card_product_id = await searchInCart(
				productsCart,
				product.id
			);

			if (user) {
				dispatch(
					addSingleProductCartAction({
						parameters: {
							product: cartProduct,
							quantity: productQuantity,
							shippingMethod: 'withdraw',
							address: user.Address![0]!,
						},
						cart_product_id: card_product_id + 1,
						formOfPayment: 'credit_card',
						installments: 1,
					})
				);
			} else if (!user) {
				dispatch(
					addProductCartAction({
						parameters: {
							product: cartProduct,
							quantity: productQuantity,
							shippingMethod: 'withdraw',
							/* address: user.Address![0]!, */
						},
						cart_product_id: card_product_id + 1,
						formOfPayment: 'credit_card',
						installments: 1,
					})
				);
			}
			history.push(`/cart/${'product'}`);
		}
	};

	const getSizeClassName = (size: VariationSizes) => {
		let name = 'size-option ';
		if (selectecSize === size) {
			name += 'active-size ';
		}
		if (size.stock < 1) {
			name += 'inactive-size ';
		}

		return name;
	};

	useEffect(() => {
		api.get('/get-shop-info', {
			params: {
				shop_id: product.user_account_id,
			},
		}).then((response: AxiosResponse) => {
			setInfo(response.data.data);
		});
	}, []);
	useEffect(() => {
		api.get('/get-shop-customizations', {
			params: {
				user_account_id: product.user_account_id,
			},
		}).then((response: AxiosResponse<ShopQueryReturn>) => {
			setShopCustomization(response.data.shop[0]);
		});
	}, []);

	useEffect(() => {
		if (selectecSize) {
			if (selectecSize.stock >= productQuantity) {
				setIsStockAvailable(true);
			} else {
				setIsStockAvailable(false);
			}
		} else if (!selectecSize && parseInt(product.stock) >= productQuantity)
			setIsStockAvailable(true);
		else setIsStockAvailable(false);
	}, [productQuantity, selectecSize, product]);

	useEffect(() => {
		if (
			product.product_variations &&
			product.product_variations.length > 0
		) {
			setSelectedColor(product.product_variations[0]);
			setSelectecdSize(product.product_variations[0].variation_sizes[0]);
		}

		if (product.inactive_products) {
			setIsInnactive(true);
		} else {
			setIsInnactive(false);
		}
	}, [product]);

	return (
		<Container>
			<FavoriteIcon
				productId={product.id}
				position={'relative'}
				top="30px"
				left="-10px"
				zIndex={1}
			/>
			<InfosContainer>

				<div className="action-buttons">
					<div className="cart-button-container">
						{!product.showcase && (
							<>
								<div className="delivery-informations-container">
									<RiTruckLine
										/* size={40} */
										fill={isInnactive ? '#353A40' : '#2B63FC'}
										className="delivery-icon"
									/>
									<div className="delivery-informations-card">
										<h4 className={isInnactive ? 'innactive' : ''}>
											Entrega{' '}
											{isInnactive
												? 'indisponível'
												: 'disponível'}
										</h4>
									</div>
								</div>
								{!isInnactive && (
									<>
										<span className="delvirey-span">
											Calcule o valor do frete
										</span>

										<input
											type="text"
											placeholder="CEP"
											value={zipCode}
											onChange={(e) =>
												handleChangeZipCode(e.target.value)
											}
										/>
										{!hasError ? (
											<>
												{isLoading ? (
													<Spinner />
												) : (
														shippingValues.map(
															(shippingValue) => (
																<span className="delivery-service-name">
																	<strong>
																		{`${shippingValue.service}: `}
																	</strong>
																	<p className="delivery-service-infos">
																		{shippingValue.message ??
																			`${shippingValue.value} chega em ${shippingValue.deadline}`}
																	</p>
																</span>
															)
														)
													)}
											</>
										) : (
												<span className="delivery-service-name">
													Não foi possível recuperar as
													informações de entrega no momento!
												</span>
											)}
										{selectedColor &&
											selectedColor.variation_sizes.length >
											1 && (
												<>
													<span
														className="delvirey-span"
														style={{
															fontSize: '16px',
															fontWeight: 500,
														}}
													>
														Escolha o tamanho
													</span>
													<div className="size-div">
														{selectedColor.variation_sizes
															.length > 4 && (
																<IoIosArrowBack
																	size={'20px'}
																	fill={
																		sizeDivider.start >
																			0
																			? '#2B63FC '
																			: ''
																	}
																	onClick={() =>
																		handleSizeDivider(
																			false
																		)
																	}
																/>
															)}
														{selectedColor.variation_sizes
															.slice(
																sizeDivider.start,
																sizeDivider.end
															)
															.map((size) => {
																return (
																	<div
																		className={getSizeClassName(
																			size
																		)}
																		onClick={() => {
																			if (
																				size.stock >
																				0
																			) {
																				setSelectecdSize(
																					size
																				);
																			}
																		}}
																	>
																		{size.stock <
																			1 && (
																				<div className="unavaialable-line" />
																			)}
																		{size.size}
																	</div>
																);
															})}
														{selectedColor.variation_sizes
															.length > 4 && (
																<IoIosArrowForward
																	size={'20px'}
																	fill={
																		sizeDivider.end <
																			selectedColor
																				.variation_sizes
																				.length
																			? '#2B63FC '
																			: ''
																	}
																	onClick={() =>
																		handleSizeDivider(
																			true
																		)
																	}
																/>
															)}
													</div>
												</>
											)}
										<div className={'stock-container'}>
											<h3
												className={
													!isStockAvailable
														? 'not-vailable'
														: ''
												}
											>
												{isStockAvailable
													? 'Estoque disponível!'
													: 'Estoque indisponível!'}
											</h3>
											<span>
												{/* {productQuantity > 1 ? "Quantidades: " : "Quantidade: "} */}
												Quantidade:
												<select
													onChange={(e) => {
														setProductQuantity(
															parseInt(e.target.value)
														);
													}}
												>
													<option value="1">1 unidade</option>
													<option value="2">
														2 unidades
													</option>
													<option value="3">
														3 unidades
													</option>
													<option value="4">
														4 unidades
													</option>
													<option value="5">
														5 unidades
													</option>
												</select>
											</span>
											{product.product_variations &&
												product.product_variations.length >
												1 && (
													<>
														<span
															style={{
																marginTop: '10px',
																fontWeight: 500,
															}}
														>
															Cor:
															{' ' + selectedColor?.color}
														</span>
														<div className="colors-div">
															{product.product_variations.map(
																(variation) => {
																	return (
																		<div
																			className={
																				selectedColor ===
																					variation
																					? 'color-option active-color'
																					: 'color-option'
																			}
																		>
																			<img
																				alt={
																					'cor ' +
																					variation.color
																				}
																				src={getFristVariationImageSource(
																					variation
																				)}
																				onClick={() => {
																					handleCorlorChange(
																						variation
																					);
																				}}
																			/>
																		</div>
																	);
																}
															)}
														</div>
													</>
												)}
										</div>
										{(
											<span
												className={
													'cart-button ' +
													(isStockAvailable
														? ''
														: 'not-vailable')
												}
												onClick={() => {
													user ? (handleAddToCart()) : history.push('/login/header/')
												}

												}
											>
												<RiShoppingCart2Line className="cart-icon" />
												<span className="cart-span">
													Adicionar ao carrinho
												</span>
											</span>
										)}
										{
											user && (
												<ReportButton onClick={() => handleReport()}>
													<RiErrorWarningLine />
													<p>
														Denunciar produto
													</p>
												</ReportButton>
											)
										}
									</>
								)}
							</>
						)}

						<div className="seller-informations-container">
							<span>Vendedor</span>
							<h4
								style={{ cursor: 'pointer' }}
								onClick={() => {
									history.push(
										`/seller-area/${product.user_account_id}/all/0/100000000/undefined`
									);
								}}
							>
								{shopCustomization?.shop_name ||
									product.user_accounts.name}
							</h4>
							<span>Avaliação do vendedor</span>
							<div className="seller-avaliation">
								{[...Array(5)].map((star, index) =>
									index + 1 <=
										(info?.reviews_average || 0) ? (
											<RiStarFill />
										) : (
											<RiStarLine />
										)
								)}
							</div>
							{/* EU FIZ O CSS PARA MUDAR A COR 
							EXEMPLO COMENTADO ABAIXO*/}

							{/* <div className="seller-avaliation">
							<span className={`avaliation-${"bad"}`}>
								Ruim
							</span>
							<div>
								<div 
									className={`avaliation-${"bad"}-div`}
									style={{width:"82px"}}
								/>
							</div>
						</div> */}
						</div>
						{isInnactive && (
							<div className="innactive-info-container">
								Anúncio inativo pelo vendedor!
								<button>Avise-me</button>
							</div>
						)}
					</div>
				</div>
			</InfosContainer>
		</Container>
	);
};

export default ProductSideInfos;
