import styled from 'styled-components';

interface IButtonWrapper {
	hasFavorite: boolean;
}

export const ButtonWrapper = styled.div<IButtonWrapper>`
	display: flex;
	width: 25px;
	height: 25px;
	align-items: center;
	justify-content: center;
	font-size: 33px;
	background-color: transparent;
	border: 0;
	padding: 0;
	cursor: pointer;
	outline: 0;
	border-radius: 100%;

	> svg {
		color: ${props => props.hasFavorite ? '#e33d3d' : '#999'};
		transition: color 0.2s;
	}

`;
