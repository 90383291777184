import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { insertMobileHeader } from '../../actions/MobileHeaderActions';
import Cart from '../../components/Cart/Cart';
import Header from '../../components/Default/header';
import { BackPreset } from '../../interfaces/MobileHeader';
import { Container } from './styles';

const CartPage: React.FC = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(insertMobileHeader(BackPreset))
	});
	return (
		<Container>
			<Header />
			<Cart />
		</Container>
	);
};

export default CartPage;
