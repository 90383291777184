import styled from 'styled-components';
export const ContainerMobile = styled.div`
	@media (min-width: 768px) {
		display: none;
	}
	width: calc(100% - 11px - 24px);
	background: white;
	min-height: 194px;
	padding: 15px 11px 15px 24px;
	display: flex;
	flex-direction: row;
	@media (max-width: 320px) {
		flex-direction: column;
	}
`;
export const ColImgMobile = styled.div`
	img {
		width: 94px;
		height: 99px;
		margin-right: 17px;
		object-fit: cover;
	}
`;
export const ColTitleAndStar = styled.div``;
export const ColRemove = styled.div`
	display: flex;
	justify-content: flex-end;
	span {
		color: #2b63fc;
		font-size: 14px;
		text-decoration: underline;
		white-space: nowrap;
	}
`;
export const ColPrice = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	/* align-items: baseline; */
	color: #20256c;
	.row-price {
		display: flex;
		flex-direction: row;
		align-items: baseline;
	}
	h2 {
		white-space: nowrap;
		font-size: 26px;
	}
	.small-text-mobile {
		margin-left: -12%;
		font-size: 20px;
	}
	.row {
		p {
			color: #353a40;
			font-size: 14px;
			margin-top: 0px;
			width: 185px;
		}
	}
`;
export const ColDetails = styled.div`
	font-family: var(--Roboto-font-family);
	display: flex;
	flex-direction: column;
	.stars {
		svg {
			width: 17px;
			height: 16px;
			fill: #f49d37;
			/* margin-right: 6px; */
		}
		span {
			color: #8a99a8;
			font-size: 14px;
		}
	}
	a {
		text-decoration: none;
		h4 {
			color: #353a40;
			text-decoration: none;
			font-size: 20px;
			font-weight: normal;
			max-width: 210px;
			max-height: 50px;
			overflow: hidden;
		}
	}

	span {
		color: #8a99a8;
		font-size: 16px;
	}
`;
export const Container = styled.div`
	@media (max-width: 768px) {
		display: none;
	}
	max-width: 1184px;
	max-height: 245px;
	padding: 30px 40px 25px 50px;
	font-family: var(--Roboto-font-family);
	background-color: #ffffff;
	border-radius: 8px;
	@media (max-width: 768px) {
		max-width: 90%;
		min-width: 90%;
	}

	img {
		width: 140px;
		height: 140px;
		margin-right: 40px;
		object-fit: cover;
	}
`;
export const InputsRow = styled.div`
	display: flex;
	flex-direction: row;
`;
export const RowItem = styled.div`
	display: flex;
	flex-direction: column;
	a {
		text-decoration: none;
		h4 {
			color: #353a40;
			text-decoration: none;
			font-size: 20px;
			font-weight: normal;
		}
	}

	span {
		color: #8a99a8;
		font-size: 16px;
	}
	h2 {
		padding: 0 !important;
		margin-top: 35px;
		color: #20256c;
		font-size: 26px;
		font-weight: normal;
	}
	.small-text {
		font-size: 18px;
		margin-top: 40px;
	}
	p {
		color: #353a40;
		font-size: 14px;
	}
	.bottom-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		span {
			color: #2b63fc;
			cursor: pointer;
		}
		.stars {
			svg {
				width: 17px;
				height: 16px;
				fill: #f49d37;
				/* margin-right: 6px; */
			}
			span {
				color: #8a99a8;
				font-size: 14px;
			}
		}
	}
`;

export const DefaultRow = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	align-items: center;
`;

export const MoreColors = styled.label`
	font-family: var(--Roboto-font-family);
	padding: 6px 10px;
	width: 128px;
	background-color: #999999;
	border-radius: 8px;
	font-size: 12px;
	font-weight: medium;
	letter-spacing: 0px;
	text-align: center;
	color: #ffffff;
	cursor: pointer;
	margin-top: 10px;
`;
