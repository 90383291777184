import React, { useEffect, useState } from 'react';
import { Container, PageContainer, AlignmentContainer } from './styles';

import { useHistory } from 'react-router-dom';
import ShippingAddressCart from './ShippingAddressCart/ShippingAddressCart';
import ShippingMethodItemCart from './ShippingMethodItemCart/ShippingMethodItemCart';
import { useSelector } from 'react-redux';
import ProductsCart from '../../../interfaces/ProductsCart';
import { AppState } from '../../../store';
import { calculateProductCartValue } from '../../../utils/CalculateProductCartValue';
import CartReviewCard from './CartReviewCard/CartReviewCard';
import CartReviewCardMobile from './CartReviewCard/CartReviewMobile/ReviewContainerMobile';

const ShippingMethodCart: React.FC = () => {
	const history = useHistory();

	const [productsValue, setProductsValue] = useState<string>('');

	const productsCart: ProductsCart[] = useSelector(
		(state: AppState) => state.productsCart.currentCart
	);
	/* const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	); */

	useEffect(() => {
		setProductsValue(
			calculateProductCartValue(productsCart).toLocaleString('pt-br', {
				style: 'currency',
				currency: 'BRL',
			})
		);
	}, [productsCart]);

	return (
		<PageContainer>
			<AlignmentContainer>
				<Container>
					<h1>Finalização de compra</h1>

					<div className="informations-container">
						<div className="main-card">
							<ShippingMethodItemCart />
							{/* <ShippingAddressCart /> */}
						</div>
						<CartReviewCardMobile
							backButtonPath={`/cart/${'shipping'}`}
							nextButtonPath={`/cart/order-confirmation/${'confirmation'}`}
						/>
						{/* <CartReviewCard 
							backButtonPath={`/cart/${'shipping'}`}
							nextButtonPath={`/cart/PaymentCartMethod/${'shipping'}`}/> */}
					</div>
				</Container>
			</AlignmentContainer>
		</PageContainer>
	);
};

export default ShippingMethodCart;
