import { UserAction, UserState } from '../interfaces/UserAccount';

const initialState: UserState = {
	currentUser: undefined,
};

export default function UserReducer(state = initialState, action: UserAction) {
	switch (action.type) {
		case 'INSERT_USER':
			return { ...state, currentUser: action.user };

		case 'REMOVE_USER':
			return { ...state, currentUser: undefined };

		case 'UPDATE_PHOTO_NAME':
			return {
				...state,
				currentUser: {
					...state.currentUser,
					user_photo: {
						...state.currentUser?.user_photo,
						name: action.photoName,
					},
				},
			};
		case 'INSERT_ADDRESS':
			return {
				...state,
				currentUser: {
					...state.currentUser,
					Address: [...state.currentUser!.Address!, action.address],
				},
			};
		case 'UPDATE_ADDRESS':
			return {
				...state,
				currentUser: {
					...state.currentUser,
					Address: [
						...state.currentUser!.Address!.filter(
							(address) => address.id !== action.address.id
						),
						action.address,
					],
				}
			}
		case 'REMOVE_ADDRESS':
			return {
				...state,
				currentUser: {
					...state.currentUser,
					Address: [
						...state.currentUser!.Address!.filter(
							(address) => address.id !== action.address_id
						),
					],
				},
			};
		case 'SET_PAYMENT_METHOD':
			return {
				...state,
				currentUser: {
					...state.currentUser,
					payment_method: action.paymentMethod,
				},
			};
		case 'INSERT_USER_CARD':
			if (state.currentUser?.user_cards.length === 0) {
				state.currentUser.user_cards = [];
			}
			return {
				...state,
				currentUser: {
					...state.currentUser,
					user_cards: [
						...state.currentUser?.user_cards!,
						action.card,
					],
				},
			};
		case 'REMOVE_USER_CARD':
			return {
				...state,
				currentUser: {
					...state.currentUser,
					user_cards: state.currentUser?.user_cards!.filter(
						(card) => card.id !== action.card_id
					)!,
				},
			};
			case 'INSERT_USER_FAVORITE':
				if (state.currentUser?.user_cards.length === 0) {
					state.currentUser.user_cards = [];
				}
				return {
					...state,
					currentUser: {
						...state.currentUser,
						Favorites: [
							...state.currentUser?.Favorites!,
							action.favorite,
						],
					},
				};
			case 'REMOVE_USER_FAVORITE':
				return {
					...state,
					currentUser: {
						...state.currentUser,
						Favorites: state.currentUser?.Favorites!.filter(
							(fav) => fav.id !== action.favorite_id
						)!,
					},
				};
		default:
			return state;
	}
}
