import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { insertMobileHeader } from '../../actions/MobileHeaderActions';
import Header from '../../components/Default/header';
import MyPurchases from '../../components/MyPurchases/MyPurchases';
import { SearchPreset } from '../../interfaces/MobileHeader';
import { Container } from './styles';

// import { Container } from './styles';

const MyPurchasesPage: React.FC = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(insertMobileHeader(SearchPreset))
	});
    
    return (
        <Container>
            <Header />
            <MyPurchases />
        </Container>
    )
}

export default MyPurchasesPage;