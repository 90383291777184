import config from '../config';
import { Product } from '../interfaces/Product';
import { ProductVariations } from '../interfaces/ProductVariations';
import placeholder_product from '../assets/placeHolders/placeholder_product.png';
import { reOrderProductVariations } from './reOrderProduct';

export const getProductImageByName = (name: string) => {
	return `${config.host}/static/products/${name}`;
}

export const getFristImageSource = (product: Product) => {
	console.log({product})

	if (product.product_photos && product.product_photos[0]) {
		return `${config.host}/static/products/${product.product_photos[0].name}`;
	} else if (
		product.product_variations &&
		product.product_variations.length > 0 &&
		product.product_variations[0].variation_photos[0]
	) {
		let orderedVariation = reOrderProductVariations(
			product.product_variations,
			false
		);
		return `${config.host}/static/products/${orderedVariation[0].variation_photos[0].name}`;
	} else {
		//put a place-holder/not-found image later
		return placeholder_product;
	}
};

export const getFristVariationImageSource = (variation: ProductVariations) => {
	if (variation.variation_photos && variation.variation_photos[0]) {
		return `${config.host}/static/products/${variation.variation_photos[0].name}`;
	} else {
		//put a place-holder/not-found image later
		return placeholder_product;
	}
};
