import React, { useEffect, useState } from 'react';
import { CgSmileSad } from 'react-icons/cg';
import { useSelector, useDispatch } from 'react-redux';
import { insertProducts } from '../../actions/ProductsList';
import api from '../../api';
import { IFavorite } from '../../interfaces/Favorites';
import { Product } from '../../interfaces/Product';
import { ProductsList, AreaTypes } from '../../interfaces/ProductsList';
import UserAccount from '../../interfaces/UserAccount';
import { AppState } from '../../store';
import { productExibitionRules } from '../../utils/productsExibitionRules';
import NotificationCard from './NotificationCard/NotificationCard';

import { Container, NoFavorites, PageContainer } from './styles';

const SellerNotification: React.FC = () => {
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);

	const [messageIsVisible, setVisibleMessage] = useState<boolean>(false);

	const [notifications, setNotifications] = useState<any[]>([]);

	useEffect(() => {
		const fetch = async () => {
			const response = await api.get('/notifications', {
				params: {
					user_id: user.id,
				}
			});

			setNotifications(response.data.data.notifications);
		}

		fetch();
	}, [])

	return (
		<PageContainer>
			<h1>Notificações</h1>
			<Container>
				{notifications.map((notification: any) => (
					<NotificationCard
						key={notification.id}
						notification={notification}
					/>
				))}
				{notifications.length <= 0 && 
					<NoFavorites>
						<div className="emoji-and-text">
							<CgSmileSad color="#2B63FC" className="emoji" />
							<h2>Você ainda não possuí nenhuma notificação</h2>
						</div>
					</NoFavorites>
				}
			</Container>
		</PageContainer>
	);
};

export default SellerNotification;
