import ProductsCart from '../interfaces/ProductsCart';
import { getProductPrice } from './GetProductPrice';

export const calculateProductCartValue = (cart: ProductsCart[], user?: any) => {
	let value: number = 0;
	cart?.forEach((item) => {
		console.log({item: item.parameters.product, user})
		value =
			value + getProductPrice(item.parameters.product, user) * item.parameters.quantity;
	});
	return value;
};
export const calculateShippingCartValue = (cart: ProductsCart[]) => {
	let value: number = 0;
	cart?.forEach((item) => {
		if (
			item.parameters.shippingValue &&
			Number(
				item.parameters.shippingValue.replace('.', '').replace(',', '.')
			)
		) {
			value =
				value +
				parseFloat(
					item.parameters.shippingValue
						.replace('.', '')
						.replace(',', '.')
				);
		}
	});
	return value;
};
