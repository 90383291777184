import React from 'react';

import { Container } from './styles';

type Props = {
	logo?: any;
	title: string;
	description: string;
	flex: number;
	pWidth: number;
	maxWidth: number;
};

const JoinUsItem: React.FC<Props> = ({ ...props }) => {
	return (
		<Container flex={props.flex} maxWidth={props.maxWidth}>
			<img src={props.logo} onDragStart={() => false} />
			<h3>{props.title}</h3>
			<p style={{ width: `${props.pWidth}px` }}>{props.description}</p>
		</Container>
	);
};

export default JoinUsItem;
