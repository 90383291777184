import { ProductActions, ProductState } from '../interfaces/Product';
import {
	ShopCustomizationsActions,
	UserCustomizationsState,
} from '../interfaces/ShopCustomizations';

const initialState: UserCustomizationsState = {
	currentShopCustomizations: undefined,
};

export default function ShopCustomizationsReducer(
	state = initialState,
	action: ShopCustomizationsActions
) {
	switch (action.type) {
		case 'INSERT_CUSTOMIZATIONS':
			return {
				...state,
				currentShopCustomizations: action.shopCustomizations,
			};

		case 'INSERT_COVER_PHOTO':
			return {
				...state,
				currentShopCustomizations: {
					...state.currentShopCustomizations,
					shop_cover_photo: action.coverPhoto,
				},
			};

		case 'INSERT_PROFILE_PHOTO':
			return {
				...state,
				currentShopCustomizations: {
					...state.currentShopCustomizations,
					shop_profile_photo: action.profilePhoto,
				},
			};

		case 'INSERT_NAME':
			return {
				...state,
				currentShopCustomizations: {
					...state.currentShopCustomizations,
					shop_name: action.name,
				},
			};

		case 'INSERT_DESCRIPTION':
			return {
				...state,
				currentShopCustomizations: {
					...state.currentShopCustomizations,
					description: action.description,
				},
			};

		default:
			return state;
	}
}
