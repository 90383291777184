import React, { useEffect, useRef, useState } from 'react';
import {
	Container,
	InfosContainer,
	PhotosContainer,
	SideImgContainer,
	TitleContainer,
} from './styles';

import { Link, useHistory } from 'react-router-dom';
import config from '../../../config';
import { RiStarFill, RiStarHalfFill, RiStarLine } from 'react-icons/ri';
import { Product, ProductPhotos } from '../../../interfaces/Product';
import { IProduct } from '../../../Pages/ProductPage/ProductPage';
import { useDispatch, useSelector } from 'react-redux';
import UserAccount, { UserTypeEnum } from '../../../interfaces/UserAccount';
import { AppState } from '../../../store';
import PhotosModal, { PhotosModalHandles } from './PhotosModal/PhotosModal';
import {
	getFristImageSource,
	getFristVariationImageSource,
	getProductImageByName,
} from '../../../utils/GetProductImage';
import {
	ProductVariations,
	VariationPhotos,
} from '../../../interfaces/ProductVariations';
import FavoriteIcon from '../../Default/FavoriteIcon/FavoriteIcon';
import { getProductPrice } from '../../../utils/GetProductPrice';

type Props = {
	product: IProduct;
	selectedVariation?: ProductVariations;
};

interface ProductImage {
	id: string;
	name: string;
}

const ProductInfos: React.FC<Props> = ({ product, selectedVariation }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [selectedImage, setSelectedImage] = useState<ProductImage>(
		//arrumar, fix
		product.product_photos![0]
	);

	const [ratingAverage, setRatingAverage] = useState<number>(0);

	useEffect(() => {
		if(!product.product_reviews) { setRatingAverage(0); return; }

		const rating = product.product_reviews.reduce(
			(accumulator, currentValue) => accumulator + currentValue.rating,
			0
		);

			console.log({product})

			console.log({selectedVariation})

		setRatingAverage(rating / product.product_reviews.length);
		setSelectedImage(
			selectedVariation
				? (selectedVariation.variation_photos[0])
				: (product.product_photos![0])
		)
	}, [product, selectedVariation])

	const modalRef = useRef<PhotosModalHandles>(null);
	return (
		<>
			<Container>
				<PhotosModal
					ref={modalRef}
					images={
						selectedVariation
							? (selectedVariation.variation_photos as ProductImage[])
							: (product.product_photos! as ProductImage[])
					}
					selectedImage={selectedImage}
				/>

				{!selectedVariation && product.product_photos!.length > 1 && (
					<div className="bottom-images">
						{product.product_photos!.map((photo, index) => {
							return (
								<SideImgContainer>
									<img
										alt="imagem do produto"
										src={`${config.host}/static/products/${photo.name}`}
										onClick={() => {
											setSelectedImage(
												product.product_photos![index]
											);
										}}
									/>
								</SideImgContainer>
							);
						})}
					</div>
				)}

				{selectedVariation &&
					selectedVariation.variation_photos!.length > 1 && (
						<div className="bottom-images">
							{selectedVariation.variation_photos!.map(
								(photo: any, index: any) => {
									return (
										<SideImgContainer>
											<img
												alt="imagem do produto"
												src={`${config.host}/static/products/${photo.name}`}
												onClick={() => {
													setSelectedImage(
														selectedVariation.variation_photos![
															index
														]
													);
												}}
											/>
										</SideImgContainer>
									);
								}
							)}
						</div>
					)}
				<img
					src={`${selectedImage ? getProductImageByName(selectedImage.name) : ''}`}
					id="image"
					alt="imagem do produto"
					onClick={() => {
						modalRef.current?.handleOpenModal();
					}}
				/>

				<InfosContainer>
					<header>
						<TitleContainer>
							<h1> {product?.title} </h1>
						</TitleContainer>
						{!product.showcase && (
							<div className="starsRow">
								{[...Array(5)].map((star, index) => (
									index + 1 <= ratingAverage ? 
										<RiStarFill/> 
										:
										<RiStarLine/>
								))}
								<div className="rowReview">
									<p>{ product.product_reviews ? product.product_reviews.length : 0 } opiniõe(s)</p>
								</div>
							</div>
						)}
						
					</header>
					{!product.showcase && (
						<>
							<div className="priceRow">
								<div className="price-container">
									<div className="price">
										<h3>
											{'R$ ' +
												String(getProductPrice(product, user))
													.replace('.', ',')
													.substring(
														0,
														getProductPrice(product, user).toString()
															.length - 2
													)}
										</h3>
										<h3 className="small-text">
											{String(getProductPrice(product, user))
												.replace('.', ',')
												.substring(
													getProductPrice(product, user).toString().length - 2,
													getProductPrice(product, user).toString().length
												)}
										</h3>
									</div>
								</div>
							</div>

							{parseInt(product.max_installments) > 0 ? (
								<span>
									Em até {product.max_installments}x sem juros
								</span>
							) : (
								<span>
									Á vista
								</span>
							)}

							<div className="details-row">
								<div className="details-column">
									<span className="details-placeholder">Marca:</span>
									<span className="details-placeholder">Modelo:</span>
									<span className="details-placeholder">
										Material:
									</span>
								</div>
								<div className="details-column">
									<span className="details-info">
										{product.brand !== '' &&
										product.brand !== 'undefined'
											? product.brand
											: 'N/A'}
									</span>
									<span className="details-info">
										{product.model !== '' && product.model
											? product.model
											: 'N/A'}
									</span>
									<span className="details-info">
										{product.material !== '' &&
										product.material !== 'undefined'
											? product.material
											: 'N/A'}
									</span>
								</div>
							</div>
						</>
					)}
				</InfosContainer>
			</Container>
		</>
	);
};

export default ProductInfos;
