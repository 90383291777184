export function currency(e: React.FormEvent<HTMLInputElement>) {
	let value = e.currentTarget.value;

	value = value.replace(/\D/g, '');
	value = value.replace(/(\d)(\d{2})$/, '$1,$2');
	value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

	e.currentTarget.value = value;
	return e;
}

export function currencyDefault(e: number) {
	let value = e.toFixed(2).toString();
	value = value.replace(/\D/g, '');
	value = value.replace(/(\d)(\d{2})$/, '$1,$2');
	value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
	return value;
}

export function cepDefault(e: string) {
	let value = e;
	value = value.replace(/\D/g, '');
	value = value.replace(/(\d{5})(\d)/, '$1-$2');

	e = value;
	return e;
}

export function cep(e: React.FormEvent<HTMLInputElement>) {
	e.currentTarget.maxLength = 9;
	let value = e.currentTarget.value;
	value = value.replace(/\D/g, '');
	value = value.replace(/(\d{5})(\d)/, '$1-$2');

	e.currentTarget.value = value;
	return e;
}

export function number(e: React.FormEvent<HTMLInputElement>) {
	let value = e.currentTarget.value;
	value = value.replace(/\D/g, '');
	value = value.replace(/^0/, '');

	if (value.length > 10) {
		value = value.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
	} else if (value.length > 5) {
		value = value.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3');
	} else if (value.length > 2) {
		value = value.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
	} else {
		value = value.replace(/^(\d*)/, '($1');
	}

	e.currentTarget.value = value;
	return e;
}
export function cardNumberMask(value: string) {
	return value
		.replace(/\D+/g, '')
		.replace(/(\d{4})(\d)/, '$1 $2')
		.replace(/(\d{4})(\d)/, '$1 $2')
		.replace(/(\d{4})(\d{1,2})/, '$1 $2')
		.replace(/(\d{4})(\d{1,2})/, '$1 $2');
}
export function cardExpirationDate(value: string) {
	return value.replace(/\D+/g, '').replace(/(\d{2})(\d)/, '$1/$2');
}
export function cpfMask(value: string) {
	return value
		.replace(/\D+/g, '')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d{1,2})/, '$1-$2');
}
export function cnpjMask(value: string) {
	return value
		.replace(/\D+/g, '')
		.replace(/(\d{2})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1/$2')
		.replace(/(\d{4})(\d{1,2})/, '$1-$2');
}
export function phoneNumberMask(value: string) {
	return value
		.replace(/\D/g, '')
		.replace(/\D+/g, '')
		.replace(/(\d{2})(\d)/, '($1) $2')
		.replace(/(\d{4})(\d)/, '$1-$2')
		.replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
		.replace(/(-\d{4})\d+?$/, '$1');
}
export function zipCodeMask(value: string) {
	return value
		.replace(/\D+/g, '')
		.replace(/(\d{5})(\d)/, '$1-$2')
		.replace(/(-\d{3})\d+?$/, '$1');
}
