import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { RiStarFill, RiStarLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { insertCustomizations } from '../../../../actions/ShopCustomizationsActions';
import api from '../../../../api';
import config from '../../../../config';
import { ShopCustomizations } from '../../../../interfaces/ShopCustomizations';
import UserAccount from '../../../../interfaces/UserAccount';
import { AppState } from '../../../../store';
import { ShopQueryReturn } from '../../../SellerStore/SellerStore';

import {
	Container,
	TopDiv,
	BottomDiv,
	ReputationBar,
	ReputationRow,
	ReputationInfos,
} from './styles';

interface PageParams {
	userId: string;
}

const SellerReputationCardVertical: React.FC = () => {
	const history = useHistory();
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const shopCustomizations: ShopCustomizations = useSelector(
		(state: AppState) => state.shopCustomizations.currentShopCustomizations
	);
	const dispatch = useDispatch();
	const pageParams = useParams<PageParams>();
	const [info, setInfo] = useState<any | undefined>(undefined);
	useEffect(() => {
		api.get('/get-shop-customizations', {
			params: {
				user_account_id: pageParams.userId,
			},
		}).then((response: AxiosResponse<ShopQueryReturn>) => {
			dispatch(insertCustomizations(response.data.shop[0]));
		});
	}, []);

	useEffect(() => {
		api.get('/get-shop-info', {
			params: {
				shop_id: pageParams.userId,
			}
		}).then((response: AxiosResponse) => {
			setInfo(response.data.data);
		})
	}, [])

	return (
		<Container>
			<TopDiv>
				<span>{shopCustomizations?.shop_name}</span>
				<button
					id="edit-store"
					onClick={() => history.push(`/my-store/${user.id}`)}
				>
					Editar loja
				</button>
			</TopDiv>
			<BottomDiv>
				<h4>Reputação </h4>
				<div className='stars'>
					{[...Array(5)].map((star, index) => (
						index + 1 <= (info?.reviews_average || 0) ? 
							<RiStarFill/> 
							:
							<RiStarLine/>
					))}
				</div>
				<ReputationInfos>
					<div className="item-grid" style={{ textAlign: 'center' }}>
						<span>{info?.total_sells || 0}</span>
					</div>
					<div className="item-grid">Vendas finalizadas</div>
					<div className="item-grid" style={{ textAlign: 'center' }}>
						<span>{info?.total_reviews || 0}</span>
					</div>
					<div className="item-grid">Avaliações</div>
				</ReputationInfos>
			</BottomDiv>
		</Container>
	);
};

export default SellerReputationCardVertical;
