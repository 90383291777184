import React, { useEffect, useState } from 'react';

import {
	Container,
	StoreHeader,
	Banner,
	UserPhoto,
	Card,
	LeftDiv,
	ReputationBar,
	ReputationInfos,
	ReputationRow,
	RightDiv,
	StoreName,
	EditBannerButtonContainer,
} from './styles';
import imagem from '../../../assets/imagem.jpg';
import { ReactComponent as EditIcon } from '../../../assets/icons/editIcon.svg';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import UserAccount from '../../../interfaces/UserAccount';
import { AppState } from '../../../store';
import { ShopCustomizations } from '../../../interfaces/ShopCustomizations';
import {
	insertCoverPhoto,
	insertDescription,
	insertName,
	insertProfilePhoto,
} from '../../../actions/ShopCustomizationsActions';
import api from '../../../api';
import { NONAME } from 'dns';
import config from '../../../config';

interface PageParams {
	userId: string;
}
interface FilesUpload {
	toEdit: string;
	file: any;
}
const SellerStoreHeader: React.FC = () => {
	const [isEditing, setEditing] = useState({
		name: false,
		description: false,
	});
	const [file, setFile] = useState<FilesUpload>({
		toEdit: '',
		file: undefined,
	});
	const pageParams = useParams<PageParams>();

	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const shopCustomizations: ShopCustomizations = useSelector(
		(state: AppState) => state.shopCustomizations.currentShopCustomizations
	);
	const dispacth = useDispatch();

	const changeCustomizationTexts = () => {
		api.post('/edit-shop', {
			name: shopCustomizations.shop_name,
			description: shopCustomizations.description,
			shop_id: shopCustomizations.id,
		});
	};

	const handleCreateImage = () => {
		if (!file.file) return;
		const newData = new FormData();
		newData.append('image', file.file);
		newData.append('toEdit', file.toEdit);
		newData.append('shop_id', shopCustomizations.id);

		api.post('/edit-shop-image', newData).then((response) => {
			if (file.toEdit === 'profile_shop_image') {
				dispacth(insertProfilePhoto(response.data.image));
			} else {
				dispacth(insertCoverPhoto(response.data.image));
			}
		});
	};

	useEffect(() => {
		if (file.file) {
			handleCreateImage();
		}
	}, [file]);

	return (
		<Container>
			<StoreHeader>
				<Banner
					imagem={
						shopCustomizations?.shop_cover_photo
							? `${config.host}/static/shopImages/${shopCustomizations?.shop_cover_photo}`
							: '#004D93'
					}
				>
					<EditBannerButtonContainer>
						{' '}
						{user?.id === pageParams.userId && (
							<>
								<div className="edit-button">
									<EditIcon fill="white" />{' '}
									<label htmlFor="coverImage">
										<p className="edit-text">
											Editar foto de capa
										</p>{' '}
									</label>
									<input
										type="file"
										id="coverImage"
										style={{ display: 'none' }}
										onChange={(e: any) =>
											setFile({
												file: e.target.files[0],
												toEdit: 'cover_shop_image',
											})
										}
									/>{' '}
								</div>
							</>
						)}
					</EditBannerButtonContainer>
					<UserPhoto
						image={
							shopCustomizations?.shop_profile_photo
								? `url(${config.host}/static/shopImages/${shopCustomizations?.shop_profile_photo}) no-repeat`
								: '#707070'
						}
					>
						{user?.id === pageParams.userId && (
							<label htmlFor="cover-image-upload-input">
								<div className="overlay">
									<EditIcon fill="white" />

									<p className="text">Editar foto</p>
								</div>
							</label>
						)}
						<input
							type="file"
							id="cover-image-upload-input"
							onChange={(e: any) =>
								setFile({
									file: e.target.files[0],
									toEdit: 'profile_shop_image',
								})
							}
							style={{ display: 'none' }}
						/>
					</UserPhoto>
				</Banner>
				<Card>
					<LeftDiv>
						<StoreName>
							{!isEditing.name && (
								<span>
									{shopCustomizations?.shop_name
										? shopCustomizations?.shop_name
										: 'Personalize o nome de sua loja'}
									{user?.id === pageParams.userId && (
										<EditIcon
											className="icon"
											fill="white"
											onClick={() =>
												setEditing({
													...isEditing,
													name: true,
												})
											}
										/>
									)}
								</span>
							)}
							{isEditing.name && (
								<div className="edit-input-container">
									<input
										type="text"
										value={shopCustomizations.shop_name}
										onChange={(e) =>
											dispacth(insertName(e.target.value))
										}
										className={`${
											isEditing.name ? 'edit' : 'false'
										}`}
									/>
									{user?.id === pageParams.userId && (
										<EditIcon
											className="icon"
											fill="white"
											onClick={() => {
												setEditing({
													...isEditing,
													name: false,
												});

												changeCustomizationTexts();
											}}
										/>
									)}
								</div>
							)}
						</StoreName>
						<p>Vendedor adequado</p>
						<ReputationBar />
					</LeftDiv>
					<RightDiv
						isVisible={`${
							!isEditing.description ? 'hidden' : 'visible'
						}`}
					>
						{!isEditing.description && (
							<p>{shopCustomizations?.description}</p>
						)}

						{isEditing.description && (
							<textarea
								className={`${
									isEditing.description ? 'edit' : 'false'
								}`}
								defaultValue={shopCustomizations?.description}
								onChange={(e) =>
									dispacth(insertDescription(e.target.value))
								}
							/>
						)}
						{user?.id === pageParams.userId && (
							<div className="isMobile">

							<div className="icon-containerMobile">
								<div className="buttonEditDescricaoMobile">
								<EditIcon
									className="icon"
									fill="white"
									onClick={() => {
										setEditing({
											...isEditing,
											description: !isEditing.description,
										});
										changeCustomizationTexts();
									}}
									/>
									<p>Editar descrição</p>
								</div>
							</div>
							<div className="icon-container">
								<EditIcon
									className="icon"
									fill="white"
									onClick={() => {
										setEditing({
											...isEditing,
											description: !isEditing.description,
										});
										changeCustomizationTexts();
									}}
									/>
							</div>
							</div>
						)}
					</RightDiv>
				</Card>
			</StoreHeader>
		</Container>
	);
};

export default SellerStoreHeader;
