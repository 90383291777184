import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import api from '../../../api';
import { IoIosArrowDown } from 'react-icons/io';
import { RiSearch2Line } from 'react-icons/ri';
import { HiBell } from 'react-icons/hi';
import { FaHeart } from 'react-icons/fa';
import { RiShoppingCart2Fill } from 'react-icons/ri';
import { FaUserCircle } from 'react-icons/fa';
import { RiStore3Fill } from 'react-icons/ri';
import { RiLogoutBoxFill } from 'react-icons/ri';
import UserImg from '../UserImg/UserImg';
import { useDispatch, useSelector } from 'react-redux';
import { removeUser } from '../../../actions/UserActions';
import config from '../../../config';
import UserAccount, { UserTypeEnum } from '../../../interfaces/UserAccount';
import { AppState } from '../../../store';
import { AxiosResponse } from 'axios';
import { ProductCategories } from '../../ProductRegister/ProductRegister';
import NotificationsCard from './NotificationsCard/NotificationsCard';
import { ReactComponent as LogoAcp } from '../../../assets/icons/acpLogoLogin.svg';
import ACPlace_reduzido from '../../../assets/acpLogo/ACPlace_reduzido.png';
import acpLogo from '../../../assets/acpLogo.png';

import {
	BottomHeader,
	BottomHeaderButtons,
	Container,
	AlignmentContainer,
	DefaultColumn,
	MenuContainer,
	Search,
	SearchBox,
	SearchIcon,
	SerchRow,
	TopHeader,
	ButtonIconContainer,
	DropDownCard,
	DropDownItem,
	ContainerColumn,
	TopMargin,
	MobileLogoBox,
	NotificationsContainer,
} from './styles';
import StoreDropDown from './StoreDropDown/StoreDropDown';
import MobileMenu from './MobileMenu/MobileMenu';
import MobileLeftComponent from './MobileLeftComponent/MobileLeftComponent';
import MobileHeader from '../../../interfaces/MobileHeader';
import useCheckMobileScreen from '../../../utils/useCheckMobileScreen';
import CategoryDropDown from './CategoryDropDown/CategoryDropDown';
import { clearCart } from '../../../actions/ProductsCartActions';

const AuthHeader: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [searchQuery, setSearchQuery] = useState('');
	const [showMenu, setShowMenu] = useState(false);
	const [showCategories, setShowCategories] = useState(false);
	const [showStores, setShowStores] = useState(false);
	const [notificationsIsVisible, setNotificationVisibility] = useState(false);
	const [
		productCategories,
		setProductCategories,
	] = useState<ProductCategories>();
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const mobileHeader: MobileHeader = useSelector(
		(state: AppState) => state.mobileHeader.currentMobHeader
	);
	useEffect(() => {
		api.get('/get-product-categories')
			.then((response: AxiosResponse<ProductCategories>) => {
				setProductCategories(response.data);
			})
			.catch((err) => {});
	}, []);
	return (
		<>
			<TopMargin />
			<Container
				onKeyPress={(e) => {
					if (
						e.nativeEvent.key === 'Enter' &&
						searchQuery.length > 0
					) {
						history.push(
							`/search/${searchQuery}/query/all/0/100000000`
						);
					}
				}}
			>
				<AlignmentContainer>
					<ContainerColumn>
						<MobileLeftComponent />
						<TopHeader>
							{/* keep logo ratio (280X74) */}
							<img
								className="hide-mobile"
								alt="Logo Acp"
								width={200}
								height={52.857}
								style={{
									marginRight: '56px',
									marginTop: '6px',
									cursor: 'pointer',
								}}
								src={ACPlace_reduzido}
								onClick={() => history.push('/seller')}
							/>
							<DefaultColumn>
								{mobileHeader.middleComp.middle === 1 && (
									<MobileLogoBox>
										<img
											alt="Logo Acp"
											width={102}
											height={30}
											style={{
												marginTop: '8px',
												cursor: 'pointer',
											}}
											src={acpLogo}
											onClick={() => history.push('/seller')}
										/>
									</MobileLogoBox>
								)}
								{(!useCheckMobileScreen() ||
									mobileHeader.middleComp.middle === 0) && (
									<SearchBox>
										<SerchRow>
											<SearchIcon>
												{' '}
												<RiSearch2Line
													style={{ fill: '#2d327c' }}
													onClick={(e) => {
														if (
															searchQuery.length >
															0
														) {
															history.push(
																`/search/${searchQuery}/query/all/0/100000000`
															);
															e.preventDefault();
														}
													}}
												/>{' '}
											</SearchIcon>
											<Search
												type="text"
												onChange={(e) =>
													setSearchQuery(
														e.target.value
													)
												}
												/* placeholder="Buscar produtos, marcas e muito mais..." */
												placeholder="Quero comprar..."
											></Search>
										</SerchRow>
									</SearchBox>
								)}
								<BottomHeader>
									<ButtonIconContainer
										className={
											showCategories ? 'active' : ''
										}
										onMouseLeave={() =>
											setShowCategories(false)
										}
										onClick={() =>
											setShowCategories(true)
										}
									>
										<BottomHeaderButtons
											className={
												showCategories
													? 'active-text have-icon'
													: 'have-icon'
											}
										>
											Categorias
										</BottomHeaderButtons>
										<IoIosArrowDown className="header-icon" />
										{showCategories && (
											<DropDownCard
												onMouseLeave={() =>
													setShowCategories(false)
												}
												onMouseEnter={() =>
													setShowCategories(true)
												}
											>
												<CategoryDropDown/>
											</DropDownCard>
										)}
									</ButtonIconContainer>
									<ButtonIconContainer
										className={showStores ? 'active' : ''}
										onMouseLeave={() =>
											setShowStores(false)
										}
										onClick={() => setShowStores(true)}
									>
										<BottomHeaderButtons
											className={
												showStores
													? 'active-text have-icon'
													: 'have-icon'
											}
										>
											Lojas associadas
										</BottomHeaderButtons>
										<IoIosArrowDown className="header-icon" />
										{showStores && (
											<DropDownCard
												onMouseLeave={() =>
													setShowStores(false)
												}
												onMouseEnter={() =>
													setShowStores(true)
												}
											>
												<StoreDropDown />
											</DropDownCard>
										)}
									</ButtonIconContainer>

									<BottomHeaderButtons
										onClick={() =>
											history.push('/about-us')
										}
									>
										Quem somos
									</BottomHeaderButtons>
									<BottomHeaderButtons
										onClick={() => history.push('/join-us')}
									>
										Associe-se
									</BottomHeaderButtons>
								</BottomHeader>
							</DefaultColumn>
							<div className="icons">
								<NotificationsContainer
									onMouseEnter={() =>
										setNotificationVisibility(true)
									}
									onMouseLeave={() =>
										setNotificationVisibility(false)
									}
								>
									<HiBell
										style={{ fill: '#2D327C' }}
										onClick={() =>
											history.push(`/notification`)
										}
									/>
									{notificationsIsVisible && (
										<NotificationsCard
											onMouseLeave={() =>
												setNotificationVisibility(false)
											}
										/>
									)}
								</NotificationsContainer>
								{user.user_types.name ===
									UserTypeEnum.customer && (
									<>
										<FaHeart
											style={{ fill: '#2D327C' }}
											onClick={() =>
												history.push('/favorite')
											}
										/>
										<RiShoppingCart2Fill
											style={{ fill: '#2D327C' }}
											onClick={() =>
												history.push(
													`/cart/${'header'}`
												)
											}
										/>
									</>
								)}
								{user.user_types.name ===
									UserTypeEnum.seller && (
									<RiStore3Fill
										style={{ fill: '#2D327C' }}
										onClick={() =>
											history.push(
												`/seller-area/${user?.id}/all/0/100000000/undefined/products`
												
											)
										}
									/>
								)}
								{showMenu && (
									<MenuContainer
										onMouseLeave={() => setShowMenu(false)}
										onMouseEnter={() => setShowMenu(true)}
									>
										<div
											className="menu"
											style={{
												height:
													user.user_types.name ===
													UserTypeEnum.seller
														? '161px'
														: user.user_types
																.name ===
														  UserTypeEnum.administrator
														? '121px'
														: '',
											}}
										>
											{user.user_types.name !==
											UserTypeEnum.administrator ? (
												<div
													className="menu-item"
													onClick={() =>
														history.push(
															'/profile/user-information'
														)
													}
												>
													<FaUserCircle
														style={{
															fill: '#2B63FC',
														}}
													/>
													<span>Meu Perfil</span>
												</div>
											) : (
												<div className="menu-item">
													<RiStore3Fill
														style={{
															fill: '#2B63FC',
														}}
													/>
													<span
														onClick={() =>
															history.push(
																'/admin/welcome'
															)
														}
													>
														Painel de administração
													</span>
												</div>
											)}
											{user.user_types.name ===
												UserTypeEnum.customer && (
												<>
													<div
														className="menu-item"
														onClick={() =>
															history.push(
																`/cart/${'header'}`
															)
														}
													>
														<RiShoppingCart2Fill
															style={{
																fill: '#2B63FC',
															}}
														/>
														<span>Carrinho</span>
													</div>
													<div
														className="menu-item"
														onClick={() =>
															history.push(
																'/favorite'
															)
														}
													>
														<FaHeart
															style={{
																fill: '#2B63FC',
															}}
														/>
														<span>Favoritos</span>
													</div>
													<div className="menu-item">
														<RiStore3Fill
															style={{
																fill: '#2B63FC',
															}}
														/>
														<span
															onClick={() =>
																history.push(
																	'/my-purchases'
																)
															}
														>
															Minhas compras
														</span>
													</div>
												</>
											)}
											{user.user_types.name ===
												UserTypeEnum.seller && (
												<>
													<div className="menu-item">
														<RiStore3Fill
															style={{
																fill: '#2B63FC',
															}}
														/>
														<span
															onClick={() =>
																history.push(
																	`/seller-area/${user?.id}/all/0/100000000/undefined/products`
																)
															}
														>
															Área do vendedor
														</span>
													</div>
												</>
											)}

											<div className="logout-button">
												<RiLogoutBoxFill
													style={{ fill: '#2B63FC' }}
												/>
												<Link
													to="/seller"
													onClick={() => {
														dispatch(clearCart())
														dispatch(removeUser())
													}
													}
												>
													Log out
												</Link>
											</div>
										</div>
									</MenuContainer>
								)}
								<UserImg
									src={`${config.host}/static/users/${user.user_photo?.name}`}
									className="user-img"
									onMouseEnter={() => setShowMenu(true)}
									onMouseLeave={() => setShowMenu(false)}
								/>
							</div>
						</TopHeader>
						<MobileMenu />
					</ContainerColumn>
				</AlignmentContainer>
			</Container>
		</>
	);
};

export default AuthHeader;
