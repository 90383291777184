import React, { useRef } from 'react';

import {
	AboutDirectionCard,
	AboutUsCard,
	Advices,
	Container,
	Image1,
	Image2,
	Images,
	PageContainer,
	ReceiveNewsContainer,
	BottomContainer,
	AddressDiv,
} from './styles';
import imagem1 from '../../assets/foto1.png';
import imagem2 from '../../assets/foto3.png';
import imagem3 from '../../assets/correta (1) (1).png';
import defaultImage from '../../assets/defaultImg.svg';
import { ReactComponent as ContactIcons } from '../../assets/contactIcons/whats.svg';
import { ReactComponent as Email } from '../../assets/contactIcons/email.svg';
import ArrowBack from '../../assets/icons/arrowBack.svg';
import president from '../../assets/boardMembersPhotos/president.jpg';
import vicePresident from '../../assets/boardMembersPhotos/vicePresident.jpg';
import first from '../../assets/boardMembersPhotos/first.jpg';
import second from '../../assets/boardMembersPhotos/second.jpg';
import third from '../../assets/boardMembersPhotos/third.jpg';
import fourth from '../../assets/boardMembersPhotos/fourth.jpg';
import fifth from '../../assets/boardMembersPhotos/fifth.jpg';
import sixth from '../../assets/boardMembersPhotos/sixth.jpg';
import AdvicesCard from './AdvicesCard/AdvicesCard';

import logo from '../../assets/logo.png';
import { Infos } from '../Default/AuthHeader/styles';
import Infositens from './Infos/Infositens';
import Carousel from '../HomePage/Carousel/Carousel';
import DrivingExecutive from './DrivingExecutive/DrivingExecutive';
import { MdEmail, MdPhone, MdPin, MdTimer } from 'react-icons/md';
// import CardManual from './CardManual/CardManual';
interface IImages {
	image: any;
	name?: string;
	description?: string;
}

const AboutUs: React.FC = () => {
	const imagesArray: IImages[] = [
		{
			image: president,
			name: 'Camilo Turmina',
			description: 'Presidente',
		},
		{
			image: vicePresident,
			name: 'Odone Fortes Martins',
			description: '1° Vice-Presidente',
		},
		{
			image: first,
			name: 'Airton Adelar Hack',
			description: '2ª Vie-Presidente',
		},
		{
			image: second,
			name: 'Jean Michel Patrick Tumeo Galiano',
			description: '3º Vice-Presidente',
		},
		{
			image: third,
			name: 'Geraldo Luiz Gonçalves',
			description: '4º Vice-Presidente',
		},
		{
			image: fourth,
			name: 'José Eduardo Moraes Sarmento',
			description: '5º Vice-Presidente',
		},
		{
			image: fifth,
			name: 'Elon Cesar Isfer Garcia',
			description: '6º Vice-Presidente e 1º Secretário',
		},
		{
			image: sixth,
			name: 'Maria Cristina Fernandes Medeiros Coutinho',
			description: '7ª Vice-Presidente e 2ª Secretária'
		},
	];

	return (
		<PageContainer>
			<h2>Quem somos</h2>
			<Container>
				<AboutUsCard>
					<div className="organizer">
						<div className="foto-and-infos">
							<div className="photo">
								<img src={imagem1} alt="" />
							</div>
							<div className="infos">
								<div className="infos-item">
									<h1>Missão</h1>
									<p>
										Exercer, fundamentada nos princípios e
										valores institucionais, a livre defesa
										dos interesses dos associados e da
										sociedade e prover soluções geradoras de
										valor que contribuam para o seu
										desenvolvimento
									</p>
								</div>
								<div className="infos-item">
									<h1>Visão</h1>
									<p>
										Ser reconhecida por sua forte liderança
										e influência institucional, alicerçada
										por uma atuação independente e
										representativa
									</p>
								</div>
								<div className="infos-item">
									<h1>Valores</h1>
									<p>
										Ser reconhecida por sua forte liderança
										e influência institucional, alicerçada
										por uma atuação independente e
										representativa
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="about-association">
						<h3>A Associação</h3>

						<p>
							A Associação Comercial do Paraná é parceira do
							comércio há mais de 130 anos. Fundada em 1890 pelo
							Barão do Serro Azul, a ACP atende com ética e
							competência as necessidades dos associados por meio
							da representação institucional e da prestação de
							serviços. Por ser uma entidade de classe sem fins
							lucrativos, consegue oferecer aos seu público uma
							série de benefícios com condições e formatos criados
							especialmente para atender desde micros até grandes
							empresas.
						</p>
						<p>
							A ACP está presente hoje em todas as regiões do
							estado com representantes, escritórios próprios em
							Maringá e Cascavel e parcerias com entidades locais.
							Atualmente, a entidade conta com mais de 30 mil
							associados diretos e indiretos.{' '}
						</p>
						<p>
							A ACP compõe parcela expressiva da sociedade e
							possui alta representatividade política nas esferas
							municipal, estadual e federal. Isso permite o
							protagonismo na defesa dos interesses dos associados
							junto aos órgãos públicos
						</p>

						{/* <div className="button-container">
							<button>Conheça nossa história</button>
						</div> */}
						<Infositens />
					</div>
				</AboutUsCard>
				<AboutDirectionCard>
					<h2>Direção executiva</h2>
					<div className="pictures-container">
						{imagesArray.map((item) => (
							<div className="images">
								<img src={item.image} alt="" />
								<div className="text">
									<span> {item.name} </span>
									<p
										style={{
											color: `${item.description ===
												'1° Vice-Presidente'
												? '#4699b2'
												: '#D59600'
												}`,
										}}
									>
										{' '}
										{item.description}{' '}
									</p>
								</div>
							</div>
						))}
					</div>
					<div className="informations">
						<div className="item">
							<ContactIcons className="contact-icon" />
							<span>41 3320-2556 | 41 3320-2550</span>
						</div>

						<div className="item">
							<Email className="contact-icon" />
							<span>
								Contato da Diretoria Executiva:
								presidência@acp.gov.br
							</span>
						</div>
					</div>
				</AboutDirectionCard>
				<Advices>
					<h2>Conselhos</h2>
					<section>
						<AdvicesCard />
					</section>
				</Advices>
				<Images>
					<AddressDiv>
						<p>
							<MdPin />
								Rua XV de Novembro, 621
								Curitiba | CEP:80020-310
							</p>
						<p>
							<MdPhone />
								(41) 3320-2929
							</p>
						<p>
							<MdEmail />
								sac@acp.org.br
							</p>

						<hr />
						<p>
							<MdTimer />
								Atendimento
								De Segunda a Sexta-feira
								das 08h30 às 18h
							</p>
					</AddressDiv>

					{/* <div className="img1-text">
							<div className="infos-container">
								<button>Maringá</button>

								<div className="address-list">
									<span className="street">
										Rua Carlos Gomes, 4020 Centro |
										Cascavel-PR
									</span>
									<span className="hours">
										Horário de Atendimento: Das 08h30 às
										17h30
									</span>
									<span className="contact">
										Contato: (45) 3039-3662 sac@acp.org.br
									</span>
								</div>
							</div>
						</div> */}

					<div className="img-1-container" style={{
						backgroundImage: imagem3
					}}>
						{/* <img src={imagem3} alt=""  /> */}
						{/* <div className="img2-text"> */}
						{/* <div className="infos-container">
								<div className="address-list">
									<span className="street">
										Rua Carlos Gomes, 4020 Centro |
										Cascavel-PR
									</span>
									<span className="hours">
										Horário de Atendimento: Das 08h30 às
										17h30
									</span>
									<span className="contact">
										Contato: (45) 3039-3662 sac@acp.org.br
									</span>
								</div>
								<button>Maringá</button>
							</div> */}
						{/* </div> */}
					</div>
				</Images>
				{/* <CardManual /> */}
			</Container>
		</PageContainer>
	);
};

export default AboutUs;
