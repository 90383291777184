import React from 'react';
import Administrator from '../../components/Administrator/Administrator';
import Categorys from '../../components/Categorys/Categorys';
import { Container } from './styles';

const AdministratorPage: React.FC = () => {
	return (
		<>
			<Container>
				<Administrator />
			</Container>
		</>
	);
};

export default AdministratorPage;
