import styled from 'styled-components';

interface ContainerProps {
	flex: number;
	maxWidth: number;
}

export const Container = styled.div<ContainerProps>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: ${(Prop) => Prop.flex};
	height: 264px;
	max-width: ${(Prop) => Prop.maxWidth}px;
	background: #fff;
	border: 1px solid #c8d0d9;
	border-radius: 16px;
	@media (max-width: 768px) {
		margin-bottom: 16px;
		max-width: 100%;
		min-height: 264px;
		max-height: 264px;
	}
	img {
		width: 150px;
		@media (max-width: 768px) {
			max-width: 164px;
			margin-bottom: 20px;
		}
	}

	h3 {
		font-family: var(--Roboto-font-family);
		font-weight: 500;
		font-size: min(20px, 1.8vw);
		color: #1c27cc;
		margin-bottom: 10px;
		@media (max-width: 768px) {
			font-size: 20px;
		}
	}
	p {
		font-family: var(--Roboto-font-family);
		font-weight: 400;
		font-size: min(12px, 1.3vw);
		color: #959595;
		text-align: center;
		width: 163px;
		line-height: 19px;
		height: 57px;

		@media (max-width: 768px) {
			font-size: 12px;
		}
	}
`;
