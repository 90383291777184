import styled from 'styled-components';

export const Container = styled.div`
    margin: 20px;
    font-family: var(--Roboto-font-family);
    height: 100vh;
	h2 {
		color: #2B63FC;
		font-size: 10px;
		font-weight: 400;
		font-size: min(22px, 1.5vw);
		border-bottom: solid 1px #e1e1e1;
		padding-bottom: 18px;
	}
	
	button {
		background: #2B63FC;
		border: none;
		color: white;
		border-radius: 8px;
		margin-left: 5%;
		width: 90%;
		height: 34px;
		margin-top: 30px;
		align-self: center;

		font-family: var(--Roboto-font-family);
		font-weight: 400;
		font-size: 25px;
		transition: all .5s;
		font-size: 16px;
		&:hover {
			background: #fff;
			color:#2B63FC;
			border: solid 1px #2B63FC;
		}
	}
`;
export const InputsContainer = styled.form`
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	padding: 15px 15px;
	background: white;
    border-radius:8px;
	.modal-title{
		font-size: 16px;
		padding-bottom: 18px;
    	margin-bottom: 20px;
		padding: 17px;
		font-weight: 600;
		display: flex;
		align-items: center;

	}

	.first-line {
		display: flex;
        flex-direction: column;
        p{
            font-size: 20px;
        }
	}
    Input{
		width: 90%;
        border:  1px solid #C5CED7;
        border-radius: 8px;
        height: 35px;
        padding-left: 15px;
        margin: 20px 5px;
    }





`;