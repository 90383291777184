import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card } from 'semantic-ui-react';
import { setPaymentMethod } from '../../../../actions/UserActions';
import ProductsCart from '../../../../interfaces/ProductsCart';
import UserAccount, { PaymentMethodEnglishEnum } from '../../../../interfaces/UserAccount';
import { AppState } from '../../../../store';
import useCheckMobileScreen from '../../../../utils/useCheckMobileScreen';
import AddPaymentModaL, { AddPaymentModalHandle } from '../../../UserProfile/UserInformation/AddPaymentModal/AddPaymentModal';
import { ItemContainer } from '../styles';

const PaymentForm: React.FC = () => {
	const [isBankSlip, setIsBankSlip] = useState<boolean>(true);
	const history = useHistory();
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const productsCart: ProductsCart[] = useSelector(
		(state: AppState) => state.productsCart.currentCart
	);
	const dispatch = useDispatch();
	
	const modalRef = useRef<AddPaymentModalHandle>(null);

	const openModal = () => {
		modalRef.current?.handleOpenModal();
	};

	return (
		<ItemContainer className="frist">
			<h2>Forma de pagamento</h2>
			<div className="options-container">
				<div className="item">
					<div
						className="selector"
						onClick={() =>
							dispatch(
								setPaymentMethod({
									english_name: 'BOLETO',
									portuguese_name: 'Boleto bancário',
									card_id: '',
								})
							)
						}
					>
						{user.payment_method?.english_name === 'BOLETO' && (
							<div className="active-selector" />
						)}
					</div>
					<div className="option-text">
						<span className={user.payment_method?.english_name === PaymentMethodEnglishEnum.BOLETO ? 'span-blue' : ''}>Boleto bancário</span>
					</div>
				</div>
				<div className="item">
					<div
						className="selector"
						onClick={() =>
							dispatch(
								setPaymentMethod({
									english_name: 'BOLETO_PIX',
									portuguese_name: 'Pix',
									card_id: '',
								})
							)
						}
					>
						{user.payment_method?.english_name === 'BOLETO_PIX' && (
							<div className="active-selector" />
						)}
					</div>
					<div className="option-text">
						<span className={user.payment_method?.english_name === PaymentMethodEnglishEnum.PIX ? 'span-blue' : ''}>Pix</span>
					</div>
				</div>
				{user.user_cards.map((card) => (
					<div className="item">
						<div
							className="selector"
							onClick={() =>
								dispatch(
									setPaymentMethod({
										english_name: 'CREDIT_CARD',
										portuguese_name: 'Cartão de crédito',
										card_id: card.card_id,
									})
								)
							}
						>
							{user.payment_method?.card_id === card.card_id && (
								<div className="active-selector" />
							)}
						</div>
						<div className="option-text">
							{' '}
							<span className={user.payment_method?.card_id === card.card_id ? 'span-blue' : ''}>
								Cartão Crédito final {card.last4CardNumber}
							</span>
						</div>
					</div>
				))}

				<div className="add-button">
					<AddPaymentModaL ref={modalRef} />
					{useCheckMobileScreen() ? (
						<button onClick={() => history.push(`/add-payment-mobile/${'cart'}`)}>
							+ Adicionar novo método de pagamento
						</button>
					) : (
						<button onClick={() => openModal()}>
							+ Adicionar novo método de pagamento
						</button>
					)}
				</div>
			</div>
		</ItemContainer>
	);
};

export default PaymentForm;
