import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import AddressArea from './AddressArea/AddressArea';
import PaymentFormsArea from './PaymentFormsArea/PaymentFormsArea';

import { Container, PageContainer } from './styles';
import UserInformationsArea from './UserInformationsArea/UserInformationsArea';
import UserProfileMenu from './UserProfileMenu/UserProfileMenu';
import UserInformation from './UserInformation/UserInformation'

const UserProfile: React.FC = () => {
	const { path, url } = useRouteMatch();
	return (
		<PageContainer>
			<Container>
				<header>
					<h1>Meu Perfil</h1>
				</header>

				{/* <main>
					<UserProfileMenu />
					<Route
						path={`${url}/user-informations`}
						component={UserInformationsArea}
					/>

					<Route
						path={`${url}/address-informations`}
						component={AddressArea}
					/>
					<Route
						path={`${url}/payment-informations`}
						component={PaymentFormsArea}
					/>
					<Route
						path={`${url}/user-information`}
						component={UserInformation}
					/>
				</main> */}
				<UserInformation />
			</Container>
		</PageContainer>
	);
};

export default UserProfile;
