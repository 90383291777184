import styled from 'styled-components';

export const ModalContainer = styled.div`
	width: 796px;
	height: 408px;
	padding: 26px;
	font-family: 'Roboto';
	.cards {
		font-size: 16px;
		.button-text {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-top: 40px;
			button {
				width: 196px;
				height: 30px;
				border-radius: 8px;
				background: #2b63fc;
				color: white;
				font-size: 13px;
				text-align: center;
				border: none;
			}
			p {
				font-size: 14px;
				color: #8a99a8;
				font-style: italic;
			}
		}
		span {
			text-align: start;
			margin-bottom: 16px;
		}
		.row {
			display: grid;
			column-gap: 20px;
			grid-template-columns: 1fr 1fr;
			input {
				height: 38px;
				border-radius: 8px;
				border: 1px solid #8a99a8;
				margin-bottom: 22px;
			}
			.motivation {
				display: flex;
				flex-direction: column;
			}
			.duration {
				display: flex;
				flex-direction: column;
			}
		}
		.motive {
			display: flex;
			flex-direction: column;
			input {
				height: 123px;
				border-radius: 8px;
				border: 1px solid #8a99a8;
			}
		}
	}
	.title {
		margin-bottom: 31px;
		h1 {
			font-size: 19px;
			font-weight: 500;
			color: #2a3199;
		}
	}
`;
