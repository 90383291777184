import React, { useEffect, useState } from 'react';

import { Container, PageContainer, NoAvaliations } from './styles';
import { CgSmileSad } from 'react-icons/cg';
import api from '../../../api';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store';
import Review from './Review';
import { IProduct } from '../../../Pages/ProductPage/ProductPage';

const Reviews: React.FC = () => {

	const user = useSelector((state: AppState) => state.user.currentUser);

	const [reviews, setReviews] = useState<{
		id: string;
		rating: number;
		review: string;
		user_accounts: {
			name: string;
			social_reason: string;
		};
		product: IProduct;
	}[]>([]);

	useEffect(() => {
		const fetch = async () => {
			const response = await api.get('/product-reviews/by-shop', {
				params: { shop_id: user.id },
			});

			setReviews(response.data.data.reviews);
		}

		fetch();
	}, [])

	useEffect(() => {
		console.log({reviews})
	}, [reviews])

	return (
		<PageContainer>
			<Container>
				<h1>Avaliações</h1>
				{
					reviews.length > 0 ?
						(reviews.map((review) => (
							<Review 
								key={review.id}
								review={review}
							/>
						))) :
					<NoAvaliations>
						<div className="emoji-and-text">
							<CgSmileSad color="#2B63FC" className="emoji" />
							<h3>Você ainda não possui avaliações</h3>
						</div>
					</NoAvaliations>
				}
			</Container>
		</PageContainer>
	);
};

export default Reviews;
