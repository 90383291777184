import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { RiSearch2Line } from 'react-icons/ri';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { insertProducts } from '../../actions/ProductsList';
import api from '../../api';
import { AreaTypes, ProductsList } from '../../interfaces/ProductsList';
import UserAccount from '../../interfaces/UserAccount';
import { AppState } from '../../store';
import { productExibitionRules } from '../../utils/productsExibitionRules';
import ProductCard from '../Default/ProductCard/productCard';
import { ProductCategories } from '../ProductRegister/ProductRegister';

import { Container, ProductContainer } from './styles';

interface PageParams {
	search_query: string;
	search_type: string;
	state: string;
	min_price: string;
	max_price: string;
}

const RegisteredProduct: React.FC = () => {
	const productsList: ProductsList[] = useSelector(
		(state: AppState) => state.productsList?.currentProductsList
	);
	const [
		productCategories,
		setProductCategories,
	] = useState<ProductCategories>();
	const pageParams = useParams<PageParams>();
	const [categoryNumber, setCategoryNumber] = useState<number>(5);
	const history = useHistory();
	const dispatch = useDispatch();

	const [messageIsVisible, setVisibleMessage] = useState<boolean>(false);

	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);

	const [searchQuery, setSearchQuery] = useState('');

	useEffect(() => {
		if (pageParams.search_query === 'all') {
			api.post('/list-products').then((response: any) => {
				// setProducts(response.data.data.response);
				// setSearchProducts(response.data.data.response);

				dispatch(
					insertProducts(
						productExibitionRules(
							response.data.data.response,
							AreaTypes.SEARCHPAGE
						),
						AreaTypes.SEARCHPAGE
					)
				);
			});
		}
	});

	useEffect(() => {
		api.get('/get-product-categories')
			.then((response: AxiosResponse<ProductCategories>) => {
				setProductCategories(response.data);
			})
			.catch((err) => {});
	}, []);

	useEffect(() => {
		if (pageParams.search_query !== 'all') {
			if (pageParams.search_type === 'category') {
				api.get(`/get-product-by-category`, {
					params: {
						category_id: pageParams.search_query,
						state: pageParams.state,
						min_price: pageParams.min_price,
						max_price: pageParams.max_price,
					},
				}).then((response: any) => {
					if (response.data.products.length === 0) {
						setVisibleMessage(true);
					} else {
						setVisibleMessage(false);
					}
					dispatch(
						insertProducts(
							productExibitionRules(
								response.data.products,
								AreaTypes.SEARCHPAGE
							),
							AreaTypes.SEARCHPAGE
						)
					);
				});
			} else {
				api.get(`/search-product`, {
					params: {
						search_query: pageParams.search_query,
						state: pageParams.state,
						min_price: pageParams.min_price,
						max_price: pageParams.max_price,
					},
				}).then((response: any) => {
					if (response.data.products.length === 0) {
						setVisibleMessage(true);
					} else {
						setVisibleMessage(false);
					}
					dispatch(
						insertProducts(
							productExibitionRules(
								response.data.products,
								AreaTypes.SEARCHPAGE
							),
							AreaTypes.SEARCHPAGE
						)
					);
				});
			}
		}
	}, [pageParams]);

	useEffect(() => {
		if (pageParams.search_type === 'category') {
			api.get(`/get-product-by-category`, {
				params: {
					category_id: pageParams.search_query,
					state: pageParams.state,
					min_price: pageParams.min_price,
					max_price: pageParams.max_price,
				},
			}).then((response: any) => {
				if (response.data.products.length == 0) {
					setVisibleMessage(true);
				} else {
					setVisibleMessage(false);
				}
				dispatch(
					insertProducts(
						productExibitionRules(
							response.data.products,
							AreaTypes.SEARCHPAGE
						),
						AreaTypes.SEARCHPAGE
					)
				);
			});
		} else {
			api.get(`/search-product`, {
				params: {
					search_query: pageParams.search_query,
					state: pageParams.state,
					min_price: pageParams.min_price,
					max_price: pageParams.max_price,
				},
			}).then((response: any) => {
				if (response.data.products.length == 0) {
					setVisibleMessage(true);
				} else {
					setVisibleMessage(false);
				}
				dispatch(
					insertProducts(
						productExibitionRules(
							response.data.products,
							AreaTypes.SEARCHPAGE
						),
						AreaTypes.SEARCHPAGE
					)
				);
			});
		}
	}, [pageParams]);

	return (
		<Container>
			<div className="row-title">
				<h1>Produtos cadastrados</h1>
				<div className="input-row">
					<RiSearch2Line />
					<input
						placeholder="Buscar"
						type="text"
						onChange={(e) => setSearchQuery(e.target.value)}
						onKeyPress={(e) => {
							if (e.nativeEvent.key === 'Enter') {
								history.push(
									`/registeredproduct/${
										searchQuery.length > 0
											? searchQuery
											: 'all'
									}/query/all/0/100000000`
								);
							}
						}}
					/>
				</div>
			</div>
			<div className="col-grid">
				<div className="categorys">
					<h6>Categoria</h6>
					<ul>
						{productCategories?.categories
							.slice(0, categoryNumber)
							.map((category) => (
								<li
									id={
										pageParams.search_query === category.id
											? 'activated'
											: ''
									}
									onClick={() =>
										history.push(
											`/registeredproduct/${category.id}/category/${pageParams.state}/${pageParams.min_price}/${pageParams.max_price}`
										)
									}
								>
									{category.name}
								</li>
							))}
						{productCategories?.categories &&
							productCategories.categories.length >
								categoryNumber && (
								<span
									className="more"
									onClick={() =>
										setCategoryNumber(categoryNumber + 3)
									}
								>
									ver mais
								</span>
							)}
						{productCategories?.categories &&
							productCategories.categories.length <
								categoryNumber && (
								<span
									className="more"
									onClick={() =>
										setCategoryNumber(categoryNumber - 6)
									}
								>
									ver menos
								</span>
							)}
					</ul>
				</div>

				<div className="cards">
					<ProductContainer>
						{productsList
							.find(
								(item) =>
									item?.areaTitle === AreaTypes.SEARCHPAGE
							)
							?.products?.map((product, index) => (
								<>
									{index < 10000 && (
										<ProductCard product={product} />
									)}
								</>
							))}
					</ProductContainer>
				</div>
			</div>
		</Container>
	);
};

export default RegisteredProduct;
