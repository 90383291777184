import { AxiosResponse } from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { insertCustomizations } from '../../../../../actions/ShopCustomizationsActions';
import api from '../../../../../api';
import config from '../../../../../config';
import { ShopCustomizations } from '../../../../../interfaces/ShopCustomizations';
import UserAccount from '../../../../../interfaces/UserAccount';
import { AppState } from '../../../../../store';
import { ShopQueryReturn } from '../../../../SellerStore/SellerStore';

import {
	Container,
	TopDiv,
	BottomDiv,
	ReputationBar,
	ReputationRow,
	ReputationInfos,
} from './styles';

interface PageParams {
	userId: string;
}

const SellerReputationCardVerticalMobile: React.FC = () => {
	const history = useHistory();
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const shopCustomizations: ShopCustomizations = useSelector(
		(state: AppState) => state.shopCustomizations.currentShopCustomizations
	);
	const dispatch = useDispatch();
	const pageParams = useParams<PageParams>();
	useEffect(() => {
		api.get('/get-shop-customizations', {
			params: {
				user_account_id: pageParams.userId,
			},
		}).then((response: AxiosResponse<ShopQueryReturn>) => {
			dispatch(insertCustomizations(response.data.shop[0]));
		});
	}, []);

	return (
		<Container>
			<TopDiv>
				<span>{shopCustomizations?.shop_name}</span>
				<button
					id="edit-store"
					onClick={() => history.push(`/my-store/${user.id}`)}
				>
					Editar loja
				</button>
			</TopDiv>
			<BottomDiv>
				<h4>Reputação excelente!</h4>
				<ReputationBar />
				<ReputationInfos>
					{/* <ReputationRow>
            <span>1200</span>Vendas finalizadas
					</ReputationRow>
          <ReputationRow>
            <span>15</span>Reclamações
					</ReputationRow>
          <ReputationRow>
            <span>300</span>Avaliações Positivas
					</ReputationRow> */}
					<div className="item-grid" style={{ textAlign: 'center' }}>
						<span>1200</span>
					</div>
					<div className="item-grid">Vendas finalizadas</div>
					<div className="item-grid" style={{ textAlign: 'center' }}>
						<span>15</span>
					</div>
					<div className="item-grid">Reclamações</div>
					<div className="item-grid" style={{ textAlign: 'center' }}>
						<span>300</span>
					</div>
					<div className="item-grid">Avaliações Positivas</div>
				</ReputationInfos>
			</BottomDiv>
		</Container>
	);
};

export default SellerReputationCardVerticalMobile;
