import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	insertProductQuantity,
	removeProductCartAction,
} from '../../../actions/ProductsCartActions';
import config from '../../../config';
import ProductsCart from '../../../interfaces/ProductsCart';
import { AppState } from '../../../store';
import { FaTrash } from 'react-icons/fa';
import { CgSmileSad } from 'react-icons/cg';

import { Container, DefaultRow, NoProducts } from './styles';
import { getFristImageSource } from '../../../utils/GetProductImage';
import { getProductPrice } from '../../../utils/GetProductPrice';
import UserAccount from '../../../interfaces/UserAccount';

const MyCart: React.FC = () => {
	const productsCart: ProductsCart[] = useSelector(
		(state: AppState) => state.productsCart.currentCart
	);
	const dispatch = useDispatch();

	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);

	return (
		<Container>
			{productsCart.length < 1 ? (
				<NoProducts>
					<div className="emoji-and-text">
						<CgSmileSad color="#2B63FC" className="emoji" />
						<h2>Parece que seu carrinho está vazio!</h2>
					</div>
				</NoProducts>
			) : (
				<div className="my-product">
					{productsCart.map((item, index) => (
						<div
							className="infos-container"
							style={{
								borderBottom:
									index + 1 === productsCart.length
										? 'none'
										: '1px solid #c5ced7',
							}}
						>
							<img
								alt="iamgem do produto"
								src={getFristImageSource(
									item?.parameters?.product
								)}
								className="foto"
							></img>
							<div className="mobileContainer">
								<div className="rowProduc-Infos">
									<p> {item?.parameters?.product.title}</p>
									<div className="rowCounterAndColor">
										{item?.parameters?.product
											.product_variations &&
											item?.parameters?.product
												.product_variations.length >
												0 && (
												<DefaultRow>
													{/* <h4>
														<b>Cor:</b>{' '}
														{
															item?.parameters?.product
																.product_variations[0].color
															}
													</h4> */}
													{item?.parameters?.product
														.product_variations[0]
														.variation_sizes
														.length > 0 && (
														<h4
															style={{
																marginLeft:
																	'10px',
															}}
														>
															<b>Tamanho:</b>{' '}
															{
																item?.parameters
																	?.product
																	.product_variations[0]
																	.variation_sizes[0]
																	.size
															}
														</h4>
													)}
												</DefaultRow>
											)}
										<div className="counter">
											<div
												className="span-div"
												onClick={() => {
													if (
														item.parameters
															.quantity > 1
													) {
														dispatch(
															insertProductQuantity(
																item.parameters
																	.product.id,
																item.parameters
																	.quantity -
																	1,
																item.cart_product_id
															)
														);
													}
												}}
											>
												-
											</div>
											<h4>{item.parameters.quantity}</h4>
											<div
												className="span-div"
												onClick={() => {
													if (
														(!item.parameters
															.product
															?.product_variations![0] &&
															parseInt(
																item.parameters
																	.product
																	.stock
															) <
																item.parameters
																	.quantity) ||
														(item.parameters.product
															?.product_variations![0] &&
															item.parameters
																.product
																?.product_variations![0]
																.variation_sizes[0]
																?.stock >
																item.parameters
																	.quantity)
													) {
														dispatch(
															insertProductQuantity(
																item.parameters
																	.product.id,
																item.parameters
																	.quantity +
																	1,
																item.cart_product_id
															)
														);
													}
												}}
											>
												+
											</div>
										</div>
									</div>
								</div>
								<div className="rowPrice">
									<DefaultRow>
										<h2>
											{'R$ ' +
												String(
													getProductPrice(item?.parameters?.product, user)
												)
													.replace('.', ',')
													.substring(
														0,
														getProductPrice(item?.parameters?.product, user).toString()
															.length - 2
													)}
										</h2>
										<h2 className="small-text">
											{String(
												getProductPrice(item?.parameters?.product, user)
											)
												.replace('.', ',')
												.substring(
													getProductPrice(item?.parameters?.product, user).toString()
														.length - 2,
													getProductPrice(item?.parameters?.product, user).toString()
														.length
												)}
										</h2>
									</DefaultRow>

									<span
										onClick={() =>
											dispatch(
												removeProductCartAction(
													item.parameters.product.id,
													item.cart_product_id
												)
											)
										}
									>
										Remover
									</span>
								</div>
							</div>

							<div className="product-info">
								{item?.parameters?.product.product_variations &&
									item?.parameters?.product.product_variations
										.length > 0 && (
										<div className="is-variation">
											<h3>
												{' '}
												{
													item?.parameters?.product
														.title
												}
											</h3>
										</div>
									)}
								{item?.parameters?.product.product_variations &&
									item?.parameters?.product.product_variations
										.length <= 0 && (
										<h3>
											{' '}
											{item?.parameters?.product.title}
										</h3>
									)}
								{item?.parameters?.product.product_variations &&
									item?.parameters?.product.product_variations
										.length > 0 && (
										<DefaultRow>
											{item?.parameters?.product
												.product_variations[0]
												.variation_sizes.length > 0 && (
												<h4>
													Tamanho:{' '}
													{
														item?.parameters
															?.product
															.product_variations[0]
															.variation_sizes[0]
															.size
													}
												</h4>
											)}
										</DefaultRow>
									)}
								<DefaultRow style={{ alignItems: 'flex-end' }}>
									<h2>
										{'R$ ' +
											String(
												getProductPrice(item?.parameters?.product, user)
											)
												.replace('.', ',')
												.substring(
													0,
													getProductPrice(item?.parameters?.product, user).toString()
														.length - 2
												)}
									</h2>
									<h2 className="small-text">
										{String(getProductPrice(item?.parameters?.product, user))
											.replace('.', ',')
											.substring(
												getProductPrice(item?.parameters?.product, user).toString()
													.length - 2,
												getProductPrice(item?.parameters?.product, user).toString()
													.length
											)}
									</h2>
								</DefaultRow>
							</div>
							<div className="right-side-container">
								<span
									onClick={() =>
										dispatch(
											removeProductCartAction(
												item.parameters.product.id,
												item.cart_product_id
											)
										)
									}
								>
									<FaTrash
										size={20}
										style={{ marginRight: '11px' }}
									/>
									Remover do carrinho
								</span>
								<div className="counter">
									<div
										className="span-div"
										onClick={() => {
											if (item.parameters.quantity > 1) {
												dispatch(
													insertProductQuantity(
														item.parameters.product
															.id,
														item.parameters
															.quantity - 1,
														item.cart_product_id
													)
												);
											}
										}}
									>
										-
									</div>
									<h4>{item.parameters.quantity}</h4>
									<div
										className="span-div"
										onClick={() => {
											if (
												(!item.parameters.product
													?.product_variations![0] &&
													parseInt(
														item.parameters.product
															.stock
													) <
														item.parameters
															.quantity) ||
												(item.parameters.product
													?.product_variations![0] &&
													item.parameters.product
														?.product_variations![0]
														.variation_sizes[0]
														?.stock >
														item.parameters
															.quantity)
											) {
												dispatch(
													insertProductQuantity(
														item.parameters.product
															.id,
														item.parameters
															.quantity + 1,
														item.cart_product_id
													)
												);
											}
										}}
									>
										+
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			)}
		</Container>
	);
};

export default MyCart;
