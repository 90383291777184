import React, { useState } from 'react';
import { ReactComponent as Logo } from '../../assets/icons/acpLogoBlack.svg';
import { Link, useHistory } from 'react-router-dom';
import { Container, DivLogo } from './styles';
import { RiNotification2Line, RiUserSettingsLine } from 'react-icons/ri';
import { FiUsers } from 'react-icons/fi';
import { AiTwotoneShop } from 'react-icons/ai';
import { RiLayout6Line, RiListSettingsFill } from 'react-icons/ri';

const HeaderAdmin: React.FC = () => {
	const history = useHistory();
	const [active, setActive] = useState<number>(0);
	return (
		<Container>
			<DivLogo>
				<Logo
					height={35}
					width={250}
					style={{ cursor: 'pointer' }}
					onClick={() => history.push('/admin/welcome')}
				/>
			</DivLogo>
			<div className="row">
				<div
					className={
						active === 1 ? 'notification active' : 'notification'
					}
					onMouseOver={(e) => {
						setActive(1);
					}}
					onMouseLeave={(e) => {
						setActive(0);
					}}
					onClick={(e) => {
						history.push('/admin/notification');
					}}
				>
					<RiNotification2Line />
					<p>Notificação</p>
				</div>
				<div
					className={active === 2 ? 'sallers active' : 'sallers'}
					onMouseOver={(e) => {
						setActive(2);
					}}
					onMouseLeave={(e) => {
						setActive(0);
					}}
					onClick={(e) => {
						history.push('/admin/sellers');
					}}
				>
					<FiUsers />
					<p>Vendedores</p>
				</div>
				<div
					className={active === 3 ? 'products active' : 'products'}
					onMouseOver={(e) => {
						setActive(3);
					}}
					onMouseLeave={(e) => {
						setActive(0);
					}}
					onClick={(e) => {
						history.push(
							`/admin/registeredproduct/all/query/all/0/100000000`
						);
					}}
				>
					<AiTwotoneShop />
					<p>Produtos</p>
				</div>
				<div
					className={active === 4 ? 'categorys active' : 'categorys'}
					onMouseOver={(e) => {
						setActive(4);
					}}
					onMouseLeave={(e) => {
						setActive(0);
					}}
					onClick={(e) => {
						history.push('/admin/categorys');
					}}
				>
					<RiListSettingsFill />
					<p>Categorias</p>
				</div>
				<div
					className={active === 5 ? 'edit active' : 'edit'}
					onMouseOver={(e) => {
						// setActive('active');
						setActive(5);
					}}
					onMouseLeave={(e) => {
						setActive(0);
					}}
					onClick={(e) => {
						history.push('/admin/edit/banners');
					}}
				>
					<RiLayout6Line />
					<p>Edição</p>
				</div>
				<div
					className={active === 6 ? 'edit active' : 'edit'}
					onMouseOver={(e) => {
						// setActive('active');
						setActive(6);
					}}
					onMouseLeave={(e) => {
						setActive(0);
					}}
					onClick={(e) => {
						history.push('/admin/newadministrator');
					}}
				>
					<RiUserSettingsLine />
					<p>Administrador</p>
				</div>
			</div>
		</Container>
	);
};

export default HeaderAdmin;
