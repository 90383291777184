import styled from 'styled-components';

export const Container = styled.div`
	padding-bottom: 27px;
	padding-top: 30px;
	border-top: solid 1px #cecece;

	.title-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		h2 {
			color: #353a40;
			font-size: min(16px, 1.2vw);
			font-family: var(--Roboto-font-family);
			font-weight: normal;
			@media (max-width: 768px){
				font-size: 14px;
			}
		}
		span {
			color: #2d327c;
			font-size: min(16px, 1.1vw);
			font-family: var(--Roboto-font-family);
			font-weight: normal;
			text-decoration: underline;
			cursor: pointer;
			@media (max-width: 768px){
					display: none;
				}
		}
	}

	.items-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.items {
			padding-left: 10px;
			span {
				color: #5a646e;
				font-size: min(14px, 1.1vw);
				font-family: var(--Roboto-font-family);
				font-weight: normal;
				@media (max-width: 768px){
					font-size: 14px;
					max-width: 300px;
					color: #8A99A8;
				}
			}
		}
		.edit-button {
			color: #004d93;
			font-size: min(13px, 1.1vw);
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			text-decoration: underline;
			&:hover {
				cursor: pointer;
			}
		}
	}
`;
