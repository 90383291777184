import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { insertProducts } from '../../actions/ProductsList';
import api from '../../api';
import { IFavorite } from '../../interfaces/Favorites';
import { Product } from '../../interfaces/Product';
import { ProductsList, AreaTypes } from '../../interfaces/ProductsList';
import UserAccount from '../../interfaces/UserAccount';
import { AppState } from '../../store';
import { productExibitionRules } from '../../utils/productsExibitionRules';
import FavoriteCard from './FavoriteCard/FavoriteCard';
import { CgSmileSad } from 'react-icons/cg';

import { Container, NoFavorites, PageContainer } from './styles';
import { useHistory } from 'react-router-dom';

const Favorite: React.FC = () => {
	const history = useHistory();
	const productsList: ProductsList[] = useSelector(
		(state: AppState) => state.productsList?.currentProductsList
	);
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);

	const [messageIsVisible, setVisibleMessage] = useState<boolean>(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if (user) {
			api.get('/get-user-favorites', {
				params: {
					user_id: user.id,
				},
			}).then((response: any) => {
				if (response.data.data.favoriteWithPhotos.length < 1) {
					setVisibleMessage(true);
				} else {
					setVisibleMessage(false);
				}
				dispatch(
					insertProducts(
						productExibitionRules(
							favoritesToProducts(
								response.data.data
									.favoriteWithPhotos as IFavorite[]
							),
							AreaTypes.FAVORITEPRODUCTSPAGE
						),
						AreaTypes.FAVORITEPRODUCTSPAGE
					)
				);
			});
		}
	}, [user]);

	const favoritesToProducts = (favorites: IFavorite[]) => {
		let tempProducts: Product[] = [];
		favorites.map((favoriteItem) => {
			tempProducts.push(favoriteItem.product);
		});
		return tempProducts;
	};

	return (
		<PageContainer>
			<h1>Meus Favoritos</h1>
			<div className="favoriteMobile">
				<p>Favoritos</p>
			</div>
			<Container>
				{productsList &&
					productsList
						.find(
							(item) =>
								item?.areaTitle ===
								AreaTypes.FAVORITEPRODUCTSPAGE
						)
						?.products?.map((product, index, array) => (
							<FavoriteCard
								product={product}
								userId={user.id}
							></FavoriteCard>
						))}
				{messageIsVisible && (
					<NoFavorites>
						<div className="emoji-and-text">
							<CgSmileSad color="#2B63FC" className="emoji" />
							<h2>Você ainda não possui favoritos</h2>
						</div>
						<button
							className="start-to-buy-button"
							onClick={() => history.push('/seller')}
						>
							Encontre seus produtos favoritos
						</button>
					</NoFavorites>
				)}
			</Container>
		</PageContainer>
	);
};

export default Favorite;
