import React, { useEffect } from 'react';

import {
	Container,
	LastViewsContainer,
	SuccessContainer,
	AlignmentContainer,
} from './styles';
import { insertProducts } from '../../../actions/ProductsList';
import api from '../../../api';
import { AreaTypes, ProductsList } from '../../../interfaces/ProductsList';
import { productExibitionRules } from '../../../utils/productsExibitionRules';
import { useDispatch, useSelector } from 'react-redux';
import { HiCheckCircle } from 'react-icons/hi';
import { AppState } from '../../../store';
import ProductCard from '../../Default/ProductCard/productCard';
import { useHistory, useParams } from 'react-router-dom';
import { Base64 } from 'js-base64';
interface PageParams {
	link: string;
	payment_method: string;
}

const PaymentSuccess: React.FC = () => {
	const dispatch = useDispatch();
	const productsList: ProductsList[] = useSelector(
		(state: AppState) => state.productsList?.currentProductsList
	);
	const history = useHistory();
	const pageParams = useParams<PageParams>();
	useEffect(() => {
		api.post('/list-products').then((response: any) => {
			dispatch(
				insertProducts(
					productExibitionRules(
						response.data.data.response,
						AreaTypes.HOMEPAGE
					),
					AreaTypes.HOMEPAGE
				)
			);
		});
	}, []);
	return (
		<Container>
			<AlignmentContainer style={{ background: '#ffffff' }}>
				<SuccessContainer>
					<div className="success-container">
						<HiCheckCircle size={43} fill="#02C384" />
						<div className="informations-and-buttons-container">
							<h2>Compra finalizada com sucesso!</h2>
							<p>
								Sua compra foi finalizada com sucesso. Acompanhe
								o andamento de envios e/ou pagamentos no seu
								histórico de compras
							</p>

							<div className="buttons">
								<button
									className="redirect-button"
									onClick={() => history.push('/seller')}
								>
									Ir para home
								</button>

								{pageParams.link === 'boleto' && (
									<button
										className="print-button"
										onClick={() =>
											window.open(
												`${Base64.decode(
													pageParams.link
												)}`
											)
										}
									>
										imprimir boleto
									</button>
								)}
								{pageParams.link === 'credit_card' && (
									<button
										className="print-button"
										onClick={() =>
											history.push('/my-purchases')
										}
									>
										Ir para histórico de compras
									</button>
								)}
							</div>
						</div>
						{pageParams.payment_method === 'boleto_pix' && (
							<img
								src={Base64.decode(pageParams.link)}
								alt="QR_CODE"
							/>
						)}
					</div>
				</SuccessContainer>
			</AlignmentContainer>
			<LastViewsContainer>
				<AlignmentContainer style={{ background: '#f5f5f5' }}>
					<div className="text-and-cards-container">
						<h2>Produtos mais pesquisados essa semana</h2>
						<div className="products-card">
							{productsList
								.find(
									(item) =>
										item?.areaTitle === AreaTypes.HOMEPAGE
								)
								?.products?.map((product, index: number) => (
									<>
										{index < 5 && (
											<ProductCard product={product} />
										)}
									</>
								))}
						</div>
					</div>
				</AlignmentContainer>
			</LastViewsContainer>
		</Container>
	);
};

export default PaymentSuccess;
