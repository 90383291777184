import React, { useEffect, useState } from 'react';
import {
	Container,
	DefaultRow,
	MoreColors,
	MoreColorsMobile,
	PromotionLabel,
	OriginalPrice,
} from './styles';

import config from '../../../config';
import { useHistory } from 'react-router-dom';
import ProductPage, { IProduct } from '../../../Pages/ProductPage/ProductPage';
import FavoriteIcon from '../FavoriteIcon/FavoriteIcon';
import { useSelector } from 'react-redux';
import UserAccount, { UserTypeEnum } from '../../../interfaces/UserAccount';
import { AppState } from '../../../store';
import { getFristImageSource } from '../../../utils/GetProductImage';
import useCheckMobileScreen from '../../../utils/useCheckMobileScreen';
import ReplacePatronizeProductModal from '../../Product/ReplacePatronizeProductModal/ReplacePatronizeProductModal';
import Modal from 'react-modal';
import api from '../../../api';
// import { Container, OriginalPrice } from './styles';
type Props = {
	product: IProduct;
	isReplace?: boolean;
	isSelectProduct?: boolean;
	updateSponsoredProduct?: (product_id: string) => void;
};

const ProductCard: React.FC<Props> = ({
	product,
	isReplace = false,
	isSelectProduct = false,
	updateSponsoredProduct,
}) => {
	const history = useHistory();
	const [isVisible, setVisible] = useState<boolean>(false);
	const [favoriting, setFavoriting] = useState<boolean>(false);
	const [showFavoriteIcon, SetShowFavoriteIcon] = useState<boolean>(false);
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
		);
		const [open, setOpen] = useState<boolean>(false);
		const [isMobile, setIsMobile] = useState<boolean>();
		const handleWindowSizeChange = () => {
			setIsMobile(window.innerWidth <= 768);
		};
	const getPriceString = (originalPrice: number):string => {
		return Number(originalPrice).toLocaleString('pt-BR', {minimumFractionDigits: 2, style: 'currency', currency:'BRL'}).replace('.','')
	}
	const priceString = getPriceString(product.price);

	useEffect(() => {
		setIsMobile(window.innerWidth <= 768);
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	const getCardClass = () => {
		let className = '';
		if (product.promotion && product.promotion > 0) {
			className += 'promotion-shadow ';
		}
		if (isMobile) {
			className += 'is-mobile ';
		}
		return className;
	};

	return (
		<>
			<Modal
				isOpen={open}
				onRequestClose={() => setOpen(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						zIndex: 1001,
						left: 0,
						right: 0,
						bottom: 0,
						background: '#0007',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						padding: '0 auto',
						bottom: 'auto',
						marginRight: '-50%',
						maxHeight: '100%',
						border: 'none',
						borderRadius: '11px',
						transform: 'translate(-50%, -50%)',
					},
				}}
			>
				<ReplacePatronizeProductModal
					setOpen={setOpen}
					open={open}
					productForAlteration={product}
				></ReplacePatronizeProductModal>
			</Modal>
			<Container
				onMouseOver={() => {
					setVisible(true);
					SetShowFavoriteIcon(true);
				}}
				onMouseLeave={() => {
					SetShowFavoriteIcon(false);
					setVisible(false);
				}}
				onClick={() => {
					if (!favoriting && !isReplace) {
						history.push(`/product/${product.id}/questions`);
						window.scroll(0, 0);
					} else {
						setFavoriting(false);
					}
					// 	// window.location.reload(); window.scrollTo(0, 0); }
				}}
				className={getCardClass()}
				/* style={{
					transform: isMobile ? 'scaleY(1.25)' : '',
				}} */
			>
				{product.product_variations && product.product_variations.length > 1 && (
					<>
						<MoreColors className="more-colors">
							+ cores disponiveis
						</MoreColors>
						<MoreColorsMobile className="more-colorsMobile">
							+ cores
						</MoreColorsMobile>
					</>
				)}

				{user &&
					user.user_types.name === UserTypeEnum.customer &&
					showFavoriteIcon && (
						<div onClickCapture={() => setFavoriting(true)}>
							<FavoriteIcon productId={product.id} />
						</div>
					)}
				<figure>
					<img
						src={getFristImageSource(product)}
						alt="imagem do produto"
					/>
				</figure>

				<div className="product-infos">
					<DefaultRow>
					{product.showcase ? (
						<h5 className="showcase">
						{product.title.substring(0, 19) +
							`${product.title.length > 19 ? '...' : ''}`}
						</h5>
					) : (
						<>
							<h5>
								{priceString
									.substring(
										0,
										priceString.length - 2
									)}
							</h5>
							<h5 className="small-text">
								{priceString
									.substring(
										priceString.length - 2,
										priceString.length
									)}
							</h5>
						</>
					)}
						
					</DefaultRow>
					{/* {product.promotion && product.promotion > 0 && (
						<OriginalPrice>
							R$ {product.original_price}
						</OriginalPrice>
					)} */}
					{((!product.promotion || (product.promotion && product.promotion === 0)) 
						&& parseInt(product.max_installments) > 0
						&& !product.showcase
						) && (
						<h3 style={{ marginTop: '0' }}>
							{' '}
							{product.max_installments +
								'x R$ ' +
								Math.round(
									product.price /
										parseInt(product.max_installments)
								)
									.toFixed(2)
									.replace('.', ',') +
								' sem juros'}{' '}
						</h3>
					)}

					{(isMobile || isVisible) && (
						<div className="hover-info">
							{product.showcase ? (
								<h4>
									{product.description.substring(0, 19) +
										`${product.description.length > 19 ? '...' : ''}`}
								</h4>
							) : (
								<h4>
									{product.title.substring(0, 19) +
										`${product.title.length > 19 ? '...' : ''}`}
								</h4>
							)}
						</div>
					)}
				</div>

				{isSelectProduct && isVisible && !isReplace && (
					// {user && user.user_types.name === 'Administrator' && SetIsReplace &&(
					<div className="select-product">
						<p
							onClick={() => {
								updateSponsoredProduct!(product.id);
							}}
						>
							Selecionar o produto
						</p>
					</div>
				)}
				{isReplace && isVisible && (
					// {user && user.user_types.name === 'Administrator' && SetIsReplace &&(
					<div className="replace">
						<p
							onClick={() => {
								setOpen(true);
							}}
						>
							Substituir
						</p>
					</div>
				)}
			</Container>
		</>
	);
};

export default ProductCard;
