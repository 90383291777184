import React, { useEffect, useRef, useState } from 'react';
import { Container, InfosContainer } from './styles';

import { RiShoppingCart2Line, RiStarFill, RiStarLine } from 'react-icons/ri';
import { RiTruckLine } from 'react-icons/ri';
import { ReactComponent as Cart } from '../../../assets/icons/cart.svg';
import { ReactComponent as DeliveryTruck } from '../../../assets/icons/deliveryTruck.svg';
import { Link, useHistory } from 'react-router-dom';
import config from '../../../config';
import { Product, ProductPhotos } from '../../../interfaces/Product';
import { IProduct } from '../../../Pages/ProductPage/ProductPage';
import { useDispatch, useSelector } from 'react-redux';
import {
	addProductCartAction,
	addSingleProductCartAction,
} from '../../../actions/ProductsCartActions';
import UserAccount, {
	PaymentMethodEnglishEnum,
	PaymentMethodPortugueseEnum,
	UserTypeEnum,
} from '../../../interfaces/UserAccount';
import { AppState } from '../../../store';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import {
	ProductVariations,
	VariationSizes,
} from '../../../interfaces/ProductVariations';
import { getFristVariationImageSource } from '../../../utils/GetProductImage';
import ProductsCart from '../../../interfaces/ProductsCart';
import { searchInCart } from '../../../utils/searchInCart';
import api from '../../../api';
import { AxiosResponse } from 'axios';
import { ShopQueryReturn } from '../../SellerStore/SellerStore';
import { insertCustomizations } from '../../../actions/ShopCustomizationsActions';
import { ShopCustomizations } from '../../../interfaces/ShopCustomizations';

type Props = {
	product: IProduct;
	setSelectedVariation: React.Dispatch<
		React.SetStateAction<ProductVariations | undefined>
	>;
};

const ProductSideInfosMobile: React.FC<Props> = ({
	product,
	setSelectedVariation,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [shopCustomization, setShopCustomization] = useState<
		ShopCustomizations | undefined
	>(undefined);
	const productsCart: ProductsCart[] = useSelector(
		(state: AppState) => state.productsCart.currentCart
	);

	const [info, setInfo] = useState<any | undefined>(undefined);
	useEffect(() => {
		api.get('/get-shop-info', {
			params: {
				shop_id: product.user_account_id,
			},
		}).then((response: AxiosResponse) => {
			setInfo(response.data.data);
		});
	}, []);

	const [isInnactive, setIsInnactive] = useState<boolean>(false);
	const [isStockAvailable, setIsStockAvailable] = useState<boolean>(true);
	const [productQuantity, setProductQuantity] = useState<number>(1);
	const [selectedColor, setSelectedColor] = useState<ProductVariations>();
	const [selectecSize, setSelectecdSize] = useState<VariationSizes>();
	const [sizeDivider, setSizeDivider] = useState<{
		start: number;
		end: number;
	}>({ start: 0, end: 4 });

	useEffect(() => {
		api.get('/get-shop-customizations', {
			params: {
				user_account_id: product.user_account_id,
			},
		}).then((response: AxiosResponse<ShopQueryReturn>) => {
			setShopCustomization(response.data.shop[0]);
		});
	}, [product]);

	const handleSizeDivider = (foward: boolean) => {
		if (foward && selectedColor!.variation_sizes.length > sizeDivider.end) {
			setSizeDivider({
				start: sizeDivider.start + 1,
				end: sizeDivider.end + 1,
			});
		} else if (!foward && 0 < sizeDivider.start) {
			setSizeDivider({
				start: sizeDivider.start - 1,
				end: sizeDivider.end - 1,
			});
		}
	};

	const handleCorlorChange = (variation: ProductVariations) => {
		setSelectedVariation(variation);
		setSelectecdSize(variation.variation_sizes[0]);
		setSelectedColor(variation);
		setSizeDivider({ start: 0, end: 4 });
	};

	const handleAddToCart = async () => {
		if (
			isStockAvailable &&
			(!user || (user && user.id !== product.user_account_id))
		) {
			let cartProduct = product;

			if (selectedColor && selectecSize) {
				//send product with only the
				//selected color and size

				let colorSize = selectedColor;
				if (selectedColor.variation_sizes.length > 1) {
					colorSize.variation_sizes = [selectecSize];
				} else {
					colorSize.variation_sizes = [];
				}
				cartProduct.product_variations = [colorSize];
			}
			const card_product_id = await searchInCart(
				productsCart,
				product.id
			);

			if (user) {
				dispatch(
					addSingleProductCartAction({
						parameters: {
							product: cartProduct,
							quantity: productQuantity,
							shippingMethod: 'withdraw',
							address: user.Address![0]!,
						},
						cart_product_id: card_product_id + 1,
						formOfPayment: 'credit_card',
						installments: 1,
					})
				);
			} else if (!user) {
				dispatch(
					addProductCartAction({
						parameters: {
							product: cartProduct,
							quantity: productQuantity,
							shippingMethod: 'withdraw',
							/* address: user.Address![0]!, */
						},
						cart_product_id: card_product_id + 1,
						formOfPayment: 'credit_card',
						installments: 1,
					})
				);
			}
			history.push(`/cart/${'product'}`);
		}
	};

	useEffect(() => {
		if (selectecSize) {
			if (selectecSize.stock >= productQuantity) {
				setIsStockAvailable(true);
			} else {
				setIsStockAvailable(false);
			}
		} else if (!selectecSize && parseInt(product.stock) >= productQuantity)
			setIsStockAvailable(true);
		else setIsStockAvailable(false);
	}, [productQuantity, selectecSize, product]);

	useEffect(() => {
		if (
			product.product_variations &&
			product.product_variations.length > 0
		) {
			setSelectedColor(product.product_variations[0]);
			setSelectecdSize(product.product_variations[0].variation_sizes[0]);
		}

		if (product.inactive_products) {
			setIsInnactive(true);
		} else {
			setIsInnactive(false);
		}
	}, [product]);

	return (
		<InfosContainer>
			<div className="action-buttons">
				<div className="cart-button-container">
					{!product.showcase &&
						<div className="delivery-informations-container">
							<RiTruckLine
								/* size={40} */
								fill={isInnactive ? '#353A40' : '#2B63FC'}
								className="delivery-icon"
							/>
							<div className="delivery-informations-card">
								<h4 className={isInnactive ? 'innactive' : ''}>
									Entrega{' '}
									{isInnactive ? 'indisponível' : 'disponível'}
								</h4>
							</div>
						</div>
					}
					{!isInnactive && !product.showcase && (
						<>
							<span className="delvirey-span">
								Calcule o valor do frete
							</span>
							<input type="text" placeholder="CEP"></input>
							{selectedColor &&
								selectedColor.variation_sizes.length > 1 && (
									<>
										<span
											className="delvirey-span"
											style={{
												fontSize: '16px',
												fontWeight: 500,
											}}
										>
											Escolha o tamanho
										</span>
										<div className="size-div">
											{/* {selectedColor */}
											{/* // .variation_sizes */}
											{/* // .length > 4 && ( */}
											<IoIosArrowBack
												size={'20px'}
												// fill={
												// sizeDivider.start >
												// 0
												// ? '#2B63FC '
												// : ''
												// }
												onClick={() =>
													handleSizeDivider(false)
												}
											/>
											{/* )} */}
											{selectedColor.variation_sizes
												.slice(
													sizeDivider.start
													// sizeDivider.end
												)
												.map((size) => {
													return (
														<div
															className={
																selectecSize ===
																	size
																	? 'size-option active-size'
																	: 'size-option'
															}
															onClick={() =>
																setSelectecdSize(
																	size
																)
															}
														>
															{size.size}
														</div>
													);
												})}
											{/* <div className="size-option">41</div>
									<div className="size-option">42</div>
									<div className="size-option">43</div>
									<div className="size-option">44</div> */}
											{/* {selectedColor */}
											{/* .variation_sizes */}
											{/* .length > 4 && ( */}
											<IoIosArrowForward
												size={'20px'}
												// fill={
												// sizeDivider.end <
												// selectedColor
												// .variation_sizes
												// .length
												// ? '#2B63FC '
												// : ''
												// }
												onClick={() =>
													handleSizeDivider(true)
												}
											/>
											{/* )} */}
										</div>
									</>
								)}
							<div className={'stock-container'}>
								<h3
									className={
										!isStockAvailable ? 'not-vailable' : ''
									}
								>
									{isStockAvailable
										? 'Estoque disponível!'
										: 'Estoque indisponível!'}
								</h3>
								<span>
									Quantidade:
									<select
										onChange={(e) => {
											setProductQuantity(
												parseInt(e.target.value)
											);
										}}
									>
										<option value="1">1 unidade</option>
										<option value="2">2 unidade</option>
										<option value="3">3 unidade</option>
										<option value="4">4 unidade</option>
										<option value="5">5 unidade</option>
									</select>
								</span>
								{product.product_variations &&
									product.product_variations.length > 1 && (
										<>
											<span
												style={{
													marginTop: '10px',
													fontWeight: 500,
												}}
											>
												Cor:{' ' + selectedColor?.color}
											</span>
											<div className="colors-div">
												{product.product_variations.map(
													(variation) => {
														return (
															<div
																className={
																	selectedColor ===
																		variation
																		? 'color-option active-color'
																		: 'color-option'
																}
															>
																<img
																	alt={
																		'cor ' +
																		variation.color
																	}
																	src={getFristVariationImageSource(
																		variation
																	)}
																	onClick={() => {
																		handleCorlorChange(
																			variation
																		);
																	}}
																/>
															</div>
														);
													}
												)}
											</div>
										</>
									)}
							</div>
							{((user &&
								user.user_types.name ===
								UserTypeEnum.customer) ||
								!user) && (
									<span
										className={
											'cart-button ' +
											(isStockAvailable ? '' : 'not-vailable')
										}
										/* className={`cart-button${product.user_account_id !== user?.id || !user? ''/* "buyer" : ''}`} */
										/*onClick={() => handleAddToCart()}*/
										onClick={() =>
											user ? (handleAddToCart()) : history.push('/login/header/')
										}
									>
										<RiShoppingCart2Line className="cart-icon" />
										<span className="cart-span">
											Adicionar ao carrinho
									</span>
									</span>
								)}
						</>
					)}
					<div className="seller-informations-container">
						<span>Vendedor</span>
						<h4
							style={{ cursor: 'pointer' }}
							onClick={() => {
								history.push(
									`/seller-area/${product.user_account_id}/all/0/100000000/undefined`
								);
							}}
						>
							{shopCustomization?.shop_name ||
								product.user_accounts.name}
						</h4>
						<span>Avaliação do vendedor</span>
						<div className="seller-avaliation">
							{[...Array(5)].map((star, index) =>
								index + 1 <= (info?.reviews_average || 0) ? (
									<RiStarFill />
								) : (
										<RiStarLine />
									)
							)}
						</div>
						{/* EU FIZ O CSS PARA MUDAR A COR 
							EXEMPLO COMENTADO ABAIXO*/}

						{/* <div className="seller-avaliation">
							<span className={`avaliation-${"bad"}`}>
								Ruim
							</span>
							<div>
								<div 
									className={`avaliation-${"bad"}-div`}
									style={{width:"82px"}}
								/>
							</div>
						</div> */}
					</div>
					{isInnactive && (
						<div className="innactive-info-container">
							Anúncio inativo pelo vendedor!
							<button>Avise-me</button>
						</div>
					)}
				</div>
			</div>
		</InfosContainer>
	);
};

export default ProductSideInfosMobile;
