import styled from 'styled-components';

export const Container = styled.div`

	.icon-container {
		.icon {
			visibility: visible;
		}
	}
`;

interface BannerProps {
	imagem: string;
}
export const Banner = styled.div<BannerProps>`
	background: #004d93;
	background-image: url(${(Prop) => Prop.imagem});
	height: 215px;
	background-position: center;
	background-size: cover;
	@media (max-width: 768px){
		background-repeat: no-repeat;
		background-size: cover;
		object-fit: cover;
	}

	span {
		font-size: 20px;
		color: #fff;
		font-family: var(--Roboto-font-family);
	}
	&:hover {
		.edit-button {
			visibility: visible;
		}
		.icon {
			visibility: visible;
		}
		.overlay {
			visibility: visible;
		}
	}
`;
export const StoreHeader = styled.div`
	.icon-container {
		.icon {
			visibility: visible;
		}
	}
`;

interface UserPhotoProps {
	image: string;
}
export const UserPhoto = styled.div<UserPhotoProps>`
	position: absolute;
	width: 162px;
	height: 162px;
	border-radius: 50%;
	background: #707070;
	overflow: overlay;
	margin-left: 30px;
	margin-top: 3.3rem;
	z-index: 1;
	background: ${(Prop) => Prop.image};
	background-size: cover;
	background-position: center;
	@media (max-width: 768px){
		left: 50%;
		transform: translateX(-70%);
	}

	&:hover {
		cursor: pointer;
		.overlay {
			visibility: visible;
		}
	}
	.overlay {
		visibility: hidden;
		z-index: 2;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background: rgb(0, 0, 0, 0.5);

		display: flex;
		justify-content: center;
		align-items: center;
		transition: all 0.3s;

		.text {
			color: white;
			font-family: var(--Roboto-font-family);
			font-weight: 500;
			font-size: min(22px, 1.9vw);

			margin-left: 8px;
		}
	}
`;
export const Card = styled.div`
	display: flex;
	height: 100%;
	flex-direction: row;
	/* max-height: 7rem; */
	background: #ffffff 0% 0% no-repeat padding-box;
	padding-top: 25px;
	padding-bottom: 20px;
	margin-top:-7px;
	@media (max-width: 768px){
		flex-direction: column;
		
	}
`;
export const LeftDiv = styled.div`
	color: #A7CA2D;
	font-size: 16px;
	font-family: var(--Roboto-font-family);
	margin-left: 9rem;
	@media (max-width: 768px){
		margin-top: 54px;
		margin-left: auto;
		margin-right: auto;
		max-width: 211px;
	}
	p {
		font-weight: bold;
		@media (max-width: 768px){
			text-align: center;
		}
	}
`;
export const ReputationBar = styled.div`
	width: 326px;
	height: 7px;
	background: #a7ca2d 0% 0% no-repeat padding-box;
	margin-top: 7px;
	margin-bottom: 19px;
	p{
		@media (max-width: 768px){
			padding-left: 18px;
		}
	}
	@media (max-width: 768px){
		width: 100%;
	}
`;

export const ReputationInfos = styled.div`
	display: flex;
	flex-direction: column;
	font-family: var(--Roboto-font-family);
	color: #5a5a5a;
	font-size: 14px;

	span {
		font-weight: bold;
		color: #959595;
		font-size: 17px;
		width: 40px;
		margin-right: 20px;
		text-align: center;
	}
`;
export const ReputationRow = styled.div`
	display: flex;
	flex-direction: row;
`;

interface IconVisible {
	isVisible: string;
}
export const RightDiv = styled.div<IconVisible>`
	font-size: 13px;
	color: #888888;
	font-family: var(--Roboto-font-family);
	margin-right: 380px;
	margin-bottom: 60px;
	margin-left: 200px;
	padding-left: 20px;
	@media (max-width: 768px){
		margin-left: 20px;
	}
	p{
		@media (max-width: 768px){
			/* min-height: 100%; */
		}
		@media (min-width: 768px){
			min-width: 200px;
		}
	}

	.isMobile{

	.buttonEditDescricaoMobile{
		display: flex;
    	width: 100%;
		margin-top: 2%;
 	   	margin-left: 17%;
		P{
			color: white;
			padding-left: 5px;
			
		}
	}
		.icon-containerMobile{
			display: none;
			@media (max-width: 768px){
				margin-top: 20px;
				border: 1px solid black;
				background: black;
				border-radius: 20px;
				width: 180px;
				height: 37px;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				visibility: visible;
			}

		}
		
	}
	@media (max-width:2000px){
		margin-right: 60px;
	}

	&:hover {
		.icon-container {
			.icon {
				visibility: visible;
				@media (max-width: 768px){
					visibility: hidden;
				}
			}
		}
	}
	textarea {
		resize: none;
		border: solid 1px #cecece;
		border-radius: 6px;
		width: 100%;
		height: 70px;
		padding: 5px 15px;
	}

	.icon-container {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		width: 100%;
		height: 40%;
		/* @media (min-width: 768px){
			display:none;
		} */

		.icon {
			visibility: ${(Prop) => Prop.isVisible};
			background: black;
			padding: 5px;
			border-radius: 50%;
		}
	}
`;
export const StoreName = styled.div`
	span {
		display: flex;
		align-items: center;
		font-size:24px;
		font-weight:700;
		color:#000;
		margin-bottom:15px;
		text-align: center;

		.icon {
			visibility: hidden;
			margin-left: 10px;
			background: black;
			padding: 5px;
			border-radius: 50%;
		}
	}
	.edit-input-container {
		position: relative;
		top: 130px;
		left: 195px;

		display: flex;
		flex-direction: row;
		align-items: center;

		.edit {
			background: transparent;
			border: solid 1px #cecece;
			border-radius: 6px;

			height: 28px;
			position: relative;
			width: 290px;
		}

		.icon {
			margin-left: 10px;
			background: black;
			padding: 5px;
			border-radius: 50%;
		}
	}

	&:hover {
		.icon {
			visibility: visible;
		}
	}
`;
export const EditBannerButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;

	.edit-button {
		background: rgb(0, 0, 0, 0.3);
		width: 170px;
		height: 41px;

		margin-right: 10px;
		margin-top: 10px;

		border-radius: 12px;
		padding: 0px 15px;

		display: flex;
		align-items: center;
		justify-content: space-around;

		visibility: hidden;

		&:hover {
			cursor: pointer;
		}

		.edit-text {
			font-size: 13px;
			color: white;
			font-family: var(--Roboto-font-family);
			font-weight: 600;
		}
	}
`;
