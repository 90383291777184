import styled from 'styled-components';

export const Container = styled.div`
	font-family: var(--Roboto-font-family);
	font-size: 16px;
	form {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	h6 {
		font-size: 16px;
		color: #2579fc;
		margin-bottom: 10px;
	}
	label {
		font-size: 14px;
		color: #8a99a8;
	}
	input {
		color: #5a646e;
		border: 1px solid #8a99a8;
		border-radius: 8px;
		width: 314px;
		height: 30px;
		margin-bottom: 14px;
		padding: 0 10px;
	}
	.button {
		display: flex;
		justify-content: center;
	}
	button {
		background: #2b63fc 0% 0% no-repeat padding-box;
		border-radius: 8px;
		color: #fff;
		border: none;
		font-weight: bold;
		width: 208px;
		height: 30px;
	}
`;
