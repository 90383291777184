import React from 'react';
import PaymentCartMethod from '../../../components/Cart/ShippingMethodCart/PaymentCartMethod/PaymentCartMethod';
import Header from '../../../components/Default/header';

import { Container } from './styles';

const ShippingMethodPage2: React.FC = () => {
	return (
		<Container>
			<Header />
			<PaymentCartMethod />
		</Container>
	);
};

export default ShippingMethodPage2;
