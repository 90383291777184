import React, { useEffect, useState } from 'react';
import gol from '../../../assets/exemple.jpg';
import { RiStarFill, RiStarHalfFill, RiStarLine } from 'react-icons/ri';
import {
	Container,
	InputsRow,
	RowItem,
	DefaultRow,
	MoreColors,
	ColImgMobile,
	ContainerMobile,
	ColDetails,
	ColTitleAndStar,
	ColPrice,
	ColRemove,
} from './styles';
import { IProduct } from '../../../Pages/ProductPage/ProductPage';
import config from '../../../config';
import { NavLink } from 'react-router-dom';
import api from '../../../api';
import { getFristImageSource } from '../../../utils/GetProductImage';
import { useDispatch, useSelector } from 'react-redux';
import { removeUserFavorite } from '../../../actions/UserActions';
import UserAccount from '../../../interfaces/UserAccount';
import { AppState } from '../../../store';

type Props = {
	product: IProduct;
	userId: string;
};

const FavoriteCard: React.FC<Props> = ({ product, userId }) => {
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const dispatch = useDispatch();

	const [ratingAverage, setRatingAverage] = useState<number>(0);

	useEffect(() => {
		if(!product.product_reviews) { setRatingAverage(0); return; }


		const rating = product.product_reviews.reduce(
			(accumulator, currentValue) => accumulator + currentValue.rating,
			0
		);

		setRatingAverage(rating / product.product_reviews.length);
	}, [product])

	const removeFavorite = async () => {
		if (user && user.Favorites) {
			const item = user.Favorites.find(
				(item) => item.product_id === product.id
			);

			if (item) {
				await api
					.post('/remove-user-favorite-product', {
						favorite_id: item.id,
					})
					.then(() => {
						dispatch(removeUserFavorite(item.id));
					});
			}
		}
	};

	return (
		<>
			<ContainerMobile>
				<ColImgMobile>
					<NavLink to={`/product/${product.id}/questions`}>
						<img
							src={getFristImageSource(product)}
							alt="imagem do produto"
						/>
					</NavLink>
				</ColImgMobile>
				<ColDetails>
					<ColTitleAndStar>
						<NavLink to={`/product/${product.id}/questions`}>
							<h4>{product.title}</h4>
						</NavLink>
						<div className="stars">
							{[...Array(5)].map((star, index) => (
								index + 1 <= ratingAverage ? 
									<RiStarFill/> 
									:
									<RiStarLine/>
							))}
							<span>{ product.product_reviews ? product.product_reviews.length : 0 } opiniõe(s)</span>
						</div>
						{product.product_variations!.length > 1 && (
							<MoreColors className="more-colors">
								+ cores disponiveis
							</MoreColors>
						)}
					</ColTitleAndStar>
					<ColPrice>
						<div className="row-price">
							<h2>
								{'R$ ' +
									String(product.price)
										.replace('.', ',')
										.substring(
											0,
											product.price.toString().length - 2
										)}
							</h2>
							<h2 className="small-text-mobile">
								{String(product.price)
									.replace('.', ',')
									.substring(
										product.price.toString().length - 2,
										product.price.toString().length
									)}
							</h2>
						</div>
						{parseInt(product.max_installments) > 0 && (
							<div className="row">
								<p>
									em{' '}
									{product.max_installments +
										'x de R$ ' +
										Math.round(
											product.price /
												parseInt(product.max_installments)
										)
											.toFixed(2)
											.replace('.', ',') +
										' sem juros'}
								</p>
							</div>
						)}
						
					</ColPrice>
					<ColRemove>
						<span onClick={() => removeFavorite()}>
							Remover dos favoritos
						</span>
					</ColRemove>
				</ColDetails>
			</ContainerMobile>
			<Container>
				<InputsRow>
					<RowItem>
						<NavLink to={`/product/${product.id}/questions`}>
							<img
								src={getFristImageSource(product)}
								alt="imagem do produto"
							/>
						</NavLink>
					</RowItem>
					<RowItem>
						<NavLink to={`/product/${product.id}/questions`}>
							<h4>{product.title}</h4>
						</NavLink>
						{product.product_variations!.length > 1 && (
							<MoreColors className="more-colors">
								+ cores disponiveis
							</MoreColors>
						)}
						{/* <span>200 unidades disponíveis</span>  */}
						<DefaultRow>
							<h2>
								{'R$ ' +
									String(product.price)
										.replace('.', ',')
										.substring(
											0,
											product.price.toString().length - 2
										)}
							</h2>
							<h2 className="small-text">
								{String(product.price)
									.replace('.', ',')
									.substring(
										product.price.toString().length - 2,
										product.price.toString().length
									)}
							</h2>
						</DefaultRow>
						{parseInt(product.max_installments) > 0  && (
							<p>
								em{' '}
								{product.max_installments +
									'x de R$ ' +
									Math.round(
										product.price /
											parseInt(product.max_installments)
									)
										.toFixed(2)
										.replace('.', ',') +
									' sem juros'}
							</p>
						)}
						
					</RowItem>
					<RowItem style={{ marginLeft: 'auto' }}>
						<div className="bottom-container">
							<span onClick={() => removeFavorite()}>
								Remover dos favoritos
							</span>
							<div className="stars">
								<RiStarFill />
								<RiStarFill />
								<RiStarHalfFill />
								<RiStarLine />
								<RiStarLine />
								<span>2732</span>
							</div>
						</div>
					</RowItem>
				</InputsRow>
			</Container>
		</>
	);
};

export default FavoriteCard;
