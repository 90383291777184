import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { insertMobileHeader } from '../../actions/MobileHeaderActions';
import Header from '../../components/Default/header';
import SellerArea from '../../components/SellerArea/SellerArea';
import SellerStore from '../../components/SellerStore/SellerStore';
import { SearchPreset } from '../../interfaces/MobileHeader';
import UserAccount from '../../interfaces/UserAccount';
import { AppState } from '../../store';

import { Container } from './styles';
import { UserTypeEnum } from '../../interfaces/UserAccount';

interface PageParams {
	userId: string;
	category: string;
	state: string;
	min_price: string;
	max_price: string;
}
const SellerAreaPage: React.FC = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		if(!window.location.href.includes('/products')&& 
				!window.location.href.includes('/sales')&& 
				!window.location.href.includes('/reputation')&& 
				!window.location.href.includes('/income')) {
					dispatch(insertMobileHeader(SearchPreset))
		}
	});
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const pageParams = useParams<PageParams>();
	return (
		<Container>
			<Header />
			{(user?.id === pageParams.userId && user.user_types.name === UserTypeEnum.seller)? (
				<SellerArea userId={pageParams.userId} />
			) : (
				<SellerStore />
			)}
		</Container>
	);
};

export default SellerAreaPage;
