import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { insertProducts } from '../../actions/ProductsList';
import api from '../../api';
import { AreaTypes, ProductsList } from '../../interfaces/ProductsList';
import { IProduct } from '../../Pages/ProductPage/ProductPage';
import { AppState } from '../../store';
import { productExibitionRules } from '../../utils/productsExibitionRules';
import ProductCard from '../Default/ProductCard/productCard';
import {
	ProductCategories,
	ProductRegister,
} from '../ProductRegister/ProductRegister';
import ResultCard from './ResultCard/ResultCard';
import { Link, useHistory } from 'react-router-dom';

import { Container, PageContainer, Select } from './styles';
import UserAccount from '../../interfaces/UserAccount';

interface PageParams {
	search_query: string;
	search_type: string;
	state: string;
	min_price: string;
	max_price: string;
}
export interface ProductsCategories {
	categories: [{ id: string; name: string }];
}

const SearchCard: React.FC = () => {
	const pageParams = useParams<PageParams>();
	const [products, setProducts] = useState<IProduct[]>();
	const history = useHistory();
	const [
		productCategories,
		setProductCategories,
	] = useState<ProductCategories>();
	const [stores, setStores] = useState<UserAccount[]>();
	const [productInfos, setProductInfos] = useState<ProductRegister>();
	const [messageIsVisible, setVisibleMessage] = useState<boolean>(false);
	const [selectedCategory, setSelectedCategory] = useState<string>('');
	const [minValue, setMinValue] = useState<string>('0');
	const [maxValue, setMaxValue] = useState<string>('100000000');

	const dispatch = useDispatch();
	const productsList: ProductsList[] = useSelector(
		(state: AppState) => state.productsList?.currentProductsList
	);
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [categoryNumber, setCategoryNumber] = useState<number>(3);
	const [shopNumber, setShopNumber] = useState<number>(3);
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const handleWindowSizeChange = () => {
		setIsMobile(window.innerWidth < 768);
	};

	useEffect(() => {
		if (!isMobile) {
			setShopNumber(100);
			setCategoryNumber(100);
		}
		else{
			setShopNumber(3);
			setCategoryNumber(3);
		}
	}, [isMobile])

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	useEffect(() => {
		api.get('/get-all-shops')
			.then((response: any) => {
				setStores(response.data.shops);
				//setSearchStores(response.data.shops);
			})
			.catch((err) => {});
	}, []);

	useEffect(() => {
		api.get('/get-product-categories')
			.then((response: AxiosResponse<ProductCategories>) => {
				setProductCategories(response.data);
			})
			.catch((err) => {});
	}, []);
	useEffect(() => {
		if (pageParams.search_type === 'category') {
			api.get(`/get-product-by-category`, {
				params: {
					category_id: pageParams.search_query,
					state: pageParams.state,
					min_price: pageParams.min_price,
					max_price: pageParams.max_price,
				},
			}).then((response: any) => {
				if (response.data.products.length == 0) {
					setVisibleMessage(true);
				} else {
					setVisibleMessage(false);
				}
				dispatch(
					insertProducts(
						productExibitionRules(
							response.data.products,
							AreaTypes.SEARCHPAGE
						),
						AreaTypes.SEARCHPAGE
					)
				);
			});
		} else {
			api.get(`/search-product`, {
				params: {
					search_query: pageParams.search_query,
					state: pageParams.state,
					min_price: pageParams.min_price,
					max_price: pageParams.max_price,
				},
			}).then((response: any) => {
				if (response.data.products.length == 0) {
					setVisibleMessage(true);
				} else {
					setVisibleMessage(false);
				}
				dispatch(
					insertProducts(
						productExibitionRules(
							response.data.products,
							AreaTypes.SEARCHPAGE
						),
						AreaTypes.SEARCHPAGE
					)
				);
			});
		}
	}, [pageParams]);

	return (
		<PageContainer>
			<Container>
				<header>
					{!messageIsVisible && (
						<>
							<h2>
								{pageParams.search_type === 'query'
									? pageParams.search_query + ''
									: `${
											productCategories?.categories.find(
												(item: any) =>
													item.id ===
													pageParams.search_query
											)?.name
									  }`}
							</h2>
						</>
					)}
					{messageIsVisible && <h2>Nenhum resultado encontrado</h2>}
				</header>
				<div className="bottom-block">
					<div className="filters">
						<div className="filterRow">
							<div>
								<h6>Lojas associadas</h6>
								<ul className="store-list">
									{stores
										?.slice(0, shopNumber)
										.map((store) => (
											<li
												onClick={(e) => {
													if (
														user &&
														store.id === user.id
													) {
														history.push(
															`/my-store/${store.id}/all/0/100000000/undefined`
														);
													} else {
														history.push(
															`/seller-area/${store.id}/all/0/100000000/undefined`
														);
													}
												}}
											>
												{
													store.shop_customizations
														?.shop_name
												}
											</li>
										))}
									{isMobile &&
										stores &&
										stores.length > shopNumber && (
											<span
												className="more"
												onClick={() =>
													setShopNumber(
														shopNumber + 3
													)
												}
											>
												ver mais
											</span>
										)}
									{isMobile &&
										stores &&
										stores.length < shopNumber && (
											<span
												className="more"
												onClick={() =>
													setShopNumber(
														shopNumber - 3
													)
												}
											>
												ver mais
											</span>
										)}
								</ul>
							</div>
							<div style={{ marginBottom: '25px' }}>
								<h6>Categoria</h6>
								<ul className="store-list">
									{productCategories?.categories
										.slice(0, categoryNumber)
										.map((category) => (
											<li
												id={
													pageParams.search_query ==
													category.id
														? 'activated'
														: ''
												}
												onClick={() =>
													history.push(
														`/search/${category.id}/category/${pageParams.state}/${pageParams.min_price}/${pageParams.max_price}`
													)
												}
											>
												{category.name}
											</li>
										))}
									{isMobile &&
										productCategories?.categories &&
										productCategories.categories.length >
											categoryNumber && (
											<span
												className="more"
												onClick={() =>
													setCategoryNumber(
														categoryNumber + 3
													)
												}
											>
												ver mais
											</span>
										)}
									{isMobile &&
										productCategories?.categories &&
										productCategories.categories.length <
											categoryNumber && (
											<span
												className="more"
												onClick={() =>
													setCategoryNumber(
														categoryNumber - 6
													)
												}
											>
												ver menos
											</span>
										)}
								</ul>
							</div>
						</div>
						<div className="filterRow">
							<div>
								<h6>Condição</h6>
								<ul>
									<li
										id={
											pageParams.state === 'new'
												? 'activated'
												: ''
										}
										onClick={() =>
											history.push(
												`/search/${pageParams.search_query}/${pageParams.search_type}/new/${pageParams.min_price}/${pageParams.max_price}`
											)
										}
									>
										Novo
									</li>
									<li
										id={
											pageParams.state === 'used'
												? 'activated'
												: ''
										}
										onClick={() =>
											history.push(
												`/search/${pageParams.search_query}/${pageParams.search_type}/used/${pageParams.min_price}/${pageParams.max_price}`
											)
										}
									>
										Usado
									</li>
								</ul>
							</div>
							<div>
								<h6>Preço</h6>
								<ul>
									<li
										id={
											pageParams.min_price === '0' &&
											pageParams.max_price === '70'
												? 'activated'
												: ''
										}
										onClick={() =>
											history.push(
												`/search/${pageParams.search_query}/${pageParams.search_type}/${pageParams.state}/0/70`
											)
										}
									>
										até R$ 70
									</li>
									<li
										id={
											pageParams.min_price === '70' &&
											pageParams.max_price === '200'
												? 'activated'
												: ''
										}
										onClick={() =>
											history.push(
												`/search/${pageParams.search_query}/${pageParams.search_type}/${pageParams.state}/70/200`
											)
										}
									>
										R$ 70 a R$ 200
									</li>
									<li
										id={
											pageParams.min_price === '200' &&
											pageParams.max_price === '100000000'
												? 'activated'
												: ''
										}
										onClick={() =>
											history.push(
												`/search/${pageParams.search_query}/${pageParams.search_type}/${pageParams.state}/200/100000000`
											)
										}
									>
										mais de R$ 200
									</li>
								</ul>
							</div>
						</div>
						<div className="filters-input">
							<input
								type="text"
								placeholder="Minimo"
								style={{ marginRight: '20px' }}
								onChange={(e) => setMinValue(e.target.value)}
								onKeyPress={(e) => {
									if (
										e.nativeEvent.key === 'Enter' &&
										minValue.length > 0
									) {
										history.push(
											`/search/${pageParams.search_query}/${pageParams.search_type}/${pageParams.state}/${minValue}/${maxValue}`
										);
									}
								}}
							/>
							<input
								type="text"
								placeholder="Máximo"
								onChange={(e) => setMaxValue(e.target.value)}
								onKeyPress={(e) => {
									if (
										e.nativeEvent.key === 'Enter' &&
										maxValue.length > 0
									) {
										history.push(
											`/search/${pageParams.search_query}/${pageParams.search_type}/${pageParams.state}/${minValue}/${maxValue}`
										);
									}
								}}
							/>
						</div>
						<span
							onClick={() =>
								history.push(
									`/search/${pageParams.search_query}/${pageParams.search_type}/all/0/100000000`
								)
							}
						>
							Limpar Filtros
						</span>
					</div>

					<div className="search-results">
						{productsList
							?.find(
								(item) =>
									item?.areaTitle === AreaTypes.SEARCHPAGE
							)
							?.products?.map((product) => (
								<ResultCard product={product} />
							))}
					</div>
				</div>
			</Container>
		</PageContainer>
	);
};

export default SearchCard;
