import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { insertMobileHeader } from '../../actions/MobileHeaderActions';
import Header from '../../components/Default/header';
import Home from '../../components/HomePage/Home';
import MobileHeader, {
	BackPreset,
	SearchPreset,
} from '../../interfaces/MobileHeader';
import { AppState } from '../../store';

import { Container } from './styles';

const HomePage: React.FC = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(insertMobileHeader(SearchPreset));
	});
	return (
		<Container>
			<Header />
			<Home />
		</Container>
	);
};

export default HomePage;
