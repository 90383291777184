import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { insertUser } from '../../actions/UserActions';
import api from '../../api';
import { cpf } from 'cpf-cnpj-validator';
import { cpfMask } from '../../utils/mask';
import Input from '../Default/Input/Input';
import InputWithMask from '../Default/InputWithMask/InputWithMask';
import HeaderAdmin from '../HeaderAdmin/HeaderAdmin';
import RegisterAdmModal from './RegisterAdmModal/RegisterAdmModal';

import { Container, PasswordError } from './styles';
import { FormInput } from 'semantic-ui-react';
import { InputAndErrorMessage } from '../UserRegister.tsx/styles';
import Axios from 'axios';

interface CreateAdmInputs {
	name: string;
	phone: string;
	email: string;
	date_birth: string;
	password: string;
	confirmPassword: string;
	CPF: string;
	street: string;
	number: string;
	complement: string;
	city: string;
	state: string;
	zip_code: string;
}

const Administrator: React.FC = () => {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const history = useHistory();
	const [errorPassword, setErrorPassword] = useState<boolean>(false);
	const { register, handleSubmit, errors } = useForm<CreateAdmInputs>();
	const dispacth = useDispatch();
	const [formIsValid, setFormIsValid] = useState({
		document_cpf: true,
		zipCode: true,
		dateBirth: true,
	});
	const [addressInformations, setAddressInformations] = useState({
		street: '',
		state: '',
		city: '',
	});

	async function getZipCode(value: string) {
		if (value.replace('-', '').length === 8) {
			Axios.get(`https://brasilapi.com.br/api/cep/v1/${value}`)
				.then((res) => {
					setAddressInformations({
						street: res.data.street,
						city: res.data.city,
						state: res.data.state,
					});
				})
				.catch((err) => {});
		}
	}

	function showFieldError(is_valid: boolean, is_valid_at_form: any) {
		if (!is_valid || is_valid_at_form) {
			return 'solid 1px red';
		} else {
			return 'solid 1px #8a99a8';
		}
	}

	function validateDocument(value: string) {
		setFormIsValid({
			...formIsValid,
			document_cpf: cpf.isValid(
				value.replaceAll('.', '').replace('-', '')
			),
		});
	}

	const onSubmit = (data: CreateAdmInputs) => {
		console.log(data);
		if (data.password === data.confirmPassword) {
			try {
				api.post('/user-register-adm', {
					name: data.name,
					CPF: data.CPF,
					Contacts: {
						phone: data.phone,
					},
					email: data.email,
					date_birth: data.date_birth,
					password: data.password,
					Address: {
						street: data.street,
						number: data.number,
						complement: data.complement,
						city: data.city,
						state: data.state,
						zip_code: data.zip_code,
					},
					// UserTypeEnum: {
					// 	user_types: 'Administrator',
					// },
				}).then((response: any) => {
					alert('Dados enviados com sucesso');
				});
			} catch (error) {
				alert('Falha ao alterar os dados');
			}
		} else {
			console.log(data.password);
			console.log(data.confirmPassword);
			setErrorPassword(true);
		}
	};
	return (
		<>
			<RegisterAdmModal open={openModal} setOpen={setOpenModal} />
			<HeaderAdmin />
			<Container>
				<div className="category-text">Cadastro de administradores</div>
				<div className="container-cards">
					<div className="container-form">
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="test">
								<div className="margin-bottom">
									<p>Dados pessoais</p>
								</div>
								<InputWithMask
									placeholder="Nome"
									label="Dados pessoais"
									flex=""
									className="form-input"
									margin="40px"
									name="name"
									style={{
										border: `${showFieldError(
											true,
											errors.name
										)}`,
									}}
									ref={register({
										required: true,
										minLength: {
											value: 1,
											message: 'Formato inválido',
										},
									})}
									{...register('name')}
								/>
								<InputWithMask
									mask="document_cpf"
									ref={register({
										required: true,
										minLength: {
											value: 14,
											message:
												'Formato inválido ex: xxx.xxx.xxx-xx',
										},
									})}
									style={{
										border: `${showFieldError(
											true,
											errors.CPF
										)}`,
									}}
									name="CPF"
									className="form-input"
									maxLength={14}
									placeholder={'CPF'}
								/>
								<FormInput
									flex="0"
									className="birth_date"
									{...register('date_birth')}
									ref={register({
										required: true,
										minLength: {
											value: 8,
											message: 'Formato inválido',
										},
									})}
									type="date"
								/>
								<InputWithMask
									mask="phone_number"
									{...register('number')}
									ref={register({
										required: true,
										minLength: {
											value: 14,
											message: 'Formato inválido',
										},
									})}
									style={{
										border: `${showFieldError(
											true,
											errors.phone
										)}`,
									}}
									name="phone"
									className="form-input"
									placeholder="Telefone"
								/>
							</div>
							<div className="test">
								<label></label>
								<div className="margin-bottom">
									<p>Endereço</p>
								</div>

								<InputWithMask
									ref={register({
										required: true,
										minLength: {
											value: 8,
											message:
												'Formato inválido ex: xxx.xxx.xxx-xx',
										},
									})}
									style={{
										border: `${showFieldError(
											formIsValid.zipCode,
											errors.zip_code
										)}`,
									}}
									name="zip_code"
									className="form-input"
									onChange={(e) => getZipCode(e.target.value)}
									maxLength={14}
									placeholder={'CEP'}
									mask="zip_code"
								/>
								{!formIsValid.zipCode && (
									<InputAndErrorMessage>
										<span className="field-validation-error">
											Cep inválido
										</span>
									</InputAndErrorMessage>
								)}
								<div className="row-input">
									<InputWithMask
										placeholder="Endereço"
										label=" "
										flex=""
										className="form-input"
										margin="40px"
										name="street"
										value={addressInformations.street}
										onChange={(e) =>
											setAddressInformations({
												...addressInformations,
												street: e.target.value,
											})
										}
										style={{
											border: `${showFieldError(
												true,
												errors.street
											)}`,
										}}
										ref={register({
											required: true,
											minLength: {
												value: 1,
												message: 'Formato inválido',
											},
										})}
										{...register('street')}
									/>
									<InputWithMask
										placeholder="Nº"
										label=" "
										flex=""
										className="form-input-left"
										margin="40px"
										name="number"
										style={{
											border: `${showFieldError(
												true,
												errors.number
											)}`,
										}}
										ref={register({
											required: true,
											minLength: {
												value: 1,
												message: 'Formato inválido',
											},
										})}
										{...register('number')}
									/>
								</div>
								<InputWithMask
									placeholder="Complemento"
									label=" "
									flex=""
									className="form-input"
									margin="40px"
									name="complement"
									style={{
										border: `${showFieldError(
											true,
											errors.complement
										)}`,
									}}
									ref={register({
										required: false,
									})}
									{...register('complement')}
								/>
								<div className="row-input">
									<InputWithMask
										placeholder="Cidade"
										label=" "
										flex=""
										className="form-input"
										margin="40px"
										name="city"
										style={{
											border: `${showFieldError(
												true,
												errors.city
											)}`,
										}}
										ref={register({
											required: true,
											minLength: {
												value: 1,
												message: 'Formato inválido',
											},
										})}
										{...register('city')}
									/>
									<InputWithMask
										placeholder="UF"
										label=" "
										flex=""
										className="form-input-left"
										margin="40px"
										name="state"
										style={{
											border: `${showFieldError(
												true,
												errors.state
											)}`,
										}}
										ref={register({
											required: true,
											minLength: {
												value: 1,
												message: 'Formato inválido',
											},
										})}
										{...register('state')}
									/>
								</div>
								<button
									type="submit"
									// onClick={() => {
									// setOpenModal(true);
									// }}
								>
									Finalizar cadastro
								</button>
							</div>
							<div className="test">
								<label></label>
								<div className="margin-bottom">
									<p>Dados pessoais</p>
								</div>
								<InputWithMask
									placeholder="E-mail"
									label=" "
									flex=""
									className="form-input"
									margin="40px"
									name="email"
									style={{
										border: `${showFieldError(
											true,
											errors.email
										)}`,
									}}
									ref={register({
										required: true,
										minLength: {
											value: 1,
											message: 'Formato inválido',
										},
									})}
									{...register('email')}
								/>
								{/* <FormInput
									placeholder="E-mail"
									className="form-input-email"
									// flex="1"
									name="email"
									{...register('email')}
									ref={register()}
								/> */}
								<InputWithMask
									type="password"
									placeholder="Senha"
									label=" "
									flex=""
									className="form-input"
									margin="40px"
									name="password"
									style={{
										border: `${showFieldError(
											true,
											errors.password
										)}`,
									}}
									ref={register({
										required: true,
										minLength: {
											value: 1,
											message: 'Formato inválido',
										},
									})}
									{...register('password')}
								/>
								<InputWithMask
									type="password"
									placeholder="Confirmar senha"
									label=" "
									flex=""
									className="form-input"
									margin="40px"
									name="confirmPassword"
									style={{
										border: `${showFieldError(
											true,
											errors.password
										)}`,
									}}
									ref={register({
										required: true,
										minLength: {
											value: 1,
											message: 'Formato inválido',
										},
									})}
									{...register('password')}
								/>
								{errorPassword && (
									<PasswordError>
										Senha incorreta
									</PasswordError>
								)}
							</div>
						</form>
					</div>
				</div>
			</Container>
		</>
	);
};

export default Administrator;
