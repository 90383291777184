import React, { useState } from 'react';
import { ReactComponent as SearchIcon } from '../../../assets/icons/searchIcon.svg';
import api from '../../../api';
import { useDispatch } from 'react-redux';
import { insertProducts } from '../../../actions/ProductsList';
import { productExibitionRules } from '../../../utils/productsExibitionRules';
import { AreaTypes } from '../../../interfaces/ProductsList';
import { Container, FiltersContainer, NoSell } from './styles';
import SalesCard from './SalesCard/SalesCard';
import { useEffect } from 'react';
import { CgSmileSad } from 'react-icons/cg';

const Sales: React.FC = () => {
	const [searchQuery, setSearchQuery] = useState('');
	const dispatch = useDispatch();
	const [userSells, setUserSells] = useState<any[]>([]);
	const [selectedOption, setSelectedOption] = useState('onGoing');

	const search = () => {
		api.get('/get-active-products-search', {
			params: { search_query: searchQuery, inactive: false },
		}).then((response) => {
			dispatch(
				insertProducts(
					productExibitionRules(
						response.data.products,
						AreaTypes.MYPRODUCTSPAGE
					),
					AreaTypes.MYPRODUCTSPAGE
				)
			);
		});
	};

	useEffect(() => {
		api.get('/get-user-sells')
			.then((res) => {
				setUserSells(res.data.foundCharges);
			})
			.catch((err) => {});
	}, []);

	return (
		<Container>
			<FiltersContainer>
				<div className="toggle">
					<label>Status</label>
					<div className="options">
						<div
							className={`option-on-going ${
								selectedOption === 'onGoing' ? 'active' : ''
							}`}
							onClick={() => setSelectedOption('onGoing')}
						>
							<span>Em andamento</span>
						</div>
						<div
							className={`option-delivered ${
								selectedOption === 'delivered' ? 'active' : ''
							}`}
							onClick={() => setSelectedOption('delivered')}
						>
							<span>Entregues</span>
						</div>
						<div
							className={`option-canceled ${
								selectedOption === 'canceled' ? 'active' : ''
							}`}
							onClick={() => setSelectedOption('canceled')}
						>
							<span>Cancelados</span>
						</div>
					</div>
				</div>
				<div className="search-container">
					<div className="search-card">
						<SearchIcon
							className="search-icon"
							onClick={() => search()}
						/>
						<input
							type="text"
							onChange={(e) => setSearchQuery(e.target.value)}
						/>
					</div>
				</div>
			</FiltersContainer>

			{userSells &&
				userSells.length > 0 &&
				userSells.map((item: any) => (
					<SalesCard key={item.id} userSell={item} />
				))}
			{userSells && userSells.length < 1 && (
				<NoSell>
					<div className="emoji-and-text">
						<CgSmileSad color="#2B63FC" className="emoji" />
						<h2>Nenhuma venda realizada</h2>
					</div>
				</NoSell>
			)}
		</Container>
	);
};

export default Sales;
