import React from 'react';

import { Container } from './styles';
import Associated from './Associated/Associated';
import { useHistory } from 'react-router-dom';

const Featured: React.FC = () => {
	const history = useHistory();

	return (
		<Container>
			<div className="text-button">
				<p>Lojas destacadas na página inicial</p>
				<span onClick={() => history.push('/seller')}>Ver no site</span>
			</div>
			<>
				<Associated />
			</>
		</Container>
	);
};

export default Featured;
