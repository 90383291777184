import styled from 'styled-components';

export const Container = styled.div`
	max-width: 380px;
	height: 269px;
	background: white;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 12px;
	padding-left: 17px;
	box-sizing: border-box;
	h1 {
		color: #5a646e;
		font-size: 14px;
		margin-bottom: 31px;
	}
	h2 {
		color: #707070;
		font-size: 12px;
		margin-bottom: 10px;
	}
	p {
		font-size: 14px;
		color: #5a646e;
	}
	.description {
		margin-top: 33px;
		p {
			margin-bottom: 22px;
		}
	}
	.type-id {
		position: relative;
		display: flex;
		justify-content: space-between;
		height: 40px;
		padding-top: 10px;
		box-sizing: border-box;
		margin-right: 5%;
		padding-top: 22px;
		p {
			font-size: 11px;
			margin-bottom: 33px;
			color: #707070;
			right: 0px;
		}
	}
	.type-id::after {
		position: absolute;
		content: '';
		width: 100%;
		height: 1px;
		margin-top: 29px;
		background: #00000029;
	}
	.buttons-cards {
		margin-top: 21px;
		display: flex;
		flex-direction: row;
		font-size: 12px;
		width: 96%;
	}
	.button-check {
		/* width: 180px; */
		width: 165px;
		height: 34px;
		text-align: center;
		border: 1px solid #2b62fa;
		border-radius: 8px;
		color: #2b62fa;
		padding-top: 7px;
		margin-right: 11px;
		box-sizing: border-box;
	}
	.button-going {
		svg {
			width: 15px;
			height: 15px;
		}
		display: flex;
		justify-content: center;
		flex-direction: row;
		width: 165px;
		height: 34px;
		text-align: center;
		border: 1px solid #2b62fa;
		border-radius: 8px;
		background: #2b62fa;
		color: white;
		padding-top: 7px;
		box-sizing: border-box;
		p {
			color: white;
			font-size: 12px;
			padding-left: 10px;
		}
	}
`;
