import styled from 'styled-components';

export const ReviewContainer = styled.div`
	:root {
		--color: rebeccapurple;
	}
	flex: 1.1;
	display: flex;
	flex-direction: column;
	row-gap: 15px;
	align-items: center;
	width: max-content;
	max-width: max-content;
	@media (max-width: 768px) {
		max-width: 90%;
		width: 90%;
		padding: 32px;
		/* display: none; */
	}
	.review {
		height: max-content;
		background: white;
		border-radius: 12px;
		min-width: 264px;

		max-width: 318px;

		display: flex;
		flex-direction: column;
		padding: 24px;
		@media (max-width: 768px) {
			min-width: 100%;
			display: none;
		}
		.titleShipping {
			span {
				font-size: 16px;
				color: #353a40;
			}
		}
		.selectShipping {
			margin-left: 20px;
			label {
				font-size: 12px;
				color: #2b63fc;
				margin-left: 5px;
			}
			button {
				display: block;
				color: #2d327c;
				background: #f5f5f5;
				border: none;
				margin: 20px 35px;
				height: 16px;
				width: 80%;
			}
		}

		.products {
			display: flex;
			flex-direction: column;
			padding-bottom: 8px;
			@media (max-width: 768px) {
				display: none;
			}
			.static-text {
				color: #5a646e;
				font-size: min(14px, 1vw);
				font-family: var(--Roboto-font-family);
				font-weight: normal;

				margin-bottom: 5px;
			}
			.products-name {
				color: #8a99a8;
				font-size: min(14px, 1vw);
				line-height: 20px;
				font-family: var(--Roboto-font-family);
				font-weight: normal;
			}
		}
		.shipping-cost-container {
			display: flex;
			flex-direction: column;
			padding: 8px 0;

			border-top: solid 1px #eaeef3;
			@media (max-width: 768px) {
				/* display: none; */
			}

			.title {
				color: #5a646e;
				font-size: min(14px, 1vw);
				font-family: var(--Roboto-font-family);
				font-weight: normal;

				margin-bottom: 10px;
			}

			.shipping-cost-and-time {
				display: flex;
				align-items: center;

				.cost {
					display: flex;
					flex-direction: column;

					h3 {
						color: #72ba6c;
						font-size: min(12px, 1vw);
						font-family: var(--Roboto-font-family);
						font-weight: 400;
					}
					span {
						color: #b1b1b1;
						font-size: min(12px, 1vw);
						font-family: var(--Roboto-font-family);
						font-weight: 400;

						&.green-span {
							font-size: 20px;
							color: #02c384;
							font-weight: normal;
							margin-left: 8px;
						}
					}
				}
			}
		}
		.total-container {
			padding-top: 16px;
			display: flex;
			flex-direction: row;
			align-items: flex-end;

			border-top: solid 1px #eaeef3;
			@media (max-width: 768px) {
				display: none;
			}
			.total-value-title {
				color: #5a646e; /* 
      font-size: 14px; */
				font-size: min(14px, 1vw);
				font-family: var(--Roboto-font-family);
				font-weight: normal;
			}
			.value {
				display: flex;
				align-items: flex-end;

				h2 {
					color: #20256c;
					font-family: var(--Roboto-font-family);
					font-size: min(24px, 1.8vw);
					font-weight: normal;
					margin-left: 8px;
				}

				.small-text {
					font-size: 14px;
					margin-left: 0;
				}
				span {
					color: #919191;
					font-size: min(11px, 1vw);
					font-family: var(--Roboto-font-family);
					font-weight: 400;

					align-self: flex-end;
					margin-bottom: 3px;
					margin-left: 3px;
				}
			}
			.items-quantity {
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background: #959498;

				align-self: center;
				margin-top: 15px;
			}
		}
	}
	.review-actions {
		display: flex;
		flex: 1;
		justify-content: center;
		min-height: 40px;
		max-height: 40px;
		column-gap: 15px;
		width: 100%;
		@media (max-width: 768px) {
			position: fixed;
			width: 93%;
			bottom: 11px;
		}

		.back-button {
			flex: 1;
			border: 1px solid #2b63fc;
			border-radius: 8px;

			color: #2b63fc;
			font-size: min(16px, 1vw);
			font-family: var(--Roboto-font-family);
			font-weight: medium;
			@media (max-width: 768px) {
				display: none;
			}
		}
		.next-button {
			flex: 2;
			border-radius: 8px;
			border: none;
			background: #02c384;

			color: #ffffff;
			font-size: min(16px, 1vw);
			font-family: var(--Roboto-font-family);
			font-weight: medium;
			@media (max-width: 768px) {
				font-size: 16px;
				border-radius: 0px;
				margin-left: -5%;
				position: absolute;
				bottom: -12px;
				height: 58px;
				width: 100vw;
			}
		}
	}
`;
