import React, { useEffect, useState } from 'react';

import { Container, AlignmentContainer } from './styles';
import footerLogo from '../../assets/footerLogo.png';
import instagramIcon from '../../assets/contactIcons/instagram.svg';
import { ReactComponent as LogoAcp } from '../../assets/icons/acpLogoLogin.svg';
import {ReactComponent as TwitterIcon} from '../../assets/contactIcons/twitter.svg';
import {ReactComponent as YoutubeIcon} from '../../assets/contactIcons/youtube.svg';
import { AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';
import { AiFillFacebook } from 'react-icons/ai';
import linkedinIcon from '../../assets/contactIcons/linkedin.svg';
import faceIcon from '../../assets/contactIcons/face.svg';
import WhatsAppIcon from '../../assets/contactIcons/whats.svg';
import securityIcon from '../../assets/securityIcon.png';
import { FaLinkedinIn } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import ACPlace_reduzido   from '../../assets/acpLogo/ACPlace_reduzido.png';
import { userInfo } from 'os';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import UserAccount from '../../interfaces/UserAccount';
import { UserTypeEnum } from '../../interfaces/UserAccount';

const Footer: React.FC = () => {
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);

	return (
		<>
			<Container>
				<AlignmentContainer>
					<div className="top-area">
						<div className="logo-and-contact-icons">
            <img 
              alt="Logo Acp"
              width={280}
              height={74}
              src={ACPlace_reduzido}
            />
							{/* <LogoAcp
								width={230}
								height={46}
							></LogoAcp> */}
							<div className="icons">
								<a href="https://www.youtube.com/channel/UC_Fv6vabeSwixIrhIl_GBIw" target="_blank">
									<YoutubeIcon/>
								</a>
								<a href="https://www.linkedin.com/company/associa-o-comercial-do-paran-/" target="_blank">
									<AiFillLinkedin fill="#002793" />
								</a>
								<a href="https://twitter.com/acpdigital" target="_blank">
									<TwitterIcon/>
								</a>
								<a href="https://www.facebook.com/acpdigital/" target="_blank">
									<AiFillFacebook fill="#002793"/>
								</a>
								<a href='https://www.instagram.com/acpdigital/' target='_blank'>
									<AiFillInstagram fill='#002793'/>
								</a>
							</div>
						</div>
						<div className="area-item">
							<h3>Institucional</h3>
							<div className="links">
								<Link to="/about-us" className="link">
									História
								</Link>
								<Link to="/about-us" className="link">
									Diretoria
								</Link>
								<Link to="/about-us" className="link">
									Conselhos
								</Link>
							</div>
						</div>

						<div className="area-item">
							<h3>Minha conta</h3>
							<div className="links">
								{user ? (
									<Link to="/profile/user-information" className="link">
										Meu perfil
									</Link>
								)  : (
									<Link to="/register/header" className="link">
										Cadastre-se
									</Link>
								)}
									<Link to="/payment-methods" className="link">
										Formas de Pagamento
									</Link>
							</div>
						</div>

						<div className="area-item">
							<h3>Contato</h3>
							<div className="links">
								<Link to="/faq" className="link">
									Dúvidas da plataforma
								</Link>
								<a href="https://wa.me/5541987049679" className="link">
									WhatsApp - (41) 98704-9679
								</a>
								<p className="link">
									Fone - (41) 3320-2929
								</p>
								<p className="link">
									Email - lojistas@bomplace.com.br
								</p>
							</div>
						</div>

						<div className="area-item">
							<h3>Associados</h3>
							<div className="links">
								<a
									href="https://acpr.com.br/associe-se/" 
									className="link"
									target="_blaak">
									Torne-se um associado
								</a>
								<a
									href="https://acpr.com.br/" 
									className="link"
									target="_blaak">
									Portal ACP
								</a>
							</div>
						</div>
						
						<div className="area-item">
							<h3>Redes Sociais</h3>
							<div className="links">
								<a 
									className="link"
									href="https://www.youtube.com/channel/UC_Fv6vabeSwixIrhIl_GBIw"
									target="_blank">
									Youtube
								</a>
								<a 
									className="link"
									href="https://www.linkedin.com/company/associa-o-comercial-do-paran-/"
									target="_blank">
									Linkedin
								</a>
								<a 
									className="link"
									href="https://twitter.com/acpdigital"
									target="_blank">
									Twitter
								</a>
								<a 
									className="link"
									href="https://www.facebook.com/acpdigital/"
									target="_blank">
									Facebook
								</a>
								<a
									className='link'
									href='https://www.instagram.com/acpdigital/'
									target='_blank'
								>Instagram</a>
							</div>
						</div>
					</div>
				</AlignmentContainer>
					<div className="bottom-area">
						<div className="container">
							<span>
								ACP - Associação comercial do Paraná 2021- Todos os
								direitos reservados - CNPJ n.º Rua xv de Novembro
								621 / Curitiba, Paraná / CEP 80020-310
							</span>
							<img src={securityIcon} alt="" />
						</div>
					</div>
			</Container>
		</>
	);
};

export default Footer;
