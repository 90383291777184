import React, { forwardRef, InputHTMLAttributes, useState } from 'react';
import {
	cardExpirationDate,
	cardNumberMask,
	cnpjMask,
	cpfMask,
	phoneNumberMask,
	zipCodeMask,
} from '../../../utils/mask';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	mask?:
		| 'card_number'
		| 'document_cpf'
		| 'document_cnpj'
		| 'phone_number'
		| 'card_expiring_date'
		| 'zip_code';
}

const InputWithMask: React.ForwardRefRenderFunction<
	HTMLInputElement,
	InputProps
> = ({ ...rest }, ref) => {
	const [inputValue, setValue] = useState<string>('');

	function chooseMask(value: string) {
		switch (rest.mask) {
			case 'card_number':
				setValue(cardNumberMask(value));
				break;
			case 'card_expiring_date':
				setValue(cardExpirationDate(value));
				break;
			case 'document_cpf':
				setValue(cpfMask(value));
				break;
			case 'document_cnpj':
				setValue(cnpjMask(value));
				break;
			case 'phone_number':
				setValue(phoneNumberMask(value));
				break;
			case 'zip_code':
				setValue(zipCodeMask(value));
				break;
			default:
				setValue(value);
				break;
		}
	}
	return (
		<input
			ref={ref}
			value={inputValue}
			{...rest}
			onInput={(e: any) => chooseMask(e.target.value)}
		/>
	);
};

export default forwardRef(InputWithMask);
