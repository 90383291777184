import styled from 'styled-components';

export const Container = styled.div`
	width: 290px;
	height: 205px;
	background: white;
	border-radius: 8px;
	font-family: var(--Roboto-font-family);
	display: flex;
	flex-direction: column;
	padding: 93px 54px 82px 54px;
	row-gap: 50px;

	h1 {
		text-align: center;
		font-size: 20px;
		font-weight: medium;
		color: #5a646e;
	}

	span {
		font-size: 12px;
		font-weight: normal;
		text-align: center;
		color: #5a646e;
	}

	button {
		font-size: 16px;
		font-weight: medium;
		border: none;
		background-color: #2b63fc;
		padding: 10px 0;
		color: #fff;
		border-radius: 8px;
	}
`;
