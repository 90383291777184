import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import api from '../../api';
import { ReactComponent as LogoAcp } from '../../assets/icons/acpLogoLogin.svg';
import { IoIosArrowDown } from 'react-icons/io';
import { RiSearch2Line } from 'react-icons/ri';
import {
	Container,
	ForgotPassword,
	LoginCardContainer,
	PageContainer,
} from './styles';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { insertUser } from '../../actions/UserActions';
import { AxiosResponse } from 'axios';
import ForgotPasswordModal from '../Default/PublicHeader/ForgotPasswordModal/ForgotPasswordModal';

interface LoginInputs {
	email: string;
	password: string;
}
interface PageParams {
	from_page: string;
}
const LoginAdmin: React.FC = () => {
	const pageParams = useParams<PageParams>();
	const { register, handleSubmit, errors } = useForm<LoginInputs>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	});
	const [errorMessage, setErrorMessage] = useState('');
	const history = useHistory();
	const dispatch = useDispatch();
	const [modalIsOpen, setIsOpen] = useState(false);
	const onSubmit = (data: any) => {
		api.post('/authenticate', {
			data,
		})
			.then((res: AxiosResponse<any>) => {
				if (res.status !== 400 && res.status !== 404) {
					localStorage.setItem('authorization', res.data.token);
					localStorage.setItem(
						'refresh_token',
						res.data.refreshToken
					);

					dispatch(insertUser(res.data.user));

					switch (pageParams.from_page) {
						case 'cart':
							history.push(`/cart/shipping-method/${'login'}`);
							window.location.reload();
							break;
						default:
							history.push('/seller');
							break;
					}
					if (pageParams.from_page === 'cart') {
					} else {
						window.location.href = '/seller';
					}
				} else {
				}
			})
			.catch((error: any) => {
				if (
					parseInt(error.message.replace(/([^\d])+/gim, '')) === 400
				) {
					setErrorMessage('Senha ou usuário invalido');
				} else if (
					parseInt(error.message.replace(/([^\d])+/gim, '')) === 404
				) {
					setErrorMessage('Senha ou usuário invalido');
				}
			});
	};

	function closeModal() {
		setIsOpen(false);
	}

	function handleOpenModal() {
		setIsOpen(true);
	}

	return (
		<PageContainer>
			<Container>
				<Modal
					isOpen={modalIsOpen}
					onRequestClose={() => setIsOpen(false)}
					style={{
						overlay: {
							position: 'fixed',
							top: 0,
							zIndex: 1001,
							left: 0,
							right: 0,
							bottom: 0,
							background: '#0007',
						},
						content: {
							top: '50%',
							left: '50%',
							right: 'auto',
							padding: '0 auto',
							bottom: 'auto',
							marginRight: '-50%',
							maxHeight: '100%',
							border: 'none',
							borderRadius: '11px',
							transform: 'translate(-50%, -50%)',
						},
					}}
				>
					<ForgotPasswordModal closeModal={() => setIsOpen(false)} />
				</Modal>

				<LogoAcp className="logo" />
				<LoginCardContainer onSubmit={handleSubmit(onSubmit)}>
					<div className="login-card">
						<div className="input-container">
							<input
								type="text"
								name="email"
								placeholder="E-mail"
								ref={register({
									required: true,
									pattern: {
										value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
										message: 'Insira um email válido',
									},
								})}
								style={{
									border: `${
										errors.email
											? 'solid 1px red'
											: 'solid 1px #8a99a8'
									}`,
									marginBottom: '32px',
								}}
							/>
							{errors.email?.message ? (
								<span
									style={{
										color: 'red',
										fontSize: '10px',
										textDecoration: 'underline',
									}}
								>
									{errors.email.message}
								</span>
							) : (
								<></>
							)}
						</div>
						<div className="input-container">
							<input
								type="password"
								name="password"
								placeholder="Senha"
								ref={register({
									required: true,
								})}
							/>
							{errorMessage.length > 0 ? (
								<span
									style={{
										color: 'red',
										fontSize: '10px',
										textDecoration: 'underline',
									}}
								>
									{errorMessage}
								</span>
							) : (
								<></>
							)}
						</div>
						<ForgotPassword
							onClick={handleOpenModal}
							style={{ marginTop: '10px' }}
						>
							Esqueci minha senha
						</ForgotPassword>
						<div className="login-actions">
							<button className="login-button" type="submit">
								Entrar
							</button>
						</div>
					</div>
				</LoginCardContainer>
			</Container>
		</PageContainer>
	);
};

export default LoginAdmin;
