import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import api from '../../../api';
import { ReactComponent as LogoAcp } from '../../../assets/icons/acpLogoLogin.svg';
import { IoIosArrowDown } from 'react-icons/io';
import { RiSearch2Line } from 'react-icons/ri';

import {
	BottomHeader,
	BottomHeaderButtons,
	Container,
	AlignmentContainer,
	DefaultColumn,
	DefaultRow,
	ForgotPassword,
	Infos,
	LoginCardContainer,
	Logo,
	MarketColumn,
	Search,
	SearchBox,
	SearchIcon,
	SerchRow,
	TopHeader,
	TopHearderButtons,
	ButtonIconContainer,
	DropDownCard,
	DropDownItem,
	ContainerColumn,
	TopMargin,
	MobileLogoBox,
} from './styles';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { insertUser } from '../../../actions/UserActions';
import logo from '../../../assets/acpLogo.png';
import { AxiosResponse } from 'axios';
import { ProductCategories } from '../../ProductRegister/ProductRegister';
import ForgotPasswordModal from './ForgotPasswordModal/ForgotPasswordModal';
import ACPlace_reduzido   from '../../../assets/acpLogo/ACPlace_reduzido.png';
import StoreDropDown from './StoreDropDown/StoreDropDown';
import MobileMenu from './MobileMenu/MobileMenu';
import MobileLeftComponent from './MobileLeftComponent/MobileLeftComponent';
import acpLogo from '../../../assets/acpLogo.png';
import useCheckMobileScreen from '../../../utils/useCheckMobileScreen';
import MobileHeader from '../../../interfaces/MobileHeader';
import { AppState } from '../../../store';
import CategoryDropDown from '../AuthHeader/CategoryDropDown/CategoryDropDown';

interface LoginInputs {
	email: string;
	password: string;
}

const PublicHeader: React.FC = () => {
	const history = useHistory();
	const [showLoginCard, setShowLoginCard] = useState<boolean>(false);
	const { register, handleSubmit, errors } = useForm<LoginInputs>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	});
	const mobileHeader: MobileHeader = useSelector(
		(state: AppState) => state.mobileHeader.currentMobHeader
	);
	const [cookies, setCookie, removeCookie] = useCookies(['jid']);
	const dispatch = useDispatch();
	const [errorMessage, setErrorMessage] = useState('');
	const [searchQuery, setSearchQuery] = useState('');
	const [
		productCategories,
		setProductCategories,
	] = useState<ProductCategories>();
	const onSubmit = (data: any) => {
		api.post('/authenticate', {
			data,
		})
			.then((res: AxiosResponse<any>) => {
				if (res.status !== 400 && res.status !== 404) {
					localStorage.setItem('authorization', res.data.token);
					dispatch(insertUser(res.data.user));
					// window.location.href = '/';
				} else {
				}
			})
			.catch((error: any) => {
				if (
					parseInt(error.message.replace(/([^\d])+/gim, '')) === 400
				) {
					setErrorMessage('Senha ou usuário invalido');
				} else if (
					parseInt(error.message.replace(/([^\d])+/gim, '')) === 404
				) {
					setErrorMessage('Senha ou usuário invalido');
				}
			});
	};
	useEffect(() => {
		api.get('/get-product-categories')
			.then((response: AxiosResponse<ProductCategories>) => {
				setProductCategories(response.data);
			})
			.catch((err) => { });
	}, []);

	const [modalIsOpen, setIsOpen] = useState(false);
	const [showCategories, setShowCategories] = useState(false);
	const [showStores, setShowStores] = useState(false);

	function closeModal() {
		setIsOpen(false);
	}

	function handleOpenModal() {
		setIsOpen(true);
	}

	return (
		<>
			<TopMargin />
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => setIsOpen(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						zIndex: 1001,
						left: 0,
						right: 0,
						bottom: 0,
						background: '#0007',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						padding: '0 auto',
						bottom: 'auto',
						marginRight: '-50%',
						maxHeight: '100%',
						border: 'none',
						borderRadius: '11px',
						transform: 'translate(-50%, -50%)',
					},
				}}
			>
				<ForgotPasswordModal closeModal={() => setIsOpen(false)} />
			</Modal>
			<Container
				onKeyPress={(e) => {
					if (e.nativeEvent.key === 'Enter' && searchQuery.length > 0) {
						history.push(`/search/${searchQuery}/query/all/0/100000000`);
					}
				}}
			>
				<AlignmentContainer>
					<ContainerColumn>
						<MobileLeftComponent />
						<TopHeader>
            	{/* keep logo ratio (280X74) */}
							<img 
								className="hide-mobile"
								alt="Logo Acp"
								width={200}
								height={52.857}
								style={{
									marginRight: '56px',
									marginTop: '6px',
									cursor: 'pointer',
								}}
								src={ACPlace_reduzido}
								onClick={() => history.push('/seller')}
							/>
							<DefaultColumn>
								{mobileHeader.middleComp.middle === 1 && (
									<MobileLogoBox>
										<img
										alt="Logo Acp"
										width={102}
										height={30}
										style={{
											marginTop: '8px',
											cursor: 'pointer',
										}}
										src={acpLogo}
										onClick={() => history.push('/seller')}
										/>
									</MobileLogoBox>
								)}
								{(!useCheckMobileScreen() || mobileHeader.middleComp.middle === 0) && (
									<SearchBox>
										<SerchRow>
											<SearchIcon>
												{' '}
												<RiSearch2Line
													style={{ fill: '#2d327c' }}
													onClick={(e) => {
														if (
															searchQuery.length > 0
														) {
															history.push(
																`/search/${searchQuery}/query/all/0/100000000`
															);
															e.preventDefault();
														}
													}}
												/>{' '}
											</SearchIcon>
											<Search
												type="text"
												onChange={(e) =>
													setSearchQuery(e.target.value)
												}
												/* placeholder="Buscar produtos, marcas e muito mais..." */
												placeholder="Quero comprar..."
											></Search>
										</SerchRow>
									</SearchBox>
								)}
								<BottomHeader>
									<ButtonIconContainer
										className={showCategories? "active" : ""}
										onMouseLeave={() => setShowCategories(false)}
										onClick={() =>setShowCategories(true)}
									>
										<BottomHeaderButtons
											className={showCategories? "active-text have-icon" : "have-icon"} 
										>
											Categorias
										</BottomHeaderButtons>
										<IoIosArrowDown	className="header-icon"	/>
										{showCategories && (
											<DropDownCard
												onMouseLeave={() =>
													setShowCategories(false)
												}
												onMouseEnter={() =>
													setShowCategories(true)
												}
											>
												<CategoryDropDown />
											</DropDownCard>
										)}
									</ButtonIconContainer>
									<ButtonIconContainer
										className={showStores? "active" : ""}
										onMouseLeave={() =>setShowStores(false)}
										onClick={() => setShowStores(true)}
									>
										<BottomHeaderButtons
											className={showStores? "active-text have-icon" : "have-icon"} 
										>
                      						Lojas associadas
                    					</BottomHeaderButtons>
										<IoIosArrowDown
										className="header-icon"
										/>
										{showStores && (
											<DropDownCard
												onMouseLeave={() =>
													setShowStores(false)
												}
												onMouseEnter={() =>
													setShowStores(true)
												}
											>
												<StoreDropDown />
											</DropDownCard>
										)}
									</ButtonIconContainer>
									<BottomHeaderButtons
										onClick={() =>
											history.push('/about-us')
										}
									>
										Quem somos
									</BottomHeaderButtons>
									<BottomHeaderButtons
										onClick={() => history.push('/join-us')}
									>
										Associe-se
									</BottomHeaderButtons>
								</BottomHeader>
							</DefaultColumn>
							<TopHearderButtons
								onClick={() => history.push('/login/header/')}
							>
								Entrar
							</TopHearderButtons>
						</TopHeader>
						<MobileMenu />
					</ContainerColumn>
				</AlignmentContainer>
			</Container>
		</>
	);
};

export default PublicHeader;
