import { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import api from '../../api';
import {
	Purchase,
	UserPurchases,
} from '../../interfaces/ApiResponses/UserPurchases';

import PurchaseItem from './PurchaseItem/PurchaseItem';
import { Container, NoPurchase, PageTitle, PurchasesCard } from './styles';
import { CgSmileSad } from 'react-icons/cg';
import { useHistory } from 'react-router-dom';

const MyPurchases: React.FC = () => {
	const [userPurchases, setUserPurchases] = useState<Purchase[]>([]);
	/* const [userPurchase, setUserPurchase] = useState<Purchase>({
		id: "string",
		amount: 5,
		ebanx_charge_id: "string",
		paid_at: "string",
		link: "string",
		code: "string",
		delivery_address_id: "string",
		payment_method: "string",
		pix_id: "string",
		pix_imageInBase64: "string",
		user_account_id: "string",
		product_id: "string",
		created_at: new Date(),
		updated_at: new Date(),
		status_id: "string",
		status: {
			id: "string",
			name: "string",
			pt_name: "string",
		},
		charge_products: [{
			id: "string",
			product_id: "string",
			charge_id: "string",
			created_at: new Date(),
			updated_at: new Date(),
			quantity: 11,
			products: {
				id: "string",
				title: "Nome do produto grande gigante",
				sub_title: "string",
				active: true,
				on_demand: true,
				stock: "string",
				max_installments: 11,
				height: 11,
				width: 11,
				use_state: "string",
				price: "string",
				thickness: 11,
				format: 11,
				weight: 11,
				description: "string",
				model: "string",
				material: "string",
				created_at: new Date(),
				updated_at: new Date(),
				category_id: "string",
				user_account_id: "string",
				brand: "string",
				user_accounts: {
					id: "string",
					name: "string",
					CPF: "string",
					social_reason: "string",
					date_birth: "string",
					email: "string",
					password: "string",
					created_at: new Date(),
					updated_at: new Date(),
					user_photo_id: "string",
					shop_id: "string",
					signup_token: "string",
					ebanx_resource_token: "string",
					ebanx_account_id: "string",
					ebanx_account_status: "string",
					user_type_id: "string",
					user_photo: "string",
				},
				product_photos: [
					{
						id: "string",
						name: "string",
						product_id: "string",
						created_at: new Date(),
						updated_at: new Date(),
					}
				],
			},
			variation_id: "string",
			variation_size_id: "string",
			product_variations: {
				id: "string",
				color: "string",
				hex: "string",
				created_at: "string",
				updated_at: "string",
		
				variation_photos: [
					{
						id: "string",
						name: "string",
						product_variations_id: "string",
					}
				],
			},
			variation_sizes: {
				id: "string",
				size: "string",
				stock: 11,
			},
		},],
	}); */
	const history = useHistory();
	useEffect(() => {
		api.get<any, AxiosResponse<UserPurchases>>('/get-user-purchases').then(
			(res) => {
				setUserPurchases(res.data.foundPurchases);
			}
		);
	}, []);
	return (
		<Container>
			<PageTitle>Histórico de compras</PageTitle>
			<PurchasesCard>
				{/* <PurchaseItem purchase={userPurchase} />
				<PurchaseItem purchase={userPurchase} />
				<PurchaseItem purchase={userPurchase} /> */}
				{userPurchases.length > 0 &&
					userPurchases.map((item: Purchase) => (
						<PurchaseItem purchase={item} />
					))}
				{userPurchases.length === 0 && (
					<NoPurchase>
						<div className="emoji-and-text">
							<CgSmileSad color="#2B63FC" className="emoji" />
							<h2>Você ainda não possui compras</h2>
						</div>
						<button
							className="start-to-buy-button"
							onClick={() => history.push('/seller')}
						>
							Comece a comprar
						</button>
					</NoPurchase>
				)}
			</PurchasesCard>
		</Container>
	);
};

export default MyPurchases;
