import styled from 'styled-components';
interface InputSize {
	width: string;
}
export const InputContainer = styled.div<InputSize>`
	flex: ${(Prop) => Prop.width};
	display: flex;
	flex-direction: column;

	label {
		font-family: 'Open sans';
		font-weight: bold;
		font-size: 0.4rem;
		color: #969696;
		margin-bottom: 4px;
	}

	input {
		height: calc(40px - 11px - 11px);
		border: 1px solid #8a99a8;
		border-radius: 8px;
		padding: 11px 5px 11px 10px;
		background: none;
		margin-bottom: 24px;

		font-family: 'Roboto';
		font-weight: normal;
		font-size: 14px;
		color: #3b3d4a;

		::placeholder {
			font-style: italic;
			color: #8a99a8;
		}
	}
`;
