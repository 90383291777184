import styled from 'styled-components';

export const Container = styled.main`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	row-gap: 12px;
`;
export const ContainerTitle = styled.div`
	display: flex;
	justify-content: space-between;
	h2 {
		color: #000000;
		font-size: 24px;
		font-family: var(--Roboto-font-family);
		font-weight: 300;
	}
`;

export const TrackingAndButtonsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	height: 50%;

	margin-top: 16px;
	column-gap: 12px;
	.products-info {
		flex: 4;
		border: solid 1px #c5ced7;
		border-radius: 8px;
		height: 100%;
		padding: 24px;
		box-sizing: border-box;

		font-family: var(--Roboto-font-family);

		>h1 {
			font-size: 16px;
			color: gray;

			margin-bottom: 12px;
		}

		>p {
			margin: 6px 0;
			font-size: 14px;
		}
	}

	.buttons-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;

		row-gap: 9px;
		flex: 2;
		.button-default {
			width: 100%;
			height: 34px;
			border-radius: 8px;
			border: none;

			font-family: var(--Roboto-font-family);
			font-size: 16px;
			font-weight: 400;
		}

		.gray {
			background: #707070;
			color: white;
		}
		.transparent {
			background: transparent;
			color: #707070;
			border: solid 1px #707070;
		}
		.transparent-red {
			color: #e32f47;
			background: transparent;
			border: solid 1px #e32f47;
		}
		.red {
			background: #e32f47;
			color: white;
		}
		.seller-report-link {
			color: #1c27cc;
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			font-size: 12px;
			text-decoration: underline;
		}
	}
`;
export const ProductsAndAvaliationContainer = styled.div`
	display: flex;
	justify-content: space-between;
	column-gap: 12px;
	height: 50%;
	.product-container {
		display: flex;
		flex-direction: column;
		border: solid 1px #c5ced7;
		border-radius: 8px;
		padding: 23px;
	}

	.avaliation-container {
		border: solid 1px #c5ced7;
		border-radius: 8px;

		display: flex;
		flex-direction: column;

		flex: 2;
		padding: 23px;
	}

	> div {
		flex: 4;
		.title {
			margin-bottom: 15px;
			h3 {
				color: #20256c;
				font-family: var(--Roboto-font-family);
				font-weight: 400;
				font-size: 16px;
			}
		}
		.cancel {
			> p {
				margin: 12px 0;

				font-family: var(--Roboto-font-family);
				font-size: 18px;
			}
		}
		.item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: solid 1px #c5ced7;
			padding-bottom: 20px;
			margin-bottom: 20px;

			cursor: pointer;

			.photo-and-title {
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				.product-image {
					width: 50px;
					height: 53px;
					object-fit: cover;
					border-radius: 8px;
				}
				.product-title {
					color: #353a40;
					font-size: 20px;
					font-family: var(--Roboto-font-family);
					font-weight: 400;
					margin-left: 20px;
				}
			}
		}

		.size-and-price {
			display: flex;
			flex-direction: column;
			row-gap: 2px;

			.size {
				width: 77px;
				height: 32px;
				border-radius: 8px;
				background: #f6f6f6;

				color: #999999;
				font-size: 16px;

				font-family: var(--Roboto-font-family);
				font-weight: 500;

				display: flex;
				align-items: center;
				justify-content: center;
				padding: 5px;
			}
			.price {
				color: #20256c;

				font-family: var(--Roboto-font-family);
				font-weight: 400;
				font-size: 17px;
			}
		}
	}
`;
