import { Button } from './../../Default/AddButton/styles';
import styled from 'styled-components';

export const Container = styled.div`
	min-height: 100vh;
	width: 100%;
	margin-top: 51px;
	text-align: center;
	font-family: 'Roboto';
	background: #f5f5f5;
	.category-text {
		color: #353a40;
		font-size: 26px;
		margin-top: 40px;
		margin-bottom: 31px;
	}
	.container-cards {
		display: flex;
		flex-direction: column;
		background: white;
		height: 480px;
		width: 85%;
		margin: auto;
		text-align: start;
		padding-left: 37px;
		padding-top: 40px;
		p {
			font-size: 16px;
			margin-bottom: 41px;
		}
		.categories-card {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
			grid-row-gap: 16px;
			margin-left: 53px;
		}
		.category {
			height: 152px;
			width: 152px;
			box-shadow: 0px 3px 6px #00000029;
			border-radius: 4px;
			background: #f5f5f5;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}
		.more-category {
			height: 152px;
			width: 152px;
			border: 2px solid #2b63fc;
			border-radius: 4px;
			background: white;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			/* margin: 0px 50px; */
			cursor: pointer;
			span {
				color: #2b63fc;
				font-weight: 500;
			}
		}
		img {
			width: 88px;
			height: 88px;
		}
		span {
			font-size: 12px;
			font-family: 'Roboto';
			color: #353a40;
		}
		/* .category {
			display: flex;
			justify-content: center;
			flex-direction: column;
			box-sizing: border-box;
		} */
	}
`;

export const ModalContainer = styled.div`
	width: 796px;
	height: max-content;
	padding: 26px;
	font-family: 'Roboto';
	.cards {
		.button-text {
			display: flex;
			justify-content: center;
			margin-top: 40px;
			Button {
				width: 196px;
				height: 30px;
				border-radius: 8px;
				background: #2b63fc;
				color: white;
				font-size: 13px;
				text-align: center;
				padding-top: 10px;
				border: none;
			}
		}
		span {
			text-align: start;
			margin-bottom: 16px;
		}
		.row {
			display: flex;
			flex-direction: column;
			column-gap: 20px;
			input {
				height: 38px;
				border-radius: 8px;
				border: 1px solid #8a99a8;
				margin-bottom: 22px;
			}
			.name {
				display: flex;
				flex-direction: column;
			}
		}
		.ilustration {
			display: flex;
			flex-direction: column;
			.more {
				width: 137px;
				height: 137px;
				background: #f6f6f6;
				border-radius: 8px;
				align-items: center;
				justify-content: center;
				display: flex;
				cursor: pointer;
				input {
					display: none;
				}
				label {
					cursor: pointer;
				}
			}
		}
	}
	.title-block {
		h1 {
			font-size: 19px;
			color: #2a3199;
			font-weight: 500;
			margin-bottom: 32px;
		}
	}
	span {
		font-size: 22px;
		color: #5a646e;
		text-align: center;
	}
	p {
		font-size: 13px;
		font-weight: 500;
		color: #8a99a8;
	}
	.buttons-modal {
		display: flex;
		justify-content: space-between;
		font-size: 12px;
		text-align: center;
		margin-top: 125px;

		.block {
			padding-top: 10px;
			width: 168px;
			height: 25px;
			border-radius: 8px;
			color: black;
			background: white;
			border: 1px solid black;
		}
	}
`;
