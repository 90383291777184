import styled from 'styled-components';

export const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 15px;
	grid-row-gap: 26px;
	height: 100%;
	background: #f5f5f5;
	margin: 0px 10%;
	/* margin-right: 10%; */
`;
