import React, { useEffect } from 'react';
import { RiNotification2Line, RiUserSettingsLine } from 'react-icons/ri';
import { RiListSettingsFill, RiStore3Fill } from 'react-icons/ri';
import { RiLayout6Line } from 'react-icons/ri';
import { FiUsers } from 'react-icons/fi';
import { AiTwotoneShop } from 'react-icons/ai';
import Cards from './Cards/Cards';
import Header from './Header/Header';
import { Container } from './styles';
import api from '../../api';
import UserAccount from '../../interfaces/UserAccount';

const Welcome: React.FC = () => {
	return (
		<>
			<Header />
			<Container>
				<Cards
					icon={<RiNotification2Line />}
					textMain="Notificações"
					textSub="Veja notificações dos moderadores e de denúncia"
					link="/admin/notification"
				/>
				<Cards
					icon={<RiUserSettingsLine />}
					textMain="Cadastro de administradores"
					textSub="Crie novos acessos ADM"
					link="/admin/newadministrator"
				/>
				<Cards
					icon={<RiListSettingsFill />}
					textMain="Categorias"
					textSub="Visualize e edite as categorias do sistema"
					link="/admin/categorys"
				/>
				<Cards
					icon={<FiUsers />}
					textMain="Vendedores cadastrados"
					textSub="Acompanha as informações cadastradas e desempenho de vendas"
					link="/admin/sellers"
				/>
				<Cards
					icon={<RiLayout6Line />}
					textMain="Edição"
					textSub="Edite e substitua os banners e cards do sistema"
					link="/admin/edit/banners"
				/>
				<Cards
					icon={<RiStore3Fill />}
					textMain="Produtos"
					textSub="Veja e acompanhe os produtos cadastrados"
					link="/admin/registeredproduct/all/query/all/0/100000000"
				/>
			</Container>
		</>
	);
};

export default Welcome;
