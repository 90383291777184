import React from 'react';

import { Container, ButtonMobile } from './styles';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProductsCart from '../../../interfaces/ProductsCart';
import { AppState } from '../../../store';
import { calculateProductCartValue } from '../../../utils/CalculateProductCartValue';
import { currency, currencyDefault } from '../../../utils/mask';
import UserAccount from '../../../interfaces/UserAccount';
import buttonAndShippingMobile from './ButtonAndShippingMobile/buttonAndShippingMobile';
interface PageParams {
	from_page: string;
}
const ShippingContainer: React.FC = () => {
	const pageParams = useParams<PageParams>();
	const history = useHistory();
	const productsCart: ProductsCart[] = useSelector(
		(state: AppState) => state.productsCart.currentCart
	);

	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);

	return (
		<Container>
			<div className="container">
				<div className="shipment-value">
					<div className="infos">
						{user && user.Address && user.Address?.length > 0 && (
							<>
								<h4 className="top-text">Enviar para:</h4>
								<h4 className="mid-text">
									{user.Address[0].street +
										', ' +
										user.Address[0].city +
										', ' +
										user.Address[0].state +
										'.'}
								</h4>
							</>
						)}
						<h4 className="bottom-text">
							Frete: <span>Retirar com o vendedor</span>
						</h4>
					</div>
				</div>
				<div className="total-value">
					Total:{' '}
					<h3>
						{'R$ ' +
							String(
								currencyDefault(
									Number(
										calculateProductCartValue(productsCart, user)
									)
								)
							)
								.replace('.', ',')
								.substring(
									0,
									currencyDefault(
										Number(
											calculateProductCartValue(
												productsCart,
												user
											)
										)
									).toString().length - 2
								)}{' '}
					</h3>
					<h3 className="small-text">
						{String(
							currencyDefault(
								Number(calculateProductCartValue(productsCart, user))
							)
						)
							.replace('.', ',')
							.substring(
								currencyDefault(
									Number(
										calculateProductCartValue(productsCart, user)
									)
								).toString().length - 2,
								currencyDefault(
									Number(
										calculateProductCartValue(productsCart, user)
									)
								).toString().length
							)}
					</h3>
				</div>
			</div>
			<div className="buttonAndShippingMobile">
				<div className="containerShippingButton">
					<div className="shipment-value">
						<div className="infos">
							<div className="shipping-adrress">
								<h4 className="bottom-text">
									Frete: <span>Retirar com o vendedor</span>
								</h4>
								{user &&
									user.Address &&
									user.Address?.length > 0 && (
										<h4 className="mid-text">
											{user.Address[0].street +
												', ' +
												user.Address[0].city +
												', ' +
												user.Address[0].state +
												'.'}
										</h4>
									)}
							</div>
							<div className="price">
								<div className="total-value">
									Total:{' '}
									<h3>
										{'R$ ' +
											String(
												currencyDefault(
													Number(
														calculateProductCartValue(
															productsCart,
															user
														)
													)
												)
											)
												.replace('.', ',')
												.substring(
													0,
													currencyDefault(
														Number(
															calculateProductCartValue(
																productsCart,
																user
															)
														)
													).toString().length - 2
												)}{' '}
									</h3>
									<h3 className="small-text">
										{String(
											currencyDefault(
												Number(
													calculateProductCartValue(
														productsCart,
														user
													)
												)
											)
										)
											.replace('.', ',')
											.substring(
												currencyDefault(
													Number(
														calculateProductCartValue(
															productsCart
															, user
														)
													)
												).toString().length - 2,
												currencyDefault(
													Number(
														calculateProductCartValue(
															productsCart,
															user
														)
													)
												).toString().length
											)}
									</h3>
								</div>
							</div>
						</div>
					</div>
					<ButtonMobile
						disabled={productsCart.length > 0 ? false : true}
						style={{
							opacity: `${productsCart.length > 0 ? '1' : '0.4'}`,
						}}
						onClick={() => {
							if (user) {
								if (pageParams.from_page === 'confirmation') {
									history.push(
										`/cart/order-confirmation/${'card'}`
									);
								} else if (productsCart.length > 0) {
									history.push(
										`/cart/shipping-method/${'cart'}`
									);
								}
							} else {
								history.push(`/login/${'cart'}`);
							}
						}}
					>
						Finalizar compra
					</ButtonMobile>
				</div>
			</div>
			<div className="buttonMobile">
				<button
					disabled={productsCart.length > 0 ? false : true}
					style={{
						opacity: `${productsCart.length > 0 ? '1' : '0.4'}`,
					}}
					onClick={() => {
						if (user) {
							if (pageParams.from_page === 'confirmation') {
								history.push(
									`/cart/order-confirmation/${'card'}`
								);
							} else if (productsCart.length > 0) {
								history.push(`/cart/shipping-method/${'cart'}`);
							}
						} else {
							history.push(`/login/${'cart'}`);
						}
					}}
				>
					Finalizar compra
				</button>
			</div>
		</Container>
	);
};

export default ShippingContainer;
