import React, { useCallback, useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import shoes from '../../../assets/categoryImages/sneaker.png';
import toys from '../../../assets/categoryImages/teddy.png';
import decoration from '../../../assets/categoryImages/vaso.png';
import sport from '../../../assets/categoryImages/volei.png';
import jewelry from '../../../assets/categoryImages/joia.png';
import party from '../../../assets/categoryImages/baloons.png';
import maleClothe from '../../../assets/categoryImages/cotton-polo-shirt.png';
import femaleClothe from '../../../assets/categoryImages/dress.png';
import eletronic from '../../../assets/categoryImages/pc.png';
import { CategoryContainer } from './styles';
import api from '../../../api';
import { AxiosResponse } from 'axios';
import Spinner from '../../Loader/Spinner';
import { useDebounce } from '../../../hooks/useDebounce';

export type ProductSubcategory = {
	id: string;
	name: string;
	category_id?: string;
};

type ProductCategory = {
	id: string;
	name: string;
};

type GetSubcategoriesResponse = {
	status: string;
	data: {
		subcategories: ProductSubcategory[];
	};
};
enum States {
	default = 'default',
	loading = 'loading',
	error = 'error',
}

interface SubCategoryItemProps {
	isActive: boolean;
	subcategory: ProductSubcategory;
	addSubcategory: (subcategory: ProductSubcategory) => void;
}

const SubCategoryItem: React.FC<SubCategoryItemProps> = ({
	isActive,
	subcategory,
	addSubcategory,
}) => (
	<button
		type="button"
		className={`subcategory ${isActive ? 'active' : ''}`}
		onClick={() => addSubcategory(subcategory)}
	>
		{subcategory.name}
	</button>
);
interface ProductCategoriesProps {
	selectedCategory: string;
	subcategoryModalShouldBeVisible: boolean;
	category: ProductCategory;
	setSelectedCategory(categroyId: string): void;
	onSelectedSubCategory: (subcategory: ProductSubcategory[]) => void;
}

const ProductCategoriesComponent: React.FC<ProductCategoriesProps> = ({
	selectedCategory,
	subcategoryModalShouldBeVisible,
	onSelectedSubCategory,
	category,
	setSelectedCategory,
}) => {
	const categoryImages: { [key: string]: string } = {
		'Roupas femininas': femaleClothe,
		'Roupas masculinas': maleClothe,
		Decorativos: decoration,
		Brinquedos: toys,
		Calçados: shoes,
		Festas: party,
		'Artigos esportivos': sport,
		Eletrônicos: eletronic,
		Joias: jewelry,
	};
	const [subcategories, setSubcategories] = useState<ProductSubcategory[]>(
		[]
	);
	const [state, setState] = useState<States>(States.loading);
	const [selectedSubcategories, setSelectedSubcategories] = useState<
		ProductSubcategory[]
	>([]);

	const removeDuplicatedSubcategories = (
		newSubcategories: ProductSubcategory[],
		selectedSubcategoriesParam: ProductSubcategory[]
	) => {
		let newArray: ProductSubcategory[] = [];

		if (selectedSubcategoriesParam.length > 0) {
			newSubcategories.forEach((newSubcategory) => {
				const findIndexIfSubcategorieAlreadyExists = selectedSubcategoriesParam.findIndex(
					(selectedSubcategories) =>
						selectedSubcategories.id === newSubcategory.id
				);
				if (findIndexIfSubcategorieAlreadyExists === -1) {
					newArray = [...newArray, newSubcategory];
				}
			});

			return newArray;
		}
		return newSubcategories;
	};

	const getSubcategoriesRequest = useCallback(
		async (selectedCategoryId: string, searchQuery?: string) => {
			try {
				setState(States.loading);
				const result = await api.get<
					unknown,
					AxiosResponse<GetSubcategoriesResponse>
				>(
					`/get-all-subcategories-by-category?category_id=${selectedCategoryId}${
						searchQuery ? `&search_query=${searchQuery}` : ''
					}&page_size=10&page=0`
				);
				setState(States.default);

				return result.data.data.subcategories;
			} catch (error) {
				setState(States.error);
			}
		},
		[]
	);

	const addSubcategory = (subcategory: ProductSubcategory): void => {
		const subcategoryAlreadySelected = selectedSubcategories.find(
			(selectedSubcategory) => selectedSubcategory.id === subcategory.id
		);
		if (subcategoryAlreadySelected) {
			setSelectedSubcategories(
				selectedSubcategories.filter(
					(selectedSubcategory) =>
						selectedSubcategory.id !== subcategory.id
				)
			);
			setSubcategories((previousValue) => [
				...previousValue,
				subcategory,
			]);

			return;
		}
		setSelectedSubcategories((previousValue) => [
			...previousValue,
			subcategory,
		]);
		setSubcategories((previousValue) =>
			previousValue.filter((item) => item.id !== subcategory.id)
		);
	};
	const handleChange = async (search: string) => {
		setSubcategories(
			removeDuplicatedSubcategories(
				(await getSubcategoriesRequest(selectedCategory, search)) ?? [],
				selectedSubcategories
			)
		);
	};
	const debounce = useDebounce(handleChange, 500);
	const handleSearch = async (search: string) => {
		setState(States.loading);
		debounce(search);
	};
	useEffect(() => {
		onSelectedSubCategory(selectedSubcategories);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSubcategories]);

	useEffect(() => {
		async function request() {
			setSubcategories(
				(await getSubcategoriesRequest(selectedCategory)) ?? []
			);
		}
		if (selectedCategory === category.id) {
			console.log(selectedCategory);
			request();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCategory, category.id]);

	return (
		<CategoryContainer>
			<button
				className={`category ${
					selectedCategory === category.id ? 'active' : ''
				}`}
				type="button"
				onClick={() => {
					setSelectedCategory(category.id);
				}}
			>
				<img src={categoryImages[category.name]} alt="" />
				<span> {category.name} </span>
			</button>

			{selectedCategory === category.id &&
				subcategoryModalShouldBeVisible && (
					<div className="sub-category-container">
						<div className="sub-category">
							<div className="input-container">
								<FiSearch className="icon" />
								<input
									type="text"
									placeholder="Pesquisar subcategorias..."
									onChange={(e) =>
										handleSearch(e.target.value)
									}
								/>
							</div>
							<div className="items">
								{selectedSubcategories.map((subcategory) => (
									<SubCategoryItem
										key={subcategory.id}
										isActive={Boolean(
											selectedSubcategories.find(
												(item) =>
													item.id === subcategory.id
											)
										)}
										subcategory={subcategory}
										addSubcategory={addSubcategory}
									/>
								))}
								{
									{
										[States.default]: subcategories.map(
											(subcategory) => (
												<SubCategoryItem
													key={subcategory.id}
													isActive={Boolean(
														selectedSubcategories.find(
															(item) =>
																item.id ===
																subcategory.id
														)
													)}
													subcategory={subcategory}
													addSubcategory={
														addSubcategory
													}
												/>
											)
										),
										[States.loading]: <Spinner />,
										[States.error]: (
											<span>
												Não foi possível exibir esse
												conteúdo
											</span>
										),
									}[state]
								}
							</div>
						</div>
					</div>
				)}
		</CategoryContainer>
	);
};

export { ProductCategoriesComponent };
