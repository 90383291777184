import styled from 'styled-components';



export const InfosDiv = styled.div`
        .infos{
			display: flex;
			flex-direction: column;
			border: 1px solid #c5ced7;
			border-radius: 8px;
			margin-top: 45px;
			padding: 1rem;
			/* margin-left: 21px; */
			display: none;
			@media (max-width: 768px){
				display: flex;
			}
			.infos-item {
			margin-bottom: 25px;
			width: 85%;
			h1 {
				color: #004d93;
				font-family: var(--Roboto-font-family);
				font-weight: 500;
				font-size: min(20px, 1.8vw);
				width: 90%;
				margin-bottom: 15px;
				@media (max-width: 768px){
					font-size: 20px;
				}
			}
			p {
				margin-left: 15px;
				padding-left: 15px;
				color: #959595;
				font-family: var(--Roboto-font-family);
				font-weight: 400;
				font-size: min(13px, 1.4vw);
				border-left: solid 1px #e1e1e1;
				/* height: 90px; */
				display: flex;
				align-items: center;
				@media (max-width: 768px){
					font-size: 13px;
					width: 200px;
				}
			}
		}
    }


`;