import React, { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from '../Default/Input/Input';
import {
	CardContainer,
	CardInputs,
	CardTitle,
	Container,
	HintContainer,
	SectionContainer,
	ProductType,
	SectionContainerMobile,
} from './styles';
import api from '../../api';
import { Router, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UserAccount from '../../interfaces/UserAccount';
import { AppState } from '../../store';
import { AxiosResponse } from 'axios';
import closeIcon from '../../assets/icons/closeIcon.svg';
import { currency } from '../../utils/mask';

import { Checkbox } from 'semantic-ui-react';
import {
	ProductVariations,
	VariationPhotos,
	VariationSizes,
} from '../../interfaces/ProductVariations';

import { RiCloseFill } from 'react-icons/ri';

import { reOrderProductVariations } from '../../utils/reOrderProduct';
import OtherProductCategories from './OtherProductCategories/OtherProductCategories';
import { ProductCategory } from '../../interfaces/ProductCategory';
import {
	ProductCategoriesComponent,
	ProductSubcategory,
} from './ProductCategories/ProductCategories';

export interface ProductRegister {
	title: string;
	stock: string;
	max_installments: number;
	height: string;
	image: any;
	width: string;
	use_state: string;
	thickness: string;
	format: number;
	weight: string;
	model: string;
	material: string;
	description: string;
	pick_up_address_id: string;
	price: string;
	withdraw: string;
	category: string;
	showcase: boolean;
	collaborator_price: string;
}
export interface ProductCategories {
	categories: [{ id: string; name: string }];
}

const ProductRegister: React.FC = () => {
	function RenderPage() {
		return true;
		/* if (user.ebanx_resource_token && user.user_types &&
				user.user_types.name === UserTypeEnum.seller) {
			return true;
		} else {
			return false;
		} */
	}

	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const scroolRef = React.useRef<HTMLDivElement>(null);
	const { register, handleSubmit, errors } = useForm<ProductRegister>({
		defaultValues: {
			max_installments: 1,
		}
	});
	const [showHint, setShowHint] = useState(false);
	const [dontShowHint, setDontShowHint] = useState(false);
	const [imgs, setImgs] = useState<any>([]);
	const [showcase, setShowcase] = useState(false);
	const history = useHistory();
	const [
		productCategories,
		setProductCategories,
	] = useState<ProductCategory[]>([]);
	const [productSubcategories, setProductSubcategories] = useState<
		ProductSubcategory[]
	>([]);
	const [selectedCategory, setSelectedCategory] = useState<string>('');
	const [
		showSubCategoryModal,
		setShowSubCategoryModal,
	] = useState<boolean>(false);
	const [
		showOtherCategoryModal,
		setshowOtherCategoryModal,
	] = useState<boolean>(false);
	const [
		showBackGroundOverlay,
		setShowBackGroundOverlay,
	] = useState<boolean>(false);
	const [productVariations, setProductVariations] = useState<
		ProductVariations[]
	>([
		{
			color: '',
			created_at: '',
			hex: '',
			variation_order: 1,
			id: Math.random().toString(36).substring(7), //temp id,
			product_id: '',
			updated_at: '',
			variation_photos: [],
			variation_sizes: [
				{
					size: '',
					stock: 1,
					created_at: '',
					updated_at: '',
					id: '',
					size_order: 1,
				},
			],
		},
	]);
	const [hasVariation, setHasVariation] = useState<true | false | 'none'>(
		false
	);
	const [validVariations, setValidVariations] = useState<boolean>(false);

	const [isMobile, setIsMobile] = useState<boolean>();
	const handleWindowSizeChange = () => {
		setIsMobile(window.innerWidth <= 768);
	};

	useEffect(() => {
		setIsMobile(window.innerWidth <= 768);
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	const addVariation = () => {
		let tempVariations = productVariations;
		let variation: ProductVariations = {
			color: '',
			created_at: '',
			hex: '',
			variation_order: productVariations.length + 1,
			id: Math.random().toString(36).substring(7), //temp id,
			product_id: '',
			updated_at: '',
			variation_photos: [],
			variation_sizes: [
				{
					size: '',
					stock: 1,
					created_at: '',
					updated_at: '',
					id: '',
					size_order: 1,
				},
			],
		};
		tempVariations.push(variation);
		setProductVariations([...tempVariations]);
	};

	const addPhoto = (index: number, value: FileList | null) => {
		let tempVariations = productVariations;
		if (value) {
			Array.from(value).map((img: File) => {
				const identiFicationName = img.name;
				//const identiFicationName = index.toString() + "-" + tempVariations[index].variation_photos.length + "-" + img.name;

				let variataionPhoto: VariationPhotos = {
					name: identiFicationName,
					id: '',
					created_at: '',
					updated_at: '',
					photo: img,
					photo_order:
						tempVariations[index].variation_photos.length + 1,
				};
				tempVariations[index].variation_photos.push(variataionPhoto);
			});
		}
		setProductVariations([...tempVariations]);
	};

	const addSize = (index: number) => {
		let tempVariations = productVariations;
		let size: VariationSizes = {
			size: '',
			id: '',
			size_order: tempVariations[index].variation_sizes.length + 1,
			stock: 0,
			created_at: '',
			updated_at: '',
		};
		tempVariations[index].variation_sizes.push(size);
		setProductVariations([...tempVariations]);
	};

	const handleColorChange = (variation: number, value: string) => {
		let tempVariations = productVariations;
		tempVariations[variation].color = value;
		setProductVariations([...tempVariations]);
	};

	const handleRemovePhoto = (variation: number, photoIndex: number) => {
		let tempVariations = productVariations;
		tempVariations[variation].variation_photos.splice(photoIndex, 1);
		tempVariations = reOrderProductVariations(tempVariations, true);
		setProductVariations([...tempVariations]);
	};

	const handleSizeChange = (
		variation: number,
		sizeIndex: number,
		value: string
	) => {
		let tempVariations = productVariations;
		tempVariations[variation].variation_sizes[
			sizeIndex
		].size = value.toUpperCase();
		setProductVariations([...tempVariations]);
	};

	const handleSizeStockChange = (
		variation: number,
		sizeIndex: number,
		value: string
	) => {
		let tempVariations = productVariations;
		tempVariations[variation].variation_sizes[sizeIndex].stock = parseInt(
			value
		);
		setProductVariations([...tempVariations]);
	};

	const handleRemoveVariation = (variation: ProductVariations) => {
		let tempVariations = productVariations;
		tempVariations! = tempVariations!.filter((variationFil) => {
			return variation.id !== variationFil.id;
		});
		tempVariations = reOrderProductVariations(tempVariations, true);
		setProductVariations([...tempVariations!]);
		if (productVariations.length < 2) {
			setHasVariation(false);
		}
	};

	const handleShowcaseChange = () => {
		setShowcase(!showcase);

		setHasVariation(false);
	}

	const validateVariations = () => {
		let valid = true;
		if (hasVariation === true) {
			for (let i = 0; i < productVariations.length; i++) {
				if (productVariations[i].color.trim() === '') {
					valid = false;
					break;
				} else {
					for (
						let j = 0;
						j < productVariations[i].variation_sizes.length;
						j++
					) {
						if (
							productVariations[i].variation_sizes[
								j
							].size.trim() === ''
						) {
							valid = false;
							break;
						}
					}
				}
			}
		}
		setValidVariations(!valid);
		return valid;
	};
	
	const onSubmit = async (data: any) => {
		if (validateVariations()) {
			const newData = new FormData();
			
			newData.append('title', data.title);
			newData.append('use_state', 'Novo');
			newData.append('category_id', selectedCategory);
			newData.append('description', data.description);
			newData.append('user_account_id', user.id);
			newData.append('pick_up_address_id', data.pick_up_address_id);
			newData.append('showcase', showcase ? 'true' : 'false');
			newData.append('withdraw', data.withdraw === "SIM" ? 'true' : 'false');

			let productSubCategories = '';
			productSubcategories.forEach((item, index) => {
				productSubCategories += `${index !== 0 ? ',' : ''}${item.id}`;
			});
			newData.append('product_subcategories', productSubCategories);


			//Dados se não for vitrine
			if(!showcase){

				newData.append(
					'price',
					String(
						parseFloat(data.price.replace('.', '').replace(',', '.'))
					)
				);
				newData.append(
					'collaborator_price',
					String(
						parseFloat(data.collaborator_price.replace('.', '').replace(',', '.'))
					)
				);
				newData.append('max_installments', data.max_installments || 1);
				newData.append(
					'height',
					String(
						parseFloat(data.height.replace('.', '').replace(',', '.'))
					)
				);
				newData.append(
					'width',
					String(
						parseFloat(data.width.replace('.', '').replace(',', '.'))
					)
				);
				/* newData.append('use_state', data.use_state); */
				newData.append(
					'thickness',
					String(
						parseFloat(
							data.thickness.replace('.', '').replace(',', '.')
						)
					)
				);
				newData.append('format', data.format);
				newData.append(
					'weight',
					String(
						parseFloat(data.weight.replace('.', '').replace(',', '.'))
					)
				);
				newData.append('brand', data.brand);
				newData.append('model', data.model);
				newData.append('material', data.material);
				
				if (hasVariation === true) {
					newData.append('variations', JSON.stringify(productVariations));

					let totalStock: number = 0;
					productVariations.forEach((variation) => {
						variation.variation_photos.forEach((photo: any) => {
							newData.append('image', photo.photo as any);
						});

						variation.variation_sizes.forEach((size: any) => {
							totalStock += size.stock;
						});
					});

					newData.append('stock', totalStock.toString());
				} else {
					imgs.forEach((image: any) => {
						newData.append('image', image);
					});
					newData.append('stock', data.stock);
				}
				
			}
			//Se for vitrine insere valores padrões para não dar erro
			else {
				newData.append('price','0.00');
				newData.append('weight','0.00');

				imgs.forEach((image: any) => {
					newData.append('image', image);
				});
			}

			api.post('/product-register', newData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}).then((res: any) => {
				history.push(`/seller-area/${user.id}/all/0/100000000/undefined/products`);
				window.scroll(0, 0);
			});
		}
	};

	const addImage = (e: ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) {
			return;
		}
		const selectedImages = Array.from(e.target.files);
		setImgs(imgs.concat(selectedImages));
	};

	const handleSelectCategory = (categoryId: string) => {
		setShowBackGroundOverlay(true);
		setShowSubCategoryModal(true);
		setSelectedCategory(categoryId);
	};

	const handleSelectSubcategory = (
		productSubcategories: ProductSubcategory[]
	) => {
		setProductSubcategories(productSubcategories);
	};

	const handleOtherCategoryModal = () => {
		setShowBackGroundOverlay(true);
		setshowOtherCategoryModal(true);
	}

	const handleShowBackGround = () => {
		setShowSubCategoryModal(false);
		setshowOtherCategoryModal(false);
		setShowBackGroundOverlay(false);
		
	}

	useEffect(() => {
		api.get('/get-product-categories')
			.then((response: AxiosResponse<ProductCategories>) => {
				setProductCategories(response.data.categories);
			})
			.catch((err) => {});
	}, []);

	console.log({user})

	if(!user.pagarme_recipient_id) {
		history.push(
			`/profile/user-information`
		)
		alert("Por favor preencha as informações de pagamento");
	}

	return (
		<Container
			onSubmit={handleSubmit(onSubmit, (err) => console.log({err}))}
			encType="multipart/form-data"
		>
			{RenderPage() ? (
				<>
					{showBackGroundOverlay && (
						<div
							className="background-overlay"
							onClick={() => handleShowBackGround()}
						/>
					)}
					<header>
						<h2>Cadastrar novo produto</h2>
						<h1>O que deseja anunciar hoje?</h1>
						<div
							className="categories-card-container"
							style={{
								border: `${
									errors.category && !selectedCategory
										? 'solid 1px red'
										: 'none'
								}`,
							}}
						>
							<input
								name="category"
								type="hidden"
								ref={register({ required: true })}
								value={selectedCategory}
							/>
							<div className="categories-card">
								{productCategories?.slice(0,8)
									.map(
									(category) => (
										<>
											{category.name !== 'Festas' && (
												<ProductCategoriesComponent
													selectedCategory={
														selectedCategory
													}
													subcategoryModalShouldBeVisible={
														showSubCategoryModal
													}
													category={category}
													setSelectedCategory={
														handleSelectCategory
													}
													onSelectedSubCategory={
														handleSelectSubcategory
													}
												/>
											)}
										</>
									)
								)}
								<OtherProductCategories
									selectedCategory={
										selectedCategory
									}
									categories={productCategories?.slice(8)}
									showCategoryModal={
										showOtherCategoryModal
									}
									setShowCategoryModal={
										handleOtherCategoryModal
									}
									setSelectedCategory={
										handleSelectCategory
									}
								/>
							</div>
						</div>
					</header>
					<CardContainer>
						<main>
							<div>
								<div className="order-mobile">
									<CardInputs>
										<SectionContainer>
											<CardTitle>
												<h2>Dados gerais</h2>
											</CardTitle>
											<div className="title-and-user-state">
												<Input
													label={''}
													placeholder="Título do anúncio"
													ref={register({
														required: true,
													})}
													name="title"
													flex="3"
													margin={'30'}
													borderColor={
														errors.title
															? 'red'
															: '#8a99a8'
													}
												/>
												{/* <div
													className="use-state"
													style={{ marginLeft: '30px' }}
												>
													<label
														htmlFor="new"
														className={`state-label ${
															radioButtonToggleNew
																? 'active-1'
																: ''
														}`}
													>
														Novo{' '}
														<input
															type="radio"
															name="use_state"
															value="Novo"
															defaultChecked={true}
															id="new"
															ref={register({
																required: true,
															})}
															onChange={() => {
																setRadioButtonToggleNew(
																	true
																);
																setRadioButtonToggleNewUsed(
																	false
																);
															}}
														/>
													</label>
													<label
														htmlFor="used"
														className={`state-label ${
															radioButtonToggleUsed
																? 'active-2'
																: ''
														}`}
													>
														Usado
														<input
															type="radio"
															name="use_state"
															ref={register({
																required: true,
															})}
															id="used"
															value="Usado"
															onChange={() => {
																setRadioButtonToggleNew(
																	false
																);
																setRadioButtonToggleNewUsed(
																	true
																);
															}}
														/>
													</label>
												</div> */}
											</div>
											<div
												className="description"
												style={{
													border: `solid 1px ${
														errors.description
															? 'red'
															: '#8a99a8'
													}`,
												}}
											>
												<textarea
													ref={register({
														required: true,
													})}
													name="description"
													placeholder="Descrição do anúncio"
												></textarea>
											</div>
											<div className="checkbox">
												<Checkbox
													label="Apenas Vitrine"
													checked={showcase}
													onChange={handleShowcaseChange}
													name="showcase"
												/>
											</div>
											{!showcase && (
												<div className="brand-model-and-material">
													<Input
														type="text"
														name="brand"
														label=""
														placeholder="Marca"
														ref={register({
															required: false,
														})}
														flex="3"
														margin={'30'}
														borderColor={'#8a99a8'}
													/>
													<Input
														type="text"
														name="model"
														label=""
														placeholder="Modelo"
														ref={register({
															required: false,
														})}
														flex="3"
														margin={'30'}
														borderColor={'#8a99a8'}
													/>

													<Input
														type="text"
														name="material"
														label=""
														placeholder="Material"
														ref={register({
															required: false,
														})}
														flex="3"
														margin={'0'}
														borderColor={'#8a99a8'}
													/>
												</div>
											)}
											
										</SectionContainer>
										
										
										<SectionContainer>
											<div className="stock-container">
												<h2>
													Imagens, estoque e variações
												</h2>
												{!showcase && (
													<ProductType>
														<button
															className={
																hasVariation ===
																false
																	? 'frist active'
																	: 'frist'
															}
															onClick={(e) => {
																e.preventDefault();
																setHasVariation(
																	false
																);
															}}
														>
															{!isMobile
																? 'Produto sem variação'
																: 'Sem variação'}
														</button>
														<button
															className={
																hasVariation ===
																true
																	? 'second active'
																	: 'second'
															}
															onClick={(e) => {
																e.preventDefault();
																setHasVariation(
																	true
																);
															}}
														>
															{!isMobile
																? 'Produto com variação'
																: 'Com variação'}
														</button>
													</ProductType>
												)}
												
												{hasVariation === true && (
													<div className="inputs-container">
														{productVariations.map(
															(
																variation,
																variationIndex
															) => (
																<div className="inputs">
																	<div className="row-inputs-delete">
																		<input
																			id="color"
																			placeholder="Cor"
																			value={
																				productVariations[
																					variationIndex
																				]
																					.color
																			}
																			onChange={(
																				e
																			) =>
																				handleColorChange(
																					variationIndex,
																					e
																						.target
																						.value
																				)
																			}
																		></input>
																		<div
																			className="delete-button"
																			onClick={() =>
																				handleRemoveVariation(
																					variation
																				)
																			}
																		>
																			<RiCloseFill
																				fill={
																					'#C91729'
																				}
																			/>
																			<span>
																				Excluir
																				variação
																			</span>
																		</div>
																	</div>
																	{/* <button
																		className="delete-button"
																		type="button"
																		onClick={() =>
																			handleRemoveVariation(
																				variation
																			)
																		}
																	>
																		Excluir
																		variação
																	</button> */}
																	<div
																		style={{
																			display:
																				'flex',
																			flexWrap:
																				'wrap',
																		}}
																	>
																		<div className="add-images-card">
																			<figure>
																				<label
																					htmlFor={
																						'add-photo-' +
																						variationIndex.toString()
																					}
																				>
																					+
																				</label>
																				<input
																					type="file"
																					ref={register(
																						{
																							required: false,
																						}
																					)}
																					name={
																						'add-photo-' +
																						variationIndex.toString()
																					}
																					id={
																						'add-photo-' +
																						variationIndex.toString()
																					}
																					multiple
																					maxLength={
																						4
																					}
																					onChange={(
																						e: ChangeEvent<HTMLInputElement>
																					) => {
																						addPhoto(
																							variationIndex,
																							e
																								.target
																								.files
																						);
																						//setPhotos();
																					}}
																				/>
																			</figure>
																			{variation.variation_photos.map(
																				(
																					photo: any,
																					photoIndex: any
																				) => (
																					<div className="preview">
																						{photo.photo && (
																							<>
																								<div className="close-icon-container">
																									<img
																										src={
																											closeIcon
																										}
																										alt=""
																										className="close-icon"
																										onClick={() =>
																											handleRemovePhoto(
																												variationIndex,
																												photoIndex
																											)
																										}
																										//setPhotoPreview(photoPreview.filter((imgItem: File) => imgItem.name !== photo.photo!.name))}
																									/>
																									<img
																										style={{
																											objectFit:
																												'cover',
																										}}
																										src={URL.createObjectURL(
																											photo.photo
																										)}
																										alt=" "
																										className="img-preview"
																									></img>
																								</div>
																							</>
																						)}
																					</div>
																				)
																			)}
																		</div>
																	</div>
																	<label>
																		Quantidade
																		por
																		Tamanho
																	</label>
																	<div className="size-container">
																		{variation.variation_sizes.map(
																			(
																				size: any,
																				sizeIndex: any
																			) => (
																				<>
																					<HintContainer
																						onMouseLeave={() => {
																							setShowHint(
																								false
																							);
																						}}
																						onMouseEnter={() => {
																							setShowHint(
																								variationIndex ===
																									0 &&
																									sizeIndex ===
																										0
																									? true
																									: false
																							);
																						}}
																					>
																						<input
																							id="size"
																							placeholder='"P"'
																							onChange={(
																								e
																							) =>
																								handleSizeChange(
																									variationIndex,
																									sizeIndex,
																									e
																										.target
																										.value
																								)
																							}
																							value={
																								size.size
																							}
																						></input>
																						{variationIndex ===
																							0 &&
																							sizeIndex ===
																								0 &&
																							!dontShowHint &&
																							showHint && (
																								<>
																									<span className="hint-text">
																										Ex:
																										P/M/G
																										ou
																										39/40/41
																										<button
																											type="button"
																											className="hint-button"
																											onClick={() =>
																												setDontShowHint(
																													true
																												)
																											}
																										>
																											Ok!
																										</button>
																									</span>
																								</>
																							)}
																					</HintContainer>
																					<input
																						id="variation-stock"
																						placeholder="Quantidade"
																						type='number'
																						min={0}
																						//defaultValue={1}
																						onChange={(
																							e
																						) =>
																							handleSizeStockChange(
																								variationIndex,
																								sizeIndex,
																								e
																									.target
																									.value
																							)
																						}
																					></input>
																				</>
																			)
																		)}
																		<button
																			onClick={(
																				e
																			) => {
																				e.preventDefault();
																				addSize(
																					variationIndex
																				);
																			}}
																		>
																			+
																			Adicionar
																			tamanho
																		</button>
																	</div>
																</div>
															)
														)}
													</div>
												)}
												{hasVariation === false && (
													<div className="image-card">
														<div className="add-images-card">
															<figure>
																<label htmlFor="add-image-no-variation">
																	+
																</label>
																<input
																	type="file"
																	title="teste"
																	name="image"
																	ref={register(
																		{
																			required: false,
																		}
																	)}
																	id="add-image-no-variation"
																	accept="image/*"
																	multiple
																	maxLength={
																		4
																	}
																	onChange={(
																		e: ChangeEvent<HTMLInputElement>
																	) => {
																		addImage(
																			e
																		);
																	}}
																/>
															</figure>
															{imgs.length > 0 &&
																imgs.map(
																	(
																		img: File,
																		index: any
																	) => (
																		<div className="preview">
																			<div className="close-icon-container">
																				<img
																					src={
																						closeIcon
																					}
																					alt=""
																					className="close-icon"
																					onClick={() =>
																						setImgs(
																							imgs.filter(
																								(
																									imgItem: File,
																									indexItem: number
																								) =>
																									indexItem !==
																									index
																							)
																						)
																					}
																				/>
																				<img
																					style={{
																						objectFit:
																							'cover',
																					}}
																					src={URL.createObjectURL(
																						img
																					)}
																					alt="imagem do produto"
																					className="img-preview"
																				/>
																			</div>
																		</div>
																	)
																)}
														</div>
													</div>
												)}
												<div className="buttons-container">
													<div></div>
													{hasVariation === true && (
														<>
															{validVariations && (
																<span>
																	Todas as
																	cores e
																	tamanhos
																	<br />
																	devem estar
																	preenchidos.
																</span>
															)}
															<button
																onClick={(
																	e
																) => {
																	e.preventDefault();
																	addVariation();
																}}
															>
																+ Adicionar
																variação
															</button>
														</>
													)}
													{(hasVariation === false && !showcase) &&  (
														<input
															id="stock"
															type='number'
															min={0}
															name="stock"
															ref={register({
																required: false,
															})}
															placeholder="Estoque"
														></input>
													)}
												</div>
											</div>
										</SectionContainer>
										
										
										{!showcase && (
											<SectionContainer>
												<div className="price-card">
													<h2>Preço e pagamento</h2>
													<div className="price-inputs-card">
														<div className="limit-container">
															<Input
																id="priceInput"
																type="text"
																name="price"
																label="Preço do produto"
																// placeholder="Preço do produto"
																ref={register({
																	required: true,
																})}
																flex="1"
																onKeyUp={currency}
																borderColor={
																	errors.price
																		? 'red'
																		: '#8a99a8'
																}
															/>															<Input
																id="priceInput"
																type="text"
																name="collaborator_price"
																label="Preço para colaboradores"
																// placeholder="Preço do produto"
																ref={register({
																	required: true,
																})}
																flex="1"
																onKeyUp={currency}
																borderColor={
																	errors.collaborator_price
																		? 'red'
																		: '#8a99a8'
																}
															/>

															{/* <Input
																type="number"
																min={1}
																name="max_installments"
																label=""
																placeholder="N° de parcelas habilitadas"
																ref={register({
																	required: true,
																})}
																flex="1"
																style={{
																	marginLeft:
																		'0px',
																	width: '170px',
																}}
																borderColor={
																	errors.max_installments
																		? 'red'
																		: '#8a99a8'
																}
															/> */}
															{/* <div className="checkbox">
																<Checkbox label="Frete grátis" />
																<Checkbox
																	label="Parcelamente sem juros"
																	style={{
																		width:
																			'220px',
																		marginLeft:
																			'40px',
																	}}
																/>
															</div> */}
														</div>
													</div>
												</div>
											</SectionContainer>
										)}

										{!showcase && (
											<SectionContainer>
												<div
													className="sending-informations"
													ref={scroolRef}
												>
													<h3>Informações de envio</h3>
													<label>
														Opção de retirada com o
														vendedor
													</label>
													<div className="sending-informations-buttom">
														<input
															type="radio"
															name="food"
															id="gray"
															checked
															hidden
														/>
														<label htmlFor="gray">
															SIM
														</label>
														<input
															type="radio"
															name="food"
															id="white"
															checked
															hidden
														/>
														<label htmlFor="white">
															NÃO
														</label>
													</div>
													<div className="sending-informations-input">
														<div className="select-container">
															<label>
																Opção de retirada
																com o vendedor
															</label>
															<select
																name="withdraw"
																ref={register({
																	required: true,
																})}
																style={{
																	border: `solid 1px ${
																		errors.withdraw
																			? 'red'
																			: '#8a99a8'
																	}`,
																}}
															>
																<option value="SIM">
																	Sim
																</option>
																<option value="NAO">
																	Não
																</option>
															</select>
														</div>
														<div
															className="select-container"
															style={{
																display: 'flex',
															}}
														>
															<label>Formato</label>
															<select
																name="format"
																ref={register({
																	required: true,
																})}
																style={{
																	border: `solid 1px ${
																		errors.format
																			? 'red'
																			: '#8a99a8'
																	}`,
																}}
															>
																<option value="">
																	Formato
																</option>
																<option value="1">
																	Caixa/Pacote
																</option>
																<option value="2">
																	Rolo/Prisma
																</option>
																<option value="3">
																	Envelope
																</option>
															</select>
														</div>
													</div>
													<div className="product-sending-informations">
														<div className="input-div">
															<Input
																type="number"
																name="weight"
																min={1}
																label=""
																placeholder="Peso"
																ref={register({
																	required: true,
																})}
																flex="1"
																borderColor={
																	errors.weight
																		? 'red'
																		: '#8a99a8'
																}
															/>
															<label>
																(incluindo embalagem
																em kg)
															</label>
														</div>
														<div className="input-div">
															<Input
																type="number"
																min={1}
																name="height"
																label=""
																placeholder="Altura"
																ref={register({
																	required: true,
																})}
																flex="1"
																style={{
																	border: `solid 1px ${
																		errors.height
																			? 'red'
																			: '#8a99a8'
																	}`,
																}}
																borderColor={
																	errors.height
																		? 'red'
																		: '#8a99a8'
																}
															/>
															<label>
																(incluindo embalagem
																em cm)
															</label>
														</div>
														<div className="input-div">
															<Input
																type="number"
																min={1}
																name="width"
																label=""
																placeholder="Largura"
																ref={register({
																	required: true,
																})}
																flex="1"
																style={{
																	border: `solid 1px ${
																		errors.width
																			? 'red'
																			: '#8a99a8'
																	}`,
																}}
																borderColor={
																	errors.width
																		? 'red'
																		: '#8a99a8'
																}
															/>
															<label>
																(incluindo embalagem
																em cm)
															</label>
														</div>
														<div className="input-div">
															<Input
																type="number"
																min={1}
																name="thickness"
																label=""
																placeholder="Diâmetro"
																ref={register({
																	required: true,
																})}
																flex="1"
																borderColor={
																	errors.thickness
																		? 'red'
																		: '#8a99a8'
																}
															/>
															<label>
																(incluindo embalagem
																em cm)
															</label>
														</div>
													</div>
												</div>
												<h2 className="additional-informations">
													*No caso de envelopes informar
													altura zero (0)
												</h2>
											</SectionContainer>
										)}

										{/* {!showcase && ( */}
											<SectionContainer>
												<div className="shipping-options-container">
													<h2>Opções de envio</h2>
													<div className="shipping-options-card">
														<h2
															style={{
																color: '#8A99A8',
																fontSize: '12px',
																fontWeight: 300,
																marginLeft: '15px',
															}}
														>
															<i>
																Escolha o endereço
																de onde o produto
																partirá para o
																envio:
															</i>
														</h2>
														<div
															className="container"
															style={{
																border: `${
																	errors.pick_up_address_id
																		? 'solid 1px red'
																		: 'none'
																}`,
															}}
														>
															{user?.Address?.map(
																(address) => (
																	<>
																		<div className="shipping-option">
																			<input
																				type="radio"
																				name="pick_up_address_id"
																				ref={register(
																					{
																						required: true,
																					}
																				)}
																				value={
																					address.id
																				}
																			/>
																			<span>
																				{`${address.zip_code}- ${address.street} - ${address.city}, ${address.state}`}
																			</span>
																		</div>
																	</>
																)
															)}
														</div>
													</div>
												</div>
											</SectionContainer>
										{/* // )} */}
										
										
									</CardInputs>
								</div>
							</div>
							<div className="finish-button-container">
								<button
									className="negative-button"
									onClick={() => history.push('/seller')}
								>
									Cancelar
								</button>

								<button
									type="submit"
									onClick={() => {
										if (!selectedCategory) {
											window.scroll(0, 0);
										}
										validateVariations();
									}}
									className="positive-button"
								>
									Anunciar Produto
								</button>
							</div>
						</main>
					</CardContainer>
				</>
			) : (
				<div className="no-token">
					<h1>
						ACESSO NEGADO! <br />
						Necessário cadastro no eBanx
					</h1>
				</div>
			)}
		</Container>
	);
};

export default ProductRegister;
