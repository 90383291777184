import React from "react";
import Header from "../../components/Default/header";
import UserRegister from "../../components/UserRegister.tsx/UserRegister";
import RegisterHeader from "../../components/UserRegister.tsx/RegisterHeader/RegisterHeader";

import { Container } from "./styles";

const UserRegisterPage: React.FC = () => {
  return (
    <Container>
      <RegisterHeader/>
      {/* <Header /> */}
      <UserRegister />
    </Container>
  );
};

export default UserRegisterPage;
