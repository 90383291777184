import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import api from '../../api';
import Input from '../Default/Input/Input';
import { Container, FormContainer, InputsContainer } from './styles';
import cep from 'cep-promise';
import { AxiosResponse } from 'axios';
import { Address } from '../../interfaces/UserAccount';
import { useDispatch } from 'react-redux';
import { insertAddress, updateAddress } from '../../actions/UserActions';
import { useHistory } from 'react-router';
import { HiLocationMarker } from 'react-icons/hi';

interface ModalInputs {
	street: string;
	state: string;
	city: string;
	number: string;
	zip_code: string;
	complement: string;
}
interface Props {
	address?: Address;
}

const UserAddressMobile: React.FC<Props> = ({ address }) => {
	const history = useHistory();
	const [editAddress, setEditAddress] = useState<Address>();
	const { register, handleSubmit, errors } = useForm<ModalInputs>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	});
	const dispatch = useDispatch();

	useEffect(() => {
		setEditAddress(address);
	}, [address]);

	const onSubmit = (data: ModalInputs) => {
		if (!address) {
			api.post('/insert-user-address', {
				city: data.city,
				state: data.state,
				street: data.street,
				zip_code: data.zip_code,
				number: data.number,
				complement: data.complement,
			})
				.then((response: AxiosResponse<any>) => {
					dispatch(insertAddress(response.data.response));
					history.push('/profile/user-information');
				})
				.catch((err) => {
					console.error(err);
				});
		} else if (address) {
			api.put('/update-user-address', {
				id: address.id,
				city: data.city,
				state: data.state,
				street: data.street,
				zip_code: data.zip_code,
				number: data.number,
				complement: data.complement,
			})
				.then((response: AxiosResponse<any>) => {
					dispatch(updateAddress(response.data.response));
					history.push('/profile/user-information');
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};
	const findCepInformations = async (zip_code: string) => {};
	return (
		<Container>
			<FormContainer onSubmit={handleSubmit(onSubmit)}>
				<InputsContainer>
					<h2 className="modal-title">
						<HiLocationMarker />
						{!address
							? 'Adicionar novo endereço'
							: 'Alterar endereço'}
					</h2>
					<div className="first-line">
						<Input
							label=""
							placeholder="CEP"
							flex="2"
							name="zip_code"
							ref={register({ required: true })}
							type="text"
							border={false}
							error={errors.zip_code ? true : false}
							//case it is edit
							defaultValue={editAddress?.zip_code}
						/>
						<div className="address-number">
							<Input
								label=""
								placeholder="Endereço"
								flex="3"
								name="street"
								ref={register({ required: true })}
								type="text"
								border={false}
								error={errors.street ? true : false}
								//case it is edit
								defaultValue={editAddress?.street}
								onChange={(e) => {
									findCepInformations(e.target.value);
								}}
							/>
							<Input
								className="number"
								label=""
								placeholder="Nº"
								flex="1"
								name="number"
								ref={register({ required: true })}
								type="text"
								border={false}
								error={errors.number ? true : false}
								//case it is edit
								defaultValue={editAddress?.number}
							/>
						</div>
					</div>
					<div className="second-line">
						<Input
							label=""
							placeholder="Complemento"
							flex="4"
							name="complement"
							ref={register({ required: false })}
							type="text"
							border={false}
							error={errors.complement ? true : false}
							//case it is edit
							defaultValue={editAddress?.complement}
						/>
						<div className="rowCity">
							<Input
								className="city"
								label=""
								placeholder="Cidade"
								flex="1"
								name="city"
								ref={register({ required: true })}
								type="text"
								border={false}
								error={errors.city ? true : false}
								//case it is edit
								defaultValue={editAddress?.city}
							/>
							<Input
								className="estado"
								label=""
								placeholder="Estado"
								flex="2"
								name="state"
								ref={register({ required: true })}
								type="text"
								border={false}
								error={errors.state ? true : false}
								//case it is edit
								defaultValue={editAddress?.state}
							/>
						</div>
					</div>
				</InputsContainer>
				<button type="submit">
					{!address ? 'Adicionar novo endereço' : 'Alterar endereço'}
				</button>
			</FormContainer>
		</Container>
	);
};

export default UserAddressMobile;
