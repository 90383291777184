import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-bottom: -20px;
	
	
	@media (max-width: 768px) {
		display: none;
	}
	@media (max-width: 505px) {
		overflow-x: scroll;
	}

	.item {
		color: #5a646e;
		font-size: 16px;
		font-family: var(--Roboto-font-family);
		font-weight: 400;
		text-decoration: none;
		background: #ebebeb;
		/* width: 35%;
		height: 50px; */
		width: 206px;
		height: 57px;
		max-width: 220px;
		min-width: 79px;

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		/* margin-bottom: 10px; */
		border-radius: 8px 8px 0px 0px ;
	}

	.item + .item {
		margin-left: 25px;
	}
	@media (max-width: 585px) {
		.item + .item {
			margin-left: 5px;
		}
	}

	.item-active {
		background: white;
		color: #2d327c;

		font-size: min(12px, 0.8vw);
		font-family: var(--Roboto-font-family);
		font-weight: 600;
		text-decoration: none;
		width: 206px;
		height: 57px;

		font-size: 16px;
		max-width: 220px;
		min-width: 79px;

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.button-container {
		width: 297px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-end;
		

		button {
			/* height: 90%;
			width: 30%; */
			max-width: 278px;
			width: 97%;
			height: 57px;
			;
			font-size: min(16px, 1.3vw);
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			color: white;
			border: none;
			background: #2b63fc;
			border-radius: 8px;
			transition: 0.3s;
			margin-bottom: 10px;
			

			&:hover {
				cursor: pointer;
				background: #fff;
				color: #2b63fc;
				border: 1px solid #2b63fc;
			}
			@media (max-width: 585px) {
				margin-left: 5px;
			}
		}
	}
`;
