import React, { useEffect, useState } from 'react';

import { Container, FiltersContainer } from './styles';
import { ReactComponent as SearchIcon } from '../../../assets/icons/searchIcon.svg';
import MyProductsCard from './MyProductsCard/MyProductsCard';
import api from '../../../api';
import { useDispatch } from 'react-redux';
import { insertProducts } from '../../../actions/ProductsList';
import { productExibitionRules } from '../../../utils/productsExibitionRules';
import { AreaTypes } from '../../../interfaces/ProductsList';
import SellerReputationCardVertical from '../SellerAreaMenu/SellerReputationCardVertical/SellerReputationCardVertical';

const MyProducts: React.FC = () => {
	const [searchQuery, setSearchQuery] = useState('');
	const dispatch = useDispatch();
	const [selectedOption, setSelectedOption] = useState('all');

	return (
		<>
			<Container>
				<FiltersContainer>
					<div className="select-container">
						<div className="toggle">
							<label>Produtos</label>
							<div className="options">
								<div
									className={`option-all ${
										selectedOption === 'all' ? 'active' : ''
									}`}
									onClick={() => setSelectedOption('all')}
								>
									<span>Todos</span>
								</div>
								<div
									className={`option-for-sale ${
										selectedOption === 'sale'
											? 'active'
											: ''
									}`}
									onClick={() => setSelectedOption('sale')}
								>
									<span>À venda</span>
								</div>
								<div
									className={`option-deactivated ${
										selectedOption === 'deactivated'
											? 'active'
											: ''
									}`}
									onClick={() =>
										setSelectedOption('deactivated')
									}
								>
									<span>Inativos</span>
								</div>
							</div>
						</div>
						<div className="search-container">
							<div className="search-card">
								<input
									type="text"
									onChange={(e) =>
										setSearchQuery(e.target.value)
									}
								/>
								<SearchIcon
									className="search-icon"
								/>
							</div>
						</div>
					</div>
				</FiltersContainer>
				<MyProductsCard listFilter={selectedOption} searchQuery={searchQuery}></MyProductsCard>
			</Container>
		</>
	);
};

export default MyProducts;
