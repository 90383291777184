import axios from 'axios';
import { removeUser } from './actions/UserActions';
import config from './config';
import store from './store';
const token = localStorage.getItem('authorization');
const api = axios.create({
	baseURL: `${config.host}`,
	withCredentials: true,

	headers: { authorization: token! },
});

api.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (Number(error.message.replace(/[^0-9]/g, '')) === 401 && token) {
			fetch(`${config.host}/refresh_token`, {
				method: 'POST',
				credentials: 'include',
				headers: {
					authorization: localStorage.getItem('refresh_token')!,
				},
			})
				.then(async (result) => {
					const { accessToken, refreshToken } = await result.json();
					if (!accessToken && !refreshToken) {
						store.dispatch(removeUser());
						window.location.href = '/seller';
					}

					localStorage.setItem('authorization', accessToken);
					localStorage.setItem('refresh_token', refreshToken);
					return result;
				})
				.catch((err) => {
					store.dispatch(removeUser());
					window.location.href = '/seller';
				});
		} else if (
			Number(error.message.replace(/[^0-9]/g, '')) === 401 &&
			!token
		) {
			store.dispatch(removeUser());
			window.location.href = '/seller';
		}

		return Promise.reject(error);
	}
);

export default api;
