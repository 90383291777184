import styled from 'styled-components';

export const Container = styled.footer`
	width: 100%;
	height: 307px;
	background: #ffffff;

	display: flex;
	flex-direction: column;
	align-items: flex-end;
	
	@media (max-width:768px){
		display: none;
	};

	.top-area {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;	
		.logo-and-contact-icons {
			display: flex;
			flex-direction: column;
			align-items: center;
			height: 150px;
			.icons {
				display: flex;
				justify-content: space-around;
				width: 100%;
				margin-top: 15px;
				column-gap: 35px;
				img {
					width: 20px;
					height: 14px;
				}
				svg {
					width: 20px;
					height: 20px;
				}

				.whats-app-icon {
					width: 20px;
					height: 14px;
				}
			}
		}

		.area-item {
			height: 150px;
			text-align: center;
			h3 {
				font: normal normal normal 20px/32px Roboto;
				/* font-family: var(--Roboto-font-family);
				font-weight: 500;
				font-size: min(20px, 1.8vw); */
				color: #000000;
				margin-bottom: 15px;
			}
			.links {
				display: flex;
				flex-direction: column;

				.link {
					font: normal normal normal 14px/20px Roboto;
					/* font-family: var(--Roboto-font-family);
					font-weight: 400;
					font-size: min(14px, 1.6vw); */
					color: #8a99a8;
					text-decoration: none;
					margin-bottom: 10px;
				}
			}
		}
	}

	.bottom-area {
		width: 100%;
		height: 52px;
		background: #f5f5f5;

		display: flex;
		align-items: center;
		justify-content: center;

		.container {
			display: flex;
			align-items: center;
			width: var(--Default-page-alignment-vale);
			height: 100%;
			justify-content: space-between;

			span {
				color: #5a5a5a;
				font-family: var(--Roboto-font-family);
				font-weight: 400;
				font-size: min(12px, 1.4vw);
			}
		}
	}
`;

export const AlignmentContainer = styled.div`
	width: var(--Default-page-alignment-vale);
	height: max-content;
	margin: auto;
`;
