import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	height: 70px;
	background: white;
	border-radius: 12px;
	font-family: 'Roboto';
	box-shadow: 0px 3px 6px #00000029;
	cursor: pointer;
	h3 {
		font-size: 20px;
		color: #2b63fc;
	}
	h4 {
		font-size: 12px;
		color: #5a646e;
	}
	.col-icon {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-left: 18px;
		svg {
			color: #2b63fc;
		}
	}
	.col-infos {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-left: 18px;
	}
`;
