import styled from 'styled-components';
import ACPlace_reduzido from '../../../assets/acpLogo/ACPlace_reduzido.png';
import acpLogoMobile from '../../../assets/acpLogo.png';

export const DefaultRow = styled.div`
	display: flex;
	flex-direction: row;
`;

export const TopMargin = styled.div`
	margin-top: 86px;
	@media (max-width: 768px) {
		margin-top: 72px;
	}
`;

export const ContainerColumn = styled.div`
	/* display: none; */
	flex-direction: column;
	flex: 1;
	background: #fff;
	@media (max-width: 768px) {
		flex: 1;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		border-bottom: 1px solid #eee;
		padding-left: 5%;
		padding-right: 5%;
	}

	.hide-mobile {
		@media (max-width: 768px) {
			display: none;
		}
	}
`;

export const DefaultColumn = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	background: #fff;
	@media (max-width: 768px) {
		flex: 1;
	}
`;

export const Container = styled(DefaultRow)`
	width: 100%;
	/* display:none; */
	background: #fff;
	height: max-content;
	position: fixed;
	z-index: 2;
	border-bottom: 1px solid #eee;
`;

export const AlignmentContainer = styled.div`
	width: var(--Default-page-alignment-vale);
	height: max-content;
	margin: auto;
	@media (max-width: 768px) {
		width: 100%;
	}
`;

export const LoginCardContainer = styled.form`
	width: max-content;
	height: max-content;
	position: absolute;

	.login-card {
		z-index: 1;
		width: 278px;
		height: 204px;
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px #00000029;
		position: relative;
		top: 8px;
		right: 232px;
		padding: 15px;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		padding-top: 5px;
		padding-bottom: 5px;
		h3 {
			color: #969696;
			font-family: var(--Roboto-font-family);
			font-size: 0.58rem;
		}

		.input-container {
			display: flex;
			flex-direction: column;

			label {
				margin-bottom: 5px;
				color: #969696;
				font-family: var(--Roboto-font-family);
				font-weight: 400;

				font-size: 0.58rem;
			}

			input {
				border: solid 1px #e1e1e1;
				height: 22px;
				padding: 2px;
			}
		}
		.login-actions {
			display: flex;
			flex-direction: column;
			width: 100%;

			.login-button {
				width: 100%;
				align-self: center;
				margin-top: 10px;
				height: 27px;
				background: #6caa36;
				color: white;
				border: none;
				font-family: var(--Roboto-font-family);
				font-weight: bold;
				font-size: 0.58rem;
			}
		}
		.forgot-password-link {
			align-self: center;
			color: #7b82e6;
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			font-size: 0.58rem;
		}
	}
`;

export const LogoCard = styled.div`
	margin-right: 56px;
	margin-top: 6px;
	cursor: pointer;
	width: 200px;
	height: 52.857px;
	background-image: url(${ACPlace_reduzido});
	background-repeat: no-repeat;
	background-size: contain;
	@media (max-width: 768px) {
		background-image: url(${acpLogoMobile});
		width: 102px;
		height: 29px;
		margin: 15px 0px;
	}
`;

export const TopHeader = styled(DefaultRow)`
	/* background-color: white; */
	height: 100%;
	margin-top: 8px;
	align-items: flex-start;
	min-height: 62px;
	flex: 1;

	.logoMobile {
		margin-right: 56px;
		margin-top: 6px;
		cursor: pointer;
		width: 200px;
		height: 52.857px;
	}

	.icons {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		column-gap: 40px;
		width: 100%;
		min-width: 165px;
		max-width: 211px;
		margin-top: 5px;

		@media (max-width: 768px) {
			display: none;
		}

		svg,
		img {
			width: 22px;
			height: 22px;
			cursor: pointer;
			@media (max-width: 768px) {
				color: yellow;
			}
		}
		img:hover {
			cursor: pointer;
		}

		.notifications-background {
			width: 24px;
			height: 24px;
			background: #ca7a52 0% 0% no-repeat padding-box;
			border-radius: 12px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			&:hover {
				cursor: pointer;
			}
		}

		.user-img {
			width: 22px;
			height: 22px;
			border-radius: 15px;
			object-fit: cover;
		}
	}
`;

export const BottomHeader = styled(DefaultRow)`
	background-color: #ffffff;
	height: 100%;
	padding-top: 8px;
	column-gap: 35px;
	@media (max-width: 768px) {
		display: none;
	}
`;

export const Logo = styled.img`
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	margin-right: 56px;
	font-size: 32px;
	color: black;
	width: 107px;
	height: 25px;
	/* width: 88px;
	height: 46px; */

	object-fit: cover;
	&:hover {
		cursor: pointer;
	}
`;

export const Infos = styled.p`
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
	height: 100%;
	align-items: center;
	justify-content: center;
	margin-right: 20px;
	font-family: var(--Roboto-font-family);
	font-size: 12px;
	color: #858585;
	font-weight: 500;
`;

export const SearchBox = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid #c5ced7;
	border-radius: 8px;

	max-width: 578px;
	width: 95%;
	height: 32px;
	color: #858585;
	@media (max-width: 768px) {
		margin-right: 10px;
		margin-left: 10px;
		width: auto;
		margin-top: 5px;
		min-width: 128px;
		flex: 1;
	}
`;

export const SerchRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: 8px 8px 8px 7px;
`;

export const Search = styled.input`
	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: white;
	padding-left: 10px;

	border: none;
	font-style: italic;
	font-family: var(--Roboto-font-family);
	font-size: 14px;

	::placeholder {
		color: #c5ced7;
	}

	&:focus {
		outline: none;
	}

	@media (max-width: 400px) {
		width: 105px;
	}
`;

export const SearchIcon = styled.div`
	display: flex;
	flex-direction: column;
	height: 18px;
	width: 18px;
	svg {
		height: 100%;
		width: 100%;
		fill: #7f7f7f;
		cursor: pointer;
	}
`;

export const MarketColumn = styled.div`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 22px;
	height: 22px;
	padding-right: 24px;
	margin-right: 24px;
	border-right: 1px solid #cbcbcb;
	margin-left: 17rem;

	@media (max-width: 1366px) {
		margin-left: 1rem;
	}

	svg {
		height: 100%;
		width: 100%;
		fill: #7f7f7f;
		cursor: pointer;
	}
`;

export const TopHearderButtons = styled.div`
	display: flex;
	flex-direction: column;
	width: max-content;
	padding: 0 22px;
	height: 32px;
	align-items: center;
	justify-content: center; /* 
	background-color: #ffffff; */
	color: #ffffff;
	font-family: var(--Roboto-font-family);
	font-size: 14px;
	font-weight: normal;
	margin-right: 9px;
	background: #2b63fc;
	border-radius: 8px;

	&:hover {
		cursor: pointer;
	}
`;

export const BottomHeaderButtons = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: max-content;
	font-family: var(--Roboto-font-family);
	font-size: 12px;
	font-weight: 500;
	color: #2d327c;

	&.active-text {
		color: #ffffff;
	}
	&.have-icon {
		margin-right: 8px;
		position: relative;
	}

	&:hover {
		cursor: pointer;
	}
`;

export const MenuContainer = styled.div`
	position: absolute;
	width: 10px;
	z-index: 1;
	top: 26px;
	left: -30px;
	.menu {
		width: 231px;
		height: 261px;
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px #00000029;
		position: relative;
		border-radius: 12px;
		display: flex;
		flex-direction: column;
		row-gap: 21px;
		padding-left: 14px;
		padding-top: 34px;

		.menu-item {
			display: flex;
			align-items: center;

			img {
				width: 18px;
				height: 18px;
			}
			svg {
				width: 20px;
				height: 20px;
				cursor: pointer;
			}

			span {
				font-family: var(--Roboto-font-family);
				font-weight: 400;
				font-size: max(13px, 0.7vw);
				color: #959595;
				margin-left: 10px;
				&:hover {
					cursor: pointer;
				}
			}
		}

		.logout-button {
			display: flex;
			justify-content: flex-start;
			margin-top: 35px;
			a {
				margin-left: 10px;
				font-family: var(--Roboto-font-family);
				font-weight: 400;
				font-size: max(13px, 0.7vw);
				color: #959595;
				text-decoration: none;
			}
		}
	}
`;

export const ButtonIconContainer = styled(DefaultRow)`
	position: relative;
	width: max-content;
	height: max-content;
	align-items: center;
	padding: 7px 6px 9px 11px;

	svg {
		fill: #2d327c;
		width: 20px;
		height: 20px;
	}
	&.active {
		background-color: #002793;
		svg {
			fill: #ffffff;
		}
	}
	.header-icon {
		&:hover {
			cursor: pointer;
		}
	}
`;

export const DropDownCard = styled(DefaultColumn)`
	z-index: 1;
	position: absolute;
	background: #353a40;
	border-radius: 8px;
	padding: 20px;
	padding-top: 10px;
	width: 155px;
	top: 30px;
	left: -10px;
`;

export const DropDownItem = styled.div`
	font-family: var(--Roboto-font-family);
	font-weight: normal;
	font-size: max(13px, 0.7vw);
	color: #ffffff;
	margin-top: 20px;
	&:hover {
		cursor: pointer;
	}
`;

export const MobileLogoBox = styled.div`
	display: flex;
	max-width: 578px;
	width: 95%;
	height: 100%;
	justify-content: center;
	align-items: center;
	@media (min-width: 768px) {
		display: none;
	}
`;

export const NotificationsContainer = styled.div`
	position: relative;
`;
