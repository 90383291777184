import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import HeaderAdmin from '../HeaderAdmin/HeaderAdmin';
import LoginHeader from '../Login/LoginHeader/LoginHeader';
import MyProducts from '../SellerArea/MyProducts/MyProducts';
import Sales from '../SellerArea/Sales/Sales';
import EditHome from './EditHome/EditHome';
import Featured from './Featured/Featured';
import MenuAds from './MenuAds/MenuAds';
import PatronizeProduct from './PatronizeProduct/PatronizeProduct';

import { Container } from './styles';

const Edit: React.FC = () => {
	const { path, url } = useRouteMatch();
	return (
		<>
			<HeaderAdmin />
			<Container>
				<div className="text-center">Anúncios</div>
				<MenuAds />
				{!window.location.href.includes('/banners') &&
					!window.location.href.includes('/Featured') &&
					!window.location.href.includes('/PatronizeProduct')}
				<Route path={`${url}/banners`} component={EditHome} />
				<Route path={`${url}/Featured`} component={Featured} />
				<Route
					path={`${url}/PatronizeProduct`}
					component={PatronizeProduct}
				/>
			</Container>
		</>
	);
};

export default Edit;
