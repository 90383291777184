import React, { useEffect, useState } from 'react'
import { RiStarFill, RiStarLine } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import api from '../../../api'
import UserAccount from '../../../interfaces/UserAccount'
import { AppState } from '../../../store'
import { Star, StarsContainer, Container, ReviewTextArea, SendReview } from './styles'

interface Props {
    productId: string;
}

export const AvaliationContainer: React.FC<Props> = ({
    productId,
}: Props) => {
    const [stars, setStars] = useState<number>(0)
    const [review, setReview] = useState<string>('')

    const [foundReview, setFoundReview] = useState<any | undefined>(undefined);

    const [canEdit, setCanEdit] = useState<boolean>(false);

	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);

    const handleSubmit = async () => {
        if(stars <= 0) { alert("Selecione uma estrela de 1 a 5"); return; }
        if(review.length <= 0) { alert("Escreva uma avaliação"); return; }

        const response = await api.post('/product-review', {
            user_account_id: user.id,
            product_id: productId,
            review,
            rating: stars,
        })

        setFoundReview(response.data.createdReview)
    }

    useEffect(() => {
        setCanEdit(!foundReview);
    }, [foundReview])

    useEffect(() => {
        const fetch = async () => {
            const response = await api.get('/product-user-review', {
                params: {
                    user_account_id: user.id,
                    product_id: productId
                }
            });

            console.log({response})

            const foundReview = response.data.foundReview || undefined;

            setFoundReview(foundReview);
        }

        fetch();
    }, [productId])

    return (
        <Container>
            <StarsContainer>
                {[...Array(5)].map((star, index) => (
                    <Star
                        canEdit={canEdit}
                        onClick={() => {
                            if(!canEdit) return;

                            setStars(index + 1);
                        }}
                    >
                        {
                            index + 1 <= (
                                foundReview ?
                                    foundReview.rating :
                                    stars
                            ) ?
                                <RiStarFill/>
                                :
                                <RiStarLine/>
                        }
                        <p>{index + 1}</p>
                    </Star>
                ))}
            </StarsContainer>
            <ReviewTextArea
                disabled={!canEdit}
                placeholder={canEdit ? 'Deixe sua avaliação' : 'Sem avaliação'}
                onChange={(e) => setReview(e.target.value)}
                value={
                    foundReview ?
                        foundReview.review :
                        review
                }
            />
            {
                canEdit &&
                <SendReview
                    onClick={handleSubmit}
                    disabled={stars <= 0 || review.length <= 0 || !canEdit}
                >
                    Enviar avaliação
                </SendReview>
            }
        </Container>
    )
}