import React from 'react';
import Header from '../../components/Default/header';
import SellerNotification from '../../components/SellerNotification/SellerNotification';
import { Container } from './styles';

const SellerNotificationPage: React.FC = () => {
	return (
		<Container>
			<Header />
			<SellerNotification />
		</Container>
	);;
}

export default SellerNotificationPage;
