import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { Container } from './styles'
import mobile_logo from '../../../../assets/acpLogo/mobile_logo.png';
import { useSelector } from 'react-redux';
import MobileHeader from '../../../../interfaces/MobileHeader';
import { AppState } from '../../../../store';

const MobileLeftComponent: React.FC = () => {
	const mobileHeader: MobileHeader = useSelector(
		(state: AppState) => state.mobileHeader.currentMobHeader
	);
  const history = useHistory();

  return (
    <Container>
      {mobileHeader.leftSideComp.leftSide === 0 && (
        <img
          alt="Logo Acp"
          width={52}
          height={29}
          style={{
            cursor: 'pointer',
          }}
          src={mobile_logo}
          onClick={() => history.push('/seller')}
        />
      )}
      {mobileHeader.leftSideComp.leftSide === 1 && (
        <div className="back">
            <IoIosArrowBack 
            style={{ fill: '#2D327C' }}
            onClick={() => history.push(mobileHeader.leftSideComp.backArrowPath!)}
            />
        </div>
      )}
    </Container>
  );

};

export default MobileLeftComponent;
