import styled from 'styled-components';

export const Container = styled.div`
	@media (max-width: 768px) {
		display: none;
	}
	width: 248px;
	height: 250px;
	background: #fff;
	border-radius: 8px;
	margin-bottom: -280px;
	margin-left: 260px;
	/* margin-top: 10px; */
	padding: 15px;
`;

export const TopDiv = styled.div`
	display: flex;
	flex-direction: column;
	font-family: var(--Roboto-font-family);

	span {
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 24px;
	}
	#edit-store {
		background: #00b06a;
		font-size: 13px;
		font-weight: 400;
		width: 100%;
		max-height: 34px;
		border-radius: 8px;
		margin-top: 15px;
		transition: 0.3s;
	}
	#edit-store:hover {
		background: #fff;
		border: 1px solid #00b06a;
		color: #00b06a;
	}
`;

export const BottomDiv = styled.div`
	display: flex;
	flex-direction: column;
	width: 87%;
	height: 48%;
	border: 1px solid #c5ced7;
	border-radius: 8px;
	font-family: var(--Roboto-font-family);
	padding: 20px 16px;

	h4 {
		font-size: 20px;
		color: #2c63fc;
	}

	div.stars {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 20px;
		margin: 10px 0 18px 0;

		svg {
			color: #2c63fc;
		}
	}
`;
export const ReputationBar = styled.div`
	width: 218px;
	height: 3px;
	background: #2c63fc 0% 0% no-repeat padding-box;
	margin-top: 7px;
	margin-bottom: 15px;
`;
export const ReputationRow = styled.div`
	display: flex;
	flex-direction: row;
	span {
		font-size: 18px;
		color: #959595;
		margin-right: 22px;
		text-align: center;
	}
`;

export const ReputationInfos = styled.div`
	font-size: 13px;
	color: #5a5a5a;
	grid-template-columns: 60px 200px;
	display: grid;
	grid-row-gap: 7px;

	.item-grid {
		span {
			font-size: 16px;
			color: #959595;
		}
	}
`;
