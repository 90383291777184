import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UserAccount from '../interfaces/UserAccount';
import { AppState } from '../store';
import AdministratorRoutes from './administrator.routes';
import CustomerRoutes from './customer.routes';
import SellerRoutes from './seller.routes';

const AuthRoutes: React.FC = () => {
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	if (user && user.user_types.name === 'Administrator') {
		return <AdministratorRoutes />;
	} else if (user && user.user_types.name === 'Customer') {
		return <CustomerRoutes />;
	} else if (user && user.user_types.name === 'Seller') {
		return <><SellerRoutes /><CustomerRoutes/></>;
	} else {
		return <></>;
	}
};
export default AuthRoutes;
