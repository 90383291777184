import { IProductAnswers, IProductQuestions } from '../components/Product/ProductAreas/ProductAreas';
import {
	InsertProduct,
	InsertProductAnswer,
	InsertProductQuestion,
	Product,
} from '../interfaces/Product';

export const insertProduct = (product: Product): InsertProduct => ({
	type: 'INSERT_PRODUCT',
	product,
});

export const insertProductQuestion = (
	question: IProductQuestions
): InsertProductQuestion => ({
	type: 'INSERT_PRODUCT_QUESTION',
	question,
});

export const insertProductAnswer = (
	question: IProductQuestions,
	answer: IProductAnswers
): InsertProductAnswer => ({
	type: 'INSERT_PRODUCT_ANSWER',
	question,
	answer,
});