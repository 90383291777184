import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { insertProductAnswer } from '../../../../../actions/ProductActions';
import api from '../../../../../api';
import { Product } from '../../../../../interfaces/Product';
import UserAccount from '../../../../../interfaces/UserAccount';
import { AppState } from '../../../../../store';
import { IProductAnswers, IProductQuestions } from '../../ProductAreas';

import {
	Container,
	QuestionActions,
	AnswersContainer,
	ShowMore,
	AnswerDescription,
	ReplyButton,
} from './styles';

interface FormInputs {
	description: string;
}
type Props = {
	question: IProductQuestions;
};

const QuestionItem: React.FC<Props> = ({ ...props }) => {
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const product: Product = useSelector(
		(state: AppState) => state.product.currentProduct
	);
	const dispatch = useDispatch();

	const { register, handleSubmit, errors } = useForm<FormInputs>();
	const [
		replyContainerIsVisible,
		setReplyContainerVisible,
	] = useState<boolean>(false);
	const [anwserNumber, SetAnwserNumber] = useState<number>(3);

	const onSubmit = async (data: FormInputs) => {
		api.post('/create-answer-product-question', {
			answer_description: data.description,
			product_question_id: props.question.id,
			user_account_id: user.id,
		}).then((response) => {
			dispatch(
				insertProductAnswer(props.question, response.data.response)
			);
			setReplyContainerVisible(false);
		});
	};
	return (
		<>
			<Container>
				<div className="row">
					<span className="question-container">{props.children}</span>
					{user && user.user_types.name === 'Administrator' && (
						<div className="delete">Excluir</div>
					)}
				</div>
			</Container>
			<AnswersContainer>
				{props.question.answers_product_questions
					?.slice(0, anwserNumber)
					.map((answer) => (
						<AnswerDescription>
							{answer.answer_description}
						</AnswerDescription>
					))}
				{props.question.answers_product_questions &&
					props.question.answers_product_questions!.length >
						anwserNumber && (
						<ShowMore
							onClick={() => SetAnwserNumber(anwserNumber + 3)}
						>
							Ver mais respostas
						</ShowMore>
					)}
			</AnswersContainer>
			{!replyContainerIsVisible &&
				user.id === product.user_account_id && (
					<ReplyButton onClick={() => setReplyContainerVisible(true)}>
						Responder
					</ReplyButton>
				)}
			{replyContainerIsVisible && (
				<QuestionActions onSubmit={handleSubmit(onSubmit)}>
					<div className="text-container">
						<textarea
							name="description"
							ref={register({ required: true })}
						></textarea>
						<div className="text-containter-actions">
							<button
								className="negative-button"
								onClick={() => setReplyContainerVisible(false)}
							>
								Cancelar
							</button>
							<button className="positive-button" type="submit">
								Confirmar
							</button>
						</div>
					</div>
				</QuestionActions>
			)}
		</>
	);
};

export default QuestionItem;
