import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';
import { MdAttachMoney } from 'react-icons/md';
import {
	RiShoppingCart2Fill,
	RiStore3Fill,
	RiLogoutBoxFill,
	RiSearch2Line,
} from 'react-icons/ri';
import { VscThreeBars } from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { removeUser } from '../../../../actions/UserActions';
import api from '../../../../api';
import MobileHeader from '../../../../interfaces/MobileHeader';
import { ProductCategory } from '../../../../interfaces/ProductCategory';
import UserAccount from '../../../../interfaces/UserAccount';
import { AppState } from '../../../../store';
import { ProductCategories } from '../../../ProductRegister/ProductRegister';
import {
	Drop,
	MobileMenuContainer,
	Overlay,
	SearchBoxStore,
	SearchIcon,
	SearchStore,
	SerchRowStore,
	SubContainer,
} from './styles';

const MobileMenu: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const mobileHeader: MobileHeader = useSelector(
		(state: AppState) => state.mobileHeader.currentMobHeader
	);

	const [menuActive, setMenuActive] = useState<boolean>(false);
	const [menuAnimation, setMenuAnimation] = useState<string>('');
	const [showCategories, setShowCategories] = useState<boolean>(false);
	const [showStores, setShowStores] = useState<boolean>(false);
	const [containerClassName, setContainerClassName] = useState<string>('');
	const [stores, setStores] = useState<UserAccount[]>();
	const [searchStores, setSearchStores] = useState<UserAccount[]>();
	const [
		productCategories,
		setProductCategories,
	] = useState<ProductCategory[]>();
	const [searchCategories, setSearchCategories] = useState<ProductCategory[]>();

	useEffect(() => {
		api.get('/get-product-categories')
			.then((response: AxiosResponse<ProductCategories>) => {
				setProductCategories(response.data.categories);
				setSearchCategories(response.data.categories);
			})
			.catch((err) => {});
	}, []);

	useEffect(() => {
		let className = '';
		if (menuActive) {
			className += 'active ';
		}
		if (showCategories || showStores) {
			className += 'sub-active';
		}
		setContainerClassName(className);
	}, [menuActive, showCategories, showStores]);

	useEffect(() => {
		if (!menuActive) {
			setShowCategories(false);
			setShowStores(false);
		}
	}, [menuActive]);

	const handleCloseMenu = () => {
		setMenuAnimation('');
		setMenuActive(false);
	};

	useEffect(() => {
		api.get('/get-all-shops')
			.then((response: any) => {
				setStores(response.data.shops);
				setSearchStores(response.data.shops);
			})
			.catch((err) => {});
	}, []);

	const handleSearchStore = (storeSearch: string) => {
		setSearchStores(
			stores!.filter((store) => {
				return store
					.social_reason!.toLocaleLowerCase()
					.includes(storeSearch.toLowerCase());
			})
		);
	};

	const handleSearchCategory = (categorySearch: string) => {
        setSearchCategories(
            productCategories!.filter((category) => {
                return category.name.toLocaleLowerCase()
                .includes(categorySearch.toLocaleLowerCase());
            })
        );
    };

	return (
		<>
			<Drop>
				{mobileHeader.rightSideComp.rightSide === 0 && (
					<RiShoppingCart2Fill
						style={{ fill: '#2D327C' }}
						onClick={() => history.push(`/cart/${'header'}`)}
					/>
				)}
				<div
					className={'box ' + menuAnimation}
					onClick={() => {
						setMenuAnimation(menuAnimation === 'on' ? '' : 'on');
						setMenuActive(!menuActive);
					}}
				>
					<span></span>
					<span></span>
					<span></span>
				</div>
				<MobileMenuContainer className={containerClassName}>
					<div className="menu">
						{!showStores && (
							<div
								className={
									showCategories
										? 'active-item arrow-menu-item'
										: 'arrow-menu-item'
								}
								onClick={() =>
									setShowCategories(!showCategories)
								}
							>
								<span>Categorias</span>
								<IoIosArrowForward
									fill="#2D327C"
									className="header-icon"
								/>
							</div>
						)}
						{!showCategories && (
							<div
								className={
									showStores
										? 'active-item arrow-menu-item'
										: 'arrow-menu-item'
								}
								onClick={() => setShowStores(!showStores)}
							>
								<span>Lojas Associadas</span>
								<IoIosArrowForward
									fill="#2D327C"
									className="header-icon"
								/>
							</div>
						)}
						{showCategories && (
							<SubContainer>
								<SearchBoxStore>
									<SerchRowStore>
										<SearchIcon>
											<RiSearch2Line
												style={{ fill: '#fff' }}
												onClick={(e) => {}}
											/>
										</SearchIcon>
										<SearchStore
											type="text"
											onChange={(e: any) =>
												handleSearchCategory(
													e.target.value
												)
											}
											placeholder="Buscar categorias..."
										></SearchStore>
									</SerchRowStore>
								</SearchBoxStore>
								{searchCategories?.map(
									(category) => (
										<div
											onClick={() => {
												history.push(
													`/search/${category.id}/category/all/0/100000000`
												);
												handleCloseMenu();
											}}
										>
											<span>{category.name}</span>
										</div>
									)
								)}
							</SubContainer>
						)}
						{showStores && (
							<SubContainer>
								<SearchBoxStore>
									<SerchRowStore>
										<SearchIcon>
											<RiSearch2Line
												style={{ fill: '#fff' }}
												onClick={(e) => {}}
											/>
										</SearchIcon>
										<SearchStore
											type="text"
											onChange={(e: any) =>
												handleSearchStore(
													e.target.value
												)
											}
											placeholder="Buscar lojas..."
										></SearchStore>
									</SerchRowStore>
								</SearchBoxStore>
								{searchStores?.map((store) => (
									<div
										onClick={(e) => {
											if (user && store.id === user.id) {
												history.push(
													`/my-store/${store.id}/all/0/100000000/undefined`
												);
											} else {
												history.push(
													`/seller-area/${store.id}/all/0/100000000/undefined`
												);
											}
										}}
									>
										<span>
											{store.shop_customizations
												? store.shop_customizations
														.shop_name
												: 'Sem nome'}
										</span>
									</div>
								))}
							</SubContainer>
						)}
						{!showStores && !showCategories && (
							<>
								<div className="arrow-menu-item border-top">
									<span
										onClick={() =>
											history.push('/about-us')
										}
									>
										Quem somos
									</span>
								</div>
								<div className="arrow-menu-item">
									<span
										onClick={() => history.push('/join-us')}
									>
										Associe-se
									</span>
								</div>
								<div className="logout-button border-top">
									<RiLogoutBoxFill
										style={{ fill: '#2B63FC' }}
									/>
									<Link to="/login/header">Entrar</Link>
								</div>
							</>
						)}
					</div>
				</MobileMenuContainer>
			</Drop>
			<Overlay
				className={menuActive ? 'active' : ''}
				onClick={() => {
					handleCloseMenu();
				}}
			/>
		</>
	);
};

export default MobileMenu;
