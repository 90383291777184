import { Input } from 'semantic-ui-react';
import styled from 'styled-components';

export const Container = styled.div`
	width: 80%;
	margin-left: 10%;
	min-height: 100vh;
	padding: 40px;
	background: white;
	font-size: 16px;
	font-family: 'Roboto';

	.text-button {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		color: #353a40;
		p {
			padding-bottom: 40px;
		}
		span {
			width: 132px;
			height: 30px;
			background: #00b06a;
			padding-top: 10px;
			color: white;
		}
	}
	img {
		height: 135px;
		margin-top: 22px;
	}
	.img {
		display: grid;
		grid-template-rows: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		.banner-img {
			position: relative;
			width: 100%;
			height: 100%;
		}
		.buttons {
			position: absolute;
			display: flex;
			justify-content: center;
			width: 100%;
			top: 0px;
			margin-top: 10%;
			color: #c9182a;
			span,
			p {
				display: none;
				font-size: 16px;
				font-family: 'Roboto';
				font-weight: 500;
				cursor: pointer;
				padding: 0 50px;
			}
			p {
				color: #c9182a;
				text-decoration: none;
			}
			span {
				color: #5a646e;
				text-decoration: underline;
			}
			label {
				display: none;
			}
		}
		.buttons.active {
			p {
				color: #5a646e;
				text-decoration: underline;
			}
			span {
				color: #c9182a;
				text-decoration: none;
			}
			label {
				display: block;
			}
		}
		.banner-img.active {
			span,
			p,
			label {
				display: block;
				cursor: pointer;
			}
			img {
				filter: blur(1px);
				opacity: 0.5;
			}
		}
	}
	.img-add {
		width: 544px;
		height: 135px;
		border-radius: 14px;
		border: 1px solid #2b63fc;
		cursor: pointer;
		margin-top: 22px;
		position: relative;
		label {
			position: absolute;
			margin-top: 53px;
			margin-left: -54px;
			font-size: 16px;
			font-family: 'Roboto';
			font-weight: 500;
			color: #2b63fc;
			cursor: pointer;
		}
		Input {
			display: none;
		}
	}
	.banners-intermediary {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		padding-bottom: 100px;
		align-items: baseline;
	}
	.banners-int {
		display: flex;
	}
	.img-int {
		position: relative;
		width: 100%;
		height: 100%;
		label {
			display: none;
			cursor: pointer;
		}
	}

	.img-int.active {
		position: relative;
		img {
			filter: blur(1px);
			opacity: 0.5;
		}
		label {
			display: flex;
			left: 45%;
			top: 37%;
			height: 100%;
			width: 100%;
			position: absolute;
			font-size: 16px;
			font-weight: 500;
			text-decoration: underline;
			cursor: pointer;
		}
	}
`;

export const IntermediaryTitle = styled.p`
	font-size: 16px;
	color: #353a40;
	margin-top: 59px;
	margin-bottom: 25px;
	text-align: left;
`;

export const InputBanner = styled.input`
	display: none;
`;
