import React, { useEffect, useState } from 'react';
import api from '../../../../api';
import { RiSearch2Line } from 'react-icons/ri';
import config from '../../../../config';
import UserAccount from '../../../../interfaces/UserAccount';
import { AxiosResponse } from 'axios';

import {
	SearchBoxStore,
	SerchRowStore,
	SearchStore,
	SearchIcon,
	DropDownItem,
	StoreContainer,
} from './styles';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store';

const StoreDropDown: React.FC = () => {
	const history = useHistory();
	const [stores, setStores] = useState<UserAccount[]>();
	const [searchStores, setSearchStores] = useState<UserAccount[]>();

	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);

	useEffect(() => {
		api.get('/get-all-shops')
			.then((response: any) => {
				setStores(response.data.shops);
				setSearchStores(response.data.shops);
			})
			.catch((err) => {});
	}, []);

	const handleSearchStore = (storeSearch: string) => {
		setSearchStores(
			stores!.filter((store) => {
				return store
					.social_reason!.toLocaleLowerCase()
					.includes(storeSearch.toLowerCase());
			})
		);
	};

	return (
		<>
			<SearchBoxStore>
				<SerchRowStore>
					<SearchIcon>
						<RiSearch2Line
							style={{ fill: '#fff' }}
							onClick={(e) => {}}
						/>
					</SearchIcon>
					<SearchStore
						type="text"
						onChange={(e: any) => handleSearchStore(e.target.value)}
						placeholder="Buscar lojas..."
					></SearchStore>
				</SerchRowStore>
			</SearchBoxStore>
			<StoreContainer>
				{searchStores?.map((store) => (
					<DropDownItem
						onClick={(e) => {
							if (user && store.id === user.id) {
								history.push(
									`/my-store/${store.id}/all/0/100000000/undefined`
								);
							} else {
								history.push(
									`/seller-area/${store.id}/all/0/100000000/undefined`
								);
							}
						}}
					>
						{store.shop_customizations
							? store.shop_customizations.shop_name
							: 'Sem nome'}
					</DropDownItem>
				))}
			</StoreContainer>
		</>
	);
};

export default StoreDropDown;
