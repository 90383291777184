import styled from 'styled-components';

export const Container = styled.div`
	background: white;
	padding-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	overflow-y: auto;
	padding-bottom: 32px;
	border-bottom: solid 1px #e1e1e1;
	font-family: var(--Roboto-font-family);

	h2 {
		font-family: var(--Roboto-font-family);
		font-weight: normal;
		font-size: 20px;
		font-weight: 300;
		color: #353a40;
		margin-bottom: 17px;
	}

	h3 {
		font-family: var(--Roboto-font-family);
		font-weight: normal;
		font-size: 20px;
		font-weight: 300;
		color: #353a40;
		margin-bottom: 15px;
	}
	@media (max-width: 760px) {
		grid-row-start: 2;
		grid-column-end: 3;
		grid-column-start: 1;
	}
`;

export const MakeQuestionCard = styled.form`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 52px;

	#descrip::-webkit-input-placeholder {
		font-family: var(--Roboto-font-family);
		font-weight: 400;
		font-style: normal;
		color: #8a99a8;
	}

	.decription-textarea {
		flex: 3;
		margin-bottom: 16px;
		border: 1px solid #d5d5d5;
		padding: 16px 15px;
		border-radius: 8px;
		width: 100%;
		height: 92px;
		font-family: var(--Roboto-font-family);
		font-weight: normal;
		font-size: 14px;
	}

	button {
		flex: 1;
		width: 151px;
		height: 40px;

		background: #2b63fc;
		border: none;
		color: white;

		font-family: var(--Roboto-font-family);
		font-weight: normal;
		font-size: 16px;
		border-radius: 8px;
		margin-right: 20px;
		cursor: pointer;
	}

	p {
		font-family: var(--Roboto-font-family);
		font-size: 14px;
		color: #8a99a8;
		padding-right: 24;
	}
`;
export const MakeAnswerCard = styled.form``;

export const QuestionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	@media (max-width: 768px) {
		display: none;
	}
`;
export const DefualtRowMobile = styled.div`
	display: none;
	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;
		/* height: 70px; */
		width: 100%;
		p {
			font-family: var(--Roboto-font-family);
			padding: 21px;
		}
	}
	button {
		width: 100%;
		height: 40px;
		flex: none;
	}
`;

export const DefaultRowSubimit = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	@media (max-width: 768px) {
		display: none;
	}
	p {
		font-family: var(--Roboto-font-family);
	}
`;
export const DefaultRow = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	flex: 1;
	@media (max-width: 768px) {
		textarea {
			font-family: var(--Roboto-font-family);
		}
	}
`;
export const DefaultColumn = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: space-between;
`;

export const ShowMore = styled.span`
	color: #2b63fc;
	cursor: pointer;
	font-size: 16px;
`;
