import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/icons/acpLogoBlack.svg';
import UserAccount from '../../../interfaces/UserAccount';
import { AppState } from '../../../store';

import { Container, DivLogo } from './styles';

const Header: React.FC = () => {
	const history = useHistory();
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	return (
		<>
			<Container>
				<div className="center-text">
					<DivLogo>
						<Logo
							height={80}
						    width={250}
							style={{ cursor: 'pointer' }}
							onClick={() => history.push('/admin/welcome')}
						/>
					</DivLogo>
					<h1>Olá {user.name}</h1>
					<p>Quais são as tarefas de hoje?</p>
				</div>
			</Container>
		</>
	);
};
export default Header;
