import styled from 'styled-components';

export const PageContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100vh;
	align-items: center;
	text-align: justify;
`;

export const Container = styled.div`
	/* sbackground: #ffffff 0% 0% no-repeat padding-box; */
	text-align: center;
	border-radius: 8px;
	padding: 151px 21px 0 21px;
	width: 75%;
	@media (max-width: 768px) {
		width: 100%;
	}
	height: 100%;
	.logo {
		margin-bottom: 40px;
	}
	.a {
		fill: black !important;
	}
`;

export const ForgotPassword = styled.div`
	align-self: center;
	color: #5a646e;
	font-family: var(--Roboto-font-family);
	font-weight: 400;
	font-size: 0.58rem;
	cursor: pointer;
	text-decoration: underline;
`;

export const LoginCardContainer = styled.form`
	text-align: -webkit-center;
	.login-card {
		width: 270px;

		h3 {
			color: #969696;
			font-family: var(--Roboto-font-family);
			font-size: 0.58rem;
		}

		.input-container {
			display: flex;
			flex-direction: column;

			label {
				margin-bottom: 5px;
				color: #969696;
				font-family: var(--Roboto-font-family);
				font-weight: 400;

				font-size: 0.58rem;
			}

			input {
				/* height: 40px; */
				padding: 15px;
				background: #f5f5f5 0% 0% no-repeat padding-box;
				border: 1px solid #8a99a8;
				border-radius: 8px;

				::placeholder {
					color: #8a99a8;
				}
			}
		}
		.login-actions {
			display: flex;
			flex-direction: column;
			width: 100%;

			.login-button {
				width: 100%;
				align-self: center;
				margin-top: 30px;
				height: 40px;
				background-color: black !important;
				border-radius: 8px;
				color: white;
				border: none;
				font-family: var(--Roboto-font-family);
				font-weight: bold;
				font-size: 0.58rem;
			}
		}
		.forgot-password-link {
			align-self: center;
			color: #5a646e;
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			font-size: 0.58rem;
		}

		.new-user {
			margin-top: 32px;
			color: #8a99a8;
			font-size: 12px;
			font-family: var(--Roboto-font-family);
			span {
				text-decoration: underline;
				color: #2b63fc;
				font-size: 12px;
				font-family: var(--Roboto-font-family);
				cursor: pointer;
				margin-left: 5px;
			}
		}
	}
`;
