import React, { useEffect, useState } from 'react';
import { Container, ModalContainer } from './styles';
import Modal from 'react-modal';
import api from '../../../api';
import { useForm } from 'react-hook-form';

type Props = {
	open: boolean;
	setOpen: (open: boolean) => void;
};

export interface FormInputs {
	name: string;
}

const CreateCategoryModal: React.FC<Props> = ({ open, setOpen }) => {
	const { register, handleSubmit, errors } = useForm<FormInputs>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		shouldFocusError: true,
	});

	const [fileCategory, setFileCategory] = useState<File>();

	const handleFileChange = (file: any) => {
		setFileCategory(file);
	};

	const onSubmit = async (data: FormInputs) => {
		const newData = new FormData();
		newData.append('newCategory', fileCategory as any);
		newData.append('name', data.name);

		api.post('/create-category', newData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then(() => window.location.reload());
	};

	return (
		<Modal
			isOpen={open}
			onRequestClose={() => setOpen(false)}
			style={{
				overlay: {
					position: 'fixed',
					top: 0,
					zIndex: 1001,
					left: 0,
					right: 0,
					bottom: 0,
					background: '#0007',
				},
				content: {
					top: '50%',
					left: '50%',
					right: 'auto',
					padding: '0 auto',
					bottom: 'auto',
					marginRight: '-50%',
					maxHeight: '100%',
					border: 'none',
					borderRadius: '11px',
					transform: 'translate(-50%, -50%)',
				},
			}}
		>
			<ModalContainer>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="title-block">
						<h1>Nova categoria</h1>
					</div>
					<div className="cards">
						<div className="row">
							<div className="name">
								<span>Nome</span>
								<input
									name="name"
									type="text"
									ref={register({
										required: true,
									})}
								/>
							</div>
						</div>
						<div className="ilustration">
							<span>Ilustração</span>
							{/* <div className="more">
							<p>+</p>
						</div> */}
							<div className="more">
								<label htmlFor="new-category">+</label>
								<input
									type="file"
									id="new-category"
									name="newCategory"
									onChange={(e: any) => {
										handleFileChange(e.target.files[0]);
									}}
								></input>
							</div>
						</div>
						<div className="button-text">
							<button className="button-text" type="submit">
								Criar categoria
							</button>
						</div>
					</div>
				</form>
			</ModalContainer>
		</Modal>
	);
};

export default CreateCategoryModal;
