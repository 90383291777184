import styled from 'styled-components';

export const PageContainer = styled.div`
	width: 75%;
	/* height: 500px; */
	border-radius: 12px;
	background: white;
	margin-top: 10px;


	@media (max-width: 768px) {
		border-radius: 0;
		width: 100%;
	}
`;
export const Container = styled.div`
	width: 93%;
	padding: 35px;
	display: flex;

	@media (max-width: 768px) {
		width: 90%;
		padding: 35px 5% 35px 5%;
	}
`;
