import React from 'react';
import HomePage from '../Pages/HomePage/HomePage';
import ProductPage from '../Pages/ProductPage/ProductPage';

import UserRegisterPage from '../Pages/UserRegister/UserRegisterPage';
import { AppState } from '../store';
import SearchPage from '../Pages/SearchPage/SearchPage';
import AboutUsPage from '../Pages/AboutUsPage/AboutUsPage';
import JoinUsPage from '../Pages/JoinUsPage/JoinUsPage';
import SellerAreaPage from '../Pages/SellerAreaPage/SellerAreaPage';
import LoginPage from '../Pages/LoginPage/LoginPage';
import LoginAdminPage from '../Pages/LoginAdminPage/LoginAdminPage';
import UserAccount from '../interfaces/UserAccount';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import FaqPage from '../Pages/FaqPage/FaqPage';
import LandingPage from '../Pages/LandingPage/LandingPage';
import PaymentMethodsPage from '../Pages/PaymentMethodsPage/PaymentMethodsPage';

const PublicRoutes: React.FC = () => {
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	return (
		<>
			<Route exact path="/" component={LandingPage} />
			<Route path="/about-us" component={AboutUsPage} />
			<Route path="/payment-methods" component={PaymentMethodsPage}/>
			<Route path="/faq" component={FaqPage} />
			<Route path="/login/:from_page" component={LoginPage}>
				{/* {user && <Redirect to="/"></Redirect>} */}
			</Route>
			<Route path="/register/:from_page" component={UserRegisterPage} />
			<Route exact path="/seller" component={HomePage} />
			<Route path="/product/:id" component={ProductPage} />
			<Route
				path="/search/:search_query/:search_type/:state/:min_price/:max_price"
				component={SearchPage}
			/>
			<Route exact path="/admin" component={LoginAdminPage} />
			<Route path="/join-us" component={JoinUsPage} />
			<Route
				path="/seller-area/:userId/:state/:min_price/:max_price/:category"
				component={SellerAreaPage}
			/>
		</>
	);
};
export default PublicRoutes;
