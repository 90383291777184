import styled from 'styled-components';

export const PageContainer = styled.main`
	display: flex;
	flex-direction: row;
	justify-content: center;
	height: 100%;
	
	@media (max-width: 768px) {
		width: 100%;
	}
`;

export const Container = styled.main`
	width: var(--Default-page-alignment-vale);
	@media (max-width: 768px) {
		margin-bottom: 0;
		width: 100%;
	}
	/* @media (max-width: 1366px) {
		width: 85%;
	} */
	height: 100%;
	margin-bottom: 50px;

	h1 {
		/* TITULO ÁREA DO VENDEDOR */
		margin-top: 50px;
		font-size: 26px;
		font-family: var(--Roboto-font-family);
		font-weight: 400;
		margin-bottom: 15px;
		color: #353a40;
		@media (max-width: 768px) {
			padding-left: 5%;
		}
	}
	h6 {
		font-family: var(--Roboto-font-family);
		font-size: 10px;
		color: #004d93;
		text-align-last: right;
		margin-bottom: 12px;
	}
`;
