import styled from 'styled-components';

export const Container = styled.div`
	width: 120%;
	height: 167px;
	position: relative;
	background: white;
	margin-left: -10%;
	margin-bottom: 69px;
	border-radius: 0 0 50% 50%;
	box-sizing: border-box;
	.center-text {
		text-align: center;
		h1 {
			margin-top: 10px;
			color: #2b63fc;
			font-size: 24px;
			font-family: 'Roboto';
		}
		p {
			color: #5a646e;
			font-size: 16px;
			font-family: 'Roboto';
		}
	}
`;
export const DivLogo = styled.div`
	margin-top: 10px;
`;
