import { Container, PageContainer } from "./styles"

const Faq: React.FC = () => {
    return(
        <PageContainer>
            <h2>Dúvidas da Plataforma</h2>
            <Container>
                <div className="questions">
                    <div className="question">
                        <h2>Ao realizar o meu cadastro, eu já posso anunciar?</h2>
                        <p>
                            Sim, após você realizar o cadastro em nosso portal, você já está livre para anunciar.
                        </p>
                    </div>
                    <div className="question">
                        <h2>Como eu faço para anunciar?</h2>
                        <p>
                            Para você anunciar no Bom Place, é simples. Basta você clicar em "Anunciar", e 
                            preencher o que você deseja anunciar, seja produtos, bens ou serviços. 
                            Ao preencher a tela de anúncio por completo, inserindo todas as informações solicitadas, 
                            clique no botão de anunciar no rodapé do formulário. 
                            Você deve apenas completar inteiramente o seu cadastro de vendedor e ser homologado pela Juno.
                        </p>
                    </div>
                    <div className="question">
                        <h2>Poderei retirar, pessoalmente, o produto?</h2>
                        <p>
                            Se o vendedor cadastrou essa opção, sim, esse é um direito seu.
                        </p>
                    </div>
                    <div className="question">
                        <h2>O Bom Place faz vendas próprias?</h2>
                        <p>
                            Não. Como market place, fazemos apenas a aproximação entre cliente/vendedor.
                            O vendedor é que gerencia seu estoque, anuncia, faz a entrega/devolução etc.
                        </p>
                    </div>
                    <div className="question">
                        <h2>Posso reclamar ao Procon, acerca de uma aquisição?</h2>
                        <p>
                            Sim, com certeza. Além de nosso canal direito para resolução de conflitos, podem ser procurados os Procons municipais ou estaduais, conforme faculta a lei.
                        </p>
                    </div>
                    <div className="question">
                        <h2>Posso devolver uma compra?</h2>
                        <p>
                            Sim. Basta entrar em contato com o vendedor. Caso haja algum conflito, pode ser acessado o nosso canal direito específico para isso. O produto deverá ser devolvido ao vendedor, que fará o estorno do valor pago. Tudo isso, seguindo as regras do site. 
                        </p>
                    </div>
                    <div className="question">
                        <h2>Posso fazer várias compras, do mesmo vendedor, economizando no valor do frete?</h2>
                        <p>
                            Sim. Basta ir adicionando os produtos escolhidos ao seu carrinho de compras. Você poderá comprar todos os produtos escolhidos de um determinado vendedor, alterar os produtos e quantidades no carrinho ou, ainda, comprar de vários vendedores ao mesmo tempo (neste último caso, compras de vendedores diferentes terão tarifas diversas de frete).
                        </p>
                    </div>
                    <div className="question">
                        <h2>É mais barato anunciar e vender no Bom Place?</h2>
                        <p>
                            Nós buscamos sempre as melhores opções de custos e preços para nossos serviços, pois acreditamos que os vendedores são nossos parceiros e juntos podemos crescer mais.
                        </p>
                    </div>
                    <div className="question">
                        <h2>Quais são os produtos e serviços que não podem ser anunciados no Bom Place?</h2>
                        <p>
                            Os mesmos produtos que a legislação brasileira não permite.
                        </p>
                    </div>
                    <div className="question">
                        <h2>Poderei pagar através de boleto bancário?</h2>
                        <p>
                            Sim, haverá essa possibilidade de pagamento
                        </p>
                    </div>
                    <div className="question">
                        <h2>Quem escolhe a forma de envio?</h2>
                        <p>
                            No momento estamos com a possibilidade limitada aos serviços do correio, mas, em breve iremos aumentar as opções.
                        </p>
                    </div>
                </div>
            </Container>
        </PageContainer>

    )
}

export default Faq;