import styled from 'styled-components';

export const Container = styled.div`
	min-height: 100vh;
	width: 100%;
	margin-top: 51px;
	text-align: center;
	font-family: 'Roboto';
	background: #f5f5f5;
	.category-text {
		color: #353a40;
		font-size: 26px;
		margin-top: 40px;
		margin-bottom: 31px;
	}
	.container-form {
		width: 100%;
		display: flex;
		flex-direction: ROW;
		justify-content: center;
		column-gap: 36px;
		label {
			color: #333333;
			font-size: 16px;
			font-weight: 500;
			margin-bottom: 13px;
		}
		.form {
			width: 15%;
		}
		form {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			column-gap: 36px;
			width: 93%;
			input {
				margin-bottom: 24px;
			}
			.form-input-left {
				width: 17%;
				height: 30px;
				margin-left: 16px;
				border: solid 1px #e1e1e1;
				border-radius: 8px;
				padding: 0px 0px 0px 17px;
				color: #a8a8a8;
				font-family: var(--Roboto-font-family);
				background: white;
				@media (max-width: 768px) {
					margin-bottom: 23px;
				}
			}
			.form-input-city {
				width: 17%;
				height: 30px;
				margin-left: 16px;
				border: solid 1px #e1e1e1;
				border-radius: 8px;
				padding: 0px 0px 0px 17px;
				color: #a8a8a8;
				font-family: var(--Roboto-font-family);
				background: white;
				margin-top: 14px;
				@media (max-width: 768px) {
					margin-bottom: 23px;
				}
			}
			.form-input {
				width: 95%;
				height: 30px;
				border: solid 1px #e1e1e1;
				border-radius: 8px;
				padding: 0px 0px 0px 17px;
				color: #a8a8a8;
				font-family: var(--Roboto-font-family);
				background: white;
				@media (max-width: 768px) {
					margin-bottom: 23px;
				}
			}
			.form-input-email {
				input {
					width: 95%;
					height: 30px;
					border: solid 1px #e1e1e1;
					border-radius: 8px;
					padding: 0px 0px 0px 17px;
					color: #a8a8a8;
					font-family: var(--Roboto-font-family);
					background: white;
					@media (max-width: 768px) {
						margin-bottom: 23px;
					}
				}
			}
			.margin-bottom {
				margin-bottom: 9px;
				p {
					margin-bottom: 0px;
				}
			}
			.birth_date {
				input {
					width: 95%;
					height: 30px;
					border: solid 1px #e1e1e1;
					border-radius: 8px;
					padding: 0px 0px 0px 17px;
					color: #a8a8a8;
					font-family: var(--Roboto-font-family);
					background: white;
					@media (max-width: 768px) {
						margin-bottom: 23px;
					}
				}
			}
			button {
				display: flex;
				justify-content: center;
				width: 218px;
				height: 40px;
				background: #2b63fc;
				color: white;
				border: none;
				border-radius: 8px;
				align-items: center;
			}
		}
		.row-input {
			display: flex;
			flex-direction: row;
			margin-top: 9px;
			margin-bottom: -5px;
			.right {
				width: 34%;
				margin-left: 20px;
			}
			.left {
				position: relative;
				width: 269px;
			}
		}
		.row-input-city {
			display: flex;
			flex-direction: row;
			margin-top: -4px;
			margin-bottom: -5px;
			.right {
				width: 34%;
				margin-left: 20px;
			}
			.left {
				position: relative;
				width: 269px;
			}
		}
	}
	.container-cards {
		background: white;
		height: 480px;
		width: 85%;
		margin: auto;
		text-align: start;
		padding-left: 37px;
		padding-top: 40px;
		p {
			font-size: 16px;
			margin-bottom: 41px;
		}
	}
`;
export const PasswordError = styled.div`
	font-family: var(--Roboto-font-family);
	font-size: 12px;
	margin-top: -20px;
	color: red;
`;
