import React from 'react';
import { useForm } from 'react-hook-form';
import api from '../../api';
import FormInput from './FormInput/FormInput';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as LogoAcp } from '../../assets/icons/acpLogoLogin.svg';
import { RiShoppingCart2Fill } from 'react-icons/ri';
import { RiStore3Fill } from 'react-icons/ri';

import {
	CardContainer,
	CardInputsContainer,
	GroupContainer,
	AlignmentContainer,
	GroupColumn,
	GroupRow,
	ButtonsContainer,
	MaskInput,
	InputAndErrorMessage,
	SelectionContainer,
	SelectType,
	DefaultRow,
	UserTypeRow,
	GroupTitle,
} from './styles';
import { UserTypeEnum, UserTypes } from '../../interfaces/UserAccount';
/* import AlertModal, {
	AlertModalHandles,
} from '../Default/AlertModal/AlertModal'; */
import { useRef } from 'react';
import { useState } from 'react';
import InputWithMask from '../Default/InputWithMask/InputWithMask';
import Axios, { AxiosResponse } from 'axios';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import formatDistance from 'date-fns/formatDistance';
import RegisterSellerModal from './RegisterSellerModal/RegisterSellerModal';
interface RegisterValues {
	state: string;
	city: string;

	street: string;
	CEP: string;
	phone: string;
	name: string;
	number: string;
	complement: string;
	birth_date: string;

	social_reason: string;
	shop_name: string;
	cpf: string;
	cnpj: string;
	email: string;
	password: string;
}
interface PageParams {
	from_page: string;
}

const UserRegister: React.FC = () => {
	const pageParams = useParams<PageParams>();
	const history = useHistory();
	const { register, handleSubmit, errors } = useForm<RegisterValues>({
		mode: 'onSubmit',
		reValidateMode: 'onSubmit',
	});
	/* const modalRef = useRef<AlertModalHandles>(null); */
	const sellerModalRef = useRef<{
		handleOpenModal: () => void;
	}>(null);
	/* const [modalTitle, setModalTitle] = useState<string>('Cadastro');
	const [modalDescription, setModalDescription] = useState<string>(
		'Cadastro realizado com sucesso'
	); */
	const [userType, setUserType] = useState<UserTypeEnum>(
		UserTypeEnum.customer
	);
	/* const [dateBirth, setDateBirth] = useState<string>(''); */
	const [formIsValid, setFormIsValid] = useState({
		document_cpf: true,
		document_cnpj: true,
		zipCode: true,
		dateBirth: true,
		email: true,
	});
	const [addressInformations, setAddressInformations] = useState({
		street: '',
		state: '',
		city: '',
	});
	function verifyBackendError(message: string) {
		switch (message) {
			case 'user_accounts_cpf_unique':
				return 'Falha ao cadastrar, CPF já cadastrado';
			case 'user_accounts_email_unique':
				return 'Falha ao cadastrar, email já cadastrado';
			case 'user_accounts_cnpj_unique':
				return 'Falha ao cadastrar, CPNJ já cadastrado';

			default:
				return 'Falha ao realizar o cadastro';
		}
	}
	const onSubmit = async (data: RegisterValues) => {
		let userInfo: {
			name?: string;
			social_reason?: string;
			shop_name?: string;
			date_birth?: any;
			CPF?: any;
			CNPJ?: any;
			email?: string;
			password?: string;
			user_type?: UserTypeEnum;
		} = {};

		if (userType === UserTypeEnum.customer) {
			userInfo.name = data.name;
			userInfo.social_reason = '';
			userInfo.shop_name = '';
			userInfo.date_birth = data.birth_date;
			userInfo.CPF = data.cpf;
			userInfo.CNPJ = '';
			userInfo.user_type = UserTypeEnum.customer;
		} else if (userType === UserTypeEnum.seller) {
			userInfo.name = data.social_reason;
			userInfo.social_reason = data.social_reason;
			userInfo.shop_name = data.shop_name;
			userInfo.date_birth = '';
			userInfo.CPF = '';
			userInfo.CNPJ = data.cnpj;
			userInfo.user_type = UserTypeEnum.seller;
		}
		userInfo.email = data.email;
		userInfo.password = data.password;

		api.post('/user-register', {
			name: userInfo.name,
			social_reason: userInfo.social_reason,
			shop_name: userInfo.shop_name,
			date_birth: userInfo.date_birth,
			CPF: userInfo.CPF,
			CNPJ: userInfo.CNPJ,
			email: userInfo.email,
			password: userInfo.password,
			user_type: userType,

			Address: {
				zip_code: data.CEP,
				street: data.street,
				state: data.state,
				city: data.city,
				number: data.number,
				complement: data.complement,
			},
			Contacts: {
				contact_name: 'Principal',
				phone: data.phone,
			},
		})
			.then((response: any) => {
				if (response.data.status !== 'error') {
					if (userType === UserTypeEnum.seller) {
						sellerModalRef.current?.handleOpenModal();
					} else {
						alert('Cadastro realizado com sucesso');
						switch (pageParams.from_page) {
							case 'cart':
								history.push(`/login/${'cart'}`);
								window.location.reload();
								break;
							default:
								history.push(`/login/${'register'}`);
								break;
						}
					}
				} else {
					alert(verifyBackendError(response.data.message));
				}
			})
			.catch((error: any) => {
				alert('Erro ao realizar cadastro');
			});
	};
	async function getZipCode(value: string) {
		if (value.replace('-', '').length === 8) {
			Axios.get(`https://brasilapi.com.br/api/cep/v1/${value}`)
				.then((res) => {
					setAddressInformations({
						street: res.data.street,
						city: res.data.city,
						state: res.data.state,
					});
					/* setFormIsValid({
						...formIsValid,
						zipCode: true,
					}); */
				})
				.catch((err) => {
					/* setModalDescription('Cep não localizado');
					setFormIsValid({
						...formIsValid,
						zipCode: false,
					});
					modalRef.current?.handleOpenModal(); */
				});
		}
	}
	function validateDocument(value: string) {
		if (userType === UserTypeEnum.customer) {
			setFormIsValid({
				...formIsValid,
				document_cnpj: true,
				document_cpf: cpf.isValid(
					value.replaceAll('.', '').replace('-', '')
				),
			});
		} else if (userType === UserTypeEnum.seller) {
			setFormIsValid({
				...formIsValid,
				document_cpf: true,
				document_cnpj: cnpj.isValid(
					value.replaceAll('.', '').replace('-', '').replace('/', '')
				),
			});
		}
	}
	function showFieldError(is_valid: boolean, is_valid_at_form: any) {
		if (!is_valid || is_valid_at_form) {
			return 'solid 1px red';
		} else {
			return 'solid 1px #8a99a8';
		}
	}
	function validateBirthDate(date: string) {
		let inputDate = new Date(date);
		let today = new Date();

		let difference = today.getFullYear() - inputDate.getFullYear();

		let birthMonth = new Date(inputDate).getMonth();
		let birthDay = new Date(inputDate).getDate();

		let todayMonth = new Date(today).getMonth();
		let todayDate = new Date(today).getDate();

		if (todayMonth <= birthMonth) {
			if (todayDate >= birthDay) {
				difference = difference - 1;
			}
		}

		if (difference >= 18) {
			setFormIsValid({
				...formIsValid,
				dateBirth: true,
			});

			return true;
		} else {
			setFormIsValid({
				...formIsValid,
				dateBirth: false,
			});

			return false;
		}
	}

	return (
		<>
			{/* <AlertModal
				ref={modalRef}
				modalTitle={modalTitle}
				modalDescription={modalDescription}
			/> */}
			<CardContainer>
				<RegisterSellerModal ref={sellerModalRef} />
				<SelectionContainer>
					<LogoAcp
						style={{
							fill: '#20256C',
						}}
					/>
					<UserTypeRow>
						<SelectType
							onClick={() => setUserType(UserTypeEnum.customer)}
							className={
								userType === UserTypeEnum.customer
									? ''
									: 'innactive'
							}
						>
							<RiShoppingCart2Fill fill="#1C27CC" />
							Quero Comprar
						</SelectType>
						<SelectType
							onClick={() => setUserType(UserTypeEnum.seller)}
							className={
								userType === UserTypeEnum.seller
									? ''
									: 'innactive'
							}
						>
							<RiStore3Fill fill="#1C27CC" />
							Quero Vender
						</SelectType>
					</UserTypeRow>
				</SelectionContainer>
				<AlignmentContainer>
					<form
						onSubmit={handleSubmit(onSubmit)}
						className="page-form"
					>
						<CardInputsContainer>
							<GroupContainer>
								<GroupColumn className="has-margin">
									<GroupTitle>
										Dados
										{userType === UserTypeEnum.customer
											? ' pessoais'
											: ' empresariais'}
									</GroupTitle>
									<div className="final-input-group">
										{userType === UserTypeEnum.customer ? (
											<FormInput
												label="Nome"
												flex="1"
												name="name"
												style={{
													border: `${showFieldError(
														true,
														errors.name
													)}`,
												}}
												ref={register({
													required: true,
												})}
											/>
										) : (
											<FormInput
												label="Razão Social"
												flex="1"
												name="social_reason"
												style={{
													border: `${showFieldError(
														true,
														errors.social_reason
													)}`,
												}}
												ref={register({
													required: true,
												})}
											/>
										)}
										<MaskInput>
											{userType ===
											UserTypeEnum.customer ? (
												<>
													<InputWithMask
														mask="document_cpf"
														ref={register({
															required: true,
															minLength: {
																value: 14,
																message:
																	'Formato inválido ex: xxx.xxx.xxx-xx',
															},
														})}
														name="cpf"
														className="form-input"
														style={{
															border: `${showFieldError(
																formIsValid.document_cpf,
																errors.cpf
															)}`,
														}}
														onChange={(e) =>
															validateDocument(
																e.target.value
															)
														}
														maxLength={14}
														placeholder={'CPF'}
													/>
												</>
											) : (
												<InputWithMask
													mask="document_cnpj"
													ref={register({
														required: true,
														minLength: {
															value: 18,
															message:
																'Formato inválido ex: xx.xxx.xxx/xxxx-xx',
														},
													})}
													name="cnpj"
													className="form-input"
													style={{
														border: `${showFieldError(
															formIsValid.document_cnpj,
															errors.cnpj
														)}`,
													}}
													onChange={(e) =>
														validateDocument(
															e.target.value
														)
													}
													maxLength={18}
													placeholder={'CNPJ'}
												/>
											)}
											{userType ===
											UserTypeEnum.customer ? (
												<>
													<FormInput
														label="Data de nascimento"
														flex="1"
														name="birth_date"
														onChange={(e) =>
															validateBirthDate(
																e.target.value
															)
														}
														ref={register({
															required: true,
														})}
														type="date"
														style={{
															border: `${showFieldError(
																formIsValid.dateBirth,
																errors.birth_date
															)}`,
														}}
													/>
													{!formIsValid.dateBirth &&
														!errors.birth_date && (
															<InputAndErrorMessage>
																<span className="field-validation-error">
																	É necessário
																	ter mais de
																	18 anos para
																	criar uma
																	conta
																</span>
															</InputAndErrorMessage>
														)}
												</>
											) : (
												<FormInput
													label="Nome Fantasia"
													flex="1"
													name="shop_name"
													style={{
														border: `${showFieldError(
															true,
															errors.shop_name
														)}`,
													}}
													ref={register({
														required: true,
													})}
												/>
											)}
										</MaskInput>

										<MaskInput>
											<InputWithMask
												mask="phone_number"
												ref={register({
													required: true,
													minLength: {
														value: 14,
														message:
															'Formato inválido',
													},
												})}
												style={{
													border: `${showFieldError(
														true,
														errors.phone
													)}`,
												}}
												name="phone"
												className="form-input"
												placeholder="Telefone"
											/>

											{/* {errors.phone && (
												<InputAndErrorMessage>
													<span className="field-validation-error">
														{errors.phone.message}
													</span>
												</InputAndErrorMessage>
											)} */}
										</MaskInput>
									</div>
								</GroupColumn>
								<GroupColumn className="has-margin">
									<GroupTitle>Endereço</GroupTitle>
									<MaskInput>
										<InputWithMask
											mask="zip_code"
											ref={register({
												required: true,
											})}
											style={{
												border: `${showFieldError(
													formIsValid.zipCode,
													errors.CEP
												)}`,
											}}
											onChange={(e) =>
												getZipCode(e.target.value)
											}
											name="CEP"
											className="form-input"
											placeholder="Cep"
										/>
										{!formIsValid.zipCode && (
											<InputAndErrorMessage>
												<span className="field-validation-error">
													Cep inválido
												</span>
											</InputAndErrorMessage>
										)}
									</MaskInput>

									<GroupRow>
										<FormInput
											label="Endereço"
											flex="4"
											value={addressInformations.street}
											style={{
												border: `${showFieldError(
													true,
													errors.street
												)}`,
											}}
											onChange={(e) =>
												setAddressInformations({
													...addressInformations,
													street: e.target.value,
												})
											}
											name="street"
											ref={register({ required: true })}
										/>
										<FormInput
											label="Nº"
											flex="1"
											name="number"
											style={{
												border: `${showFieldError(
													true,
													errors.number
												)}`,
												width: '30px',
												marginLeft: '10px',
											}}
											ref={register({ required: true })}
										/>
									</GroupRow>
									<FormInput
										label="Complemento"
										flex="1"
										name="complement"
										style={{
											border: `${showFieldError(
												true,
												errors.complement
											)}`,
										}}
										ref={register({ required: false })}
									/>
									<GroupRow>
										<FormInput
											label="Cidade"
											flex="4"
											name="city"
											style={{
												border: `${showFieldError(
													true,
													errors.city
												)}`,
											}}
											onChange={(e) =>
												setAddressInformations({
													...addressInformations,
													city: e.target.value,
												})
											}
											value={addressInformations.city}
											ref={register({ required: true })}
										/>
										<FormInput
											label="UF"
											flex="1"
											name="state"
											style={{
												border: `${showFieldError(
													true,
													errors.state
												)}`,
												width: '30px',
												marginLeft: '10px',
											}}
											onChange={(e) =>
												setAddressInformations({
													...addressInformations,
													state: e.target.value,
												})
											}
											value={addressInformations.state}
											ref={register({ required: true })}
										/>
									</GroupRow>
								</GroupColumn>
								<GroupColumn>
									<GroupTitle>Dados para login</GroupTitle>
									<MaskInput>
										<FormInput
											label="E-mail"
											flex="0"
											name="email"
											style={{
												border: `${showFieldError(
													true,
													errors.email
												)}`,
											}}
											ref={register({
												required: true,
												pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i 
											})}
										/>
										<FormInput
											label="Senha"
											flex="0"
											type="password"
											name="password"
											style={{
												border: `${showFieldError(
													true,
													errors.password
												)}`,
											}}
											ref={register({ required: true })}
										/>
										<FormInput
											label="Confirmar senha"
											flex="0"
											type="password"
											name="confirmPassword"
											ref={register({ required: true})}
										/>
										{!formIsValid.document_cpf &&
											!errors.cpf && (
												<InputAndErrorMessage>
													<span className="field-validation-error">
														CPF inválido
													</span>
												</InputAndErrorMessage>
											)}
										{errors.cpf && (
											<InputAndErrorMessage>
												<span className="field-validation-error">
													{errors.cpf.message}
												</span>
											</InputAndErrorMessage>
										)}
										{!formIsValid.document_cnpj &&
											!errors.cnpj && (
												<InputAndErrorMessage>
													<span className="field-validation-error">
														CNPJ inválido
													</span>
												</InputAndErrorMessage>
											)}
										{errors.cnpj && (
											<InputAndErrorMessage>
												<span className="field-validation-error">
													{errors.cnpj.message}
												</span>
											</InputAndErrorMessage>
										)}
										{!formIsValid.email &&
											!errors.email && (
												<InputAndErrorMessage>
													<span className="field-validation-error">
														Email inválido
													</span>
												</InputAndErrorMessage>
											)}
										{errors.email && (
											<InputAndErrorMessage>
												<span className="field-validation-error">
													{errors.email.message}
												</span>
											</InputAndErrorMessage>
										)}
									</MaskInput>
								</GroupColumn>
							</GroupContainer>
							<ButtonsContainer>
								{/* 
							<button className="negative-button">
								Cancelar
							</button> */}
								<button
									type="submit"
									className="positive-button"
								>
									Finalizar cadastro
								</button>
							</ButtonsContainer>
						</CardInputsContainer>
					</form>
				</AlignmentContainer>
			</CardContainer>
		</>
	);
};

export default UserRegister;
