import React, { useEffect, useState } from 'react';
import Header from '../../components/Default/header';
import { Container } from './styles';

import UserAddressMobile from '../../components/UserAddressMobile/UserAddressMobile';
import { useParams } from 'react-router';
import api from '../../api';
import { Address } from '../../interfaces/UserAccount';
import { useDispatch } from 'react-redux';
import { insertUser } from '../../actions/UserActions';
import AddPaymentMobile from '../../components/AddPaymentMobile/AddPaymentMobile';


const AddPaymentMobilePage: React.FC = () => {
        
    return (
        <>
		<Container>
			<Header />
            <AddPaymentMobile />
		</Container>
        </>
	);
};

export default AddPaymentMobilePage;
