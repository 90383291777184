import styled from 'styled-components';

export const Container = styled.main`
	/* produtos cadastrados */
	width: 75%;
	border-radius: 12px;
	margin-top: 10px;
	background: white;
	@media (max-width: 768px) {
		width: 90%;
		margin: 0 5%;
	}
`;
export const FiltersContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	/* padding: 0px 20px; */
	/* padding-top: 25px; */
	margin-bottom: 15px;
	@media (max-width: 768px) {
		width: 100%;
	}

	.select-container {
		/* background-color: purple; */
		margin: 50px;
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		flex: 1;
		margin-top: 10px;
		@media (max-width: 768px) {
			margin: 0px;
			
		}

		.select {
			display: flex;
			flex-direction: column;
			border-radius: 4px;
			width: 24%;
			max-width: 117px;

			label {
				font-size: min(12px, 1.8vw);
				font-family: var(--Roboto-font-family);
				font-weight: 400;

				margin-bottom: 6px;
				border-radius: 4px;
				color: #888888;
			}
			select {
				border: solid 1px #cbcbcb;
				height: 28px;
				border-radius: 4px;

				&:focus {
					outline: none;
				}
			}
		}
		.select + .select {
			margin-left: 15px;
		}

		.toggle {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			@media (max-width: 768px) {
				margin: 11px 21px;
			}

			label {
				font-size: min(12px, 1.8vw);
				font-family: var(--Roboto-font-family);
				font-weight: 400;

				margin-bottom: 6px;
				border-radius: 4px;
				color: #888888;
				@media (max-width: 768px) {
					font-size: 12px;
				}
			}
			.options {
				display: flex;
				align-items: center;
				border: solid 1px #cbcbcb;
				border-radius: 4px;
				justify-content: center;
				align-items: center;
				height: 28px;
				@media (max-width: 768px) {
					width: 65%;
				}

				span {
					font-size: min(12px, 1.6vw);
					font-family: var(--Roboto-font-family);
					font-weight: 400;
					color: #888888;
					@media (max-width: 768px) {
						font-size: 10px;
					}
				}
				.option-all {
					border-top-left-radius: 6px;
					border-bottom-left-radius: 6px;
					&.active {
						@media (max-width: 768px) {
							border-radius: 4px 0px 0px 4px;
						}
					}
				}
				.option-for-sale {
					border-top-right-radius: 6px;
					border-bottom-right-radius: 6px;
					&.active {
						@media (max-width: 768px) {
							border-radius: 0 4px 4px 0px;
						}
					}
				}
				.option-deactivated {
					border-left: solid 1px #cbcbcb;
					border-right: solid 1px #cbcbcb;
					&.active {
						@media (max-width: 768px) {
							border-radius: 0;
						}
					}
				}
				.option-all,
				.option-deactivated,
				.option-for-sale {
					width: 100px;
					display: flex;

					height: 100%;
					flex-direction: row;
					align-items: center;
					justify-content: center;

					&:hover {
						cursor: pointer;
					}
				}
				.active {
					background: #6f6f6f;
					border-radius: 0;
					@media (max-width: 768px) {
						border-radius: 4px 0px 0px 4px;
					}
					span {
						color: white;
					}
				}
			}
		}
		.search-container {
			display: flex;
			align-items: center;
			flex: 1;
			place-content: flex-end;
			margin-top: 10px;

			.search-card {
				display: flex;
				flex-direction: row;
				align-items: center;

				border: solid 1px #cbcbcb;
				padding: 5px;
				border-radius: 4px;
				width: 90%;
				max-width: 428px;
				@media (max-width: 768px) {
					display: none;
				}

				input {
					border: none;
					width: 100%;
					border-radius: 4px;
					
				}

				.search-icon {
					width: 16px;
					height: 16px;
					fill: #7f7f7f;
					stroke: #7f7f7f;
				}
			}
		}
	}
	.grid-icon-container {
		width: 23px;
		height: 18px;
		background: white;
		margin-left: 8px;
	}
	.grid-icon {
		width: 23px;
		height: 18px;

		display: grid;
		grid-template-rows: 1fr 1fr;
		grid-template-columns: 1fr 1fr 1fr;
		background: #3b3d4a;

		row-gap: 1px;
		column-gap: 1px;
	}
`;
