import React from 'react';
import { useHistory } from 'react-router-dom';
import JoinUsItem from './JoinUsItem/JoinUsItem';
import {
	AreaFooter,
	BenefitsInBuying,
	Container,
	FooterOptions,
	PageContainer,
} from './styles';
import foto2 from '../../assets/foto-2.png';
import foto3 from '../../assets/foto-3.png';
import foto7 from '../../assets/foto-7.png';
import ciee from '../../assets/joinUsIcons/CIEE.png';
import dentalUni from '../../assets/joinUsIcons/DentalUni.webp';
import pgrs from '../../assets/joinUsIcons/PGRS.png';
import logistica from '../../assets/joinUsIcons/logistica.png';
import reciclagem from '../../assets/joinUsIcons/ReciclagemEad.png';
import segurosUnimed from '../../assets/joinUsIcons/SegurosUnimed.webp';
import unimedBrasil from '../../assets/joinUsIcons/unimed-brasil.png';
import footerImage from '../../assets/joinUsIcons/joinUsIcon.svg';

const JoinUs: React.FC = () => {
	const history = useHistory();

	return (
		<PageContainer>
			<h1>Associe-se</h1>
			<Container>
				<h2>A associação</h2>
				<div className="benefits">
					<div className="first-line">
						<JoinUsItem
							logo={unimedBrasil}
							description="Entre em contato e saiba mais informações: sac@acp.org.br | (41) 3320-2929"
							title="Unimed Saúde"
							flex={1}
							pWidth={171}
							maxWidth={235}
						/>
						<JoinUsItem
							logo={segurosUnimed}
							description="Entre em contato e saiba mais informações: sac@acp.org.br | (41) 3320-2929"
							title="Seguros Unimed"
							pWidth={171}
							maxWidth={235}
							flex={1}
						/>
						<JoinUsItem
							logo={dentalUni}
							description="O plano odontológico Dental Uni ACP conta com planos a partir de R$13,90 por funcionários."
							title="DentalUni"
							pWidth={171}
							maxWidth={235}
							flex={1}
						/>
						<JoinUsItem
							logo={pgrs}
							description="Entre em contato e saiba mais informações: sac@acp.org.br | (41) 3320-2929"
							title="PGRS"
							pWidth={171}
							maxWidth={235}
							flex={1}
						/>
					</div>
					<div className="second-line">
						<JoinUsItem
							logo={ciee}
							description="Associados da ACP ao formalizarem convênio com o CIEE/PR poderão receber descontos de 5% no cálculo da contribuição mensal ao realizarem contratações"
							title="CIEE/PR"
							pWidth={260}
							maxWidth={317}
							flex={2}
						/>
						<JoinUsItem
							logo={logistica}
							description="O Conselho de Ação para Sustentabilidade Empresarial (CASEM) da Associação Comercial do Paraná (ACP), a SEMA e a AMBINEE assinaram um Termo de Compromisso para implantação do Sistema de Logística Reversa"
							title="Logística Reversa"
							pWidth={260}
							maxWidth={317}
							flex={2}
						/>
						<JoinUsItem
							logo={reciclagem}
							description="Associados da ACP com a CNH suspensa podem realizar o curso de reciclagem por EAD com 20% de desconto"
							title="Reciclagem EAD"
							pWidth={260}
							maxWidth={317}
							flex={2}
						/>
					</div>
				</div>
				<BenefitsInBuying>
					<h2 className="main-title">
						Benefícios em comprar e vender no nosso E-commerce
					</h2>
					<div className="images-and-text">
						<div className="images-container">
							<div className="image">
								<img src={foto2} alt="" />
								{/* <div className="text"> */}
								<span className="textImage">
								</span>
								{/* </div> */}
							</div>
							<div className="image">
								<img src={foto3} alt="" />
								{/* <div className="text"> */}
								{/* </div> */}
							</div>
							<div className="image">
								<img src={foto7} alt="" />
								{/* <div className="text"> */}
								<span className="textImage">
								</span>
								{/* </div> */}
							</div>
						</div>
						<h2>E muito mais!</h2>
					</div>
				</BenefitsInBuying>
			</Container>
			<AreaFooter>
				{' '}
				<FooterOptions>
					<div className="icon-and-text">
						<div className="text">
							<h3>A Adesão é online!</h3>
							<span>Muito mais fácil e prático</span>
						</div>
					</div>
					<button onClick={() => window.open('https://acpr.com.br')}>
						Quero me associar
					</button>{' '}
				</FooterOptions>
			</AreaFooter>
		</PageContainer>
	);
};

export default JoinUs;
