import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import UserAccount from '../interfaces/UserAccount';
import { AppState } from '../store';
import AuthRoutes from './auth.routes';

import Footer from '../components/Footer/Footer';

import PublicRoutes from './public.routes';

const Routes: React.FC = () => {
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);

	return (
		<BrowserRouter>
			<Switch>
				<PublicRoutes />
			</Switch>
			{/** Todo: Removed esse check depois que removermos a landing page */}
			{!user && window.location.pathname !== '/' ? (
				<>
					<Footer />
				</>
			) : (
				<Switch>
					<AuthRoutes />
				</Switch>
			)}
		</BrowserRouter>
	);
};
export default Routes;
