import React from 'react';
import { RiStarFill, RiStarLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';

import { Container, Header, StarsContainer } from './styles';

type Props = {
	notification: any;
};

const NotificationItem: React.FC<Props> = ({ notification }) => {
	const history = useHistory();
	
	return (
		<Container onClick={() => history.push("/notification")}>
			<Header>
				<div className='titles'>
					<h1>{notification.title}</h1>
					{
						notification.related_product_reviews &&
							<h2>{notification.related_product_reviews.product.title}</h2>
					}
					{
						notification.related_product &&
							<h2>{notification.related_product.product.title}</h2>
					}
				</div>
				{
					notification.related_product_reviews &&
						<StarsContainer>
									{[...Array(5)].map((star, index) => (
										index + 1 <= notification.related_product_reviews.rating ? 
											<RiStarFill/> 
											:
											<RiStarLine/>
									))}
						</StarsContainer>
				}
			</Header>
			<p>{notification.message}</p>
		</Container>
	);
};

export default NotificationItem;
