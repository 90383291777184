import React from 'react';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { RiSearch2Line } from 'react-icons/ri';
import { ModalContainer } from './styles';
import { UserShopProducts } from '../../../../../interfaces/UserAccount';
import api from '../../../../../api';
import { FeatureStores } from '../Associated';

type Props = {
	open: boolean;
	setOpen: (open: boolean) => void;
	currentStore: FeatureStores | undefined;
};

const AssociatedReplaceModal: React.FC<Props> = ({
	open,
	setOpen,
	currentStore,
}) => {
	const [usersShopsProducts, setUsersShopsProducts] = useState<
		UserShopProducts[]
	>([]);
	const [searchShops, setSearchShops] = useState<UserShopProducts[]>([]);

	useEffect(() => {
		api.get('/get-all-users-shops-products').then((response: any) => {
			setUsersShopsProducts(response.data.usersShopProducts);
			setSearchShops(response.data.usersShopProducts);
		});
	}, []);

	const updateFeaturedStore = (store: UserShopProducts): void => {
		api.put('/update-featured-store', {
			id: currentStore?.id,
			user_accounts_id: store.user_account_id,
		}).then(() => {
			window.location.reload();
		})
	};

	const handleSearchShop = (shopSearch: string) => {
		setSearchShops(
			usersShopsProducts!.filter((shop) => {
				return shop.shop_customizations.shop_name
					.toLocaleLowerCase()
					.includes(shopSearch.toLowerCase());
			})
		);
	};

	return (
		<Modal
			isOpen={open}
			onRequestClose={() => setOpen(false)}
			style={{
				overlay: {
					position: 'fixed',
					top: 0,
					zIndex: 1001,
					left: 0,
					right: 0,
					bottom: 0,
					background: '#0007',
				},
				content: {
					top: '50%',
					left: '50%',
					right: 'auto',
					padding: '0 auto',
					bottom: 'auto',
					marginRight: '-50%',
					maxHeight: '100%',
					border: 'none',
					borderRadius: '11px',
					transform: 'translate(-50%, -50%)',
				},
			}}
		>
			<ModalContainer>
				<div className="title">
					<h1>Substituir lojas destacadas</h1>
				</div>
				<div className="search">
					<RiSearch2Line />
					<input
						placeholder="Buscar"
						type="text"
						onChange={(e: any) => handleSearchShop(e.target.value)}
					></input>
				</div>
				{searchShops.map((store) => (
					<ul>
						<li>{store.user_account_name}</li>
						<button onClick={() => updateFeaturedStore(store)}>
							Selecionar loja
						</button>
					</ul>
				))}
			</ModalContainer>
		</Modal>
	);
};

export default AssociatedReplaceModal;
