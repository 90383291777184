import React from 'react';

import { 
	Container,
	LeftSide,
	Mid,
	RightSide,
	Status,
	ProductImage,
} from './styles';
import { ImCoinDollar } from 'react-icons/im';
import { Purchase } from '../../../interfaces/ApiResponses/UserPurchases';
import config from '../../../config';
import UserImg from '../../Default/UserImg/UserImg';
import PurchaseDetails, {
	MorePurchaseInformationsModalHandles,
} from '../../PurchaseDetails/PurchaseDetails';
import { useRef } from 'react';

type Props = {
	purchase: Purchase;
};
const PurchaseItem: React.FC<Props> = ({ purchase }) => {

	const modalRef = useRef<MorePurchaseInformationsModalHandles>(null);

	if(purchase.charge_products.length === 0) return null;
	return (
		<Container>
			<ProductImage
				src={`${config.host}/static/products/${purchase.charge_products[0].products.product_photos[0]?.name}`}
				alt=""
				className="mobile"
			/>
			<LeftSide>
				<ProductImage
					src={`${config.host}/static/products/${purchase.charge_products[0].products.product_photos[0]?.name}`}
					alt=""
					className="desktop"
				/>
				<div className="product-infos">
					<h1 className="product-title">
						{' '}
						{purchase.charge_products[0].products.title}
					</h1>
					<span className="product-price">
						{' '}
						{purchase.amount.toLocaleString('pt-BR', {
							style: 'currency',
							currency: 'BRL',
						})}
					</span>
				</div>
			</LeftSide>
			<Mid>
				<h3>Vendedor</h3>

				<div className="seller-informations">
					<UserImg
						src={`${config.host}/static/products/${purchase.charge_products[0].products.user_accounts.user_photo}`}
						className="seller-image"
					/>

					<span>
						{
							purchase.charge_products[0].products.user_accounts
								.social_reason
						}
					</span>
				</div>
			</Mid>
			<RightSide>
			<button onClick={() => modalRef.current?.handleOpenModal()}>
					Mais informações
				</button>
			</RightSide>
			<PurchaseDetails ref={modalRef} purchase={purchase} />
		</Container>
	);
};

export default PurchaseItem;
