import { Product } from './Product';

export interface ProductsList {
	products?: Product[];
	areaTitle: string;
}
export enum AreaTypes {
	HOMEPAGE = 'homePage',
	SEARCHPAGE = 'SearchPage',
	MYPRODUCTSPAGE = 'myProductsPage',
	INACTIVEPRODUCTSPAGE = 'inactiveProductsPage',
	FAVORITEPRODUCTSPAGE = 'favoriteProductsPage',
}
export interface ProductsListState {
	currentProductsList: ProductsList[];
}

export const INSERT_PRODUCTS = 'INSERT_PRODUCTS';
export const REMOVE_SPECIFIC_PRODUCT = 'REMOVE_SPECIFIC_PRODUCT';
export const CLEAN_PRODUCTS_LIST = 'CLEAN_PRODUCTS_LIST';

export interface InsertProducts {
	type: typeof INSERT_PRODUCTS;
	products: Product[];
	targetArea: string;
}
export interface RemoveSpecificProduct {
	type: typeof REMOVE_SPECIFIC_PRODUCT;
	product_id: string;
	target_area: string;
}
export interface ClearList {
	type: typeof CLEAN_PRODUCTS_LIST;
}

export type ProductListActions =
	| InsertProducts
	| RemoveSpecificProduct
	| ClearList;
