import styled from 'styled-components';

export const FowardButton = styled.div`
	position: absolute;
	right: -60px;
	width: 32px;
	height: 51px;
	background: white;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 6px;
	padding-right: 10px;
	z-index: 4;

	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		cursor: pointer;
	}
	&.active {
		background: #aaa;
	}
	.button-icon {
		transform: rotate(180deg);
		.d {
			fill: #004d93;
		}
	}
`;
export const BackButton = styled.div`
	position: absolute;
	left: -60px;
	width: 32px;
	height: 51px;
	background: white;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 6px;
	padding-left: 10px;

	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		cursor: pointer;
	}
	&.active {
		background: #aaa;
	}

	.button-icon {
		.d {
			fill: #004d93;
		}
	}
`;

export const CloseButton = styled.div`
	position: absolute;
	padding: 0px 6px;
	/* 	background-color: #fff; */
	//border-radius: 50%;
	//border: 1px solid #ccc;
	top: 0;
	right: 0;
	cursor: pointer;
`;
