import { Address } from 'cluster';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { insertMobileHeader } from '../../actions/MobileHeaderActions';
import { insertProduct } from '../../actions/ProductActions';
import api from '../../api';
import Header from '../../components/Default/header';
import Product from '../../components/Product/Product';
import { IProductAnswers } from '../../components/Product/ProductAreas/ProductAreas';
import BlockedAnnouncement from '../../interfaces/BlockAnnouncement';
import BlockedSeller from '../../interfaces/BlockSeller';
import { SearchPreset } from '../../interfaces/MobileHeader';
import {
	Product as ProductRedux,
	ProductPhotos,
} from '../../interfaces/Product';
import { ProductVariations } from '../../interfaces/ProductVariations';
import { AppState } from '../../store';

import { Container } from './styles';

interface PageParams {
	id: string;
}
export interface IProduct {
	id: string;
	title: string;
	active: boolean;
	on_demand: boolean;
	stock: string;
	max_installments: string;
	height: number;
	width: number;
	use_state: string;
	price: number;
	collaborator_price?: number;
	allow_withdraw?: boolean;
	thickness: number;
	format: number;
	weight: number;
	description: string;
	model: string;
	material: string;
	brand: string;
	created_at: Date;
	updated_at: Date;
	category_id: number;
	showcase: boolean;
	user_account_id: string;
	product_photos?: ProductPhotos[];
	product_variations?: ProductVariations[];
	blocked_announcement?: BlockedAnnouncement;
	address?: any;
	promotion?: number;
	original_price?: number;
	user_accounts: {
		id: string;
		name: string;
		CPF: string;
		social_reason: string;
		date_birth: string;
		email: string;
		blocked_seller?: BlockedSeller;
		created_at: Date;
		updated_at: Date;
	};
	product_category: {
		id: string;
		name: string;
	};
	product_reviews?: [{
		id: string;
		rating: number;
		review: string;
		user_accounts: {
			name: string;
			social_reason: string;
		}
	}];
	product_questions?: [
		{
			id: string;
			question_description: string;
			product_id: string;
			user_account_id: string;
			created_at: Date;
			updated_at: Date;
			answers_product_questions?: IProductAnswers[];
		}
	];
	inactive_products?: {
		id: string;
		product_id: string;
		created_at: Date;
		updated_at: Date;
	};
}
const ProductPage: React.FC = () => {
	const PageParams: PageParams = useParams();

	const product: ProductRedux = useSelector(
		(state: AppState) => state.product.currentProduct
	);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(insertMobileHeader(SearchPreset));
	});

	const [testProduct, setTestProduct] = useState<IProduct | undefined>(undefined)

	useEffect(() => {
		api.get(`/product`, {
			params: { id: PageParams.id },
		}).then((response: any) => {
			dispatch(insertProduct(response.data.data.response[0]))
			setTestProduct(response.data.data.response[0] as any)
		});
	}, [PageParams]);

	return (
		<Container>
			<Header />
			{testProduct && <Product product={testProduct} />}
		</Container>
	);
};

export default ProductPage;
