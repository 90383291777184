import { Product } from '../interfaces/Product';
import { AreaTypes } from '../interfaces/ProductsList';

export const productExibitionRules = (
	products: Product[],
	targetArea: AreaTypes
) => {
	let areasRules = [
		{
			areaName: 'homePage',
			inactiveProducts: false,
		},
		{
			areaName: 'SearchPage',
			inactiveProducts: true,
		},
		{
			areaName: 'myProductsPage',
			inactiveProducts: false,
		},
		{
			areaName: 'inactiveProductsPage',
			inactiveProducts: false,
		},
		{
			areaName: 'favoriteProductsPage',
			inactiveProducts: false,
		},
	];
	let filterProducts: Product[] = [];

	const findRule = areasRules.find((rule) => rule.areaName === targetArea);

	if (!findRule) return products;

	if (findRule.inactiveProducts) {
		filterProducts = products.filter(
			(product) => !product.inactive_products
		);
		return filterProducts;
	} else {
		return products;
	}
};
