import React, { useRef, useState } from 'react';
import UserAccount, { Address } from '../../../../interfaces/UserAccount';
import moreIcon from '../../../../assets/icons/moreIcon.svg';
import api from '../../../../api';
import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { removeAddress } from '../../../../actions/UserActions';
import { AppState } from '../../../../store';
import Input from '../../../Default/Input/Input';
import NewAddressModal, {
	NewAddressModalHandles,
} from '../NewAddressModal/NewAddressModal';
import { useHistory } from 'react-router';
import useCheckMobileScreen from '../../../../utils/useCheckMobileScreen';

// import { Container } from './styles';

type Props = {
	address: Address;
};

const AddressItem: React.FC<Props> = ({ address }) => {
	const [optionsMenuIsVisible, setMenuVisible] = useState<boolean>(false);
	const history = useHistory();
	const closeModal = () => {
		setMenuVisible(false);
	};
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const dispatch = useDispatch();
	const handleDelete = () => {
		if (user.Address?.length != 1) {
			api.delete('/delete-user-address', {
				params: { address_id: address.id },
			}).then((response: AxiosResponse<any>) => {
				if (response.status === 200) {
					dispatch(removeAddress(address.id));
				}
			});
		} else {
			alert('É necessário ter ao menos um endereço cadastrado');
		}
	};

	const modalRef = useRef<NewAddressModalHandles>(null);

	const openModal = () => {
		modalRef.current?.handleOpenModal();
	};

	return (
		<div className="address-card">
			<div className="first-row">
				<div className="infos-container">
					<label>{user.name}</label>
				</div>
			</div>

			<form className="second-row">
				<div className="first-line">
					<span>
						{address.street} {address.number}
					</span>
					<span>
						{address.complement ? address.complement + ', ' : ''}
						{address.city},{' '}
						<span>
							{address.state}
							{'   '}
						</span>
						<span>{address.zip_code}</span>
					</span>
					<span>Telefone: {user.Contacts![0]!.phone}</span>
				</div>
				<div className="second-line">
					<NewAddressModal address={address} ref={modalRef} />
					{useCheckMobileScreen()? (
						<span   onClick={() => {
							history.push(`/user-address-mobile/${address.id}`);
						}}>
							Alterar
						</span>
					) : (
						<span onClick={() => openModal()}>
							Alterar
						</span>
					)}
					<span onClick={() => handleDelete()}>Excluir</span>
				</div>
			</form>
		</div>
	);
};

export default AddressItem;
