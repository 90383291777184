import styled from 'styled-components';

export const Container = styled.div`
	padding-top: 80px;
	min-height: 100vh;
	width: 100%;
	margin: auto;
	background: #f5f5f5;
	text-align: center;
	justify-content: center;
	color: #353a40;
	font-size: 26px;
	font-family: 'Roboto';
	padding-bottom: 80px;
	.text-center {
		margin-top: 40px;
		margin-bottom: 31px;
	}
`;
