import React, { useEffect, useRef, useState } from 'react';
import LoginHeader from '../Login/LoginHeader/LoginHeader';
import { Container, ModalContainer } from './styles';
import party from '../../assets/categoryImages/baloons.png';
import maleClothe from '../../assets/categoryImages/cotton-polo-shirt.png';
import femaleClothe from '../../assets/categoryImages/dress.png';
import eletronic from '../../assets/categoryImages/pc.png';
import shoes from '../../assets/categoryImages/sneaker.png';
import toys from '../../assets/categoryImages/teddy.png';
import decoration from '../../assets/categoryImages/vaso.png';
import sport from '../../assets/categoryImages/volei.png';
import jewelry from '../../assets/categoryImages/joia.png';
import api from '../../api';
import { AxiosResponse } from 'axios';
import HeaderAdmin from '../HeaderAdmin/HeaderAdmin';
import Modal from 'react-modal';
import CreateCategory from './CreateCategoryModal/CreateCategory';
import CreateCategoryModal from './CreateCategoryModal/CreateCategory';
import config from '../../config';
import EditCategoryModal from './EditCategoryModal/EditCategory';

export interface categorysProps {
	category: string;
}
export interface ProductCategories {
	id: string;
	name: string;
	path_image: string;
}
const Categorys: React.FC = () => {
	const [productCategories, setProductCategories] = useState<
		ProductCategories[]
	>([]);

	useEffect(() => {
		api.get('/get-product-categories')
			.then((response: any) => {
				setProductCategories(response.data.categories);
			})
			.catch((err) => {});
	}, []);

	const [selectedCategory, setSelectedCategory] = useState<string>('');
	const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);

	const openEditModal = useRef<(category: ProductCategories) => void>(() => {});

	const getCategoryImage = (categoryName: string) => {
		switch (categoryName) {
			case 'Roupas femininas':
				return femaleClothe;
			case 'Roupas masculinas':
				return maleClothe;
			case 'Decorativos':
				return decoration;
			case 'Brinquedos':
				return toys;
			case 'Calçados':
				return shoes;
			case 'Festas':
				return party;
			case 'Artigos esportivos':
				return sport;
			case 'Eletrônicos':
				return eletronic;
			case 'Joias':
				return jewelry;
			default:
				return decoration;
		}
	};

	return (
		<>
			<CreateCategoryModal
				open={openCreateModal}
				setOpen={setOpenCreateModal}
			/>
			<EditCategoryModal
				openModal={openEditModal}
			/>
			<HeaderAdmin />
			<Container>
				<div className="category-text">Categorias</div>
				<div className="container-cards">
					<div className="categories-card">
						{productCategories.map((category) => 
								(
									<div
										className={`category ${
											selectedCategory === category.id
												? 'active'
												: ''
										}`}
										onClick={() => {
											openEditModal.current(category);
										}}
									>
										<img
											src={`${config.host}/static/categories/${category.path_image}`}
											alt=""
										/>
										<span> {category.name} </span>
									</div>
								)
						)}
						<div
							className="more-category"
							onClick={() => {
								setOpenCreateModal(true);
							}}
						>
							<span>+ nova</span>
						</div>
					</div>
				</div>
			</Container>
		</>
	);
};

export default Categorys;
