import React, { useEffect, useState } from 'react';

import { Container } from './styles';
import {
	LineChart,
	Line,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';
import api from '../../../../api';

const defaultData = [
		{
			name: 'Janeiro',
			total: 0,
		},
		{
			name: 'Fevereiro',
			total: 0,
		},
		{
			name: 'Março',
			total: 0,
		},
		{
			name: 'Abril',
			total: 0,
		},
		{
			name: 'Maio',
			total: 0,
		},
		{
			name: 'Junho',
			total: 0,
		},
		{
			name: 'Julho',
			total: 0,
		},
		{
			name: 'Agosto',
			total: 0,
		},
		{
			name: 'Setembro',
			total: 0,
		},
		{
			name: 'Outubro',
			total: 0,
		},
		{
			name: 'Novembro',
			total: 0,
		},
		{
			name: 'Dezembro',
			total: 0,
		},
]

const SellerReputationChart: React.FC = () => {

	const [data, setData] = useState(defaultData);

	useEffect(() => {
		const fetch = async () => {
			const response = await api.get('/get-shop-growth')

			let newData = [...defaultData];

			(response.data.data as any[]).forEach((item, index) => {
				console.log({data: newData[index], index, item})
				newData[index].total += item.total;
			})

			setData(newData);
		};

		fetch();
	}, [])

	return (
		<Container>
			<div style={{ flex: '1', height: '100%' }}>
				<h2>Vendas Realizadas</h2>
				<ResponsiveContainer>
					<LineChart
						width={370}
						height={240}
						data={data}
						margin={{
							top: 5,
							right: 30,
							left: 20,
							bottom: 5,
						}}
					>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="name" />
						<YAxis />
						<Tooltip />
						<Legend />
						<Line
							type="monotone"
							dataKey="total"
							stroke="#8884d8"
							activeDot={{ r: 8 }}
						/>
					</LineChart>
				</ResponsiveContainer>
			</div>
			{/* <div className="left-content">
				<div className="table-info">
					<h1>25%</h1>
					<p style={{ color: '#5A5A5A', fontSize: '17px' }}>Aumento de vendas</p>
					<p style={{ color: '#B1B1B1', fontSize: '13px' }}>Registrado entre 25/04 e 25/04</p>
				</div>
			</div> */}
		</Container>
	);
};

export default SellerReputationChart;
