
export default interface MobileHeader {
	leftSideComp: leftSideComponent;
	middleComp: middleComponent;
	rightSideComp: rightSideComponent;
}

interface leftSideComponent {
	leftSide: leftSideEnum;
	backArrowPath?: string;
}
export enum leftSideEnum {
  logo = 0,
  backArrow = 1,
}

interface middleComponent {
	middle: middleEnum;
}
export enum middleEnum {
  search = 0,
  logo = 1,
}

interface rightSideComponent {
	rightSide: rightSideEnum;
}
export enum rightSideEnum {
  iconMenu = 0,
  menu = 1,
}

export interface MobileHeaderState {
	currentMobHeader: MobileHeader;
}

export const SearchPreset: MobileHeader = {
	leftSideComp: {
		leftSide: leftSideEnum.logo,
	},
	middleComp: {
		middle: middleEnum.search,
	},
	rightSideComp: {
		rightSide: rightSideEnum.iconMenu,
	}
}

export const BackPreset: MobileHeader = {
	leftSideComp: {
		leftSide: leftSideEnum.backArrow,
		backArrowPath: "/seller",
	},
	middleComp: {
		middle: middleEnum.logo,
	},
	rightSideComp: {
		rightSide: rightSideEnum.menu,
	}
}

export const INSERT_IN_MOB_HEADER = 'INSERT_IN_MOB_HEADER';

export interface InsertMobileHeaderAction {
	type: typeof INSERT_IN_MOB_HEADER;
	mobileHeader: MobileHeader;
}

export type MobileHeaderAction =
	| InsertMobileHeaderAction;