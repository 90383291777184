import { createStore, combineReducers } from 'redux';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { devToolsEnhancer } from 'redux-devtools-extension';
import UserReducer from '../reducers/UserReducer';
import ProductReducer from '../reducers/ProductReducer';
import ProductsListReducer from '../reducers/ProductsListReducer';
import ProductsCartReducer from '../reducers/ProductsCartReducer';
import ShopCustomizationsReducer from '../reducers/ShopCustomizations';
import MobileHeaderReducer from '../reducers/MobileHeaderReducer';

const rootReducer: any = combineReducers({
	user: UserReducer,
	product: ProductReducer,
	productsList: ProductsListReducer,
	productsCart: ProductsCartReducer,
	mobileHeader: MobileHeaderReducer,
	shopCustomizations: ShopCustomizationsReducer,
});

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['user', 'productsCart'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, devToolsEnhancer({}));

export type AppState = ReturnType<typeof rootReducer>;

export const persistor = persistStore(store);

export default store;
