import styled from 'styled-components';
import { Checkbox } from 'semantic-ui-react';

export const Container = styled.form`
	padding: 33px 5% 45px 5%;
	width: 90%;
	font-family: var(--Roboto-font-family);
	background: #fff;
	textarea {
		resize: none;
	}
	h4 {
		font-family: var(--Roboto-font-family);
		font-size: 24px;
		font-weight: 300;
		margin-bottom: 10px;
		margin-top: 29px;
	}
	.color-menu {
		display: flex;
		flex-direction: row;
		color: #8a99a8;
		font-size: 16px;
		font-style: italic;
	}
	.color-option {
		margin-right: 10px;
		padding: 15px;
		cursor: pointer;
	}
	.active-variation {
		background: #ffffff 0% 0% no-repeat padding-box;
		border-radius: 8px 8px 0 0;
	}
	.new-variation {
		color: #00b06a;
	}
`;
export const PageTitle = styled.div`
	font-family: var(--Roboto-font-family);
	font-size: 24px;
	font-weight: 300;
	margin-bottom: 25px;
	margin-top: 33px;
	margin-left: 5%;
`;
export const ContainerForm = styled.div`
	//border: 1px solid #c5ced7;
	border-radius: 8px;
	padding: 0 0 25px 0;
	background: #fff;
`;
export const DefaultRow = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	max-width: 100%;
`;
export const InputsColumn = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 30px;
`;
export const RowItem = styled.div`
	display: flex;
	flex-direction: column;
	label {
		font-size: 16px;
		color: #2d327c;
		font-weight: 500;
		margin-bottom: 14px;
	}
	input {
		border: 1px solid #8a99a8;
		border-radius: 8px;
		height: 46px;
		padding-left: 15px;
		color: #5a646e;
	}
	select {
		border: 1px solid #8a99a8;
		border-radius: 8px;
		height: 46px;
		padding-left: 15px;
		color: #5a646e;
		outline: none;
	}
	textarea {
		font-family: var(--Roboto-font-family);
		border: 1px solid #8a99a8;
		border-radius: 8px;

		&.description {
			color: #5a646e;
			font-size: 16px;
			padding: 21px 14px 18px 22px;
			height: calc(164px - 39px);
		}
		&.title {
			padding: 12px 19px;
			height: calc(90px - 24px);
			font-size: 20px;
			color: #000;
		}
	}
	.price {
		font-size: 26px;
		text-align: center;
		padding: 0 0 0 0;
		color: #2d327c;
		height: 70px;
		margin-right: 19px;
		width: 90%;
	}
	.promotion {
		font-size: 26px;
		text-align: center;
		padding: 0 0 0 0;
		color: #2d327c;
		height: 70px;
		width: 100%;
	}
	#tec-description {
		margin-bottom: 11px;
	}
	.variation-name-container {
		display: flex;
		flex-direction: row;
		//width: 700px;
		margin-bottom: 30px;
		column-gap: 13px;
		row-gap: 13px;
		.variation-input-container {
			display: flex;
			flex-direction: column;
		}
		.delete-button {
			width: 150px;
			height: 45px;
			border-radius: 8px;
			border: none;
			border: 1px solid #c9182a;
			color: #fff;
			background: #c9182a;
			margin-top: 35px;
			padding: 0 10px;
		}
	}
	.photo-container {
		display: flex;
		flex: row;
		column-gap: 20px;
		row-gap: 20px;
		flex-wrap: wrap;
		justify-content: space-around;

		.photo {
			width: 65px;
			height: 65px;
		}
		.preview {
			display: flex;
			flex-direction: row;
			width: 65px;
			height: 65px;
			/* overflow-x: auto; */

			.img-preview {
				/* border: 1px solid #8a99a8;
				padding: 5px; */
				border-radius: 8px;
				height: 65px;
				width: 65px;
			}

			.close-icon-container {
				position: relative;
				.close-icon {
					position: absolute;
					left: 80%;
					bottom: 80%;
					height: 15px;
					background: white;
					width: 15px;
					border: 1px solid #444;
					border-radius: 50%;

					&:hover {
						cursor: pointer;
					}
				}
			}
		}
	}
	figure {
		width: 65px;
		height: 65px;
		display: flex;
		flex-direction: row;
		label {
			margin-right: 15px;
			width: 65px;
			height: 65px;
			background: #f6f6f6;
			font-size: 20px;
			color: #8a99a8;
			cursor: pointer;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 8px;
			margin: 0;
		}
		input {
			border: none !important;
			display: none;
		}
	}
	.input-size-div {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		column-gap: 10px;
		row-gap: 10px;
		justify-content: space-around;
		@media (max-width: 380px) {
			justify-content: flex-start;
		}
		&.single {
			align-items: flex-start;
			justify-content: flex-start;
		}
		div {
			position: relative;
			display: flex;
			flex-wrap: nowrap;
		}
		svg {
			position: absolute;
			cursor: pointer;
			top: 10px;
			right: 0;
		}
		button {
			width: 152px;
			height: 40px;
			border-radius: 8px;
			border: none;
			color: #fff;
			background: #00b06a;
		}
	}
	.size {
		border: 1px solid #8a99a8;
		border-right: none;
		border-radius: 8px 0 0 8px;
		width: 29px;
		height: 43px;
		padding: 0 5px;
		text-align: center;
		background-color: #f2f2f2;
	}
	.size-stock {
		border: 1px solid #8a99a8;
		border-radius: 0 8px 8px 0;
		width: 114px;
		height: 43px;
		text-align: center;
		padding: 0;
	}
	#stock {
		width: 104px;
		height: 43px;
		border: none;
		border-radius: 0;
		border: 1px solid #8a99a8;
		border-radius: 8px;
		text-align: center;
	}
`;
export const SideImgContainer = styled.div`
	border-radius: 8px;
	padding: 2px;

	img {
		width: 66px;
		height: 69px;
		object-fit: cover;
		border-radius: 2px;
		margin-right: 20px;
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	place-content: flex-end;
	margin: 30px 0;
	span {
		font-family: var(--Roboto-font-family);
		font-size: 16px;
		font-weight: 500;
		color: #c9182a;
		text-align: center;
	}
	button {
		height: 34px;
		border-radius: 8px;
		border: none;
		flex: 1;
		font-size: 12px;
		font-weight: normal;
	}
	#save-changes {
		color: #fff;
		background: #00b06a;
	}
	#cancel-changes {
		color: #707070;
		margin-right: 12px;
		border: 1px solid #707070;
	}
	#delete-product {
		color: #ffffff;
		background: #c9182a;
	}
`;

export const StyledCheckBox = styled(Checkbox)`
	display: flex;
	align-items: center;
	height: 35px;

	label {
		color: #5a646e;
		margin-left: 20px;
		font-size: 14px;
		margin-bottom: 0;
	}
`;

export const DeleteProduct = styled.div`
	display: flex;
	height: 76px;
	justify-content: center;
	font-size: 12px;
	font-weight: normal;
	button {
		margin-top: 15px;
		border: none;
		background: none;
		color: #c9182a;
		height: 30px;
		text-decoration: underline;
	}
`;
