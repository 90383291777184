import styled from 'styled-components';

export const PageContainer = styled.main`
	display: flex;
	flex-direction: row;
	justify-content: center;
	height: 100%;
`;
export const Container = styled.div`
	/* display: flex; */
	width: var(--Default-page-alignment-vale);
	height: 100%;
	margin-bottom: 50px;
	@media (max-width: 768px){
		width: 100%;
	}

	h3 {
		font-family: var(--Roboto-font-family);
		font-size: 20px;
		color: #858585;
		/* margin-top: 60px; */
		font-weight: 400;
	}
`;

export const FiltersRow = styled.div`
	display: flex;
	flex-direction: row;
	margin: 30px auto 21px;
    justify-content: center;

`;
export const SearchBox = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid #c5ced7;
	border-radius: 8px;

	min-width: 406px;
	max-width: 578px;
	width: 100%;
	height: 32px;
	color: #858585;
	@media (max-width: 768px) {
		/* display: none; */
		min-width: 50px;
		max-width: 350px;
		margin: 0px


	}
	/* @media (max-width: 1366px) {
		max-width: 455px;
	} */
`;

export const SerchRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: 8px 8px 8px 7px;
`;

export const Search = styled.input`
	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: white;
	padding-left: 10px;

	border: none;
	font-style: italic;
	font-family: var(--Roboto-font-family);
	font-size: 14px;
	@media (max-width: 768px){
		width: 350px;
	}

	::placeholder {
		color: #c5ced7;
	}

	&:focus {
		outline: none;
	}
`;

export const SearchIcon = styled.div`
	display: flex;
	flex-direction: column;
	height: 18px;
	width: 18px;
	svg {
		height: 100%;
		width: 100%;
		fill: #7f7f7f;
		cursor: pointer;
	}
`;

export const Select = styled.select`
	width: 145px;
	height: 28px;
	border: 1px solid #cbcbcb;
	border-radius: 2px;
	padding: 5px;
	margin-right: 20px;
	background: #fff;

	color: #888888;
	font-family: var(--Roboto-font-family);
	font-weight: 400;

	&:focus {
		outline: none;
	}
`;
export const ProductContainer = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: 23px;
	row-gap: 50px;
	width: 100%;

	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;

	height: 90%;
	padding-bottom: 50px;
	@media (max-width: 768px){
		row-gap: 8px;
		align-items: center;
		justify-content: center;
	}

	h3 {
		color: #3b3d4a;
		font-size: 1.2rem;
		font-family: var(--Roboto-font-family);
		font-weight: 400;
	}
`;

export const CardContainer = styled.div`
	display: grid;
	grid-template-columns: 243px 980px;
	align-items: flex-start;
	justify-content: flex-start;

	width: var(--Default-page-alignment-vale);
	height: 50%;
	@media (max-width: 1366px) {
		display: flex;
		width: 97%;
	}

	margin-top: 40px;

	h2 {
		color: #858585;
		font-family: var(--Roboto-font-family);
		font-weight: 500;
		font-size: min(20px, 1.6vw);

		margin-bottom: 25px;
	}

	.filters {
		font-family: var(--Roboto-font-family);
		@media (max-width: 768px){
			display: none;
		}

		ul {
			list-style: none;
			color: #5a646e;
			font-size: 16px;
			font-weight: 300;
		}
		li {
			margin-bottom: 8px;
			transition: all 0.3s;
			cursor: pointer;
		}
		li:hover {
			color: #002793;
			text-decoration: underline;
		}
		#activated{
				font-weight:bold;
			}
		span {
			color: #8a99a8;
			font-size: 14px;
			cursor: pointer;
			transition: all 300ms;
		}
		span:hover{
			font-weight: bold;
			text-decoration: underline;
		}
		h6 {
			font-size: 18px;
			color: #353a40;
			margin-bottom: 16px;
		}

		.filters-input {
			input {
				width: 73px;
				height: 40px;
				background: #f5f5f5 0% 0% no-repeat padding-box;
				border: 1px solid #8a99a8;
				border-radius: 4px;
				text-align: center;
			}
		}
	}

	.categories-list{
		@media (min-width: 768px) {
			max-height: 145px;
			overflow: auto;
			margin-right: 70px;
		}
	}
`;
