import styled from 'styled-components';

export const PageContainer = styled.div`
	height: max-content;
	min-height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	background: #f5f5f5;
`;

export const AlignmentContainer = styled.div`
	width: var(--Default-page-alignment-vale);
	height: max-content;
	margin: auto;
	@media (max-width: 768px) {
		width: 100%;
	}
`;

export const Container = styled.div`
	height: max-content;
	min-height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	background: #f5f5f5;

	margin-top: 45px;
	margin-bottom: 45px;

	h1 {
		color: #353a40;
		font-family: var(--Roboto-font-family);
		font-size: 26px;
		font-weight: normal;

		margin-bottom: 24px;
		@media (max-width: 768px) {
			padding-left: 5%;
		}
	}

	.informations-container {
		flex: 1;
		display: flex;
		justify-content: space-between;
		width: 100%;
		column-gap: 25px;
		@media (max-width: 768px) {
			flex-direction: column;
			width: 90%;
			margin: 0 5%;
		}

		.main-card {
			flex: 2;
			background: white;
			height: max-content;
			border-radius: 12px;
		}

		.main-card {
			display: flex;
			flex-direction: column;
			padding: 32px;

			.product-quantity-container {
				/* border-bottom: solid 1px #cecece; */
				padding: 0px 10px 30px 10px;
				h2 {
					color: #757478;
					font-size: min(16px, 1vw);
					font-family: var(--Roboto-font-family);
					font-weight: 400;

					margin-bottom: 15px;
				}

				.quantity {
					display: flex;
					align-items: center;
					column-gap: 18px;

					padding-left: 10px;

					.less-selector,
					.more-selector {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 20px;
						height: 20px;
						border: solid 1px #72ba6c;
						color: #72ba6c;
						text-align: center;
						border-radius: 4px;
					}
					.quantity-number {
						display: flex;
						align-items: center;
						justify-content: center;
						border: solid 1px #b1b1b1;
						border-radius: 4px;
						width: 28px;
						height: 28px;

						color: #5a5a5a;

						font-size: min(20px, 1.2vw);
						font-family: var(--Roboto-font-family);
						font-weight: bold;
					}
					.product-name {
						color: #888888;

						font-size: min(13px, 1vw);
						font-family: var(--Roboto-font-family);
						font-weight: 400;
					}
				}
			}
		}
	}
`;
export const ItemContainer = styled.div`
	border-top: solid 1px #cecece;
	/* padding: 30px 10px 30px 10px; */

	h2 {
		color: #353a40;
		font-size: min(16px, 1vw);
		font-family: var(--Roboto-font-family);
		font-weight: normal;

		margin-bottom: 10px;
	}

	.options-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		row-gap: 18px;

		padding-left: 8px;
		padding-bottom: 30px;

		.product-title {
			color: #5a646e;
			font-size: min(16px, 1vw);
			font-family: var(--Roboto-font-family);
			font-weight: bold;

			margin-bottom: 15px;
		}

		.item {
			display: flex;
			flex-direction: row;
			align-items: center;

			column-gap: 15px;

			.selector {
				width: 16px;
				height: 16px;

				border: solid 1px #b1b1b1;
				border-radius: 2px;
				align-self: flex-start;

				&:hover {
					cursor: pointer;
				}
			}

			.active {
				background: #72ba6c;
				border: none;
			}

			.option-text {
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				span {
					color: #8a99a8;

					font-size: min(14px, 1vw);
					font-family: var(--Roboto-font-family);
					font-weight: normal;
					margin-bottom: 8px;

					&.span-blue {
						color: #2b63fc;
						margin-bottom: 0;
					}
				}

				p {
					color: #8a99a8;
					font-size: min(12px, 0.7vw);
					font-family: var(--Roboto-font-family);
					font-weight: normal;
				}
			}
		}

		.add-button {
			display: flex;
			width: 100%;

			button {
				width: 100%;
				height: 32px;
				border-radius: 2px;

				background: #f5f5f5;
				border: none;

				color: #2d327c;
				font-size: min(12px, 1vw);
				font-family: var(--Roboto-font-family);
				font-weight: normal;
			}
		}
	}
`;
