import React, { useEffect, useState } from 'react';
import { Container, ModalContainer } from './styles';
import Modal from 'react-modal';
type Props = {
	open: boolean;
	setOpen: (open: boolean) => void;
};

const RegisterAdmModal: React.FC<Props> = ({ open, setOpen }) => {
	return (
		<Modal
			isOpen={open}
			onRequestClose={() => setOpen(false)}
			style={{
				overlay: {
					position: 'fixed',
					top: 0,
					zIndex: 1001,
					left: 0,
					right: 0,
					bottom: 0,
					background: '#0007',
				},
				content: {
					top: '50%',
					left: '50%',
					right: 'auto',
					padding: '0 auto',
					bottom: 'auto',
					marginRight: '-50%',
					maxHeight: '100%',
					border: 'none',
					borderRadius: '11px',
					transform: 'translate(-50%, -50%)',
				},
			}}
		>
			<ModalContainer>
				<div className="cards">
					<h1>Usuário cadastrado</h1>
					<p>
						<strong>Login: </strong>
						test@gmail.com
					</p>
					<p>
						<strong>Senha: </strong>
						123
					</p>
				</div>
			</ModalContainer>
		</Modal>
	);
};

export default RegisterAdmModal;
