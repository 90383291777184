import styled from 'styled-components';

export const Container = styled.div`
	font-family: var(--Roboto-font-family);
	padding-top: 31px;
	padding-left: 27px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 8px;
	margin-left: 10px;
	max-width: 368px;
	@media (max-width: 768px){
		margin-bottom: 50px;
		margin-left: 0px;
		max-width:100%;
	}
`;

export const CardHeader = styled.div`
	font-weight: 600;
	height: 30px;
	margin-bottom: 30px;
	color: #89898b;
	font-size: min(14px, 1.8vw);
	@media (max-width: 768px){
		font-size: 14px;
	}
	h4 {
		color: #004d93;
		font-size: min(18px, 1.8vw);
		@media (max-width: 768px){
			font-size: 18px;
		}
	}
`;
export const CardMid = styled.div`
	margin-right: 31px;
	margin-bottom: 30px;
	p {
		color: #959595;
		font-size: min(13px, 1.8vw);
		@media (max-width: 768px){
			font-size: 13px;
		}
	}

`;
export const CardBottom = styled.div`
	margin-bottom: 50px;

	span {
		color: #89898b;
		font-size: min(14px, 1.8vw);
		font-weight: 600;
		@media (max-width: 768px){
			font-size: 12px;
		}
	}
	ul {
		list-style: none;
	}
	li {
		@media (max-width: 768px){
			font-size: 13px;
		}
		color: #959595;
		font-size: min(13px, 1.8vw);
	}
`;
