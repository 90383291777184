import styled, { createGlobalStyle } from 'styled-components';
import 'react-credit-cards/es/styles-compiled.css';
export const GlobalStyles = createGlobalStyle`

* {
	margin: 0;
	padding: 0;
}

body{
    overflow-x: hidden;
}

.app-container,
html,
body,
#root {
	height: max-content;
    min-height:100%;
    
    [disabled]{
      opacity: 0.5;
      cursor: not-allowed;
    }
    font-size: 1.4rem;
    --Tofino-font-family: 'Tofino Pro Personal', sans-serif;
    --Roboto-font-family: 'Roboto', sans-serif;
    --Open-sans-font-family: 'Open Sans', sans-serif;
    --Poppins-font-family: 'Poppins', sans-serif;

    --Default-green-color: #72BA6C;
    --Default-page-alignment-vale: 1184px;
    /* --Default-page-alignment-vale: 78.6%; */

    input:focus,button:focus,textarea:focus{
        outline:none;

    }
    button:hover{
        cursor:pointer;
    }
   
}



`;
