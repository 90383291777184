import ProductsCart, {
	AddProductInCart,
	ClearCart,
	IAddProductAction,
	IInserProductShippingWay,
	IInsertAddressProductAction,
	IInsertProductPaymentFormProductAction,
	IInsertProductQuantityProductAction,
	InsertInstallmentsAction,
	IRemoveProductAction,
} from '../interfaces/ProductsCart';
import { Address } from '../interfaces/UserAccount';
import { IProduct } from '../Pages/ProductPage/ProductPage';

export interface Service {}
export const addProductCartAction = (
	productsCart: ProductsCart
): IAddProductAction => ({
	type: 'INSERT_IN_CART',
	productsCart,
});
export const addSingleProductCartAction = (
	product: ProductsCart
): AddProductInCart => ({
	type: 'ADD_ITEM_IN_CART',
	product,
});

export const insertAddressProductAction = (
	productId: string,
	address: Address,
	cart_product_id: number
): IInsertAddressProductAction => ({
	type: 'INSERT_ADDRESS',
	productId,
	address,
	cart_product_id,
});

export const removeProductCartAction = (
	productId: string,
	cart_product_id: number
): IRemoveProductAction => ({
	type: 'REMOVE_FROM_CART',
	productId,
	cart_product_id,
});
export const insertProductQuantity = (
	productId: string,
	quantity: number,
	cart_product_id: number
): IInsertProductQuantityProductAction => ({
	type: 'INSERT_QUANTITY',
	productId,
	cart_product_id,
	quantity,
});
export const insertProductShippingWay = (
	productId: string,
	shippingWay: string,
	cart_product_id: number,
	shippingValue?: string,
	address?: Address
): IInserProductShippingWay => ({
	type: 'INSERT_SHIPPING_METHOD',
	productId,
	shippingWay,
	address,
	shippingValue,
	cart_product_id,
});

export const insertProductPaymentForm = (
	productId: number,
	paymentForm: 'boleto' | 'credit_card'
): IInsertProductPaymentFormProductAction => ({
	type: 'INSERT_PRODUCT_PAYMENT',
	productId,
	paymentForm,
});
export const insertInstallments = (
	installments: number,
	productId: string
): InsertInstallmentsAction => ({
	type: 'INSERT_INSTALLMENTS',
	installments,
	productId,
});
export const clearCart = (): ClearCart => ({
	type: 'CLEAR_CART',
});
