import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Modal from 'react-modal';
import { Container } from './styles';
import cardBrand from '../../../../assets/icons/cardBrand.svg';
import UserAccount from '../../../../interfaces/UserAccount';
import { useForm } from 'react-hook-form';
import api from '../../../../api';
import { insertUser, updatePhotoName } from '../../../../actions/UserActions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../store';

export interface UpdateUserInfosModalHandle {
	handleOpenModal: () => void;
}
interface UpdateUserFormInputs {
	name: string;
	phone: string;
	email: string;
}

const UpdateUserInfosModal: React.ForwardRefRenderFunction<UpdateUserInfosModalHandle> = (
	{},
	ref
) => {
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const [modalIsOpen, setIsOpen] = useState(false);
	const { register, handleSubmit, errors } = useForm<UpdateUserFormInputs>();
	const dispacth = useDispatch();
	const onSubmit = (data: any) => {
		try {
			api.put('/update-user', data).then((response) => {
				dispacth(insertUser(response.data.user));
				closeModal();
				alert('Dados enviados com sucesso');
			});
		} catch (error) {
			alert('Falha ao alterar os dados');
		}
	};

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			width: '60%',
			maxWidth: '336px',
			transform: 'translate(-50%, -50%)',
			padding: '32px',
		},
		overlay: {
			backgroundColor: 'rgba(0,0,0, 0.8)',
		},

		h2: {
			color: '#3B3D4A',
		},
	};

	function closeModal() {
		setIsOpen(false);
	}
	function handleOpenModal() {
		setIsOpen(true);
	}

	useImperativeHandle(ref, () => {
		return {
			handleOpenModal,
		};
	});

	return (
		<Modal
			isOpen={modalIsOpen}
			onRequestClose={closeModal}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<Container>
				<div className="title">
					<h6>Alterar Dados Gerais</h6>
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<label>Nome:</label>
					<input
						type="text"
						defaultValue={user.name}
						ref={register({ required: true })}
						name="name"
					/>
					<label>E-mail:</label>
					<input
						type="text"
						defaultValue={user.email}
						name="email"
						ref={register({ required: true })}
					/>
					<label>Telefone:</label>
					<input
						type="text"
						defaultValue={user.Contacts![0]!.phone}
						name="phone"
						ref={register({ required: true })}
					/>

					<div className="button">
						<button type="submit">Alterar informações</button>
					</div>
				</form>
			</Container>
		</Modal>
	);
};

export default forwardRef(UpdateUserInfosModal);
