import React, { useState } from 'react';
import defaultImg from '../../../assets/defaultImg.svg';
import { FaUserCircle } from 'react-icons/fa';

type Props = {
	src: string;
	className: string;
	effectIsActive?: boolean;
	onMouseLeave?: any;
	onMouseEnter?: any;
};

const UserImg: React.FC<Props> = ({
	src,
	className,
	effectIsActive,
	onMouseLeave,
	onMouseEnter,
}) => {
	const [effect, setEffect] = useState<string>('1');
	const [imgState, setImgState] = useState<any>(
		<img
			src={src}
			className={className}
			alt=""
			style={{ filter: `brightness(${effect})` }}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onError={() =>
				setImgState(
					<>
						{' '}
						<FaUserCircle
							style={{
								fill: '#2D327C',
								filter: `brightness(${effect})`,
							}}
							className={className}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
						/>
					</>
				)
			}
		/>
	);

	return imgState;
};

export default UserImg;
