import React from 'react';
import Avaliations from './Avaliations/Avaliations';
import SellerReputationChart from './SellerReputationChart/SellerReputationChart';
import SellerReputationInfosCard from './SellerReputationInfosCard/SellerReputationInfosCard';

import { PageContainer, Container } from './styles';

const SellerReputation: React.FC = () => {
	return (
		<PageContainer>
			<Container>
				{/* <SellerReputationInfosCard /> */}
				<SellerReputationChart />
			</Container>
			{/* <Avaliations /> */}
		</PageContainer>
	);
};

export default SellerReputation;
