import React, { useState } from 'react';

import { Container, ImageAndInfosContainer } from './styles';

import { useDispatch } from 'react-redux';

import gol from '../../../../assets/exemple.jpg';

import Modal from 'react-modal';
import CancelOrderModal from './CancelOrderModal/CancelOrderModal';
import MoreInfoModal from './MoreInfoModal/MoreInfoModal';
import config from '../../../../config';
import { ChargeProducts } from '../../../../interfaces/ApiResponses/UserPurchases';

type Props = {
	userSell: any;
};

const SalesCard: React.FC<Props> = ({ userSell }) => {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [modalIsOpen2, setModalIsOpen2] = useState(false);

	function closeModal() {
		setModalIsOpen(false);
	}
	function openModal() {
		setModalIsOpen(true);
	}
	function closeModal2() {
		setModalIsOpen2(false);
	}
	function openModal2() {
		setModalIsOpen2(true);
	}

	const canceled = !!(userSell.charge_products as ChargeProducts[]).find(product => product.canceled);

	console.log({userSell})

	return (
		<Container>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => {
					closeModal();
				}}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						zIndex: 1001,
						left: 0,
						right: 0,
						bottom: 0,
						background: '#0007',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						padding: '0 auto',
						bottom: 'auto',
						marginRight: '-50%',
						maxHeight: '100%',
						border: 'none',
						borderRadius: '11px',
						transform: 'translate(-50%, -50%)',
					},
				}}
			>
				<CancelOrderModal
					charge_id={userSell.id}
				/>
			</Modal>
			<Modal
				isOpen={modalIsOpen2}
				onRequestClose={() => {
					closeModal2();
				}}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						zIndex: 1001,
						left: 0,
						right: 0,
						bottom: 0,
						background: '#0007',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						padding: '0 auto',
						bottom: 'auto',
						marginRight: '-50%',
						maxHeight: '100%',
						border: 'none',
						borderRadius: '11px',
						transform: 'translate(-50%, -50%)',
					},
				}}
			>
				<MoreInfoModal
					userSell={userSell}
					// handleCloseModal={closeModal}
				/>
			</Modal>

			<ImageAndInfosContainer>
				<div className="card">
					<img
						src={`${config.host}/static/products/${userSell.charge_products[0].products.product_photos[0]?.name}`}
						alt=""
					/>

					<div className="infos-container">
						{/* <h3>{product?.user_accounts?.name}</h3> */}
						<h2>
							{' '}
							{userSell.charge_products[0].products.title.substring(
								0,
								20
							)}{' '}
						</h2>
						<div className="bottom-infos">
							<h5>
								{parseFloat(
									userSell.charge_products[0].products.price
								).toLocaleString('pt-BR', {
									style: 'currency',
									currency: 'BRL',
								})}
							</h5>
							{/* <span> {userSell.status.pt_name} </span> */}
						</div>
					</div>
				</div>
				<div className="buttons">
					<span>Pedido {userSell.pagarme_charge_id}</span>
					{canceled && <span style={{color: 'red'}}>Pedido cancelado</span>}
					<button id="more-infos" onClick={() => openModal2()}>
						Mais informações
					</button>
					{!canceled && (
						<button id="cancel-order" onClick={() => openModal()}>
							Cancelar Pedido
						</button>
					)}
				</div>
			</ImageAndInfosContainer>
		</Container>
	);
};

export default SalesCard;
