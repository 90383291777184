import ProductsCart from '../interfaces/ProductsCart';

export async function searchInCart(
	productsInCart: ProductsCart[],
	product_id: string
) {
	let quantityIsInCart: number = 0;
	productsInCart.map((item) => {
		if (item.parameters.product.id === product_id) {
			quantityIsInCart = quantityIsInCart + 1;
		}
	});

	return quantityIsInCart;
}
