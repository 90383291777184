import styled from 'styled-components';

export const Container = styled.div`
	width: 100vw;
	height: 50px;
	background: #fff;
	box-shadow: 0px 3px 6px #0000001c;
	position: absolute;
	left: 0;
	z-index: 2;
	display: flex;
	flex-direction: row;
	.row {
		display: flex;
		margin-left: 23%;
		svg {
			padding-left: 5px;
			padding-right: 5px;
			cursor: pointer;
			margin: 10px 20px;
		}
		p {
			background: black;
			color: white;
			font-size: 12px;
			border-radius: 13px;
			text-align: center;
			font-family: 'Roboto';
			width: 94px;
			height: 23px;
			position: absolute;
		}
		.notification {
			svg {
				width: 26px;
				padding: 0px;
			}
			p {
				display: none;
			}
		}
		.notification.active {
			svg {
				border-radius: 8px;

				padding-left: 5px;
				padding-right: 5px;
				color: white;
				background: black;
			}
			p {
				display: block;
				color: white;
			}
		}
		.categorys {
			svg {
				width: 26px;
				padding: 0px;
			}
			p {
				display: none;
			}
		}
		.categorys.active {
			svg {
				border-radius: 8px;

				padding-left: 10px;
				padding-right: 10px;
				color: white;
				background: black;
			}
			p {
				display: block;
			}
		}
		.products {
			svg {
				width: 26px;
				padding: 0px;
			}
			p {
				display: none;
			}
		}
		.products.active {
			svg {
				border-radius: 8px;
				padding-left: 5px;
				padding-right: 5px;
				color: white;
				background: black;
			}
			p {
				display: block;
			}
		}
		.sallers {
			svg {
				width: 26px;
				padding: 0px;
			}
			p {
				display: none;
			}
		}
		.sallers.active {
			svg {
				width: 30px;
				padding-left: 5px;
				padding-right: 5px;
				color: white;
				border-radius: 8px;
				background: black;
			}

			p {
				display: block;
			}
		}
		.edit {
			p {
				display: none;
			}
			svg {
				width: 26px;
				padding: 0px;
			}
		}
		.edit.active {
			svg {
				border-radius: 8px;

				padding-left: 5px;
				padding-right: 5px;
				border-radius: 8px;
				color: white;
				background: black;
			}
			p {
				display: block;
			}
		}
	}
`;

export const DivLogo = styled.div`
	margin-left: 90px;
	margin-top: 10px;
	fill: red;
	.header-a {
		color: purple;
	}
`;
