import React, { useEffect, useState } from 'react';
import Header from '../../../components/Default/header';
import { Container } from './styles';

import UserAddressMobile from '../../../components/UserAddressMobile/UserAddressMobile';
import { useParams } from 'react-router';
import api from '../../../api';
import { Address } from '../../../interfaces/UserAccount';
import { useDispatch } from 'react-redux';
import { insertMobileHeader } from '../../../actions/MobileHeaderActions';
import { BackPreset } from '../../../interfaces/MobileHeader';

interface PageParams {
	address_id: string;
}

const UserAddressMobilePage: React.FC = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(
			insertMobileHeader({
				...BackPreset,
				leftSideComp: {
					leftSide: 1,
					backArrowPath: '/profile/user-information',
				},
			})
		);
	});

	const pageParams = useParams<PageParams>();
	const [address, setAddress] = useState<Address>();

	useEffect(() => {
		if (pageParams.address_id) {
			api.get('/get-user-address', {
				params: {
					address_id: pageParams.address_id,
				},
			})
				.then((response: any) => {
					setAddress(response.data.address);
				})
				.catch((error: any) => {});
		} else {
		}
	}, [pageParams]);

	return (
		<>
			<Container>
				<Header />
				{address && <UserAddressMobile address={address} />}
				{!pageParams.address_id && <UserAddressMobile />}
			</Container>
		</>
	);
};

export default UserAddressMobilePage;
