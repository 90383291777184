import React from 'react';
import Header from '../../components/Default/header';
import UserProfile from '../../components/UserProfile/UserProfile';

import { Container } from './styles';

const UserProfilePage: React.FC = () => {
	return (
		<Container>
			<Header />
			<UserProfile />
		</Container>
	);
};

export default UserProfilePage;
