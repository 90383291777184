import React from 'react';
import Welcome from '../../components/Welcome/Welcome';
import { Container } from './styles';

const WelcomePage: React.FC = () => {
	return (
		<>
			<Container>
				<Welcome />
			</Container>
		</>
	);
};

export default WelcomePage;
