const config = {
	host: 
			'https://bomplace.com.br/api',
	cardPublicToken:
	//Matheus
	// 'A007E44AFAC2AC9770F89DDB18002221DF96BF82912DE4D6EEA87686175834DF',
		process.env.NODE_ENV === 'development' ?
		'72A57B19C0ECBF9BB11194588302537617C08E98398A6D675637A8E21869247D':
		'BE600B7962B8B845AD2DBF525AA6E6F9151E9FAE0F3C157A286C343A531BF49902C26FE21FC674AF'
};
export default config;
