import styled from 'styled-components';

export const WhatsappButton = styled.button`
    background-color: #128c7e;
    color: white;

    padding: 12px;
    border: 0;
    border-radius: 24px;

    margin: auto;

    font-size: 24px;

    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    justify-content: center;
`

export const ContactForm = styled.form`
    margin: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 8px;
`

export const ContactFormSection = styled.section`
    display: flex;
    flex-direction: column;
`

export const ContactFormLabel = styled.label`
    margin: 0;
`

export const ContactFormSubmit = styled.button`
    padding: 8px 24px;
    border: 0;
    border-radius: 16px;
    background-color: #084F92;
    color: white;
    font-weight: bold;

    &:disabled {
        cursor: default;
    }
`

interface IContactFormInput {
    hasErrors?: boolean;
}

export const ContactFormInput = styled.input<IContactFormInput>`
    padding: 8px;
    border: 0;
    outline: ${props => props.hasErrors ? '1px solid red' : '0'};
    border-radius: 16px;
`

export const FormSentText = styled.h1`
    margin-top: 12px;

    color: #5FD068;
    font-size: 24px;
    font-weight: bold;
`