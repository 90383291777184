import styled from 'styled-components';

export const Container = styled.div`
	z-index: 0;

	.swiper-container {
		width: 100vw;
		height: 378px;
		@media (max-width: 768px) {
			height: 290px;
		}
		@media (max-width: 600px) {
			height: 200px;
		}

		&:hover {
			.swiper-button-next, .swiper-button-prev {
				opacity: 1;
			}
		}
	}

	.swiper-button-next, .swiper-button-prev {
		opacity: 0;
		transition: opacity 150ms ease-in-out;
	}

	.swiper-slide {
		text-align: center;
		font-size: 18px;
		background: #fff;

		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
	}

	.swiper-slide img {
		display: block;
		width: 100%;
		height: 378px;
		object-fit: cover;
		@media (max-width: 768px) {
			height: 290px;
		}
		@media (max-width: 600px) {
			height: 200px;
		}
	}
`;
