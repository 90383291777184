import styled from 'styled-components';
interface StatusColor {
	color: string;
}
export const Container = styled.div`
	width: 95%;
	border: solid 1px #c5ced7;
	border-radius: 8px;
	height: 100px;

	display: flex;
	align-items: center;
	padding: 16px;

	@media (max-width: 768px) {
		display: grid;
		grid-template-columns: 1fr 2fr;
		border: none;
		/* flex-direction: column; */
		height: max-content;
		row-gap: 20px;
		background: #fff;
	}
`;
export const LeftSide = styled.div`
	flex: 4;
	display: flex;
	flex-direction: row;

	height: 100%;

	align-items: flex-start;
	padding-top: 5px;
	padding-bottom: 5px;

	@media (max-width: 768px) {
		grid-column: 2;
		grid-row: 1;
	}

	.product-infos {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;

		height: 100%;
		@media (max-width: 768px) {
			justify-content: flex-start;
		}

		.product-title {
			color: #353a40;
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			font-size: 20px;
			@media (max-width: 768px) {
				margin-bottom: 15px;
			}
		}
		.product-price {
			color: #20256c;
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			font-size: 26px;
		}
	}
`;
export const Mid = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	@media (max-width: 768px) {
		display: none;
	}

	flex: 2;
	border-left: solid 1px #c5ced7;
	padding-left: 16px;
	padding-right: 16px;

	margin-left: 16px;

	padding-top: 5px;
	padding-bottom: 5px;

	height: 100%;
	h3 {
		color: #2d327c;
		font-family: var(--Roboto-font-family);
		font-weight: 400;
		font-size: min(16px, 1.8vw);

		margin-bottom: 15px;
	}
	.seller-informations {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;

		.seller-image {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			margin-right: 8px;
		}
		span {
			color: #5a646e;
			font-family: var(--Roboto-font-family);
			font-weight: 300;
			font-size: min(16px, 1.5vw);
		}
	}
`;
export const RightSide = styled.div`
	flex: 3;
	border-left: solid 1px #c5ced7;
	padding-left: 16px;
	margin-left: 16px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	height: 100%;

	@media (max-width: 768px) {
		border-left: none;
		padding-left: 0;
		margin-left: 0;
		width: 100%;
		grid-column: 2;
		grid-row: 2;
	}

	.purchase-status {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		margin-bottom: 18px;
	}
	.icon {
		margin-right: 11px;
	}

	button {
		border: solid 1px #2b63fc;
		width: 290px;
		height: 34px;
		color: #2b63fc;

		font-family: var(--Roboto-font-family);
		font-weight: 500;
		font-size: 13px;
		background: white;
		border-radius: 8px;

		@media (max-width: 768px) {
			width: 100%;
		}
	}
`;
export const Status = styled.div<StatusColor>`
	color: ${(prop) => prop.color};

	font-family: var(--Roboto-font-family);
	font-weight: 500;
	font-size: 16px;
`;

export const ProductImage = styled.img`
	width: 160px;
	height: 100%;
	object-fit: cover;
	border-radius: 8px;
	margin-right: 16px;

	&.desktop {
		@media (max-width: 768px) {
			display: none;
		}
	}
	&.mobile {
		display: none;
		@media (max-width: 768px) {
			display: block;
			width: 90px;
			height: 90px;
			grid-column: 1;
			grid-row: 1 / 2;
		}
	}
`;
