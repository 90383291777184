import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Item } from 'semantic-ui-react';
import api from '../../../../../api';
import gol from '../../../../../assets/exemple.jpg';
import config from '../../../../../config';
import { ChargeProducts } from '../../../../../interfaces/ApiResponses/UserPurchases';
import { Container, CorreiosLabel, InfoContainer, InputsRow, RowItem, Tracking } from './styles';

type Props = {
	userSell: any;
};

const MoreInfoModal: React.FC<Props> = ({ userSell }) => {

	const resultRef = useRef<HTMLDivElement>(null);
	const handlePrint = useReactToPrint({ content: () => resultRef.current });
	const receiptInputRef = useRef<HTMLInputElement>(null);
	const [tracking, setTracking] = useState<any[]>([]);

	useEffect(() => {
		const fetch = async () => {
			try {
				const result = await api.get(`/tracking/${userSell.charge_products[0].correios_etiqueta}`)

				setTracking(result.data);
			} catch (error) {
				setTracking([{
					descricao: "Etiqueta não encontrada no sistema dos Correios",
					dtHrCriado: new Date(),
				}])
			}
		}

		fetch();
	}, [userSell])

	console.log({tracking})

	const sendReceipt = async (file: File) => {
		const data = new FormData();
		data.append('receipt', file);
		data.append('charge_id', userSell.id);

		const response = await api.post('/charge-receipt', data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		})

		console.log({file, response})
	}

	const canceled = !!(userSell.charge_products as ChargeProducts[]).find((chargeProduct) => chargeProduct.canceled);
	const cancelReason = (userSell.charge_products as ChargeProducts[]).find((chargeProduct) => chargeProduct.canceled)?.cancel_reason || '';
	const canceledAt = (userSell.charge_products as ChargeProducts[]).find((chargeProduct) => chargeProduct.canceled)?.canceled_at || new Date();

	console.log({userSell})

	return (
		<>
		<Container>
			<h4>Informações do pedido</h4>
			<div className="infos">
				<InfoContainer>
					<label>Produtos nesta venda</label>
					{userSell.charge_products.map((item: any) => (
						<InputsRow
							key={item.id}
							style={{
								marginTop: '15px',
								borderBottom: '1px solid #C5CED7',
							}}
						>
							<RowItem>
								<img
									src={`${config.host}/static/products/${item.products.product_photos[0]?.name}`}
									alt="imagem_do_produto"
								/>
							</RowItem>
							<RowItem style={{ width: '209px' }}>
								<h6 className="product-title">{item.products.title.substring(0, 20)}</h6>
							</RowItem>
							<RowItem style={{ placeItems: 'flex-end' }}>
								<div className="size">
									<p>TAM: U</p>
								</div>
								<span>
									{parseFloat(
										item.products.price
									).toLocaleString('pt-BR', {
										style: 'currency',
										currency: 'BRL',
									})}
								</span>
							</RowItem>
						</InputsRow>
					))}
				</InfoContainer>
				<InfoContainer style={{ width: '231px' }}>
					<label>Comprador</label>
					<RowItem style={{ flexDirection: 'row' }}>
						<div className="user-photo"></div>
						<p>{userSell.user_accounts.name.substring(0, 15)}</p>
					</RowItem>
					<RowItem style={{ marginTop: '15px' }}>
						<label>Entrega Escolhida</label>
						<p>Envio pelos correios</p>
					</RowItem>
					<RowItem style={{ marginTop: '15px' }}>
					{canceled && (
						<>
							<label>Cancelado</label>
							<p>{cancelReason}</p>
							<p>Cancelado em: {new Date(canceledAt).toLocaleDateString('pt-br')}</p>
						</>
					)}
					</RowItem>
				</InfoContainer>
				<InfoContainer
					style={{
						width: '406px',
						marginRight: '0',
						display: 'inline-grid',
					}}
				>
					<Tracking>
						{tracking.map((track) => (
							<li>
								<p>
									{new Date(track.dtHrCriado).toLocaleDateString('pt-br')}
								</p>
								<h1>
									{track.descricao}
								</h1>
							</li>
						))}
					</Tracking>
				</InfoContainer>
			</div>
			{!canceled && (
				<div className="buttons">
					<button 
						id="invoice"
						onClick={() => {
							receiptInputRef.current?.click();
						}}
					>
						<input 
							type='file' 
							id='file' 
							ref={receiptInputRef} 
							style={{display: 'none'}}
							multiple={false}
							onChange={(e) => {

								if(!e.target.files) return;

								const file = e.target.files[0];

								if (file) {
									sendReceipt(file);
								}
							}}
						/>
						Enviar nota fiscal
					</button>
					<button id="printer" onClick={() => handlePrint()}>Imprimir etiqueta de envio</button>
				</div>
			)}
		</Container>
		<CorreiosLabel ref={resultRef}>
			<p>Contrato: 9912574929</p>
			<p>{userSell.charge_products[0].correios_etiqueta}</p>
			<p>Recebedor:</p>
			<p>Assinatura:</p>
			<section>
				<h1>Destinatário</h1>
				<p>{userSell.user_accounts.name}</p>
				<p>{userSell.charge_products[0].address.street}, {userSell.charge_products[0].address.number}</p>
				<p>{userSell.charge_products[0].address.complement}</p>
				<p><strong>{userSell.charge_products[0].address.zip_code}</strong>, {userSell.charge_products[0].address.city}/{userSell.charge_products[0].address.state}</p>
			</section>
			<p><strong>Remetente</strong>: {userSell.charge_products[0].products.user_accounts.name}</p>
			<p>{userSell.charge_products[0].products.address.street}, {userSell.charge_products[0].products.address.number}, {userSell.charge_products[0].products.address.complement}</p>
			<p><strong>{userSell.charge_products[0].products.address.zip_code}</strong> {userSell.charge_products[0].products.address.city}-{userSell.charge_products[0].products.address.state}</p>
		</CorreiosLabel>
		</>
	);
};
export default MoreInfoModal;
