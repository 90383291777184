import styled from 'styled-components';

export const Container = styled.div`
	min-height:100vh;
	background: #f5f5f5;
	/* background: purple; */
	display: flex;
	flex-direction: column;
	/* margin-top: 500px; */
`;
