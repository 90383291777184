import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
	font-size: 10px;
	display: flex;

	width: 100%;
	height: 300px;
	font-family: var(--Roboto-font-family);

	@media (max-width: 768px) {
		flex-direction: column;
		height: 100%;
		width: 100%;
		padding-bottom: 100px;
	}

	h2 {
		color: #353a40;
		font-size: 18px;
		font-weight: 300;
		font-family: var(--Roboto-font-family);
		margin-bottom: 15px;
	}

	.left-content {
		display: flex;
		flex-direction: column;
		width: 348px;
		height: 201px;
		border: 1px solid #e1e1e1;
		border-radius: 6px;
		text-align: center;
		margin-top: 7%;
		@media (max-width: 768px) {
			margin-top: calc(7% + 25px);
			flex-direction: column;
			width: 100%;
		}
		.table-info {
			font-family: var(--Roboto-font-family);
			padding: 43px 0 42px 0;
			h1 {
				color: #72ba6c;
				font-size: 53px;
				margin-top: 0;
			}
		}
	}
`;
