import {
	InsertCoverPhoto,
	InsertCustomizations,
	InsertDescription,
	InsertName,
	InsertProfilePhoto,
	ShopCustomizations,
} from '../interfaces/ShopCustomizations';

export const insertCustomizations = (
	shopCustomizations: ShopCustomizations
): InsertCustomizations => ({
	type: 'INSERT_CUSTOMIZATIONS',
	shopCustomizations,
});

export const insertCoverPhoto = (coverPhoto: string): InsertCoverPhoto => ({
	type: 'INSERT_COVER_PHOTO',
	coverPhoto,
});

export const insertProfilePhoto = (
	profilePhoto: string
): InsertProfilePhoto => ({
	type: 'INSERT_PROFILE_PHOTO',
	profilePhoto,
});

export const insertName = (name: string): InsertName => ({
	type: 'INSERT_NAME',
	name,
});

export const insertDescription = (description: string): InsertDescription => ({
	type: 'INSERT_DESCRIPTION',
	description,
});
