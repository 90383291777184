import styled from 'styled-components';

export const Container = styled.div`
	height: max-content;
	min-height: 100vh;
	background: #f5f5f5;
	display: flex;
	flex-direction: column;
`;

export const Section = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 8px;
	/* padding: 31px 50px 50px 21px; */
	width: var(--Default-page-alignment-vale);
	height: 100%;
	margin: auto;
	@media (max-width: 768px) {
		width: 100%;
	}

	>h1 {
		font: normal normal bold 24px/29px Roboto;
		letter-spacing: 0px;
		color: #000000;
		opacity: 1;
		margin: 31px 0px 0px 21px;
		text-align: center;
		text-transform: uppercase;
	}

	>h2 {
		font: normal normal bold 24px/29px Roboto;
		letter-spacing: 0px;
		color: #000000;
		opacity: 1;
		margin: 31px 0 0 21px;	
		text-align: left;
		opacity: 0.7;
	}

	>div.list-types {
		padding: 24px;

		width: 100%;
		display: flex;
		column-gap: 10px;
		justify-content: left;
		align-items: center;

		>img {
			width: 70px;
		}
	}

`