import React from 'react';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { Container } from './styles';

const MenuAds: React.FC = () => {
	const { path, url } = useRouteMatch();
	const history = useHistory();
	return (
		<>
			<Container>
				<NavLink
					to={`${url}/banners`}
					className="banners"
					activeClassName="item-active"
				>
					Banners da página inicial
				</NavLink>
				<NavLink
					to={`${url}/Featured`}
					className="store"
					activeClassName="item-active"
				>
					Lojas destacadas
				</NavLink>
				<NavLink
					to={`${url}/PatronizeProduct`}
					className="product"
					activeClassName="item-active"
				>
					Produtos patrocinados
				</NavLink>
			</Container>
		</>
	);
};

export default MenuAds;
