import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Container } from './styles';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import { useHistory } from 'react-router-dom';
import Banner1 from '../../../assets/banner/Banner_01.jpg';
import Banner2 from '../../../assets/banner/Banner_02.jpg';
import Banner3 from '../../../assets/banner/Banner_03.jpg';
import Banner4 from '../../../assets/banner/Banner_04.png';
import { AxiosResponse } from 'axios';
import api from '../../../api';
import { ProductCategories } from '../../ProductRegister/ProductRegister';
import config from '../../../config';

SwiperCore.use([Navigation, Pagination, Autoplay]);

interface Banner {
	id: string;
	path_image: string;
}

const Carousel: React.FC = () => {
	const history = useHistory();
	const [banners, setBanners] = useState<Banner[]>([]);

	useEffect(() => {
		api.get('/all-main-banner')
			.then((response: any) => {
				setBanners(response.data.mainBanner);
			})
			.catch((err) => {});
	}, []);

	return (
		<Container>
			{banners.length > 0 && (
				<Swiper
					navigation={true}
					loop={true}
					className="mySwiper"
					spaceBetween={0}
					slidesPerView={1}
				>
					{banners.map((banner) => (
						<SwiperSlide>
							<img
								src={`${config.host}/static/banners-home-page/${banner.path_image}`}
								alt="Imagem banner"
							></img>
						</SwiperSlide>
					))}
					{/* <SwiperSlide>
					<img src={Banner1} alt="" />
				</SwiperSlide>
				<SwiperSlide>
					<img src={Banner2} alt="" />
				</SwiperSlide>
				<SwiperSlide><img
          src={Banner3}
          alt=""
        /></SwiperSlide>
				<SwiperSlide>
					<img
						src={Banner4}
						alt=""
						onClick={() =>
						window.open('http://www.luanovamodas.com.br/')
						}
					/>
				</SwiperSlide> */}
				</Swiper>
			)}
		</Container>
	);
};

export default Carousel;
