import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { insertMobileHeader } from '../../actions/MobileHeaderActions';
import Header from '../../components/Default/header';
import JoinUs from '../../components/JoinUs/JoinUs';
import { BackPreset } from '../../interfaces/MobileHeader';

import { Container } from './styles';

const AboutUsPage: React.FC = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(insertMobileHeader(BackPreset))
	});
	return (
		<Container>
			<Header />
            <JoinUs />
		</Container>
	);
};

export default AboutUsPage;