import {
	ProductsCartAction,
	ProductsCartState,
} from '../interfaces/ProductsCart';

const initialState: ProductsCartState = {
	currentCart: [],
};

export default function ProductsCartReducer(
	state = initialState,
	action: ProductsCartAction
) {
	switch (action.type) {
		case 'INSERT_IN_CART':
			return {
				...state,
				currentCart: [action.productsCart],
			};
		case 'CLEAR_CART':
			return {
				...state,
				currentCart: [],
			};
		case 'ADD_ITEM_IN_CART':
			return {
				...state,
				currentCart: [...state.currentCart, action.product],
			};
		case 'INSERT_ADDRESS':
			return {
				...state,
				currentCart: [
					...state.currentCart.map((item) => {
						if (item.parameters.product.id === action.productId) {
							return {
								...item,
								address: action.address,
							};
						} else {
							return item;
						}
					}),
				],
			};
		case 'INSERT_SHIPPING_METHOD':
			return {
				...state,
				currentCart: state.currentCart.map((item) => {
					if (
						item.parameters.product.id === action.productId &&
						action.cart_product_id === item.cart_product_id
					) {
						return {
							...item,

							parameters: {
								...item.parameters,
								shippingMethod: action.shippingWay,
								shippingValue: action.shippingValue,
								address: action.address,
							},
						};
					} else {
						return item;
					}
				}),
			};
		case 'INSERT_INSTALLMENTS':
			return {
				...state,
				currentCart: state.currentCart.map((item) => {
					if (item.parameters.product.id === action.productId) {
						return {
							...item,
							installments: action.installments,
						};
					} else {
						return item;
					}
				}),
			};
		case 'INSERT_QUANTITY':
			return {
				...state,
				currentCart: state.currentCart.map((item) => {
					if (
						item.parameters.product.id === action.productId &&
						item.cart_product_id === action.cart_product_id
					) {
						return {
							...item,
							parameters: {
								...item.parameters,
								quantity: action.quantity,
							},
						};
					} else {
						return item;
					}
				}),
			};
		case 'REMOVE_FROM_CART':
			return {
				currentCart: state.currentCart.filter(
					(item) =>
						item.parameters.product.id !== action.productId ||
						item.cart_product_id !== action.cart_product_id
				),
			};

		default:
			return state;
	}
}
