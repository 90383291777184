import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import api from '../../../../api';
import config from '../../../../config';
import UserAccount, {
	UserShopProducts,
} from '../../../../interfaces/UserAccount';
import { AppState } from '../../../../store';
import { AssociatedCard, AssociatedRowContainer } from './styles';
import placeholder_store_background from '../../../../assets/placeHolders/placeholder_store_background.png';
import placeholder_store_profile from '../../../../assets/placeHolders/placeholder_store_profile.png';
import { getFristImageSource } from '../../../../utils/GetProductImage';
import AssociatedReplaceModal from './AssociatedReplaceModal/AssociatedReplaceModal';

export type FeatureStores = {
	id: string;
	user_accounts_id: string;
	shop_customizations: any;
	product: any[] | undefined;
};

const Associated: React.FC = () => {
	const history = useHistory();
	const [replace, setReplace] = useState<number>(-1);
	const [open, setOpen] = useState<boolean>(false);
	const [usersShopsProducts, setUsersShopsProducts] = useState<
		UserShopProducts[]
	>([]);
	const [featuredStores, setFeaturedStores] = useState<FeatureStores[]>([]);
	const [currentStore, setCurrentStore] = useState<FeatureStores>();

	useEffect(() => {
		api.get('/get-all-users-shops-products').then((response: any) => {
			setUsersShopsProducts(response.data.usersShopProducts);
		});
	}, []);

	useEffect(() => {
		api.get('/all-featured-store').then((response) => {
			setFeaturedStores(response.data.featuredStore);
		});
	}, []);

	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	return (
		<>
			<AssociatedReplaceModal
				setOpen={setOpen}
				open={open}
				currentStore={currentStore}
			/>
			<AssociatedRowContainer>
				{featuredStores
					.map((store, index) => (
						<>
								<AssociatedCard
									onMouseOver={(e) => {
										setReplace(index);
									}}
									onMouseLeave={(e) => {
										setReplace(-1);
									}}
								>
									<div className="associated-img-container">
										<img
											className="associated-img-cover"
											src={
												store
													.shop_customizations
													?.shop_cover_photo
													? `${config.host}/static/shopImages/${store.shop_customizations?.shop_cover_photo}`
													: placeholder_store_background
											}
											alt="Imagem da categoria"
										/>
										<img
											className="associated-img-profile"
											src={
												store
													.shop_customizations
													?.shop_profile_photo
													? `${config.host}/static/shopImages/${store.shop_customizations?.shop_profile_photo}`
													: placeholder_store_profile
											}
											alt="Imagem da categoria"
										/>
									</div>
									<div className="associated-name-products-container">
										<h3>
											{store.shop_customizations
												.shop_name
												? store
														.shop_customizations
														.shop_name.length > 40
													? store.shop_customizations.shop_name.substring(
															0,
															40
													  ) + '...'
													: store
															.shop_customizations
															.shop_name
												: 'Sem nome'}
										</h3>

										<h4
											onClick={() => {
												if (
													user?.id !==
													store.user_accounts_id
												) {
													history.push(
														`/seller-area/${store.user_accounts_id}/all/0/100000000/undefined`
													);
													window.scrollTo(0, 0);
												} else {
													history.push(
														`/seller-area/${store.user_accounts_id}/all/0/100000000/undefined/products`
													);
													window.scrollTo(0, 0);
												}
											}}
										>
											ver loja
										</h4>
									</div>
									{replace === index && (
										<div className="replace">
											<p
												onClick={() => {
													setOpen(true);
													setCurrentStore(
														store
													);
												}}
											>
												Substituir
											</p>
										</div>
									)}
								</AssociatedCard>
						</>
					))}
			</AssociatedRowContainer>
		</>
	);
};
export default Associated;
