import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import api from '../../../../api';
import Input from '../../../Default/Input/Input';
import { Container, InputsContainer } from './styles';
import cep from 'cep-promise';
import { AxiosResponse } from 'axios';
import { Address } from '../../../../interfaces/UserAccount';
import { useDispatch } from 'react-redux';
import { insertAddress, updateAddress } from '../../../../actions/UserActions';

export interface NewAddressModalHandles {
	handleOpenModal: () => void;
}
interface ModalInputs {
	street: string;
	state: string;
	city: string;
	number: string;
	zip_code: string;
	complement: string;
}
interface Props {
	address?: Address;
}

const NewAddressModal: React.ForwardRefRenderFunction<
	NewAddressModalHandles,
	Props
> = ({ address }, ref) => {
	const [modalIsOpen, setIsOpen] = useState(false);
	const [editAddress, setEditAddress] = useState<Address>();
	const { register, handleSubmit, errors } = useForm<ModalInputs>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
	});
	const dispatch = useDispatch();
	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			width: '60%',
			maxWidth: '840px',
			transform: 'translate(-50%, -50%)',
			padding: '40px',
		},
		overlay: {
			backgroundColor: 'rgba(0,0,0, 0.8)',
		},

		h2: {
			color: '#3B3D4A',
		},
	};
	function closeModal() {
		setIsOpen(false);
	}
	function handleOpenModal() {
		setIsOpen(true);
	}

	useEffect(() => {
		setEditAddress(address);
	}, [address]);

	useImperativeHandle(ref, () => {
		return {
			handleOpenModal,
		};
	});
	const onSubmit = (data: ModalInputs) => {
		if (!address) {
			api.post('/insert-user-address', {
				city: data.city,
				state: data.state,
				street: data.street,
				zip_code: data.zip_code,
				number: data.number,
				complement: data.complement,
			})
				.then((response: AxiosResponse<any>) => {
					dispatch(insertAddress(response.data.response));
					closeModal();
				})
				.catch((err) => {
					console.error(err);
				});
		} else if (address) {
			api.put('/update-user-address', {
				id: address.id,
				city: data.city,
				state: data.state,
				street: data.street,
				zip_code: data.zip_code,
				number: data.number,
				complement: data.complement,
			})
				.then((response: AxiosResponse<any>) => {
					dispatch(updateAddress(response.data.response));
					closeModal();
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};
	const findCepInformations = async (zip_code: string) => {};
	return (
		<Modal
			isOpen={modalIsOpen}
			onRequestClose={closeModal}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<Container>
				<h2 className="modal-title">
					{!address ? 'Adicionar novo endereço' : 'Alterar endereço'}
				</h2>
				<InputsContainer onSubmit={handleSubmit(onSubmit)}>
					<div className="first-line">
						<Input
							label=""
							placeholder="CEP"
							flex="2"
							name="zip_code"
							ref={register({ required: true })}
							type="text"
							border={false}
							error={errors.zip_code ? true : false}
							//case it is edit
							defaultValue={editAddress?.zip_code}
						/>
						<Input
							label=""
							placeholder="Endereço"
							flex="3"
							name="street"
							ref={register({ required: true })}
							type="text"
							border={false}
							error={errors.street ? true : false}
							//case it is edit
							defaultValue={editAddress?.street}
							onChange={(e) => {
								findCepInformations(e.target.value);
							}}
						/>
						<Input
							label=""
							placeholder="Número"
							flex="1"
							name="number"
							ref={register({ required: true })}
							type="text"
							border={false}
							error={errors.number ? true : false}
							//case it is edit
							defaultValue={editAddress?.number}
						/>
					</div>
					<div className="second-line">
						<Input
							label=""
							placeholder="Estado"
							flex="2"
							name="state"
							ref={register({ required: true })}
							type="text"
							border={false}
							error={errors.state ? true : false}
							//case it is edit
							defaultValue={editAddress?.state}
						/>
						<Input
							label=""
							placeholder="Cidade"
							flex="1"
							name="city"
							ref={register({ required: true })}
							type="text"
							border={false}
							error={errors.city ? true : false}
							//case it is edit
							defaultValue={editAddress?.city}
						/>

						<Input
							label=""
							placeholder="Complemento"
							flex="4"
							name="complement"
							ref={register({ required: false })}
							type="text"
							border={false}
							error={errors.complement ? true : false}
							//case it is edit
							defaultValue={editAddress?.complement}
						/>
					</div>
					<button type="submit">
						{!address
							? 'Adicionar novo endereço'
							: 'Alterar endereço'}
					</button>
				</InputsContainer>
			</Container>
		</Modal>
	);
};

export default forwardRef(NewAddressModal);
