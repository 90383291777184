import React, { useEffect, useState } from 'react';
import Header from '../../../components/Default/header';
import UpdateProductMobile from '../../../components/UpdateProductMobile/UpdateProductMobilePage';
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';
import api from '../../../api';
import { Container } from './styles';
import { IProduct } from '../../ProductPage/ProductPage';
import { useDispatch, useSelector } from 'react-redux';
import { insertMobileHeader } from '../../../actions/MobileHeaderActions';
import { BackPreset } from '../../../interfaces/MobileHeader';
import UserAccount from '../../../interfaces/UserAccount';
import { AppState } from '../../../store';

interface PageParams {
	product_id: string;
}
const UpdateProductMobilePage: React.FC = () => {
	const dispatch = useDispatch();
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	useEffect(() => {
		dispatch(
			insertMobileHeader({
				...BackPreset,
				leftSideComp: {
					leftSide: 1,
					backArrowPath: `/seller-area/${user.id}/all/0/100000000/undefined/`,
				},
			})
		);
	});

	const pageParams = useParams<PageParams>();
	const [product, setProduct] = useState<IProduct>();

	useEffect(() => {
		if (pageParams.product_id) {
			api.get('/product', {
				params: {
					id: pageParams.product_id,
				},
			})
				.then((response: any) => {
					setProduct(response.data.data.response[0]);
				})
				.catch((error: any) => {});
		} else {
		}
	}, [pageParams]);

	return (
		<Container>
			<Header />
			{product && <UpdateProductMobile product={product} />}
		</Container>
	);
};

export default UpdateProductMobilePage;
