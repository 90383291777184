import styled from 'styled-components';

export const Container = styled.div`
	width: 380px;
	height: 191px;
	background: white;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 12px;
	font-family: 'Roboto';
	font-size: 12px;
	padding-left: 23px;
	padding-top: 16px;
	.name-id {
		/* width: 90%; */
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		position: relative;
		font-size: 18px;
		font-weight: 500;
		color: #5a646e;
		font-family: 'Open Sans';
		/* margin-bottom: 32px; */
		align-items: center;
		height: 40px;
		span {
			font-size: 11px;
			color: #707070;
		}
	}
	.name-id::before {
		content: '';
		width: 100%;
		height: 1px;
		position: absolute;
		margin-top: 28px;
		background: #00000029;
	}
	.cnpj {
		margin-bottom: 12px;
		p {
			color: #707070;
			font-size: 12px;
			font-weight: 500;
			/* margin-top: 10px; */
			margin-bottom: 10px;
		}
		.id-row {
			margin-top: 6px;
		}
		span {
			margin-bottom: 10px;
			font-size: 14px;
			color: #5a646e;
		}
	}
	.buttons {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 90%;
		cursor: pointer;
	}
	.saller-btn {
		display: flex;
		align-items: center;
		justify-content: center;

		border: 1px solid #2b63fc;
		width: 180px;
		height: 25px;
		text-align: center;
		border-radius: 8px;
		color: #2b63fc;
		cursor: pointer;
	}
	.going-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: 8px;

		svg {
			width: 15px;
			height: 15px;
		}
		justify-content: center;
		display: flex;
		flex-direction: row;
		width: 135px;
		height: 25px;
		text-align: center;
		/* border: 1px solid #2b63fc; */
		border: 1px solid #2b63fc;
		background: #2b63fc;
		color: white;
		border-radius: 8px;
		cursor: pointer;
		p {
			margin-left: 10px;
		}
	}
`;
export const ModalContainer = styled.div`
	width: 796px;
	height: 408px;
	padding: 26px;
	font-family: 'Roboto';
	.cards {
		.button-text {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-top: 40px;
			span {
				width: 196px;
				height: 30px;
				border-radius: 8px;
				background: #2b63fc;
				color: white;
				font-size: 13px;
				text-align: center;
				padding-top: 15px;
			}
			p {
				font-size: 14px;
				color: #8a99a8;
				font-style: italic;
			}
		}
		span {
			text-align: start;
			margin-bottom: 16px;
		}
		.row {
			display: grid;
			column-gap: 20px;
			grid-template-columns: 1fr 1fr;
			input {
				height: 38px;
				border-radius: 8px;
				border: 1px solid #8a99a8;
				margin-bottom: 22px;
			}
			.motivation {
				display: flex;
				flex-direction: column;
			}
			.duration {
				display: flex;
				flex-direction: column;
			}
		}
		.motive {
			display: flex;
			flex-direction: column;
			input {
				height: 123px;
				border-radius: 8px;
				border: 1px solid #8a99a8;
			}
		}
	}
	.title-block {
		h1 {
			font-size: 19px;
			color: #2a3199;
			font-weight: 500;
			margin-bottom: 32px;
		}
	}
	.title {
		h1 {
			font-size: 19px;
			font-weight: 500;
			color: #5a646e;
		}
		p {
			font-size: 14px;
			color: #8a99a8;
		}
	}
	.collumns {
		display: grid;
		height: 51%;
		grid-template-columns: 1fr 2fr;
		.cnpj-modal {
			h2 {
				font-weight: 500;
				font-size: 13px;
				color: #707070;
				margin-top: 27px;
				margin-bottom: 11px;
			}
			p {
				color: #5a646e;
				font-size: 14px;
			}
		}
	}
	span {
		font-size: 22px;
		color: #5a646e;
		text-align: center;
	}
	p {
		font-size: 13px;
		font-weight: 500;
		color: #8a99a8;
	}
	.product-registre {
		grid-area: product-registre;
		/* width: 90%;
		height: 68%; */
		padding: 15px;
		border-radius: 7px;
		column-gap: 12px;
		row-gap: 12px;
		background-color: #f3f2f2;

		text-align: center;
	}
	.sallers {
		border-radius: 7px;
		column-gap: 12px;
		row-gap: 12px;
		background-color: #f3f2f2;
		grid-area: sallers;

		text-align: center;
	}
	.cancel {
		border-radius: 7px;
		column-gap: 12px;
		row-gap: 12px;
		background-color: #f3f2f2;
		grid-area: cancel;

		text-align: center;
	}
	.reports {
		border-radius: 7px;
		column-gap: 12px;
		row-gap: 12px;
		background-color: #f3f2f2;
		grid-area: reports;

		text-align: center;
	}
	.devolution {
		border-radius: 7px;
		column-gap: 12px;
		row-gap: 12px;
		background-color: #f3f2f2;
		grid-area: devolution;
		text-align: center;
	}
	.cancel-client {
		border-radius: 7px;
		column-gap: 12px;
		row-gap: 12px;
		background-color: #f3f2f2;
		grid-area: cancel-client;
		text-align: center;
		.row-text {
			margin-top: 27px;
		}
	}
	.reputation {
		border-radius: 7px;
		column-gap: 12px;
		row-gap: 12px;
		background-color: #f3f2f2;
		grid-area: reputation;
		text-align: center;
		.row-text {
			margin-top: 27px;
			h1 {
				font-size: 17px;
				color: #acc651;
			}
			p {
				font-size: 13px;
				font-weight: bold;
			}
		}
	}
	.statistics {
		display: grid;
		column-gap: 25px;
		row-gap: 14px;
		grid-template-areas:
			'product-registre sallers cancel cancel reports'
			'product-registre sallers cancel cancel reports'
			'product-registre cancel-client reputation reputation reputation'
			'devolution cancel-client reputation reputation reputation'
			'devolution cancel-client reputation reputation reputation';
	}
	.buttons-modal {
		display: flex;
		justify-content: space-between;
		font-size: 12px;
		text-align: center;
		margin-top: 125px;
		.going {
			padding-top: 10px;
			width: 168px;
			height: 25px;
			border-radius: 8px;
			color: white;
			background: #2b63fc;
			cursor: pointer;
		}
		.block {
			padding-top: 10px;
			width: 168px;
			height: 25px;
			border-radius: 8px;
			color: black;
			background: white;
			border: 1px solid black;
			cursor: pointer;
		}
	}
`;
