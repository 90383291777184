import styled from 'styled-components';

export const Container = styled.div`
	/* minhas vendas */
	width: 75%;
	background: white;
	border-radius: 12px;
	min-height: 361px;
	margin-top: 10px;
	

	background: #fff;
	@media (max-width: 768px) {
		border-radius: 0;
		width: 100%;
	}

	h1 {
		padding-left: 5%;
		padding-top: 20px;
		margin: 0px;
	}
`;
export const FiltersContainer = styled.div`
	display: flex;
	flex-direction: row;
	padding-top: 10px;
	padding-left: 40px;
	@media (max-width: 768px) {
		width: 100%;
	}
	.select-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex: 1;
		margin-top: 10px;

		.select {
			display: flex;
			flex-direction: column;
			border-radius: 8px;
			width: 24%;
			max-width: 117px;

			label {
				font-size: min(10px, 1.8vw);
				font-family: var(--Roboto-font-family);
				font-weight: 400;

				margin-bottom: 6px;
				border-radius: 8px;
				color: #888888;
			}
			select {
				border: solid 1px #cbcbcb;
				color: #b9b9b9;
				height: 28px;
				border-radius: 8px;
				margin-right: 15px;

				&:focus {
					outline: none;
				}
			}
			input {
				border: solid 1px #cbcbcb;
				height: 28px;
				border-radius: 8px;
				color: #b9b9b9;
				width: 130px;
			}
		}
		.select + .select {
			margin-left: 15px;
		}
	}
	.search-container {
		display: flex;
		align-items: flex-end;
		flex: 1;
		margin-top: 10px;
		margin-left: 22px;
		@media (max-width: 768px) {
			margin: 0px;
			width: 90%;
		}

		.search-card {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;

			border: solid 1px #cbcbcb;
			padding: 5px;
			border-radius: 4px;
			width: 90%;
			max-width: 428px;
			margin-left: 30px;
			@media (max-width: 768px) {
				display: none;
			}

			input {
				border: none;
				width: 100%;
				border-radius: 4px;
			}

			.search-icon {
				width: 16px;
				height: 16px;
				fill: #cbcbcb;
				stroke: #cbcbcb;
			}
		}
	}
	.toggle {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		label {
			font-size: 12px;
			font-family: var(--Roboto-font-family);
			font-weight: 400;

			margin-bottom: 6px;
			border-radius: 4px;
			color: #888888;
			@media (max-width: 768px) {
				margin-top: 12px;
			}
		}
		.options {
			display: flex;
			align-items: center;
			border: solid 1px #cbcbcb;
			border-radius: 4px;
			justify-content: space-around;
			align-items: center;
			height: 28px;
			@media (max-width: 768px) {
				width: 80%;
			}

			span {
				font-size: min(12px, 1.6vw);
				font-family: var(--Roboto-font-family);
				font-weight: 400;
				color: #888888;
				/* @media (max-width: 768px) {
					color: #5a646e;
					font-size: 12px;
				} */
			}
			.option-on-going {
				border-top-left-radius: 6px;
				border-bottom-left-radius: 6px;
			}
			.option-canceled {
				border-top-right-radius: 6px;
				border-bottom-right-radius: 6px;
			}
			.option-delivered {
				border-left: solid 1px #cbcbcb;
				border-right: solid 1px #cbcbcb;
			}
			.option-on-going,
			.option-delivered,
			.option-canceled {
				width: 100px;
				display: flex;
				flex: 1;
				height: 100%;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				/* height: 28px; */
				@media (max-width: 768px) {
					white-space: pre;
				}

				span {
					font-size: 12px;
					font-family: var(--Roboto-font-family);
					font-weight: 400;
					color: #888888;
					@media (max-width: 768px) {
						font-size: 12px;
					}

					&:hover {
						cursor: pointer;
					}
				}
				&.active {
					background: #6f6f6f;
					border-radius: 0;

					span {
						color: white;
					}
				}
			}
		}
	}
`;

export const NoSell = styled.div`
	height: 51.5vh;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #fff;

	@media (max-width: 768px) {
		border-radius: 8px;
		width: 100%;
	}

	.emoji-and-text {
		display: flex;
		flex-direction: column;
		align-items: center;

		.emoji {
			width: 96px;
			height: 96px;
		}

		h2 {
			color: #353a40;
			font-size: 20px;
			font-family: var(--Roboto-font-family);
			font-weight: 400;
			margin-top: 22px;
			text-align: center;

			@media (max-width: 468px) {
				max-width: 221px;
				text-align: center;
			}
		}
	}
`;
