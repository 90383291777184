import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: 17px;
	font-size: 16px;
	font-family: 'Roboto';
	font-weight: 500;
	color: #5a646e;
	justify-content: center;
	.banners {
		align-items: center;
		justify-content: center;
		display: flex;
		width: 242px;
		height: 37px;
		background: #cccccc;
		color: #5a646e;
		text-align: center;
		border-radius: 8px;
		text-decoration: none;
	}
	.store {
		align-items: center;
		justify-content: center;
		display: flex;
		width: 242px;
		height: 37px;
		background: #cccccc;
		color: #5a646e;
		text-align: center;
		border-radius: 8px;
		text-decoration: none;
	}
	.product {
		align-items: center;
		justify-content: center;
		display: flex;
		width: 242px;
		height: 37px;
		background: #cccccc;
		color: #5a646e;
		text-decoration: none;
		text-align: center;
		border-radius: 8px;
	}

	.item {
		text-decoration: none;
		color: #cccccc;
		font-size: 16px;
		font-family: var(--Roboto-font-family);
		font-weight: 400;
		text-decoration: none;
		background: red;
		/* width: 35%;
		height: 50px; */
		width: 206px;
		height: 57px;
		max-width: 220px;
		min-width: 79px;

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		/* margin-bottom: 10px; */
		border-radius: 8px 8px 8px;
	}

	.item + .item {
		margin-left: 25px;
	}
	@media (max-width: 585px) {
		.item + .item {
			margin-left: 5px;
		}
	}

	.item-active {
		width: 242px;
		height: 37px;
		background: white;
		color: #2b63fc;
		text-align: center;
		border-radius: 8px;
	}
`;
