import React, { useState } from 'react';
import EmailSent from './EmailSent/EmailSent';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import { Container } from './styles';

interface Props {
  closeModal(): void;
}

const ForgotPasswordModal: React.FC<Props> = ({ closeModal }) => {
  const [stepPassword, setStepPassword] = useState(false);
  return (
    <Container>
      {!stepPassword ? (
        <ForgotPassword step={() => setStepPassword(true)} />
      ) : (
          <EmailSent closeModal={closeModal} />
        )}

    </Container>
  );
};

export default ForgotPasswordModal;
