import styled from 'styled-components';

export const Container = styled.div`
	width: 100vw;
	height: 50px;
	background: #fff;
	box-shadow: 0px 3px 6px #0000001c;
	position: absolute;
	left: 0;
	z-index: 2;
`;

export const DivLogo = styled.div`
	margin-left: 90px;
	margin-top: 10px;
`;
