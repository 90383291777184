import React from 'react';
import { NavLink, useRouteMatch, useHistory } from 'react-router-dom';
import { ReactComponent as SellIcon } from '../../../assets/icons/sells.svg';
import { ReactComponent as StoreIcon } from '../../../assets/icons/store.svg';
import { ReactComponent as ReputationIcon } from '../../../assets/icons/reputation.svg';
import { AiFillShop } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import api from '../../../api';
import { AxiosResponse } from 'axios';
import { GetSignUpLinkResponse } from '../../../interfaces/ApiResponses/SignUpLink';

import { Container } from './styles';
import SellerReputationCardVertical from './SellerReputationCardVertical/SellerReputationCardVertical';
import UserAccount, { UserTypeEnum } from '../../../interfaces/UserAccount';
import { AppState } from '../../../store';

const SellerAreaMenu: React.FC = () => {
	
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);

	function RenderButton() {
		/* if (!user.ebanx_resource_token && user.user_types &&
				user.user_types.name === UserTypeEnum.seller) {
			return (
				<button onClick={() => history.push('/profile/user-information')}>
					Termine o cadastro no perfil!
				</button>
			)
		} else if (user.ebanx_resource_token) { */
			return (
				<button onClick={() => history.push('/product-register')}>
					+ Cadastrar novo produto
				</button>
			)
		//}
	}

	const { path, url } = useRouteMatch();
	const history = useHistory();
	return (
		<Container>
			<NavLink
				to={`${url}/products`}
				className="item"
				activeClassName="item-active"
			>
				Produtos cadastrados 
			</NavLink>
			<NavLink
				to={`${url}/sales`}
				className="item"
				activeClassName="item-active"
			>
				Minhas vendas
			</NavLink>
			<NavLink
				to={`${url}/reputation`}
				className="item"
				activeClassName="item-active"
			>
				Reputação
			</NavLink>
			<NavLink
				to={`${url}/income`}
				className="item"
				activeClassName="item-active"
			>
				Rendimentos
			</NavLink>
			<div className="button-container">
				{RenderButton()}

				<SellerReputationCardVertical />
			</div>
		</Container>
	);
};

export default SellerAreaMenu;
