import { ProductActions, ProductState } from '../interfaces/Product';
import {
	AreaTypes,
	ProductListActions,
	ProductsListState,
} from '../interfaces/ProductsList';

const initialState: ProductsListState = {
	currentProductsList: [
		{
			products: undefined,
			areaTitle: 'homePage',
		},
		{
			products: undefined,
			areaTitle: 'SearchPage',
		},
		{
			products: undefined,
			areaTitle: 'myProductsPage',
		},
		{
			products: undefined,
			areaTitle: 'inactiveProductsPage',
		},
		{
			products: undefined,
			areaTitle: 'favoriteProductsPage',
		},
	],
};

export default function ProductsListReducer(
	state = initialState,
	action: ProductListActions
) {
	switch (action.type) {
		case 'INSERT_PRODUCTS':
			return {
				...state,
				currentProductsList: [
					...state.currentProductsList.map((item) => {
						if (item?.areaTitle === action.targetArea) {
							item.products = action.products;
						}
						return item;
					}),
				],
			};

		case 'REMOVE_SPECIFIC_PRODUCT':
			return {
				...state,
				currentProductsList: [
					...state.currentProductsList.map((item) => {
						if (item.areaTitle === action.target_area) {
							item.products = item.products?.filter(
								(item) => item.id !== action.product_id
							);
						}
						return item;
					}),
				],
			};

		case 'CLEAN_PRODUCTS_LIST':
			return {
				...state,
				currentProductsList: [],
			};

		default:
			return state;
	}
}
