import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../../api";
import { ProductCategories } from "../../../ProductRegister/ProductRegister";
import { ProductCategory } from '../../../../interfaces/ProductCategory';
import { CategoryContainer, DropDownItem, SearchBoxCategory, SearchCategory, SearchIcon, SearchRowCategory } from "./styles";
import { RiSearch2Line } from "react-icons/ri";

const CategoryDropDown: React.FC = () => {
    const history = useHistory();
    const [
		productCategories,
		setProductCategories,
	] = useState<ProductCategory[]>();
    const [searchCategories, setSearchCategories] = useState<ProductCategory[]>();

    useEffect(() => {
		api.get('/get-product-categories')
			.then((response: AxiosResponse<ProductCategories>) => {
				setProductCategories(response.data.categories);
                setSearchCategories(response.data.categories);
			})
			.catch((err) => {});
	}, []);

    const handleSearchCategory = (categorySearch: string) => {
        setSearchCategories(
            productCategories!.filter((category) => {
                return category.name.toLocaleLowerCase()
                .includes(categorySearch.toLocaleLowerCase());
            })
        );
    };

    return (
        <>
            <SearchBoxCategory>
                <SearchRowCategory>
                    <SearchIcon>
						<RiSearch2Line
							style={{ fill: '#fff' }}
							onClick={(e) => {}}
						/>
					</SearchIcon>
                    <SearchCategory
                        type="text"
                        onChange={(e: any) => handleSearchCategory(e.target.value)}
                        placeholder="Buscar categorias..."
                    ></SearchCategory>
                </SearchRowCategory>
            </SearchBoxCategory>
            <CategoryContainer>
                {searchCategories?.sort((a, b) => a.name.localeCompare(b.name)).map((category) => (
                    <DropDownItem
                        onClick={() => {
                            history.push(
                                `/search/${category.id}/category/all/0/100000000`
                            )
                        }}
                    >
                        {category.name}
                    </DropDownItem>
                ))}
            </CategoryContainer>
        </>
    )
}

export default CategoryDropDown;