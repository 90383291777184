import { 
  MobileHeaderState,
  MobileHeaderAction,
  leftSideEnum,
  middleEnum,
  rightSideEnum,
  SearchPreset
} from "../interfaces/MobileHeader";


const initialState: MobileHeaderState = {
	currentMobHeader: SearchPreset,
};

export default function MobileHeaderReducer(
	state = initialState,
	action: MobileHeaderAction
) {
	switch (action.type) {
		case 'INSERT_IN_MOB_HEADER':
			return {
				...state,
				currentMobHeader: action.mobileHeader,
			};
		default:
			return state;
	}
}
