import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { ButtonWrapper } from './styles';
import { Link, useHistory, useParams } from 'react-router-dom';
import ProductPage, { IProduct } from '../../../Pages/ProductPage/ProductPage';
import animationData from './animation.json';
import api from '../../../api';
import UserAccount from '../../../interfaces/UserAccount';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store';
import {
	insertUserFavorite,
	removeUserFavorite,
} from '../../../actions/UserActions';
import { BsHeart, BsHeartFill } from 'react-icons/bs';

type Props = {
	productId: string;
	size?: number;
	position?:
		| 'absolute'
		| 'inherit'
		| '-moz-initial'
		| 'initial'
		| 'revert'
		| 'unset'
		| '-webkit-sticky'
		| 'fixed'
		| 'relative'
		| 'static'
		| 'sticky'
		| undefined;
	zIndex?: number;
	top?: string;
	left?: string;
};

const FavoriteIcon: React.FC<Props> = ({
	productId,
	position = 'absolute',
	size = 20,
	zIndex = 3,
	top = '0',
	left = '88%',
}) => {
	const dispatch = useDispatch();
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);

	if(!user) { return <></>}

	const hasFavorite: boolean = user.Favorites?.findIndex((fav) => fav.product_id === productId) !== -1;

	const favoriteProduct = async () => {
		if (user.Favorites) {
			const index = user.Favorites.findIndex(
				(fav) => fav.product_id === productId
			);
			if (index !== -1) {
				await api
					.post('/remove-user-favorite-product', {
						favorite_id: user.Favorites[index].id,
					})
					.then(() => {
						dispatch(
							removeUserFavorite(user!.Favorites![index].id)
						);
					});
			} else {
				await api
					.post('/insert-userfavorite-product', {
						user_id: user.id,
						product_id: productId,
					})
					.then((response) => {
						dispatch(
							insertUserFavorite({
								id: response.data.favoriteProduct.id,
								product_id:
									response.data.favoriteProduct.product_id,
								user_account_id:
									response.data.favoriteProduct
										.user_account_id,
							})
						);
					});
			}
		}
	};

	return (
		<ButtonWrapper
			hasFavorite={hasFavorite}
			style={{
				position: position!,
				zIndex: zIndex,
				top: top,
				left: left,
			}}
			onClick={async () => {
				await favoriteProduct();
			}}
		>
			<BsHeartFill
				size={size}
			/>
		</ButtonWrapper>
	);
};

export default FavoriteIcon;
