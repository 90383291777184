import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { RiSearch2Line } from 'react-icons/ri';
import api from '../../../api';
import ProductCard from '../../Default/ProductCard/productCard';

import { DailyPromotion, ModalContainer } from './styles';
import { IProduct } from '../../../Pages/ProductPage/ProductPage';
import { Product } from '../../../interfaces/Product';

type Props = {
	open: boolean;
	setOpen: (open: boolean) => void;
	productForAlteration: IProduct;
};

const ReplacePatronizeProductModal: React.FC<Props> = ({
	open,
	setOpen,
	productForAlteration,
}) => {
	const [allProducts, setAllProducts] = useState<Product[]>([]);
	const [searchProducts, setSearchProducts] = useState<Product[]>([]);

	useEffect(() => {
		api.post('/list-products').then((response) => {
			setAllProducts(response.data.data.response);
			setSearchProducts(response.data.data.response);
		});
	}, []);

	const updateSponsoredProduct = (product_id: string) => {
		api.put('/update-product-sponsored', {
			product_id: product_id,
			id: productForAlteration.id,
		});
	};

	const handleSearchProduct = (productSearch: string) => {
		setSearchProducts(
			allProducts!.filter((products) => {
				return products.title
					.toLocaleLowerCase()
					.includes(productSearch.toLowerCase());
			})
		);
	};

	return (
		<Modal
			isOpen={open}
			onRequestClose={() => setOpen(false)}
			style={{
				overlay: {
					position: 'fixed',
					top: 0,
					zIndex: 1001,
					left: 0,
					right: 0,
					bottom: 0,
					background: '#0007',
				},
				content: {
					top: '50%',
					left: '50%',
					right: 'auto',
					padding: '0 auto',
					bottom: 'auto',
					marginRight: '-50%',
					maxHeight: '100%',
					border: 'none',
					borderRadius: '11px',
					transform: 'translate(-50%, -50%)',
				},
			}}
		>
			<ModalContainer>
				<div className="title">
					<h1>Substituir produto patrocinado</h1>
				</div>
				<div className="search">
					<RiSearch2Line />
					<input
						placeholder="Buscar"
						type="text"
						onChange={(e: any) =>
							handleSearchProduct(e.target.value)
						}
					></input>
				</div>

				<DailyPromotion>
					<div className="daily-promotions-container">
						{searchProducts.map((product) => (
							<>
								<ProductCard
									product={product}
									isReplace={false}
									isSelectProduct={true}
									updateSponsoredProduct={
										updateSponsoredProduct
									}
								/>
							</>
						))}
					</div>
				</DailyPromotion>
			</ModalContainer>
		</Modal>
	);
};

export default ReplacePatronizeProductModal;
