import React, { useEffect, useState } from 'react';
import {
	Container,
	InfosContainer,
	DefaultRow,
	PromotionLabel,
	OriginalPrice,
} from './styles';
import imagem from '../../../assets/imagem.jpg';
import config from '../../../config';
import { RiStarFill } from 'react-icons/ri';
import { RiStarHalfFill } from 'react-icons/ri';
import { RiStarLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { IProduct } from '../../../Pages/ProductPage/ProductPage';
import { getFristImageSource } from '../../../utils/GetProductImage';
import FavoriteIcon from '../../Default/FavoriteIcon/FavoriteIcon';
import { useSelector } from 'react-redux';
import UserAccount, { UserTypeEnum } from '../../../interfaces/UserAccount';
import { AppState } from '../../../store';
import { MoreColors, MoreColorsMobile } from '../../Default/ProductCard/styles';
import { getProductPrice } from '../../../utils/GetProductPrice';
// import { Container } from './styles';
type Props = {
	product: IProduct;
};
const ResultCard: React.FC<Props> = ({ product }) => {
	const user: UserAccount = useSelector(
		(state: AppState) => state.user.currentUser
	);
	const history = useHistory();
	const [showFavoriteIcon, SetShowFavoriteIcon] = useState<boolean>(false);

	const [ratingAverage, setRatingAverage] = useState<number>(0);

	useEffect(() => {
		if(!product.product_reviews) { setRatingAverage(0); return; }

		const rating = product.product_reviews.reduce(
			(accumulator, currentValue) => accumulator + currentValue.rating,
			0
		);

		setRatingAverage(rating / product.product_reviews.length);
	}, [product])

	return (
		<Container
			onMouseOver={() => {
				SetShowFavoriteIcon(true);
			}}
			onMouseLeave={() => {
				SetShowFavoriteIcon(false);
			}}
		>
			<InfosContainer>
				{user &&
					user.user_types.name === UserTypeEnum.customer &&
					showFavoriteIcon && (
						<div className="mobile">
							<FavoriteIcon
								productId={product.id}
								position={'absolute'}
								top="10px"
								left="90%"
								zIndex={1}
							/>
						</div>
					)}
				<figure>
					<img
						src={getFristImageSource(product)}
						alt="imagem do produto"
						onClick={() =>
							history.push(`/product/${product.id}/questions`)
						}
					/>
				</figure>
				<div
					className="product-infos"
					onClick={() =>
						history.push(`/product/${product.id}/questions`)
					}
				>
					<h4>
						{product.title.substring(0, 41) +
							`${product.title.length > 41 ? '...' : ''}`}{' '}
					</h4>
					<DefaultRow>
						{!product.showcase &&
						<div className="rowPrice">
							<h5>
								{' '}
								{'R$ ' +
									String(getProductPrice(product, user))
										.replace('.', ',')
										.substring(
											0,
											product.price.toString().length - 2
										)}{' '}
							</h5>
							<h5 className="small-text">
								{String(product.price)
									.replace('.', ',')
									.substring(
										product.price.toString().length - 2,
										product.price.toString().length
									)}
							</h5>
							{product.promotion && product.promotion > 0 && (
								<OriginalPrice>
									R$ {product.original_price}
								</OriginalPrice>
							)}
						</div>}
						{parseInt(product.max_installments) > 0 && (
							<h3 style={{ marginTop: '0' }}>
								{' '}
								{product.max_installments +
									'x R$ ' +
									Math.round(
										getProductPrice(product, user) /
											parseInt(product.max_installments)
									)
										.toFixed(2)
										.replace('.', ',') +
									' sem juros'}{' '}
							</h3>
						)}
						<div className="row-stars">
							<div className="stars">
								{[...Array(5)].map((star, index) => (
									index + 1 <= ratingAverage
										? <RiStarFill/>
										: <RiStarLine/>
								))}
								{product.product_reviews ? product.product_reviews.length : 0}
							</div>
						</div>
					</DefaultRow>
				</div>
			</InfosContainer>
		</Container>
	);
};

export default ResultCard;
