import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import SellerAreaPage from '../Pages/SellerAreaPage/SellerAreaPage';
import UserProfilePage from '../Pages/UserProfilePage/UserProfilePage';
import Footer from '../components/Footer/Footer';
import ReviewPage from '../Pages/BuyProcessPage/ReviewPage/ReviewPage';
import PaymentSuccessPage from '../Pages/BuyProcessPage/PaymentSuccessPage/PaymentSucessPage';
import CartPage from '../Pages/CartPage/CartPage';
import ShippingMethodPage from '../Pages/CartPage/ShippingMethodPage/ShippingMethodPage';
import OrderConfirmationPage from '../Pages/CartPage/OrderConfirmationPage/OrderConfirmationPage';
import ShippingMethodPage2 from '../Pages/CartPage/ShippingMethodPage2/ShippingMethodPage2';
import FavoriteItemsPage from '../Pages/FavoriteItemsPage/FavoriteItemsPage';
import SellerNotificationPage from '../Pages/SellerNotificationPage/SellerNotificationPage';
import MyPurchasesPage from '../Pages/MyPurchasesPage/MyPurchasesPage';
import PurchaseDetailsPage from '../Pages/PurchaseDetailsPage/PurchaseDetailsPage';
import UserAddressMobilePage from '../Pages/MobilePages/UserAddressMobilePage/UserAddressMobilePage';
import AddPaymentMobilePage from '../Pages/AddPaymentMobile/AddPaymentMobile';
import ChangeUserInformationPage from '../Pages/MobilePages/ChangeUserInformationPage/ChangeUserInformationPage';

const CustomerRoutes: React.FC = () => {
	return (
		<>
			<Route exact path="/cart/:from_page" component={CartPage} />
			<Route path="/favorite" component={FavoriteItemsPage} />
			<Route path="/notification" component={SellerNotificationPage} />
			<Route path="/profile" component={UserProfilePage} />
			<Route path="/purchase-review" component={ReviewPage} />
			<Route
				path="/cart/shipping-method/:from_page"
				component={ShippingMethodPage}
			/>
			<Route
				path="/cart/PaymentCartMethod/:from_page"
				component={ShippingMethodPage2}
			/>
			<Route
				path="/cart/order-confirmation/:from_page"
				component={OrderConfirmationPage}
				/* 	path="/cart/shipping-method2" */
				/* component={ShippingMethodPage2} */
			/>
			<Route
				path="/payment-success/:link/:payment_method"
				component={PaymentSuccessPage}
			/>
			<Route path="/my-purchases" component={MyPurchasesPage} />
			<Route
				path="/purchase-details/:purchase_id"
				component={PurchaseDetailsPage}
			/>
			<Route
				exact
				path="/user-address-mobile"
				component={UserAddressMobilePage}
			/>
			<Route
				exact
				path="/user-address-mobile/:address_id"
				component={UserAddressMobilePage}
			/>
			<Route
				path="/add-payment-mobile/:from_page"
				component={AddPaymentMobilePage}
			/>
			<Route
				path="/change-information-mobile/"
				component={ChangeUserInformationPage}
			/>

			<Footer />
		</>
	);
};
export default CustomerRoutes;
